import {useState} from 'react'
import { BrandTools,   ManageExternalTitle    } from '../../../../utils/Constant'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Label, Row } from 'reactstrap'
 
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
 
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu' 
 
import { branchPublicContactFormInitialValue, BranchPublicContactFormValidationProp, branchPublicContactFormValidationSchema, manageBranchFormInitialValue, manageBranchFormValidationSchema, scheduleFormInitialValue, scheduleFormValidationSchema, SchedulesFormValidationProp } from '../../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import { ErrorMessage, Field, FieldArray, Formik } from 'formik'
import Message from '../../../../Component/CommonMessage/Message'
import City from '../../../../Component/CommonInput/City'
import Branches from '../../../../Component/CommonInput/Branches'
import PhoneField from '../../../../Component/Tools/ManageOnlineStore/ManageBranches/BranchPublicContact/PhoneField'
import EmailField from '../../../../Component/Tools/ManageOnlineStore/ManageBranches/BranchPublicContact/EmailField'
import { Btn, LI, UL } from '../../../../AbstractElements'
import Country from '../../../../Component/CommonInput/Country'
import State from '../../../../Component/CommonInput/State'
import SchedulesForm from '../../../../Component/Tools/ManageOnlineStore/ManageBranches/Schedules/SchedulesForm'
import CommonSwitchSpan from '../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan'
import TitleInput from '../../../../Component/CommonInput/TitleInput'
import DescriptionInput from '../../../../Component/CommonInput/DescriptionInput'
import { options } from '../../../../Component/Application/Ecommerce/AddProduct/AddProduct'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import AddFaqForm from './AddFaqForm'
import ImportBulk from '../ImportBulk/ImportBulk'
import Popup from '../../../../Component/MasterPopup/Popup'
import Notes from '../../Notes/Notes'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'





const AddnewFequentlyQuestion = () => {
   
  const [submitErrors, setSubmitError] = useState<boolean>(false); 
  const [openQestionModal, setOpenQestionModal] = useState(false);
  const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
     
     
    const handleSubmit = (values: SchedulesFormValidationProp) => {
      console.log(values);
      setSubmitError(false);
    };

  return (
    <div className='page-body'>
       <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
      <SubMenuPopup  currentPage="Add Frequently Asked Questions "/>
                </div>
            
      <Breadcrumbs mainParent="All Tools" mainTitle={`${`Add Frequently Asked Questions`} (${BrandTools})`} parent={BrandTools} />
            </div>
      <Container fluid>
        <Row>
          <Col sm="12">
          <Card className="p-3">
          

                               <div className='my-3'>
        <Btn onClick={openQestionModalToggle} className='p-0 child_btn border rounded'   ><span  >Import Bulk Frequently Asked Questions</span></Btn>

                               </div>
                               
        <Formik
                        initialValues={scheduleFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={scheduleFormValidationSchema}>
                        {({ errors ,values,setFieldValue}) => (
                            <AddFaqForm setFieldValue={setFieldValue} values={values} errors={errors}/>
                        )}
                    </Formik>
                    <Popup
                title='Publish Online'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup>
        </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddnewFequentlyQuestion