import React from "react";
import { BrandTools, CreateAPost, Post } from "../../../../utils/Constant";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CreatePostContainer from "../../../../Component/App/Post/CreatePostContainer";
import PostsubMenu from "../../../../Component/App/PostSubMenu/PostsubMenu";
import { ManagePostsTableAction } from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import { Card, CardBody, Container } from "reactstrap";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const CreatePost = () => {
    const location = useLocation();
    return (
        <div className="page-body">
             <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    <SubMenuPopup currentPage="Add a Post" />
                </div>
             <Breadcrumbs
                mainTitle={`${CreateAPost} (${BrandTools})`}
                parent={Post}
            />
            </div>
            
            <CreatePostContainer />
        </div>
    );
};

export default CreatePost;
