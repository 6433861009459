
import { Card, CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  ManageOnlineStoreTitle,
  NewAddonCategory,
} from "../../../../utils/Constant"; 
import AddonCategoriesMain from "../../../../Component/Tools/ManageOnlineStore/AddonCategories/AddonCategoriesMain";
import Message from "../../../../Component/CommonMessage/Message";
import { Link } from "react-router-dom";
import { AddonCategoriesTableAction, ManageOnlineStore } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import AddCategoriesSubMenu from "./AddCategoriesSubMenu/AddCategoriesSubMenu";
import { Btn } from "../../../../AbstractElements";
import ImportBulk from "../../BrandSupportTools/ImportBulk/ImportBulk";
import Popup from "../../../../Component/MasterPopup/Popup";
import { useState } from "react";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";
const AddAddonCategories = () => {
 const [openImportModal, setOpenImportModal] = useState(false);
    const openImportModalToggle = () => setOpenImportModal(!openImportModal);
  return (
    <div className="page-body">
      <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                <SubMenuPopup  currentPage="New Addon"/>
                </div>
      <Breadcrumbs
        mainTitle={`${NewAddonCategory} (Manage Store)`}
        mainParent="All Tools"
        parent={ManageOnlineStoreTitle}
      /> 
            </div>
      <Container  fluid>
      <CardHeader className='pb-0'> 
               
                <div>
      <Btn onClick={openImportModalToggle} className='p-0 child_btn border rounded'   ><span  >Import Bulk Addons</span></Btn>

                </div>
              </CardHeader> 
        <Card className=" p-2">  
          <AddonCategoriesMain/>
        </Card>
        <Popup
                title='Import Bulk Addons'
                openModalToggle={openImportModalToggle}
                isOpen={openImportModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup>
      </Container>
    </div>
  );
};

export default AddAddonCategories;
