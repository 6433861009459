import ContactsContainer from "../../../Component/Application/Contacts/Contacts";
import NotificationScreenContainer from "../../../Component/Application/NotificationScreen/NotificationScreenContain";

const NotificationScreen = () => {
  return (
    <div className="page-body">
      <NotificationScreenContainer />
    </div>
  );
};

export default NotificationScreen;
