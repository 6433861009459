import { apiSlice } from "../../apiSlice";
import { CATEGORY_ALL } from "../../../Api";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubCategoryData: builder.mutation<SuccessDataProps, void>({
      query: () => ({
        url: CATEGORY_ALL,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetSubCategoryDataMutation } = categoryApiSlice;