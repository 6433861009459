import React, { useState } from 'react';
import { CardHeader, Col, Collapse, Input, Label, Row } from 'reactstrap'; 
import PhotoContestContent from './PhotoContestContent';
import ProductStockContent from './ProductStockContent';
import { H3, H5, LI, UL } from '../../../AbstractElements';
import PhotoStock from './PhotoStock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

const UploadData: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const [isOpen, setIsOpen] = useState(true);
  // Function to toggle tabs
  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  };
  const tabNotes: Record<string, string[]> = {
    '1': [
      "Pending Content: Admin's approval is required for each of your uploaded contents, before displaying it to users. Check your Media Profile >>  'Pending Photos'  page",
      "Please read the terms and conditions to avoid sanctions",
      "Daily maximum number of files to upload: (100)",
      "Daily upload files left: (100)",
      "Not allowed to upload files of violence or pornographic content of any kind",
      "File must be of Authoring"
    ],
    '3': [
      "Pending Content: Admin's approval is required for each of your uploaded contents, before displaying it to users. Check your Media Profile >>  'Pending Photos'  page.",
      "Please read the terms and conditions to avoid sanctions",
      "Not allowed to upload files of violence or pornographic content of any kind",
      "File must be of Authoring"
    ],
    '4': [
      "Pending Content: Admin's approval is required for each of your uploaded contents, before displaying it to users. Check your Media Profile >>  'Pending Photos'  page.",
      "Please read the terms and conditions to avoid sanctions",
      "Daily maximum number of files to upload: (100)",
      "Daily upload files left: (100)",
      "Not allowed to upload files of violence or pornographic content of any kind ",
      "File must be of Authoring",
    ]
  };
  return (
    <div className="product-wrapper-grid upload">
     <div className="my-3 border border-danger rounded">
      {/* Accordion Header */}
      <div
        className=" p-2 d-flex justify-content-between align-items-center"
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: 'pointer' }}
      >
        <p className="mb-0">
          <i className="bi-exclamation-triangle me-1 text-warning"></i>
          <span> These attributes will be applied to all the uploaded images.</span>
        </p>
        <FontAwesomeIcon
          icon={isOpen ? fas.faChevronUp : fas.faChevronDown}
          className="text-danger"
        />
      </div>

      {/* Accordion Content (Collapsible List) */}
      <Collapse isOpen={isOpen}>
          <div className=" rounded-bottom p-2">
            <UL className="list-content">
              {/* Use tabNotes based on activeTab */}
              {(tabNotes[activeTab] && tabNotes[activeTab].length > 0) ? (
                tabNotes[activeTab].map((note, index) => (
                  <LI key={index}>
                    <FontAwesomeIcon icon={fas.faCircle} className="pe-2" size="xs" />
                    {note}
                  </LI>
                ))
              ) : (
                <LI>No notes available</LI>
              )}
            </UL>
          </div>
        </Collapse>
    </div>
  
      <CardHeader className="ps-2 pt-2">
                <H3>{'Upload Contents (Step 1)'}</H3>
              </CardHeader>
      <Row className='ms-1 ms-md-4 mt-3'>
        <Col md="12" className='d-flex align-items-start'>
          <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
            <Input
              id="radioinline_upload"
              type="radio"
              name="radio_upload"
              onChange={() => handleTabChange("1")}
              checked={activeTab === "1"}
            />
            <Label className="mb-0" htmlFor="radioinline_upload" check>
              <H5>{`Social Media `}</H5>
              <small>{`(view only, cannot be downloaded by others)`}</small>
            </Label>
          </div>

          <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
            <Input
              id="radioinline_uploadd"
              type="radio"
              name="radio_upload"
              onChange={() => handleTabChange("3")}
              checked={activeTab === "3"}
            />
            <Label className="mb-0" htmlFor="radioinline_uploadd" check>
              <H5>{`Participate in a Photo Contest`}</H5>
            </Label>
          </div>

          <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
            <Input
              id="radioinline_uploadds"
              type="radio"
              name="radio_upload"
              onChange={() => handleTabChange("4")}
              checked={activeTab === "4"}
            />
            <Label className="mb-0" htmlFor="radioinline_uploadds" check>
              <H5>{`Photo Stock `}</H5>
              <span><small>{`(can be downloaded and used by others)`}</small></span>
            </Label>
          </div>
        </Col>
      </Row>

      <Row>
        <div className="tab-content">
          {activeTab === "1" && <ProductStockContent form="d-none" />}
          {activeTab === "3" && <PhotoContestContent />}
          {activeTab === "4" && <PhotoStock />}
        </div>
      </Row>
    </div>
  );
};

export default UploadData;
