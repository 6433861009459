import { useState } from "react";
import {
    Row,
    Col,
    Label,
    FormGroup,
    Button,
    Input,
    CardBody,
    Table,
    Card,
} from "reactstrap";
import { Btn, H3, H4, H5, H6 } from "../../../../AbstractElements";
import CommonSwitchSpan from "../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import SubCategory from "../../../CommonInput/SubCategory";
import CommonModal from "../../../../Component/Ui-Kits/Modal/Common/CommonModal";
import {
    ErrorMessage,
    Field,
    Form,
    FieldArray,
    useFormikContext,
    Formik,
} from "formik";
import StudyMaterialLessonMain from "../StudyMaterialLesson/StudyMaterialLessonMain";
import QuizMaterialMain from "../QuizMaterial/QuizMaterialMain";
import TextCounter from "../../../CommonInput/TextCounter";
import AlternativeQuestions from "../../../CommonInput/AlternativeQuestions";
import AnswerInput from "../../../CommonInput/AnswerInput";
import CorrectAnswerField from "./CorrectAnswerField";
import { X } from "react-feather";
import AddAnswersMain from "./AddAnswersMain";
import TestOrQuizSettingsForm from "../SurveyAndQuizzes/TestOrQuizSettingsForm";
import {
    scheduleFormInitialValue,
    scheduleFormValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import LessonField from "../../../CommonInput/LessonField";
import {
    questionFormInitialValue,
    QuestionFormValidationProp,
    questionFormValidationSchema,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import DescriptionInput from "../../../CommonInput/DescriptionInput";
import TitleInput from "../../../CommonInput/TitleInput";
import { AddNewIdeaStoryAlbum } from "../../../../utils/Constant";
import Popup from "../../../MasterPopup/Popup";
import ServerErrorMessages from "../../../CommonMessage/ServerErrorMessages";
import AddManageMyAlbumsForm from "../../ManageContents/ManageMyAlbums/AddManageMyAlbumsForm";
import {
    manageAlbumFormInitialValue,
    manageAlbumFormValidationSchema,
} from "../../../../Data/Tools/ManageContents/FormControlsValidation";
import AddQuestionGroup, {
    QuestioninitialValues,
    QuestionvalidationSchema,
} from "../TabBrandSupport/AddQuestionGroup";
import AddQuizMaterial from "./AddQuizMaterial";
import AddQuestionGroupModal from "./AddQuestionGroupModal";
import CustomSelect from "../../../CommonInput/CustomSelect";

const AddQuestionsForm = (props: any) => {
    const [currectAnswerCount, setCurrectAnswerCount] = useState(["1"]);
    const [openQuizzModal, setOpenQuizzModal] = useState(false);
    const [openQestionModal, setOpenQestionModal] = useState(false);
    const [currecQuestionCount, setCurrectcurrecQuestionCount] = useState([
        "0",
    ]);
    const [errorMessages, setErrorMessages] = useState<any>();
    const [showPhraseSection, setShowPhraseSection] = useState(false);

    const addCurrecQuestionCountHandler = (arrayHelpers: any) => {
        const value = currecQuestionCount.length + 1;
        console.log(value);

        setCurrectcurrecQuestionCount((oldArray) => [
            ...oldArray,
            value.toString(),
        ]);
        arrayHelpers.push("");
    };

    const removeCurrecQuestionCountHandler = (
        value: string,
        arrayHelpers: any,
        index: number
    ) => {
        const updatedFormCount = currecQuestionCount.filter(
            (item) => item !== value
        );
        setCurrectcurrecQuestionCount(updatedFormCount);
        arrayHelpers.remove(index);
    };

    // Quiz Material popup.
    const [albumType, setAlbumType] = useState("1");
    const [openModal, setOpenModal] = useState(false);
    const openModalToggle = () => setOpenModal(!openModal);

    // Study Material & Lesson popup.
    const [openSMLModal, setOpenSMLModal] = useState(false);
    const openSMLModalToggle = () => setOpenSMLModal(!openSMLModal);

    const {
        errors,
        submitErrors,
        setSubmitError,
        formtype,
        setFieldValue,
        values,
        testquizsetting,
        handleChange,
        handleBlur,
    } = props;
    const handleSubmit = (values: QuestionFormValidationProp) => {
        setSubmitError(false);
    };
    const [showOptions, setShowOptions] = useState(true);
    const [showinputanswer, setshowinputanswer] = useState(false);
    const openQuizzModalToggle = () => setOpenQuizzModal(!openQuizzModal);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
    // Toggle function for the answer type options
    // const { setFieldValue } = useFormikContext();
    const [selectedNegativeMarking, setSelectedNegativeMarking] = useState(
        values.negativeMakingOptional === "zero" ? true : false
    );
    const handleNegativeMarkingChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        setSelectedNegativeMarking(!selectedNegativeMarking);
        // setSelectedNegativeMarking(value); // Update the state with the selected value
        setFieldValue("negativeMakingOptional", value); // Update Formik field value
        setFieldValue("pointGrade", value === "negative-grade" ? -5 : 0); // Set point grade based on selection
        console.log(value);
    };
    const [
        selectedNegativeMarkingObligator,
        setSelectedNegativeMarkingObligator,
    ] = useState("zero1");
    const jobLocation = [
        { value: "remote", label: "Remote" },
        { value: "onsite", label: "Onsite" },
        { value: "hybrid", label: "Hybrid" },
        { value: "usa", label: "USA" },
        { value: "uk", label: "UK" },
        { value: "canada", label: "Canada" },
    ];
    return (
        <>
            <Form className="form-wizard">
            <FieldArray
                    name="answers"
                    render={(arrayHelpers) => {
                        return (
                            <>
                                {currecQuestionCount.map(
                                    (questionItem, questionIndex) => (
                                        <>
                                            <>
                                                <H4 className="my-2 text-muted">{`Add Question: ${
                                                    questionIndex + 1
                                                }`}</H4>
                                                <Row>
                                                    <Col sm="6">
                                                        <div>
                                                            <H4 className="my-2 text-muted">
                                                                Quiz Material
                                                                (optional)
                                                            </H4>
                                                            <Col
                                                                sm="12"
                                                                className=" ps-0"
                                                            >
                                                                <Label check>
                                                                    (Is the
                                                                    participant
                                                                    required to
                                                                    provide
                                                                    answers
                                                                    based on a
                                                                    specific
                                                                    quiz
                                                                    material (a
                                                                    text, video
                                                                    or audio)?).
                                                                </Label>

                                                                <div className="ms-2 mb-2 text-start align-items-center">
                                                                    <div
                                                                        sm="12"
                                                                        className=""
                                                                    >
                                                                        <Label
                                                                            check
                                                                        >
                                                                            Quiz
                                                                            Material{" "}
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>
                                                                        </Label>
                                                                        <div className="">
                                                                            <FormGroup className="mb-0">
                                                                                <Input type="select">
                                                                                    <option>{`Select`}</option>
                                                                                    <option>
                                                                                        Free
                                                                                        for
                                                                                        Commercial
                                                                                        and
                                                                                        Non-profit
                                                                                        use
                                                                                    </option>

                                                                                    <option>
                                                                                        Free
                                                                                        for
                                                                                        Non-Profit
                                                                                        use
                                                                                        only
                                                                                    </option>
                                                                                    {/* <option>View Only. No right to download or use. </option>
                            <option>Display only on shopping pages. Not for social media usages</option> */}
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        sm="12"
                                                                        className="mt-2"
                                                                    >
                                                                        <Btn
                                                                            className="ps-1 d-flex"
                                                                            onClick={
                                                                                openQuizzModalToggle
                                                                            }
                                                                        >
                                                                            <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                                                            Add
                                                                            a
                                                                            Quiz
                                                                            Material
                                                                        </Btn>
                                                                    </div>
                                                                </div>

                                                                <Row>
                                                                    {values.quizmaterialanswerInput ===
                                                                        "Existing Quiz Material" && (
                                                                        <>
                                                                            <Formik
                                                                                initialValues={
                                                                                    scheduleFormInitialValue
                                                                                }
                                                                                onSubmit={
                                                                                    handleSubmit
                                                                                }
                                                                                validationSchema={
                                                                                    scheduleFormValidationSchema
                                                                                }
                                                                            >
                                                                                {({
                                                                                    errors,
                                                                                }) => (
                                                                                    <Form className="form-wizard">
                                                                                        <Row className="align-items-center">
                                                                                            <Col
                                                                                                sm="12"
                                                                                                className="mb-3"
                                                                                            >
                                                                                                <LessonField
                                                                                                    name="branch"
                                                                                                    isMulti={
                                                                                                        true
                                                                                                    }
                                                                                                    placeholder="Select"
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Form>
                                                                                )}
                                                                            </Formik>
                                                                        </>
                                                                    )}
                                                                    {values.quizmaterialanswerInput ===
                                                                        "Create a New Quiz Material" && (
                                                                        <>
                                                                            <Col
                                                                                sm="4"
                                                                                className=" "
                                                                            >
                                                                                <TitleInput
                                                                                    title="Quiz Material Name"
                                                                                    values={
                                                                                        values.quizmaterialtitle
                                                                                    }
                                                                                    name="quizmaterialtitle"
                                                                                    placeholder="Enter a Quiz Material Name"
                                                                                />
                                                                            </Col>
                                                                            <Col sm="4">
                                                                                <DescriptionInput
                                                                                    name="quizmaterialdescription"
                                                                                    placeholder="Enter a Quiz Material Text"
                                                                                    values={
                                                                                        values.quizmaterialdescription
                                                                                    }
                                                                                    lable="Quiz Material Text"
                                                                                />
                                                                            </Col>
                                                                            <Col sm="4">
                                                                                <FormGroup>
                                                                                    <Label
                                                                                        htmlFor="audioFile"
                                                                                        className=""
                                                                                    >
                                                                                        Audio
                                                                                        Material
                                                                                    </Label>
                                                                                    <input
                                                                                        id="audioFile"
                                                                                        name="audioFile"
                                                                                        type="file"
                                                                                        accept="audio/*"
                                                                                        className="form-control"
                                                                                        onChange={(
                                                                                            event
                                                                                        ) => {
                                                                                            if (
                                                                                                event
                                                                                                    .currentTarget
                                                                                                    .files
                                                                                            ) {
                                                                                                setFieldValue(
                                                                                                    "audioFile",
                                                                                                    event
                                                                                                        .currentTarget
                                                                                                        .files[0]
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col sm="4">
                                                                                <FormGroup>
                                                                                    <Label htmlFor="videoURL">
                                                                                        Video
                                                                                        URL
                                                                                    </Label>
                                                                                    <Field
                                                                                        id="videoURL"
                                                                                        name="videoURL"
                                                                                        type="url"
                                                                                        placeholder="Enter video URL"
                                                                                        className="form-control"
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <H4 className="my-2 text-muted">{`A Group of Related Questions (optional)`}</H4>
                                                        <Col
                                                            sm="12"
                                                            className="mb-3"
                                                        >
                                                            <Label check>
                                                                (to divide the
                                                                quiz to multiple
                                                                related groups,
                                                                example:
                                                                Grammar,
                                                                Reading,
                                                                Writing).
                                                            </Label>
                                                            <Row className="ms-2 mb-2 text-start align-items-center">
                                                                <Col
                                                                    sm="12"
                                                                    className="ps-0 "
                                                                >
                                                                    <Label
                                                                        check
                                                                    >
                                                                        Question
                                                                        Group{" "}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </Label>
                                                                    <div className="">
                                                                        <Input type="select">
                                                                            <option>{`Select`}</option>
                                                                            <option>
                                                                                Free
                                                                                for
                                                                                Commercial
                                                                                and
                                                                                Non-profit
                                                                                use
                                                                            </option>

                                                                            <option>
                                                                                Free
                                                                                for
                                                                                Non-Profit
                                                                                use
                                                                                only
                                                                            </option>
                                                                            {/* <option>View Only. No right to download or use. </option>
                            <option>Display only on shopping pages. Not for social media usages</option> */}
                                                                        </Input>
                                                                    </div>
                                                                </Col>
                                                                <Col
                                                                    sm="12"
                                                                    className="mt-2 ps-0"
                                                                >
                                                                    <Btn
                                                                        className="ps-1 d-flex"
                                                                        onClick={
                                                                            openQestionModalToggle
                                                                        }
                                                                    >
                                                                        <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                                                        Add a
                                                                        Question
                                                                        Group
                                                                    </Btn>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </>

                                            <Row className="justify-content-between">
                                                <Col sm="auto" className="mb-3">
                                                    {currecQuestionCount.length >
                                                        1 &&
                                                        questionIndex != 0 && (
                                                            <span
                                                                onClick={() =>
                                                                    removeCurrecQuestionCountHandler(
                                                                        questionItem,
                                                                        arrayHelpers,
                                                                        questionIndex
                                                                    )
                                                                }
                                                                className="badge b-ln-height badge-danger"
                                                            >
                                                                <i className="fa fa-minus"></i>
                                                            </span>
                                                        )}
                                                </Col>
                                            </Row>
                                            <H4 className="my-2 text-muted">
                                                Question Details
                                            </H4>
                                            <Row>
                                                <Col sm="4" className="mb-3">
                                                    <DescriptionInput
                                                        optional={true}
                                                        name="descquestionTextription"
                                                        placeholder="questionText"
                                                        values={
                                                            values.questionText
                                                        }
                                                        lable="Instruction What or How to Answer"
                                                    />
                                                </Col>
                                                <Col sm="4" className="mb-3">
                                                    <Label check>
                                                        Question Image
                                                        (optional)
                                                    </Label>
                                                    <FormGroup>
                                                        <Field
                                                            className="form-control"
                                                            name="questionImage"
                                                            type="file"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="4" className="mb-3">
                                                    <Label check>
                                                        Question Audio
                                                        (optional)
                                                    </Label>
                                                    <FormGroup>
                                                        <Field
                                                            className="form-control"
                                                            name="questionAudio"
                                                            type="file"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="12" className="mb-3">
                                                    <Label check>
                                                        Question Type
                                                    </Label>
                                                    <Row>
                                                        {/* Answering Type Options */}
                                                        <Col
                                                            sm="4"
                                                            className="mb-3 "
                                                        >
                                                            <FormGroup
                                                                className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                                                    values.answerInput ===
                                                                    "multiple-choice"
                                                                        ? "transform-scale"
                                                                        : ""
                                                                }`}
                                                                check
                                                            >
                                                                <Label
                                                                    className={`mb-0 ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.formType
                                                                                ? "text-danger"
                                                                                : "text-success"
                                                                        }`
                                                                    }`}
                                                                    for="select4Options"
                                                                    check
                                                                >
                                                                    <Input
                                                                        id="select4Options"
                                                                        name="answerInput"
                                                                        value="multiple-choice"
                                                                        type="checkbox"
                                                                        onChange={() => {
                                                                            setFieldValue(
                                                                                "answerInput",
                                                                                "multiple-choice"
                                                                            );
                                                                            setShowPhraseSection(
                                                                                false
                                                                            );
                                                                            setShowOptions(
                                                                                true
                                                                            );
                                                                            setshowinputanswer(
                                                                                false
                                                                            );
                                                                        }}
                                                                        className="form-check-input"
                                                                    />
                                                                    <span className="ms-2">
                                                                        Selecting
                                                                        1 Option
                                                                        from
                                                                        Multiple
                                                                        Options
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col
                                                            sm="2"
                                                            className="mb-3  "
                                                        >
                                                            <FormGroup
                                                                className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                                                    values.answerInput ===
                                                                    "trueFalse"
                                                                        ? "transform-scale"
                                                                        : ""
                                                                }`}
                                                                check
                                                            >
                                                                <Label
                                                                    className={`mb-0 ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.formType
                                                                                ? "text-danger"
                                                                                : "text-success"
                                                                        }`
                                                                    }`}
                                                                    for="trueorfalse"
                                                                    check
                                                                >
                                                                    <Input
                                                                        id="trueorfalse"
                                                                        name="answerInput"
                                                                        value="trueFalse"
                                                                        type="checkbox"
                                                                        onChange={() => {
                                                                            setFieldValue(
                                                                                "answerInput",
                                                                                "trueFalse"
                                                                            );
                                                                            setShowPhraseSection(
                                                                                (
                                                                                    prev
                                                                                ) =>
                                                                                    !prev
                                                                            );
                                                                            setshowinputanswer(
                                                                                false
                                                                            );
                                                                            setShowOptions(
                                                                                false
                                                                            );
                                                                        }}
                                                                        className="form-check-input"
                                                                    />
                                                                    <span className="ms-2">
                                                                        False or
                                                                        True
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col
                                                            sm="2"
                                                            className="mb-3 "
                                                        >
                                                            <FormGroup
                                                                className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                                                    values.answerInput ===
                                                                    "text"
                                                                        ? "transform-scale"
                                                                        : ""
                                                                }`}
                                                                check
                                                            >
                                                                <Label
                                                                    className={`mb-0 ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.formType
                                                                                ? "text-danger"
                                                                                : "text-success"
                                                                        }`
                                                                    }`}
                                                                    for="textInput"
                                                                    check
                                                                >
                                                                    <Input
                                                                        id="textInput"
                                                                        name="answerInput"
                                                                        value="text"
                                                                        type="checkbox"
                                                                        onChange={() => {
                                                                            setFieldValue(
                                                                                "answerInput",
                                                                                "text"
                                                                            );
                                                                            setShowPhraseSection(
                                                                                false
                                                                            );
                                                                            setshowinputanswer(
                                                                                true
                                                                            );
                                                                            setShowOptions(
                                                                                false
                                                                            );
                                                                        }}
                                                                        className="form-check-input"
                                                                    />
                                                                    <span className="ms-2">
                                                                        {" "}
                                                                        Writing
                                                                        the
                                                                        Answer
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        {values.answerInput ===
                                                            "trueFalse" ||
                                                        values.answerInput ===
                                                            "multiple-choice" ? (
                                                            <Col
                                                                sm="4"
                                                                className="mb-3"
                                                            >
                                                                <Label check>
                                                                    After
                                                                    selecting an
                                                                    option,
                                                                    write an
                                                                    explanation
                                                                    of why the
                                                                    option was
                                                                    selected{" "}
                                                                    <span className="txt-danger">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                <br />
                                                                <div className="d-flex">
                                                                    <FormGroup
                                                                        className={`form-check form-check-inline popup_rediobtn radio-primary px-2 ${
                                                                            values.explanation ===
                                                                            "no"
                                                                                ? "transform-scale"
                                                                                : ""
                                                                        }`}
                                                                        check
                                                                    >
                                                                        <Label
                                                                            className={`mb-0 ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.formType
                                                                                        ? "text-danger"
                                                                                        : "text-success"
                                                                                }`
                                                                            }`}
                                                                            for="explanationInputNo"
                                                                            check
                                                                        >
                                                                            <Input
                                                                                id="explanationInputNo"
                                                                                name="explanation"
                                                                                value="no"
                                                                                type="checkbox"
                                                                                onChange={() => {
                                                                                    setFieldValue(
                                                                                        "explanation",
                                                                                        "no"
                                                                                    );
                                                                                    // setShowPhraseSection(false)
                                                                                    // setshowinputanswer(
                                                                                    //     true
                                                                                    // );
                                                                                    // setShowOptions(false);
                                                                                }}
                                                                                className="form-check-input"
                                                                            />
                                                                            <span className="ms-2">
                                                                                {" "}
                                                                                No
                                                                                (select
                                                                                only)
                                                                            </span>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        className={`form-check form-check-inline popup_rediobtn radio-primary px-2 ${
                                                                            values.explanation ===
                                                                            "yes"
                                                                                ? "transform-scale"
                                                                                : ""
                                                                        }`}
                                                                        check
                                                                    >
                                                                        <Label
                                                                            className={`mb-0 ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.formType
                                                                                        ? "text-danger"
                                                                                        : "text-success"
                                                                                }`
                                                                            }`}
                                                                            for="explanationInput"
                                                                            check
                                                                        >
                                                                            <Input
                                                                                id="explanationInput"
                                                                                name="explanation"
                                                                                value="yes"
                                                                                type="checkbox"
                                                                                onChange={() => {
                                                                                    setFieldValue(
                                                                                        "explanation",
                                                                                        "yes"
                                                                                    );
                                                                                    // setShowPhraseSection(false)
                                                                                    // setshowinputanswer(
                                                                                    //     true
                                                                                    // );
                                                                                    // setShowOptions(false);
                                                                                }}
                                                                                className="form-check-input"
                                                                            />
                                                                            <span className="ms-2">
                                                                                Yes
                                                                                (select
                                                                                and
                                                                                explain){" "}
                                                                            </span>
                                                                        </Label>
                                                                    </FormGroup>
                                                                </div>
                                                            </Col>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Row>
                                                    {showinputanswer && (
                                                        <>
                                                            <div className="d-flex flex-column gap-3">
                                                                <FieldArray name="phrasesinput">
                                                                    {(
                                                                        arrayHelpers
                                                                    ) => (
                                                                        <>
                                                                            {values.phrasesinput.map(
                                                                                (
                                                                                    phrase,
                                                                                    index
                                                                                ) => (
                                                                                    <Card
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        className="shadow-sm p-2 w-100 mb-1"
                                                                                    >
                                                                                        <CardBody className="d-flex align-items-center gap-3 p-0 w-100">
                                                                                            {/* Numbering */}
                                                                                            <div
                                                                                                className="text-center"
                                                                                                style={{
                                                                                                    width: "40px",
                                                                                                }}
                                                                                            >
                                                                                                <strong>
                                                                                                    {index +
                                                                                                        1}
                                                                                                </strong>
                                                                                            </div>

                                                                                            {/* Question Input */}
                                                                                            <DescriptionInput
                                                                                                optional={
                                                                                                    true
                                                                                                }
                                                                                                name={`phrasesinput[${index}].text`}
                                                                                                placeholder="Write a question"
                                                                                                values={`phrasesinput[${index}].text`}
                                                                                            />

                                                                                            {/* Correct Answer Input */}
                                                                                            <DescriptionInput
                                                                                                optional={
                                                                                                    true
                                                                                                }
                                                                                                name={`phrasesinput[${index}].correct`}
                                                                                                placeholder="Correct Answer with Explanation (optional)"
                                                                                                values={`phrasesinput[${index}].correct`}
                                                                                            />

                                                                                            {/* Action Buttons */}
                                                                                            <div className="align-items-center">
                                                                                                {index ===
                                                                                                    values
                                                                                                        .phrasesinput
                                                                                                        .length -
                                                                                                        1 && (
                                                                                                    <Button
                                                                                                        color="dark"
                                                                                                        outline
                                                                                                        className="ms-2 mb-1"
                                                                                                        onClick={() =>
                                                                                                            arrayHelpers.push(
                                                                                                                {
                                                                                                                    text: "",
                                                                                                                    correct:
                                                                                                                        "",
                                                                                                                }
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <i className="fa fa-plus"></i>
                                                                                                    </Button>
                                                                                                )}
                                                                                                {values
                                                                                                    .phrasesinput
                                                                                                    .length >
                                                                                                    1 && (
                                                                                                    <Button
                                                                                                        color="danger"
                                                                                                        size="sm"
                                                                                                        className="ms-2"
                                                                                                        onClick={() =>
                                                                                                            arrayHelpers.remove(
                                                                                                                index
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <i className="fa fa-trash"></i>
                                                                                                    </Button>
                                                                                                )}
                                                                                            </div>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                )
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </FieldArray>
                                                            </div>
                                                            <Row>
                                                                <Col
                                                                    sm="4"
                                                                    className="mb-3"
                                                                >
                                                                    <Label
                                                                        check
                                                                    >
                                                                        Answer
                                                                        Input{" "}
                                                                        <span className="txt-danger">
                                                                            *
                                                                        </span>
                                                                    </Label>
                                                                    <AnswerInput
                                                                        name="answerInput"
                                                                        className={`form-control ${
                                                                            submitErrors &&
                                                                            `${
                                                                                errors.answerInput
                                                                                    ? "is-invalid"
                                                                                    : "is-valid"
                                                                            }`
                                                                        }`}
                                                                    />
                                                                    <ErrorMessage
                                                                        name="answerInput"
                                                                        component="span"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label
                                                                            check
                                                                        >
                                                                            Maximum
                                                                            Number
                                                                            of
                                                                            Characters
                                                                            (optional)
                                                                        </Label>
                                                                        <Field
                                                                            type="number"
                                                                            name="maxCharacters"
                                                                            placeholder="Enter desired budget"
                                                                            className={`form-control ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.desired_budget
                                                                                        ? "is-invalid"
                                                                                        : "is-valid"
                                                                                }`
                                                                            }`}
                                                                        />

                                                                        <ErrorMessage
                                                                            name="maxCharacters"
                                                                            component="span"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                    {/* Conditionally render options if 'Select 1 option from 4 Options' is selected */}
                                                    {showOptions && (
                                                        <>
                                                            <AddAnswersMain
                                                                values={values}
                                                                showOptions={
                                                                    showOptions
                                                                }
                                                            />
                                                        </>
                                                    )}

                                                    {showPhraseSection && (
                                                        <div className="d-flex flex-column gap-3">
                                                            <FieldArray name="phrases">
                                                                {(
                                                                    arrayHelpers
                                                                ) => (
                                                                    <>
                                                                        {values.phrases.map(
                                                                            (
                                                                                phrase,
                                                                                index
                                                                            ) => (
                                                                                <Card
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className="shadow-sm p-2 w-100 mb-1"
                                                                                >
                                                                                    <CardBody className="d-flex align-items-center gap-3 p-0 w-100">
                                                                                        {/* Numbering */}
                                                                                        <div
                                                                                            className="text-center"
                                                                                            style={{
                                                                                                width: "40px",
                                                                                            }}
                                                                                        >
                                                                                            <strong>
                                                                                                {index +
                                                                                                    1}
                                                                                            </strong>
                                                                                        </div>

                                                                                        {/* Question Input */}
                                                                                        <DescriptionInput
                                                                                            optional={
                                                                                                true
                                                                                            }
                                                                                            name={`phrases[${index}].text`}
                                                                                            placeholder="Write a Phrase"
                                                                                            values={`phrases[${index}].text`}
                                                                                        />
                                                                                        <div className="flex-shrink-0 text-center icon-state switch-outline">
                                                                                            <p className="mb-0">
                                                                                                False/True
                                                                                            </p>
                                                                                            <Field
                                                                                                name={`phrases[${index}].yesNo`}
                                                                                                render={({
                                                                                                    field,
                                                                                                    form,
                                                                                                }: any) => (
                                                                                                    <CommonSwitchSpan
                                                                                                        {...field}
                                                                                                        color="primary"
                                                                                                        checked={
                                                                                                            field.value
                                                                                                        } // Toggling between true and false
                                                                                                        onChange={() => {
                                                                                                            // Toggle the value between true and false
                                                                                                            form.setFieldValue(
                                                                                                                field.name,
                                                                                                                !field.value
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </div>
                                                                                        {/* Correct Answer Input */}
                                                                                        <DescriptionInput
                                                                                            optional={
                                                                                                true
                                                                                            }
                                                                                            name={`phrases[${index}].correct`}
                                                                                            placeholder="Correct Answer with Explanation"
                                                                                            values={`phrases[${index}].text`}
                                                                                        />

                                                                                        {/* Action Buttons */}
                                                                                        <div className="  align-items-center">
                                                                                            {index ===
                                                                                                values
                                                                                                    .phrases
                                                                                                    .length -
                                                                                                    1 && (
                                                                                                <Button
                                                                                                    color="dark"
                                                                                                    outline
                                                                                                    className="ms-2 mb-1"
                                                                                                    onClick={() =>
                                                                                                        arrayHelpers.push(
                                                                                                            {
                                                                                                                text: "",
                                                                                                                yesNo: false,
                                                                                                                correct:
                                                                                                                    "",
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i className="fa fa-plus"></i>
                                                                                                </Button>
                                                                                            )}
                                                                                            {values
                                                                                                .phrases
                                                                                                .length >
                                                                                                1 && (
                                                                                                <Button
                                                                                                    color="danger"
                                                                                                    size="sm"
                                                                                                    className="ms-2"
                                                                                                    onClick={() =>
                                                                                                        arrayHelpers.remove(
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i className="fa fa-trash"></i>
                                                                                                </Button>
                                                                                            )}
                                                                                        </div>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            )
                                                                        )}
                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>
                                                {(values.answerInput !==
                                                    "multiple-choice" &&
                                                    values.answerInput !==
                                                        "trueFalse") ||
                                                values.explanation === "yes" ? (
                                                    <Col
                                                        sm="4"
                                                        className="mb-3 theme-form"
                                                    >
                                                        <Label check>
                                                            Point/Grade of the
                                                            Written Answer
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                        </Label>
                                                        <Field
                                                            type="text"
                                                            name="pointGrade"
                                                            placeholder="Point/Grade of the Written Answer "
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.pointGrade
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                        />
                                                        <ErrorMessage
                                                            name="pointGrade"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                    </Col>
                                                ) : null}
                                                {values.answerInput !=
                                                    "text" && (
                                                    <Col
                                                        sm="4"
                                                        className="mb-3 theme-form"
                                                    >
                                                        <Label check>
                                                            Point/Grade for the
                                                            Right Selection
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                        </Label>
                                                        <Field
                                                            type="text"
                                                            name="pointGrade"
                                                            placeholder="Point/Grade for the Right Selection"
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.pointGrade
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                        />
                                                        <ErrorMessage
                                                            name="pointGrade"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                    </Col>
                                                )}
                                                <Col sm="4" className="mb-3">
                                                    <Label check>
                                                        Obligatory Question{" "}
                                                        <span className="txt-danger">
                                                            *
                                                        </span>
                                                    </Label>
                                                    <br />
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                            <CommonSwitchSpan
                                                                color={
                                                                    "primary"
                                                                }
                                                                name="obligationQuestion"
                                                                className={`form-control ${
                                                                    submitErrors &&
                                                                    `${
                                                                        errors.obligationQuestion
                                                                            ? "is-invalid"
                                                                            : "is-valid"
                                                                    }`
                                                                }`}
                                                                defaultChecked
                                                            />
                                                        </div>
                                                        <Label
                                                            className="m-l-10"
                                                            check
                                                        ></Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-end">
                                                <Col sm="4" className="mb-3">
                                                    <Label check>
                                                        Alternative Questions
                                                        (optional)
                                                    </Label>
                                                    <AlternativeQuestions
                                                        name="aleternativeQuestions"
                                                        isMulti={true}
                                                        placeholder="Please select alternative questions"
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                )}
                                <Col sm="4" className="mb-3">
                                    <Btn
                                        color="secondary"
                                        type="button"
                                        onClick={() =>
                                            addCurrecQuestionCountHandler(
                                                arrayHelpers
                                            )
                                        }
                                    >
                                        Add another Question
                                    </Btn>
                                </Col>
                            </>
                        );
                    }}
                />
            </Form>

            <Popup
                title="Add a Question Group"
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                BodyClassName="pt-1"
                largesize="modal-custom"
            >
                <AddQuestionGroupModal />
            </Popup>
            <Popup
                title="Add a Quiz Material"
                openModalToggle={openQuizzModalToggle}
                isOpen={openQuizzModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                BodyClassName="pt-1"
                largesize="modal-custom"
            >
                <AddQuizMaterial />
            </Popup>
        </>
    );
};

export default AddQuestionsForm;
