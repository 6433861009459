import { Field, Formik } from 'formik';
import React from 'react'
import { questionFormInitialValue, QuestionFormValidationProp, questionFormValidationSchema } from '../../../../Data/Tools/BrandSupportTools/FormControlsValidation';
import { CardBody, Col, FormGroup, Label, Row } from 'reactstrap';
import TitleInput from '../../../CommonInput/TitleInput';
import DescriptionInput from '../../../CommonInput/DescriptionInput';

function AddQuizMaterial() {
    const handleSubmit = (values: QuestionFormValidationProp) => { 
             console.log(values);
             
        };
  return (
    <>
     <Formik
                initialValues={questionFormInitialValue}
                onSubmit={handleSubmit}
                validationSchema={questionFormValidationSchema}
              >
                {({ errors, values, setFieldValue }) => ( // <-- Access setFieldValue here
                  <CardBody className='p-2'>
                    <Row>
                      <Col sm="12">
                        <TitleInput
                          title='Unique Quiz Material Title'
                          values={values.quizmaterialtitle}
                          name="quizmaterialtitle"
                          placeholder="Enter a Quiz Material Name"
                        />
                      </Col> 
                      <Col sm="12">
                        <DescriptionInput
                          name='quizmaterialdescription'
                          placeholder="Enter a Quiz Material Text"
                          values={values.quizmaterialdescription}
                          lable='Quiz Material Text'
                        />
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="audioFile">Audio Material</Label>
                          <input
                            id="audioFile"
                            name="audioFile"
                            type="file"
                            accept="audio/*"
                            className="form-control"
                            onChange={(event) => {
                              if (event.currentTarget.files) {
                                setFieldValue("audioFile", event.currentTarget.files[0]);
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="videoURL">Video URL</Label>
                          <Field
                            id="videoURL"
                            name="videoURL"
                            type="url"
                            placeholder="Enter video URL"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                )}
              </Formik>
    </>
  )
}

export default AddQuizMaterial