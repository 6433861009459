import { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
    ProductServicesColumnData,
    supportTicketData,
} from "../../../../../Data/Tools/BrandSupportTools/ButtonActions";
import {
    ManageOnlineStore,
    ManageProductServicesAction,
} from "../../../../../Data/Tools/ManageContents/ManageContentButtons";
import ManageOnlineSubMenu from "../../ManageOnlineSubMenu/ManageOnlineSubMenu";
import ProductServiceSubMenu from "./ProductServiceSubMenu";
import Popup from "../../../../../Component/MasterPopup/Popup";
import ImportBulk from "../../../BrandSupportTools/ImportBulk/ImportBulk";
import BulkProductImages, { ProductvalidationSchema } from "./BulkProductImages";
import { Formik } from "formik";
import { FequentlyinitialValues, FequentlyValidationSchema } from "../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import FequentlyModalForm from "../../../BrandSupportTools/FequentlyQuestion/FequentlyModalForm";
import SubMenuPopup from "../../../../../Layout/Sidebar/ManageStorePopup";

const ManageProductServices = () => {
    const toggleDelete = useState(false);
    const [data, setData] = useState(supportTicketData);
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const handleRowSelected = () => {};
    const [openQestionModal, setOpenQestionModal] = useState(false);
    const [openImagesModal, setopenImagesModal] = useState(false);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
    const openImagesModalToggle = () => setopenImagesModal(!openImagesModal);
    const [openpublishModal, setOpenpublishModal] = useState(false);
    const openpublishModalToggle = () => setOpenpublishModal(!openpublishModal);
    const [openProductModal, setOpenProductModal] = useState(false);
    const openProductModalToggle = () => setOpenProductModal(!openProductModal);
    return (
        <div className="page-body">
             <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    
                <SubMenuPopup currentPage="Product and Service List "/>
                </div>
               
            <Breadcrumbs
                mainTitle={`Manage Products and Services (Manage Store)`}
                parent={`Manage Store`}
                mainParent={`All Tools`}
            />
            </div>
            
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <div className="mt-2">
                                <ProductServiceSubMenu openProductModalToggle={openProductModalToggle}  openpublishModalToggle={openpublishModalToggle}/>
                            </div>
                            <CardBody className="p-2">
                                <>
                                    <div className="table-responsive ">
                                        <DataTable
                                            className="custom-scrollbar"
                                            columns={ProductServicesColumnData}
                                            data={data}
                                            striped={true}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={
                                                handleRowSelected
                                            }
                                            clearSelectedRows={toggleDelete}
                                        />
                                    </div>
                                </>
                            </CardBody>
                        </Card>
                        <Popup
                title='Import Bulk Branches'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup>
            <Popup
                title='Import Bulk Product Images'
                openModalToggle={openImagesModalToggle}
                isOpen={openImagesModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                    <Formik
    initialValues={{ 
        additionalImages: [],
    }}
    validationSchema={ProductvalidationSchema}
    onSubmit={(values) => {
        // Handle form submission.
        console.log(values);
    }}
>
    {({ setFieldValue, errors, touched }) => (
        <BulkProductImages name="bulkproductImages" setFieldValue={setFieldValue} errors={errors} touched={touched} />
    )}
</Formik>

                    </Popup>
                    <Popup
                title='Publish Online'
                openModalToggle={openpublishModalToggle}
                isOpen={openpublishModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                  <Formik
                        initialValues={FequentlyinitialValues}
                        validationSchema={FequentlyValidationSchema}
                        onSubmit={(values) => {
                          console.log("Selected values:", values);
                        }}
                      >
                        {({ handleChange, values,errors ,setFieldValue}) => ( 
               <FequentlyModalForm selectField='product' setFieldValue={setFieldValue} selectField="product" handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
              )}
    </Formik>
 
            </Popup>
            {/* <Popup
                title='Import Bulk '
                openModalToggle={openProductModalToggle}
                isOpen={openProductModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup> */}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ManageProductServices;
