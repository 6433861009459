
import React, { useState } from "react";
import { CardHeader, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Btn, H3 } from "../../../AbstractElements";
import { Field, Formik } from "formik";
import { createPostSchema } from "../../../Data/Post/CreatePostValidationSchema";
import CustomSelect from "../../CommonInput/CustomSelect";
import TagsInput from "../../CommonInput/TagsInput";
import DescriptionInput from "../../CommonInput/DescriptionInput";

interface MyComponentProps {
    popup: boolean;
    heading: boolean;
    responseData?: { image: string; description?: string }[];
}

// Function to initialize blank values for each form
const createNewInitialValue = () => ({
    category: [],
    description: "",
    keywords: [],
});

const AddDetails: React.FC<MyComponentProps> = ({ popup, heading, responseData = [] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [formData, setFormData] = useState<any[]>([]); // Array to store form data for each step

    const cityOptions = [
        {
            label: "Free for Commercial and Non-profit use",
            value: "Free for Commercial and Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];

    // Handle next form button click
    const handleNext = (values: any, setValues: any) => {
        // Save the current form values before moving to the next form
        setFormData((prevData) => [...prevData, values]); // Add the current form data to the array

        setValues(createNewInitialValue()); // Reset form values for the next form
        setCurrentIndex(currentIndex + 1); // Move to the next form
    };
    const handleSave = (values: any) => {
        setFormData((prevData) => [...prevData, values]); // Save the last form data 
        setValues(createNewInitialValue());
    };
    // Handle previous form button click
    const handlePrevious = (values: any, setValues: any) => {
        setFormData((prevData) => prevData.slice(0, -1)); // Remove the last added form data (going back)
        setCurrentIndex(currentIndex - 1); // Move to the previous form
        setValues(createNewInitialValue()); // Reset form values for previous form
    };

    // Get the current form data for the selected image
    const currentData = responseData.length > 0 ? responseData[currentIndex] : null;

    if (!currentData) {
        return (
            <div className="text-center mt-3">
                <H3>No data available</H3>
            </div>
        );
    }

    return (
        <div className={`position-relative mb-3 ${popup ? "p-4" : ""}`}>
            {heading && (
                <CardHeader className="ps-0 pt-2">
                    <H3>{`Add Details (Step ${currentIndex + 1} of ${responseData.length})`}</H3>
                </CardHeader>
            )}
<div>
            <h4>Form Data:</h4>
            <pre>{JSON.stringify(formData, null, 2)}</pre>
        </div>
            <Row className="gap-3 mt-3">
                <Col md="5">
                    <div>
                        <img
                            src={currentData.image}
                            alt={`Image ${currentIndex + 1}`}
                            className="img-fluid"
                        />
                    </div>
                </Col>
                <Col md="6">
                    <Formik
                        initialValues={createNewInitialValue()} // Use blank initial values
                        onSubmit={(values) => {
                            console.log("Form Submitted:", values);
                        }}
                        validationSchema={createPostSchema} // Validation schema
                    >
                        {({ values, setFieldValue, handleSubmit, setValues }) => {
                            return (
                                <Form>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label>{`Category *:`}</Label>
                                                <Field
                                                    className="custom-select"
                                                    name="category"
                                                    options={cityOptions}
                                                    component={CustomSelect}
                                                    placeholder="Category"
                                                    value={values.category}
                                                    isMulti={true}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm="12" className="mb-3">
                                            <DescriptionInput
                                                name="description"
                                                placeholder="Description"
                                                values={values.description}
                                                label="Description"
                                            />
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label>{"Keywords:"}</Label>
                                                <TagsInput
                                                    name="keywords"
                                                    placeholder="Add keywords"
                                                    className="w-100"
                                                    setFieldValue={setFieldValue}
                                                    value={values.keywords}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <div className="d-flex justify-content-between mt-3">
                                        <Btn
                                            className="btn btn-secondary"
                                            onClick={() => handlePrevious(values, setValues)}
                                            disabled={currentIndex === 0}
                                        >
                                            Previous
                                        </Btn>
                                        {currentIndex === responseData.length - 1 ? (
                                            <Btn
                                                className="btn btn-success"
                                                onClick={() => handleSave(values, setValues)} // Save the last form data
                                            >
                                                Save
                                            </Btn>
                                        ) : (
                                            <Btn
                                                className="btn btn-primary"
                                                onClick={() => handleNext(values, setValues)}
                                                disabled={currentIndex === responseData.length - 1}
                                            >
                                                Next
                                            </Btn>
                                        )}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Col>
            </Row>
        </div>
    );
};

export default AddDetails;

