import { useState } from "react";
import { Card, CardBody, Col, Collapse, Container, Row } from "reactstrap";
import { PlanSummary } from "../../../../utils/Constant";
import CardHeaderCollapse from "../../../../CommonElements/CardHeaderCommon/CardHeaderCollapse";

function CurrentPlanSummary(props: any) {
  const [isPlanSummaryOpen, setIsPlanSummaryOpen] = useState(true);
  const { planSummary } = props;

  return (
    <Card key={`1`}>
      <CardHeaderCollapse
        title={PlanSummary}
        isOpen={isPlanSummaryOpen}
        setIsOpen={setIsPlanSummaryOpen}
      />
      <Collapse isOpen={isPlanSummaryOpen}>
        <CardBody className="bg_light">
          <Row className="p-3">
            {planSummary !== undefined &&
              Object.entries(planSummary).map(([key, value]: any) => {
                let title = key.replace(/_/g, " ");
                return (
                  <Col md="6" sm="6" key={key}>
                    <Row>
                      <Col md="5" className="col-6 p-2 text-capitalize">
                        {title} :{" "}
                      </Col>{" "}
                      <Col md="5" className="col-6 p-2">
                        {value}
                      </Col>
                    </Row>
                  </Col>
                );
              })}
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );
}

export default CurrentPlanSummary;
