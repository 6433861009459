import * as Yup from "yup";

export interface RenewSignupValidationProp {
  country_id: string;
  type: string;
  plan_id: string;
  pay_period: string;
  agreeTermsConditions: string;
}

export const signupFormInitialValue: RenewSignupValidationProp = {
  country_id: "",
  type: "",
  plan_id: "", 
  pay_period: "",
  agreeTermsConditions : "",
};

export const renewSignupFormSchema = Yup.object().shape({ 
  pay_period: Yup.string().required("Required"),
  country_id : Yup.string().required("Required"),
  plan_id: Yup.string().required("Required"),
  agreeTermsConditions : Yup.string().required("Required"),
});
