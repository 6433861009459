import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './SidebarResponsive.css'; // Ensure your CSS file is correctly styled
import { MenuList } from '../../Data/LayoutData/SidebarData';
import { H4, H5 } from '../../AbstractElements';
import { useAppDispatch, useAppSelector } from '../../ReduxToolkit/Hooks';
import { openModal, setActiveItem } from '../../ReduxToolkit/Reducers/Navigation/Navigation';

interface MenuItem {
  path?: string;
  title?: string;
  type: string;
  id?: number;
  icon?: IconDefinition;
  children?: MenuItem[];
  Items?: MenuItem[];
  subtitle?: string;
  description?: string;
  iconbi?: any;
}

// Extract items from "Marketplace" to "Tools" (inclusive)
const getItemsInRange = (): MenuItem[] => {
  const topLevelItems = MenuList[0]?.Items || [];

  // Find the index of "Marketplace" and "Tools"
  const startIndex = topLevelItems.findIndex(item => item.title === "Marketplace");
  const endIndex = topLevelItems.findIndex(item => item.title === "Tools") + 1;

  if (startIndex !== -1 && endIndex !== -1) {
    return topLevelItems.slice(startIndex, endIndex) as MenuItem[];
  }

  return [];
  
};

const SidebarResponsive: React.FC = () => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null); // Default: no dropdown open
  const items = getItemsInRange();
  const location = useLocation();
  const currentPath = location.pathname;
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [openSubDropdown, setSubDropdown] = React.useState<string | null>(null);
  const dispatch = useAppDispatch();
  const isActive = (path: string | undefined) => currentPath === path;

  const getActiveClass = (item: MenuItem) => {
    if (item.path && isActive(item.path)) {
      return 'active';
    }

    if (item.children) {
      const hasActiveChild = item.children.some(child => isActive(child.path) || (child.Items && child.Items.some(subItem => isActive(subItem.path))));
      return hasActiveChild ? 'active' : '';
    }

    return '';
  };

  const toggleDropdown = (items:any) => {
    console.log(items);
    
    dispatch(setActiveItem(items))
    dispatch(openModal())
    // setOpenDropdown(prev => (prev === title ? null : title));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="top-navigation" ref={dropdownRef}>
      {items.map(({ title, icon, path, children },i) => {
        const activeClass = getActiveClass({ path, children });
        const isDropdownOpen = openDropdown === title;
        
        return (
          <div className={`top-menu-item ${activeClass}`} key={i}>
            {path ? (
              <Link
                to={path}
                className={`text-dark svg_size ${activeClass}`}
                onClick={() => setOpenDropdown(null)} // Close all dropdowns on link click
              >
                <span className={`svg_size ${activeClass}`}>
                  {icon && <FontAwesomeIcon icon={icon} />}
                </span>
              </Link>
            ) : (
              <div className={`top-menu-subitem ${activeClass}`} >
                <span className={`svg_size ${activeClass}`} onClick={() => toggleDropdown(items[i])}>
                  {icon && <FontAwesomeIcon icon={icon} />}
                </span>
                <FontAwesomeIcon
                  icon={isDropdownOpen ? faAngleUp : faAngleDown}
                  className="dropdown-toggle-icon"
                />
                
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SidebarResponsive;
