import { useEffect, useState } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import StaticContent from "../../Component/Information/StaticContent";
import { useGetStaticsPageDataMutation } from "../../ReduxToolkit/Reducers/Information/staticsApiSlice";
import { toast } from "react-toastify";
import Loading from "../../Component/Loading/Loading";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Statics() {
  const [staticsData, setStaticsData] = useState<any>([]);

  const [getStaticsPageData, { isLoading }] = useGetStaticsPageDataMutation();
  const { id, slug } = useParams();

  const navigate = useNavigate();

  const getStaticsPageDataHandler = async () => {
    try {
      if (id !== undefined && slug !== undefined) {
        const response = await getStaticsPageData({
          id: Number(id),
          slug: slug,
        }).unwrap();
        const { data, success, message } = response;

        if (success === true) {
          setStaticsData(data);
        } else {
          //toast.error(message);
          navigate("/404");
        }
      }
    } catch (error: any) {
      //toast.error(error?.data?.message || "Somthing went wrong");
      navigate("/404");
    }
  };

  useEffect(() => {
    getStaticsPageDataHandler();
  }, [id, slug]);

  if (isLoading) return <Loading />;

  return (
    <div className="page-body">
      {staticsData && (
        <Breadcrumbs mainTitle={staticsData.title} parent={"Information"} />
      )}
      <div className="container mt-5">
        {staticsData && <StaticContent staticsData={staticsData} />}
      </div>
    </div>
  );
}

export default Statics;
