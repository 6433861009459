import { useState, useEffect } from "react";
import { CardBody, Col, Row } from "reactstrap";
import { Formik } from "formik";
import {
  RegistrationPlanValidationProp,
  registratinoFormInitialValue,
  registrationFormSchema,
} from "../../Data/RegistrationPlans/RegistrationValidation";
import StaticPlanData from "./StaticPlanData";
import DynamicPlanData from "./DynamicPlanData";
import Loading from "../Loading/Loading";
import FilterForm from "./FilterForm";
import Message from "../CommonMessage/Message";
import { toast } from "react-toastify";
import { useGetCurrentRegistrationPlanMutation } from "../../ReduxToolkit/Reducers/RegistrationPlan/registrationPlanApiSlice";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";

const PricingPlans = ({ allregistrationplans }: any) => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const [planData, setPlanData] = useState({});
  const [isLoading, setIsLoading] = useState();
  const [countryId, setCountryId] = useState();
  const [currentRegistrationPlan, setCurrentRegistrationPlan] = useState<any>(
    []
  );

  const { userInfo } = useAppSelector((state) => state.auth);

  const [getCurrentRegistrationPlanApi, { isLoading: currentPlanLoading }] =
    useGetCurrentRegistrationPlanMutation();

  // Get Current Registration Plan Funcation.
  const getCurrentRegistrationPlan = async () => {
    try {
      const response = await getCurrentRegistrationPlanApi().unwrap();
      const { data, success, message } = response;
      if (success === true) {
        setCurrentRegistrationPlan(data);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error.data.message || "Something went weong.");
    }
  };

  useEffect(() => {
    if (userInfo) {
      getCurrentRegistrationPlan();
    }
  }, [userInfo]);

  const handleSignup = (values: RegistrationPlanValidationProp) => {
    console.log(values);
  }; 

  if (isLoading) return <Loading />;

  return (
    <CardBody className="pt-0 mt-4">
      <>
        <Row className="pt-2">
          <Formik
            initialValues={registratinoFormInitialValue}
            onSubmit={handleSignup}
            validationSchema={registrationFormSchema}
          >
            {({ errors, values, setFieldValue }) => (
              <FilterForm
                errors={errors}
                values={values}
                submitErrors={submitErrors}
                setSubmitError={setSubmitError}
                setPlanData={setPlanData}
                setIsLoading={setIsLoading}
                setFieldValue={setFieldValue}
                setCountryId={setCountryId}
              />
            )}
          </Formik>
        </Row>
        {Object.keys(planData).length !== 0 ? (
          <div className="py-1 d-flex pricingplan ">
            {isMobile ? (
              <Col
                md="12"
                className="d-flex ps-0"
                style={{ overflowX: "auto" }}
              >
                <StaticPlanData
                  staticData={planData}
                  allregistrationplans={allregistrationplans}
                />
                <DynamicPlanData
                  dData={planData}
                  isButton={true}
                  countryId={countryId}
                  planId={currentRegistrationPlan?.plan_summary?.plan_id}
                />
              </Col>
            ) : (
              <>
                <Col className="ps-0 ">
                  <StaticPlanData staticData={planData} />
                </Col>
                <Col md="8">
                  <div>
                    <Col
                      md="12"
                      className="d-flex w-100 daynmicplan"
                      style={{
                        // overflowX: "scroll",
                        paddingRight: "165px",
                        // transform: 'rotateX(180deg)',
                        scrollbarWidth: "initial",
                        // marginTop: '1.4rem',
                        scrollbarColor: "#000",
                      }}
                    >
                      <div
                        className="d-flex w-auto"
                        //  style={{ transform: 'rotateX(180deg)' }}
                      >
                        <DynamicPlanData
                          dData={planData}
                          allregistrationplans={allregistrationplans}
                          isButton={true}
                          countryId={countryId}
                          planId={currentRegistrationPlan?.plan_summary?.plan_id}
                        />
                      </div>
                    </Col>
                  </div>
                </Col>
              </>
            )}
          </div>
        ) : (
          <Message message="Select country and plan type to see plan list" />
        )}
      </>
    </CardBody>
  );
};

export default PricingPlans;
