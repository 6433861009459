import { Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs"; 
import { AddPhotoCotest, BrandSupportTools, BrandTools } from "../../../../utils/Constant"; 
import PhotoContestMain from "../../../../Component/Tools/BrandSupportTools/ManageMyPhotoContest/PhotoContestMain";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";
const AddManageMyPhotoContest = () => {
  
  return (
    <div className="page-body">
       <div className="d-flex container-fluid align-items-center">
                <div className="px-2"> 
      <SubMenuPopup  currentPage="Add a Media Contest "/>
                </div>
               
      <Breadcrumbs mainParent="All Tools" mainTitle={`${AddPhotoCotest} (${BrandTools})`} parent={BrandSupportTools} />
            </div>
      <Container className="card p-4" fluid>
          <PhotoContestMain/>
      </Container>
    </div>
  );
};

export default AddManageMyPhotoContest;
