import {useState} from 'react'
import { BrandTools, Lesson, ManageExternalTitle, QuizMaterial, StudyMaterial } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link, useLocation } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { ActionButtons, ExternalLinkTableAction,  StudyMaterialAction, StudyMaterialTableAction, links, supportColumnData, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import Popup from '../../../../Component/MasterPopup/Popup'
import StudyMaterialEdit from '../../../../Component/Tools/BrandSupportTools/StudyMaterialLesson/StudyMaterialEdit'
import StudyMaterialView from '../../../../Component/Tools/BrandSupportTools/StudyMaterialLesson/StudyMaterialView'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import StudyMaterialSubMenu from '../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu'
import StudyMaterialShare from '../../../../Component/Tools/BrandSupportTools/StudyMaterialLesson/StudyMaterialShare'
import { Btn } from '../../../../AbstractElements'
import ImportBulk from '../ImportBulk/ImportBulk'
import { FequentlyinitialValues, FequentlyValidationSchema, validationSchemaTransition } from '../../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import { Formik } from 'formik'
import FequentlyModalForm from '../FequentlyQuestion/FequentlyModalForm'
import ImportTransitionForm from '../../ImportTransition/ImportTransitionForm'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'

const StudyMaterialLessions = () => {
  
  
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const [isEditOpen , setIsEditOpen] = useState();
    const [isOpen , setIsOpen] = useState(false);
  
    const handleRowSelected =() =>{}

    const openPopup = (type:any) =>{
        setIsOpen(!isOpen);
        console.log(type);
        
        setIsEditOpen(type);
    }

    const openModalToggle = () =>{
        setIsOpen(!isOpen);
    } 
    const [submitErrors, setSubmitError] = useState<boolean>(false); 
    const [openGamePublishModal, setOpenGamePublishModal] = useState(false);
    const openGamePublishModalToggle = () => setOpenGamePublishModal(!openGamePublishModal);
    const StudyMaterialColumnData:TableColumn<SupportDataType>[] = [
        {
            name: <TableHeadColumn title={`S. No.`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`By`}/>,
            selector: (row) => row["by"],
            sortable: true,
            center: true,
          },
        {
            name: <TableHeadColumn title={`Material Title`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Sub Categories`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Keywords`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Auto Publish Date`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Attachment`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Urls`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name:<TableHeadColumn title={`Actions`}/>,
            cell: (row) => <ActionButtons playbtn={true} openPopup={openPopup}/>,
            center: false,
            right:true,
        }
    ]
    const location = useLocation();
    const currentPath = location.pathname;
    const isActive = (path) => {
        // Construct the full path by combining PUBLIC_URL with the provided path
        const fullPath = `${process.env.PUBLIC_URL}${path}`;
        
        // Check if the currentPath matches or starts with the full path (for sub-paths)
        return currentPath === fullPath || currentPath.startsWith(fullPath);
      };
      
      const activeClass = isActive('/tools/brand-support-tools/knowledge/lessons/add-new') ? ' text-light bg-gray' : ' text-dark';
    
      return (
         <>
        
        <Container fluid>
            <Row>
            <Col sm="12">
                <Card>
                <div className='mt-2 ms-1'> 
                <StudyMaterialSubMenu  />

                    {/* <StudyMaterialSubMenu position={3}  bulkandpublish={true} openBulkModalToggle={openBulkModalToggle} openPublishModalToggle={openGamePublishModalToggle} bulkbtn={true}  /> */}
                  </div> 
                   

                <CardBody className='p-2'>
                    <> 
                    <div className='p-2 border'> 
                        <div className=''>
                        {StudyMaterialTableAction.map((action, index)=>{
                        return(
                        <>
                            <Link to={`${process.env.PUBLIC_URL}${action.path}`}><span key={index} className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                        </>)
                        })}
                        </div>  
                        <div className="table-responsive pt-2">
                            <DataTable className='custom-scrollbar' columns={StudyMaterialColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                        </div>
                        <Popup title={`Study Material`} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`}>
                        {isEditOpen == 'edit' ? <StudyMaterialEdit/> : isEditOpen == 'view' ? <StudyMaterialView /> : <StudyMaterialShare/> }
                        </Popup>
                        
            <Popup
                title='Publish Online lesson'
                openModalToggle={openGamePublishModalToggle}
                isOpen={openGamePublishModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                  <Formik
                        initialValues={FequentlyinitialValues}
                        validationSchema={FequentlyValidationSchema}
                        onSubmit={(values) => {
                          console.log("Selected values:", values);
                        }}
                      >
                        {({ handleChange, values,errors,setFieldValue }) => ( 
               <FequentlyModalForm selectField='lesson' setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
              )}
    </Formik>
 
            </Popup>
                    </div>
                    </>
                </CardBody>
                </Card>
            </Col>
            </Row>
        </Container> 
         </>
    )
  
}

export default StudyMaterialLessions