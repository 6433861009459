import { apiSlice } from "../../apiSlice";
import { ACCOUNT_HISTORY_BULK_DELETE, ACCOUNT_HISTORY_LIST, ACCOUNT_HISTORY_SHOW_BY_NO } from "../../../Api";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const accountHistoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountHistoryData: builder.mutation<SuccessDataProps, void>({
      query: () => ({
        url: ACCOUNT_HISTORY_LIST,
        method: "GET",
      }),
    }),
    getAccountHistoryShowByNo: builder.mutation<SuccessDataProps, { no: any }>({
      query: (data) => ({
        url: `${ACCOUNT_HISTORY_SHOW_BY_NO}/${data.no}`,
        method: "GET",
      }),
    }),
    deleteAccountHistoryRows : builder.mutation<SuccessDataProps, { ids : any }>({
      query: (data) => ({
        url: `${ACCOUNT_HISTORY_BULK_DELETE}`,
        method: "POST",
        body: data
      }),
    }),
  }),
});

export const {
  useGetAccountHistoryDataMutation,
  useGetAccountHistoryShowByNoMutation,
  useDeleteAccountHistoryRowsMutation
} = accountHistoryApiSlice;
