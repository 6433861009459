import React from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { Btn } from "../../AbstractElements";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import Symbol from "./Symbol";

const DynamicPlanData = (props: any) => {
  const planData = props.dData.data.dynamic;
  let pkg = "tetset";

  const { countries } : any = useAppSelector((state) => state.common);
  const { userInfo } = useAppSelector((state) => state.auth); 
  const selectedCountry = countries.filter((item: any) => {
    return item.value === props.countryId;
  });

  const countryLabel = selectedCountry.map((item: any) => item?.label);  

  return (
    <>
      {planData.map((item: any, index: number) => (
        <div
          className={`border-bottom-0 auto text-nowrap px-4 p-5 pt-0`}
          key={index}
        >
          <Table bordered hover className={`${props.planId == item.plan_id ? 'bg-secondary' : ''}`}>
            <tbody>
              <tr>
                <td>
                  <div className="d-md-flex">
                    <div className="col-6">
                      <b>{item.plan_name}</b>
                    </div>
                    {props?.isModifyButton && (
                      <div className="col-6">
                        <Link
                          className=""
                          to={`${process.env.PUBLIC_URL}/account-settings/modify-registration-plan`}
                        >
                          <Btn className="bg-primary float-end">Modify Plan</Btn>
                        </Link>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    height: "225px",
                    borderWidth: "1px",
                    borderColor: "rgba(153, 153, 153, 0.3)",
                  }}
                >
                  {props?.planPayPeriod !== undefined ? (
                    <p key={1}>
                      {item.pricings[props?.planPayPeriod?.toLowerCase()]}
                    </p>
                  ) : (
                    Object.keys(item.pricings).map((m_item, index) => {
                      return <p key={index}>{item.pricings[m_item]}</p>;
                    })
                  )}
                  {props?.isButton && (
                    <>
                      {userInfo ? (
                        <Link
                          to={`${process.env.PUBLIC_URL}/account-settings/modify-registration-plan`}
                        >
                          <Btn
                            className={`btn py-1 my-1  ${
                              pkg === "Premium"
                                ? "bg-light text-primary"
                                : "btn-primary text-white"
                            }`}
                          >{`Upgrade`}</Btn>
                        </Link>
                      ) : (
                        <Link
                          to={`${process.env.PUBLIC_URL}/register-account/${
                            item.plan_id
                          }/${countryLabel.toString()}/${
                            item.registration_type
                          }`}
                        >
                          <Btn
                            className={`btn py-1 my-1  ${
                              pkg === "Premium"
                                ? "bg-light text-primary"
                                : "btn-primary text-white"
                            }`}
                          >{`Register`}</Btn>
                        </Link>
                      )}
                    </>
                  )}
                </td>
              </tr>
              {item.details.map((d_item: any, d_index: number) => (
                <React.Fragment key={d_index}>
                  <tr>
                    <td
                      style={{
                        WebkitLineClamp: "2",
                        textWrap: "wrap",
                        minHeight: "80px",
                        display: "inline-block",
                        width: "100%",
                        height: "100%",
                        // borderWidth:'1px',
                        // borderColor:'rgba(153, 153, 153, 0.3)'
                      }}
                    >
                      <p>
                        <b>{d_item.heading}</b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        WebkitLineClamp: "2",
                        textWrap: "wrap",
                        minHeight: "80px",
                        display: "inline-block",
                        width: "100%",
                        height: "100%",
                        // borderWidth:'1px',
                        // borderColor:'rgba(153, 153, 153, 0.3)'
                      }}
                    >
                      {Object.keys(d_item.sub_headings).map(
                        (sub_item, i_index) => (
                          <React.Fragment key={i_index}>
                            <>
                              {(d_index == 0 && i_index == 0) ||
                              (d_index == 1 && i_index == 3) ||
                              (d_index == 1 && i_index == 4) ||
                              (d_index == 1 && i_index == 5) ||
                              (d_index == 2 && i_index == 0) ||
                              (d_index == 2 && i_index == 2) ||
                              (d_index == 2 && i_index == 7) ||
                              (d_index == 3 && i_index == 0) ||
                              (d_index == 3 && i_index == 1) ||
                              (d_index == 5 && i_index == 1) ? (
                                <p>
                                  <Symbol
                                    value={d_item.sub_headings[sub_item]}
                                  />
                                </p>
                              ) : (
                                <p>
                                  <Symbol
                                    value={d_item.sub_headings[sub_item]}
                                  />
                                </p>
                              )}
                            </>
                          </React.Fragment>
                        )
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </>
  );
};

export default DynamicPlanData;
