import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useGetMetaTagsDataMutation } from "../../ReduxToolkit/Reducers/Meta/tagsApiSlice";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { setMetatagsData } from "../../ReduxToolkit/Reducers/commonInputSlice";
import { useLocation } from "react-router-dom";
function Tags() {
  const [title, setTitle] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [keywords, setKeywords] = useState<any>();
  const [metaData, setMetaData] = useState([]);

  const dispatch = useAppDispatch();

  const websiteSettingData : any = useAppSelector((state) => state.common.websiteSettingsData);

  const [getMetaTagsDataApiCall, { isLoading }] = useGetMetaTagsDataMutation();

  const getMetaTagsData = async () => {
    const response = await getMetaTagsDataApiCall().unwrap();
    const { data, success, message }: any = response;
    if (success === true) {
      setMetaData(data); 
      dispatch(setMetatagsData(data));
    } else {
      toast.error(message);
    }
  };

  const location = useLocation();

  const getFilterData = (data: any) => {
    if (data.length > 0) { 
      return data.filter((item: any) => item.uri === window.location.pathname);
    }
    return [];
  };

  // load the meta tags api and set in state.
  useEffect(() => { 
    getMetaTagsData(); 
  }, []);

  // Every page url changed the useEffect call and update the current page meta tags.
  useEffect(() => {
    // Filter the data for current page
    const filterData = getFilterData(metaData); 
    // set title, description and keywords value for current page.
    if (filterData.length > 0) {
      filterData.map((item: any) => {
        const { meta_title, meta_description, tags }: any = item; 
        setTitle(`${meta_title} | ${websiteSettingData.title}`);
        setDescription(meta_description);
        setKeywords(tags);
      });
    } else {
      websiteSettingData.title && setTitle(websiteSettingData.title);
    }
  },[location.pathname,metaData]);

  
  if(isLoading) return <Loading/>;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
}

export default Tags;
