// src/redux/formSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 
import { FormState, specialParametersFormInitialValue, studyMaterialLessonFormInitialValue } from "../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { scheduleFormInitialValue } from "../../../Data/Tools/ManageOnlineStore/FormControlsValidation";

 

// Define initial values for each tab form
const initialState: FormState = {
    specialParameters: specialParametersFormInitialValue,
    studyMaterialLesson: scheduleFormInitialValue,
};

 

// Create a Redux slice to manage form state
const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
        updateFormValues: (
            state,
            action: PayloadAction<{ formKey: keyof FormState; values: any }>
        ) => {
            // Ensure formKey is valid key of FormState
            state[action.payload.formKey] = action.payload.values;
        },
    },
});

export const { updateFormValues } = formSlice.actions;
export default formSlice.reducer;
