 
import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../../CommonInput/CustomSelect';
import { Btn } from '../../../../AbstractElements';
import ImportBulk from '../../../../Pages/Tools/BrandSupportTools/ImportBulk/ImportBulk';
import Popup from '../../../MasterPopup/Popup';

interface OnlineAssistant {
  assistant_username: string;
  assistant_email: string;
  assistant_price: string;
  branches: any[];
}

interface OnlineAssistantFormProps {
  assistants: OnlineAssistant[];
}

const onlineAssistantInitialValue: OnlineAssistantFormProps = {
  assistants: [
    {
      assistant_username: "",
      assistant_email: "",
      assistant_price: "",
      branches: [],
    },
  ],
};

const onlineAssistantSchema = Yup.object().shape({
  assistants: Yup.array().of(
    Yup.object().shape({
      assistant_username: Yup.string().required("Required"),
      assistant_email: Yup.string().required("Required"),
      assistant_price: Yup.string().required("Required"),
      branches: Yup.array().optional(),
    })
  ),
});
function OnlineAssistantForm({openAssistantModalToggle}) {
      const [submitErrors, setSubmitErrors] = useState(false);
  const navigate = useNavigate();
      const handleSubmit = (values: OnlineAssitantValidationProp) => {
        setSubmitErrors(false);
        navigate("/cart");
      };
      const branchOptions = [
        { value: "branch1", label: "Branch 1, Surat, Gujarat" },
        { value: "branch2", label: "Branch 2, Mumbai, Maharashtra" },
        { value: "branch3", label: "Branch 3, Bangalore, Karnataka" },
      ];
      const [openQestionModal, setOpenQestionModal] = useState(false);
      const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
  return (
    < > <Formik
    initialValues={onlineAssistantInitialValue}
    onSubmit={handleSubmit}
    validationSchema={onlineAssistantSchema}
  
  >
    {({ errors, values, setFieldValue }) => (
      <Form className="mt-2 mx-2">
     
           <FieldArray name="assistants">
          {({ push, remove }) => (
            <>
              {values.assistants.map((assistant, index) => (
                <>
                   <Col sm="12" className="my-2">
                   <Btn onClick={openQestionModalToggle} className='p-0 child_btn border rounded'   ><span  >Import Bulk Contacts</span></Btn>
                   </Col>
                <Row key={index} className='align-items-end'>
                  <Col sm="4" className="my-2">
                    <Label check>{"Assistant’s Username"}:</Label>
                    <Field
                      type="text"
                      placeholder={"Assistant’s Username"}
                      name={`assistants.${index}.assistant_username`}
                      className={`form-control ${
                        submitErrors &&
                        `${
                          errors.assistants?.[index]?.assistant_username ? "is-invalid" : "is-valid"
                        }`
                      }`}
                      autoFocus={true}
                    />
                    <ErrorMessage
                      name={`assistants.${index}.assistant_username`}
                      component="span"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col sm="4" className="my-2">
                    <Label check>{"Assistant’s Email"}:</Label>
                    <Field
                      type="text"
                      placeholder={"Assistant’s Email"}
                      name={`assistants.${index}.assistant_email`}
                      className={`form-control ${
                        submitErrors &&
                        `${errors.assistants?.[index]?.assistant_email ? "is-invalid" : "is-valid"}`
                      }`}
                      autoFocus={true}
                    />
                    <ErrorMessage
                      name={`assistants.${index}.assistant_email`}
                      component="span"
                      className="invalid-feedback"
                    />
                  </Col> 
                  <Col sm="4" className="my-2">
                    {index != 0 && <Btn color="danger" className='me-2' type="button" onClick={() => remove(index)}>  <i className="fa fa-minus"></i></Btn>}
                  </Col>
                </Row>
                </>
              ))}
                  <Btn color="success" type="button" onClick={() => push(onlineAssistantInitialValue.assistants[0])}>
                    <i className="fa fa-plus"></i>
                    </Btn> 
              <Row className="mt-4">
          <Col>

              <Btn
              color="light"
              type="button"
              onClick={openAssistantModalToggle}
              className="mx-2"
            >
              Cancel
            </Btn>
          </Col>
          </Row>

          <Popup
                title='Import Bulk Account Assistants'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup>
            </>
          )}
        </FieldArray>
      </Form>
    )}
  </Formik></>
  )
}

export default OnlineAssistantForm