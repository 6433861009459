import React from 'react';
import { Spinner } from 'reactstrap';
import style from './loading.module.css';
interface LoadingProps {
  className?: string; // Make it optional
}
const Loading: React.FC<LoadingProps> = ({ className}) => { 
  return (
    <div  className={`${style['loading-overlay']} ${className}`}>
      <Spinner color="primary" />
    </div>
  );
};

export default Loading;