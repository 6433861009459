import { Card, CardBody, CardHeader, Col, Container, Form, Label, Row } from "reactstrap";
import { AddProductServiceList, ManageStore, ProductForm } from "../../../../utils/Constant";
import { Btn, H3, LI, UL } from "../../../../AbstractElements";
import ProductBody from "./ProductBody/ProductBody";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import ManageOnlineSubMenu from "../../../../Pages/Tools/ManageOnlineStore/ManageOnlineSubMenu/ManageOnlineSubMenu";
import ProductServiceSubMenu from "../../../../Pages/Tools/ManageOnlineStore/ManageBranches/ManageProductServices/ProductServiceSubMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Country from "../../../CommonInput/Country";
import { ErrorMessage, Field, Formik } from "formik";
import { manageBranchFormInitialValue, manageBranchFormValidationSchema } from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import Popup from "../../../MasterPopup/Popup";
import ImportBulk from "../../../../Pages/Tools/BrandSupportTools/ImportBulk/ImportBulk";
import BulkProductImages, { ProductvalidationSchema } from "../../../../Pages/Tools/ManageOnlineStore/ManageBranches/ManageProductServices/BulkProductImages";
import { useState } from "react";
import Notes from "../../../../Pages/Tools/Notes/Notes";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

export const options = [
  { value: '5', label: '30 Days, 5 USD' },
  { value: '12', label: '90 Days (4 USD/ 30 Days) if you pay 12 USD' },
  { value: '18', label: '180 Days (3 USD/ 30 Days) if you pay 18 USD' },
  { value: '24', label: 'Annually (2 USD/ 30 Days) if you pay 24 USD' }, 
];
const AddProductContainer = () => {
      const [openQestionModal, setOpenQestionModal] = useState(false);
      const [openImagesModal, setopenImagesModal] = useState(false);
  const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
  const openImagesModalToggle = () => setopenImagesModal(!openImagesModal);
  return (
    <>
    <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
      <SubMenuPopup  currentPage="Add a Product or Service"/>
                </div>
      <Breadcrumbs mainTitle={`${AddProductServiceList} (Manage Store)`} mainParent="All Tools" parent={ManageStore} />
            </div>
      <Container fluid> 
        <Row>
          <Col xs="12">
            <Card>  
              <CardBody> 
                                <div className="mt-2 ps-1">
      <Btn onClick={openQestionModalToggle} className='p-0 child_btn border rounded me-2'   ><span  >Import Bulk Products and Services</span></Btn>
      <Btn onClick={openImagesModalToggle} className='p-0 child_btn border rounded'   ><span  >Import Bulk Product Images</span></Btn>

      </div>
                <ProductBody />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Popup
                title='Import Bulk Products and Services'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup>
            <Popup
                title='Import Bulk Product Images'
                openModalToggle={openImagesModalToggle}
                isOpen={openImagesModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                    <Formik
    initialValues={{ 
      bulkproductImages: [],
    }}
    validationSchema={ProductvalidationSchema}
    onSubmit={(values) => {
        // Handle form submission.
        console.log(values);
    }}
>
    {({ setFieldValue, errors, touched }) => (
        <BulkProductImages name="bulkproductImages" setFieldValue={setFieldValue} errors={errors} touched={touched} />
    )}
</Formik>

                    </Popup>
      </Container>

    </>
  );
};

export default AddProductContainer;
