import { CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs"; 
import {
  AddCouponTitle,
  ManageOnlineStoreTitle,
} from "../../../../utils/Constant";
import CouponMain from "../../../../Component/Tools/ManageOnlineStore/ManageMyCoupon/CouponMain";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const AddCoupon = () => {
  
  return (
    <div className="page-body">
      <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
            <SubMenuPopup  currentPage="Add a Coupon"/>
                </div> 
      <Breadcrumbs mainTitle={`${AddCouponTitle} (Manage Store)`} mainParent="All Tools" parent={ManageOnlineStoreTitle} />
            </div>
      
      <Container className="card p-4" fluid>
          <CouponMain/>
      </Container>
    </div>
  );
};

export default AddCoupon;
