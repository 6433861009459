import { Row, Col, Label, Input } from "reactstrap";
import { ErrorMessage, Field } from "formik";

function OrderLimitForm(props: any) {

    const { submitErrors, errors, index } = props;

    const { orderlimits } = errors;
    const orderLimitError = orderlimits?.length && orderlimits[index];

  return (
    <Row>
      <Col sm="4" className="mb-3">
        <Label check>Minimum Order Total <span className="txt-danger">*</span></Label>
        <Field name={`orderlimits.${index}.minOrderTotal`}>
  {({ field, form }) => (
    <Input
      {...field}
      type="text"
      id={`price-${index}`}
      value={form.values.orderlimits[index].minOrderTotal}
      onChange={(e) => {
        let value = e.target.value;

        // Allow only numbers and a single decimal point
        if (!/^\d*\.?\d*$/.test(value)) return;

        form.setFieldValue(`orderlimits.${index}.minOrderTotal`, value);
      }}
      onBlur={() => {
        // Convert to 2 decimal places when user leaves input
        form.setFieldValue(
          `orderlimits.${index}.minOrderTotal`,
          parseFloat(form.values.orderlimits[index].minOrderTotal || "0").toFixed(2)
        );
      }}
      placeholder="0.00"
    />
  )}
</Field>

        <ErrorMessage
            name={`orderlimits.${index}.minOrderTotal`}
            component="span"
            className="invalid-feedback"
          />
      </Col>
      <Col sm="4" className="mb-3">
        <Label check>Maximum Order Total <span className="txt-danger">*</span></Label>
        <Field name={`orderlimits.${index}.maxOrderTotal`}>
  {({ field, form }) => (
    <Input
      {...field}
      type="text"
      id={`price-${index}`}
      value={form.values.orderlimits[index].maxOrderTotal}
      onChange={(e) => {
        let value = e.target.value;

        // Allow only numbers and a single decimal point
        if (!/^\d*\.?\d*$/.test(value)) return;

        form.setFieldValue(`orderlimits.${index}.maxOrderTotal`, value);
      }}
      onBlur={() => {
        // Convert to 2 decimal places when user leaves input
        form.setFieldValue(
          `orderlimits.${index}.maxOrderTotal`,
          parseFloat(form.values.orderlimits[index].maxOrderTotal || "0").toFixed(2)
        );
      }}
      placeholder="0.00"
    />
  )}
</Field> 
        <ErrorMessage
            name={`orderlimits.${index}.maxOrderTotal`}
            component="span"
            className="invalid-feedback"
          />
      </Col>
      <Col sm="4" className="mb-3">
        <Label check>Fixed Amount Discount <span className="txt-danger">*</span></Label>
       
           <Field name={`orderlimits.${index}.fixAmountDiscount`}>
  {({ field, form }) => (
    <Input
      {...field}
      type="text"
      id={`price-${index}`}
      value={form.values.orderlimits[index].fixAmountDiscount}
      onChange={(e) => {
        let value = e.target.value;

        // Allow only numbers and a single decimal point
        if (!/^\d*\.?\d*$/.test(value)) return;

        form.setFieldValue(`orderlimits.${index}.fixAmountDiscount`, value);
      }}
      onBlur={() => {
        // Convert to 2 decimal places when user leaves input
        form.setFieldValue(
          `orderlimits.${index}.fixAmountDiscount`,
          parseFloat(form.values.orderlimits[index].fixAmountDiscount || "0").toFixed(2)
        );
      }}
      placeholder="0.00"
    />
  )}
</Field>
        <ErrorMessage
            name={`orderlimits.${index}.fixAmountDiscount`}
            component="span"
            className="invalid-feedback"
          />
      </Col>
    </Row>
  );
}

export default OrderLimitForm;
