import { Button, Card, CardBody, Col, Collapse, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {EventAndActivites, Post } from "../../../../utils/Constant";
import MediaData from "../../../../Component/App/MediaData/MediaData";
import { Home } from "react-feather";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import PostsubMenu from "../../../../Component/App/PostSubMenu/PostsubMenu";
import DragCalendar from "../../../../Component/Application/Calender/DragCalendar/DragCalendar";
import { useState } from "react";
import DefaultFilter from "../../../../Component/Application/Ecommerce/Products/ProductFeatures/DefaultFilter";
import { Field, Formik } from "formik";
import ReactDatePicker from "react-datepicker";
import { initialValues } from "../../../Tools/BrandSupportTools/ManageAttendance/ManageAttendanceSystem";

 

const EventActivites = () => {
  const[isOpen, setIsOpen] = useState(true);
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={EventAndActivites} parent={Post} />
      <PostsubMenu/>
      <Container fluid>
      <div className='mt-2'>
      <DefaultFilter remove={true}/>
        </div>
      <Card> 
          <Collapse isOpen={isOpen}>
            <CardBody>
            <Formik
                                            initialValues={initialValues}
                                            onSubmit={(values) => {
                                                console.log(
                                                    "Selected Date Range:",
                                                    values
                                                );
                                            }}>
                                            {({ setFieldValue, values }) => (
                                                <Form>
                                                    <Row>
                                                        <Col sm="2">
                                                            <FormGroup>
                                                            <div className="d-flex flex-column">
              <Label htmlFor="activityType">Activity Type:</Label>
              <Field
                name="activityType"
                as={Input}
                type="select"
                id="activityType"
              >
                <option value="">{`Select`}</option>
                <option value="freeCommercial">
                Appointment
                </option>
                <option value="freeNonProfit">
                Class Room
                </option>
                <option value="freeNonProfit">
                Event
                </option>
                <option value="freeNonProfit">
                Work Shift
                </option>
              </Field>
            </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="2">
                                                            <FormGroup>
                                                                <div className="d-flex flex-column">
                                                                    <Label htmlFor="startDate">
                                                                        From:
                                                                    </Label>
                                                                    <Field name="startDate">
                                                                        {({
                                                                            field,
                                                                        }: any) => (
                                                                            <ReactDatePicker
                                                                                selected={
                                                                                    values.startDate
                                                                                }
                                                                                onChange={(
                                                                                    date
                                                                                ) =>
                                                                                    setFieldValue(
                                                                                        "startDate",
                                                                                        date
                                                                                    )
                                                                                }
                                                                                dateFormat="yyyy/MM/dd"
                                                                                placeholderText="Select start date"
                                                                                className="form-control flatpickr-input"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="2">
                                                            <FormGroup>
                                                                <div className="d-flex flex-column">
                                                                    <Label htmlFor="endDate">
                                                                        To:
                                                                    </Label>
                                                                    <Field name="endDate">
                                                                        {({
                                                                            field,
                                                                        }: any) => (
                                                                            <ReactDatePicker
                                                                                selected={
                                                                                    values.endDate
                                                                                }
                                                                                onChange={(
                                                                                    date
                                                                                ) =>
                                                                                    setFieldValue(
                                                                                        "endDate",
                                                                                        date
                                                                                    )
                                                                                }
                                                                                dateFormat="yyyy/MM/dd"
                                                                                placeholderText="Select end date"
                                                                                minDate={
                                                                                    values.startDate
                                                                                }
                                                                                className="form-control flatpickr-input"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="2">
                                                            <FormGroup>
                                                                <div className="d-flex flex-column">
                                                                    <Label htmlFor="activity">
                                                                    Search Activities:
                                                                    </Label>
                                                                    <Field
                                                                        name="activity"
                                                                        className="form-control"
                                                                        type="text"
                                                                        placeholder="enter a keyword, title or category"></Field>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col
                                                            sm="1"
                                                            className="align-self-center">
                                                            <Button type="submit">
                                                                Filter
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
              <Row>
                <Col sm="12">
                  <Row className="flex-column flex-md-row"> 
                    <DragCalendar modalBodyClassName="modal-custom "/>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </Container>
    </div>
  );
};

export default EventActivites;
