import { Dropzone, FileMosaic } from '@dropzone-ui/react';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from "yup";
import { setFormValue } from '../../../../../ReduxToolkit/Reducers/AddProductSlice';
import { DragFilesHere } from '../../../../../utils/Constant';
import { H6, P, SVG } from '../../../../../AbstractElements';
export const ProductvalidationSchema = Yup.object({
 
  bulkproductImages: Yup.array()
    .min(1, "additional image is required")
    .required("Additional images are required"),
});
function BulkProductImages({setFieldValue, errors, touched,name}) {
   
     const [additionalFiles, setAdditionalFiles] = useState<ExtFile[]>([]);
     const dispatch = useDispatch();
    const updateAdditionalFiles = (incomingFiles: ExtFile[]) => {
        setAdditionalFiles(incomingFiles);
        dispatch(setFormValue({ name: "fileName1", value: incomingFiles.map((file) => file.file?.name) }));
      };
      const removeAdditionalFile = (id: string | number | undefined) => {
        setAdditionalFiles(additionalFiles.filter((file) => file.id !== id));
      };
  return (
    <>
    {/* <Formik
    initialValues={{ 
      additionalImages: [],
    }}
    validationSchema={ProductvalidationSchema}
    onSubmit={(values) => {
      // Handle form submission.

      console.log(values);
    }}
  >
    {({ setFieldValue, errors, touched }) => ( */}
      <Form>
        
        <FormGroup>
        <div className={` mt-2`}> 
              <Field name={name}>
                {({ field }: any) => (
                  <Dropzone
                    onChange={updateAdditionalFiles}
                    value={additionalFiles}
                    maxFiles={10}
                    header={false}
                    footer={false}
                    minHeight="80px"
                    name="fileName1"
                    className={`${touched?.name && errors?.name ? "form-control is-invalid" : ""}`}
                     
                  >
                    {additionalFiles.map((file: ExtFile) => (
                      <FileMosaic key={file.id} {...file} onDelete={removeAdditionalFile} info={true} />
                    ))}
                    {additionalFiles.length === 0 && (
                      <div className="dz-message mt-5">
                        <SVG iconId="file-upload1" />
                        <H6>{DragFilesHere}</H6>
                        <span className="note">SVG, PNG, JPG or GIF</span>
                      </div>
                    )}
                  </Dropzone>
                )}
              </Field> 
            </div> 
        </FormGroup>
        <Button>Import</Button>
        {/* <Button color="primary" type="submit">Submit</Button> */}
      </Form>
    {/* )}
  </Formik> */}
     </>
  )
}

export default BulkProductImages