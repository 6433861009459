import { useEffect, useCallback } from "react";
import { Card, Container } from "reactstrap";
import { Home, RegistrationPlan } from "../../utils/Constant";
import PricingPlans from "../../Component/Pricing/PricingPlans";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import { setGeoLocation } from "../../ReduxToolkit/Reducers/commonInputSlice";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";

const Pricing = () => { 

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={RegistrationPlan} parent={Home} />
      <Container fluid>
        <Card className="pt-0">
          <PricingPlans allregistrationplans={true}/>
        </Card>
      </Container>
    </div>
  );
};

export default Pricing;
