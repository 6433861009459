import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import CreateNewProjectForm from './CreateNewProjectForm'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { BrandTools, Project, ProjectCreates } from '../../../../utils/Constant'
import SubMenu from '../../SubMenu/SubMenu' 
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'

const ProjectCreateContainer = () => {
  return (
    <>
     <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
 
      <SubMenuPopup  currentPage="Create a New Project"/>
                </div> 
      <Breadcrumbs mainTitle={`${ProjectCreates}(${BrandTools})`}  mainParent="All Tools" parent={BrandTools} />
            </div>
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
             
              <CardBody>
                <CreateNewProjectForm />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProjectCreateContainer