import {useState} from 'react'
import { BrandTools, ManageExternalTitle } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { ActionButtons, ExternalLinkTableAction, links, ManagePostsTableAction, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import Popup from '../../../../Component/MasterPopup/Popup'
import ExternalLinkView from '../../../../Component/Tools/BrandSupportTools/ManageExternalLink/ExternalLinkView'
import ExternalLinkEdit from '../../../../Component/Tools/BrandSupportTools/ManageExternalLink/ExternalLinkEdit'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import { Btn } from '../../../../AbstractElements'
import FequentlyModalForm from './FequentlyModalForm'
import { FequentlyinitialValues, FequentlyValidationSchema } from '../../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import { Formik } from 'formik'
import Notes from '../../Notes/Notes'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'





const FequentlyQuestion = () => {
  const [isOpen , setIsOpen] = useState(false);
  const [isEditOpen , setIsEditOpen] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState(supportTicketData);
  const [selectedData , setSelectedData] = useState();
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const handleRowSelected =() =>{}

  const openPopup = (type:boolean) =>{
    setIsOpen(!isOpen);
    setIsEditOpen(type);
  }

  const openModalToggle = () =>{
    setIsOpen(!isOpen);
  }

  const externalColumns: TableColumn<SupportDataType>[]= [
    {
      name: <TableHeadColumn title={`ID`}/>,
      selector: (row) => row["id"],
      sortable: true,
      center: false,
    }, 
    {
      name: <TableHeadColumn title={`By`}/>,
      selector: (row) => row["by"],
      sortable: true,
      center: false,
    },
    {
      name: <TableHeadColumn title={`Title`}/>,
      selector: (row) => row["name"],
      sortable: true,
      center: false,
    },
    {
      name: <TableHeadColumn title={`Number of Questions`}/>,
      selector: (row) => row["category"],
      sortable: true,
      center: false,
    }, 
    {
      name: <TableHeadColumn title={`Branches`}/>,
      selector: (row) => row["posttype"],
      sortable: true,
      center: false,
    },  
    {
      name: <TableHeadColumn title={`Date`}/>,
      selector: (row) => row["date"],
      sortable: true,
      center: false,
    }, 
    {
      name:<TableHeadColumn title={`Actions`}/>,
      cell: (row) => <ActionButtons openPopup={openPopup} />,
      center: false,
      right:true,
    },
  ]
  const [openQestionModal, setOpenQestionModal] = useState(false);
  const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
  return (
    <div className='page-body'>
       <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    
      <SubMenuPopup  currentPage="Frequently Asked Question List "/>
                </div>
               
      <Breadcrumbs mainParent="All Tools" mainTitle={`${`Frequently Asked Question System`} (${BrandTools})`} parent={BrandTools} />
            </div>
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card> 
            <div className='mt-2'>
                <Link to={`${process.env.PUBLIC_URL}/tools/brand-support-tools/frequently-asked-question-system/add-new`} ><span  className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>Add Frequently Asked Questions</span></Link>
                {/* <Link to={`${process.env.PUBLIC_URL}/tools/brand-support-tools/frequently-asked-question-system/add-new`} ><span  className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>Publish Online</span></Link> */}
                <Btn className='p-0' onClick={openQestionModalToggle}><span className={`border rounded p-2 m-1 bg-dark text-white  ps-2 pe-2  `}>Publish Online</span></Btn>
                
                {ManagePostsTableAction.slice(1).map((action, index)=>{
                  return(                  
                    <Link to={`${process.env.PUBLIC_URL}/${action.path}`} key={index}><span  className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                  )
                })}
                </div>
              <CardBody className='p-2'>
                <> 
                <div className="table-responsive pt-2">
                  <DataTable className='custom-scrollbar' columns={externalColumns} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                </div>
                
                <Popup title={`External Links`} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`} >
                  {isEditOpen ? <ExternalLinkEdit/> : <ExternalLinkView /> }
                </Popup>
                <Popup
                title='Publish Online'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                  <Formik
                        initialValues={FequentlyinitialValues}
                        validationSchema={FequentlyValidationSchema}
                        onSubmit={(values) => {
                          console.log("Selected values:", values);
                        }}
                      >
                        {({ handleChange, values,errors,setFieldValue }) => ( 
               <FequentlyModalForm selectField='Conference' setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
              )}
    </Formik>
 
            </Popup>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FequentlyQuestion