import { Card, CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ManageBranches, DeliveryFeesTitle } from "../../../../utils/Constant";
import DeliveryFeesMain from "../../../../Component/Tools/ManageOnlineStore/DeliveryFees/DeliveryFeesMain";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import DeliverySubMenu from "../DeliverySubMenu/DeliverySubMenu";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const AddDeliveryFees = () => {
    return (
        <div className="page-body">
             <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    <SubMenuPopup currentPage="Add  Delivery Fees " />
                </div>
             <Breadcrumbs
                mainTitle={`${DeliveryFeesTitle} (Manage Store)`}
                parent={ManageBranches}
                mainParent="All Tools"
            />
            </div>
            <Container fluid>
                <CardHeader className="pb-0 pt-0  ">
                    <ManageOnlineSubMenu />
                </CardHeader>
            </Container>
            <Container fluid>
                <DeliverySubMenu />
                <Card className=" mt-2 p-2">
                    <DeliveryFeesMain />
                </Card>
            </Container>
        </div>
    );
};

export default AddDeliveryFees;
