import { Label, Col } from "reactstrap";
import { H4, H5 } from "../../AbstractElements";
import { Field } from "formik";
function PricingForm(props: any) {
  const { title, index, value, values } = props;
  const titles = title.split(',');
  let  currentValue = values?.pay_period && values?.pay_period;
  currentValue = currentValue && currentValue.toLowerCase();  
  
  return (
    <Col
      md="2"
      sm="5"
      className="border rounded text-center mx-1 my-1 col-5 pb-2 flex-fill"
    >
      <div className="form-check form-check-inline radio radio-primary  mt-2">
        <Field
          id={`radioinline_${index}`}
          type="radio"
          name={`pay_period`}
          value={value}  
          checked={value === currentValue}
        />
        <Label className="mb-0 ms-4" for={`radioinline_${index}`} check>
        {titles.length > 0 && titles[0]}
        </Label>
      </div>
      <H4 className="pt-4 text-center mb-4"> </H4>
      <H5>{titles.length > 1 && titles[1]}</H5>
    </Col>
  );
}

export default PricingForm;
