import { useEffect } from "react";
import PricingForm from "../SignUp/PricingForm";
import { Row } from "reactstrap";

function PricingCode(props: any) {
  const { planSummary, values, filterDynamicPlan, setFieldValue, countryId } = props;

  useEffect(() => {
    if(planSummary?.plan_pay_period !== undefined){
        let currentPayPeriod = planSummary?.plan_pay_period && planSummary?.plan_pay_period;
        currentPayPeriod = currentPayPeriod && currentPayPeriod.toLowerCase();
        setFieldValue('country_id', countryId); 
        setFieldValue('type', 'extend'); 
        setFieldValue('plan_id', planSummary.plan_id); 
        setFieldValue('pay_period', currentPayPeriod);  
    }  
  },[planSummary]); 

  let priceCode = filterDynamicPlan && filterDynamicPlan?.map((item: any, index: number) => {
    return (
      <Row key={index}>
        <PricingForm
          title={item.pricings.monthly}
          value="monthly"
          index={1}
          values={values}
        />
        <PricingForm
          title={item.pricings.quarterly}
          value="quarterly"
          index={2}
          values={values}
        />
        <PricingForm
          title={item.pricings.semiannually}
          value="semiannually"
          index={3}
          values={values}
        />
        <PricingForm
          title={item.pricings.annually}
          value="annually"
          index={4}
          values={values}
        />
      </Row>
    );
  });

  return <div>{priceCode}</div>;
}

export default PricingCode;
