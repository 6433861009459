import { Link } from "react-router-dom";
import { FeatherIcons, H5, Image, LI, P, UL } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { CheckAll, Href } from "../../../../utils/Constant";
import { infoData } from "../../../../Data/LayoutData/HeaderData";

const InfoBox = (props: any) => {

  const { headerPages } = props;

  return (
    <UL className="simple-list ">
        {headerPages && headerPages.map((data: any,index: number) => (
            <LI key={data.id} className="py-2 px-0 text-left">
                <div className="d-flex align-items-start"> 
                    <div className="flex-grow-1">
                        <H5 className="mb-1 text-start">
                            <Link to={`statics/${data.id}/${data.slug}`}>{data.title}</Link>
                        </H5> 
                    </div> 
                </div>
            </LI>
        ))} 
    </UL>
  );
};

export default InfoBox;
