import * as Yup from "yup";
import { FormikErrors } from "formik";
import {
  RequiredFieldValidationText,
  URLFieldValidationText,
} from "../../utils/Constant";

export interface CreatePostValidationProp {
  posttype: string;
  subject: string;
  desired_budget: string;
  max_budget: string;
  country: string;
  description: string;
  customer_description: string;
  job_description: string,
  notification_description: string,
  users: string;
  keywords: Array<string>;
  employexperience: string; // updated to string to match form field type
  employperiodMonths: string; // updated to string to match form field type
  employscheduleType: string;
  employjobLocationType: string;
  externallink: string;
  employsalary: string;
  jobreferenceid: string;
  notificationreferenceid: string;
  dateHoursExpiry: string | null;
  Title: string  ;
  customer_Title: string  ;
  job_Title: string  ;
  notification_Title: string  ;
  aarticalDescription: string  ;
  PolicyService: string  ;
   registrationDeadline: Date ;
   dateofterminating: Date ;
   certificateOfGraduation: { status: string; description: string }; 
  officialTranscripts: { status: string; description: string };
  governmentPhotoID: { status: string; description: string };
  passport: { status: string; description: string };
  proofOfLanguageProficiency: { status: string; description: string };
  referenceLetters: { status: string; description: string };
  statementOfPurpose: { status: string; description: string };
  essay: { status: string; description: string };
  comanage :any,
  coManagers: [{ username: string, permission: any, dispaymedia: any }],
}

export const createPostInitialValue: CreatePostValidationProp = {
  Title:"",
  customer_Title:"",
  keywords: [],
  job_Title:"",
  notification_Title:"",
  posttype: "",
  subject: "",
  desired_budget: "",
  max_budget: "",
  country: "",
  description: "",
  customer_description: "",
  job_description: "",
  notification_description: "",
  users: "",
  employexperience: "",
  employperiodMonths: "",
  employscheduleType: "",
  employjobLocationType: "",
  externallink: "",
  employsalary: "",
  jobreferenceid: "",
  notificationreferenceid: "",
  dateHoursExpiry: null,
  aarticalDescription:"",
  documents: [
    { name: "Certificate of Graduation", status: "Unneeded", description: "" },
    { name: "Official Transcripts", status: "Unneeded", description: "" },
    { name: "Government Photo ID", status: "Unneeded", description: "" },
    { name: "Passport", status: "Unneeded", description: "" },  
    { name: "Proof of Language Proficiency", status: "Unneeded", description: "" },
    { name: "Reference Letters", status: "Unneeded", description: "" },
    { name: "Statement of Purpose", status: "Unneeded", description: "" },
    { name: "Essay", status: "Unneeded", description: "" },
  ],
  registrationDeadline: new Date(),
  dateofterminating: new Date(),
  certificateOfGraduation: { status: "Optional", description: "" },
  officialTranscripts: { status: "Optional", description: "" },
  governmentPhotoID: { status: "Optional", description: "" },
  passport: { status: 'Optional', description: '' },
  proofOfLanguageProficiency: { status: 'Optional', description: '',},
  referenceLetters: { status: 'Optional', description: '',},
  statementOfPurpose: { status: "Optional", description: "", maxCharacters: "" },
  essay: { status: "Optional", description: "", maxCharacters: "" },
  comanage :0,
  coManagers: [{ username: "", permission: 1, dispaymedia: 0 }],
  PolicyService:"",
};

// export const createPostSchema = Yup.object().shape({
//     posttype:Yup.string().required(),
//     subject: Yup.string().required("Required").min(5),
//     desired_budget: Yup.string().required("Required"),
//     max_budget: Yup.string().required("Required"),
//     country: Yup.string().required("Required"),
//     description: Yup.string().required("Required"),
//     users: Yup.string().required("Required"),
// });
export const createPostSchema = Yup.object().shape({
  Title: Yup.string().required("Title is required"),
  customer_Title: Yup.string().required("Title is required"),
  job_Title: Yup.string().required("Title is required"),
  notification_Title: Yup.string().required("Title is required"),
  posttype: Yup.string().required("Post type is required"),
  subject: Yup.string()
    .required("Subject is required")
    .min(5, "Subject must be at least 5 characters"),
  desired_budget: Yup.number()
    .typeError("Desired Budget must be a number")
    .required("Desired Budget is required")
    .positive("Desired Budget must be a positive number"),
  max_budget: Yup.number()
    .typeError("Max Budget must be a number")
    .required("Max Budget is required")
    .min(
      Yup.ref("desired_budget"),
      "Max Budget must be equal to or greater than Desired Budget"
    ),
    registrationDeadline: Yup.date()
    .required("Registration Deadline is required")
    .min(new Date(), "Date cannot be in the past"), 
    dateofterminating: Yup.string().required("Registration Deadline is required")
    .min(new Date(), "Date cannot be in the past"), 
  country: Yup.string().required("Country is required"),
  description: Yup.string().required("Description is required"),
  customer_description: Yup.string().required("Description is required"),
  job_description: Yup.string().required("Description is required"),
  notification_description: Yup.string().required("Description is required"),
  users: Yup.string().required("Users field is required"),
  // Additional validations for new fields
  employexperience: Yup.number()
    .typeError("Experience must be a number")
    .required("Minimum years of experience is required")
    .min(0, "Experience can't be negative")
    .integer("Experience should be a whole number"),
  employperiodMonths: Yup.number()
    .typeError("Period in months must be a number")
    .required("Period in months is required")
    .min(0, "Period can't be negative")
    .integer("Period should be a whole number"),
  //  employscheduleType: Yup.string().required("Schedule type is required"),
  employscheduleType: Yup.array()
    .of(
      Yup.string().oneOf(
        ["full-time", "part-time", "freelancing"],
        "Invalid Schedule Type"
      )
    )
    .min(1, "At least one schedule type must be selected") // Ensures at least one type is selected
    .required("Schedule type is required"),
  employjobLocationType: Yup.string()
    .required("Job location type is required")
    .oneOf(
      ["Remote", "Specific Address", "Job Location"],
      "Invalid Job Location Type"
    ),
  externallink: Yup.string()
    .url(URLFieldValidationText)
    .required(RequiredFieldValidationText),
  employsalary: Yup.string().required("Salary is required"),
  jobreferenceid: Yup.string().required(
    "Job Opening as a Reference is required"
  ),
  notificationreferenceid: Yup.string().required(
    "Job Opening as a Reference is required"
  ),
  keywords: Yup.array().min(1, RequiredFieldValidationText).required(RequiredFieldValidationText),
  dateHoursExpiry: Yup.date()
    .nullable()
    .required("Expiry Date + Hour is required"),
    aarticalDescription: Yup.string()
        .max(160, "Description must be at most 160 characters")
        .required("Description is required"), // Adding required validation 
        certificateOfGraduation: Yup.object({ status: Yup.string().required("Status is required") }),
  officialTranscripts: Yup.object({ status: Yup.string().required("Status is required") }),
  governmentPhotoID: Yup.object({ status: Yup.string().required("Status is required") }),
  passport: Yup.object({ status: Yup.string().required("Status is required") }),
  proofOfLanguageProficiency: Yup.object({ status: Yup.string().required("Status is required") }),
  referenceLetters: Yup.object({ status: Yup.string().required("Status is required") }),
  statementOfPurpose: Yup.object({ 
    status: Yup.string().required("Status is required"),
    maxCharacters: Yup.number().required("Maximum Number of Characters is required") 
  }),
  essay: Yup.object({ 
    status: Yup.string().required("Status is required"),
    maxCharacters: Yup.number().required("Maximum Number of Characters is required") 
  }),
});
export interface PostFormValidationsProp {
  submitErrors: boolean;
  setSubmitError: (key: boolean) => void;
  errors: FormikErrors<CreatePostValidationProp>;
  values: any;
}
// Notification Screen 
export const notificationInitialValues: NotificationFormValues = {
  notification_Title: "",
  image: null,
  reference_coupon: "",
  targetCountry: "",
  notification_description: "",
  country: "",
};
export interface NotificationFormValues {
  notification_Title: string;
  image: File | null;
  reference_coupon?: string;
  targetCountry: string;
  notification_description: string;
  country: string;
};
export const notificationValidationSchema = Yup.object({
  notification_Title: Yup.string().required("Title is required"),
  image: Yup.mixed().required("Image is required"),
  reference_coupon: Yup.string().optional(),
  targetCountry: Yup.string().required("Target country is required"),
  notification_description: Yup.string().required("Description is required"),
  country: Yup.string().required("Country is required"),
});
