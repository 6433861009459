import { Field, FieldArray, Formik } from "formik";
import React, { useState } from "react";
import { Row, Col, Label, FormGroup, Input, Button, Form, ModalBody, ModalHeader, Modal } from "reactstrap";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import GroupMain from "../../../Communication/GroupMain";
import Groups from "../../../CommonInput/Groups";
import TagsInput from "../../../CommonInput/TagsInput";
import { Btn, H3 } from "../../../../AbstractElements";
import { setCategoryModal } from "../../../../ReduxToolkit/Reducers/ContactSlice";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { AddLabels, Cancel, Save } from "../../../../utils/Constant";
import { keywords } from "../../../Application/Contacts/ContactSideBar/CategoryCreate";
import { Typeahead } from "react-bootstrap-typeahead";
import { manageAlbumFormValidationSchema } from "../../../../Data/Tools/ManageContents/FormControlsValidation";
function SharingSection(props) {
    const {
        selectedToolType,
        values,
        setFieldValue, 
        errors,
        ImportInviteeList,
        CoManagement,
        OnlyInvitedUsers,
        InviteeList,
        viewOnly
    } = props;
    const [reloadGroupsData, setReloadGroupsData] = useState(0);
    const [openFromModal, setOpenFromModal] = useState(false);
    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
    const { categoryModal } = useAppSelector((state) => state.contact);
    const dispatch = useAppDispatch();
    const categoryToggle = () => dispatch(setCategoryModal());
    const reloadGroups = () => {
        setReloadGroupsData((prev) => prev + 1);
        console.log(reloadGroupsData);
    };
    console.log(values);
    const handleSubmit = () => {};
    return (
        <div>
           {InviteeList ?"" :(<> <Row>
                {![
                    "product",
                    "article",
                    "wish",
                    "class",
                    "work",
                    "job",
                    "registration",
                ].includes(selectedToolType) && (
                    <Col sm="auto">
                        <FormGroup>
                            <div>
                                <Label check>
                                    Who can open or use the link of this
                                    content?
                                </Label>
                            </div>
                            <div className="d-flex flex-wrap gap-2 align-items-center">
                                <div className="m-checkbox-inline px-2">
                                    <Label for={`edo-ani-a`} check>
                                        <Field
                                            className="radio_animated"
                                            id={`edo-ani-a`}
                                            type="radio"
                                            name="display_on_media_center"
                                            value={1}
                                            checked={
                                                values?.display_on_media_center ==
                                                "1"
                                            }
                                        />
                                        {` Anyone`}
                                    </Label>
                                </div>
                                <div className="m-checkbox-inline px-2 mt-1">
                                    <Label for={`edo-ani-b`} check>
                                        <Field
                                            className="radio_animated"
                                            id={`edo-ani-b`}
                                            type="radio"
                                            name="display_on_media_center"
                                            value={2}
                                            checked={
                                                values?.display_on_media_center ==
                                                "2"
                                            }
                                        />
                                        {`Only Followers and Invited Users`}
                                    </Label>
                                </div>
                                {OnlyInvitedUsers ? (
                                    ""
                                ) : (
                                    <div className="m-checkbox-inline px-2 mt-1">
                                        <Label for={`edo-ani-c`} check>
                                            <Field
                                                className="radio_animated"
                                                id={`edo-ani-c`}
                                                type="radio"
                                                name="display_on_media_center"
                                                value={3}
                                                checked={
                                                    values.display_on_media_center ==
                                                    "3"
                                                }
                                            />
                                            {`Only Invited Users`}
                                        </Label>
                                    </div>
                                )}
                            </div>
                        </FormGroup>
                    </Col>
                )}
            </Row>
            <h4 className="mb-2">Invitee List:</h4>
            <Row>
                <Col sm="4">
                    <FormGroup>
                        <Groups
                            title="Invite Groups"
                            className={""}
                            isRequired={false}
                            name="groups"
                            isMulti={true}
                            reloadGroupsData={reloadGroupsData}
                        />
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <span>Create a New Group</span>
                        <span
                            className="mt-1 btn btn-primary px-3 mx-2"
                            onClick={openFromModalToggle}
                        >
                            <i className="icofont icofont-users"></i>
                        </span>
                        <CommonModal
                            isOpen={openFromModal}
                            toggle={openFromModalToggle}
                            sizeTitle="Add a Group"
                            size="lg"
                        >
                            <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                                <GroupMain
                                    reloadGroups={reloadGroups}
                                    openFromModalToggle={openFromModalToggle}
                                />
                            </div>
                        </CommonModal>
                    </FormGroup>
                </Col>

                <Col sm="4">
                     
                        <div className=" col form-Label">
                            <FormGroup className=" ">
                                <Label check className="mb-1">
                                    Invite Usernames:
                                </Label>
                                <TagsInput
                                    name="individual_users"
                                    username={true}
                                    placeholder="Enter Invite Usernames"
                                    className={`form-control pt-0`}
                                    setFieldValue={setFieldValue}
                                />
                            </FormGroup>
                        </div> 
                     
                </Col>
                {ImportInviteeList ? (
                    ""
                ) : (
                    <Col sm="4">
                        <FormGroup>
                            <Label for="fileInput">
                                Import Invitee List (optional)
                            </Label>
                            <Input
                                id="fileInput"
                                name="file"
                                type="file"
                                className={`form-control ${
                                    errors.files && submitErrors
                                        ? "is-invalid"
                                        : "is-valid"
                                }`}
                                onChange={(event) => {
                                    if (event.target.files) {
                                        setFieldValue(
                                            "file",
                                            event.target.files[0]
                                        );
                                    }
                                }}
                                // invalid={!!(errors.file  )}
                            />
                        </FormGroup>
                    </Col>
                )}
            </Row></>) }
            {CoManagement ? (
                ""
            ) : (
                <Row>
                    <Col sm="12">
                        <div className="">
                            <H3> 
                                        Contents Co-Manager{" "}
                                        <span className="font-danger">*</span>
                                
                            </H3>
                            <small>
                            To increase your user base and traffic to your contents, <br /> a copy of your this content will displayed on Co-Managers' 1Webbb Centers from their accounts, without any accessing your account.
                            </small>

                            <Row className="mt-3 px-3">
                                <Col sm="12">
                                    <div className="mb-3">
                                        <Label className="d-block">
                                            Allow Co-Manager
                                        </Label>
                                        <div className="form-check form-check-inline radio radio-primary   mx-2">
                                            <Field
                                                id={`comanage_yes`}
                                                type="radio"
                                                name={`comanage`}
                                                value={1}
                                                checked={
                                                    values.comanage === 1 ||
                                                    values.comanage === "1"
                                                }
                                            />
                                            <Label
                                                className="mb-0"
                                                for={`comanage_yes`}
                                                check
                                            >
                                                Yes
                                            </Label>
                                        </div>
                                        <div className="form-check form-check-inline radio radio-primary   mx-2">
                                            <Field
                                                id={`comanage_no`}
                                                type="radio"
                                                value={0}
                                                checked={
                                                    values.comanage === 0 ||
                                                    values.comanage === "0"
                                                }
                                                name={`comanage`}
                                            />
                                            <Label
                                                className="mb-0"
                                                for={`comanage_no`}
                                                check
                                            >
                                                No
                                            </Label>
                                        </div>
                                    </div>
                                </Col>
                                {values.comanage === 1 ||
                                values.comanage === "1" ? (
                                    <FieldArray
                                        name="coManagers"
                                        render={(arrayHelpers) => (
                                            <>
                                                {values.coManagers.map(
                                                    (coManager, index) => (
                                                        <div
                                                            key={index}
                                                            className="border mt-2 row align-items-end rounded"
                                                        >
                                                            <Col
                                                                sm="4"
                                                                className="mt-2"
                                                            >
                                                                <FormGroup>
                                                                    <Label
                                                                        check
                                                                        className="mb-1"
                                                                    >
                                                                        Co-Manager
                                                                        Username
                                                                        :
                                                                    </Label>
                                                                    <Field
                                                                        name={`coManagers[${index}].username`}
                                                                        placeholder="Enter Invite Usernames"
                                                                        className="form-control pt-0"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col  sm="4" className="mb-3" >
                                                                <Label className="d-block">
                                                                    Permission
                                                                    (under
                                                                    "Tools"
                                                                    menu)
                                                                    <span className="font-danger">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                <div className="form-check form-check-inline radio radio-primary mx-2">
                                                                    <Field
                                                                        id={`read_yes_${index}`}
                                                                        type="radio"
                                                                        name={`coManagers[${index}].permission`}
                                                                        value={
                                                                            1
                                                                        }
                                                                        checked={
                                                                            coManager.permission ===
                                                                                1 ||
                                                                            coManager.permission ===
                                                                                "1"
                                                                        }
                                                                    />
                                                                    <Label
                                                                        className="mb-0"
                                                                        for={`read_yes_${index}`}
                                                                        check
                                                                    >
                                                                        View
                                                                        Only
                                                                    </Label>
                                                                </div>
                                                              {viewOnly ? '' :  <div className="form-check form-check-inline radio radio-primary mx-2">
                                                                    <Field
                                                                        id={`edit_${index}`}
                                                                        type="radio"
                                                                        name={`coManagers[${index}].permission`}
                                                                        value={
                                                                            0
                                                                        }
                                                                        checked={
                                                                            coManager.permission ===
                                                                                0 ||
                                                                            coManager.permission ===
                                                                                "0"
                                                                        }
                                                                    />
                                                                    <Label
                                                                        className="mb-0"
                                                                        for={`edit_${index}`}
                                                                        check
                                                                    >
                                                                        Edit
                                                                    </Label>
                                                                </div>}
                                                            </Col>
                                                            <Col
                                                                sm="4"
                                                                className="mb-2"
                                                            >
                                                                <Label className="d-block">
                                                                    List this
                                                                    content on
                                                                    Co-Manager's
                                                                    1Webbb Center{" "}
                                                                    <span className="font-danger">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                <div className="form-check form-check-inline radio radio-primary mx-2">
                                                                    <Field
                                                                        id={`Display_yes_${index}`}
                                                                        type="radio"
                                                                        name={`coManagers[${index}].dispaymedia`}
                                                                        value={
                                                                            1
                                                                        }
                                                                        checked={
                                                                            coManager.dispaymedia ===
                                                                                1 ||
                                                                            coManager.dispaymedia ===
                                                                                "1"
                                                                        }
                                                                    />
                                                                    <Label
                                                                        className="mb-0"
                                                                        for={`Display_yes_${index}`}
                                                                        check
                                                                    >
                                                                        Yes
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check form-check-inline radio radio-primary mx-2">
                                                                    <Field
                                                                        id={`Display_no_${index}`}
                                                                        type="radio"
                                                                        name={`coManagers[${index}].dispaymedia`}
                                                                        value={
                                                                            0
                                                                        }
                                                                        checked={
                                                                            coManager.dispaymedia ===
                                                                                0 ||
                                                                            coManager.dispaymedia ===
                                                                                "0"
                                                                        }
                                                                    />
                                                                    <Label
                                                                        className="mb-0"
                                                                        for={`Display_no_${index}`}
                                                                        check
                                                                    >
                                                                        No
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    )
                                                )}
                                                <div className="mt-3 mb-2 text-right">
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        onClick={() =>
                                                            arrayHelpers.push({
                                                                username: "",
                                                                permission: 0,
                                                                dispaymedia: 0,
                                                            })
                                                        }
                                                    >
                                                          <i className="fa fa-plus"></i>
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    />
                                ) : null}
                            </Row>
                        </div>
                    </Col>
                </Row>
            )}
             <Modal fade isOpen={categoryModal} toggle={categoryToggle}>
                <ModalHeader toggle={categoryToggle}>{AddLabels}</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{ usename: "" }}
                        onSubmit={handleSubmit}
                        validationSchema={manageAlbumFormValidationSchema}
                    >
                        {({ errors, values, setFieldValue }) => (
                            <Form className="form-bookmark">
                                <Row className="g-2">
                                    <Col md="12">
                                        <FormGroup>
                                            {/* <Input type="text" required placeholder={EnterKeywordName} autoComplete="off" /> */}
                                            {/* <TagsInput name="keywords" placeholder="Add keywords" setFieldValue={setFieldValue}/> */}
                                            <Typeahead
                                                id="multiple-typeahead"
                                                className="mt-2"
                                                labelKey="name"
                                                multiple
                                                options={keywords}
                                                placeholder={`Contact`}
                                            />
                                        </FormGroup>
                                        <FormGroup className=" ">
                                            <Label check className="mb-1">
                                                Invite Contacts:
                                            </Label>
                                            <TagsInput
                                                name="individual_users"
                                                username={true}
                                                placeholder="Enter Invite Contacts"
                                                className={`form-control pt-0`}
                                                setFieldValue={setFieldValue}
                                            />
                                        </FormGroup>
                                        <div >
                                            <Label check className="mb-1">
                                                Invite Usernames:
                                            </Label>
                                            <TagsInput
                                                name="individual_users"
                                                username={true}
                                                placeholder="Enter Invite Usernames"
                                                className={`form-control pt-0`}
                                                setFieldValue={setFieldValue}
                                            />
                                        </div>
                                    <Btn
                                    color="secondary"
                                    className="me-2" 
                                >
                                    Invite
                                </Btn>
                                    </Col>
                                </Row>
                                <Btn
                                    color="secondary"
                                    className="me-2"
                                    onClick={categoryToggle}
                                >
                                    {Save}
                                </Btn>
                                <Btn color="primary" onClick={categoryToggle}>
                                    {Cancel}
                                </Btn>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default SharingSection;
