import React from 'react'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'  

import MyjobstudyApplicationsContainer from '../../../Component/Application/MyjobstudyApplications/MyjobstudyApplicationsContainer'
const MyjobstudyApplications = () => {
  return (
    <div className='page-body'>
        <Breadcrumbs mainTitle={`My job and study Applications`} parent={`User Dashboard`} />
        <MyjobstudyApplicationsContainer/>
    </div>
  )
}

export default MyjobstudyApplications 