import {useState} from 'react'
import { ManageCoupons } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { AddonCategoryColumnData,  supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { CouponTableAction  } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import ManageOnlineSubMenu from '../ManageOnlineSubMenu/ManageOnlineSubMenu'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'

const ManageMyCoupons = () => {
    const toggleDelete = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const handleRowSelected =() =>{}

    return (
    <div className='page-body'>
      <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
            <SubMenuPopup  currentPage="Coupon List "/>
                </div> 
            <Breadcrumbs mainTitle={`${ManageCoupons} (Manage Store)`} mainParent="All Tools" parent={`Manage Store`} />
      </div>
      
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card > 
            <div className='mt-2'>
                {CouponTableAction.map((action, index)=>{
                  return(
                  <>
                    <Link to={`${process.env.PUBLIC_URL}/${action.path}`} key={index}><span className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                  </>)
                })}
                </div>
              <CardBody className='p-2'>
                <>              
                
                {/* </Col></Row> */}
                <div className="table-responsive">
                  <DataTable className='custom-scrollbar' columns={AddonCategoryColumnData} data={supportTicketData} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                </div>
                
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ManageMyCoupons