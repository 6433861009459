import { Col, Container, Row } from "reactstrap";
import { H3, H6, Image, LI, P, SVG, UL } from "../../AbstractElements";
import { dynamicImage } from "../../Service";
import { useAppSelector, useAppDispatch } from "../../ReduxToolkit/Hooks";
import { Link } from "react-router-dom";
import { useLogoutMutation } from "../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import { logout } from "../../ReduxToolkit/Reducers/Authentication/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../../Component/Loading/Loading";

export const footerImportant = [
  { title: "Media", link: "/see-all-contents" },
  { title: "Contest", link: "/contests" },
  { title: "Collection", link: "/services-and-products" },
];

export const footerPrivate = [
  { title: "My    1Webbb", link: "/about" },
  { title: "Logout" },
];

export const footer_help = [
  { title: "Terms & Conditions", link: "/see-all-contents" },
  { title: "Privacy & Policy", link: "/see-all-contents" },
  { title: "FAQs", link: "/see-all-contents" },
  { title: "Licence", link: "/see-all-contents" },
  { title: "Contact us", link: "/see-all-contents" },
];

const Footer = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);

  const [logoutApiCall, { isLoading: logoutLoading }] = useLogoutMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutUser = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      toast.success("Logout Succesfully", { position: "bottom-right" });
      navigate("/");
    } catch (error) {
      toast.error("Something went wrong!", { position: "bottom-right" });
    }
  };

  const footerLink = userInfo
    ? [...footerImportant, ...footerPrivate]
    : footerImportant;

  return (
    <>
      <Container fluid className="bg-dark py-5">
        <Row>
          <Col md="3" className="offset-md-1 offset-0">
            <Image
              className="image-radius m-r-15 align-top"
              src={dynamicImage("logo/logo-1.png")}
              alt="user121"
              style={{ width: "89px" }}
            />
            <H6 className="text-light  pt-3 w-50">{`2 B with 1 Web, `}</H6>
            <span>{`grow with a multi-purpose web platform.`}</span>
          </Col>
          <Col md="3" className="pt-2 offset-md-1 offset-0">
            <div>
              <H3 className="text-light py-1">{`Help and Contact`}</H3>
              <UL className="">
                {websiteSettingsData.footer_pages &&
                  websiteSettingsData.footer_pages.map(
                    (item: any, index: number) => (
                      <LI key={index}>
                        <H6 className="text-light py-1">
                          <Link style={{ color: 'white' }} to={`statics/${item.id}/${item.slug}`}>
                            {item.title}
                          </Link>
                        </H6>
                      </LI>
                    )
                  )}
              </UL>
            </div>
          </Col>
          <Col md="3" className="pt-2 offset-md-1 offset-0">
            <div>
              <H3 className="text-light py-1">{`Important`}</H3>
              <UL className="">
                {footerLink.map((item, index) => {
                  let content;
                  if (item.title === "Logout") {
                    content = (
                      <div onClick={logoutUser} className="text-light  py-1">
                        {item.title}
                      </div>
                    );
                    content = logoutLoading ? <Loading /> : content;
                  } else {
                    content = (
                      <Link
                        to={item.link ? item.link : ""}
                        className="text-light  py-1"
                      >
                        {item.title}
                      </Link>
                    );
                  }
                  return <LI key={index}>{content}</LI>;
                })}
              </UL>
            </div>
          </Col>
        </Row>
      </Container>

      <footer className="footer">
        <Container fluid>
          <Row>
            <Col md="6" className="p-0 footer-copyright">
              <P className="mb-0 px-4">Copyright 2024 © 1webbb.com</P>
            </Col>
            <Col md="6" className="p-0">
              <P className="mb-0 heart">
                Hand crafted &amp; made with
                <SVG iconId="heart" className="footer-icon" />
              </P>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
