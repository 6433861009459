import { MenuItem } from "../../Types/Layout/SidebarType";
import { faFaceGrinHearts, fas } from "@fortawesome/free-solid-svg-icons";

export const PrivateMenuNames = [
    "Communication",
    "My 1Webbb Center",
    "Tools",
    "Create a Post",
];

export const MenuList: MenuItem[] = [
    {
        title: "",
        lanClass: "lan-1",
        Items: [
            {
                path: `${process.env.PUBLIC_URL}/see-all-contents`,
                title: "Media",
                type: "link",
                id: 0,
                icon: fas.faEye,
            },
            {
                title: "Dashboard",
                icon: fas.faTachometerAlt,
                id: 13,
                children: [
                    {
                        title: "User Dashboard",
                        path: `${process.env.PUBLIC_URL}/dashboard/user-dashboard`,
                    },
                    {
                        title: "My Job and Study Applications",
                        path: `${process.env.PUBLIC_URL}/dashboard/my-job-and-study-applications`,
                    },
                    {
                        title: "My Grades and Surveys",
                        path: `${process.env.PUBLIC_URL}/dashboard/my-grades-and-surveys`,
                    },
                    {
                        title: "My Reservations and Attendance",
                        path: `${process.env.PUBLIC_URL}/dashboard/my-reservations-and-attendance`,
                    },
                    {
                        title: "My Purchases",
                        path: `${process.env.PUBLIC_URL}/dashboard/my-purchases`,
                    },
                    {
                        title: "Transactions",
                        path: `${process.env.PUBLIC_URL}/dashboard/transactions-history`,
                    },
                    {
                        title: "Payment Methods",
                        path: `${process.env.PUBLIC_URL}/dashboard/payment-methods/`,
                    },
                    {
                        title: "Add a Payment Methods",
                        path: `${process.env.PUBLIC_URL}/dashboard/payment-methods/add-new`,
                    },
                    {
                        title: "Withdrawals",
                        path: `${process.env.PUBLIC_URL}/dashboard/withdrawals`,
                    },

                    // { name: "Sales", link: `${process.env.PUBLIC_URL}/dashboard/my-sales` },
                    {
                        title: "Referrals",
                        link: `${process.env.PUBLIC_URL}/dashboard/referrals`,
                    },
                ],
            },
            {
                title: "Account Settings",
                icon: fas.faGear,
                id: 14,
                children: [
                    {
                        title: "Personal Information",
                        path: `${process.env.PUBLIC_URL}/account-settings/personal-information`,
                    },
                    {
                        title: "Resume",
                        path: `${process.env.PUBLIC_URL}/account-settings/manage-resume`,
                    },
                    {
                        title: "Work Availability Status ",
                        type: "popup",
                        modalType: "WorkAvailability",
                    },
                    {
                        title: "Shipping Address",
                        path: `${process.env.PUBLIC_URL}/account-settings/shipping-addresses`,
                    },
                    {
                        title: "Add a Shipping Address",
                        path: `${process.env.PUBLIC_URL}/account-settings/shipping-addresses/add-new`,
                    },
                    {
                        title: "Password",
                        path: `${process.env.PUBLIC_URL}/account-settings/password`,
                    },
                    {
                        title: "Registration Plan",
                        path: `${process.env.PUBLIC_URL}/account-settings/my-registration-plan`,
                    },
                    {
                        title: "Edit Registration Plan Account History ",
                        path: `${process.env.PUBLIC_URL}/account-settings/modify-registration-plan`,
                    },
                    {
                        title: "Account History",
                        path: `${process.env.PUBLIC_URL}/account-settings/account-history`,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/communication/contacts`,
                        title: "Contact List",
                    },
                ],
            },
            {
                // path: `${process.env.PUBLIC_URL}/knowledge`,
                title: "Knowledge",
                type: "sub",
                id: 1,
                icon: fas.faBook,
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/knowledge/lessons`,
                        title: "Lessons",
                        type: "link",
                        icon: fas.faPersonChalkboard,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/knowledge/quizzes-and-tests`,
                        title: "Quizzes and Tests",
                        type: "link",
                        icon: fas.faClipboardQuestion,
                    },
                ],
            },
            {
                path: `${process.env.PUBLIC_URL}/contests`,
                title: "Contests",
                type: "link",
                id: 2,
                icon: fas.faTrophy,
            },
            {
                title: "Posts",
                id: 3,
                icon: fas.faSignsPost,
                type: "sub",
                active: false,
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-posts/add-new `,
                        title: "Create a Post",
                        type: "link",
                        icon: fas.faPenNib,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/post/activities-and-events`,
                        title: "Activities and Events",
                        type: "link",
                        icon: fas.faPeopleGroup,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/post/articles-and-blogs`,
                        title: "Articles and Blogs ",
                        type: "link",
                        icon: fas.faNewspaper,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/post/customers-wishes`,
                        title: "Customers’ Wishes",
                        type: "link",
                        icon: fas.faHandSparkles,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/post/job-openings`,
                        title: "Job Openings",
                        type: "link",
                        icon: fas.faBuildingCircleExclamation,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/posts/surveys`,
                        title: "Surveys",
                        type: "link",
                        icon: fas.faCircleQuestion,
                    },
                ],
            },
            {
                title: "Communication",
                id: 8,
                icon: fas.faPodcast,
                type: "sub",
                active: false,
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/communication/start-classified-chat`,
                        title: "Start Classified Chat",
                        type: "link",
                        icon: fas.faCommentMedical,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/communication/message-threads-inbox`,
                        type: "link",
                        title: "Inbox",
                        icon: fas.faEnvelope,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/communication/private-classified-chat`,
                        type: "link",
                        title: "Private C-Chat",
                        icon: fas.faCommentDots,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/communication/activities-and-events-classified-chat`,
                        type: "link",
                        title: "Activity and Event C-Chat ",
                        icon: fas.faPeopleGroup,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/communication/applications-classified-chat`,
                        type: "link",
                        title: "Applications: for work and study",
                        icon: fas.faEnvelopeOpenText,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/communication/marketplace-classified-chat`,
                        type: "link",
                        title: "Marketplace C-Chat",
                        icon: fas.faCommentsDollar,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/communication/projects-classified-chat`,
                        type: "link",
                        title: "Project C-Chat",
                        icon: fas.faUsersRectangle,
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/communication/contacts`,
                        title: "Contact List",
                        type: "link",
                        active: false,
                        icon: fas.faAddressBook,
                    },
                    {
                        title: "Notification Screen on My Media Center",
                        path: `${process.env.PUBLIC_URL}/communication/add-public-notification-screen-on-my-media-center`,
                        type: "link",
                        active: false,
                        icon: fas.faUser,
                    },
                ],
            },
            {
                path: `${process.env.PUBLIC_URL}/services-and-products`,
                title: "Marketplace",
                id: 6,
                icon: fas.faStore,
                type: "link",
            },
            {
                path: `${process.env.PUBLIC_URL}/coupons`,
                title: "Coupons",
                type: "link",
                icon: fas.faMoneyCheckDollar,
                id: 4,
            },

            {
                id: 11,
                title: "My 1Webbb Center",
                icon: fas.faArrowsToEye,
                type: "sub",
                active: false,
                children: [
                    {
                        title: "About",
                        type: "link",
                        path: `${process.env.PUBLIC_URL}/about`,
                        icon: fas.faAddressCard,
                    },
                    {
                        icon: fas.faCalendarDays,
                        path: `${process.env.PUBLIC_URL}/my-media-center/calendar`,
                        type: "link",
                        title: "Calendar",
                    },
                    {
                        icon: fas.faShop,
                        path: `${process.env.PUBLIC_URL}/online-store`,
                        type: "link",
                        title: "Online Store",
                    },
                    {
                        title: "Coupons",
                        type: "link",
                        icon: fas.faMoneyCheckDollar,
                        path: `${process.env.PUBLIC_URL}/my-coupons`,
                    },
                    {
                        title: "My Posts",
                        type: "link",
                        icon: fas.faSignsPost,
                        path: `${process.env.PUBLIC_URL}/my-media-center/posts`,
                    },
                    {
                        title: "From My Web Masters",
                        type: "link",
                        icon: fas.faFaceGrinHearts,
                        path: `${process.env.PUBLIC_URL}/from-my-media-masters`,
                    },
                    {
                        title: "Photos",
                        type: "link",
                        icon: fas.faImage,
                        path: `${process.env.PUBLIC_URL}/photos`,
                    },
                    {
                        title: "Photo Contests",
                        type: "link",
                        icon: fas.faTrophy,
                        path: `${process.env.PUBLIC_URL}/my-media-center/photo-contests`,
                    },
                    {
                        title: "Albums",
                        type: "link",
                        icon: fas.faImages,
                        path: `${process.env.PUBLIC_URL}/albums`,
                    },
                    {
                        title: "Visual Ideas",
                        type: "link",
                        icon: fas.faLightbulb,
                        path: `${process.env.PUBLIC_URL}/visual-ideas`,
                    },
                    {
                        title: "Likes",
                        type: "link",
                        icon: fas.faHeart,
                        path: `${process.env.PUBLIC_URL}/likes`,
                    },
                    {
                        title: "Reviews ",
                        type: "link",
                        icon: fas.faComment,
                        path: `${process.env.PUBLIC_URL}/reviews`,
                    },
                    {
                        title: "Activities and Events",
                        type: "link",
                        icon: fas.faPeopleRoof,
                        path: `${process.env.PUBLIC_URL}/my-activities-and-events`,
                    },
                    {
                        title: "Frequently Asked Questions",
                        type: "link",
                        icon: fas.faCircleQuestion,
                        path: `${process.env.PUBLIC_URL}/frequently-asked-questions`,
                    },
                    {
                        title: "Quizzes and Surveys",
                        type: "link",
                        icon: fas.faCircleQuestion,
                        path: `${process.env.PUBLIC_URL}/quizzes-surveys`,
                    },
                    {
                        title: "Followers",
                        type: "link",
                        icon: fas.faUsersRays,
                        path: `${process.env.PUBLIC_URL}/followers`,
                    },
                    {
                        title: "I Follow",
                        type: "link",
                        icon: fas.faPersonWalkingArrowRight,
                        path: `${process.env.PUBLIC_URL}/i-follow`,
                    },
                    {
                        title: "I Downloaded",
                        type: "link",
                        icon: fas.faCloudArrowDown,
                        path: `${process.env.PUBLIC_URL}/i-downloaded`,
                    },
                    {
                        title: "External Links",
                        type: "link",
                        icon: fas.faLink,
                        path: `${process.env.PUBLIC_URL}/external-links`,
                    },

                    {
                        title: "Pending Approval",
                        type: "link",
                        icon: fas.faCircleHalfStroke,
                        path: `${process.env.PUBLIC_URL}/pending-photos`,
                    },
                ],
            },

            {
                title: "Tools",
                id: 12,
                icon: fas.faWandMagicSparkles,
                type: "sub",
                active: false,
                children: [
                    {
                        title: "Manage Store",
                        id: 1,
                        Items: [
                            {
                                icon: fas.faBuildingFlag,
                                subtitle: "Manage Sales and Branches",
                                description:
                                    "Manage sales, also add, edit and manage branches ",
                                path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/sales`,
                                children: [
                                    {
                                        actionName: "Sales",
                                        path: "tools/manage-online-store/manage-branches/sales",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: " Add a Branch",
                                        path: "tools/manage-online-store/manage-branches/add-new",
                                         pageType: 'form'
                                    },
                                    {
                                        actionName: "Branch List",
                                        path: "tools/manage-online-store/manage-branches",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Verfied Branch Tags",
                                        path: "",
                                        modalType: "Verfied Branch Tags",
                                         pageType: 'form'
                                    },
                                    {
                                        actionName: "Branch Photographer",
                                        path: "",
                                        modalType: "Branch Photographer",
                                         pageType: 'form'
                                    },
                                    {
                                        actionName: "Branch Contacts",
                                        path: "tools/manage-online-store/manage-branches/branch-public-contacts",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add Branch Contacts",
                                        path: "tools/manage-online-store/manage-branches/branch-public-contacts/add-new",
                                         pageType: 'form'
                                    },
                                    // {actionName:"Add Branch Contacts", path:'tools/manage-online-store/manage-branches/branch-public-contacts/add-new' },
                                    {
                                        actionName: "Schedules",
                                        path: "tools/manage-online-store/manage-branches/schedules",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add Schedules",
                                        path: "tools/manage-online-store/manage-branches/schedules/add-new",
                                        pageType: 'form'
                                    },

                                    {
                                        actionName: "Add an Account Assistant",
                                        path: "",
                                        modalType: "Add an Account Assistant",
                                        pageType: 'form'
                                    },
                                    // {actionName:"Add a Schedule", path:'tools/manage-online-store/manage-branches/schedules/add-new' },
                                ],
                            },
                            {
                                icon: fas.faGifts,
                                subtitle: "Manage Products and Services",
                                description:
                                    "Add, edit and manage your products and services",
                                path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-products-and-services`,
                                children: [
                                    {
                                        actionName: "Product and Service List ",
                                        path: "tools/manage-online-store/manage-products-and-services",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Product or Service",
                                        path: "tools/manage-online-store/manage-products-and-services/add-new",
                                        pageType: 'form'
                                    },
                                    // {actionName:"Import Transition", path:'' },
                                    {
                                        actionName: "Publish Online",
                                        path: "",
                                        modalType: "productservicePublishOnline",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName:
                                            "Import Bulk Products and Services",
                                        path: "",
                                        modalType: "importBulkProductService",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Import Bulk Images",
                                        path: "",
                                        modalType: "importBulkProductImages",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faArrowsDownToLine,
                                subtitle: "Addon Categories",
                                description:
                                    "Add, edit and manage add-on with your products and services",
                                children: [
                                    {
                                        actionName: "Addon List ",
                                        path: "tools/manage-online-store/addon-categories",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "New Addon",
                                        path: "tools/manage-online-store/addon-categories/add-new",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Import Bulk Addons",
                                        path: "",
                                        modalType: "importBulkAddons",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faMoneyCheckDollar,
                                subtitle: "Coupon System",
                                description:
                                    "Offer discounts and manage coupons.",
                                children: [
                                    {
                                        actionName: "Coupon List ",
                                        path: "tools/manage-online-store/manage-my-coupons",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Coupon",
                                        path: "tools/manage-online-store/manage-my-coupons/add-new",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faTruckFast,
                                subtitle: "Delivery Service",
                                description:
                                    "Add, edit, manage your delivery fees and delivery workers",
                                children: [
                                    {
                                        actionName: "Delivery Worker List",
                                        path: "tools/manage-online-store/delivery-system/delivery-workers",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Delivery Worker ",
                                        path: "tools/manage-online-store/delivery-system/delivery-workers/add-new",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Delivery Fee List ",
                                        path: "tools/manage-online-store/delivery-system/delivery-fees-Settings",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add  Delivery Fees ",
                                        path: "tools/manage-online-store/delivery-system/delivery-fees-Settings/add-new",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "1Webbb Delivery ",
                                        path: "",
                                        modalType: "1webbbDelivery",
                                        pageType: 'form'
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        id: 2,
                        title: "Manage Media",
                        Items: [
                             
                            {
                                icon: fas.faImages,
                                subtitle: "Manage Albums",
                                description:
                                    "Group  your contents under a specific idea or album name.",
                                children: [
                                    {
                                        actionName: "Album List",
                                        path: "tools/manage-visual-contents/manage-my-albums",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add an Album ",
                                        path: "",
                                        modalType: "addAlbum",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faLightbulb,
                                subtitle: "Manage Visual Ideas",
                                description:
                                    "Group contents you like from any user, under specific visual ideas",
                                path: `${process.env.PUBLIC_URL}/tools/manage-visual-contents/manage-my-visual-ideas`,
                                children: [
                                    {
                                        actionName: "Visual Idea List ",
                                        path: "tools/manage-visual-contents/manage-my-visual-ideas",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Visual Idea",
                                        path: "",
                                        modalType: "addVisualIdea",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faImage,
                                subtitle: "Manage Uploaded Media",
                                path: `${process.env.PUBLIC_URL}/tools/manage-contents/my-uploaded-visual-contents`,
                                children: [
                                    {
                                        actionName: "Media List ",
                                        path: "tools/manage-contents/my-uploaded-visual-contents",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Upload Media",
                                        path: "/upload",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Media Trash",
                                        path: "tools/manage-visual-contents/trash",
                                        pageType: 'table'
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        id: 3,
                        title: "Brand Support Tools",
                        Items: [
                            {
                                icon: fas.faSignsPost,
                                subtitle: "Manage Posts",
                                description:
                                    "Add and manage articles, blogs, job openings, and customer’s wishes of a product of service.",
                                 
                                children: [
                                    {
                                        actionName: "Post List",
                                        path: "tools/brand-support-tools/manage-posts",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Post",
                                        path: "tools/brand-support-tools/manage-posts/add-new",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Publish Online",
                                        path: "",
                                        modalType: "postPublish",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faPersonShelter,
                                subtitle: "Activities and Attendance",
                                description:
                                    "Manage attendance, registration data, appointments, events, classes, work shifts.",
                                path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/activities-and-attendance`,
                                children: [
                                    {
                                        actionName: "Activity and Event List ",
                                        path: "tools/brand-support-tools/activities-and-attendance",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add an Activity ",
                                        path: "tools/brand-support-tools/activities-and-attendance/add-new-activity",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Publish Online",
                                        path: "",
                                        modalType: "activitesPublish",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faCircleQuestion,
                                subtitle: "Frequently Asked Question System",
                                description:
                                    "Easily answer common questions from your customers, followers, students etc…   ", 
                                children: [
                                    {
                                        actionName:
                                            "Frequently Asked Question List ",
                                        path: "tools/brand-support-tools/frequently-asked-question-system",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName:
                                            "Add Frequently Asked Questions ",
                                        path: "tools/brand-support-tools/frequently-asked-question-system/add-new",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Publish Online",
                                        path: "",
                                        modalType: "frequentlyPublish",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faScroll, 
                                subtitle: "Policy or Service Terms",

                                path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/policies-and-service-terms`,
                                children: [
                                    {
                                        actionName: "Policy or Service Terms List ",
                                        path: "tools/brand-support-tools/policies-and-service-terms",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName:
                                            "Add Policy or Service Terms",
                                        path: "tools/brand-support-tools/policies-and-service-terms/add-new",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faLink,
                                subtitle: "Manage External Links",
                                description:
                                    "Group you favourite and recommended web pages in one place to help followers and customers find them easily.",
                                path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-my-external-links`,
                                children: [
                                    {
                                        actionName: "External Link List ",
                                        path: "tools/brand-support-tools/manage-my-external-links",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add an External Link",
                                        path: "tools/brand-support-tools/manage-my-external-links/add-new",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faTrophy,
                                subtitle: "Media Contest System",
                                description:
                                    "Create and manage photo contests to reward and interact with followers and customers.",
                                path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-my-photo-contests`,
                                children: [
                                    {
                                        actionName: "Media Contest List",
                                        path: "tools/brand-support-tools/manage-my-photo-contests",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Media Contest ",
                                        path: "tools/brand-support-tools/manage-my-photo-contests/add-new",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Publish Online",
                                        path: "",
                                        modalType: "mediaContestPublish",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faBook,
                                subtitle: "Knowledge  System",
                                description:
                                    "Add and manage educational games, exams, tests, surveys, lessons, tutorials, training materials…",
                                path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/questionnaires-quizzes-and-surveys`,
                                children: [
                                    {
                                        actionName: "Quizzes and Surveys - Questionnaire List",
                                        path: "tools/brand-support-tools/knowledge/questionnaires-quizzes-and-surveys",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Educational Games - Questionnaire List",
                                        path: "tools/brand-support-tools/knowledge/questionnaires-educational-games",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Questionnaire",
                                        path: "tools/brand-support-tools/knowledge/questionnaire-list/add-new",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Questions List",
                                        path: "tools/brand-support-tools/knowledge/question-list",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Publish Online",
                                        path: "",
                                        modalType: "knowledgePublish",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Lesson List ",
                                        path: "tools/brand-support-tools/knowledge/lessons",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Lesson",
                                        path: "tools/brand-support-tools/knowledge/lessons/add-new",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Question Group List ",
                                        path: "tools/brand-support-tools/knowledge/question-groups",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Question Group ",
                                        modalType: "addQuestionGroup",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName: "Quiz Material List ",
                                        path: "tools/brand-support-tools/knowledge/quiz-materials",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName: "Add a Quiz Material",
                                        path: "",
                                        modalType: "addQuizzMaterial",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName:
                                            "Course and Teacher Information List ",
                                        path: "tools/brand-support-tools/knowledge/course-and-teacher-information",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName:
                                            "Add Course and Teacher Information ",
                                        path: "",
                                        modalType: "addCourseInfo",
                                        pageType: 'form'
                                    },
                                    {
                                        actionName:
                                            "Questionnaire Settings List ",
                                        path: "tools/brand-support-tools/knowledge/questionnaire-settings",
                                        pageType: 'table'
                                    },
                                    {
                                        actionName:
                                            "Add Questionnaire Settings",
                                        path: "",
                                        modalType: "addQuestionnaireSettings",
                                        pageType: 'form'
                                    },
                                ],
                            },
                            {
                                icon: fas.faBriefcase,
                                subtitle: "Manage Projects",
                                description:
                                    "Manage your work with your team and clients",
                                path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list`,
                                children: [
                                    {
                                        actionName: "Manage Projects List ",
                                        path: "tools/brand-support-tools/project-list",
                                        pageType: 'table'
                                    }, 
                                    {
                                        actionName: "Create a New Project",
                                        path: "tools/brand-support-tools/project-list/new-project",
                                        pageType: 'form'
                                    }, 
                                    {
                                        actionName: "Tasks",
                                        path: "tools/brand-support-tools/project-list/task-list",
                                        pageType: 'table'
                                    }, 
                                    {
                                        actionName: "To Dos",
                                        path: "tools/brand-support-tools/project-list/to-do-list",
                                        pageType: 'table'
                                    }, 
                                ],
                            },
                            {
                                icon: fas.faHeadset,
                                subtitle: "Support Ticket System",
                                description:
                                    "Manage customer service and satisfaction effectively",
                                path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/support-ticket-system`,
                                children: [
                                    {
                                        actionName: "Support Ticket System List ",
                                        path: "tools/brand-support-tools/support-ticket-system",
                                        pageType: 'table'
                                    }, 
                                ],
                            },
                            {
                                icon: fas.faFileInvoice,
                                subtitle: "Ready Templates",
                                description:
                                    "Save time and use high quality templates",
                                path: `${process.env.PUBLIC_URL}/tools/brand-support/ready-templates`,
                                children: [
                                    {
                                        actionName: "Ready Templates List ",
                                        path: "tools/brand-support/ready-templates",
                                        pageType: 'table'
                                    }, 
                                ],
                            },
                        ],
                    },
                    
                ],
            },
        ],
    },
];
