import { Card, CardBody, Col, Container, Row } from "reactstrap";
 
import { useCallback, useState } from "react";
 
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Communication, Contacts, NotificationScreen } from "../../../utils/Constant";
import CommunicationSubMenu from "../Chat/CommunicationSubMenu";
import { notificationInitialValues, notificationValidationSchema } from "../../../Data/Post/CreatePostValidationSchema";
import { Formik } from "formik";
import NotificationScreenForm from "./NotificationScreenForm";

const NotificationScreenContainer = () => {
  const [activeTab, setActiveTab] = useState("2");
  const callback = useCallback((tab: string) => {
    setActiveTab(tab);
  }, []);
  const [submitErrors, setSubmitErrors] = useState(false);

    const handleSubmit = (values: NotificationFormValues, { setSubmitting }: any) => {
        console.log("Form Submitted:", values);
        setSubmitting(false);
        setSubmitErrors(true); // Simulating submission errors
    };
  return (
    <>
      <Breadcrumbs mainTitle={NotificationScreen} parent={Communication} />
      <CommunicationSubMenu/>
      <Container fluid>
        <div className="  bookmark-wrap">
        <Formik
            initialValues={notificationInitialValues}
            validationSchema={notificationValidationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched }) => (
                <Card> 
                <CardBody>
                    <NotificationScreenForm submitErrors={submitErrors} values={values} errors={errors} touched={touched}/>
                </CardBody>
                </Card>
)}
        </Formik>
        </div>
      </Container>
    </>
  );
};

export default NotificationScreenContainer;
