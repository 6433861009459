// ReduxToolkit/Reducers/ActiveHeaderRightIcon.js
import { createSlice } from '@reduxjs/toolkit';
import { log } from 'util';

const NavigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    activeItem: null, // or default value
    modalOpen: false,
  },
  reducers: {
    setActiveItem: (state, action) => {
      state.activeItem = action.payload;
    },
    openModal: (state) => {
      state.modalOpen = true;
    },
    closeModal: (state) => {
      state.modalOpen = false;
    },
  },
});

export const { setActiveItem, openModal, closeModal } = NavigationSlice.actions;
export default NavigationSlice.reducer;
