import UserBanner from "../../../Component/App/User/UserBanner";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useState, useEffect, useRef, useMemo } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  AdditionalServices,
  MyRegistrationPlans,
  AccountSettings,
  MyCurrentPlan,
  NoDataAvailable,
} from "../../../utils/Constant";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCollapse from "../../../CommonElements/CardHeaderCommon/CardHeaderCollapse";
import { useGetCurrentRegistrationPlanMutation } from "../../../ReduxToolkit/Reducers/RegistrationPlan/registrationPlanApiSlice";
import { toast } from "react-toastify";
import Loading from "../../../Component/Loading/Loading";
import StaticPlanData from "../../../Component/Pricing/StaticPlanData";
import DynamicPlanData from "../../../Component/Pricing/DynamicPlanData";
import { useGetRegistrationPlanListQuery } from "../../../ReduxToolkit/Reducers/Registration/Plan/planApliSlice";
import { useAppSelector, useAppDispatch } from "../../../ReduxToolkit/Hooks";
import { useGetCountryListMutation } from "../../../ReduxToolkit/Reducers/Country/countryApiSlice";
import { setCounteries } from "../../../ReduxToolkit/Reducers/commonInputSlice";
import CurrentPlanSummary from "./RegistrationPlan/CurrentPlanSummary";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import CustomGrid from "../../../Component/CommonDataTable/CustomGrid";

export const TableHeadColumn = ({ title }: any) => {
  return <span style={{ whiteSpace: "break-spaces" }}>{title}</span>;
};

export const AdditionalDetailsColumns: TableColumn<any>[] = [
  {
    name: <TableHeadColumn title={`Order No`} />,
    selector: (row) => row.order_no,
    sortable: false,
    center: true,
    id: "order_no",
  },
  {
    name: <TableHeadColumn title={`Service Name`} />,
    selector: (row) => row.service_name,
    sortable: true,
    center: true,
    id: "service_name",
  },
  {
    name: <TableHeadColumn title={`Plan Period`} />,
    selector: (row) => row.plan_period,
    sortable: true,
    right: true,
    id: "plan_period",
  },
  {
    name: <TableHeadColumn title={`Currency Symbol`} />,
    selector: (row) => row.currency_symbol,
    sortable: true,
    center: true,
    id: "currency_symbol",
  },
  {
    name: <TableHeadColumn title={`Currency Name`} />,
    selector: (row) => row.currency_name,
    sortable: true,
    center: true,
    id: "currency_name",
  },

  {
    name: <TableHeadColumn title={`Applied Discount`} />,
    selector: (row) => row.applied_discount,
    sortable: true,
    center: true,
    id: "applied_discount",
  },

  {
    name: <TableHeadColumn title={`Daily Price`} />,
    selector: (row) => row.daily_price,
    sortable: true,
    center: true,
    id: "daily_price",
  },

  {
    name: <TableHeadColumn title={`Monthly Price`} />,
    selector: (row) => row.monthly_price,
    sortable: true,
    center: true,
    id: "monthly_price",
  },
  {
    name: <TableHeadColumn title={`Total Price`} />,
    selector: (row) => row.total_price,
    sortable: true,
    center: true,
    id: "total_price",
  },
  {
    name: <TableHeadColumn title={`Left Days`} />,
    selector: (row) => row.left_days,
    sortable: true,
    center: true,
    id: "left_days",
  },
  {
    name: <TableHeadColumn title={`Remaining Order Value`} />,
    selector: (row) => row.remaining_order_value,
    sortable: true,
    center: true,
    id: "remaining_order_value",
  },
  {
    name: <TableHeadColumn title={`Service Data`} />,
    selector: (row) => row.service_data,
    sortable: true,
    center: true,
    id: "service_data",
  },
  {
    name: <TableHeadColumn title={`Payment Status`} />,
    selector: (row) => row.payment_status,
    sortable: true,
    center: true,
    id: "payment_status",
  },
  {
    name: <TableHeadColumn title={`Renewed At`} />,
    selector: (row) => row.renewed_at,
    sortable: true,
    center: true,
    id: "renewed_at",
  },
  {
    name: <TableHeadColumn title={`Exprires At`} />,
    selector: (row) => row.expires_at,
    sortable: true,
    center: true,
    id: "expires_at",
  },
  {
    name: <TableHeadColumn title={`Created At`} />,
    selector: (row) => row.created_at,
    sortable: true,
    center: true,
    id: "created_at",
  },
  {
    name: <TableHeadColumn title={`Updated At`} />,
    selector: (row) => row.updated_at,
    sortable: true,
    center: true,
    id: "updated_at",
  },
];

const MyRegistrationPlan = () => {
  const [isAdditionalServiceOpen, setIsAdditionalServiceOpen] = useState(true);
  const [isMyPlanOpen, setIsMyPlanOpen] = useState(true);
  const [currentRegistraionPlan, setCurrentRegistrationPlan] = useState<any>(
    []
  );
  const [countryId, setCountryId] = useState<number>(0);
  const [plans, setPlans] = useState<any>();   

  const dispatch = useAppDispatch();

  // Create a reference for the table
  const tableRef: any = useRef();

  // get country list
  const { countries } = useAppSelector((state) => state.common);

  const [getCurrentRegistrationPlanApi, { isLoading }] =
    useGetCurrentRegistrationPlanMutation();

  //Get CountryListDropdown
  const [getCountryListApi, { isLoading: isCountryLoading }] =
    useGetCountryListMutation();

  // Get Current Registration Plan Funcation.
  const getCurrentRegistrationPlan = async () => {
    try {
      const response = await getCurrentRegistrationPlanApi().unwrap();
      const { data, success, message } = response;
      if (success === true) {
        setCurrentRegistrationPlan(data);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error.data.message || "Something went weong.");
    }
  };

  useEffect(() => {
    getCurrentRegistrationPlan();
  }, []);

  // Get Registration Plan List
  const { data: plansList, isLoading: isLoadingPlan } =
    useGetRegistrationPlanListQuery(
      {
        country: countryId,
        registrationType: 0,
      },
      {
        skip: countryId !== 0 && countryId !== undefined ? false : true,
      }
    );

  // Current Registration Plan Country Id set.
  useEffect(() => {
    if (currentRegistraionPlan?.plan_summary?.account_country !== undefined) {
      const selectedCountry: any = countries.filter((item: any) => {
        return (
          item.label == currentRegistraionPlan.plan_summary.account_country
        );
      });
      setCountryId(selectedCountry[0]?.value);
    }
  }, [currentRegistraionPlan]);

  // If Country List is not find then load tha country data.
  const getCountryList = async () => {
    try {
      const response = await getCountryListApi().unwrap();
      const { data, success, message } = response;
      if (success === true) {
        // Map the fetched countries to your desired structure
        let filter_country = data.map((item: any) => {
          return { label: item.country_name, value: item.id };
        });

        // Add the clearable option
        filter_country = [...filter_country];

        // Dispatch the countries to the Redux store
        dispatch(setCounteries(filter_country));
      }
    } catch (error: any) {
      console.log(error?.data?.message || "Something went wrong.");
    }
  };

  // Filter Dyanmic Data for current Plan Id.
  let filterDynamicPlan = plans?.dynamic.filter((item: any) => {
    return item.plan_id === currentRegistraionPlan?.plan_summary?.plan_id;
  });

  // Set Current Plan Data base on countryId.
  useEffect(() => {
    if (countryId !== 0 && countryId !== undefined) {
      setPlans(plansList?.data);
    }
  }, [plansList, countryId]);

  //Load country data.
  useEffect(() => {
    if (countries.length === 0) {
      getCountryList();
    }
  }, [countries]);

  // Showing My Current Plan Details using common component
  const showingPriceList = filterDynamicPlan?.map(
    (item: any, index: number) => {
      return (
        <div className="d-flex overflow-auto" key={index}>
          <Col className="ps-0">
            <StaticPlanData staticData={{ data: plans }} />
          </Col>
          <Col>
            <DynamicPlanData
              dData={{ data: { dynamic: filterDynamicPlan } }}
              isButton={false}
              isModifyButton={true}
              planPayPeriod={
                currentRegistraionPlan?.plan_summary?.plan_pay_period
              }
              countryId={countryId}
            />
          </Col>
        </div>
      );
    }
  );
 

  if (isLoading || isLoadingPlan || isCountryLoading) return <Loading />;

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={MyRegistrationPlans} parent={AccountSettings} />
      <UserBanner />
      <Container fluid>
        <CurrentPlanSummary
          planSummary={currentRegistraionPlan?.plan_summary}
        />

        <Card key={`3`}>
          <CardHeaderCollapse
            title={MyCurrentPlan}
            isOpen={isMyPlanOpen}
            setIsOpen={setIsMyPlanOpen}
          />
          <Collapse isOpen={isMyPlanOpen}>{showingPriceList}</Collapse>
        </Card>

        <Card key={`2`}>
          <CardHeaderCollapse
            title={AdditionalServices}
            isOpen={isAdditionalServiceOpen}
            setIsOpen={setIsAdditionalServiceOpen}
          />
          <Collapse isOpen={isAdditionalServiceOpen}>
            <CardBody> 
              <CustomGrid
                id="additional-details"
                column={AdditionalDetailsColumns}
                data={currentRegistraionPlan?.additional_orders}
                title={"Additional Order"}
                isDeleteButton={false}
              />
            </CardBody>
          </Collapse>
        </Card>
      </Container>
    </div>
  );
};

export default MyRegistrationPlan;
