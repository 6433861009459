import * as Yup from "yup";
import {
  RequiredFieldValidationText,
  EmailFieldValidationText,
  PhoneNumberFieldValidationText,
  NumbersFieldValidationText,
  URLFieldValidationText,
} from "../../../utils/Constant";
const onlyNumber = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;

/* Start -- Manage Branch Form */
export interface ManageBranchFormValidationProp {
  branchname: string;
  fulladdress: string;
  branchcountry: string;
  branchcity: string;
  latitude: string;
  longitude: string;
  notificationtone: string;
  hoursday: boolean;
  job_Title:string;
  employjobLocationType: string,
  employexperience: string,
  employperiodMonths: string,
  country: string,
  notification_Title: string  ;
  job_description: string,
  image:any
  notification_description: string,
  Title:string,
  description:string,
  aarticalDescription:string, 
  customer_Title:string,
  display_on_media_center:any,
  comanage :any,
  coManagers: [{ username: string, permission: any, dispaymedia: any }],
}

export const manageBranchFormInitialValue: ManageBranchFormValidationProp = {
  branchname: "",
  fulladdress: "",
  employexperience: "",
  branchcountry: "",
  branchcity: "",
  latitude: "",
  longitude: "",
  notificationtone: "",
  hoursday: true,
  job_Title:"",
  employjobLocationType: "",
  employperiodMonths: "",
  country: "",
  notification_Title: ''   ,
  job_description:'',
  image:  [],
  notification_description: '',
  Title:'',
  description:'',
  aarticalDescription:'',
  customer_Title:'',
  display_on_media_center:1,
  comanage :0,
  coManagers: [{ username: "", permission: 1, dispaymedia: 0 }],
};

export const manageBranchFormValidationSchema = Yup.object().shape({
  branchname: Yup.string().required(RequiredFieldValidationText),
  fulladdress: Yup.string().required(RequiredFieldValidationText),
  branchcountry: Yup.string().required(RequiredFieldValidationText),
  branchcity: Yup.string().required(RequiredFieldValidationText),
  latitude: Yup.string().required(RequiredFieldValidationText),
  longitude: Yup.string().required(RequiredFieldValidationText),
  notificationtone: Yup.string().required(RequiredFieldValidationText),
  hoursday: Yup.string().required(),
});

/* End -- Manage Branch Form */

/* Start -- Branch Public Contact Form */

export const phoneValue = {
  phone: "",
};

export const emailValue = {
  email: "",
};

export interface BranchPublicContactFormValidationProp {
  country: any;
  city: any;
  branches: any;
  phones: Array<any>;
  emails: Array<any>;
  sharing:any

}

export const branchPublicContactFormInitialValue: BranchPublicContactFormValidationProp =
  {
    country: "",
    city: "",
    branches: "",
    phones: [phoneValue],
    emails: [emailValue],
    sharing:''
  };

export const branchPublicContactFormValidationSchema = Yup.object().shape({
  country: Yup.array().min(1).required(RequiredFieldValidationText),
  city: Yup.array().min(1).required(RequiredFieldValidationText),
  branches: Yup.array().min(1).required(RequiredFieldValidationText),
  phones: Yup.array().of(
    Yup.object().shape({
      phone: Yup.number().typeError(PhoneNumberFieldValidationText),
    })
  ),
  emails: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().optional().email(EmailFieldValidationText),
    })
  ),
});

/* End -- Branch Public Contact Form */


/* Start -- Coupon Form */

export const orderLimit = {
  minOrderTotal: "0.00",
  maxOrderTotal: "",
  fixAmountDiscount: "",
};

export interface CouponFormValidationProp {
  couponName: string;
  couponCode: string;
  discountType: string;
  startDate: string;
  activationCondition: string;
  currency: string;
  orderlimits: Array<any>;
  permission: any, // Default to "Edit"
  dispaymedia: any,
  comanage :any,
  comanager_users:string,
  individual_users: Array<any>, 
  display_on_media_center:any,
  coManagers: [{ username: string, permission: any, dispaymedia: any }],
}

export const couponFormInitialValue: CouponFormValidationProp = {
  couponName: "",
  couponCode: "",
  discountType: "",
  startDate: "",
  activationCondition: "",
  currency: "",
  orderlimits: [orderLimit],
  permission: 1, // Default to "Edit"
  dispaymedia: 0,
  comanage :0,
  comanager_users:"",
  individual_users: [], 
  display_on_media_center: 1,
  coManagers: [{ username: "", permission: 1, dispaymedia: 0 }],
};

export const couponFormValidationSchema = Yup.object().shape({
  couponName: Yup.string().required(RequiredFieldValidationText),
  couponCode: Yup.string().required(RequiredFieldValidationText),
  discountType: Yup.string().required(RequiredFieldValidationText),
  startDate: Yup.string().required(RequiredFieldValidationText),
  activationCondition: Yup.string().required(RequiredFieldValidationText),
  currency: Yup.string().required(RequiredFieldValidationText),
  orderlimits: Yup.array().of(
    Yup.object().shape({
      minOrderTotal: Yup.string()
        .required(RequiredFieldValidationText)
        .matches(onlyNumber,NumbersFieldValidationText),
      maxOrderTotal: Yup.string()
        .required(RequiredFieldValidationText)
        .matches(onlyNumber,NumbersFieldValidationText),
      fixAmountDiscount: Yup.string()
        .required(RequiredFieldValidationText)
        .matches(onlyNumber,NumbersFieldValidationText),
    })
  ),
});

/* End -- Coupon Form */

/* Start -- Delivery Fees Form */
export interface DeliveryFeesFormValidationProp {
  country: string;
  city: string;
  branch: any;
  deliveryGuysEarningFrom: string;
  minDeliveryFee: string;
  feePerDistance: string;
  feePerWeight: string;
  maxDistanceToDeliverTo: string;
  maxDeliveryWeightPerOrder: string;
  commissionRateToDeliveryWork: string;
  tipAmount: string;
  tipPercentage: string;
  deliveryWorkersTipShare: string;
  enableCustomerAddress: boolean;
  enableOrderAddons:boolean;
  enablePriceTotal:boolean;
  enableDynamicDelivery:boolean;
  acceptingDeliveryOrders:boolean;

}

export const deliveryFeesFormInitialValue: DeliveryFeesFormValidationProp = {
  country: "",
  city: "",
  branch: "",
  deliveryGuysEarningFrom: "",
  minDeliveryFee: "",
  feePerDistance: "",
  feePerWeight: "",
  maxDistanceToDeliverTo: "",
  maxDeliveryWeightPerOrder: "",
  commissionRateToDeliveryWork: "",
  tipAmount: "",
  tipPercentage: "",
  deliveryWorkersTipShare: "",
  enableCustomerAddress: false,
  enableOrderAddons:false,
  enablePriceTotal:false,
  enableDynamicDelivery:false,
  acceptingDeliveryOrders: true
};

export const deliveryFeesFormValidationSchema = Yup.object().shape({
  country: Yup.string().required(RequiredFieldValidationText),
  city: Yup.string().required(RequiredFieldValidationText),
  branch: Yup.array().min(1).required(RequiredFieldValidationText),
  deliveryGuysEarningFrom: Yup.string().required(RequiredFieldValidationText),
  minDeliveryFee: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber,NumbersFieldValidationText),
  feePerDistance: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber,NumbersFieldValidationText),
  feePerWeight: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber,NumbersFieldValidationText),
  maxDistanceToDeliverTo: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber,NumbersFieldValidationText),
  maxDeliveryWeightPerOrder: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber,NumbersFieldValidationText),
  commissionRateToDeliveryWork: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber,NumbersFieldValidationText),
  tipAmount: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber,NumbersFieldValidationText),
  tipPercentage: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber,NumbersFieldValidationText),
  deliveryWorkersTipShare: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber,NumbersFieldValidationText),
    enableCustomerAddress: Yup.boolean()
        .oneOf([true], 'You must enable customer address'),
        enableOrderAddons:Yup.boolean()
        .oneOf([true], 'You must enable customer address'),
        enablePriceTotal:Yup.boolean()
        .oneOf([true], 'You must enable customer address'),
        enableDynamicDelivery:Yup.boolean()
        .oneOf([true], 'You must enable customer address'),
        acceptingDeliveryOrders: Yup.array()
    .of(Yup.boolean())
    .min(1, "You must select at least one option."),
});

/* End -- Delivery Fees Form */

/* Start -- Delivery Workers Form */
export interface DeliveryWorkerFormValidationProp {
  deliveryWorkerUsername: string;
  confirmUserEmail: string;
  vehicleTypes: string;
  country: string;
  city: string;
  vehiclePlate:string;
  vehicleBrand:string;
  vehicleColor:string;
  vehicleDrivingLicenece:string;
  vehicleImage: File | null;
}

export const deliveryWorkerFormInitialValue: DeliveryWorkerFormValidationProp =
  {
    deliveryWorkerUsername: "",
    confirmUserEmail: "",
    vehicleTypes: "",
    country: "",
    city: "",
    vehiclePlate:"",
    vehicleBrand:"",
    vehicleColor:"",
    vehicleDrivingLicenece:"",
    vehicleImage: null,
  };

export const deliveryWorkerFormValidationSchema = Yup.object().shape({
  deliveryWorkerUsername: Yup.string().required(RequiredFieldValidationText),
  confirmUserEmail: Yup.string()
    .email(EmailFieldValidationText)
    .required(RequiredFieldValidationText),
  vehicleTypes: Yup.string().required(RequiredFieldValidationText),
  country: Yup.string().required(RequiredFieldValidationText),
  city: Yup.string().required(RequiredFieldValidationText),
  vehiclePlate:Yup.string().required(RequiredFieldValidationText),
  vehicleBrand:Yup.string().required(RequiredFieldValidationText),
  vehicleColor:Yup.string().required(RequiredFieldValidationText),
  vehicleImage:Yup.string().required(RequiredFieldValidationText),
  vehicleDrivingLicenece: Yup.string().notRequired(),
});

/* End -- Delivery Workers Form */

/* Start -- Addon Categories Form */

export const addonValues = {
  addOnOption: "",
  addOnExtraPrice: "",
  addOnQuantity: "",
};
export interface AddonCategoriesFormValidationProp {
  country: string;
  branch: string;
  products: string;
  addonCategory: string;
  requiredSelection: string;
  multipleSelection: string;
  addons: Array<any>;
}

export const addonCategoriesFormInitialValue: AddonCategoriesFormValidationProp =
  {
    country: "",
    branch: "",
    products: "",
    addonCategory: "",
    requiredSelection: "",
    multipleSelection: "",
    addons: [addonValues],
  };

export const addonCategoriesFormValidationSchema = Yup.object().shape({
  country: Yup.string().required(RequiredFieldValidationText),
  branch: Yup.string().required(RequiredFieldValidationText),
  products: Yup.string().required(RequiredFieldValidationText),
  addonCategory: Yup.string().required(RequiredFieldValidationText),
  requiredSelection: Yup.string().required(RequiredFieldValidationText),
  multipleSelection: Yup.string().required(RequiredFieldValidationText),
  addons: Yup.array().of(
    Yup.object().shape({
      addOnOption: Yup.string().required(RequiredFieldValidationText),
      addOnExtraPrice: Yup.string()
        .required(RequiredFieldValidationText)
        .matches(onlyNumber,NumbersFieldValidationText),
      addOnQuantity: Yup.string()
        .required(RequiredFieldValidationText)
        .matches(onlyNumber,NumbersFieldValidationText),
    })
  ),
});

/* End -- Addon Categories Form */

/* Start -- Schedule Form */

export const timeSlotValues = {
  openingTime: "",
  closingTime: "",
};

export interface SchedulesFormValidationProp {
  amount :any,
  title: string,
  description: string,
  questions: [{ question: string, answer: string }],
  country: string; 
  state : string,
  city: string,
  finalBranchList: any;
  monday: Array<any>;
  breakinActivity:Boolean;
  tuesday: Array<any>;
  wednesday: Array<any>;
  thursday: Array<any>;
  friday: Array<any>;
  saturday: Array<any>;
  sunday: Array<any>;
  image:File | null| Array<any>;
  cover_image:File | null| Array<any>;
  imagePreview:any
  academicYear:any;
  selectedYear:string;
  keywords: Array<any>;
  url: string; // Add URL field
  studymateialdescription: string;
  lessonTextDescription: string;  
  upload_image: File[] | null| Array<any>;
  days: {
    Monday: { action: string; priceDifference: string };
    Tuesday: { action: string; priceDifference: string };
    Wednesday: { action: string; priceDifference: string };
    Thursday: { action: string; priceDifference: string };
    Friday: { action: string; priceDifference: string };
    Saturday: { action: string; priceDifference: string };
    Sunday: { action: string; priceDifference: string };
  };
  dateRange: {
    from: string;
    to: string;
    action: string;
    priceDifference: string;
  };
  silverDiscount: "",
  goldDiscount: "",
  diamondDiscount: "",
  supersaverDiscount: "",
  display_on_media_center:any,
  comanage :any,
  coManagers: [{ username: string, permission: any, dispaymedia: any }],
  PolicyService: string;
};

 

export const scheduleFormInitialValue: SchedulesFormValidationProp = {
  amount:'',
  title: "",
  questions: [{ question: "", answer: "" }],
  country: "",
  state : '',
  city: '',
  academicYear: "", 
  imagePreview: [], // For storing image previews (URLs)
  finalBranchList: "",
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
   description: "",
   keywords: [],
   breakinActivity:false,
  days: {
    Monday: { action: "Regular", priceDifference: "" },
    Tuesday: { action: "Regular", priceDifference: "" },
    Wednesday: { action: "Regular", priceDifference: "" },
    Thursday: { action: "Regular", priceDifference: "" },
    Friday: { action: "Regular", priceDifference: "" },
    Saturday: { action: "Regular", priceDifference: "" },
    Sunday: { action: "Regular", priceDifference: "" },
  },
  dateRange: {
    from: "",
    to: "",
    action: "Regular",
    priceDifference: "",
  },
  silverDiscount: "",
  goldDiscount: "",
  diamondDiscount: "",
  supersaverDiscount : "",
  selectedYear: "", 
  knowledgeBank: [],  
  url: "", 
  studymateialdescription: "",  
  lessonTextDescription: "",
  image: [], // for storing cover image files
  cover_image: []  , // for storing cover image files
  upload_image: [] ,
  display_on_media_center:1,
  comanage :0,
  coManagers: [{ username: "", permission: 1, dispaymedia: 0 }],
  PolicyService :""
};

export const scheduleFormValidationSchema = Yup.object().shape({
  country: Yup.string().required(RequiredFieldValidationText),
  finalBranchList: Yup.array().min(1).required(RequiredFieldValidationText),
      image: Yup.string().required(RequiredFieldValidationText),
  monday: Yup.array().of(
    Yup.object().shape({
      openingTime: Yup.string().required(RequiredFieldValidationText),
      closingTime: Yup.string().required(RequiredFieldValidationText),
    })
  ),
  tuesday: Yup.array().of(
    Yup.object().shape({
      openingTime: Yup.string().required(RequiredFieldValidationText),
      closingTime: Yup.string().required(RequiredFieldValidationText),
    })
  ),
  wednesday: Yup.array().of(
    Yup.object().shape({
      openingTime: Yup.string().required(RequiredFieldValidationText),
      closingTime: Yup.string().required(RequiredFieldValidationText),
    })
  ),
  thursday: Yup.array().of(
    Yup.object().shape({
      openingTime: Yup.string().required(RequiredFieldValidationText),
      closingTime: Yup.string().required(RequiredFieldValidationText),
    })
  ),
  friday: Yup.array().of(
    Yup.object().shape({
      openingTime: Yup.string().required(RequiredFieldValidationText),
      closingTime: Yup.string().required(RequiredFieldValidationText),
    })
  ),
  saturday: Yup.array().of(
    Yup.object().shape({
      openingTime: Yup.string().required(RequiredFieldValidationText),
      closingTime: Yup.string().required(RequiredFieldValidationText),
    })
  ),
  sunday: Yup.array().of(
    Yup.object().shape({
      openingTime: Yup.string().required(RequiredFieldValidationText),
      closingTime: Yup.string().required(RequiredFieldValidationText),
    })
  ),
  days: Yup.object().shape(
    Object.fromEntries(
      ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => [
        day,
        Yup.object().shape({
          priceDifference: Yup.number()
            .required("Price Difference is required")
            .typeError("Price Difference must be a number")
            .test(
              "is-greater",
              `Price Difference must be greater than Amount`,
              function (value) {
                const { amount } = this.options.context;
                return value > amount;
              }
            ),
        }),
      ])
    )
  ),
  dateRange: Yup.object({
    from: Yup.date().required("From date is required"),
    to: Yup.date()
      .required("To date is required")
      .min(Yup.ref("from"), "To date must be after From date"),
    action: Yup.string().required("Action is required"),
    priceDifference: Yup.number()
      .required("Price difference is required")
      .min(0, "Price difference cannot be negative"),
  }),
  silverDiscount: Yup.number()
  .typeError("Silver Discount must be a number")
  .min(0, "Silver Discount must be at least 0%")
  .required("Silver Discount is required"),
goldDiscount: Yup.number()
  .typeError("Gold Discount must be a number")
  .moreThan(
    Yup.ref("silverDiscount"),
    "Gold Discount must be greater than Silver Discount"
  )
  .required("Gold Discount is required"),
diamondDiscount: Yup.number()
  .typeError("Diamond Discount must be a number")
  .moreThan(
    Yup.ref("silverDiscount"),
    "Diamond Discount must be greater than Silver Discount"
  )
  .moreThan(
    Yup.ref("goldDiscount"),
    "Diamond Discount must be greater than Gold Discount"
  )
  .required("Diamond Discount is required"),
  supersaverDiscount: Yup.number()
        .typeError("Super Saver Discount must be a number")
        .moreThan(
            Yup.ref("silverDiscount"),
            "Super Saver must be greater than Silver Discount"
        )
        .moreThan(
            Yup.ref("goldDiscount"),
            "Super Saver must be greater than Gold Discount"
        )
        .moreThan(
            Yup.ref("diamondDiscount"),
            "Super Saver must be greater than Diamond Discount"
        )
        .required("Super Saver Discount is required"),
  yourLessons: Yup.array().of(Yup.string().required("Select at least one lesson")),
  knowledgeBank: Yup.array().of(Yup.string().required("Select at least one public lesson")),
});
export const FequentlyinitialValues = {
  amount:'',
  days: {
    Monday: { action: "Regular", priceDifference: "" },
    Tuesday: { action: "Regular", priceDifference: "" },
    Wednesday: { action: "Regular", priceDifference: "" },
    Thursday: { action: "Regular", priceDifference: "" },
    Friday: { action: "Regular", priceDifference: "" },
    Saturday: { action: "Regular", priceDifference: "" },
    Sunday: { action: "Regular", priceDifference: "" },
  },
  activitiesAndEvents: "",
  faq: "",
  lessons: "",
  questionnaires: "",
  photoContests: "",
  productsAndServices: "",
  branchcountry:"",
  branches:"",
  listing:'',
  permission: 1, // Default to "Edit"
  dispaymedia: 0,
  comanage :0,
  comanager_users:"",
  individual_users: [], 
  coManagers: [{ username: "", permission: 1, dispaymedia: 0 }],
  display_on_media_center: 1,
  
};
export const FequentlyValidationSchema = Yup.object({
  amount: Yup.number()
  .required("Amount is required")
  .typeError("Amount must be a number"),
  activitiesAndEvents: Yup.string().required("Required"),
  faq: Yup.string().required("Required"),
  lessons: Yup.string().required("Required"),
  questionnaires: Yup.string().required("Required"),
  photoContests: Yup.string().required("Required"),
  productsAndServices: Yup.string().required("Required"),
  branchcountry: Yup.string().required("Required"),
  branches: Yup.string().required("Required"),
  days: Yup.object().shape(
    Object.fromEntries(
      ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => [
        day,
        Yup.object().shape({
          priceDifference: Yup.number()
            .required("Price Difference is required")
            .typeError("Price Difference must be a number")
            .test(
              "is-greater",
              `Price Difference must be greater than Amount`,
              function (value) {
                const { amount } = this.parent.parent;
                return value > amount;
              }
            ),
        }),
      ])
    )
  ),
});
/* End -- Schedule Form */
export const validationSchemaTransition  = Yup.object().shape({
    file: Yup.string().required('Please select a file '),
    activitiesAndEvents: Yup.string().required("Required"),
     language_id: Yup.string().required("Required"),
  })
