import { useState } from "react";
import { DeliveryFee, ManageAlbums } from "../../../../utils/Constant";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
    DeliveryFeesColumnData,
    supportTicketData,
} from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import {
    DeliveryFeeAction,
    DeliveryFeeTableAction,
    ManageOnlineStore,
} from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import DeliverySubMenu from "../DeliverySubMenu/DeliverySubMenu";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const DeliveryFees = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const handleRowSelected = () => {};
    return (
        <div className="page-body">
            <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    <SubMenuPopup currentPage="Delivery Fees" />
                </div>
                
            <Breadcrumbs
            mainParent="All Tools"
                mainTitle={`${DeliveryFee} (Manage Store)`}
                parent={`Manage Store`}
            />
            </div>
            <Container fluid>
                <CardHeader className="pb-0 pt-0  ">
                    <ManageOnlineSubMenu />
                </CardHeader>
            </Container>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <div>
                                <DeliverySubMenu />
                            </div>
                            <div className="">
                                            {DeliveryFeeTableAction.map(
                                                (action, index) => {
                                                    return (
                                                        <>
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/${action.path}`}>
                                                                <span
                                                                    key={index}
                                                                    className="border rounded p-2 m-1 text-white bg-dark ps-2 pe-2">
                                                                    {
                                                                        action.actionName
                                                                    }{" "}
                                                                </span>
                                                            </Link>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </div>
                            <CardBody className="p-2">
                                <>
                                    <div className=""> 
                                        <div className="table-responsive">
                                            <DataTable
                                                className="custom-scrollbar"
                                                columns={DeliveryFeesColumnData}
                                                data={data}
                                                striped={true}
                                                pagination
                                                selectableRows
                                                onSelectedRowsChange={
                                                    handleRowSelected
                                                }
                                                clearSelectedRows={toggleDelete}
                                            />
                                        </div>
                                    </div>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DeliveryFees;
