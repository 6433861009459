import {useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import {  BranchesColumnData, SchedulingColumnData, supportTicketData } from '../../../../../Data/Tools/BrandSupportTools/ButtonActions'
import {  BranchsAction,  ManageOnlineStore } from '../../../../../Data/Tools/ManageContents/ManageContentButtons'
import BranchSubMenu from '../BranchSubMenu/BranchSubMenu'
import ManageOnlineSubMenu from '../../ManageOnlineSubMenu/ManageOnlineSubMenu'
import { Href } from '../../../../../utils/Constant'
import { Btn } from '../../../../../AbstractElements'
import ImportBulk from '../../../BrandSupportTools/ImportBulk/ImportBulk'
import Popup from '../../../../../Component/MasterPopup/Popup'
import SubMenuPopup from '../../../../../Layout/Sidebar/ManageStorePopup'

const Schedules = () => {
  const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const [basicTab, setBasicTab] = useState<string>("1");

    const handleRowSelected =() =>{} 
    return (
    <div className='page-body'>
      <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                <SubMenuPopup  currentPage="Schedules"/>
                </div> 
      <Breadcrumbs mainParent="All Tools" mainTitle={`Schedulings (Manage Store)`} parent={`Manage Store`} />
            </div>
      
      <Container fluid>
        <Row>
        <div className='ms-1 mt-2'>
                    <BranchSubMenu/>

                    </div> 
          <Col sm="12">
          <Card className='shadow-none'>
                  <div   className="nav-warning "> 
                  <div className="mt-3">
                       
                      <Link
                        to={`${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/schedules/add-new`}
                        className={  "border rounded p-2 m-1 text-dark  ps-2 pe-2"  }
                       
                      >
                        Add Scedules
                      </Link>

                    </div>
                    {/* <NavItem>
                      <NavLink
                        href={Href}
                        className={basicTab === "2" ? "bg-gray text-white d-inline-block rounded" : "d-inline-block"}
                        onClick={() => setBasicTab("2")}
                      >
                        {ContactNoList}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={Href}
                        className={basicTab === "3" ? "bg-gray text-white d-inline-block rounded" : "d-inline-block"}
                        onClick={() => setBasicTab("3")}
                      >
                        {EmailList}
                      </NavLink>
                    </NavItem> */}
                    

                    {/* <NavItem className='w-50'>
                      <Link to={`${process.env.PUBLIC_URL}/manage-online-store/manage-branches/branch-public-contacts/add-new`}><Btn className='bg-primary float-end'>{`Add a Branch Contact`}</Btn></Link>
                    </NavItem> */}
                  </div>

                  </Card>
            <Card>
               
              <CardBody className='p-2'>
                <> 
                    <div className="table-responsive pt-2">
                    <DataTable className='custom-scrollbar' columns={SchedulingColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                    
                </div>
                
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Schedules