import React,{useState} from 'react'
import { Btn, H4, H5, H6, LI, UL } from '../../../AbstractElements'
import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { Formik } from 'formik'
import { AddNewIdeaStoryAlbum, AssignContenttoProductService, AttributionRequired, DropZoneWarning, SelectPhotoContest, StartNewUpload, UndoChanges } from '../../../utils/Constant'
import CommonSwitchSpan from '../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan'
import CardHeaderCommon from '../../BonusUi/CardHeaderCommon/CardHeaderCommon'
import Dropzone from 'react-dropzone-uploader'
import Popup from '../../MasterPopup/Popup'
import { manageAlbumFormInitialValue, manageAlbumFormValidationSchema } from '../../../Data/Tools/ManageContents/FormControlsValidation'
import AddManageMyAlbumsForm from '../../Tools/ManageContents/ManageMyAlbums/AddManageMyAlbumsForm'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import LicenseForm from './LicenseForm'

export const photo_contest = [""]

const PhotoContestContent = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openPSModal, setOpenPSModal] = useState(false);
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const handleSubmit =() =>{
        return console.log("hello");
        
    }
    const SocialContentValidation =() =>{

    }
    const getUploadParams = () => {
        return { url: "https://1webbb.com/post" };
    }
    return (
        <div className="px-4 py-4">
            <div>
            <Btn className='ps-1' onClick={()=>setOpenPSModal(!openPSModal)}>
                <i className="bi bi-plus-circle mx-1 text-warning"></i>
                {SelectPhotoContest}
            </Btn>
            </div>        
            <div>
            <Btn className='ps-1' onClick={()=>setOpenModal(!openModal)}>
                <i className="bi bi-plus-circle mx-1 text-warning"></i>
                {AddNewIdeaStoryAlbum}
            </Btn>
            </div>
            <Popup title={`Select a Photo Contest`} CommonFooter={true} openModalToggle={()=>setOpenPSModal(!openPSModal)} isOpen={openPSModal} size={`lg`} backdrop={true} isClose={false}>
                <div className='p-3 py-4'>
                    <Typeahead options={photo_contest} placeholder="Select a Photo Contest" id="Basic TypeAhead"/>
                </div>
            </Popup>
            <Popup title={AddNewIdeaStoryAlbum} CommonFooter={true} openModalToggle={()=>setOpenModal(!openModal)} isOpen={openModal} size={`xl`} backdrop={true} isClose={false}>
                <div className='p-3'>
                    <Formik
                        initialValues={manageAlbumFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={manageAlbumFormValidationSchema}
                        >
                        {({ errors, values, setFieldValue }) => (
                            <AddManageMyAlbumsForm
                            submitErrors={submitErrors}
                            setSubmitError={setSubmitError}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                            />
                        )}
                    </Formik>
                </div>
            </Popup>
            <LicenseForm add={true}/>
            <Row className=''>
                <Col xxl="8">
                    <Formik
                    initialValues = {{email:''}}
                    onSubmit={handleSubmit}
                    validationSchema={SocialContentValidation}>
                    {({ errors }) => (
                        <Form >
                            
                            <Card className='mb-0'>
                                <CardBody className='pb-0'>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    onSubmit={handleSubmit}
                                    maxFiles={1}
                                />
                                </CardBody>
                            </Card>

                            {/* <div className='d-flex'>
                                <i className="bi-exclamation-triangle me-1 text-warning"></i>
                                <H6 className=''>{DropZoneWarning}</H6>
                            </div> */}
                           
                            <div className='d-flex py-3'> 
                                <Btn color="primary" type="button">{StartNewUpload}</Btn>
                                {/* <Btn color='secondary' className='mx-2'>{UndoChanges}</Btn> */}
                            </div>
                        </Form>
                    )}
                    </Formik>
                </Col>
                
            </Row>
        </div>
    )
}

export default PhotoContestContent