import React from "react";
import {
    BrandTools, 
    Languages,
    ProjectCreates,
} from "../../../../utils/Constant";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row,
} from "reactstrap";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import TitleInput from "../../../../Component/CommonInput/TitleInput";
import Language from "../../../../Component/CommonInput/Language";
import LongDescription from "../../../../Component/CommonInput/LongDescription";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";
const validationSchema = Yup.object({
    title: Yup.string().required("Unique Title is required"),
    language: Yup.string().required("Language is required"),
});
function PoliciesandServiceTermsAdd() {
    return (
        <>
         <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                <SubMenuPopup  currentPage="Add Policy or Service Terms"/>
                </div>
            <Breadcrumbs
                mainTitle={`Add Policies and Service Terms  (${BrandTools})`}
                mainParent="All Tools"
                parent={BrandTools}
            />
            </div>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Formik
                                    initialValues={{
                                        title: "",
                                        languages: "English", // Default value
                                        descriptionLong: "", // Default value
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                        console.log("Form Data:", values);
                                    }}
                                >
                                    {({ isSubmitting, values }) => (
                                        <Form className="">
                                            <Row>
                                            <Col xl="6" md="6">
                                                    <TitleInput
                                                        title="Unique Title"
                                                        values={values.title}
                                                        name="title"
                                                        placeholder="Unique Title"
                                                    />
                                                    <ErrorMessage
                                                        name="title"
                                                        component="div"
                                                        className="text-red-500 text-sm mt-1"
                                                    />
                                            </Col>
                                            <Col xl="6" md="6">
                                                <Label check>{Languages}</Label>
                                                <Language
                                                    name="languages"
                                                    isMulti={false}
                                                    className={``}
                                                />
                                            </Col>
                                            <Col
                                                                                                            sm="12">
                                                                                                            <FormGroup>
                                                                                                                <LongDescription
                                                                                                                    lable='Text'
                                                                                                                    name="descriptionLong"
                                                                                                                    value={
                                                                                                                        values.descriptionLong
                                                                                                                    }
                                                                                                                    id="editor_container"
                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </Col>
                                            </Row>
                                            
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PoliciesandServiceTermsAdd;
