import React from 'react' 
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import TitleInput from '../../CommonInput/TitleInput';
import { ErrorMessage, Field } from 'formik';
import Country from '../../CommonInput/Country';
import DescriptionInput from '../../CommonInput/DescriptionInput';
function NotificationScreenForm({submitErrors, values, errors, touched }) {
  return (
    <Form className='form-wizard  '>
         <Row><Col
                                                                sm="4"
                                                                className="mb-3">
                                                        <FormGroup>
                                                          
                                                          <TitleInput title='Title' values={values.notification_Title} name="notification_Title"  placeholder="Title"/>  
                                                  
                                                                                                      </FormGroup>
                                                  </Col>
                                                  <Col
                                                                sm="4"
                                                                className="mb-3">
                                                                                                      <Label check>Image</Label>
                                                                                                      <FormGroup>
    <Field name="image">
        {({ field, form }: any) => {
            const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const files = event.target.files;
                if (files) {
                    const fileArray = Array.from(files).slice(0, 10); // Limit to 10 images
                    form.setFieldValue("image", fileArray);
                }
            };

            return (
                <>
                    <input
                        className={`form-control ${
                            form.touched.image && form.errors.image
                                ? "is-invalid"
                                : form.touched.image
                                ? "is-valid"
                                : ""
                        }`}
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={onImageChange}
                    />
                    {form.values.image && form.values.image.length > 10 && (
                        <span className="text-danger">Max limit 10</span>
                    )}
                    <ErrorMessage name="image" component="span" className="invalid-feedback" />

                    <ul>
                        Total Images: {form.values.image ? form.values.image.length : 0}
                    </ul>
                </>
            );
        }}
    </Field>
</FormGroup></Col>
                                                            <Col
                                                                sm="4"
                                                                className="mb-3">
                                                                <Label check>
                                                                    {" "}
                                                                    A Previous
                                                                    Notification
                                                                    Screen as a
                                                                    Reference
                                                                    (optional){" "}
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-control"
                                                                    name="reference-coupon">
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }>
                                                                        Select a
                                                                        Reference
                                                                    </option>
                                                                </Field>
                                                            </Col>
                                                            <Col sm="4" className="mb-3">
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Target Countries`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="targetcountry"
                                                                    placeholder="Select"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm="4" className="mb-3">
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup> 
                                                                       <DescriptionInput  name='notification_description' placeholder="Description/Summary" values={values.notification_description} lable='Description'/>

                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            </Col>
                                                            <Col sm="4" className="mb-3">
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Country`}:
                                                                </Label>
                                                                <Country
                                                                    name="country"
                                                                    placeholder="Any Country’"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                        </Row>
    </Form>
  )
}

export default NotificationScreenForm