import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "../../../MasterPopup/Popup";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { Btn } from "../../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import CustomSelect from "../../../CommonInput/CustomSelect";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import OnlineAssistantForm from "./OnlineAssistantForm";
import { toast } from "react-toastify";
import { useSaveOnlineAssistantDataMutation } from "../../../../ReduxToolkit/Reducers/OnlineAssistant/onlineAssistantApiSlice";

function AddOnlineAssistant(props: any) {
  const [isAssistantModalOpen, setIsAssistantModalOpen] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(false);

  const navigate = useNavigate();

  const openAssistantModalToggle = () => {
    setIsAssistantModalOpen(!isAssistantModalOpen);
  };

  const { title } = props;

  const [saveOnlineAssistantDataApi, { isLoading }] =
    useSaveOnlineAssistantDataMutation();

  const handleSubmit = async (values: any) => {
    try {
      console.log(values);
      //const response = await saveOnlineAssistantDataApi(values).unwrap();
      //const { data, success, message } = response;
      //toast.success(message);
      navigate("/cart");
    } catch (error: any) {
      toast.error(error || error.data.message);
    }
    setSubmitErrors(false);
  };

  const branchOptions = [
    { value: "branch1", label: "Branch 1, Surat, Gujarat" },
    { value: "branch2", label: "Branch 2, Mumbai, Maharashtra" },
    { value: "branch3", label: "Branch 3, Bangalore, Karnataka" },
  ];

  return (
    <>
      <Link
        to=""
        onClick={openAssistantModalToggle}
        className="ms-1 me-1 text-decoration-underline"
      >
        <span>{title}</span>
      </Link>
      <CommonModal
                isOpen={isAssistantModalOpen}
                toggle={openAssistantModalToggle}
                modalBodyClassName="  modal-custom"
                largesize="modal-custom"
                sizeTitle="Add an Account Assistant"
                AdShow={false} 
                size="md">
        <div className="mx-4 my-2">
            <OnlineAssistantForm openAssistantModalToggle={openAssistantModalToggle}/>
        </div>
      </CommonModal>
    </>
  );
}

export default AddOnlineAssistant;
