import { CardHeader, Col, Container, Row } from 'reactstrap' 
import PageHeader from '../../../Dashboard/Default/PageHeader/PageHeader'
import ManageOnlineSubMenu from '../../../../Pages/Tools/ManageOnlineStore/ManageOnlineSubMenu/ManageOnlineSubMenu'
import BranchSubMenu from '../../../../Pages/Tools/ManageOnlineStore/ManageBranches/BranchSubMenu/BranchSubMenu'
import NewOrders from '../OrderHistory/NewOrders'
import ShippedOrders from '../OrderHistory/ShippedOrders'
import CancelledOrders from '../OrderHistory/CancelledOrders'
import DataTableOrderHistory from '../OrderHistory/DataTableOrderHistory'

const Purchase = () => {
  return (
    <>
      <Container fluid>
      {/* <CardHeader> */}
      <CardHeader className='py-0 px-0'>
               <PageHeader/>
            </CardHeader>
        <Row>
          <Col sm="12">
            <NewOrders />
            <ShippedOrders />
            <CancelledOrders />
            <DataTableOrderHistory />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Purchase