// import React, { useState } from "react";
// import CommonModal from "../../Component/Ui-Kits/Modal/Common/CommonModal";
// import { Button } from "reactstrap";
// import { MenuList } from "../../Data/LayoutData/SidebarData";

// const sections = [
//   {
//     title: "Manage Store",
//     subtitles: [
//       { subtitle: "Manage Sales and Branches", children: ["Sales", "Add a Branch", "Branch List"] },
//       { subtitle: "Manage Products and Services", children: ["Product and Service List"] },
//       { subtitle: "Addon Categories", children: ["Addon List"] },
//     ],
//   },
//   {
//     title: "Manage Media",
//     subtitles: [
//       { subtitle: "Manage Albums", children: ["Album List"] },
//       { subtitle: "Manage Visual Ideas", children: ["Visual Idea List"] },
//     ],
//   },
//   {
//     title: "Brand Support Tools",
//     subtitles: [
//       { subtitle: "Manage Posts", children: ["Post List"] },
//       { subtitle: "Activities and Attendance", children: ["Activity and Event List"] },
//     ],
//   },
// ];
// const ManageStorePopup = () => {
//     const data = MenuList?.[0]?.Items?.find((item) => item.title === "Tools") || [];
//     const sections =data.children;
//     console.log(sections);
    
//   const [open, setOpen] = useState(false);
//   const [selectedSection, setSelectedSection] = useState(sections[0].title);
//   const [selectedSubtitle, setSelectedSubtitle] = useState(sections[0].Items[0].subtitle);

//   const handleSectionChange = (event) => {
//     const newSection = event.target.value;
//     setSelectedSection(newSection);
//     setSelectedSubtitle(sections.find((s) => s.title === newSection).Items[0].subtitle);
//   };

//   const handleSubtitleChange = (event) => {
//     setSelectedSubtitle(event.target.value);
//   };

//   const selectedChildren =
//     sections
//       .find((s) => s.title === selectedSection)
//       ?.Items.find((sub) => sub.subtitle === selectedSubtitle)?.children || [];

//   return (
//     <div>
//       <Button onClick={() => setOpen(true)}>Open Popup</Button>
//       <CommonModal isOpen={open} onOpenChange={setOpen}>
//         <h4>Select a Section</h4>

//         {/* Radio Buttons for Main Sections */}
//         {sections.map((section) => (
//           <label key={section.title} style={{ display: "block", marginBottom: "5px" }}>
//             <input
//               type="radio"
//               name="section"
//               value={section.title}
//               checked={selectedSection === section.title}
//               onChange={handleSectionChange}
//             />
//             {section.title}
//           </label>
//         ))}

//         {/* Dropdown for Items */}
//         <select value={selectedSubtitle} onChange={handleSubtitleChange} style={{ display: "block", margin: "10px 0" }}>
//           <option value="">Select a Subtitle</option>
//           {sections
//             .find((s) => s.title === selectedSection)
//             ?.Items.map((sub) => (
//               <option key={sub.subtitle} value={sub.subtitle}>
//                 {sub.subtitle}
//               </option>
//             ))}
//         </select>

//         {/* Show Selected Children as Buttons */}
//         <div style={{ marginTop: "10px" }}>
//           {selectedChildren.length > 0 ? (
//             selectedChildren.map((child) => (
//               <Button key={child} style={{ margin: "5px" }}>
//                 {child.actionName}
//               </Button>
//             ))
//           ) : (
//             <p>No items available.</p>
//           )}
//         </div>
//       </CommonModal>
//     </div>
//   );
// };

// export default ManageStorePopup;
import React, { useState, useEffect } from "react";
import CommonModal from "../../Component/Ui-Kits/Modal/Common/CommonModal";
import { Button, Col } from "reactstrap";
import { MenuList } from "../../Data/LayoutData/SidebarData";
import { Link, useLocation } from "react-router-dom";
import { Btn } from "../../AbstractElements";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import {  Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, NavLink, Row } from "reactstrap";
import { workAvailabilityFormInitialValue, workAvailabilityFormSchema } from "../../Data/App/AccountSettings/MyResumesAddNew";
import { ErrorMessage, Field, Formik } from "formik";
import WorkAvailabilityForm from "../../Pages/App/AccountSettings/WorkAvailabilityForm";
import { resumesData } from "../../Pages/App/AccountSettings/MyResumes";
import Popup from "../../Component/MasterPopup/Popup";
import { H5 } from "../../AbstractElements";
import { FaTurnDown } from "react-icons/fa6";
import Notes from "../../Pages/Tools/Notes/Notes";
import { branchPublicContactFormInitialValue, branchPublicContactFormValidationSchema, FequentlyinitialValues, FequentlyValidationSchema } from "../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import CommonSwitchSpan from "../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import OnlineAssistantForm from "../../Component/App/AccountSettings/OnlineAssistant/OnlineAssistantForm";
import FequentlyModalForm from "../../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyModalForm";
import ImportBulk from "../../Pages/Tools/BrandSupportTools/ImportBulk/ImportBulk";
import BulkProductImages, { ProductvalidationSchema } from "../../Pages/Tools/ManageOnlineStore/ManageBranches/ManageProductServices/BulkProductImages";
import Country from "../../Component/CommonInput/Country";
import State from "../../Component/CommonInput/State";
import City from "../../Component/CommonInput/City";
import Branches from "../../Component/CommonInput/Branches";
import AddAlbumModal from "../../Component/App/UploadData/AddAlbumModal";
import AddVisualIdeaModal from "../../Component/App/UploadData/AddVisualIdeaModal";
import AddQuestionGroupModal from "../../Component/Tools/BrandSupportTools/Questions/AddQuestionGroupModal";
import AddQuizMaterial from "../../Component/Tools/BrandSupportTools/Questions/AddQuizMaterial";
import AddCourseandTeacherInformation from "../../Pages/Tools/BrandSupportTools/CourseandTeacherInformation/AddCourseandTeacherInformation";
import AddSurveyAndQuizzesForm from "../../Component/Tools/BrandSupportTools/SurveyAndQuizzes/AddSurveyAndQuizzesForm";
import { surveyAndQuizzesFormInitialValue, TabsurveyAndQuizzesFormValidationSchema } from "../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SubMenuPopup = ({ currentPage }) => {
  const data = MenuList?.[0]?.Items?.find((item) => item.title === "Tools") || [];
  const sections = data.children || [];

  // Function to find the section and subtitle based on the current page
  const findSectionAndSubtitle = () => {
    for (const section of sections) {
      console.log(section);
      
      for (const subtitle of section.Items) {
      console.log(subtitle);
for(const subchild of subtitle?.children){

  if (subchild?.actionName.includes(currentPage)) {
          console.log(subchild?.actionName); 
        return { sectionTitle: section?.title, subtitle: subtitle?.subtitle };
      }
    }
      }
    }
    return { sectionTitle: sections[0]?.title || "", subtitle: sections[0]?.Items?.[0]?.subtitle || "" };
  };

  // Initial selection based on current page
  const initialSelection = findSectionAndSubtitle();

  const [open, setOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(initialSelection?.sectionTitle);
  const [selectedSubtitle, setSelectedSubtitle] = useState(initialSelection?.subtitle);
  const [showBusinessBranch, setShowBusinessBranch] = useState(true);
   const [openModal, setOpenModal] = useState(false);
        const [modalType, setModalType] = useState(false);
          const [submitErrors, setSubmitErrors] = useState(false); 
                  const [showBusinessLocation, setShowBusinessLocation] = useState(true);
                  const [showBranchPhotoLocation, setShowBranchPhotoLocation] = useState(true); 
const location = useLocation();
    const currentPath = location.pathname;
    const isActive = (path: string) => {
      return (
          path && path !== "#" && 
          (location.pathname === `${process.env.PUBLIC_URL}/${path}` ||
          (location.pathname.startsWith(`${process.env.PUBLIC_URL}/${path}`) &&
              !location.pathname.includes(`${process.env.PUBLIC_URL}/${path}/`)))
      );
  };
  // Update selection when currentPage changes
  useEffect(() => {
    const updatedSelection = findSectionAndSubtitle();
    setSelectedSection(updatedSelection?.sectionTitle);
    setSelectedSubtitle(updatedSelection?.subtitle);
  }, [currentPage]);

  // Handle section selection
  const handleSectionChange = (event) => {
    const newSection = event.target.value;
    setSelectedSection(newSection);
    setSelectedSubtitle(sections.find((s) => s.title === newSection)?.Items[0]?.subtitle || "");
  };
  const openModalToggle= () => {
    setOpenModal(!openModal) 
}
  // Handle subtitle selection
  const handleSubtitleChange = (event) => {
    setSelectedSubtitle(event.target.value);
  };
  const handleSubmit =()=>{ }
  // Get the children buttons of the selected subtitle
  const selectedChildren =
    sections
      .find((s) => s.title === selectedSection)
      ?.Items.find((sub) => sub.subtitle === selectedSubtitle)?.children || [];

  return (
    <div>
       
      <Col md="12">
                    {open ? (
                            <IoClose size='2em' onClick={() =>setOpen(false)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                        ) : (
                            <HiOutlineMenuAlt2 size='2em' onClick={() => setOpen(true)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                        )}
                    </Col>
      <CommonModal isOpen={open} toggle={() => {
                          setOpen(false); 
                      }}
                      largesize="modal_md_size"
                      sizeTitle="Navigation Option"
                      AdShow={false} >
        <h4>Select a Section</h4>
                      <div className="d-flex gap-2">
        {/* Radio Buttons for Sections */}
        {sections.map((section,ind) => (
          // <label key={section.title} style={{ display: "block", marginBottom: "5px" }}>
          //   <input
          //     type="radio"
          //     name="section"
          //     value={section.title}
          //     checked={selectedSection === section.title}
          //     onChange={handleSectionChange}
          //   />
          //   <span className="ms-2">
          //   {section.title}
          //   </span>
          // </label>
                    <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                    <Input
                        id={`checkbox-${ind}`}
                        type="radio"
                        name="section"
                        onChange={handleSectionChange}  
                        checked={selectedSection === section.title}
                        className="ms-0"
                        value={section.title}
                    />
                    <Label
                        className="mb-0"
                        htmlFor={`checkbox-${ind}`}
                        >
                        <h6>
                        {section.icon && <FontAwesomeIcon icon={section.icon} />}{" "}
                        {section.title}
                        </h6>
                    </Label>
                </div>
        ))}
                      </div>

        {/* Dropdown for Subtitles */}
        {/* <select value={selectedSubtitle} onChange={handleSubtitleChange} style={{ display: "block", margin: "10px 0" }}>
          {sections
            .find((s) => s.title === selectedSection)
            ?.Items.map((sub) => (
              <option key={sub.subtitle} value={sub.subtitle}>
                {sub.subtitle}
              </option>
            ))}
        </select> */}
        <Formik
  initialValues={{ section: "" }}
  onSubmit={(values) => console.log(values)}
>
  {({ handleChange, values }) => (
    <Form className="mt-2">
      <Field    as={Input} type="select" name="section" onChange={handleSubtitleChange} value={selectedSubtitle}>
        <option value="">-- Select --</option>
        
         {sections
            .find((s) => s.title === selectedSection)
            ?.Items.map((sub) => (
              <option key={sub.subtitle} value={sub.subtitle}>
                {sub.subtitle}
              </option>
            ))}
      </Field>
    </Form>
  )}
</Formik>

        {/* Display Buttons for Selected Children */}
        <div style={{ marginTop: "10px" }}>
  {selectedChildren?.length > 0 ? (
    selectedChildren.map((child, index) => { // Added index parameter
      const activeClass = isActive(child.path) ? "bg-dark text-light" : "text-dark";
      console.log(activeClass);

      return child.modalType ? (
        <div className="">
        <Btn
          className="border-0 d-inline-block  rounded p-1 ps-2 pe-2 text-dark btn_primary "
          key={index} // Ensuring each element has a unique key
          onClick={() => {
            openModalToggle();
            setModalType(child.modalType); // Changed subitem to child
          }}
        >
          <span
            style={{
              lineHeight: "21px",
              fontFamily: "Lexend",
            }}
          >
            {child.actionName}
          </span>
        </Btn>
        </div>
      ) : (
        <div className="">
        <Link
          to={`/${child.path}`}
          key={index} // Using index as key instead of `child`
          style={{ margin: "5px" }}
          className={` m-0 d-inline-block  rounded p-1 ps-2 pe-2 ${activeClass}`}
        >
          {child.actionName}
        </Link>
        </div>
      );
    })
  ) : (
    <p>No items available.</p>
  )}
</div>

      </CommonModal> 
        {modalType == "WorkAvailability" &&  openModal &&  
           <Popup
                              title={`Work Availability`}
                              openModalToggle={() => setOpenModal(!openModal)}
                              isOpen={openModal}
                              size={`lg`}
                              backdrop={true}
                              isClose={false}
                              CommonFooter={false}
                            >
                              <div className="mx-4 my-2">
                                <Formik
                                  initialValues={workAvailabilityFormInitialValue}
                                  onSubmit={handleSubmit}
                                  validationSchema={workAvailabilityFormSchema}
                                >
                                  {({ errors, values, setFieldValue }) => (
                                    <WorkAvailabilityForm
                                      errors={errors}
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      setOpenModal={setOpenModal}
                                      openModal={openModal}
                                      setSubmitErrors={setSubmitErrors}
                                      submitErrors={submitErrors}
                                      resumeData={resumesData}
                                    />
                                  )}
                                </Formik>
                              </div>
      </Popup>}
      {modalType == "Verfied Branch Tags" &&  openModal &&  
                                  <Modal isOpen={openModal} toggle={openModalToggle} size="xl">
                                      <ModalHeader toggle={openModalToggle}>
                                      Verfied Branch Tags (1Webbb Service)
                                      </ModalHeader>
                                      <ModalBody className="overflow-y-auto">
                                      <Notes notsectionlist={true} branchountry={true} city={true} branches={true} PriceDay={true} Price={false}  oneBranch={true} />
                      
                                          {/* You can display additional data here */}
                                          <Formik
                                              initialValues={branchPublicContactFormInitialValue}
                                              onSubmit={handleSubmit}
                                              validationSchema={
                                                  branchPublicContactFormValidationSchema
                                              }>
                                              {({ errors, values, setFieldValue }) => {
                                                  
                                                  
                                                  return (
                                                      <Form className="form-wizard mx-3">
                                                          
                                                          <Row>
                                                              <Col sm="4" className="mt-2">
                                                                  <Label check>
                                                                  Verified Business Branch License Tags:{" "} 
                                                                      <small className="d-block">We display ‘Verified License’ tag under your branch name, after we verify with the government officials the business license you provide us.</small>
                                                                  </Label>
                                                                  <div className="flex-shrink-0 icon-state switch-outline">
                                                                  <CommonSwitchSpan
                                                                  color={"primary"}
                                                                  name="showBusinessBranch"
                                                                  checked={showBusinessBranch}
                                                                  onChange={() => setShowBusinessBranch(!showBusinessBranch)}
                                                              />
                                                                  </div>
                                                              </Col>
                                                              {showBusinessBranch && (
                                                          <>
                                                              <Col sm="4" className="mb-3">
                                                                  <Label check>
                                                                      Price (30 Days per Branch):{" "}
                                                                      <b>10 USD</b>
                                                                  </Label>
                                                              </Col>
                                                              <Col sm="4" className="mb-3">
                                                                  <Label check>Total: </Label>
                                                                  <b>{values.branches.length * 10}</b>
                                                              </Col>
                                                          </>
                                                      )}
                                                          </Row>
                                                          <Row>
                                                              <Col sm="4" className="mt-2">
                                                                  <Label check>
                                                                  Verified Branch Location Tags:{" "} 
                                                                      <small className="d-block">We display ‘Verified Location’ tag under your visited branch name, after we visit your location in various random times to verify your location.</small>
                                                                  </Label>
                                                                  <div className="flex-shrink-0 icon-state switch-outline">
                                                                  <CommonSwitchSpan
                                                                  color={"primary"}
                                                                  name="showBusinessLocation"
                                                                  checked={showBusinessLocation}
                                                                  onChange={() => setShowBusinessLocation(!showBusinessLocation)}
                                                              />
                                                                  </div>
                                                              </Col>
                                                              {showBusinessLocation && (
                                                          <>
                                                              <Col sm="4" className="mb-3">
                                                                  <Label check>
                                                                      Price (30 Days per Branch):{" "}
                                                                      <b>5 USD</b>
                                                                  </Label>
                                                              </Col>
                                                              <Col sm="4" className="mb-3">
                                                                  <Label check>Total: </Label>
                                                                  <b>{values.branches.length * 5}</b>
                                                              </Col>
                                                          </>
                                                      )}
                                                             
                                                              <Col sm="4" className="mb-3 mt-2">
                                                                  <Label check>
                                                                      Subtotal:{" "} <b>{(showBusinessBranch ? values.branches.length * 10 : 0) +
                                                                            (showBusinessLocation ? values.branches.length * 5 : 0)}</b>
                                                                      
                                                                  </Label>
                                                                  
                                                              </Col>
                                                          </Row>
                                                      </Form>
                                                  );
                                              }}
                                          </Formik>
                                      </ModalBody>
                                  </Modal>}
                                 {modalType == "Branch Photographer" &&  openModal &&   <Modal isOpen={openModal} toggle={openModalToggle} size="xl" className=' modal-dialog  modal-dialog-scrollable modal-custom  modal-xl modal-dialog-centered'>
                                      <ModalHeader toggle={openModalToggle}>
                                      Branch Photographer (taking photos at your location - 1Webbb Service)
                                      </ModalHeader>
                                      <ModalBody>
                                      <Notes notsectionlist={true} branchountry={true} city={true} branches={true} PriceDay={true} Price={false}  oneBranch={true} />
                      
                       
                                          <Formik
                                              initialValues={branchPublicContactFormInitialValue}
                                              onSubmit={handleSubmit}
                                              validationSchema={
                                                  branchPublicContactFormValidationSchema
                                              }>
                                              {({ errors, values, setFieldValue }) => {
                                                  return (
                                                      <Form className="form-wizard mx-3">
                                                           
                                                              <Row className="mb-2">
                                                                  <H5>
                                                                  Branch Photographer (taking photos at your location):{" "} 
                                                                  </H5> 
                                                                  <span>
                                                                  A well trained Assistant will visit the business location to take professional photos of your products or services
                                                                  </span>
                      
                                                                  <Col sm="4" className="mt-3">
                                                                      <div className="flex-shrink-0 icon-state switch-outline">
                                                                          <CommonSwitchSpan
                                                                              color={"primary"}
                                                                              name="showCalculator" 
                                                                  checked={showBranchPhotoLocation}
                      
                                                                  onChange={() => setShowBranchPhotoLocation(!showBranchPhotoLocation)}
                      
                                                                          />
                                                                      </div>
                                                                  </Col>
                                                                  {showBranchPhotoLocation && ( 
                                                                      <>
                                                                       <Col sm="4" className="mb-3 mt-3">
                                                                      <Label check>
                                                                          Price (per Session per
                                                                          Branch):
                                                                      </Label>
                                                                      <Field name="photoSessionPrice" className="form-control">
                                                      {({ field, form }) => (
                                                          <select
                                                              {...field}
                                                              id="photoSessionPrice"
                                                              className={`form-select `}
                                                              onChange={(e) => {
                                                                  form.setFieldValue(
                                                                      "photoSessionPrice",
                                                                      parseInt(e.target.value, 10)
                                                                  );
                                                              }}>
                                                              <option value={10}>
                                                                  10 USD (4 hours)
                                                              </option>
                                                              <option value={20}>
                                                                  20 USD (8 hours)
                                                              </option>
                                                              <option value={30}>
                                                                  30 USD (15 hours)
                                                              </option>
                                                          </select>
                                                      )}
                                                  </Field>
                                                                  </Col>
                                                                  <Col sm="4" className="mb-3 mt-3">
                                                                      <Label check>Total: <b>
                                                                  {values.branches.length *
                                                                      values.photoSessionPrice}{" "}
                                                                  USD
                                                              </b></Label>
                                                                  </Col>
                                                                  
                                                                  </>
                                                              )}
                                                              <Col sm="4" className="mt-3">
                                                                  <Label>Auto Renew (monthly)</Label>
                                                                      <div className="flex-shrink-0 icon-state switch-outline">
                                                                          <CommonSwitchSpan
                                                                              color={"primary"}
                                                                              name="showCalculator"
                                                                              defaultChecked
                                                                          />
                                                                      </div>
                                                                  </Col>
                                                              </Row> 
                                                      </Form>
                                                  );
                                              }}
                                          </Formik>
                                      </ModalBody>
                                </Modal>}
                                {modalType == "Add an Account Assistant" &&  openModal &&
                                <CommonModal
                                      isOpen={openModal}
                                      toggle={openModalToggle}
                                      modalBodyClassName="  modal-custom"
                                      largesize="modal-custom"
                                      sizeTitle="Add an Account Assistant"
                                      AdShow={false} 
                                      size="md">
                                            <div className="mx-0 my-2">
                                            <Notes branchountry={true} city={true} branches={true} PriceDay={true} Price={false}  oneBranch={true} />
                                    
                                                <div className="mx-2">
                                                <OnlineAssistantForm openAssistantModalToggle={openModalToggle}/>
                                                    </div>
                                                </div>
                                </CommonModal>  }
                             {modalType == "productservicePublishOnline" &&  openModal &&   
            
                                    <CommonModal
                                        isOpen={openModal}
                                        toggle={openModalToggle}
                                        modalBodyClassName="  modal-custom"
                                        largesize="modal-custom"
                                        title='Publish Online'
                                        AdShow={false} 
                                        size="md"> 
                                            <Formik
                                                    initialValues={FequentlyinitialValues}
                                                    validationSchema={FequentlyValidationSchema}
                                                    onSubmit={(values) => {
                                                    console.log("Selected values:", values);
                                                    }}
                                                >
                                                    {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                        <FequentlyModalForm selectField='product' setFieldValue={setFieldValue} selectField="product" handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
                                                        )}
                                            </Formik>
                                    </CommonModal> }
                              {modalType == "importBulkProductService" &&  openModal &&   
                                <CommonModal
                                      isOpen={openModal}
                                      toggle={openModalToggle}
                                    //   modalBodyClassName="  modal-custom"
                                      largesize="modal-custom"
                                     title='Import Bulk Products and Services'
                                      AdShow={false} 
                                      
                                      size="md"> 
                                           <ImportBulk/>
                                </CommonModal>}
                            { modalType == "importBulkProductImages" &&  openModal &&   
                                <CommonModal
                                    isOpen={openModal}
                                    toggle={openModalToggle} 
                                    largesize="modal-custom"
                                    title='Import Bulk Product Images'
                                    AdShow={false}  
                                    size="md"> 
                                    <Formik
                                        initialValues={{ 
                                        bulkproductImages: [],
                                        }}
                                        validationSchema={ProductvalidationSchema}
                                        onSubmit={(values) => {
                                            // Handle form submission.
                                            console.log(values);
                                        }}
                                    >
                                        {({ setFieldValue, errors, touched }) => (
                                            <BulkProductImages name="bulkproductImages" setFieldValue={setFieldValue} errors={errors} touched={touched} />
                                        )}
                                    </Formik>
                            
                                </CommonModal>}
                            { modalType == "importBulkAddons" &&  openModal &&   
                                <CommonModal
                                    isOpen={openModal}
                                    toggle={openModalToggle} 
                                    largesize="modal-custom"
                                    title='Import Bulk Addons'
                                    AdShow={false}  
                                    size="md"> 
                                    <ImportBulk/>
                            
                                </CommonModal>}
                                { modalType == "1webbbDelivery" &&  openModal &&   
                                <CommonModal
                                    isOpen={openModal}
                                    toggle={openModalToggle}  
                                    title='1Webbb Deleviery'
                                    AdShow={false}  
                                    size="xl"> 
                                                    {/* You can display additional data here */}
                                                    <Formik
                                                        initialValues={branchPublicContactFormInitialValue}
                                                        onSubmit={handleSubmit}
                                                        validationSchema={
                                                            branchPublicContactFormValidationSchema
                                                        }>
                                                        {({ errors, values, setFieldValue }) => {
                                                            return (
                                                                <Form className="form-wizard">
                                                                    <Row>
                                                                        <Col sm="4" className="mb-3">
                                                                            <Label check>
                                                                                Country{" "}
                                                                                <span className="txt-danger">
                                                                                    *
                                                                                </span>
                                                                            </Label>
                                                                            <Country
                                                                                name="country"
                                                                                isMulti={false}
                                                                                placeholder="Select"
                                                                                className={`${
                                                                                    submitErrors &&
                                                                                    `${
                                                                                        errors.country
                                                                                            ? "is-invalid"
                                                                                            : "is-valid"
                                                                                    }`
                                                                                }`}
                                                                            />
                                                                            <ErrorMessage
                                                                                name="country"
                                                                                component="span"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </Col>
                                                                        <Col sm="4" className="mb-3">
                                                                            <Label check>State</Label>
                                                                            <State
                                                                                name="state"
                                                                                countryId={values.country} // Pass the selected country ID
                                                                                isMulti={true}
                                                                                setFieldValue={setFieldValue}
                                                                                placeholder="Select"
                                                                            />
                                                                        </Col>
                                                                        <Col sm="4" className="mb-3">
                                                                            <Label check>
                                                                                City{" "}
                                                                                <span className="txt-danger">
                                                                                    *
                                                                                </span>
                                                                            </Label>
                                                                            <City
                                                                                name="city"
                                                                                countryId={values.country}
                                                                                stateId={values.state} // Pass the selected state ID
                                                                                isMulti={true}
                                                                                placeholder="Select"
                                                                                className={`${
                                                                                    submitErrors &&
                                                                                    `${
                                                                                        errors.city
                                                                                            ? "is-invalid"
                                                                                            : "is-valid"
                                                                                    }`
                                                                                }`}
                                                                            />
                                                                            <ErrorMessage
                                                                                name="city"
                                                                                component="span"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="align-items-center">
                                                                        <Col sm="4" className="mb-3">
                                                                            <Label check>
                                                                                Branches{" "}
                                                                                <span className="txt-danger">
                                                                                    *
                                                                                </span>
                                                                            </Label>
                                                                            <Branches
                                                                                name="branches"
                                                                                placeholder="Select"
                                                                                isMulti={true}
                                                                                className={`${
                                                                                    submitErrors &&
                                                                                    `${
                                                                                        errors.branches
                                                                                            ? "is-invalid"
                                                                                            : "is-valid"
                                                                                    }`
                                                                                }`}
                                                                            />
                                                                            <ErrorMessage
                                                                                name="branches"
                                                                                component="span"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                
                                                                    <Row className="mb-2">
                                                                        <H5>
                                                                            1Webbb Deleviery{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                        </H5>
                                
                                                                        <span>
                                                                            Use 1Webbb Delivery Team to serve
                                                                            your business. You pay nothing for
                                                                            us. Our fees will be added to the
                                                                            bill as a separate fee.{" "}
                                                                        </span>
                                
                                                                        <Col sm="4" className="mt-3">
                                                                            <div className="flex-shrink-0 icon-state switch-outline">
                                                                                <CommonSwitchSpan
                                                                                    color={"primary"}
                                                                                    name="showCalculator"
                                                                                    defaultChecked
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            );
                                                        }}
                                                    </Formik>
                                </CommonModal>}
                           {  modalType == "addAlbum" &&  openModal && <AddAlbumModal isOpen={openModal} title="Add an Album" openModalToggle={openModalToggle}/>}
                           {  modalType == "addVisualIdea" &&  openModal && <AddVisualIdeaModal  isOpen={openModal} title="Add a Visual Idea" openModalToggle={openModalToggle}/>}
                                     
                           { modalType == "postPublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={
                                            FequentlyinitialValues
                                        }
                                        validationSchema={
                                            FequentlyValidationSchema
                                        }
                                        onSubmit={(values) => {
                                            console.log(
                                                "Selected values:",
                                                values
                                            );
                                        }}
                                    >
                                            {({
                                                handleChange,
                                                values,
                                                errors,
                                                setFieldValue,
                                            }) => (
                                                <FequentlyModalForm
                                                    selectField="article"
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    handleChange={handleChange}
                                                    values={values}
                                                    submitErrors={submitErrors}
                                                    errors={errors}
                                                />
                                            )}
                                    </Formik>
                                </CommonModal>
                            }            
                           { modalType == "activitesPublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={FequentlyinitialValues}
                                        validationSchema={FequentlyValidationSchema}
                                        onSubmit={(values) => {
                                        console.log("Selected values:", values);
                                        }} >
                                                            {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                <FequentlyModalForm handleChange={handleChange} setFieldValue={setFieldValue} values={values} submitErrors={submitErrors} errors={errors}/>
                                                )}
                                    </Formik> 
                            </CommonModal>
                            }            
                           { modalType == "frequentlyPublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={FequentlyinitialValues}
                                        validationSchema={FequentlyValidationSchema}
                                        onSubmit={(values) => {
                                        console.log("Selected values:", values);
                                        }} >
                                                            {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                               <FequentlyModalForm selectField='Conference' setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
                                                )}
                                    </Formik> 
                            </CommonModal>
                            }            
                           { modalType == "mediaContestPublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={FequentlyinitialValues}
                                        validationSchema={FequentlyValidationSchema}
                                        onSubmit={(values) => {
                                        console.log("Selected values:", values);
                                        }} >
                                                            {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                                              <FequentlyModalForm  selectField='photocontest'  setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
                                                               
                                                )}
                                    </Formik> 
                            </CommonModal>
                            }            
                           { modalType == "knowledgePublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={FequentlyinitialValues}
                                        validationSchema={FequentlyValidationSchema}
                                        onSubmit={(values) => {
                                        console.log("Selected values:", values);
                                        }} >
                                                            {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                                                             <FequentlyModalForm selectField='Seminar' setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
                                                               
                                                )}
                                    </Formik> 
                            </CommonModal>
                            }            
                           { modalType == "addQuestionGroup" &&  openModal && 
                            <CommonModal 
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Add a Question Group'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <AddQuestionGroupModal />
                            </CommonModal>
                            }            
                           { modalType == "addQuizzMaterial" &&  openModal && 
                            <CommonModal 
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Add a Question Group'
                                AdShow={false} 
                                backdrop={true} 
                                largesize="modal-custom"
                                size="xl"> 
                                    <AddQuizMaterial />
                            </CommonModal>
                            }            
                           { modalType == "addCourseInfo" &&  openModal && 
                            <CommonModal 
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Add Course and Teacher Information'
                                AdShow={false} 
                                backdrop={true} 
                                largesize="modal-custom"
                                size="xl"> 
                                     <AddCourseandTeacherInformation />
                            </CommonModal>
                            }            
                           { modalType == "addQuestionnaireSettings" &&  openModal && 
                            <CommonModal 
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Add Course and Teacher Information'
                                AdShow={false} 
                                backdrop={true} 
                                largesize="modal-custom"
                                size="xl"> 
                                     <Formik
                                              initialValues={
                                                  surveyAndQuizzesFormInitialValue
                                              }
                                              onSubmit={handleSubmit}
                                              validationSchema={
                                                  TabsurveyAndQuizzesFormValidationSchema
                                              }
                                          >
                                              {({
                                                  errors,
                                                  values,
                                                  setFieldValue,
                                                  handleSubmit,
                                              }) => (
                                                  <AddSurveyAndQuizzesForm
                                                      submitErrors={
                                                          submitErrors
                                                      }
                                                      setSubmitError={
                                                          setSubmitErrors
                                                      }
                                                      errors={errors}
                                                      values={values}
                                                      setFieldValue={
                                                          setFieldValue
                                                      }
                                                      addquizsurveysTab={false}
                                                      testquizsetting={false}
                                                      handleSubmit={
                                                          handleSubmit
                                                      }
                                                      // setCategory={setCategory}
                                                  />
                                              )}
                                          </Formik>
                            </CommonModal>
                            }   
    </div>
  );
};

export default SubMenuPopup;
