import * as Yup from "yup";

export interface MyResumesValidationProp {
    id: number; 
    display_resume_on_media_ceneter : string;
    subcategories: string; 
    job_title: string;
    objective: string;
    summery: string;
    education: string;
    training: string;
    languages: string;
    skills: string;
    hobbies_interests: string;
    recommendation: string;
    work_history: Array<any>;
    visibility: string;
    file: File | null;
    work : number;
    comanage : number;
    dispaymedia : number;
    comanager_users:String;
    individual_users:Array<any>;
    permission: number;
    coManagers:any
}

export const workHistoryValues = {
    name: "",
    description: ""
};

export const myResumesFormInitialValue: MyResumesValidationProp = {
    id: 0, 
    display_resume_on_media_ceneter : "1",
    subcategories: "", 
    job_title: "",
    objective: "",
    summery: "",
    education: "",
    training: "",
    languages: "",
    skills: "",
    hobbies_interests: "",
    recommendation: "",
    work_history: [workHistoryValues],
    visibility: "To Anyone",
    file: null,
    work : 0,
    permission: 1, // Default to "Edit"
    dispaymedia: 0,
    comanage :0,
    comanager_users:"",
    individual_users: [], 
    coManagers: [{ username: "", permission: 1, dispaymedia: 0 }] 
};

export const myReusmesFormSchema = Yup.object().shape({ 
    display_resume_on_media_ceneter: Yup.string().required("Required"),
    subcategories: Yup.string().required("Required"),
    job_title: Yup.string().required("Required"),  
    work_history: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Required"),
            description: Yup.string().required("Required")
        })
    ),
    file: Yup.mixed()
    .required("File is required")
    .test(
      "fileFormat",
      "Only .csv and .xlsx files are allowed",
      (value) =>
        value && ["application/vnd.ms-excel", "text/csv"].includes(value.type)
    ),
    work: Yup.string().required("Required"),
});


export interface WorkAvailabiltyValidationProp { 
    work : number;
    comanage : number;
    dispaymedia : number;
    comanager_users:String;
    permission: number; // true for "Edit", false for "Read Only"
    work_type: Array<any>;
    work_job_title: string;
    work_location:Array<any>;
    display_on_media_center: number;
    individual_users:Array<any>;
    groups: Array<any>;
}

export const workAvailabilityFormInitialValue: WorkAvailabiltyValidationProp = { 
    work : 0,
    permission: 1, // Default to "Edit"
    dispaymedia: 0,
    comanage :0,
    comanager_users:"",
    work_type: [],
    work_job_title: "",
    work_location: [],
    display_on_media_center: 1,
    individual_users: [],
    groups:[],
};

export const workAvailabilityFormSchema = Yup.object().shape({ 
    work: Yup.string().required("Required"),
});