import * as Yup from "yup";
import {
  RequiredFieldValidationText,
  URLFieldValidationText,
  NumbersFieldValidationText,
  PhoneNumberFieldValidationText,
  EmailFieldValidationText,
} from "../../../utils/Constant";
import { SchedulesFormValidationProp } from "../ManageOnlineStore/FormControlsValidation";
const onlyNumber = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;

/* Start -- Branch Public Contact Form */
export interface ManageExternalLinkFormValidationProp {
  title: string;
  description: string;
  externallink: string;
  keywords: Array<string>;
  display_on_media_center:any;
}

export const manageExternalLinkFormInitialValue: ManageExternalLinkFormValidationProp =
  { title: "", description: "", externallink: "",keywords: [],display_on_media_center:'1' };

export const manageExternalLinkFormValidationSchema = Yup.object().shape({
  title: Yup.string().required(RequiredFieldValidationText),
  description: Yup.string().required(RequiredFieldValidationText),
  keywords: Yup.array().min(1, RequiredFieldValidationText).required(RequiredFieldValidationText),
  externallink: Yup.string()
    .url(URLFieldValidationText)
    .required(RequiredFieldValidationText),
});
/* End -- Manage Branch Form */

/* Start -- Branch Public Contact Form */
export interface StudyMaterialLessonFormValidationProp {
  subcategory: string;
  specialParameter: string;
  title: string;
  materialText: string;
  dateHours: string;
  url: string;
  studymateialdescription: string;
  uniqueIdNumber: string;
  description:string;
  file: any;
}

export const studyMaterialLessonFormInitialValue: StudyMaterialLessonFormValidationProp =
  {
    description:'',
    subcategory: "",
    specialParameter: "",
    title: "",
    materialText: "",
    dateHours: "",
    url: "",
    studymateialdescription:'',
    uniqueIdNumber:'',
    file: [],
  };

export const studyMaterialLessonFormValidationSchema = Yup.object().shape({
  subcategory: Yup.string().required(RequiredFieldValidationText),
  specialParameter: Yup.string().required(RequiredFieldValidationText),
  title: Yup.string().required(RequiredFieldValidationText),
  description: Yup.string().required(RequiredFieldValidationText),
  materialText: Yup.string().required(RequiredFieldValidationText),
  dateHours: Yup.string().required(RequiredFieldValidationText),
  url: Yup.string().optional().url(URLFieldValidationText),
  studymateialdescription: Yup.string()
  .max(160, "Description must be at most 160 characters")
  .required("Description is required"), // Adding required validation
  uniqueIdNumber: Yup.string().required(' Unique Key  is required'),
  file: Yup.mixed().required('File is required'),
});
/* End -- Manage Branch Form */

/* Start -- Quiz Material Form */

export interface QuizMaterialFormValidationProp {
  subcategory: string;
  title: string;
  materialText: string;
}

export const quizMaterialFormInitialValue: QuizMaterialFormValidationProp = {
  subcategory: "",
  title: "",
  materialText: "",
};

export const quizMaterialLessonFormValidationSchema = Yup.object().shape({
  subcategory: Yup.string().required(RequiredFieldValidationText),
  title: Yup.string().required(RequiredFieldValidationText),
  materialText: Yup.string().required(RequiredFieldValidationText),
});

/* End -- Quiz Material Form */

/* Start -- Questions Form */

export interface QuestionFormValidationProp {
  subcategory: string;
  questionText: string;
  durationInMinutes: string;
  pointGrade: string;
  obligationQuestion: boolean;
  description: string;
  title: string;
  GroupanswerInput: 'none' | 'existingGroup' | 'newGroup'; 
  quizmaterialanswerInput: 'none' | 'existingGroup' | 'newGroup'; 
  answerInput: string; // Type of answer input (e.g., "text", "multiple-choice")
  quizmaterialtitle: string;
  quizmaterialdescription: string;
  audioFile: File | null;
  videoURL: string;
  uniqueIdNumber:string;
  // options: string[];   // For multiple-choice questions
  correctAnswer: string; // For selecting the correct answer
  phrases: Phrase[];
  maxCharacters: string,

}
interface Phrase {
  text: string;
  yesNo: boolean;
}
// Initial values, including new fields for options and correct answer
export const questionFormInitialValue: QuestionFormValidationProp = {
  subcategory: "",
  questionText: "",
  durationInMinutes: "",
  pointGrade: "",
  obligationQuestion: false,
  answerInput: "multiple-choice", // Default to text input
  description:"",
  title: "",
  GroupanswerInput: 'none',
  quizmaterialanswerInput: 'none',
  quizmaterialtitle:"",
  quizmaterialdescription:"",
  audioFile: null,
  videoURL: '',
  uniqueIdNumber:'',
  // options: ["", "", "", ""], // 4 empty options
  correctAnswer: "", // No correct answer selected initially 
  phrases: [{ text: "", yesNo: false },],
  maxCharacters: "",
};

// Updated validation schema to handle multiple-choice logic
export const questionFormValidationSchema = Yup.object().shape({
  subcategory: Yup.string().required(RequiredFieldValidationText),
  questionText: Yup.string().required(RequiredFieldValidationText),
  durationInMinutes: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber, NumbersFieldValidationText),
  pointGrade: Yup.string()
    .required(RequiredFieldValidationText)
    .matches(onlyNumber, NumbersFieldValidationText),
  obligationQuestion: Yup.boolean().required(RequiredFieldValidationText),
  answerInput: Yup.string().required(RequiredFieldValidationText),
  description: Yup.string().required("Description is required"), 
  quizmaterialtitle: Yup.string().required("title is required"), 
  quizmaterialdescription: Yup.string().required("Description is required"), 
  uniqueIdNumber: Yup.string().required(' Unique Key  is required'),
  maxCharacters:  Yup.string().required(RequiredFieldValidationText),
  // options: Yup.array().of(
  //   Yup.string().required("Each option must have a value"),
  //       // Define what happens otherwise if needed
      
  // ).min(4, "At least four options are required"),  
  
  correctAnswer: Yup.string().required("You must select the correct answer"),
   
    
});

/* End -- Questions Form */

/* Start -- Answer Form */
export interface answerFormValidationProp {
  
  answerInput: string; // Type of answer input (e.g., "text", "multiple-choice")
  options: string[];   // For multiple-choice questions
  correctAnswer: string; // For selecting the correct answer
}
export const answerFormInitialValue: answerFormValidationProp = {
   
  answerInput: "text", // Default to text input
  options: ["", "", "", ""], // 4 empty options
  correctAnswer: "", // No correct answer selected initially
};
export const answerFormValidationSchema= Yup.object().shape({
  correctAnswer: Yup.string().required("You must select the correct answer"),
  answerInput: Yup.string().required(RequiredFieldValidationText),
  options: Yup.array().of(
    Yup.string().required("Each option must have a value"),
        // Define what happens otherwise if needed
      
  ).min(4, "At least four options are required"),  
})
/* End -- Answer Form */
/* Start -- Survey and Quizzes Form */

export interface SurveyAndQuizzesFormValidationProp {
  formType: string;
  specialParameters: string;
  quizMaterial: string;
  title: string;
  description: string;
  recipientType: string;
  timezone: string;
  questionsCount: string;
  showFinalScore: string;
  showAnswer: string;
  showAnswerList: string;
  allowRetaking: string;
  dateHours: string;
  playerType: string;
  quizzsurveyid: string;
  negativeMakingOptional:string;
  optionnegativeMaking:string;
  showCurrectAnswer:string;
   obligatorypointGrade: string;
    optionpointGrade: string
    uniqueIdNumber:string
    image:File | null,
}

export const surveyAndQuizzesFormInitialValue: SurveyAndQuizzesFormValidationProp =
  {
    formType: "3",
    specialParameters: "",
    quizMaterial: "",
    title: "",
    description: "",
    recipientType: "Any",
    timezone: "",
    questionsCount: "",
    showFinalScore: "",
    showAnswer: "",
    showAnswerList: "",
    allowRetaking: "",
    dateHours: "",
    playerType: "",
    quizzsurveyid:'', 
    negativeMakingOptional: 'Zero',
    optionnegativeMaking : 'Zero',
    showCurrectAnswer: 'no',
    obligatorypointGrade: '',
    optionpointGrade: '',
    image:null,
    uniqueIdNumber:''
  };

export const surveyAndQuizzesFormValidationSchema = Yup.object().shape({
  formType: Yup.string().required(RequiredFieldValidationText),
  specialParameters: Yup.string().required(RequiredFieldValidationText),
  quizMaterial: Yup.string().required(RequiredFieldValidationText),
  title: Yup.string().required(RequiredFieldValidationText),
  description: Yup.string().required(RequiredFieldValidationText),
  recipientType: Yup.string().required(RequiredFieldValidationText),
  timezone: Yup.string().required(RequiredFieldValidationText),
  questionsCount: Yup.string().required(RequiredFieldValidationText),
  showFinalScore: Yup.string().required(RequiredFieldValidationText),
  showAnswer: Yup.string().required(RequiredFieldValidationText),
  showAnswerList: Yup.string().required(RequiredFieldValidationText),
  allowRetaking: Yup.string().required(RequiredFieldValidationText),
  dateHours: Yup.string().required(RequiredFieldValidationText),
  playerType: Yup.string().required(RequiredFieldValidationText),
  quizzsurveyid:Yup.string().required(RequiredFieldValidationText),
  negativeMakingOptional:Yup.string().required(RequiredFieldValidationText),
  pointGrade: Yup.number()
  .required(RequiredFieldValidationText)
  .test(
    'negativeGrade',
    'Must be a negative number when Negative Grade is selected',
    function (value) {
      const { negativeGrade } = this.parent;
      if (negativeGrade) {
        return value < 0; // Ensure negative value
      }
      return true; // Skip the test if Negative Grade is not selected
    }
  ),
});
// Define your required field validation text
const RequiredFieldValidationTexts = "This field is required";
export const TabsurveyAndQuizzesFormValidationSchema = Yup.object().shape({
  formType: Yup.string().required(RequiredFieldValidationTexts), // Required field
  title: Yup.string().required(RequiredFieldValidationTexts), // Required field
  description: Yup.string().required(RequiredFieldValidationTexts), // Required field
  recipientType: Yup.string().required(RequiredFieldValidationTexts), // Required field
  timezone: Yup.string().required(RequiredFieldValidationTexts), // Required field
  image: Yup.string().required(RequiredFieldValidationText),
  questionsCount: Yup.number()
    .min(1, "At least one question is required") // Minimum of 1 question required
    .required(RequiredFieldValidationTexts), // Required field
  showFinalScore: Yup.boolean()
    .required(RequiredFieldValidationTexts), // Required field
  allowRetaking: Yup.boolean()
    .required(RequiredFieldValidationTexts), // Required field
  dateHours: Yup.date()
    .required(RequiredFieldValidationTexts), // Required field
  playerType: Yup.string()
    .required(RequiredFieldValidationTexts), // Required field
  // Optional fields (remove validation if they are not required)
  specialParameters: Yup.string().nullable(), // Optional
  quizMaterial: Yup.string().nullable(), // Optional
  showCurrectAnswer: Yup.string().required(RequiredFieldValidationTexts),
  uniqueIdNumber: Yup.string().required(' Unique Key  is required'),
  obligatorypointGrade: Yup.number()
  .required(RequiredFieldValidationText)
  .test(
    'negativeGrade',
    'Must be a negative number when Negative Grade is selected',
    function (value) {
      const { negativeGrade } = this.parent;
      if (negativeGrade) {
        return value < 0; // Ensure negative value
      }
      return true; // Skip the test if Negative Grade is not selected
    }
  ),
  optionpointGrade: Yup.number()
  .required(RequiredFieldValidationText)
  .test(
    'negativeGrade',
    'Must be a negative number when Negative Grade is selected',
    function (value) {
      const { negativeGrade } = this.parent;
      if (negativeGrade) {
        return value < 0; // Ensure negative value
      }
      return true; // Skip the test if Negative Grade is not selected
    }
  ),
});
/* End -- Survey and Quizzes Form */

/* Start -- Attendance System Add new event form */

export interface AttendanceFormValidationProp {
  referenceid: string;
  activitytype: string;
  activitytitle: string;
  description: string;
  activitylocation: boolean;
  country: Array<String>;
  state: Array<String>;
  city: Array<String>;
  fulladdress: string;
  subcategory: string;
  keywords: Array<string>;
  duration: string;
  instructions:string;
  startdates: Date;
  expirydate: Date;
  leavingactivity:boolean;
  breakinactivity:boolean;
  publicactivity:string;
  latearrival:number;
  supervisorconfirm:boolean;
  supervisorname:string;
  manualcheck:boolean;
  inviteusers:Array<string>;
  image:File | null;
  activityschedule: Array<any>;
  // offline: boolean;
  // online: boolean;
  meetingurl: string;
  phonenumber: string;
  meetingplatform: string;
  activityfrequency:boolean;
  dateranges:string;
  comanage :any,
  coManagers: [{ username: string, permission: any, dispaymedia: any }],
  PolicyService:string;
}



export const AddAttendanceNewEventInitialValues: AttendanceFormValidationProp =
  {
    referenceid: "",
    activitytype: "",
    activitytitle: "",
    description: "",
    activitylocation: false,
    country: [],
    state: [],
    city: [],
    fulladdress: "",
    subcategory: "",
    keywords: [],
    duration: '',
    instructions:"",
    startdates: new Date(),
    expirydate: new Date(),
    leavingactivity:false,
    breakinactivity:false,
    publicactivity:'',
    latearrival:10,
    supervisorconfirm:true,
    supervisorname:'',
    manualcheck:true,
    inviteusers:[],
    image:null,
    activityschedule: [],
    // offline: false,
    // online: false,
    meetingurl: "",
    phonenumber: "",
    meetingplatform: "",
    activityfrequency:false,
    dateranges:"",
    comanage :0,
    coManagers: [{ username: "", permission: 1, dispaymedia: 0 }],
    PolicyService:"",
  };

export const AttendanceNewEventFormValidationSchema = Yup.object().shape({
  // genereal fields
  referenceid: Yup.string().required(RequiredFieldValidationText),
  activitytype: Yup.string().required(RequiredFieldValidationText),
  activitytitle: Yup.string().required(RequiredFieldValidationText),
  description: Yup.string().required(RequiredFieldValidationText),
  subcategory: Yup.string().required(RequiredFieldValidationText),
  keywords: Yup.array().required(RequiredFieldValidationText)  // This ensures the array is required
  .min(1, RequiredFieldValidationText),
  duration: Yup.string(),
  image:Yup.mixed().nullable().required(RequiredFieldValidationText),
  publicactivity: Yup.string(),
  
  // requirements and instruction fields
  instructions: Yup.string(),
  leavingactivity:Yup.boolean().required(RequiredFieldValidationText),
  breakinactivity:Yup.boolean(),
 
//  management feilds
latearrival:Yup.number(),
supervisorconfirm:Yup.boolean(),
supervisorname:Yup.string(),
manualcheck:Yup.boolean(),
inviteusers:Yup.array(),

// location realted fields
  activitylocation: Yup.boolean().when("activitytype", {
    is: "appointment",
    then: (schema) => schema.required(RequiredFieldValidationText),
    otherwise: (schema) => schema.notRequired(),
  }),
  country: Yup.array().when(["activitytype", "activitylocation"], {
    is: (activitytype: string, activitylocation: boolean) =>
      activitytype !== "appointment" ||
      (activitytype === "appointment" && activitylocation === true),
    then: (schema) =>
      schema.min(1, RequiredFieldValidationText).required(RequiredFieldValidationText),
    otherwise: (schema) => schema.notRequired(),
  }),
  state: Yup.array().notRequired(),
  city: Yup.array().when(["activitytype", "activitylocation"], {
    is: (activitytype: string, activitylocation: boolean) =>
      activitytype !== "appointment" ||
      (activitytype === "appointment" && activitylocation === true),
    then: (schema) =>
      schema.min(1, RequiredFieldValidationText).required(RequiredFieldValidationText),
    otherwise: (schema) => schema.notRequired(),
  }),
  fulladdress: Yup.string().when(["activitytype", "activitylocation"], {
    is: (activitytype: string, activitylocation: boolean) =>
      activitytype !== "appointment" ||
      (activitytype === "appointment" && activitylocation === true),
    then: (schema) => schema.required(RequiredFieldValidationText),
    otherwise: (schema) => schema.notRequired(),
  }),
  meetingurl: Yup.string().url(URLFieldValidationText),
  phonenumber: Yup.string(),
  meetingplatform: Yup.string(),

// Dates and Time feilds
activityfrequency:Yup.boolean().required(RequiredFieldValidationText),
   startdates: Yup.date(),
  expirydate: Yup.date(),
   activityschedule: Yup.array(),
   dateranges:Yup.string(),
  
  
   // offline: Yup.boolean().when("activitytype", {
  //   is: "appointment",
  //   then: (schema) => schema.required(RequiredFieldValidationText),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  // online: Yup.boolean().when("activitytype", {
  //   is: "appointment",
  //   then: (schema) => schema.required(RequiredFieldValidationText),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
});
/* End -- Attendance System Add new event form */


/* Start -- General Parameters Form */
export interface SpecialParametersFormValidationProp {
  title: string;
  country: any;
  city: any;
  branches: any;
  phones: Array<any>;
  emails: Array<any>;
  sharing:any;
  isLessonChecked: any;
  isKnowledgeChecked: any;
  image:File | null;
  description:string
  breakinActivity: boolean,
  uniqueIdNumber:string
}
export const phoneValue = {
  phone: "",
};

export const emailValue = {
  email: "",
};
export const specialParametersFormInitialValue: SpecialParametersFormValidationProp =
  {
    title: "",
    country: "",
    city: "",
    branches: "",
    phones: [phoneValue],
    emails: [emailValue],
    sharing:'',
      isLessonChecked: false,
  isKnowledgeChecked: false,
  image:null,
  description: "",
  breakinActivity: true,
  uniqueIdNumber:""
  };

export const specialParametersFormValidationSchema = Yup.object().shape({
  title: Yup.string().required(RequiredFieldValidationText),
   country: Yup.array().min(1).required(RequiredFieldValidationText),
    city: Yup.array().min(1).required(RequiredFieldValidationText),
    branches: Yup.array().min(1).required(RequiredFieldValidationText),
    phones: Yup.array().of(
      Yup.object().shape({
        phone: Yup.number().typeError(PhoneNumberFieldValidationText),
      })
    ),
    emails: Yup.array().of(
      Yup.object().shape({
        email: Yup.string().optional().email(EmailFieldValidationText),
      })
    ),
    image: Yup.string().required(RequiredFieldValidationText),
    description: Yup.string().required(RequiredFieldValidationText),
    uniqueIdNumber: Yup.string().required(' Unique Key  is required'),
});

/* End -- Survey and Quizzes Form */

/* Start -- Photo Contest Form */
export interface PhotoContestsFormValidationProp {
  photoContestTitle: string;
  description: string;
  image: string;
  submissionStartDate: string;
  submissionEndDate: string;
  photoContestEndDate: string;
  minNumberOfFiles: string;
  maxNumberOfFiles: string;
  mediaType: string;
  minSharesPerContent: string;
  minLikesPerContent: string;
  minVotesPerContent: string;
  mediaCenter: string;
  onlineStore: string;
  prizes: Array<any>;
  isPreviousCustomer: boolean;
  minOrderAmount: number;
  faq:  Array<any>;
  comanage :any,
  coManagers: [{ username: string, permission: any, dispaymedia: any }],
  PolicyService: string;
}

export const prizesValue = {
  rank: "",
  prizeName: "",
  prizeImage: "",
  prizeDescription: "",
  prizeValueInUsd: "",
  numberOfPrize: "",
  numberOfShares: "",
  numberOfLikes: "",
  numberOfVotes: "",
  excatNumber: "",
  priceType:'' 
};

export const photoContestsFormInitialValue: PhotoContestsFormValidationProp = {
  photoContestTitle: "",
  description: "",
  image: "",
  submissionStartDate: "",
  submissionEndDate: "",
  photoContestEndDate: "",
  minNumberOfFiles: '1',
  maxNumberOfFiles: "",
  mediaType: "",
  minSharesPerContent: "",
  minLikesPerContent: "",
  minVotesPerContent: "",
  mediaCenter: "",
  onlineStore: "",
  prizes: [prizesValue],
  isPreviousCustomer: false,
  minOrderAmount: 0,
  faq: [],
  comanage :0,
  coManagers: [{ username: "", permission: 1, dispaymedia: 0 }],
  PolicyService: "",
};

export const photoContestsFormValidationSchema = Yup.object().shape({
  photoContestTitle: Yup.string().required(RequiredFieldValidationText),
  description: Yup.string().required(RequiredFieldValidationText),
  image: Yup.string().required(RequiredFieldValidationText),
  submissionStartDate: Yup.string().required(RequiredFieldValidationText),
  submissionEndDate: Yup.string().required(RequiredFieldValidationText),
  photoContestEndDate: Yup.string().required(RequiredFieldValidationText),
  isPreviousCustomer: Yup.boolean().required("This field is required"),
  faq: Yup.array()
    .of(Yup.object().shape({ label: Yup.string().required(), value: Yup.string().required() }))
    .nullable(),
  // minOrderAmount: Yup.number()
  //   .when("isPreviousCustomer", {
  //     is: true,
  //     then: Yup.number()
  //       .required("Minimum order amount is required")
  //       .min(1, "Amount must be greater than 0")
  //       .positive("Amount must be positive"),
  //   })
  //   .nullable(),
    minNumberOfFiles: Yup.number()
    .min(1, "Minimum number must be at least 1")
    .max(5, "Maximum number is 5")
    .required("Minimum number of files is required"),
  
  maxNumberOfFiles: Yup.number()
    .max(10, "Maximum number is 10")
    .moreThan(Yup.ref('minNumberOfFiles'), "Maximum must be greater than Minimum")
    .required("Maximum number of files is required"),
  mediaType: Yup.string().required(RequiredFieldValidationText),
  
  // minSharesPerContent: Yup.string()
  //   .required(RequiredFieldValidationText)
  //   .matches(onlyNumber,NumbersFieldValidationText),
  // minLikesPerContent: Yup.string()
  //   .required(RequiredFieldValidationText)
  //   .matches(onlyNumber,NumbersFieldValidationText),
  // minVotesPerContent: Yup.string()
  //   .required(RequiredFieldValidationText)
  //   .matches(onlyNumber,NumbersFieldValidationText),
  mediaCenter: Yup.string().required(RequiredFieldValidationText),
  onlineStore: Yup.string().required(RequiredFieldValidationText),
  // Conditional validation based on checkbox selection
  
  prizes: Yup.array().of(
    Yup.object().shape({
      rank: Yup.string()
        .required(RequiredFieldValidationText)
        .matches(onlyNumber,NumbersFieldValidationText),
      prizeName: Yup.string().required(RequiredFieldValidationText),
      prizeImage: Yup.string().required(RequiredFieldValidationText),
      prizeDescription: Yup.string().required(RequiredFieldValidationText),
      prizeValueInUsd: Yup.string()
        .required(RequiredFieldValidationText)
        .matches(onlyNumber,NumbersFieldValidationText),
      numberOfPrize: Yup.string()
        .required(RequiredFieldValidationText)
        .matches(onlyNumber,NumbersFieldValidationText),
      numberOfShares: Yup.string().required(RequiredFieldValidationText),
      numberOfLikes: Yup.string().required(RequiredFieldValidationText),
      numberOfVotes: Yup.string().required(RequiredFieldValidationText),
      excatNumber: Yup.string().required(RequiredFieldValidationText),
      priceType: Yup.string().required(RequiredFieldValidationText),
      // Always require 50 or more, with default value 50
  minSharesPerContent: Yup.number()
  .min(50, "Enter 50 or more")
  .required("Minimum eligibility to win a prize (minimum shares per content)*")
  .default(50),

minLikesPerContent: Yup.number()
  .min(50, "Enter 50 or more")
  .required("Minimum eligibility to win a prize (minimum likes per content)*")
  .default(50),

minVotesPerContent: Yup.number()
  .min(50, "Enter 50 or more")
  .required("Minimum eligibility to win a prize (minimum votes per content)*")
  .default(50),
    })
  ),
});

/* End -- Photo Contest Form */
// Questionnaire Form 
export interface QuestionnaireFormValues {
  quizmaterialtitle: string;
  academicYear?: number | "";
  quizmaterialdescription: string;
  keywords?: string[];
  file?: File | null;
  breakinActivity:Boolean;
}

export const QuestionnaireinitialValues: QuestionnaireFormValues = {
  quizmaterialtitle: '',
  academicYear: '',
  quizmaterialdescription: '',
  keywords: [],
  file: null,
  breakinActivity:false,
};
export const QuestionnairevalidationSchema = Yup.object({
  quizmaterialtitle: Yup.string().required('Title is required'),
  academicYear: Yup.number().nullable(),
  quizmaterialdescription: Yup.string().required('Description is required'),
  keywords: Yup.array().of(Yup.string()),
  file: Yup.mixed()
    .required('File is required')
    .test('fileSize', 'File size is too large', (value) => {
      return value ? (value as File).size <= 5242880 : true; // 5MB limit
    })
    .test('fileType', 'Unsupported file format', (value) => {
      return value ? ['application/pdf', 'image/jpeg', 'image/png'].includes((value as File).type) : true;
    }),
});
// Questionnaire Form End
export interface FormState {
  specialParameters: SpecialParametersFormValidationProp;
  studyMaterialLesson: SchedulesFormValidationProp;
}