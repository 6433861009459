 
import React, { useState } from 'react'
import { StudyMaterialLessonFormValidationProp, studyMaterialLessonFormValidationSchema } from '../../../../Data/Tools/BrandSupportTools/FormControlsValidation';
import AddStudyMaterialLessonForm from '../../../../Component/Tools/BrandSupportTools/StudyMaterialLesson/AddStudyMaterialLessonForm';
import { ErrorMessage, Field, Formik } from "formik";
import { useAppSelector } from '../../../../ReduxToolkit/Hooks';
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu';
import { Card, CardBody, Col, Container, Form, Label, Row } from 'reactstrap';
import StudyMaterialSubMenu from '../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu';
import { BrandTools, Lesson } from '../../../../utils/Constant';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs';
import Country from '../../../../Component/CommonInput/Country';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FequentlyinitialValues, FequentlyValidationSchema, manageBranchFormInitialValue, manageBranchFormValidationSchema } from '../../../../Data/Tools/ManageOnlineStore/FormControlsValidation';
import { LI, UL } from '../../../../AbstractElements';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Popup from '../../../../Component/MasterPopup/Popup';
import ImportBulk from '../ImportBulk/ImportBulk';
import FequentlyModalForm from '../FequentlyQuestion/FequentlyModalForm';
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup';
export const options = [
    { value: '5', label: '30 Days, 5 USD' },
    { value: '12', label: '90 Days (4 USD/ 30 Days) if you pay 12 USD' },
    { value: '18', label: '180 Days (3 USD/ 30 Days) if you pay 18 USD' },
    { value: '24', label: 'Annually (2 USD/ 30 Days) if you pay 24 USD' }, 
  ];
function AddLesson() {
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const formValues = useAppSelector((state) => state.form?.studyMaterialLesson);
     const handleSubmit = (values: StudyMaterialLessonFormValidationProp) => {
            console.log(values);
            setSubmitError(false);
        };
        const [openBulkModal, setOpenBulkModal] = useState(false); 
    const openBulkModalToggle = () => setOpenBulkModal(!openBulkModal);
        const [openPublishModal, setOpenPublishModal] = useState(false);
    const openPublishModalToggle = () => setOpenPublishModal(!openPublishModal);
  return (
    <div className='page-body'>
       <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                  
         <SubMenuPopup  currentPage="Add a Lesson"/>
                </div> 
         <Breadcrumbs mainParent="All Tools" mainTitle={`${Lesson} (${BrandTools})`} parent={BrandTools} />
            </div>
        <Container fluid>
            <Row>
            <Col sm="12">
                <Card>
                <div className='mt-2 ms-1'> 
                <StudyMaterialSubMenu position='3' bulkandpublish={true} openBulkModalToggle={openBulkModalToggle} openPublishModalToggle={openPublishModalToggle} bulkbtn={true} publishBtn={true}/>
                  </div> 
                  <CardBody className='p-3'>
                    <> 
          <Formik
                                        initialValues={
                                            formValues
                                        }
                                        onSubmit={(values, { setSubmitting }) => {
                                            // Handle form submission logic here
                                            console.log('Form values:', values);
                                            setSubmitting(false); // If you're doing async work, ensure to setSubmitting(false)
                                          }}
                                        validationSchema={
                                            studyMaterialLessonFormValidationSchema
                                        }>
                                        {({
                                            errors,
                                            values,
                                            setFieldValue, handleSubmit
                                        }) => (
                                            <AddStudyMaterialLessonForm
                                                submitErrors={submitErrors}
                                                setSubmitError={setSubmitError}
                                                errors={errors}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                               
                                                handleSubmit={handleSubmit}
                                            />
                                        )}
                                    </Formik>
                                    <Popup
                title='Import Bulk Lesson'
                openModalToggle={openBulkModalToggle}
                isOpen={openBulkModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup>
            <Popup
                title='Publish Online'
                openModalToggle={openPublishModalToggle}
                isOpen={openPublishModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                  <Formik
                        initialValues={FequentlyinitialValues}
                        validationSchema={FequentlyValidationSchema}
                        onSubmit={(values) => {
                          console.log("Selected values:", values);
                        }}
                      >
                        {({ handleChange, values,errors ,setFieldValue}) => ( 
               <FequentlyModalForm selectField='lesson' setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
              )}
    </Formik>
 
            </Popup>
                                    </>
                </CardBody>
                </Card>
            </Col>
            </Row>
        </Container> 
        
    </div>
  )
}

export default AddLesson