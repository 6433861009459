import { useState } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ManageBranches, ManagesBranchesAdd } from "../../../../utils/Constant"; 
import { Formik } from "formik"; 
import {
  ManageBranchFormValidationProp,
  manageBranchFormInitialValue,
  manageBranchFormValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import AddManageBranchesForm from "../../../../Component/Tools/ManageOnlineStore/ManageBranches/AddManageBranchesForm";
import { BranchsAction } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import { Link } from "react-router-dom";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import BranchSubMenu from "./BranchSubMenu/BranchSubMenu";
import Popup from "../../../../Component/MasterPopup/Popup";
import ImportBulk from "../../BrandSupportTools/ImportBulk/ImportBulk";
import { Btn } from "../../../../AbstractElements";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const AddManageBranch = () => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (values: ManageBranchFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
  };
  
  return (
    <div className="page-body">
      <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                <SubMenuPopup  currentPage="Add a Branch"/>
                </div>
                <Breadcrumbs mainParent="All Tools" mainTitle={`${ManagesBranchesAdd} (Manage Store)`} parent={ManageBranches} />
            </div> 
       
      <Container  fluid> 
      <div className='ms-1 my-2'>
      <BranchSubMenu sliced='-4'/> 
                  </div>  
                  
                  <Card >
                    <CardBody className="p-2">
                    <Formik
          initialValues={manageBranchFormInitialValue}
          onSubmit={handleSubmit}
          validationSchema={manageBranchFormValidationSchema}
        >
          {({ errors, values,setFieldValue }) => (
            <AddManageBranchesForm
              submitErrors={submitErrors}
              setSubmitError={setSubmitError}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
        </Formik>
        
                    </CardBody>
                  </Card>
        
      </Container>
    </div>
  );
};

export default AddManageBranch;
