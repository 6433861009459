import {useState} from 'react'
import { BrandTools, Lesson, ManageExternalTitle, QuizMaterial, StudyMaterial } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import EducatinalGame from './EducatinalGame'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'
 

const EducationalGamePage = () => {
  
   
  
    return (
        <div className='page-body'>
             <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                <SubMenuPopup  currentPage="Game"/>
                </div>
               
        <Breadcrumbs mainParent="All Tools" mainTitle={`Educational Games - Questionnaire List (${BrandTools})`} parent={BrandTools} />
            </div>
        <EducatinalGame/>
        </div>
    )
  
}

export default EducationalGamePage