import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillWave, faChevronUp, faChevronDown, faLanguage, faCoins } from "@fortawesome/free-solid-svg-icons";
import { H6, LI } from "../../../../AbstractElements";

const currencyData = [
  { currencyName: "US Dollar", currencyCode: "USD", symbol: "$" },
  { currencyName: "Euro", currencyCode: "EUR", symbol: "€" },
  { currencyName: "British Pound", currencyCode: "GBP", symbol: "£" },
  { currencyName: "Japanese Yen", currencyCode: "JPY", symbol: "¥" },
  { currencyName: "Indian Rupee", currencyCode: "INR", symbol: "₹" },
];

const CurrencyDropdown = () => {
  const [open, setOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currencyData[0]);

  const toggleCurrencySelection = () => {
    setOpen(!open);
  };

  const changeCurrency = (currency: any) => {
    setSelectedCurrency(currency);
    setOpen(false);
  };

  return (
    <LI className="language-nav my-2 mb-4">
      <div className={`translate_wrapper ${open ? "active" : ""}`}>
        <div className="current_lang" onClick={toggleCurrencySelection}>
          <div className="lang d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2">
              <FontAwesomeIcon icon={faCoins} style={{ width: '23px' }} />
              <H6 className="py-0 my-0">Currency {`(${selectedCurrency.currencyCode})`}</H6>
            </div>
            <FontAwesomeIcon 
              icon={open ? faChevronUp : faChevronDown} 
              style={{ cursor: 'pointer' }} 
            />
          </div>
        </div>
        <div className={`more_lang position-relative z-3 ${open ? "active" : ""}`}>
          {currencyData.map((data, index) => (
            <div
              className="lang selected"
              key={index}
              onClick={() => changeCurrency(data)}
            >
              <i className={`flag-icon fi  `}>{data.symbol}</i>
              <span className="lang-txt">
                {data.currencyName}
                {data.currencyCode && <span> ({data.symbol})</span>}
              </span>
            </div>
          ))}
        </div>
      </div>
    </LI>
  );
};

export default CurrencyDropdown;
