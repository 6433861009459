import {useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import {  BranchesColumnData, BranchesSupportColumnData, BranchSupportDataType, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import {  BranchsAction,  ManageOnlineStore } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import ManageOnlineSubMenu from '../ManageOnlineSubMenu/ManageOnlineSubMenu'
import BranchSubMenu from './BranchSubMenu/BranchSubMenu'
import CommonSwitchSpan from '../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan'
import CommonSwitch from '../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitch'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import { Btn, H3, SVG } from '../../../../AbstractElements'
import { X } from 'react-feather'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'

const dummyData = [
  { 
    ownerUsername: 'owner1',
    assistantUsername: 'assistant1',
    noOfBranches: 5,
    branchList: ['Branch A', 'Branch B', 'Branch C'],
  },
  { 
    ownerUsername: 'owner2',
    assistantUsername: 'assistant2',
    noOfBranches: 3,
    branchList: ['Branch X', 'Branch Y'],
  },
  { 
    ownerUsername: 'owner3',
    assistantUsername: 'assistant3',
    noOfBranches: 8,
    branchList: ['Branch P', 'Branch Q', 'Branch R', 'Branch S'],
  },
];

const ManageBranches = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedRow , setSelectedRow ] = useState(null);
  
    const [showModal, setShowModal] = useState(false);
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const handleRowSelected =() =>{}
     
    const openModalToggleColumnClick = () =>{
      setIsPopupOpen(!isPopupOpen);
    }
    const handleColumnClick = () => {
      setIsPopupOpen(!isPopupOpen); 
      // setSelectedRow(row); // Store the clicked row's data
    };
    const updatedBranchesColumnData = [
      ...BranchesColumnData.slice(0, 3), // First three columns (SR. No, Branch Name, Thumbnail)
      {
        name: 'Verified Business Tags', // This is the new column we want to add
        selector: (row) => (
          <div>
            <p className='mb-2'>Location: {row.locationVerified ? 'Yes' : 'No'}</p>
            <p className='mb-2'>License: {row.licenseVerified ? 'Yes' : 'No'}</p>
            <p className='mb-2 text-info' onClick={() => handleModalOpen()}  >
              Verify
            </p>
          </div>
        ),
        sortable: true,
        center: true,
      },
      {
        name: '1Webbb Delivery', // This is the new column we want to add
        selector: (row) => (
          <div>
             <CommonSwitch />
          </div>
        ),
        sortable: true,
        center: true,
      },
      {
        name: 'Photographer at the branch', // This is the new column we want to add
        selector: (row) => (
          <div>
             <p>10 USD (4 hours)</p>
          </div>
        ),
        sortable: true,
        center: true,
      },
      {
            name: <TableHeadColumn title={`Assistants`}/>,
            selector: (row) => row["assistants"],
            sortable: true,
            center: true,
            cell: (row) => (
        <div onClick={() => handleColumnClick()}>
          {row["assistants"]}
        </div>)
          },
      ...BranchesColumnData.slice(3), // Remaining columns (Thumbnail column will be before this)
    ];
    
    return (
    <div className='page-body'>
      <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                <SubMenuPopup  currentPage="Branch List"/>
                </div>
                <Breadcrumbs mainTitle={`Manage Branches (Manage Store)`} mainParent={`All Tools`} parent={`Manage Store`} />
            </div>
       
      <Container fluid>
        <Row>
          <div className='mt-2'> 
                <BranchSubMenu  /> 
          </div>
          <div> 
                       
                     </div>
          <Col sm="12">
            <Card>
              <CardBody className='p-2'>
                <>
                <div className=''>
                    </div> 
                    <div className="table-responsive ">
                    <DataTable className='custom-scrollbar' columns={updatedBranchesColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
                                            isOpen={showModal}
                                            toggle={handleModalClose}
                                            size="xl">
                                            <ModalHeader toggle={handleModalClose}>
                                            Verify Branch
                                            </ModalHeader>
                                            <ModalBody>
                                                {/* You can display additional data here */}
                                          
    
    <div className="form-wizard">
      
      <Row>
        <Col sm="4" className="mb-3">
          <p  >
            Country:  india
          </p>  
        </Col>
        <Col sm="4" className="mb-3">
        <p  >
        State: Gujrat
          </p>  
        </Col>
        <Col sm="4" className="mb-3">
        <p  >
        City: Surat
          </p>  
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm="4" className="mb-3">
        <p>
        Branches : Surat 
        </p>
          
        </Col>
        {/* <Col sm="4" className="mt-2">
           <Button className="btn btn-primary">
            Select Branch
           </Button>
        </Col>  */}
      </Row>
    </div> 
                                            </ModalBody>
                                        </Modal>
                                        <Modal
  isOpen={isPopupOpen}
  toggle={openModalToggleColumnClick}
  modalBodyClassName="px-3"
  size= 'xl' 
//   backdrop={backdrop}
>
  <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
    {/* Header Section */}
    <div className="modal-header align-items-center justify-content-between">
      <H3 className="w-50 text-left">Branch Information</H3>
        <div onClick={openModalToggleColumnClick} className="search-close-icon">
                 <X />
               </div>
    </div>

    {/* Body Section */}
    <ModalBody>
    <DataTable 
        columns={BranchesSupportColumnData}
        data={dummyData} 
        pagination
      />
    </ModalBody>
 {/* Body Section */}

    {/* Footer Section */}
    {/* {CommonFooter && ( */}
      <ModalFooter className="modal-footer">
        <div className="next_previous position-relative pt-0">
          <Btn  className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Previous <SVG iconId="back-arrow" />
            </div>
          </Btn>
          <Btn className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Next <SVG iconId="front-arrow" />
            </div>
          </Btn>
        </div>
      </ModalFooter>
    {/* )} */}
  </div>
</Modal>
      </Container>
    </div>
  )
}

export default ManageBranches