import { useState } from "react";
import { CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs"; 
import {
  BrandSupportTools,
  AddMangeExternalLinks,
  BrandTools,
} from "../../../../utils/Constant"; 
import AddManageExternalLinkForm from "../../../../Component/Tools/BrandSupportTools/ManageExternalLink/AddManageExternalLinkForm";
import { Formik } from "formik";
import {
  ManageExternalLinkFormValidationProp,
  manageExternalLinkFormInitialValue,
  manageExternalLinkFormValidationSchema,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const AddManageMyExternalLink = () => { 

  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (values: ManageExternalLinkFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
  };

  return (
    <div className="page-body">
       <div className="d-flex container-fluid align-items-center">
                <div className="px-2"> 
                <SubMenuPopup  currentPage="Add an External Link"/>
                </div>
      <Breadcrumbs
        mainTitle={`${AddMangeExternalLinks} (${BrandTools})`}
        parent={BrandSupportTools}
        mainParent="All Tools"
      /> 
            </div>
 
      <Container className="card p-4" fluid>
        <Formik
          initialValues={manageExternalLinkFormInitialValue}
          onSubmit={handleSubmit}
          validationSchema={manageExternalLinkFormValidationSchema}
        >
          {({ errors, values, setFieldValue }) => (
            <AddManageExternalLinkForm
              submitErrors={submitErrors}
              setSubmitError={setSubmitError}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default AddManageMyExternalLink;
