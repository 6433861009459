import React ,{useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { BrandTools, PhotoContestSystem } from '../../../../utils/Constant'
import { ActionButtons, MyPhotoContestTableAction, links, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import Popup from '../../../../Component/MasterPopup/Popup'
import PhotoContestEdit from '../../../../Component/Tools/BrandSupportTools/ManageMyPhotoContest/PhotoContestEdit'
import PhotoContestView from '../../../../Component/Tools/BrandSupportTools/ManageMyPhotoContest/PhotoContestView'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import { Btn } from '../../../../AbstractElements'
import FequentlyModalForm from '../FequentlyQuestion/FequentlyModalForm'
import { FequentlyinitialValues, FequentlyValidationSchema } from '../../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import { Formik } from 'formik'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'


  

const ManageMyPhotoContests = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const [isEditOpen , setIsEditOpen] = useState(false);
    const [isOpen , setIsOpen] = useState(false);
    
    const handleRowSelected =() =>{}

    const openPopup = (type:boolean) =>{
      setIsOpen(!isOpen);
      setIsEditOpen(type);
    }

    const openModalToggle = () =>{
      setIsOpen(!isOpen);
    }
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const [openQestionModal, setOpenQestionModal] = useState(false);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
    const ManageMyPhotoContestColumnData: TableColumn<SupportDataType>[] = [
      {
        name:<TableHeadColumn title={`ID`}/> ,
        selector: (row) => row["id"],
        sortable: true,
        center: false,
        grow: 2,
      },
      {
        name: <TableHeadColumn title={`By`}/>,
        selector: (row) => row["by"],
        sortable: true,
        center: false,
      },
      {
        name:<TableHeadColumn title={`Photo Contest`}/> ,
        selector: (row) => row["id"],
        sortable: true,
        center: true,
        grow: 2,
      },
      {
        name: <TableHeadColumn title={`Thumbnail`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: true,
        grow: 2,
      },
      {
        name: <TableHeadColumn title={`Approval Status`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: true,
        grow: 2,
      },
      {
        name: <TableHeadColumn title={`Photo Contest Description`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: true,
        grow: 2,
      },
      {
        name:<TableHeadColumn title={`Keywords`}/> ,
        selector: (row) => row["id"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Prizes`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Submission Start Date`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Submission End Date`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Photo Contest End Date`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Minimum Number of files`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Maximum Number of files`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
        grow: 2,
      },
      {
        name: <TableHeadColumn title={`Media Type`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
        grow: 2,
      }, 
      {
        name: <TableHeadColumn title={`Created On`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
        grow: 2,
      },
      {
        name:<TableHeadColumn title={`Actions`}/>,
        cell: (row) => <ActionButtons openPopup={openPopup} />,
        center: false,
        right:true,
        grow: 3,
      },
    ];
    
    return (
      <div className='page-body'>
         <div className="d-flex container-fluid align-items-center">
                <div className="px-2"> 
        <SubMenuPopup  currentPage="Media Contest List"/> 
                </div> 
        <Breadcrumbs mainParent="All Tools" mainTitle={`${PhotoContestSystem} (${BrandTools})`} parent={BrandTools} />
            </div>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
              <div className='mt-2'>
                  {MyPhotoContestTableAction.map((action, index)=>{ 
                      return   <Btn className='p-0'  ><span className={`border rounded p-2 m-1 bg-dark text-white  ps-2 pe-2  `}>{action.actionName}</span></Btn>
  
                  })}
                  </div> 
                <CardBody className='p-2'>
                  <>  
                  <div className="table-responsive pt-2">
                    <DataTable className='custom-scrollbar' columns={ManageMyPhotoContestColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                  </div>

                  <Popup title={`Photo Contests`} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`}>
                    {isEditOpen ? <PhotoContestEdit/> : <PhotoContestView /> }
                  </Popup>
                  <Popup
                title='Publish Online'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                  <Formik
                        initialValues={FequentlyinitialValues}
                        validationSchema={FequentlyValidationSchema}
                        onSubmit={(values) => {
                          console.log("Selected values:", values);
                        }}
                      >
                        {({ handleChange, values,errors,setFieldValue }) => ( 
               <FequentlyModalForm  selectField='photocontest'  setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
              )}
    </Formik>
 
            </Popup>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default ManageMyPhotoContests