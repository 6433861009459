import { Btn } from "../../../../AbstractElements";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { setCategoryModal } from "../../../../ReduxToolkit/Reducers/ContactSlice";
import {
    AddKeywords,
    AddLabels,
    Cancel,
    Save,
} from "../../../../utils/Constant";
import {
    Col,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Field, Formik } from "formik";
import { manageAlbumFormValidationSchema } from "../../../../Data/Tools/ManageContents/FormControlsValidation";
import TagsInput from "../../../CommonInput/TagsInput";
import CustomSelect from "../../../CommonInput/CustomSelect";
import { useState } from "react";

export const keywords = ["All", "Personal", "Work", "Important"];
const CategoryCreate = () => {
    const { categoryModal } = useAppSelector((state) => state.contact);
    const dispatch = useAppDispatch();
    const categoryToggle = () => dispatch(setCategoryModal());
    const [ismodal,setIsmodal]= useState(false)
    const handleInviteToogle =() => setIsmodal(!ismodal)
    const handleSubmit = () => {};
    const Options = [
        {
            label: " All",
            value: "All",
        },
        {
            label: "Personal",
            value: "Personal",
        },
        {
            label: "Work",
            value: "Work",
        },
    ];
    return (
        <>
        <div className="mt-2 d-flex gap-2">
            <Btn
                color="black"
                className="btn btn-light btn bg-gray"
                onClick={categoryToggle}
            >
                + {AddLabels}
            </Btn>
            <Btn
                color="black"
                className="btn btn-light btn bg-gray"
                onClick={handleInviteToogle}
            >
                Invite Contacts
            </Btn>
        </div>
            <Modal fade isOpen={categoryModal} toggle={categoryToggle}>
                <ModalHeader toggle={categoryToggle}>{AddLabels}</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{ usename: "",contacts:"",message:'' }}
                        onSubmit={handleSubmit}
                        validationSchema={manageAlbumFormValidationSchema}
                    >
                        {({ errors, values, setFieldValue }) => (
                            <Form className="form-bookmark">
                                <Row className="g-2">
                                    <Col md="12">
                                        <FormGroup>
                                            {/* <Input type="text" required placeholder={EnterKeywordName} autoComplete="off" /> */}
                                            <Label check className="mb-1">
                                                New Label:
                                            </Label>
                                            <TagsInput name="keywords" placeholder="New Label" setFieldValue={setFieldValue}/>
                                            </FormGroup>
                                            <FormGroup>
                                            <Label check className="mb-1">
                                            Contacts (optional):
                                            </Label>
                                                 <Field
                                                    className={`custom-select  `}
                                                    name="contacts"
                                                    options={
                                                        Options
                                                    }
                                                    component={
                                                        CustomSelect
                                                    }
                                                    placeholder="Contacts"
                                                    isMulti={true}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                            <Label check className="mb-1">
                                            Message Subjects (optional):
                                            </Label>
                                                 <Field
                                                    className={`custom-select  `}
                                                    name="message"
                                                    options={
                                                        Options
                                                    }
                                                    component={
                                                        CustomSelect
                                                    }
                                                    placeholder="Message Subjects"
                                                    isMulti={true}
                                                />
                                            </FormGroup>
                                             
                                         
                                    </Col>
                                </Row>
                                <Btn
                                    color="secondary"
                                    className="me-2"
                                    onClick={categoryToggle}
                                >
                                    {Save}
                                </Btn>
                                <Btn color="primary" onClick={categoryToggle}>
                                    {Cancel}
                                </Btn>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            <Modal fade isOpen={ismodal} toggle={handleInviteToogle}>
                <ModalHeader toggle={categoryToggle}> Invite Contacts </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{ newContacts : "",Label :"" }}
                        onSubmit={handleSubmit}
                        validationSchema={manageAlbumFormValidationSchema}
                    >
                        {({ errors, values, setFieldValue }) => (
                            <Form className="form-bookmark">
                                <Row className="g-2">
                                    <Col md="12">
                                        <FormGroup>
                                            {/* <Input type="text" required placeholder={EnterKeywordName} autoComplete="off" /> */}
                                            <Label check className="mb-1">
                                            New Contacts :
                                            </Label>
                                            <TagsInput name="newContacts" placeholder="New Contacts " setFieldValue={setFieldValue}/>
                                            </FormGroup>
                                            <FormGroup>
                                            <Label check className="mb-1">
                                            Label (optional):
                                            </Label>
                                                 <Field
                                                    className={`custom-select  `}
                                                    name="Label"
                                                    options={
                                                        Options
                                                    }
                                                    component={
                                                        CustomSelect
                                                    }
                                                    placeholder="Label"
                                                    isMulti={true}
                                                />
                                            </FormGroup>
                                             
                                    </Col>
                                </Row>
                                <Btn
                                    color="secondary"
                                    className="me-2"
                                    onClick={categoryToggle}
                                >
                                    {Save}
                                </Btn>
                                <Btn color="primary" onClick={categoryToggle}>
                                    {Cancel}
                                </Btn>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default CategoryCreate;
