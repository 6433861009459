import {useState} from 'react'
import { BrandTools, ManageExternalTitle, SpecialParameter } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { ActionButtons, SpecialParameterColumnData, SpecialParameterTableAction, StudyMaterialAction, links, supportColumnData, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import Popup from '../../../../Component/MasterPopup/Popup'
import SpecialParameterEdit from '../../../../Component/Tools/BrandSupportTools/SpecialParameters/SpecialParameterEdit'
import SpecialParameterView from '../../../../Component/Tools/BrandSupportTools/SpecialParameters/SpecialParameterView'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import StudyMaterialSubMenu from '../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import { Btn } from '../../../../AbstractElements' 
import { useAppSelector } from '../../../../ReduxToolkit/Hooks'
import AddGenralPerameters from '../CourseandTeacherInformation/AddGenralPerameters'
import AddQuestionGroupModal from '../../../../Component/Tools/BrandSupportTools/Questions/AddQuestionGroupModal'
import { Formik } from 'formik'
import { surveyAndQuizzesFormInitialValue, TabsurveyAndQuizzesFormValidationSchema } from '../../../../Data/Tools/BrandSupportTools/FormControlsValidation'
import AddSurveyAndQuizzesForm from '../../../../Component/Tools/BrandSupportTools/SurveyAndQuizzes/AddSurveyAndQuizzesForm'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'

const Settings = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const [isEditOpen , setIsEditOpen] = useState(false);
    const [isOpen , setIsOpen] = useState(false);

    const handleRowSelected =() =>{}

    const openPopup = (type:boolean) =>{
        setIsOpen(!isOpen);
        setIsEditOpen(type);
    }

    const openModalToggle = () =>{
        setIsOpen(!isOpen);
    }

    const SpecialParameterColumnData: TableColumn<SupportDataType>[] = [
      {
        name: <TableHeadColumn title={`ID`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: false,
      },
      {
        name: <TableHeadColumn title={`By`}/>,
        selector: (row) => row["by"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Title`}/>,
        selector: (row) => row["position"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Country`}/>,
        selector: (row) => row["salary"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Keywords`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`State`}/>,
        selector: (row) => row["position"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Course`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Book`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Exam`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name:<TableHeadColumn title={`Actions`}/>,
        cell: (row) => <ActionButtons openPopup={openPopup}/>,
        center: false,
        right:true,
      },
      
    ];
    const [openGeneralModal, setOpenGeneralModal] = useState(false);
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const openGeneralModalToggle = () => setOpenGeneralModal(!openGeneralModal);
    const handleSubmit = (formValues: any) => {
      console.log(formValues);
      // You can also handle other submit actions here, like validation or API calls
  };
    return (
      <div className='page-body'>
        <Breadcrumbs mainParent="All Tools" mainTitle={`Questionnaire Settings (${BrandTools})`} parent={BrandTools} />
        <SubMenuPopup  currentPage="Questionnaire Settings List "/>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card> 
              <div className='mt-2 ms-1'> 
                    <StudyMaterialSubMenu />
                  </div>
                  
                <CardBody>
                  <> 
                  {/* <Popup
                title={'Add Questionnaire Settings'}
                openModalToggle={openGeneralModalToggle}
                isOpen={openGeneralModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                 BodyClassName="pt-1"
                largesize="modal-custom"> 
                    <div className="mx-2 mt-2 mb-3 px-2">
                        <AddQuestionGroupModal /> 
                    </div>
                    </Popup> */}
                    <Popup
                title={'Add Questionnaire Settings'}
                openModalToggle={openGeneralModalToggle}
                isOpen={openGeneralModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                 BodyClassName="pt-1"
                largesize="modal-custom"> 
                    <div className="mx-2 mt-2 mb-3 px-2">
                  
                        <>
                            <Formik
                                initialValues={surveyAndQuizzesFormInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={
                                    TabsurveyAndQuizzesFormValidationSchema
                                }>
                                {({
                                    errors,
                                    values,
                                    setFieldValue,
                                    handleSubmit,
                                }) => (
                                    <AddSurveyAndQuizzesForm
                                        submitErrors={submitErrors}
                                        setSubmitError={setSubmitError}
                                        errors={errors}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        addquizsurveysTab={false}
                                        testquizsetting={false}
                                        // setFormType={setFormType}
                                        // setCategory={setCategory}
                                    />
                                )}
                            </Formik>
                        </>
                     
               
               </div>
            </Popup>
                  {/* <Row><Col md="2" className='d-flex'> */}

                  <div className='border p-2'>
                  <div>
                  {SpecialParameterTableAction.map((action, index)=>{
                    return(
                    <> 
                      <Link to={`${process.env.PUBLIC_URL}/${action.path}`}><span key={index} className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                    </>)
                  })}
                  </div>

                  {/* </Col></Row> */}
                  <div className="table-responsive pt-2">
                    <DataTable className='custom-scrollbar' columns={SpecialParameterColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                  </div>

                  <Popup title={`General Parameters`} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`}>
                    {isEditOpen ? <SpecialParameterEdit/> : <SpecialParameterView /> }
                  </Popup>
                  </div>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default Settings