import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { FormGroup, Input, Label, Button } from 'reactstrap';
import * as Yup from 'yup';
import { H4, LI, P, UL } from '../../../AbstractElements';

const SocialContentValidation = Yup.object().shape({
    licenseType: Yup.string().required('Please select a license type'),
    email: Yup.string().email('Invalid email').required('Email is required'),
     
    contentUsageFee: Yup.string().when('licenseType', {
        is: false,
        then: () =>
            Yup.string().required('Content usage fee is required').min(0, 'Fee must be a positive number'),
      }),
      otherwise: Yup.string().notRequired(),

});

const LicenseForm = (props) => {
    const [isPremiumSelected, setIsPremiumSelected] = useState(false);

    const handleSelectChange = (event, handleChange) => {
        const selectedValue = event.target.value;
        setIsPremiumSelected(selectedValue === 'Premium Content, paid usage');
        handleChange(event); // Make sure Formik handles the change as well
    };

    const handleSubmit = (values) => {
        console.log('Form Data:', values);
    };

    return (
        <Formik
            initialValues={{  licenseType: '', email: '', additionalInfo: '', contentUsageFee: '' }}
            validationSchema={SocialContentValidation}
            onSubmit={handleSubmit}
        >
            {({ errors, handleChange, touched }) => (
                <Form>
                    {/* License Type Selection */}
                    <FormGroup floating>
                        <Field
                            name="licenseType"
                            as="select"
                            className="form-control"
                            onChange={(e) => handleSelectChange(e, handleChange)}
                        >
                            <option value="">Select License Type</option>
                            <option value="Free for Commercial and Non-profit use">Free for Commercial and Non-profit use</option>
                            <option value="Free for Non-Profit use only">Free for Non-Profit use only</option>
                            {props.add ? <><option value="Premium Content, paid usage">Premium Content, paid usage</option>  <option value="View Only. No right to downloador use">View Only. No right to downloador use</option></> : '' }
                            {props.add ? '': <option value="Premium Content, paid usage">Premium Content, paid usage</option> }
                        </Field>
                        <Label>License and Right to Use Contents</Label>
                        {errors.licenseType && touched.licenseType && (
                            <div className="text-danger">{errors.licenseType}</div>
                        )}
                    </FormGroup>

                    

                    {/* Additional Info Field (Conditional) */}
                    {isPremiumSelected && (
                        <FormGroup floating>
                        <Field
                            name="contentUsageFee"
                            type="email"
                            className="form-control"
                            placeholder="Enter content usage fee"
                        />
                        <Label>Content Usage Fee($)</Label>
                        {errors.contentUsageFee && touched.contentUsageFee && (
                            <div className="text-danger">{errors.contentUsageFee}</div>
                        )}
                    <P className='fw-bold '>*For Each Sale, You Will receive 
                        (95%)
                    </P>
                    <div className='p-3 bg-primary rounded-3'>
                        <H4 className='text-white'>
                            Content Usage Fee Formats:
                        </H4>
                        <UL >
                        <LI className='text-white'>
                            Fee for small Size : 0$
                        </LI>
                        <LI className='text-white'>
                            Fee for Medium Size : 0$
                        </LI>
                        <LI className='text-white'>
                            Fee for large Size : 0$
                        </LI>
                        <LI className='text-white'>
                            Fee for Vector Graphics : 0$ (if included)
                        </LI>
                        <LI className='text-white'>
                            Fee Minimum : 1$ - Maximum :5$
                        </LI> 
                        </UL>
                    </div>
                    </FormGroup>
                    )}

               
                </Form>
            )}
        </Formik>
    );
};

export default LicenseForm;
