import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../ReduxToolkit/Hooks';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { MenuList } from '../../../Data/LayoutData/SidebarData';
import { closeModal, openModal, setActiveItem } from '../../../ReduxToolkit/Reducers/Navigation/Navigation';

export const communication_menu = [
    { title: "Start C-Chat", path: 'start-classified-chat', icon: <FontAwesomeIcon icon={fas.faCommentMedical} className='pe-2' /> },
    { title: "Inbox", path: 'message-threads-inbox', icon: <FontAwesomeIcon icon={fas.faEnvelope} className='pe-2' /> },
    { title: "Private C-Chats", path: 'private-classified-chat', icon: <FontAwesomeIcon icon={fas.faCommentDots} className='pe-2' /> },
    { title: "Activity and Event C-Chats", path: 'activities-and-events-classified-chat', icon: <FontAwesomeIcon icon={fas.faCommentDots} className='pe-2' /> },
    { title: "Application: For Work and Study", path: 'applications-classified-chat', icon: <FontAwesomeIcon icon={fas.faUsersRectangle} className='pe-2' /> },
    { title: "Marketplace C-Chat", path: 'marketplace-classified-chat', icon: <FontAwesomeIcon icon={fas.faCommentsDollar} className='pe-2' /> },
    { title: "Project C-Chat", path: 'projects-classified-chat', icon: <FontAwesomeIcon icon={fas.faUsersRectangle} className='pe-2' /> },
    { title: "Contacts", path: 'contacts', icon: <FontAwesomeIcon icon={fas.faUser} className='pe-2' /> },
    { title: "Notification Screen", path: 'notification-screen-on-my-media-center', icon: <FontAwesomeIcon icon={fas.faUser} className='pe-2' /> },
];

const CommunicationSubMenu = () => {
    const { mobileView } = useAppSelector((state) => state.layout);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation(); // Get the current location
 const dispatch = useAppDispatch();
    const menuPopupOpen =()=>{
      const data = MenuList[0]?.Items?.find((item) => item.title === "Communication");
      
      dispatch(setActiveItem(data))
      dispatch(openModal())
    }
    const menuPopupClose =()=>{
      dispatch(closeModal())
    }
    return (
        
                    <div>
                         
                                                      {isOpen ? (
                                                              <IoClose size='2em' onClick={() =>menuPopupClose()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                                                          ) : (
                                                              <HiOutlineMenuAlt2 size='2em' onClick={() => menuPopupOpen()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                                                          )}
                                                      
                    </div>
                 
    );
}

export default CommunicationSubMenu;
