import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import PostsubMenu from "../../../../Component/App/PostSubMenu/PostsubMenu";
import ArticlesBlogsContainer from "../../../../Component/Application/ArticlesBlogs/ArticlesBlogsContainer";
import KnowledgeContainer from "../../../../Component/Application/Knowledge/KnowledgeContainer";
import MarketplaceContainer from "../../../../Component/Application/Marketplace/Marketplace";
import { Parent1Web } from "../../../../utils/Constant";

const ArticlesBlogs = () => {
  return (
    <div className="page-body"> 
      <Breadcrumbs mainTitle={'Articles and Blogs'} parent={Parent1Web} />
      <PostsubMenu/> 
      <ArticlesBlogsContainer /> 
    </div>
  );
};

export default ArticlesBlogs;
