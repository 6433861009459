import { useState } from "react";
import { Row, Col, Label, Container, FormGroup, Button, Table, Input } from "reactstrap"; 
import { ErrorMessage, Field, Form } from "formik"; 
import Branches from "../../../../../CommonInput/Branches";
import City from "../../../../../CommonInput/City";
import State from "../../../../../CommonInput/State";
import Country from "../../../../../CommonInput/Country";
import { SchedulesFormValidationProp } from "../../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { H3, H4, H6 } from "../../../../../../AbstractElements";
import CategoryDataDrop from "../../../../../CommonInput/CategoryDataDrop";
import CategoryTable from "./CategoryTable";
import Sharing from "../../../../../Tools/BrandSupportTools/SurveyAndQuizzes/Sharing";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
// Sample Data for Price Setup Table
const priceData = [
  { id: 1, type: "Retail", price: "$10.00" },
  { id: 2, type: "Wholesale", price: "$8.00" },
  { id: 3, type: "Bulk", price: "$6.00" },
];

// Table Columns
const columns = [
  { name: "Type", selector: (row) => row.type, sortable: true },
  { name: "Price", selector: (row) => row.price, sortable: true },
];

// Selected Items (Mock Data)
const selectedItems = ["X1", "W5"];
function BranchSetupPrice(props: any) {
  const { errors, submitErrors,setFieldValue, setSubmitError,values,validateField,handleSubmit   } = props;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([false, false]);
  const [autoSchedule, setAutoSchedule] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const onChangeHandler = () => {
    setAutoSchedule((oldFlag) => !oldFlag);
  }; 
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(selectedRows.map(() => newSelectAll));
  };
  const [openSections, setOpenSections] = useState<string[]>([]);

  // Toggle Collapse
  const toggleSection = (item: string) => {
    setOpenSections((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };
  const handleRowSelect = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);

    // If any row is unselected, uncheck the header checkbox
    if (!newSelectedRows.includes(false)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  }; 

  const renderTableRows = () => {
    if (!selectedCategory) return null;
    return data[selectedCategory].map((item, index) => (
      <tr key={index}>
        <th scope="row">
          <Input type="checkbox" />
        </th>
        <td>{item.addon}</td>
        <td>{item.name}</td>
        <td>{item.quantity}</td>
        <td>{item.price}</td>
      </tr>
    ));
  };
  const CustomDatePicker = ({ field, form, minDate, maxDate, ...props }) => {
    const selectedDate = field.value ? new Date(field.value) : null;
  
    return (
      <DatePicker
        selected={selectedDate}
        onChange={(date) => form.setFieldValue(field.name, date)}
        dateFormat="dd/MM"
        showYearDropdown={false}
        showMonthDropdown
        dropdownMode="select"
        minDate={minDate} // Ensure "To" date is not before "From" date
        maxDate={maxDate} // Optional: Prevent selecting beyond a certain limit
        {...props}
      />
    );
  };
  const fromDate = values.dateRange?.from ? new Date(values.dateRange.from) : null;
  return (
    <>
      
        <Row>
        <Col sm="4" className="mb-3 mt-1">
          <Label >Base Price : </Label>
          <Field name="price">
              {({ field }) => (
                <Input
                  {...field}
                  type="text"
                  id="price"
                  value={values.amount}
                  onChange={(e) => {
                    let value = e.target.value;

                    // Allow only numbers and a single decimal point
                    if (!/^\d*\.?\d*$/.test(value)) return;

                    setFieldValue("amount", value);
                  }}
                  onBlur={() => {
                    // Convert to 2 decimal places when user leaves input
                    setFieldValue("amount", parseFloat(values.amount || "0").toFixed(2));
                  }}
                  placeholder="0.00"
                />
              )}
            </Field>
          </Col>
          <Col sm='12'>
          <h4 className="my-2 fw-medium">Price for SPECIFIC DAYS  (optional):</h4>
      
          <Row> 
          
          {values.days && Object.keys(values.days).map((day) => (
  <Col lg={4} key={day} className="mb-4">
    <label className="form-label">{day}:</label>
    <div className="d-flex align-items-center">
      <FormGroup
        className={`form-check mx-2 form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
          values?.days[day]?.action === 'Regular' ? 'transform-scale' : ''
        }`}
      >
        <Label
          className="form-check-label me-3"
          for={`Days-${day}`}
          onClick={() => setFieldValue(`days.${day}.action`, 'Regular')}
        >
          <Field
            id={`Days-${day}`}
            type="checkbox"
            name={`days.${day}.action`}
            value="Regular"
            className="form-check-input me-2"
            checked={values.days[day]?.action === 'Regular'}
            onChange={() => setFieldValue(`days.${day}.action`, 'Regular')}
          />
          <H6 className="mx-2">Regular Price</H6>
        </Label>
      </FormGroup>

      <FormGroup
        className={`form-check mx-2 form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
          values?.days[day]?.action === 'Increase' ? 'transform-scale ' : ''
        }`} style={{marginBottom:'10px !important'}}
      >
        <Label
          className="form-check-label me-3"
          for={`Days2-${day}`}
          onClick={() => setFieldValue(`days.${day}.action`, 'Increase')}
        >
          <Field
            id={`Days2-${day}`}
            type="checkbox"
            name={`days.${day}.action`}
            value="Increase"
            className="form-check-input me-2"
            checked={values.days[day]?.action === 'Increase'}
            onChange={() => setFieldValue(`days.${day}.action`, 'Increase')}
          />
          <H6 className="mx-2">Increase Price</H6>
        </Label>
      </FormGroup>
    </div>

    {values.days[day]?.action === 'Increase' && (
      <Field name={`days.${day}.priceDifference`}>
        {({ field, form }) => (
          <>
          <input
            {...field}
            type="text"
            placeholder="Extra Price Difference"
            className={`form-control ${errors?.days?.[day]?.priceDifference ? "is-invalid" : ""}`}
            value={
              field.value !== undefined && field.value !== ''
                ? `${field.value}`
                : ''
            }
            onChange={(e) => {
              let value = e.target.value.replace('%', ''); // Remove '%' symbol
              // Allow only valid numbers and decimals
              if (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value)) {
                form.setFieldValue(field.name, value); // Store numeric value
              }
            }}
            onBlur={() => {
              // Convert value to 2 decimal places when user leaves input
              if (field.value) {
                form.setFieldValue(field.name, parseFloat(field.value).toFixed(2));
              }
            }}
          />
          <ErrorMessage
          name={`days.${day}.priceDifference`} // Correct dynamic field name
          component="div"
          className="invalid-feedback"
        />
        </>
        )}
      </Field>
    )}
  </Col>
))}

          </Row>
          
 <h3 className="mb-4"> Price for SPECIFIC DATES or SEASONS (optional)</h3>
      <Row>
        <Col sm='4'>
            
          <div className="mb-3">
            <div >
              <Label htmlFor="dateRange.from" className="form-label d-block">
                From:
              </Label>
              <Field
            name="dateRange.from"
            component={CustomDatePicker}
            className="form-control"
          />
            </div>
            
          </div>
        </Col>
        <Col sm='4'>
          <div >
            <Label htmlFor="dateRange.to" className="form-label d-block">
              To:
            </Label>
            <Field
            name="dateRange.to"
            component={CustomDatePicker}
            className="form-control"
            minDate={fromDate} // Ensure "To" cannot be before "From"
            maxDate={fromDate ? new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0) : null} // Limit to the same month
          />
          </div>
        </Col> 
        <Col sm='4'>
      <div className="mb-3 ">
        <label className="form-label">Price:</label>
        <div className="d-flex align-items-center">
        <FormGroup
        className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 ps-3  me-2 ${
          values.dateRange?.action === 'Regular' ? 'transform-scale' : ''
        }`}
      >
        <Label
          className="form-check-label "
          for="hoursDay1"
          onClick={() => setFieldValue('dateRange.action', 'Regular')}
        >
          <Field
            id="hoursDay1"
            type="checkbox"
            name="dateRange.action"
            value="Regular"
            className="form-check-input me-2"
            checked={values.dateRange?.action === 'Regular'}
            onChange={() => setFieldValue('dateRange.action', 'Regular')}
          />
          <H6 className="mx-3">Regular Price</H6>
          
        </Label>
      </FormGroup>

      {/* Increase Price */}
      <FormGroup
        className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-3 ${
          values.dateRange?.action === 'Increase' ? 'transform-scale' : ''
        }`}
      >
        <Label
          className="form-check-label me-3"
          for="hoursDay2"
          onClick={() => setFieldValue('dateRange.action', 'Increase')}
        >
          <Field
            id="hoursDay2"
            type="checkbox"
            name="dateRange.action"
            value="Increase"
            className="form-check-input me-2"
            checked={values.dateRange?.action === 'Increase'}
            onChange={() => setFieldValue('dateRange.action', 'Increase')}
          />
          Increase Price
        </Label>
      </FormGroup>
        </div>
      </div>
       {/* Price Difference */} 
       {values.dateRange?.action === "Increase" && (
  <div className="mb-3">
    <label htmlFor="dateRange.priceDifference" className="form-label">
      Extra Price Difference (%):
    </label>
    <Field name="dateRange.priceDifference">
      {({ field, form }) => (
        <input
          {...field}
          type="text"
          className={`form-control ${
            errors?.dateRange?.priceDifference ? "is-invalid"
              : ""
          }`}
          placeholder="Extra price difference (%)"
          value={
            field.value !== undefined && field.value !== ""
              ? `${field.value}%`
              : ""
          }
          onChange={(e) => {
            let value = e.target.value.replace('%', ''); // Remove % symbol
            if (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value)) {
              form.setFieldValue(field.name, value); // Update numeric value in state
            }
          }}
        />
      )}
    </Field>
    {/* {errors?.dateRange?.priceDifference   (
        <div className="invalid-feedback">
          {errors?.dateRange?.priceDifference}
        </div>
      )} */}
  </div>
)}
        </Col>
      </Row>

      

      

     
 <h3 className="mb-2">Follower's Discount Membership (recommended)</h3>
 <p className="mb-0">(Offer your followers special discounts to be more loyal to your business.)</p>
  
     
      {/* Radio Buttons for Action */}
      <div className="mb-3 "> 
        <div className="d-flex align-items-center"> 
         
          {/* <label className="form-check-label">Increase Price</label> */}
        </div>
      </div>

      {/* Price Difference */} 
      {/* {values.dateRange?.action === "Increase" && ( */}
      <Row>  
             <Col lg={4} className="mb-3">
              <label htmlFor="silverDiscount">Silver Discount (%)</label>
              <Field
                name="silverDiscount"
                type="text"
                className={`form-control ${
                  errors?.silverDiscount 
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Enter Silver Discount (%)"
                value={
                  values?.silverDiscount !== ""
                    ? `${values?.silverDiscount}%`
                    : ""
                }
                onChange={(e) => {
                  let value = e.target.value.replace("%", "");
                  if (/^\d*\.?\d*$/.test(value)) {
                    setFieldValue("silverDiscount", value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
              
                    // Trigger validation manually
                    validateField("silverDiscount");
                                      validateField("goldDiscount");
                                      validateField("diamondDiscount");
                                      validateField("supersaverDiscount");
                  }
                }}
              />
              <ErrorMessage
                name="silverDiscount"
                component="div"
                className="invalid-feedback"
              />
            </Col>

            <Col lg={4} className="mb-3">
              <label htmlFor="goldDiscount">Gold Discount (%)</label>
              <Field
                name="goldDiscount"
                type="text"
                className={`form-control ${
                  errors?.goldDiscount 
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Enter Gold Discount (%)"
                value={
                  values?.goldDiscount !== "" ? `${values?.goldDiscount}%` : ""
                }
                onChange={(e) => {
                  let value = e.target.value.replace("%", "");
                  if (/^\d*\.?\d*$/.test(value)) {
                    setFieldValue("goldDiscount", value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default Enter behavior
                    validateField("goldDiscount"); // Validate the field
                    
                  }
                }}
              />
              <ErrorMessage
                name="goldDiscount"
                component="div"
                className="invalid-feedback"
              />
            </Col>

            <Col lg={4} className="mb-3">
              <label htmlFor="diamondDiscount">Diamond Discount (%)</label>
              <Field
                name="diamondDiscount"
                type="text"
                className={`form-control ${
                  errors?.diamondDiscount  
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Enter Diamond Discount (%)"
                value={
                  values?.diamondDiscount !== ""
                    ? `${values?.diamondDiscount}%`
                    : ""
                }
                onChange={(e) => {
                  let value = e.target.value.replace("%", "");
                  if (/^\d*\.?\d*$/.test(value)) {
                    setFieldValue("diamondDiscount", value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default Enter behavior
                    validateField("diamondDiscount"); // Validate the field
                    
                  }
                }}
              />
              <ErrorMessage
                name="diamondDiscount"
                component="div"
                className="invalid-feedback"
              />
            </Col>
            <Col lg={4} className="mb-3">
                          <label htmlFor="supersaverDiscount">
                              Super Saver (%)
                          </label>
                          <Field
                              name="supersaverDiscount"
                              type="text"
                              className={`form-control ${
                                  errors?.supersaverDiscount ? "is-invalid" : ""
                              }`}
                              placeholder="Enter Super Saver Discount (%)"
                              value={
                                  values?.supersaverDiscount !== ""
                                      ? `${values?.supersaverDiscount}%`
                                      : ""
                              }
                              onChange={(e) => {
                                  let value = e.target.value.replace("%", "");
                                  if (/^\d*\.?\d*$/.test(value)) {
                                      setFieldValue(
                                          "supersaverDiscount",
                                          value
                                      );
                                  }
                              }}
                              onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                      e.preventDefault(); // Prevent default Enter behavior
                                      validateField("supersaverDiscount"); // Validate the field
                                  }
                              }}
                          />
                          <ErrorMessage
                              name="supersaverDiscount"
                              component="div"
                              className="invalid-feedback"
                          />
                      </Col>
      </Row>
{/* )} */} 
        </Col> 
      </Row>   
     
        <Row>
        <Col sm="8" className="mb-3">
          <H3 className="text-gray">Addons</H3>
        </Col> 
      </Row> 
      <CategoryTable /> 
      {/* <Sharing  nomediacenter={true} /> this is used in future Dont delete */}
     
    </>
   
 
  );
}

export default BranchSetupPrice;
