import PoliciesandServiceTermsAdd from "../../Tools/BrandSupportTools/PoliciesandServiceTerms/PoliciesandServiceTermsAdd"

 

const PoliciesandServiceTermsCreate = () => {
  return (
    <div className='page-body py-2'>
      <PoliciesandServiceTermsAdd />
    </div>
  )
}

export default PoliciesandServiceTermsCreate