export const styleData = [
  {
    text: "Use the different styles of borders like:",
    code: "border radius/border-color/border-width",
  },
  { text: ". Use of any components." },
];

export const borderClass = [
  "b-r-1",
  "b-r-2",
  "b-r-3",
  "b-r-4",
  "b-r-5",
  "b-r-6",
  "b-r-7",
  "b-r-8",
  "b-r-9",
  "b-r-10",
];

export const borderColorCartData = [
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "dark",
];

export const borderWidthData = ["2", "3", "4", "5", "6", "7", "8", "9", "10"];

export const textColorData = [
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "dark",
  "light",
];

export const borderData = [
  {
    text: "Use border utilities to add or remove an element's borders.",
  },
];

export const additiveData = ["top", "bottom", "start", "end"];

export const subtractiveData = ["top-0", "end-0", "bottom-0", "start-0"];

export const radiusData = [
  "top",
  "end",
  "bottom",
  "start",
  "pill",
  "circle",
  "0",
];

export const backgroundData = [
  {
    text: "Use the ",
    code: ".bg-* ",
  },
  {
    text: "and ",
    code: ".alert-light-*",
  },
  {
    text: "colors in Crocs theme.Use of any components.",
  },
];

export const darkBackgroundData = [
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "dark",
  "light",
];

export const lightBackgroundData = [
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "dark",
  "light",
];

export const borderColorDetail = [
  {
    text: " Use the ",
    code: ".b-*/.b-t-*/.b-b-*/.b-l-*/.b-r-*",
  },
  {
    text: "class for borders.",
  },
];

export const borderColorData = [
  "b-t-primary",
  "b-b-primary",
  "b-l-primary",
  "b-r-primary",
  "b-secondary",
  "b-t-secondary",
  "b-b-secondary",
  ".b-l-secondary",
  "b-r-secondary",
  "b-success",
  ".b-t-success",
  ".b-b-success",
  "b-l-success",
  "b-r-success",
  "b-danger",
  "b-t-danger",
  "b-b-danger",
  "b-l-danger",
  "b-r-danger",
  "b-danger",
  "b-t-danger",
  ".b-b-danger",
  "b-l-danger",
  "b-r-danger",
  "b-warning",
  "b-t-warning",
  "b-b-warning",
  "b-l-warning",
  "b-r-warning",
  ".b-info",
  "b-t-info",
  ".b-b-info",
  "b-l-info",
  "b-r-info",
  ".b-dark",
  "b-t-dark",
  ".b-b-dark",
  "b-l-dark",
  "b-r-dark",
  ".b-light",
  "b-t-light",
  ".b-b-light",
  "b-l-light",
  "b-r-light",
];

export const imageData = [
  {
    text: "Use the width and height of images class like: ",
    code: ".img-*",
  },
  {
    text: "and",
    code: ".img-h-*",
  },
];

export const imagesDetails = [40, 50, 60, 70, 80, 90, 100];

export const fontData = [
  {
    text: "Use the font-style like: ",
    code: " .f-s-* [normal/italic/oblique/initial/inherit]",
  },
];
export const fontStyleDetail = [
  "f-s-italic",
  "f-s-oblique",
  "f-s-initial",
  "f-s-inherit",
];

export const fontWeightData = [
  {
    text: "Use the font-weight classes like: ",
    code: ".f-w-* [100/300/400/600/700/900]",
  },
];

export const fontWeightDetail = ["300", "400", "600", "700", "900"];

export const paddingCartData = [
  {
    text: "Use the padding classes like: ",
    code: ".p-* [10/15/20/25/30/35/40/45/50]",
  },
];

export const paddingCartDetail = [15, 20, 25, 30, 35, 40, 45, 50];

export const sidePaddingData = [
  {
    text: "Use the padding classes like: ",
    code: ".p-l-*/.p-r-*/.p-t-*/.p-b-*",
  },
];

export const sidePaddings = [
  ".p-l-10",
  ".p-l-15",
  ".p-l-20",
  ".p-l-25",
  ".p-l-30",
  ".p-l-35",
  ".p-l-40",
  ".p-l-45",
  ".p-l-50",
];

export const sidePaddingDetails = [
  {
    title: "Padding Right",
    direction: [
      ".p-r-10",
      ".p-r-15",
      ".p-r-20",
      ".p-r-25",
      ".p-r-30",
      ".p-r-35",
      ".p-r-40",
      ".p-r-45",
      ".p-r-50",
    ],
  },
  {
    title: "Padding Top",
    direction: [
      ".p-t-10",
      ".p-t-15",
      ".p-t-20",
      ".p-t-25",
      ".p-t-30",
      ".p-t-35",
      ".p-t-40",
      ".p-t-45",
      ".p-t-50",
    ],
  },
  {
    title: "Padding Bottom",
    direction: [
      ".p-b-10",
      ".p-b-15",
      ".p-b-20",
      ".p-b-25",
      ".p-b-30",
      ".p-b-35",
      ".p-b-40",
      ".p-b-45",
      ".p-b-50",
    ],
  },
];

export const marginData = [
  {
    text: "Use the padding classes like: ",
    code: "m-* [10/15/20/25/30/35/40/45/50]",
  },
];

export const marginDetail = [15, 20, 25, 30, 35, 40, 45, 50];

export const sideMarginData = [
  {
    text: "Use the padding classes like: ",
    code: ".m-l-*/.m-r-*/.m-t-*/.m-b-*",
  },
];

export const sideMargins = [
  ".m-l-10",
  ".m-l-15",
  ".m-l-20",
  ".m-l-25",
  ".m-l-30",
  ".m-l-35",
  ".m-l-40",
  ".m-l-45",
  ".m-l-50",
];

export const sideMarginDetails = [
  {
    title: "Margin Right",
    direction: [
      ".m-r-10",
      ".m-r-15",
      ".m-r-20",
      ".m-r-25",
      ".m-r-30",
      ".m-r-35",
      ".m-r-40",
      ".m-r-45",
      ".m-r-50",
    ],
  },
  {
    title: "Margin Top",
    direction: [
      ".m-t-10",
      ".m-t-15",
      ".m-t-20",
      ".m-t-25",
      ".m-t-30",
      ".m-t-35",
      ".m-t-40",
      ".m-t-45",
      ".m-t-50",
    ],
  },
  {
    title: "Margin Bottom",
    direction: [
      ".m-b-10",
      ".m-b-15",
      ".m-b-20",
      ".m-b-25",
      ".m-b-30",
      ".m-b-35",
      ".m-b-40",
      ".m-b-45",
      ".m-b-50",
    ],
  },
];

export const fontSize = [
  {
    text: "Use the font-size for ",
    code: ".f-* [14/16/18/20/22/24/26/28/30/32/34/36/38/40]",
  },
];

export const fontSideData = [
  "f-14",
  "f-16",
  "f-18",
  "f-20",
  "f-22",
  "f-24",
  "f-26",
  "f-28",
  "f-30",
  "f-32",
  "f-34",
  "f-36",
  "f-38",
  "f-40",
];

export const textColorDetail = [
  { text: "Use the text color for ", code: ".font-*" },
  { text: " class." },
];

export const textDataColors = [
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "dark",
];

export const getOneHoursTimes = () => {
  var now = new Date();
  var time = now.getTime();
  time += 3600 * 1000;
  now.setTime(time);
  now.toUTCString();
  return now;
};

export const getOneYearTimes = () => {
  var now = new Date();
  var time = now.getTime();
  time += 3600 * 1000 * 24 * 365 * 1;
  now.setTime(time);
  now.toUTCString();
  return now;
}

export const setCookie = (cname: any, cvalue: any) => {
  let expires = "expires=" + getOneYearTimes();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const deleteCookie = () => {
  var allCookies = document.cookie.split(";");
  // The "expire" attribute of every cookie is
  // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  for (var i = 0; i < allCookies.length; i++)
    document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString(); 
};

export const  getCookie = (cname: any) => {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}