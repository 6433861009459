import {useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link, useLocation } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { BrandTools, ManageQuizSurveySystem } from '../../../../utils/Constant'
import { ActionButtons, QuizzSurveyTableAction, StudyMaterialAction, links, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import Popup from '../../../../Component/MasterPopup/Popup'
import SurveyQuizEdit from '../../../../Component/Tools/BrandSupportTools/SurveyAndQuizzes/SurveyQuizEdit'
import SurveyQuizView from '../../../../Component/Tools/BrandSupportTools/SurveyAndQuizzes/SurveyQuizView'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import StudyMaterialSubMenu from '../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import { Btn, H3, SVG } from '../../../../AbstractElements'
import CommonModal from '../../../../Component/Ui-Kits/Modal/Common/CommonModal'
import { X } from 'react-feather'
import QuizTest, { questions } from '../../../../Component/QuizzAnimation/QuizzTest'
import ImportBulk from '../ImportBulk/ImportBulk'
import { Formik } from 'formik'
import { FequentlyinitialValues, FequentlyValidationSchema, validationSchemaTransition } from '../../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import FequentlyModalForm from '../FequentlyQuestion/FequentlyModalForm'
import ImportTransitionForm from '../../ImportTransition/ImportTransitionForm'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'



const SurveyQuizes = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const [isEditOpen , setIsEditOpen] = useState(false);
    const [isOpen , setIsOpen] = useState(false);
     const [isModal , setIsModal] = useState(false);
     const [selectedRow, setSelectedRow] = useState<SupportDataType | null>(null);
     const [selectedAnswers, setSelectedAnswers] = useState({});
     const [isresultOpen, setIsresultOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<any>(null); 
  const [submitErrors, setSubmitError] = useState<boolean>(false); 
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const openPublishModalToggle = () => setOpenPublishModal(!openPublishModal);
    const handleRowSelected =() =>{}

    const openPopup = (type:boolean) =>{
      setIsOpen(!isOpen);
      setIsEditOpen(type);
    }

    const openModalToggle = () =>{
        setIsOpen(!isOpen);
    }
     // Function to handle click
  const handleParticipantClick = (participant: any) => {
    setSelectedParticipant(participant);
    setIsresultOpen(true);
  };

  // Close modal
  const openModalToggleresultModal = () => {
    setIsresultOpen(!isresultOpen);
    setSelectedParticipant(null);
  };
  const openModalToggleColumnClick = () =>{
    setIsModal(!isModal);
  }
    const handleColumnClick = (failedValue) => {
      setIsModal(!isModal); 
      setSelectedRow(failedValue); // Store the clicked row's data
    };
    const QuizSurveyColumnData: TableColumn<SupportDataType>[] = [
      {
        name: <TableHeadColumn title={`S.No.`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: false,
      },
      {
        name: <TableHeadColumn title={`By`}/>,
        selector: (row) => row["by"],
        sortable: true,
        center: false,
      },
      {
        name: <TableHeadColumn title={`Form Type`}/>,
        selector: (row) => row["position"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Title`}/>,
        selector: (row) => row["salary"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Recipient Type`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Participants`}/>, 
        selector: (row) => (
          <span 
            onClick={() => handleColumnClick(row.participants)} 
            style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
          >
            {row.participants}
          </span>
        ),
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Passed`}/>, 
        selector: (row) => (
          <span 
            onClick={() => handleColumnClick(row.passed)} 
            style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
          >
            {row.passed}
          </span>
        ),
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Failed`}/>,
        selector: (row) => (
          <span 
            onClick={() => handleColumnClick(row.failed)} 
            style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
          >
            {row.failed}
          </span>
        ),
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Skip & Jump to Other Questions`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },    
      {
        name: <TableHeadColumn title={`Show Bookmark`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Pause Timer`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: false,
      },
      {
        name: <TableHeadColumn title={`Keywords`}/>,
        selector: (row) => row["position"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Image`}/>,
        selector: (row) => row["salary"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Start Date`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`End Date`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },    
      {
        name: <TableHeadColumn title={`Shuffle Question`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Font`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Text Size`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },    
      {
        name: <TableHeadColumn title={`Status`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name:<TableHeadColumn title={`Actions`}/>,
        cell: (row) => <ActionButtons openPopup={openPopup}/>,
        center: false,
        right:true,
      },
    ];
    const Studentdata = [
      {
        participant: "John Doe",
        dateStartTime: "2025-01-30 09:00",
        score: 85,
        correct: 17,
        mistakes: 3,
      },
      {
        participant: "Jane Smith",
        dateStartTime: "2025-01-30 09:30",
        score: 92,
        correct: 18,
        mistakes: 2,
      },
      {
        participant: "Michael Brown",
        dateStartTime: "2025-01-30 10:00",
        score: 78,
        correct: 16,
        mistakes: 4,
      },
    ];
    
    const Studentcolumns = [
      {
        name: "Participant",
        selector: (row: any) => row.participant,
        sortable: true,
        cell: (row: any) => (
          <button onClick={() => handleParticipantClick(row)} style={{ color: "blue", textDecoration: "underline", cursor: "pointer", background: "none", border: "none" }}>
            {row.participant}
          </button>
        ),
      },
      {
        name: "Date Start Time",
        selector: (row: any) => row.dateStartTime,
        sortable: true,
      },
      {
        name: "Score",
        selector: (row: any) => row.score,
        sortable: true,
      },
      {
        name: "Correct",
        selector: (row: any) => row.correct,
        sortable: true,
      },
      {
        name: "Mistakes",
        selector: (row: any) => row.mistakes,
        sortable: true,
      },
    ];
    const location = useLocation();
    const [openQestionModal, setOpenQestionModal] = useState(false);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal); 
        const currentPath = location.pathname;
        const isActive = (path) => {
            // Construct the full path by combining PUBLIC_URL with the provided path
            const fullPath = `${process.env.PUBLIC_URL}${path}`;
            
            // Check if the currentPath matches or starts with the full path (for sub-paths)
            return currentPath === fullPath || currentPath.startsWith(fullPath);
          };
          const activeClass = isActive('/tools/brand-support-tools/knowledge/questionnaire-list/add-new') ? 'bg-gray text-light' : ' ';
    return (
      <div className='page-body'>
         <div className="d-flex container-fluid align-items-center">
                <div className="px-2"> 
        <SubMenuPopup  currentPage="Quizzes and Surveys "/>
                </div> 
        <Breadcrumbs mainParent="All Tools" mainTitle={`${ManageQuizSurveySystem} (${BrandTools})`} parent={BrandTools} />
            </div>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
              <div className='my-2 ms-1'>
                    <Row>
                      <Col md="12">  
                    <StudyMaterialSubMenu />
 
                    </Col>
                    </Row>
                  </div>
                  
                <CardBody className='p-2'>
                  <> 
                  <div className='p-2 border'>
                    <div className=''>
                    {QuizzSurveyTableAction.map((action, index)=>{
                      return(
                      <>
                        <Link to={`${process.env.PUBLIC_URL}/${action.path}`}><span key={index} className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                      </>)
                    })}
                    </div>
                    
                    <div className="table-responsive pt-2">
                      <DataTable className='custom-scrollbar' columns={QuizSurveyColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                    </div>

                    <Popup title={`Quiz and Survey System`} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`}>
                      {isEditOpen ? <SurveyQuizEdit/> : <SurveyQuizView /> }
                    </Popup>

                  </div>
                  <Popup
                title='Import Bulk Qestions'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk titleField={true} notes={true}/>
            </Popup> 
            <Popup
                title='Publish Online'
                openModalToggle={openPublishModalToggle}
                isOpen={openPublishModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                  <Formik
                        initialValues={FequentlyinitialValues}
                        validationSchema={FequentlyValidationSchema}
                        onSubmit={(values) => {
                          console.log("Selected values:", values);
                        }}
                      >
                        {({ handleChange, values,errors ,setFieldValue}) => ( 
               <FequentlyModalForm selectField='Seminar' setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
              )}
    </Formik>
 
            </Popup>
                  <CommonModal
  isOpen={isModal}
  toggle={openModalToggleColumnClick}
  modalBodyClassName="px-3"
  size= 'xl' 
//   backdrop={backdrop}
>
  <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
    {/* Header Section */}
    <div className="modal-header align-items-center">
      <H3 className="w-50 text-left">{selectedRow?.title || "Quiz Summary"}</H3>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex justify-content-between align-items-center w-75">
          <p className='mb-0'><strong>Username:</strong> {selectedRow?.username || "Guest"}</p>
          <p className='mb-0'><strong>Date:</strong> {selectedRow?.date || new Date().toLocaleDateString()}</p>
          <p className='mb-0'><strong>Start Time:</strong> {selectedRow?.startTime || "N/A"}</p>
          <p className='mb-0'><strong>Score:</strong> {selectedRow?.startTime || "N/A"}</p>
          <p className='mb-0'><strong>Correct:</strong> {selectedRow?.startTime || "N/A"}</p>
          <p className='mb-0'><strong>Mistakes:</strong> {selectedRow?.startTime || "N/A"}</p>
        </div>
        <div onClick={openModalToggleColumnClick} className="search-close-icon">
          <X />
        </div>
      </div>
    </div>

    {/* Body Section */}
    <div style={{ height: "auto", overflow: "auto", padding: "1rem" }}>
    <DataTable
        title="Participants' Results"
        columns={Studentcolumns}
        data={Studentdata}
        pagination
        highlightOnHover
        striped
      />
    </div>
 {/* Body Section */}

    {/* Footer Section */}
    {/* {CommonFooter && ( */}
      <div className="modal-footer">
        <div className="next_previous position-relative pt-0">
          <Btn  className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Previous <SVG iconId="back-arrow" />
            </div>
          </Btn>
          <Btn className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Next <SVG iconId="front-arrow" />
            </div>
          </Btn>
        </div>
      </div>
    {/* )} */}
  </div>

</CommonModal>
 
                  <Modal
  isOpen={isresultOpen}
  toggle={openModalToggleresultModal}
  modalBodyClassName="px-3"
  size= 'xl' 
//   backdrop={backdrop}
>
  <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
    {/* Header Section */}
    <div className="modal-header align-items-center">
      <H3 className="w-50 text-left">{selectedRow?.title || "Quiz Summary"}</H3>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex justify-content-between align-items-center w-75">
          <p className='mb-0'><strong>Username:</strong> {selectedRow?.username || "Guest"}</p>
          <p className='mb-0'><strong>Date:</strong> {selectedRow?.date || new Date().toLocaleDateString()}</p>
          <p className='mb-0'><strong>Start Time:</strong> {selectedRow?.startTime || "N/A"}</p>
          <p className='mb-0'><strong>Score:</strong> {selectedRow?.startTime || "N/A"}</p>
          <p className='mb-0'><strong>Correct:</strong> {selectedRow?.startTime || "N/A"}</p>
          <p className='mb-0'><strong>Mistakes:</strong> {selectedRow?.startTime || "N/A"}</p>
        </div>
        <div onClick={openModalToggleresultModal} className="search-close-icon">
          <X />
        </div>
      </div>
    </div>

    {/* Body Section */}
    <ModalBody  >
      <div className="quiz-summary">
        <h4>Quiz Results</h4>
        <p><strong>Score:</strong> {  "N/A"}</p>
        <p><strong>Correct Answers:</strong> {  0}</p>
        <p><strong>Total Questions:</strong> {  0}</p>
        <p><strong>Unanswered:</strong> {   0}</p>
        <p><strong>Remarks:</strong> {  "Good job!"}</p>
        <div>
                <h2>Quiz Results</h2>
                {questions.map((question,num) => {
                  const selectedAnswer = selectedAnswers[question.id];
                  const correctAnswer = question.correct;
      
                  return (
                    <div key={question.id} className="question my-3">
                      <h5>{num + 1}{")"}.{' '}{question.text}</h5>
                      <div className='d-flex gap-2 mt-2'>
                        {question.options.map((option, index) => {
                          const isSelected = selectedAnswer === index;
                          const isCorrect = correctAnswer === index;
                          let buttonClass = 'btn ';
                          if (isSelected && !isCorrect) buttonClass += 'bg-danger'; // wrong answer selected
                          if (isCorrect) buttonClass += 'bg-success'; // correct answer
      
                          return (
                            <button
                              key={index}
                              className={buttonClass}
                              disabled
                            >
                              {option}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                {/* <button onClick={startGame} className="btn btn-primary">Restart</button> */}
              </div>
      </div>
    </ModalBody>
 {/* Body Section */}

    {/* Footer Section */}
    {/* {CommonFooter && ( */}
      <ModalFooter className="modal-footer">
        <div className="next_previous position-relative pt-0">
          <Btn  className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Previous <SVG iconId="back-arrow" />
            </div>
          </Btn>
          <Btn className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Next <SVG iconId="front-arrow" />
            </div>
          </Btn>
        </div>
      </ModalFooter>
    {/* )} */}
  </div>
</Modal>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default SurveyQuizes