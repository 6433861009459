import { ErrorMessage, Field, Formik } from 'formik';
import React from 'react'
import CustomSelect from '../../../../Component/CommonInput/CustomSelect';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { validationSchema } from '../../../../Component/Tools/BrandSupportTools/TabBrandSupport/FirstSpecialPerameter';
import Notes from '../../Notes/Notes';

function ImportBulk({titleField,notes}) {
    const jobLocation = [
        { value: 'remote', label: 'Remote' },
        { value: 'onsite', label: 'Onsite' },
        { value: 'hybrid', label: 'Hybrid' },
        { value: 'usa', label: 'USA' },
        { value: 'uk', label: 'UK' },
        { value: 'canada', label: 'Canada' },
      ];


      return (
    <Formik
    initialValues={{
      file: null, // Initial value for file input
      questionnaireKey: '',
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      // Handle form submission.

      console.log(values);
    }}
  >
    {({ setFieldValue, errors, touched }) => (
      <>
      {notes && <Notes  branchountry={true} PriceDay={true} Price ={false} oneBranch={true} post={false}/>}
      <Form className='py-2'>
         
      {titleField &&  <FormGroup>
        <Label for="fileInput">
         Questionnaire Title<span className="text-danger">*</span>
          </Label>
                                    <Field
          name="questionnaireKey"
          component={CustomSelect}
          options={jobLocation}
          className={`custom-select ${errors.questionnaireKey && touched.questionnaireKey ? 'is-invalid' : 'is-valid'}`}
          placeholder="Select Questionnaire Title"
          isMulti={true}
        />
        <ErrorMessage name="questionnaireKey" component="div" className="invalid-feedback" />
                                      </FormGroup>}
        <FormGroup>
          <Label for="fileInput">
            Select File <span className="text-danger">*</span>
            
          </Label>
          <Field name="file">
            {({ field }) => (
              <Input
                {...field}
                id="fileInput"
                name="file"
                type="file"
                className={`form-control ${
                  errors.file && touched.file ? 'is-invalid' : 'is-valid'
                }`}
                onChange={(event) => {
                  if (event.target.files) {
                    setFieldValue('file', event.target.files[0]);
                  }
                }}
              />
            )}
          </Field>
          <ErrorMessage name="file" component="div" className="invalid-feedback" />
        </FormGroup>
        <Button>Import</Button>
        {/* <Button color="primary" type="submit">Submit</Button> */}
      </Form>
      </>
    )}
  </Formik>
  )
}

export default ImportBulk