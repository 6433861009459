import React ,{useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link, useLocation } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { BrandTools, ManageQuizSurveySystem } from '../../../../utils/Constant'
import { ActionButtons, QuestionTableAction, QuestionsColumnData, StudyMaterialAction, links, supportColumnData, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import Popup from '../../../../Component/MasterPopup/Popup'
import QuestionEdit from '../../../../Component/Tools/BrandSupportTools/Questions/QuestionEdit'
import QuestionView from '../../../../Component/Tools/BrandSupportTools/Questions/QuestionView'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import StudyMaterialSubMenu from '../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import { Btn } from '../../../../AbstractElements'
import AddQuestionGroupModal from '../../../../Component/Tools/BrandSupportTools/Questions/AddQuestionGroupModal'
import ImportBulk from '../ImportBulk/ImportBulk'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'

const Questions = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const [isEditOpen , setIsEditOpen] = useState(false);
    const [isOpen , setIsOpen] = useState(false);
    
    const handleRowSelected =() =>{}
    const openPopup = (type:boolean) =>{
      setIsOpen(!isOpen);
      setIsEditOpen(type);
    }

    const openModalToggle = () =>{
        setIsOpen(!isOpen);
    }

    const QuestionsColumnData: TableColumn<SupportDataType>[] = [    
      {
        name: <TableHeadColumn title={`S. No.`}/>,
        selector: (row) => row["position"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Question`}/>,
        selector: (row) => row["position"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Image`}/>,
        selector: (row) => row["position"],
        sortable: true,
        center: false,
      },
      {
        name: <TableHeadColumn title={`Audio`}/>,
        selector: (row) => row["salary"],
        sortable: true,
        center: true,
      },
      {
        name:<TableHeadColumn title={`Duration`}/> ,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Mark`}/>,
        selector: (row) => row["position"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Compulsory`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Subcategories`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name:<TableHeadColumn title={`Study Material & Lesson`}/> ,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name:<TableHeadColumn title={`Quiz Material`}/> ,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Calculator`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Translator`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Dictionary`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Search`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name:<TableHeadColumn title={`Actions`}/>,
        cell: (row) => <ActionButtons openPopup={openPopup}/>,
        center: false,
        right:true,
      },
      
    ];
    const [openQestionModal, setOpenQestionModal] = useState(false);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
    const location = useLocation();
        const currentPath = location.pathname;
        const isActive = (path) => {
            // Construct the full path by combining PUBLIC_URL with the provided path
            const fullPath = `${process.env.PUBLIC_URL}${path}`;
            
            // Check if the currentPath matches or starts with the full path (for sub-paths)
            return currentPath === fullPath || currentPath.startsWith(fullPath);
          };
          const activeClass = isActive('/tools/brand-support-tools/knowledge/questionnaire-list/add-new') ? ' text-light bg-gray' : ' text-dark';
    return (
      <div className='page-body'>
         <div className="d-flex container-fluid align-items-center">
                <div className="px-2">  
        <SubMenuPopup  currentPage="Questions List"/>
                </div> 
       
        <Breadcrumbs mainParent="All Tools" mainTitle={`Question List (${BrandTools})`} parent={BrandTools} />
            </div>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card> 
              <div className='mt-2 ms-1'> 
                    <StudyMaterialSubMenu />
                  </div> 
                  
                <CardBody className='p-3'>
                  <>
                  
                 
                  <div className='p-2 border'>
                    <div className=''>
                    {QuestionTableAction.map((action, index)=>{
                      return(
                      <>
                        <Link to={`${process.env.PUBLIC_URL}/${action.path}`}><span key={index} className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                      </>)
                    })}
                    </div>
                  
                    <div className="table-responsive pt-2">
                    <DataTable className='custom-scrollbar' columns={QuestionsColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                    </div>
                    <Popup
                title='Import Bulk Questions'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk titleField={true} notes={true}/>
            </Popup>
                    <Popup title={`Questions`} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`}>
                      {isEditOpen ? <QuestionEdit/> : <QuestionView /> }
                    </Popup>
                    
                  </div>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default Questions