import * as Yup from "yup";
import { RequiredFieldValidationText } from "../../../utils/Constant";
/* Start -- Manage Album Form */
export interface ManageAlbumFormValidationProp {
  // albumName: string;
  description: string;
}

export const manageAlbumFormInitialValue: ManageAlbumFormValidationProp = {
  // albumName: "",
  description:""
};

export const manageAlbumFormValidationSchema = Yup.object().shape({
  // albumName: Yup.string().required(RequiredFieldValidationText),
  description: Yup.string().required(RequiredFieldValidationText),
});
/* End -- Manage Album Form */

// Start Visual idea
export interface visualIdea {
  albumName: string;
  description: string;
  keywords: string[];
}
export const visualIdeainitialValues: visualIdea = {
  albumName: "",
  description: "",
  keywords: [],
};
export const visualIdeavalidationSchema = Yup.object({
  albumName: Yup.string().required("Album name is required"),
  description: Yup.string().optional(),
  keywords: Yup.array().of(Yup.string().required("Keyword is required")),
});

