import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Btn } from "../../../../AbstractElements";
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput";
import TitleInput from "../../../CommonInput/TitleInput";
import LongDescription from "../../../CommonInput/LongDescription";
import { useState } from "react";
import DatePicker from "react-multi-date-picker";
import DescriptionInput from "../../../CommonInput/DescriptionInput";
import FaqModal from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/AddFaqForm";
import AddCourseandTeacherInformation from "../../../../Pages/Tools/BrandSupportTools/CourseandTeacherInformation/AddCourseandTeacherInformation";
import Popup from "../../../MasterPopup/Popup";
import {
    FequentlyinitialValues,
    FequentlyValidationSchema,
    scheduleFormInitialValue,
    scheduleFormValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import AddFaqForm from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/AddFaqForm";
import FequentlyModalForm from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyModalForm";
import SubCategory from "../../../CommonInput/SubCategory";
import Country from "../../../CommonInput/Country";
import SharingSection from "../SurveyAndQuizzes/SharingSection";
import CustomSelect from "../../../CommonInput/CustomSelect";

const AddStudyMaterialLessonForm = (props: any) => {
    const {
        errors,
        submitErrors,
        setSubmitError,
        values,
        setFieldValue,
        category,
        handleSubmit,
    } = props;
    const [images, setImages] = useState([] as any);
    const [imageMaxLength, setImageMaxLength] = useState(false);
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState<number | "">("");
    const [openGenralModal, setOpenGenralModal] = useState(false);
    const [openFaqModal, setOpenFaqModal] = useState(false);
    const openGenralModalToggle = () => setOpenGenralModal(!openGenralModal);
    const openFaqModalToggle = () => setOpenFaqModal(!openFaqModal);
    const [openPublishModal, setOpenPublishModal] = useState(false);
    const openPublishModalToggle = () => setOpenPublishModal(!openPublishModal);
    // Generate an array of past years (e.g., last 50 years)
    const years = Array.from({ length: 50 }, (_, i) => currentYear - i);

    const [imageURLS, setImageURLS] = useState<string[]>([]);
    const onImageChange = (e: any, fieldName: string) => {
        const files = e.target.files;
        if (files) {
            // Update Formik values for image or upload_image
            setFieldValue(fieldName, files); // Save the files to Formik
            // Optionally log the file details
            console.log(`Files for ${fieldName}:`, files);
        }
    };
    // const onImageChange = (e: any) => {
    //     let totalfiles = e.target.files.length;
    //     if (totalfiles < 1) return;
    //     else if (totalfiles > 10) {
    //         setImages([]);
    //         setImageMaxLength(true);
    //         e.target.value = "";
    //         return;
    //     } else if (totalfiles > 0 && totalfiles <= 10) {
    //         setImages([...e.target.files]);
    //     }
    //     const urls = [];
    //     images.forEach((image: any) => {
    //         if (urls.length < 10) {
    //             urls.push(URL.createObjectURL(image));
    //         }
    //     });
    //     setImageURLS(urls);
    // };
    const onYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const year = e.target.value;
        setSelectedYear(year); // Update local state
        setFieldValue("selectedYear", year); // Update Formik field value
    };
    const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];
    return (
        <Form
            className="form-wizard mt-3"
            onSubmit={(e) => {
                handleSubmit(e);
                console.log(values);
            }}
        >
            <Row>
                <Col sm="12" className="mb-3">
                    <Label check>
                        A Previous Lesson as a Reference (optional){" "}
                    </Label>
                    <Field
                        as="select"
                        className="form-control"
                        name="reference-coupon"
                    >
                        <option value={""}>Select a Reference</option>
                    </Field>
                </Col>

                <Col sm="4" className="mb-3">
                    <TitleInput
                        title="Lesson Unique Title"
                        values={values.title}
                        name="title"
                        placeholder="Title"
                    />
                </Col>
                <Col sm="4">
                    <Label check>Cover Image</Label>
                    <FormGroup>
                        <Field
                            className={`form-control ${
                                submitErrors &&
                                `${errors.image ? "is-invalid" : "is-valid"}`
                            }`}
                            type="file"
                            name="cover_image"
                            Single
                            accept="image/*"
                            onChange={(e) => {
                                const file = e.target.files
                                    ? e.target.files[0]
                                    : null;
                                setImages(file); // Update the state with selected files

                                console.log("Uploaded files:", file); // Log the upload image files
                            }}
                        />

                        <ErrorMessage
                            name="image"
                            component="span"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                </Col>
                <Col sm="4" className="mb-3">
                    <SubCategory
                        name="subcategory"
                        className={`${
                            submitErrors &&
                            `${errors.subcategory ? "is-invalid" : "is-valid"}`
                        }`}
                    />
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label>{"Student Countries"} (optional)</Label>
                        <Country
                            placeholder="Select"
                            name="country"
                            isMulti={true}
                            className={`${
                                submitErrors &&
                                `${errors.country ? "is-invalid" : "is-valid"}`
                            }`}
                        />
                        <ErrorMessage
                            name="country"
                            component="span"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Academic Year (optional)</Label>
                    <select
                        id="academic-year"
                        value={selectedYear}
                        onChange={(e) =>
                            setSelectedYear(Number(e.target.value))
                        }
                        className="form-control"
                    >
                        <option value="">Select Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </Col>
                <Col sm="4">
                    <DescriptionInput
                        name="description"
                        placeholder="Enter a Description"
                        values={values.description}
                        lable="Description"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Keywords (optional)</Label>
                    <TagsInput
                        name="keywords"
                        placeholder="Enter keywords"
                        className="form-control"
                        setFieldValue={setFieldValue}
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Url</Label>
                    <FormGroup>
                        <Field
                            type="text"
                            name="url"
                            placeholder="URL"
                            className={`form-control ${
                                submitErrors &&
                                `${errors.url ? "is-invalid" : "is-valid"}`
                            }`}
                        />
                        <ErrorMessage
                            name="url"
                            component="span"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="12">
                    <FormGroup>
                        {/* <LongDescription lable={'Examples and Explanations (optional):'}   value={values.studymateialdescription} id="editor_container"/> */}
                        <LongDescription
                            lable="Lesson Text"
                            value={values.lessonTextDescription}
                            id="lesson_text_description"
                            onChange={(value: string) => {
                                console.log(
                                    "Lesson Text Description Updated:",
                                    value
                                );
                                setFieldValue("lessonTextDescription", value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col sm="12" md="12">
                    <FormGroup>
                        <LongDescription
                            lable="Examples and Explanations (optional):"
                            value={values.studymateialdescription}
                            id="examples_description"
                            onChange={(value: string) => {
                                console.log(
                                    "Study Material Description Updated:",
                                    value
                                );
                                setFieldValue("studymateialdescription", value);
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm="4">
                    <Label check>
                        Upload Media <span className="txt-danger">*</span>
                    </Label>
                    <FormGroup>
                        <Field
                            className={`form-control ${
                                submitErrors &&
                                `${errors.image ? "is-invalid" : "is-valid"}`
                            }`}
                            type="file"
                            name="upload_image"
                            multiple
                            accept="image/*"
                            //   onChange={(e) => {
                            //     const files = e.target.files;
                            //     setImages(files); // Update the state with selected files

                            //     console.log("Uploaded files:", files); // Log the upload image files
                            // }}
                        />
                        {images.length > 10 ? (
                            <span className="text-danger">Max limit 10</span>
                        ) : (
                            ""
                        )}
                        <ul>Total Image : {images.length}</ul>
                        <ErrorMessage
                            name="image"
                            component="span"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Homework (optional)</Label>
                    <Field
                        as="textarea"
                        name="homework"
                        placeholder="Homework"
                        className="form-control"
                        maxLength="300"
                    />
                    <TextCounter
                        length={values?.homework?.length}
                        totalLength="300"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Course and Teacher Information (optional)
                    </Label>
                    <div className="">
                        <FormGroup>
                            <Input type="select">
                                <option>{`Select`}</option>
                                <option>
                                    Free for Commercial and Non-profit use
                                </option>

                                <option>Free for Non-Profit use only</option>
                                {/* <option>View Only. No right to download or use. </option>
                            <option>Display only on shopping pages. Not for social media usages</option> */}
                            </Input>
                            <span className="text-muted small">
                                <Btn
                                    className="ps-0  d-flex"
                                    onClick={openGenralModalToggle}
                                >
                                    <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                    Add Course and Teacher Information
                                </Btn>
                            </span>
                        </FormGroup>
                    </div>
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Frequently Asked Questions (optional)</Label>
                    <div className="">
                        <FormGroup>
                            <Input type="select">
                                <option>{`Select`}</option>
                                <option>
                                    Free for Commercial and Non-profit use
                                </option>

                                <option>Free for Non-Profit use only</option>
                                {/* <option>View Only. No right to download or use. </option>
                            <option>Display only on shopping pages. Not for social media usages</option> */}
                            </Input>
                            <span className="text-muted small">
                                <Btn
                                    className="ps-0  d-flex"
                                    onClick={openFaqModalToggle}
                                >
                                    <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                    Add Frequently Asked Questions
                                </Btn>
                            </span>
                        </FormGroup>
                    </div>
                </Col>
                <Col sm="4" className="mb-3">
                                                <FormGroup>
                                                    <Label check>
                                                        {`Policy or Service Terms (optional)`}
                                                        :
                                                    </Label>
                                                    <FormGroup floating>
                                                        <Field
                                                            className={`custom-select  `}
                                                            name="PolicyService"
                                                            options={
                                                                cityOptions
                                                            }
                                                            component={
                                                                CustomSelect
                                                            }
                                                            placeholder="Select Tests and Surveys"
                                                            isMulti={true}
                                                        />
                                                        
                                                    </FormGroup>
                                                </FormGroup>
                                                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Reusable Bank of Knowledge</Label>
                    <small className="d-block">
                        Allow others users, such as teachers, trainers,
                        influencers, colleges, schools, businesses etc… to
                        search, find and use your lessons and quizzes to train
                        their followers, students, trainees. Your username will
                        be always visible to other users
                    </small>
                    <Row className="mb-3 mx-2">
                        {/* Allow Option */}
                        <Col sm="auto">
                            <FormGroup
                                className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                    values.breakinActivity === false
                                        ? "transform-scale"
                                        : ""
                                }`}
                                check
                            >
                                <Label
                                    className={`mb-0 ${
                                        values.breakinActivity === false
                                            ? "text-success"
                                            : ""
                                    }`}
                                    for="yes"
                                    check
                                >
                                    <Input
                                        id="yes"
                                        name="breakinActivity"
                                        value={false} // Value as a boolean
                                        type="checkbox" // Radio buttons for mutually exclusive options
                                        onChange={() =>
                                            setFieldValue(
                                                "breakinActivity",
                                                false
                                            )
                                        }
                                        checked={
                                            values.breakinActivity === false
                                        }
                                        className="form-check-input"
                                    />
                                    <span className="ms-2">Allow</span>
                                </Label>
                            </FormGroup>
                        </Col>

                        {/* Do not Option */}
                        <Col sm="auto" className="mx-sm-2 mx-lg-4">
                            <FormGroup
                                className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                    values.breakinActivity === true
                                        ? "transform-scale"
                                        : ""
                                }`}
                                check
                            >
                                <Label
                                    className={`mb-0 ${
                                        values.breakinActivity === true
                                            ? "text-success"
                                            : ""
                                    }`}
                                    for="no"
                                    check
                                >
                                    <Input
                                        id="no"
                                        name="breakinActivity"
                                        value={true} // Value as a boolean
                                        type="checkbox"
                                        onChange={() =>
                                            setFieldValue(
                                                "breakinActivity",
                                                true
                                            )
                                        }
                                        checked={
                                            values.breakinActivity === true
                                        }
                                        className="form-check-input"
                                    />
                                    <span className="ms-2">Do not</span>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* Submit Button */}
                </Col>
                    <SharingSection
                CoManagement={false}
                InviteeList={true}
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
                viewOnly={true}
            />
                <Popup
                    title={"Add Course and Teacher Information"}
                    openModalToggle={openGenralModalToggle}
                    isOpen={openGenralModal}
                    size={`lg`}
                    backdrop={true}
                    CommonFooter={true}
                    isClose={false}
                    CommonFooter={false}
                    BodyClassName="pt-1"
                    largesize="modal-custom"
                >
                    <div className="mx-2 mt-2 mb-3 px-2">
                        <AddCourseandTeacherInformation />
                    </div>
                </Popup>
                <Popup
                    title="Add Frequently Asked Questions"
                    openModalToggle={openFaqModalToggle}
                    isOpen={openFaqModal}
                    size={`lg`}
                    backdrop={true}
                    CommonFooter={true}
                    isClose={false}
                    CommonFooter={false}
                    BodyClassName="pt-1"
                    largesize="modal-custom"
                >
                    <div className="mx-2 mt-2 mb-3 px-2">
                        <Formik
                            initialValues={scheduleFormInitialValue}
                            onSubmit={handleSubmit}
                            validationSchema={scheduleFormValidationSchema}
                        >
                            {({ errors, values }) => (
                                <AddFaqForm values={values} />
                            )}
                        </Formik>
                    </div>
                </Popup>
                <Popup
                    title="Publish Online"
                    openModalToggle={openPublishModalToggle}
                    isOpen={openPublishModal}
                    size={`lg`}
                    backdrop={true}
                    CommonFooter={true}
                    isClose={false}
                    CommonFooter={false}
                    BodyClassName="pt-1"
                    largesize="modal-custom"
                >
                    <Formik
                        initialValues={FequentlyinitialValues}
                        validationSchema={FequentlyValidationSchema}
                        onSubmit={(values) => {
                            console.log("Selected values:", values);
                        }}
                    >
                        {({ handleChange, values, errors, setFieldValue }) => (
                            <FequentlyModalForm
                                selectField="lesson"
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                values={values}
                                submitErrors={submitErrors}
                                errors={errors}
                            />
                        )}
                    </Formik>
                </Popup>
            </Row>

            <Row>
                <Col sm="auto" className="mb-3 ">
                    <Btn
                        color="primary"
                        type="submit"
                        onClick={() => setSubmitError(true)}
                    >
                        Save as a Draft
                    </Btn>
                </Col>
                <Col sm="auto" className="mb-3 ">
                    <Btn
                        color="primary"
                        type="submit"
                        onClick={openPublishModalToggle}
                    >
                        Save and Publish Online
                    </Btn>
                    {/* <Btn className='p-0' onClick={openQestionModalToggle}><span className={`border rounded p-2 m-1 bg-dark text-white  ps-2 pe-2  `}>Publish Online</span></Btn> */}
                </Col>
            </Row>
        </Form>
    );
};

export default AddStudyMaterialLessonForm;
