// import React, { useState } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { DeliveryFeeAction } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
// import {
//     Col,
//     Form,
//     Input,
//     Label,
//     Modal,
//     ModalBody,
//     ModalHeader,
//     Row,
// } from "reactstrap";
// import {
//     branchPublicContactFormInitialValue,
//     branchPublicContactFormValidationSchema,
// } from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";

// import { ErrorMessage, Formik } from "formik";
// import City from "../../../../Component/CommonInput/City";
// import Branches from "../../../../Component/CommonInput/Branches";
// import CommonSwitchSpan from "../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
// import { H5 } from "../../../../AbstractElements";
// import State from "../../../../Component/CommonInput/State";
// import Country from "../../../../Component/CommonInput/Country";

// const DeliverySubMenu = () => {
//     const location = useLocation();
//     const currentPath = location.pathname;
//     // State to manage selected checkbox
//     const [selectedPath, setSelectedPath] = useState("");

//     const handleCheckboxChange = (path) => {
//         // If already selected, uncheck it; otherwise, select it
//         setSelectedPath((prev) => (prev === path ? "" : path));
//     };
//     // const isActive = (path) => {
//     //     return (
//     //         path && currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`)
//     //     );.
        
//     // };
//     const isActive = (path) => {
//         // Check if the current path starts with the path of the link
//         return currentPath.includes(path) || currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`);
//       };
//     return (
//         <>
//             <div className="d-flex">
//                 {DeliveryFeeAction.map((item, index) => {
//                     // Determine the active class only if the item has a path
//                     const activeClass =
//                         item.path && isActive(item.path) ? "" : "";
//                     const isChecked = item.path && isActive(item.path);

//                     return (
//                         <div
//                             key={index}
//                             className={` p-2 delivery_input ${activeClass}`}>
                           
//                             <Link
//                             to={`${process.env.PUBLIC_URL}/${item.path}`}
//                             className="text-decoration-none text-reset d-flex align-items-center"
//                         >
//                             {/* Checkbox - controlled via state */}
//                             <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${isChecked ? "transform-scale" : ""}`}>
//                                 <Label>
//                                     <Input
//                                         type="checkbox"
//                                         name="deliveryFee"
//                                         className="form-check-input me-2"
//                                         value={item.path}
//                                         checked={isChecked}
//                                         onChange={() => handleCheckboxChange(item.path)}
//                                     />
//                                     <span className="ms-2">{item.actionName}</span>
//                                 </Label>
//                             </div>
//                         </Link>
//                         </div>
//                     );
//                 })}
//             </div>
            
//         </>
//     );
// };

// export default DeliverySubMenu;
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { DeliveryFeeAction } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import { Input, Label } from "reactstrap";

const DeliverySubMenu = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    
    // Set the first item's path as the default selectedPath
    const [selectedPath, setSelectedPath] = useState(DeliveryFeeAction[0]?.path || "");

    useEffect(() => {
        // Ensure the selected path remains active when navigating
        const activeItem = DeliveryFeeAction.find((item) => currentPath.includes(item.path));
        if (activeItem) {
            setSelectedPath(activeItem.path);
        }
    }, [currentPath]);

    const handleCheckboxChange = (path) => {
        // Allow only one selection at a time
        setSelectedPath(path);
    };

    return (
        <div className="d-flex">
            {DeliveryFeeAction.map((item, index) => {
                const isChecked = selectedPath === item.path;

                return (
                    <div key={index} className={`p-2 delivery_input`}>
                        <Link
                            to={`${process.env.PUBLIC_URL}/${item.path}`}
                            className="text-decoration-none text-reset d-flex align-items-center"
                        >
                            <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${isChecked ? "transform-scale" : ""}`}>
                                <Label>
                                    <Input
                                        type="checkbox"
                                        name="deliveryFee"
                                        className="form-check-input me-2"
                                        value={item.path}
                                        checked={isChecked}
                                        onChange={() => handleCheckboxChange(item.path)}
                                    />
                                    <span className="ms-2">{item.actionName}</span>
                                </Label>
                            </div>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default DeliverySubMenu;
