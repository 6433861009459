import { useState } from "react";
import { ErrorMessage, Field, Formik } from "formik"; 
import {
  SpecialParametersFormValidationProp,
  specialParametersFormInitialValue,
  specialParametersFormValidationSchema,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import AddSpecialParametersForm from "../SpecialParameters/AddSpecialParametersForm";
import { H4, H5 } from "../../../../AbstractElements";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import AddManageMyAlbumsForm from "../../ManageContents/ManageMyAlbums/AddManageMyAlbumsForm";
import { manageAlbumFormInitialValue, manageAlbumFormValidationSchema } from "../../../../Data/Tools/ManageContents/FormControlsValidation";
import CommonButton from "../../../Application/Ecommerce/AddProduct/ProductBody/CommonButton/CommonButton";
import Message from "../../../CommonMessage/Message";
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
 export const validationSchema = Yup.object({
    file: Yup.mixed().required('A file is required').test(
      'fileType',
      'Only Excel files are allowed',
      (value) => value && ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(value.type)
    )
  });
const FirstSpecialPerameter = (  {setActiveCheckboxFirst,setActiveCheckboxSecond  } ) => {
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const [albumType, setAlbumType] = useState("1");
    const dispatch = useAppDispatch();
    const formValues = useAppSelector((state) => state.form?.specialParameters);
  
    
    const [activeCheckbox, setActiveCheckbox] = useState< 'first' | 'second' | 'bulk'>('second');

    const handleCheckboxChange = (checkbox:  'first' | 'second' | 'bulk') => {
      // Toggle between checkboxes
      setActiveCheckbox(checkbox);
    };
    return (
        <>
            <H4 className="mb-2">Branches</H4> 
           
              <Formik
                    initialValues={formValues}
                    onSubmit={(values, { setSubmitting }) => {
                      // Handle form submission logic here
                      console.log('Form values:', values);
                      setSubmitting(false); // If you're doing async work, ensure to setSubmitting(false)
                    }}
                    validationSchema={specialParametersFormValidationSchema}
                    enableReinitialize={true}
                >
                    {({ errors, values, setFieldValue,handleSubmit }) => (
                        <AddSpecialParametersForm
                            submitErrors={submitErrors}
                            setSubmitError={setSubmitError}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                            setActiveCheckboxFirst={setActiveCheckboxFirst}
                            setActiveCheckboxSecond={setActiveCheckboxSecond}
                            handleSubmit={handleSubmit}
                        />
                    )}
                </Formik> 
                     {/* {activeCheckbox != 'none' ? <CommonButton step={true} bottombutton={true}/> : ''} */}


        </>
    );
};

export default FirstSpecialPerameter;
