 
import React from "react";
import { Field } from "formik";
import CustomSelect from "./CustomSelect";

const yourLessonsOptions = [
  { label: "Lesson 1", value: "branch1" },
  { label: "Lesson 2", value: "branch2" },
  { label: "Lesson 3", value: "branch3" },
];

const knowledgeBankOptions = [
  { label: "Public Lesson 1", value: "kb1" },
  { label: "Public Lesson 2", value: "kb2" },
  { label: "Public Lesson 3", value: "kb3" },
];

function LessonField(props: any) {
  const { name, className, isMulti, placeholder } = props;

  return (
    <>
      <div className="mb-3">
        <label>Select from YOUR existing lessons</label>
        <Field
          className={`custom-select ${className}`}
          name={`${name}.yourLessons`}
          options={yourLessonsOptions}
          component={CustomSelect}
          placeholder={placeholder}
          isMulti={isMulti}
        />
      </div>

      <div className="mb-3">
        <label>Select from the KNOWLEDGE BANK, public lessons from other users</label>
        <Field
          className={`custom-select ${className}`}
          name={`${name}.knowledgeBank`}
          options={knowledgeBankOptions}
          component={CustomSelect}
          placeholder={placeholder}
          isMulti={isMulti}
        />
      </div>
    </>
  );
}

export default LessonField;
