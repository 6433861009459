import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Btn } from "../../AbstractElements";
import {
  AccountCountry,
  AdditionalServices,
  AgreeToTermsAndConditions,
  NewBiilingDateText,
  NewBillingDate,
  PlanDetails,
  PrivacyPolicy,
  RegistrationPlan,
  RegistrationPlanText,
  RegistrationType,
  Submit,
  TermsAndConditions,
} from "../../utils/Constant";
import { ErrorMessage, Field, Form } from "formik";
import { Link } from "react-router-dom";
import CardHeaderCollapse from "../../CommonElements/CardHeaderCommon/CardHeaderCollapse";
import ReactDatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import { AdditionalDetailsColumns } from "../../Pages/App/AccountSettings/MyRegistrationPlan";
import PricingCode from "./PricingCode";
import Country from "../CommonInput/Country";
import RegistrationTypes from "../CommonInput/RegistrationTypes";
import { FaLaptopHouse } from "react-icons/fa";

function RenewFormContainer(props: any) {
  const {
    currentRegistraionPlan,
    filterDynamicPlan,
    values,
    setFieldValue,
    countryId,
    setSubmitError,
    submitErrors,
    errors,
    registrationPlanOptions,
    showingPriceList,
    handleRowSelected,
    toggleDelete,
    selectedAction,
    setSelectedAction,
    setCountryId,
    setPlanId,
    setPlans,
  } = props;

  const [isMyPlanOpen, setIsMyPlanOpen] = useState(true);
  const [isAdditionalServiceOpen, setIsAdditionalServiceOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date());

  const maxDate = () => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() + 405));
  };

  useEffect(() => {
    setCountryId(values.country_id);
    setPlanId(values.plan_id);
  }, [values]);

  useEffect(() => {
    if (selectedAction === 2) {
      setFieldValue("country_id", 0);
      setFieldValue("plan_id", "");
      setFieldValue("pay_period", "");
      setFieldValue("type", "change");
      setFieldValue("agreeTermsConditions", "");
      setPlans(undefined);
    } else {
      setFieldValue("type", "extend");
      setFieldValue("agreeTermsConditions", true);
    }
  }, [selectedAction]); 

  return (
    <Form className="form-wizard">
      <Row>
        <Col md="12" className="pb-2">
          <div className="card-wrapper border rounded-3 checkbox-checked pb-2">
            <div className="form-check-size">
              <div className="form-check form-check-inline radio radio-primary ps-4">
                <Input
                  id="radioinline1"
                  type="radio"
                  name="radio5"
                  defaultChecked
                  onChange={() => setSelectedAction(1)}
                />
                <Label className="mb-0" for="radioinline1" check>
                  {`Extend the Existing Plan`}
                </Label>
              </div>
              <div className="form-check form-check-inline radio radio-primary ps-4">
                <Input
                  id="radioinline2"
                  type="radio"
                  name="radio5"
                  onChange={() => setSelectedAction(2)}
                />
                <Label className="mb-0" for="radioinline2" check>
                  {`Change Plan`}
                </Label>
              </div>
              <div className="form-check form-check-inline radio radio-primary ps-4">
                <Input
                  id="radioinline3"
                  type="radio"
                  name="radio5"
                  onChange={() => setSelectedAction(3)}
                />
                <Label className="mb-0" for="radioinline3" check>
                  {`Unify Billing Date`}
                </Label>
              </div>
            </div>
          </div>
        </Col>
        {selectedAction === 1 ? (
          <>
            <Col lg="12" className="mx-1">
              <PricingCode
                planSummary={currentRegistraionPlan?.plan_summary}
                filterDynamicPlan={filterDynamicPlan}
                values={values}
                countryId={countryId}
                setFieldValue={setFieldValue}
              />
            </Col>
            <Col xs="12 pt-4 pb-4">
              <Btn
                color="primary"
                type="submit"
                onClick={() => setSubmitError(true)}
              >
                {Submit}
              </Btn>
            </Col>
          </>
        ) : (
          ""
        )}

        {selectedAction === 2 ? (
          <>
            <Col xs="12" lg="6">
              <FormGroup className="position-relative">
                <Label>
                  {AccountCountry}
                  <span className="font-danger">*</span>
                </Label>
                <Country
                  name="country_id"
                  placeholder="Select Country"
                  className={`${
                    submitErrors &&
                    `${errors.country_id ? "is-invalid" : "is-valid"}`
                  }`}
                />
                <ErrorMessage
                  name="country_id"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup className="position-relative">
                <Label>{RegistrationType}</Label>
                <RegistrationTypes
                  name="registration_type"
                  placeholder="Select Registration Type"
                  isMulti={true}
                  className={`${
                    submitErrors && `${errors.type ? "is-invalid" : "is-valid"}`
                  }`}
                  countryId={countryId}
                />
                <ErrorMessage
                  name="registration_type"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col xs="12">
              <Link to={`${process.env.PUBLIC_URL}/all-registration-plans`}>
                {RegistrationPlanText}
              </Link>
              <FormGroup className="position-relative">
                <Label>
                  {RegistrationPlan}
                  <span className="font-danger">*</span>
                </Label>
                <Field
                  as="select"
                  name="plan_id"
                  className={`form-control ${
                    submitErrors &&
                    `${errors.plan_id ? "is-invalid" : "is-valid"}`
                  }`}
                >
                  <option>
                    {"Select"} {RegistrationPlan}
                  </option>
                  {registrationPlanOptions}
                </Field>
                <ErrorMessage
                  name="plan_id"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>

            {currentRegistraionPlan?.plan_summary && (
              <Col xs="12" className="mx-2">
                <PricingCode
                  planSummary={currentRegistraionPlan?.plan_summary}
                  filterDynamicPlan={filterDynamicPlan}
                  values={values}
                  setFieldValue={setFieldValue}
                />
                {errors?.pay_period && (
                  <span
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Required
                  </span>
                )}
              </Col>
            )}

            {showingPriceList && values.plan_id &&  (
              <Col xs="12" className="mt-4">
                <Card key={`3`}>
                  <CardHeaderCollapse
                    title={PlanDetails}
                    isOpen={isMyPlanOpen}
                    setIsOpen={setIsMyPlanOpen}
                  />
                  <Collapse isOpen={isMyPlanOpen}>{showingPriceList}</Collapse>
                </Card>
              </Col>
            )}

            <Col xs="12" className="mx-4">
              <FormGroup check>
                <Field
                  id="invalid-check-wizard"
                  name="agreeTermsConditions"
                  type="checkbox"
                  className="form-check-input"
                />
                <Label
                  for="invalid-check-wizard"
                  className={`mb-0 d-block ${
                    submitErrors &&
                    ` ${
                      errors.agreeTermsConditions
                        ? "text-danger"
                        : "text-success"
                    }`
                  }`}
                >
                  {AgreeToTermsAndConditions}
                  <Link to="" className="px-1">
                    {TermsAndConditions},
                  </Link>
                  <Link to="" className="px-1">
                    {PrivacyPolicy}.
                  </Link>
                </Label>
              </FormGroup>
            </Col>
            <Col xs="12 pt-4 pb-4">
              <Btn
                color="primary"
                type="submit"
                onClick={() => setSubmitError(true)}
              >
                {Submit}
              </Btn>
            </Col>
          </>
        ) : (
          ""
        )}

        {selectedAction === 3 ? (
          <>
            <Col xxl="12" className="box-col-12">
              <Card key={`2`}>
                <CardHeaderCollapse
                  title={AdditionalServices}
                  isOpen={isAdditionalServiceOpen}
                  setIsOpen={setIsAdditionalServiceOpen}
                />
                <Collapse isOpen={isAdditionalServiceOpen}>
                  <CardBody>
                    <DataTable
                      className="custom-scrollbar"
                      columns={AdditionalDetailsColumns}
                      data={currentRegistraionPlan?.additional_orders || []}
                      striped={true}
                      pagination
                      selectableRows
                      onSelectedRowsChange={handleRowSelected}
                      clearSelectedRows={toggleDelete}
                    />
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
            <Label>{NewBillingDate}</Label>
            <small>{NewBiilingDateText}</small>
            <Col xxl="9" className="box-col-12">
              <InputGroup className="flatpicker-calender">
                <ReactDatePicker
                  className="form-control flatpickr-input"
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  minDate={new Date()}
                  maxDate={maxDate()}
                  placeholderText="Select a date in February 2020"
                />
              </InputGroup>
            </Col>

            <Col xs="12 pt-4 pb-4">
              <Btn color="primary" onClick={() => setSubmitError(true)}>
                {Submit}
              </Btn>
            </Col>
          </>
        ) : (
          ""
        )}
      </Row>
    </Form>
  );
}

export default RenewFormContainer;
