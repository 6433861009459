import React, { useState } from 'react'
import Popup from '../../MasterPopup/Popup'
import { AddNewIdeaStoryAlbum } from '../../../utils/Constant'
import { Col, Input, Label } from 'reactstrap'
import { H5 } from '../../../AbstractElements'
import { manageAlbumFormInitialValue, manageAlbumFormValidationSchema } from '../../../Data/Tools/ManageContents/FormControlsValidation'
import { Formik } from 'formik'
import AddManageMyAlbumsForm from '../../Tools/ManageContents/ManageMyAlbums/AddManageMyAlbumsForm'
import ServerErrorMessages from '../../CommonMessage/ServerErrorMessages'

function AddAlbumModal({isOpen,openModalToggle,title}) {
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const handleSubmit =  (values) => {
        console.log( values ); 
        
       };
  return (
    <>
    <Popup
                title={title}
                openModalToggle={openModalToggle}
                isOpen={isOpen}
                size={`xl`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                > 
                    <div className="px-4 py-2">
                        <Formik
                            initialValues={manageAlbumFormInitialValue}
                            onSubmit={handleSubmit}
                            validationSchema={manageAlbumFormValidationSchema}>
                            {({ errors, values, setFieldValue }) => (
                                <AddManageMyAlbumsForm
                                    submitErrors={submitErrors}
                                    setSubmitError={setSubmitError}
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Formik> 

                    </div>
                 
            </Popup>
    </>
  )
}

export default AddAlbumModal