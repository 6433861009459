import { useCallback } from "react";
import { ToastContainer } from "react-toastify";
import RouterData from "./Routes";
import MaintanceRouterData from "./Component/WebsiteSettings/Maintenance/MaintanceRouterData";
import WebsiteSettings from "./Component/WebsiteSettings";
import { useAppSelector, useAppDispatch } from "./ReduxToolkit/Hooks";
import Loading from "./Component/Loading/Loading";
import { useEffect } from "react";
import { useGetWebsiteSettingDataMutation } from "./ReduxToolkit/Reducers/AdvancedSettings/websiteSettingsApiSlice";
import { setWebsiteSettingData } from "./ReduxToolkit/Reducers/commonInputSlice";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import {
  setColorBackground1,
  setColorBackground2,
} from "./ReduxToolkit/Reducers/ThemeCustomizerSlice";
import ConfigDB from "./Config/ThemeConfig";
import { setGeoLocation } from "./ReduxToolkit/Reducers/commonInputSlice";

function App() {
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);
  const { geoLocation }: any = useAppSelector((state) => state.common);

  const dispatch = useAppDispatch();

  /* Get Geo Location Data */
  const getGeoInfo = useCallback(() => {
    fetch("https://ipapi.co/json/")
      .then((response: any) => response.json())
      .then((data: any) => { 
        dispatch(
          setGeoLocation({
            ip: data.ip,
            countryName: data.country_name,
            countryCode: data.country_calling_code,
            city: data.city,
            timezone: data.timezone,
            country_code: data.country_code,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    getGeoInfo();
  }, [getGeoInfo]);
  /* END : Get Geo Location */

  const isMaintanceMode: any =
    websiteSettingsData &&
    websiteSettingsData.maintenance_mode &&
    websiteSettingsData.maintenance_mode;

  const [getWebsiteSettingDataApi, { isLoading }] =
    useGetWebsiteSettingDataMutation();

  //Star : Get Website setting data get and set in common state.
  const getWebsiteSettingDataHandler = useCallback(async () => {
    try {
      const response = await getWebsiteSettingDataApi({
        code:
          geoLocation.country_code !== undefined
            ? geoLocation.country_code
            : "US",
      }).unwrap();
      const { data, success, message } = response;

      if (success === true) {
        dispatch(setWebsiteSettingData(data)); 
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.error || "Something went wrong");
    }
  }, []);
  useEffect(() => {
    getWebsiteSettingDataHandler();
  }, [dispatch]);
  // END.

  // Start : Page wise Google Analytic Code set.
  const trackingId =
    websiteSettingsData && websiteSettingsData.google_analytics;
  if (trackingId) {
    ReactGA.initialize(trackingId);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }
  useEffect(() => {
    // Track page views when route changes (in case you're using React Router)
    const trackPageView = () => {
      if (trackingId) {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }
    };
    window.addEventListener("popstate", trackPageView);
    return () => {
      window.removeEventListener("popstate", trackPageView);
    };
  }, [window.location.pathname]);
  //END.

  // Start : Function to update the favicon
  const updateFavicon = (newFavicon: any) => {
    let link: any = document.querySelector("link[rel*='icon']");
    // If no favicon link exists, create a new one
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    // Set the href to the new favicon URL
    link.href = newFavicon;
  };

  const faviconUrl =
    websiteSettingsData && websiteSettingsData.frontend_favicon;
  useEffect(() => {
    // When the favicon URL state changes, update the favicon
    if (faviconUrl) {
      updateFavicon(faviconUrl);
    }
  }, [faviconUrl]);
  // End :

  function hexToRgba(hex: any) {
    // Remove '#' if it exists
    hex = hex.replace(/^#/, "");

    // Parse r, g, b values
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Return the RGBA string
    return `${r}, ${g}, ${b}`;
  }
  //Start : Default primary and secondary theme color set.
  const primaryColor = websiteSettingsData && websiteSettingsData.primary_color;
  const secondaryColor =
    websiteSettingsData && websiteSettingsData.secondary_color;

  const rgbaGradient = primaryColor != undefined ? hexToRgba(primaryColor) : "";
  useEffect(() => {
    if (primaryColor) {
      dispatch(setColorBackground1(primaryColor));
      document.documentElement.style.setProperty(
        "--theme-default",
        primaryColor
      );
      document.documentElement.style.setProperty(
        "--theme-default-gradient",
        rgbaGradient
      );
      ConfigDB.data.color.primary_color = primaryColor;
      //console.log(defaultColor);
    }

    if (secondaryColor) {
      dispatch(setColorBackground2(secondaryColor));
      document.documentElement.style.setProperty(
        "--theme-secondary",
        secondaryColor
      );
      ConfigDB.data.color.secondary_color = secondaryColor;
    }
  }, [primaryColor, secondaryColor]);
  //END.


  if (websiteSettingsData.length === 0 || isLoading) return <Loading />;

  return (
    <>
      <WebsiteSettings />
      {isMaintanceMode === "on" ? <MaintanceRouterData /> : <RouterData />}
      <ToastContainer className="custom-toast-container" />
    </>
  );
}

export default App;
