import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, Col, Collapse, Modal, Row, Table } from 'reactstrap'
import { Btn, H3, H4, LI, P, SVG, UL } from '../../../../AbstractElements';
import { Carousel } from 'react-responsive-carousel';
import AdSense from '../../../WebsiteSettings/AdSense/Index';
import { useAppSelector } from '../../../../ReduxToolkit/Hooks';
import { Link } from 'react-router-dom';
import { images_a, images_b, items } from '../../MediaData/MediaData';
import { dynamicImage } from '../../../../Service';
import MediaModal from '../../MediaData/MediaModal';
import { FaChevronDown } from 'react-icons/fa';
import { ChevronDown, ChevronUp } from 'react-feather';

export interface ContestModalInterface {
    value: boolean;
    setOpenModal: (value: boolean) => void;
    dataId:   number;
    contestitems: { id: number; title: string }[];
}

const ContestModal = ({value , setOpenModal,modalBodyClassName , dataId,contestitems}:ContestModalInterface) => {
    const [open, setOpen] = useState(value);
    const [Ismedia, setMedia] = useState(false);
    const [mediadataId, setmediaDataId] = useState<number>(0);
    const [shouldResize, setShouldResize] = useState(false);
    const [activeAccordionId, setActiveAccordionId] = useState<string | null>('0'); // Updated state name

    const toggleAccordion = (id: string) => {
      setActiveAccordionId(activeAccordionId === id ? null : id); // Toggle the accordion item
    };
    const onCloseModal = () => {
        setOpen(false);
        setOpenModal(false);
    };
    const [currentId, setCurrentId] = useState(dataId);
    const handlePopupClick = useCallback((id: number) => {
        setmediaDataId(id);
        setMedia(true);
      }, []);

    const handleNext = () => {
        if (currentId < contestitems.length - 1) {
            setCurrentId(currentId + 1);
        }
    };

    
    const handlePrevious = () => {
        if (currentId > 0) {
            setCurrentId(currentId - 1);
        }
    }; 
    const { websiteSettingsData }: any = useAppSelector((state) => state.common); 
   
  // Split the items for desktop layout
  const splitItems = [
    items.filter((_, index) => index % 4 === 0),
    items.filter((_, index) => index % 4 === 1),
    items.filter((_, index) => index % 4 === 2),
    items.filter((_, index) => index % 4 === 3),
  ];

  // Merge items for desktop view (to display 4 columns)
  const mergedItems = useMemo(() => {
    return splitItems.flat();
  }, [splitItems]);

  // Check and update layout on resize or page load
  const updateLayout = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setShouldResize(true); // Mobile view
    } else {
      setShouldResize(false); // Desktop view
    }
  }, []);

  useEffect(() => {
    updateLayout(); // Check layout on mount
    window.addEventListener("resize", updateLayout); // Add resize listener

    return () => {
      window.removeEventListener("resize", updateLayout); // Cleanup listener on unmount
    };
  }, [updateLayout]);
const imageUrls = [
  "../assets/images/ecommerce/01.jpg",
  "../assets/images/ecommerce/02.jpg",
  "../assets/images/ecommerce/03.jpg",
  "../assets/images/ecommerce/04.jpg",
  "../assets/images/ecommerce/05.jpg",
  "../assets/images/ecommerce/06.jpg",
  "../assets/images/ecommerce/07.jpg",
  "../assets/images/ecommerce/08.jpg"
];
const renderCarousel = (index: number) => (
  <Carousel
    className="owl-carousel owl-theme"
    showStatus={false}
    showIndicators={false}
    showArrows={true}
    swipeable={false}
    autoPlay={true}
    infiniteLoop={true}
    showThumbs={false}
    stopOnHover={true}
    emulateTouch={false}
    onClickItem={() => handlePopupClick(index)}
  >
    {(index % 2 === 0 ? images_a : images_b).map((image, idx) => (
      <div className="item" key={idx+10}>
        <img src={dynamicImage(image)} alt="" />
        <div className="product-hover">
          <UL className="simple-list flex-row">
            <LI>
              <Link to={``}>
                <i className="icon-eye"></i>
              </Link>
            </LI>
          </UL>
        </div>
      </div>
    ))}
  </Carousel>
);
    return (
        <Modal wrapClassName=' ' className={` modal-dialog-scrollable ${ modalBodyClassName ? modalBodyClassName : "" }`}  fade centered size="xl" isOpen={open} toggle={onCloseModal}>
            <div className='modal-header' onClick={onCloseModal}> 
                <H4>{`Contest `}{contestitems[currentId].title}</H4>
                <Btn className="py-0" close></Btn>
            </div>
            <div className='modal-body bg-light'>
            <Card   className="mb-2">
                                    <div onClick={() => toggleAccordion('1')} className="d-flex px-2 bg-primary rounded gap-2 align-items-center justify-content-between">
                                        <div className="d-flex gap-2 align-items-center p-3">
                                        A Previous Photo Contest as a Reference :	Latest Contest
                                        </div>
                                        {activeAccordionId === '1' ? <ChevronUp /> : <ChevronDown />}
                                    </div>
                                    <Collapse isOpen={activeAccordionId === '1'}>
                                        <CardBody className="py-2 px-2">
                                        <Row className="product-box">
                    <Col lg="4" sm="12">
                        <Card>
                            <CardBody>
                            <Carousel
                                className="owl-carousel owl-theme"
                                showStatus={false}
                                showIndicators={false}
                                showArrows={true}
                                swipeable={true}
                                autoPlay={true}
                                infiniteLoop={true}
                                showThumbs={true}
                            >
                                <div className="item">
                                <img src="../assets/images/ecommerce/01.jpg" alt="" />
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/02.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/03.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/04.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/05.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/06.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/07.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/08.jpg" alt=""/>
                                </div>
                            </Carousel>
                            </CardBody>
                        </Card>
                    </Col>
                    { websiteSettingsData?.google_ads_index === "on" && <Col lg="3" sm="12">
                    <AdSense />
                    </Col>}
                    <Col lg={websiteSettingsData?.google_ads_index === "on" ? "5" :'12'} sm="12">
                        <Card>
                            <CardBody>
                                <div className="product-page-details"> 
                                    <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                A Previous Photo Contest as a Reference :
                                                                </b>
                                                            </td>
                                                            <td> Latest Contest</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Photo Contest Title:
                                                                </b>
                                                            </td>
                                                            <td> Champio ColdpLay</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Category:</b>
                                                            </td>
                                                            <td>Free Commercial</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Product or Service Name:</b>
                                                            </td>
                                                            <td>Value 1</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Url:</b>
                                                            </td>
                                                            <td> <a href="1weeb.com">1weeb.com</a> </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Vender’s Country:</b>
                                                            </td>
                                                            <td>India</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Desired Budget:</b>
                                                            </td>
                                                            <td>10</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Media Type :</b>
                                                            </td>
                                                            <td>photos</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Country:</b>
                                                            </td>
                                                            <td>India</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Minimum Shares per Content :</b>
                                                            </td>
                                                            <td>100</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Minimum Likes per Content:</b>
                                                            </td>
                                                            <td>200</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Minimum Votes per Content :</b>
                                                            </td>
                                                            <td>50</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>A competitor must be a follower in my    1Webbb:</b>
                                                            </td>
                                                            <td>User</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>A competitor must be a previous customer on my online store:</b>
                                                            </td>
                                                            <td>Past 30 Days</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                 Description:
                                                                </b>
                                                            </td>
                                                            <td>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores dolorem obcaecati commodi numquam est libero sint accusantium quo ea explicabo.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio, iure possimus eum nisi ab sed quas veritatis. Quaerat, veniam voluptates.</td>
                                                        </tr> 
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    alignContent:
                                                                        "baseline",
                                                                }}>
                                                                <b>Keywords:</b>
                                                            </td>
                                                            <td>
                                                                High-Quality
                                                                Photos,Stock
                                                                Images,Free
                                                                Photos,
                                                                Royalty-Free
                                                                Images,
                                                                Photography
                                                                Collection, HD
                                                                Photos, Curated
                                                                Photo Library,
                                                                Creative Commons
                                                                Photos, Free
                                                                Image Downloads,
                                                                Professional
                                                                Photography,
                                                                Background
                                                                Images, Nature
                                                                Photos
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                 
                </Row>
                                            
                                        </CardBody>
                                    </Collapse>
                                </Card>
               
                <Row>
                    <Col sm="12">
                    
                                  <div className="product-wrapper-grid mt-5">
                    <H3 className="my-3">Participating Media</H3>
                    <div className="product-wrapper-grid">
      <div className={shouldResize ? "d-flex flex-column" : "column-3"}>
        {shouldResize ? (
          // Mobile layout: one item per row
          items.map((item, index) => (
            <div key={index+500}>
              {websiteSettingsData?.google_ads_index === "on" &&
              index !== 3 &&
              index !== 15 &&
              index !== 27 ? (
                <Card className="pb-4 h-100">
                  <div className="product-box h-100 border">
                    <div className="product-img">{renderCarousel(index)}</div>
                    <div className="product-details">
                      <Link to={`${process.env.PUBLIC_URL}/ecommerce/productpage`}>
                        <H4>{item.title}</H4>
                      </Link>
                      <P>Description of {item.title}</P>
                    </div>
                  </div>
                </Card>
              ) : (
                <AdSense listingAds={true} />
              )}
            </div>
          ))
        ) : ( 
          
          // Desktop layout: grid of 4 items per row
          mergedItems.map((item, index) => (
            <div key={index+'a'} className="">
              {websiteSettingsData?.google_ads_index === "on" &&
              index !== 3 &&
              index !== 15 &&
              index !== 27 ? (
                <Card className="pb-4 h-100">
                  <div className="product-box h-100 border">
                    <div className="product-img">{renderCarousel(index)}</div>
                    <div className="product-details">
                      <Link to={`${process.env.PUBLIC_URL}/ecommerce/productpage`}>
                        <H4>{item.title}</H4>
                      </Link>
                      <P>Description of {item.title}</P>
                    </div>
                  </div>
                </Card>
              ) : (
                <AdSense listingAds={true} />
              )}
            </div>
          ))
        )}
      </div>

      {Ismedia && (
        <MediaModal
          value={Ismedia}
          setOpenModal={setMedia}
          dataId={mediadataId}
          items={items}
          modalBodyClassName="modal-custom"
        />
      )}
    </div>
      </div>
                </Col>
                </Row>
            </div>
            <div className="modal-footer ">
                <div className="product-buttons position-relative pt-0" >

                <Btn onClick={handlePrevious} className="border" disabled={currentId === 0}>
                    <div className="d-flex align-items-center gap-sm-2 gap-1">
                    Previous           <SVG iconId=" back-arrow" />
                    </div>
                </Btn>
                <Btn onClick={handleNext} className="border" disabled={currentId === items.length - 1}>
                    <div className="d-flex align-items-center gap-sm-2 gap-1">
                    Next             <SVG iconId="front-arrow"/>
                    </div>
                </Btn>
                </div>
                {/* <CommonButton step={true} /> */}
            </div>
        </Modal>
    )
}

export default ContestModal