import { useState } from "react";
import { Formik } from "formik";
import SchedulesForm from "./SchedulesForm";
import { Card, CardHeader, Container } from "reactstrap";
import {
    SchedulesFormValidationProp,
    scheduleFormInitialValue,
    scheduleFormValidationSchema,
} from "../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import Breadcrumbs from "../../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ManageBranches, SchedulesTitle } from "../../../../../utils/Constant";
import Message from "../../../../CommonMessage/Message";
import SubMenu from "../../../../Application/SubMenu/SubMenu";
import { Link } from "react-router-dom";
import { MyPhotoContestTableAction } from "../../../../../Data/Tools/BrandSupportTools/ButtonActions";
import ManageOnlineSubMenu from "../../../../../Pages/Tools/ManageOnlineStore/ManageOnlineSubMenu/ManageOnlineSubMenu";
import BranchSubMenu from "../../../../../Pages/Tools/ManageOnlineStore/ManageBranches/BranchSubMenu/BranchSubMenu";
import { Btn, LI, UL } from "../../../../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Popup from "../../../../MasterPopup/Popup";
import ImportBulk from "../../../../../Pages/Tools/BrandSupportTools/ImportBulk/ImportBulk";
import SubMenuPopup from "../../../../../Layout/Sidebar/ManageStorePopup";

function SchedulesMain() {
    const [submitErrors, setSubmitError] = useState<boolean>(false);

    const handleSubmit = (values: SchedulesFormValidationProp) => {
        console.log(values);
        setSubmitError(false);
    };
    const [openQestionModal, setOpenQestionModal] = useState(false);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
    return (
        <div >
            <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
           
            <SubMenuPopup  currentPage="Add Schedules"/>
                </div> 
            <Breadcrumbs mainTitle={`${SchedulesTitle} (Manage Store)`} parent={ManageBranches} />
            </div>
            <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    </CardHeader>
                    </Container>
            <Container fluid>
                <div className="my-2">
                <BranchSubMenu sliced='-4'/> 
                </div>
                <div className="">
                    {/* {MyPhotoContestTableAction.slice(1).map((action, index) => {
                        return (
                            <Link
                                to={`${process.env.PUBLIC_URL}/${action.path}`}
                                key={index + 1}>
                                <span className="border rounded p-2 m-1 text-white bg-dark ps-2 pe-2">
                                    {action.actionName}
                                </span>
                            </Link>
                        );
                    })} */}
                </div>
                <Card className="p-2">
                <div className='border rounded border-danger p-2 mb-2'>
                                   
                                   <UL className='list-content '>
                                   <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Please add at least one branch first to be able to use this form`}</LI> 
                                       </UL>
                 
        
                               </div>
                               <div>
                      <Btn onClick={openQestionModalToggle} className='p-0 child_btn border rounded'   ><span  >Import Bulk Schedules</span></Btn>

                               </div>
                    <Formik
                        initialValues={scheduleFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={scheduleFormValidationSchema}>
                        {({ errors,values,setFieldValue }) => (
                            <SchedulesForm
                                submitErrors={submitErrors}
                                setSubmitError={setSubmitError}
                                errors={errors}
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Formik>
                </Card>
                <Popup
                title='Import Bulk Schedules'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup>
            </Container>
        </div>
    );
}

export default SchedulesMain;
