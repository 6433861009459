import React, { useState } from "react";
import Popup from "../../../../Component/MasterPopup/Popup";
import { ErrorMessage, FieldArray, Formik } from "formik";
import {
    FequentlyinitialValues,
    FequentlyValidationSchema,
    scheduleFormInitialValue,
    scheduleFormValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { Button, Col, Form, Label, Row } from "reactstrap";
import State from "../../../../Component/CommonInput/State";
import City from "../../../../Component/CommonInput/City";
import Branches from "../../../../Component/CommonInput/Branches";
import TitleInput from "../../../../Component/CommonInput/TitleInput";
import DescriptionInput from "../../../../Component/CommonInput/DescriptionInput";
import { Btn } from "../../../../AbstractElements";
import FequentlyModalForm from "./FequentlyModalForm";
import SharingSection from "../../../../Component/Tools/BrandSupportTools/SurveyAndQuizzes/SharingSection";

function AddFaqForm({ values, errors, setFieldValue }) {
    const [submitErrors, setSubmitError] = useState<boolean>(false);

    const [openQestionModal, setOpenQestionModal] = useState(false);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);

    return (
        <Form className="form-wizard">
            <Row>
                <Col sm="4" className="mb-3">
                    <TitleInput
                        title="Unique Title"
                        values={values?.title}
                        name="title"
                        placeholder="Unique Title"
                    />
                </Col>
                <FieldArray
                    name="questions"
                    render={(arrayHelpers) => (
                        <>
                            {values?.questions.map((_, index) => (
                                <Row key={index}>
                                    <Col sm="6" className="mb-3">
                                        <Label>{`Question ${index + 1}`}</Label>
                                        <DescriptionInput
                                            name={`questions.${index}.question`}
                                            placeholder={`Question ${
                                                index + 1
                                            }`}
                                            // label={`Question ${index + 1}`}
                                        />
                                    </Col>
                                    <Col sm="6" className="mb-3">
                                        <Label>{`Answer ${index + 1}`}</Label>

                                        <DescriptionInput
                                            name={`questions.${index}.answer`}
                                            placeholder={`Answer ${index + 1}`}
                                            label={`Answer ${index + 1}`}
                                        />
                                    </Col>
                                    <Col sm="12" className="mb-3">
                                        <Button
                                            color="danger"
                                            type="button"
                                            onClick={() =>
                                                arrayHelpers.remove(index)
                                            }
                                            disabled={
                                                values.questions.length === 1
                                            }
                                        >
                                            Remove Question
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col sm="12" className="mb-3">
                                    <Button
                                        color="success"
                                        type="button"
                                        onClick={() =>
                                            arrayHelpers.push({
                                                question: "",
                                                answer: "",
                                            })
                                        }
                                    >
                                        Another Question
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                />
            </Row>
            <SharingSection
                CoManagement={false}
                InviteeList={true}
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
            />

            <Popup
                title="Publish Online"
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                BodyClassName="pt-1"
                largesize="modal-custom"
            >
                <Formik
                    initialValues={FequentlyinitialValues}
                    validationSchema={FequentlyValidationSchema}
                    onSubmit={(values) => {
                        console.log("Selected values:", values);
                    }}
                >
                    {({ handleChange, values, errors, setFieldValue }) => (
                        <FequentlyModalForm
                            selectField="Conference"
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            values={values}
                            submitErrors={submitErrors}
                            errors={errors}
                        />
                    )}
                </Formik>
            </Popup>
            <Row>
                <Col sm="auto" className="mb-3">
                    <Btn
                        color="primary"
                        type="submit"
                        onClick={() => setSubmitError(true)}
                    >
                        Save as a Draft
                    </Btn>
                </Col>
                <Col sm="auto" className="mb-3">
                    <Btn color="primary" onClick={openQestionModalToggle}>
                        Save and Publish Online
                    </Btn>
                </Col>
            </Row>
        </Form>
    );
}

export default AddFaqForm;
