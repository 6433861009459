import { Row, Col, Label, Input, FormGroup } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { ErrorMessage, Field, Form } from "formik";
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput";
import Sharing from "../SurveyAndQuizzes/Sharing";
import TitleInput from "../../../CommonInput/TitleInput";
import DescriptionInput from "../../../CommonInput/DescriptionInput";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import Groups from "../../../CommonInput/Groups";
import { useState } from "react";
import GroupMain from "../../../Communication/GroupMain";
import SharingSection from "../SurveyAndQuizzes/SharingSection";

const AddManageExternalLinkForm = (props: any) => { 

  const { errors, submitErrors, setSubmitError, values, setFieldValue } = props;
    const [openFromModal, setOpenFromModal] = useState(false);
        const [reloadGroupsData, setReloadGroupsData] = useState(0);
    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
    const reloadGroups = () => {
      setReloadGroupsData((prev) => prev + 1);
      console.log(reloadGroupsData);
    }
  return (
    <Form className="form-wizard">
      <Row className="mb-3">
        <Col sm="4">  

<TitleInput title='Unique Title of your links' values={values.couponName} name="title"  placeholder="Enter title of your links"/>  

        </Col>
        <Col sm="4">
        <DescriptionInput  name='description'  className={`${
              submitErrors &&
              `${errors.description ? "is-invalid" : "is-valid"}`
            }`} placeholder="Enter Opinion/Description" values={values.description} lable='Opinion/Description'/>
         
        </Col>
        <Col sm="4">
          <Label check>Keywords (optional)</Label>  
          <TagsInput name="keywords" className={`${
              submitErrors &&
              `${errors.keywords ? "is-invalid" : "is-valid"}`
            }`} placeholder="Add keywords" setFieldValue={setFieldValue} />
            <ErrorMessage
            name="keywords"
            component="span"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="4">
          <Label check>
            External Links <span className="txt-danger">*</span>
          </Label>
          <Field
            name="externallink"
            type="text"
            placeholder="Enter your external link"
            className={`form-control ${
              submitErrors && `${errors.externallink ? "is-invalid" : "is-valid"}`
            }`}
          />
          <ErrorMessage
              name="externallink"
              component="span"
              className="invalid-feedback"
            />
        </Col>
      </Row>
      <SharingSection OnlyInvitedUsers={true}  CoManagement={true}  errors={errors} setFieldValue={setFieldValue} values={values}  />

      
      <Row>
        <Col sm="auto" className="mb-3">
          <Btn color="primary" type="submit" onClick={() => setSubmitError(true)}>Add Links</Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default AddManageExternalLinkForm;
