import React, { useState } from "react";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";

import { Container } from "reactstrap";
import { Formik } from "formik";

import {
  AddAttendanceNewEvent,
  BrandTools,
  ManageAttendanceSystem,
} from "../../../../utils/Constant";
import {
  AddAttendanceNewEventInitialValues,
  AttendanceFormValidationProp,
  AttendanceNewEventFormValidationSchema,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import AddNewEvent from "./AddNewEvent";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SubMenu from "../../../Application/SubMenu/SubMenu";
import { ManagePostsTableAction } from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const AttendanceContainer = () => {
  const navigate = useNavigate();
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const handleSubmit = (values: AttendanceFormValidationProp) => {
    console.log("activity values", values);
    // api call and response
    setSubmitError(false);
    // temperary to know the submission of form
    alert("form submitted");
    // navigate(`${process.env.PUBLIC_URL}/activities-meetings`)
  };
  const location = useLocation();
  return (
    <div>
       <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                 <SubMenuPopup  currentPage="Add an Activity "/>
                </div>
       <Breadcrumbs
        mainTitle={`${AddAttendanceNewEvent} (${BrandTools})`}
        parent={ManageAttendanceSystem}
        mainParent="All Tools"
      />
            </div> 
      <Container className="card p-4 " fluid>
        {/* <Message message="Please add at least one branch first to be able to use this form." /> */}

        <Formik
          initialValues={AddAttendanceNewEventInitialValues}
          onSubmit={handleSubmit}
          validationSchema={AttendanceNewEventFormValidationSchema}
        >
          {({ errors, values, setFieldValue }) => (
            <AddNewEvent
              submitErrors={submitErrors}
              setSubmitError={setSubmitError}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default AttendanceContainer;
