import { Row, Col, Label } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { ErrorMessage, Field, Form } from "formik";
import TagsInput from "../../../CommonInput/TagsInput";
import TextCounter from "../../../CommonInput/TextCounter";
import TitleInput from "../../../CommonInput/TitleInput";
import DescriptionInput from "../../../CommonInput/DescriptionInput";

const AddManageMyAlbumsForm = (props: any) => {
  const { errors, submitErrors, setSubmitError, values, setFieldValue } = props;

  return (
    <Form className="form-wizard">
      <Row>
        <Col sm="4" className="mb-3">
        <TitleInput title='New Album Unique Name' values={values.albumName} name="albumName"  placeholder="New Album Unique Name"/>  
        </Col>
        <Col sm="4" className="mb-3">
        <DescriptionInput  name='description' placeholder="Description/Summary" values={values.description} lable='Description/Summary (optional)'/>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Keywords (optional)
          </Label> 
          <TagsInput name="keywords" placeholder="Add keywords" setFieldValue={setFieldValue} />
        </Col>
      </Row>
      <Row>
        <Col sm="auto" className="mb-3">
          <Btn
            color="primary me-3"
            type="submit"
            onClick={() => setSubmitError(true)}
          >
            Save
          </Btn>
          {/* <span className="btn btn-light btn-air-light ">Undo Changes</span> */}
        </Col>
      </Row>
    </Form>
  );
};

export default AddManageMyAlbumsForm;
