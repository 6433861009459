import { Col, Label, Row } from "reactstrap";
import AttendanceContainer from "../../../../Component/Tools/BrandSupportTools/AttendanceSystem/AttendanceContainer";
import CommonModal from "../../../../Component/Ui-Kits/Modal/Common/CommonModal";
import { Btn, H3, H6, Image, P } from "../../../../AbstractElements";
import { QRCode } from "react-qrcode-logo";
import { dynamicImage } from "../../../../Service";
import { useState } from "react";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
export interface MediaModalInterface {
    value?: boolean; // Optional default value
    setqrCodeModal?: (value: boolean) => void; // Optional default value
    dataId?: number; // Optional default value
    items?: { id: number; title: string }[]; // Optional default value
}
const QrcodeAttendance = ({ value, setqrCodeModal = () => {} }) => {
    const [open, setOpen] = useState(value);

    const { mobileView } = useAppSelector((state) => state.layout);
    const [qrFullSize, setQrFullSize] = useState(mobileView ? 200 : 1024);

    const onCloseModal = () => {
        setOpen(false);
        setqrCodeModal(false);
    };
    const downloadQR = () => {
        var canvas = document.getElementById("react-qrcode-logo");
        var url = canvas.toDataURL("image/png");
        var link = document.createElement("a");
        link.download = "QRCode.png";
        link.href = url;
        link.click();
    };
    return (
        <CommonModal
            isOpen={open}
            toggle={onCloseModal}
            modalBodyClassName="p-4"
            sizeTitle={
                mobileView
                    ? "My    1Webbb - 1webbb.com |"
                    : " My    1Webbb - 1webbb.com | 2 B with 1 Web"
            }
            size="lg"
            logo={true}
            description={true}>
            <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                <div className="modal-body justify-content-center">
                    <Row>
                        <Col md="12">
                            <div className="d-flex px-2 align-items-center align-items-sm-center">
                                <Image
                                    className="image-radius m-r-15 align-top"
                                    src={dynamicImage("user/user.png")}
                                    alt="user121"
                                    style={{ width: "49px" }}
                                />
                                <H3 className="d-flex ">Cfotos admin</H3>
                            </div>
                        </Col>
                        <Col md="12">
                        <div className="d-flex justify-content-between">
                            <H6 className="w-100  border-0 pt-2 text-warning">
                                {" "}
                                {` (https://1webbb.com/cfp-admin-images)`}
                            </H6>
                            <Btn
                                className="btn btn-primary"
                                onClick={downloadQR}>
                                Download
                            </Btn>
                        </div>
                            <hr />
                        </Col>
                        <div   className="d-flex justify-content-center px-0">
                            <div
                                className="p-2"
                                style={{ height: "290px", width: "250px" }}>
                                <QRCode
                                    logoImage={dynamicImage("logo/qr-logo.png")}
                                    logoWidth={qrFullSize / 6}
                                    logoHeight={qrFullSize / 6}
                                    logoOpacity={1}
                                    value="MyMediaCenter"
                                    size={qrFullSize}
                                    bgColor={"#ffffff"}
                                    style={{
                                        height: "auto",
                                        maxWidth: "100%",
                                        width: mobileView ? "90%" : "100%",
                                        backgroundColor: "#fff",
                                        border: "2px solid black",
                                    }}
                                />
                            </div>
                        </div>
                        <Col md="12" className="d-flex justify-content-between">
                            <Row>
                                <Col sm="12">
                                    
                                        <H3 className="mb-3 fw-bold d-inline-block">Activity Title:</H3> <span></span> Exam And
                                        Practical
                                     
                                </Col>
                                <Col sm="12">
                                    <H3 className="mb-3 fw-bold">
                                        Activity's Location
                                    </H3>

                                    <Row>
                                        <Col sm="4" className="mb-2">
                                            <Label check>
                                                <b>Country:</b> India
                                            </Label>
                                        </Col>
                                        <Col sm="4" className="mb-2">
                                            <Label check>
                                                <b>State:</b> Gujrat
                                            </Label>
                                        </Col>
                                        <Col sm="4" className="mb-2">
                                            <Label check>
                                                <b>City:</b> Surat
                                            </Label>
                                        </Col>
                                        <Col sm="6" className="mb-2">
                                            <Label check>
                                                <b>Full Address:</b> City Light
                                                Road RahulRaj mall
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <H3 className="textAlign-left mb-3 fw-bold">
                                            Dates and Hours
                                        </H3>
                                        <Col sm="4" className="mb-2">
                                            <Label check>
                                                <b>Activity's Frequency:</b> One
                                                Time
                                            </Label>
                                        </Col>

                                        <>
                                            <Col sm="4" className="mb-2">
                                                <Label check>
                                                    <b>Start Date:</b> 10 Sep
                                                </Label>
                                            </Col>
                                            <Col sm="4" className="mb-2">
                                                <Label check>
                                                    <b>Expiry Date:</b> 20 Sep
                                                </Label>
                                            </Col>
                                        </>
                                    </Row>
                                </Col>
                            </Row> 
                        </Col>
                       
                    </Row>
                </div>
            </div>
        </CommonModal>
    );
};

export default QrcodeAttendance;
