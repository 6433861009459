import { Card,  CardBody,  Col,  Container,  Input,  Row } from 'reactstrap'
import { Btn, H3, H4, H5,  H6,  Image, P } from '../../../../AbstractElements'
import { dynamicImage } from '../../../../Service'
import { PropsType } from '../../../../Types/Application/SocialApp/SocialApp'
import { Link, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks'
import { useState } from 'react';
import { IoClose } from 'react-icons/io5'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import { QRCode } from 'react-qrcode-logo';
import CommonModal from '../../../Ui-Kits/Modal/Common/CommonModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import "bootstrap-icons/font/bootstrap-icons.css";
import Popup from '../../../MasterPopup/Popup'
import BookAppointment from '../Appointment/BookAppointment'
import { closeModal, openModal, setActiveItem } from '../../../../ReduxToolkit/Reducers/Navigation/Navigation'
import { MenuList } from '../../../../Data/LayoutData/SidebarData'

export const navData = [
    { id: 1, tittle: 'About', size:1 , mobile_size:3, color:"bg-primary" , path:'about', icon:<FontAwesomeIcon icon={fas.faAddressCard} className='pe-2'/>},
    { id: 18, tittle: 'Calendar', size:2 , mobile_size:4,color:"bg-warning", path:'my-media-center/calendar', icon:<FontAwesomeIcon icon={fas.faCalendarDays} className='pe-2'/>},
    { id: 4, tittle: 'Online Store', size:2 , mobile_size:4,color:"bg-warning", path:'online-store', icon:<FontAwesomeIcon icon={fas.faShop} className='pe-2'/>},
    { id: 5, tittle: 'Coupons' , size:1,mobile_size:3, color:"bg-primary", path:'my-coupons', icon:<FontAwesomeIcon icon={fas.faMoneyCheckDollar} className='pe-2'/>},
    {id: 8,  tittle: 'My Posts' , size:2, mobile_size:5,color:"bg-secondary", path:'my-media-center/posts', icon:<FontAwesomeIcon icon={fas.faSignsPost} className='pe-2'/>},
    {id: 17, tittle: 'From My Web Masters' , size:3, mobile_size:7, color:"bg-primary", path:'from-my-media-masters', icon:<i className="bi bi-postage-heart me-1 pe-2"></i>},
    { id: 2, tittle: 'Photos', size:1 , mobile_size:3, color:"bg-success", path:'photos', icon:<FontAwesomeIcon icon={fas.faFileImage} className='pe-2'/>},   
    {id: 8,  tittle: 'Photo Contests' , size:2, mobile_size:5,color:"bg-secondary", path:'my-media-center/photo-contests', icon:<FontAwesomeIcon icon={fas.faTrophy} className='pe-2'/>},
    {id: 12,  tittle: 'Albums', size:1 , mobile_size:3, color:"bg-primary", path:'albums', icon:<FontAwesomeIcon icon={fas.faImages} className='pe-2'/>},    
    {id: 13,  tittle: 'Visual Ideas', size:1 , mobile_size:3,color:"bg-primary", path:'visual-ideas', icon:<i className='bi bi-journal-plus pe-2'/>},
    {id: 11, tittle: 'Likes', size:1, mobile_size:3,color:"bg-primary", path:'likes' , icon:<FontAwesomeIcon icon={fas.faHeart} className='pe-2'/>},
    {id: 15, tittle: 'Reviews  ', size:1, mobile_size:5,color:"bg-primary", path:'reviews' , icon:<FontAwesomeIcon icon={fas.faComment} className='pe-2'/>},
    {id: 6, tittle: 'Activities and Events', size:2 , mobile_size:7,color:"bg-primary", path:'my-activities-and-events', icon:<FontAwesomeIcon icon={fas.faPeopleRoof} className='pe-2'/>},
    {id: 6, tittle: 'Frequently Asked Questions', size:2 , mobile_size:7,color:"bg-primary", path:'frequently-asked-questions', icon:<FontAwesomeIcon icon={fas.faCircleQuestion} className='pe-2'/>},
    {id: 7, tittle: 'Quizzes and Surveys', size:2 , mobile_size:6,color:"bg-primary", path:'quizzes-surveys', icon:<FontAwesomeIcon icon={fas.faClipboardQuestion} className='pe-2'/>},
    {id: 9,  tittle: 'Followers' , size:1, mobile_size:3,color:"bg-primary", path:'followers', icon:<FontAwesomeIcon icon={fas.faUsersRays} className='pe-2'/>},
    {id: 10,  tittle: 'I Follow', size:1, mobile_size:3,color:"bg-primary" , path:'i-follow', icon:<FontAwesomeIcon icon={fas.faPersonWalkingArrowRight} className='pe-2'/>},        
    {id: 14, tittle: 'I Downloaded' , size:1, mobile_size:5,color:"bg-primary", path:'i-downloaded', icon:<FontAwesomeIcon icon={fas.faCloudArrowDown} className='pe-2'/>},    
    {id: 16, tittle: 'External Links', size:2 ,mobile_size:5, color:"bg-primary", path:'external-links', icon:<FontAwesomeIcon icon={fas.faLink} className='pe-2'/>},
    {id: 3, tittle: 'Pending Approval', size:2, mobile_size:5, color:"bg-info", path:'pending-photos', icon:<FontAwesomeIcon icon={fas.faCircleHalfStroke} className='pe-2'/>},
  ]

const UserProfile = ({ callback }: PropsType) => {
  const { mobileView } = useAppSelector((state) => state.layout); 
  const [isOpen, setIsOpen] = useState(false);
  const [openisModal, setOpenisModal] = useState(false); 
  const [qrFullSize , setQrFullSize] = useState(mobileView ? 200 : 1024);
  const backgroundImage = '';
  const openModalToggle = () => setOpenisModal(!openisModal);
  const downloadQR =() =>{
    var canvas = document.getElementById("react-qrcode-logo");
    var url = canvas.toDataURL("image/png");
    var link = document.createElement('a');
    link.download = 'QRCode.png';
    link.href = url;
    link.click();
  }

  const openFileChoose =() =>{
    document.getElementById('upfile')?.click();
  }
  const location = useLocation();
  const currentPath = location.pathname;
 const dispatch = useAppDispatch();
    const menuPopupOpen =()=>{
      const data = MenuList[0]?.Items?.find((item) => item.title === "My 1Webbb Center");
      
      dispatch(setActiveItem(data))
      dispatch(openModal())
    }
    const menuPopupClose =()=>{
      dispatch(closeModal())
    }
  const isActive = (path) => {
    // Check if the current path starts with the path of the link
    return currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`);
  };
  const login = JSON.parse(localStorage.getItem("userInfo")!) ? JSON.parse(localStorage.getItem("userInfo")!) : false;

  return (
   
    // <Container fluid>
    <>
      <div className="user-profile" style={{marginBottom: mobileView ? '42%':''}}> 
        <Card className='mb-2'>
          <Row>
            <Col sm="12" className="box-col-12">
              <Card className="hovercard mb-0">
                <Row className='cardheader d-flex align-items-end'>
                  <Col md="12" lg="12">
                    <div className='d-flex ps-2 p-2 justify-content-center rounded-pill align-items-baseline' style={{marginTop: mobileView ? '25%':'0%'}} onClick={()=>openFileChoose()}  style={{marginLeft:'8%',backgroundColor:'rgba(0, 0, 0, 0.5)', width:'10%', cursor:'pointer'}}><FontAwesomeIcon color='black' icon={fas.faCamera}  className='p-1 bg-dark border rounded-circle align-item-center'/> <H4 className='text-white ps-2'>Change</H4></div>
                  </Col>
                  <Col md="12" lg="12" className='bg-primary' style={{marginTop: mobileView ? '25%':'0%'}}>
                    <Row className='py-2' >
                      <Col md="2" lg="1" sm="2" className='col-3'>
                        <div>
                          <Image className="rounded-circle image-radius m-r-15 align-top" src={dynamicImage("user/download-avatar.jpg")} alt="user121" style={{width:'89px'}}/>
                          <FontAwesomeIcon onClick={()=>openFileChoose()} color='black' icon={fas.faCamera}  className='p-1 bg-white border rounded-circle ms-4' style={{marginTop:'-2%'}}/>
                          <div className='d-none'><Input id='upfile' multiple type='file' onChange={(e) => onFileChange(e)} /></div>
                        </div>
                      </Col>
                      <Col md="7" lg="8" sm="6" className='col-9'>
                        <div className='flex-grow-1 ps-2'>
                          <H3 className="mb-0 f-w-700 text-white"><span style={{opacity:'1'}}>{`James Mark`}, <small>{`Designer`}</small></span></H3>
                          <P className='text-white'>{`B69 Near Schoool Demo Home`}</P>
                          {mobileView ? (
                            <P className='text-white'><span className='ms-1 me-1 text-white'><FontAwesomeIcon icon={fas.faHand} className='pe-2'/>{`I'm Available for Work`}</span> 
                           {login !== false ?  <Link to={`${process.env.PUBLIC_URL}/account-settings/manage-resume`}><span className='ms-1 me-1'><FontAwesomeIcon icon={fas.faIdBadge} className='pe-2'/>{`Manage Resume`}</span></Link>:""}</P>
                          ):(
                            <P className='d-flex text-white'><span className='ms-1 me-1 text-white text-decoration-underline'><FontAwesomeIcon icon={fas.faHand} className='pe-2'/>{`I'm Available for Work`}</span>
                            {login !== false ? <Link to={`${process.env.PUBLIC_URL}/account-settings/manage-resume`}><span className='ms-1 me-1 text-decoration-underline text-white'><FontAwesomeIcon icon={fas.faIdBadge} className='pe-2'/>{`Manage Resume`}</span></Link>:""}</P>
                          )}
                        </div>
                         
                      </Col>
                      <Col md="3" sm="3" className=' '>
                        <div className={` ${ mobileView ? 'text-center' : 'pull-right'}`} style={{marginTop:mobileView ? "" :"0%", height:'100', width:'80' }}>
                          <div className={`mt-0 mx-4 ${ mobileView ? '' : 'pull-right'}`} onClick={openModalToggle}>
                            <QRCode logoImage={dynamicImage("logo/qr-logo.png")} logoWidth={15} logoHeight={15} logoOpacity={1} value={`http://1webbb.com/qr-code-popup`} size={90} bgColor={'#ffffff'} style={{ height: "auto", maxWidth: "125px", width:"100%", backgroundColor:'#fff' , border:'2px solid black'}} />
                          </div>
                          
                          <CommonModal
                              isOpen={openisModal}
                              toggle={openModalToggle}
                              modalBodyClassName="p-0"
                              sizeTitle={mobileView ? 'My    1Webbb - 1webbb.com |':' My    1Webbb - 1webbb.com | 2 B with 1 Web'}
                              size='lg'      
                              logo={true}    
                              description={true}                    
                              >
                              <div className="modal-toggle-wrapper social-profile text-start dark-sign-up"  >
                                 
                                  <div className='modal-body p-2 justify-content-center'>
                                    <Row>
                                      <Col md="12">
                                        <div className='d-flex px-2 align-items-center align-items-sm-center'>
                                          <Image className="image-radius m-r-15 align-top" src={dynamicImage("user/user.png")} alt="user121" style={{width:'49px'}}/>                                    
                                          <H3 className='d-flex '>Cfotos admin</H3>
                                        </div>
                                      </Col>
                                      <Col md="12">
                                        <H6 className="w-100  border-0 pt-2 text-warning"> {` (https://1webbb.com/cfp-admin-images)`}</H6>
                                        <hr/>
                                      </Col>
                                      <Col md="12" className='d-flex justify-content-end'>
                                        <Btn className='btn btn-primary' onClick={downloadQR}>Download</Btn>
                                      </Col>
                                      <Col md="12" className='d-flex justify-content-center'>                                
                                        <div className='p-2' style={{height:'600px' , width:'600px'}}>
                                          <QRCode l
                                          ogoImage={dynamicImage("logo/qr-logo.png")} logoWidth={qrFullSize/10} logoHeight={qrFullSize/10} logoOpacity={1} value="MyMediaCenter" size={qrFullSize} bgColor={'#ffffff'} style={{ height: "auto", maxWidth: "100%", width: mobileView ? "90%":"100%", backgroundColor:'#fff', border:'2px solid black'}} />                        
                                        </div>  
                                      </Col>
                                    </Row>
                                  </div>

                              </div>
                          </CommonModal>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>                
              </Card>
            </Col>
          </Row>
        </Card>
        
      </div>
      <Card className='mb-0'>
        <CardBody className='py-0 px-1'>
        <Row>
          <Col sm="12" >
          <div className="timeline-content">
            <Row>                  
           
                  <>
                  <Col md="12" >
                  {isOpen ? (
                                        <IoClose size='2em' onClick={() =>menuPopupClose()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                                    ) : (
                                        <HiOutlineMenuAlt2 size='2em' onClick={() => menuPopupOpen()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                                    )} 
                    
                  </Col> 
                  </> 
            </Row>
          </div>
          </Col>
        </Row>
        </CardBody>
      </Card>
    </>
    // </Container>
  )
}

export default UserProfile