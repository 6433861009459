import React, {useState} from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { Btn, H3, SVG } from '../../../AbstractElements'
import { supportTicketData } from '../../../Data/Miscellaneous/SupportTicket/SupportTicket'
import { TableHeadColumn } from '../../../Pages/App/AccountSettings/MyRegistrationPlan'
import PageHeader from '../../Dashboard/Default/PageHeader/PageHeader'
import { GradeData } from '../../../Data/GradeSurvey/GradeData' 
import { ActionButtons } from '../../../Data/Tools/BrandSupportTools/ButtonActions'
import Popup from '../../MasterPopup/Popup' 
import { X } from 'react-feather'
import { questions } from '../../QuizzAnimation/QuizzTest'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export interface ActionButtonProps{
    action:number;
  }

 

 
export interface SupportDataType {
    id: number;
    title: string;
    score: Number;
    type: string;
    question: Number;
    answered: Number;
    unanswered: Number;
    correct: Number;
    mistake: Number;
    comment: string;
    date: string;
  }
  
  const MyjobstudyApplicationsContainer = () => {
    const [isOpen , setIsOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<SupportDataType | null>(null);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const toggleDelete = useState(false); 
    const handleRowSelected =() =>{}
    const openPopup = (row: SupportDataType) =>{
        setIsOpen(!isOpen); 
        setSelectedRow(row); // Store the clicked row's data
        
    }
    const openModalToggle = () =>{
        setIsOpen(!isOpen);
    }
    const GradeSurveyListColumns:TableColumn<SupportDataType>[] = [
           {
               name: <TableHeadColumn title={`S. No.`}/>,
               selector: (row) => row["id"],
               sortable: true,
               center: false,
           },
           {
               name: <TableHeadColumn title={`Post Type`}/>,
               cell: (row) => (
                   <Link to='/tools/brand-support-tools/manage-posts'
                       style={{cursor: "pointer",color:'blue' }}
                       
                   >
                       {row["title"]}
                   </Link>
               ),
               sortable: true,
               center: false,
           },
           {
               name: <TableHeadColumn title={`Post Title`}/>,
               selector: (row) => row["score"],
               sortable: true,
               center: false,
           },
           {
               name: <TableHeadColumn title={`Application Date/ Time`}/>,
               selector: (row) => row["date"],
               sortable: true,
               center: false,
           },
           {
               name: <TableHeadColumn title={`Current Status `}/>,
               selector: (row) => row["answered"],
               sortable: true,
               center: false,
           }, 
           {
               name:<TableHeadColumn title={`Actions`}/>,
               cell: (row) => <Link to='/tools/brand-support-tools/manage-posts' size='sm' color='warning' className='mx-1 px-2' onClick={() => openPopup("view")}><FontAwesomeIcon icon={fas.faEye} size={`sm`} /></Link>
               ,
               center: false,
               right:true,
           }
       ]
    return (
        <Container fluid>
        <Card>
            <CardHeader className='py-0 px-0'>
                    <PageHeader/>
                </CardHeader>
            <CardBody>
                <Row className=' py-3'>
                    <Col md="12" >
                        <div className="table-responsive">   
                        {/* <DataTable className='custom-scrollbar' columns={StudyMaterialColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />                      */}
                            <DataTable className='custom-scrollbar' columns={GradeSurveyListColumns} data={GradeData} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                        </div>
                    </Col>
                </Row>
                 
                        <Modal
  isOpen={isOpen}
  toggle={openModalToggle}
  modalBodyClassName="px-3"
  size= 'xl' 
  className=' modal-dialog  modal-dialog-scrollable modal-custom  modal-xl modal-dialog-centered'
//   backdrop={backdrop}
>
 
    {/* Header Section */}
    <div className="modal-header align-items-center">
      <H3 className="w-50 text-left">{selectedRow?.title || "Quiz Summary"}</H3>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex justify-content-between align-items-center w-75">
          <p className='mb-0'><strong>Username:</strong> {selectedRow?.username || "Guest"}</p>
          <p className='mb-0'><strong>Date:</strong> {selectedRow?.date || new Date().toLocaleDateString()}</p>
          <p className='mb-0'><strong>Start Time:</strong> {selectedRow?.startTime || "N/A"}</p>
        </div>
        <div onClick={openModalToggle} className="search-close-icon">
          <X />
        </div>
      </div>
    </div>

    {/* Body Section */}
    <ModalBody  >
      <div className="quiz-summary">
        <h4>Quiz Results</h4>
        <p><strong>Score:</strong> {  "N/A"}</p>
        <p><strong>Correct Answers:</strong> {  0}</p>
        <p><strong>Total Questions:</strong> {  0}</p>
        <p><strong>Unanswered:</strong> {   0}</p>
        <p><strong>Remarks:</strong> {  "Good job!"}</p>
        <div>
                <h2>Quiz Results</h2>
                {questions.map((question,num) => {
                  const selectedAnswer = selectedAnswers[question.id];
                  const correctAnswer = question.correct;
      
                  return (
                    <div key={question.id} className="question my-3">
                      <h5>{num + 1}{")"}.{' '}{question.text}</h5>
                      <div className='d-flex gap-2 mt-2'>
                        {question.options.map((option, index) => {
                          const isSelected = selectedAnswer === index;
                          const isCorrect = correctAnswer === index;
                          let buttonClass = 'btn ';
                          if (isSelected && !isCorrect) buttonClass += 'bg-danger'; // wrong answer selected
                          if (isCorrect) buttonClass += 'bg-success'; // correct answer
      
                          return (
                            <button
                              key={index}
                              className={buttonClass}
                              disabled
                            >
                              {option}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                {/* <button onClick={startGame} className="btn btn-primary">Restart</button> */}
              </div>
      </div>
    </ModalBody>

    {/* Footer Section */}
    {/* {CommonFooter && ( */}
      <ModalFooter >
        <div className="next_previous position-relative pt-0">
          <Btn  className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Previous <SVG iconId="back-arrow" />
            </div>
          </Btn>
          <Btn className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Next <SVG iconId="front-arrow" />
            </div>
          </Btn>
        </div>
      </ModalFooter>
    {/* )} */}
   
</Modal>
            </CardBody>
        </Card>
        </Container>
    )
}

export default MyjobstudyApplicationsContainer