import { useEffect, useState } from "react";
import { AccountCountry, FilterOption } from "../../utils/Constant";
import Country from "../CommonInput/Country";
import { ErrorMessage, Form } from "formik";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { useGetRegistrationPlanListQuery } from "../../ReduxToolkit/Reducers/Registration/Plan/planApliSlice";
import Filter from "../CommonInput/Filter";
import { useAppSelector } from "../../ReduxToolkit/Hooks";

function FilterForm(props: any) {
  const [skip, setSkip] = useState(true);
  const [filter, setFilter] = useState([]);

  const {
    errors,
    submitErrors,
    values,
    setPlanData,
    setIsLoading,
    setFieldValue,
    setCountryId,
  } = props;

  // call api for fetch RegistrationPlan data
  const { data: fetchData } = useGetRegistrationPlanListQuery(
    {
      country: values?.country,
      registrationType: filter?.length && filter,
    },
    { skip: skip }
  );

  // get default geo location data
  const { geoLocation, countries } : any = useAppSelector((state) => state.common);

  useEffect(() => {
    if (values?.country) {
      setIsLoading(true);
      setSkip(false);
      setCountryId(values?.country);
      fetchData !== undefined && setPlanData(fetchData);
      setIsLoading(false);
    }
  }, [fetchData, values, filter, setIsLoading, setPlanData]);

  useEffect(() => {
    const selectedCountry = countries.filter((item: any) => {
      return item.label === geoLocation?.countryName;
    });
    selectedCountry.length > 0 &&
      setFieldValue("country", selectedCountry[0]?.value);
  }, [countries, geoLocation]);

  return (
    <Form className="d-flex">
      <Col sm="4">
        <Label>{AccountCountry}</Label>
        <Country
          name="country"
          placeholder="Select"
          className={`${
            submitErrors && `${errors.country ? "is-invalid" : "is-valid"}`
          }`}
          isMulti={false}
        />
        <ErrorMessage
          name="country"
          component="span"
          className="invalid-feedback"
        />
      </Col>
      <Col sm="8" className="ps-1 ms-lg-4 ms-3">
        <FormGroup>
          <Label>{FilterOption}</Label>
          <Row>
            <Filter
              filter={filter}
              setFilter={setFilter}
              countryId={values?.country}
            />
          </Row>
        </FormGroup>
      </Col>
    </Form>
  );
}

export default FilterForm;
