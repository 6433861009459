import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { H4, Image, LI, P, UL } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { Link } from "react-router-dom";
import SearchInput from "../../../Application/SearchResult/SearchInput/SearchInput";
import { useState } from "react";
import { items } from "../../CouponsData/CouponsData";
import { Carousel } from "react-responsive-carousel";
import ActivityMeetingsModal from "./ActivityMeetingsModal";
// import { AttendanceFormValidationProp } from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { AttendanceSystemData } from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import { FaChevronDown, FaQuestionCircle, FaSearch } from "react-icons/fa";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
export const randomData = [
  { id: 1, src: "masonry/1.jpg" },
  { id: 2, src: "masonry/2.jpg" },
  { id: 3, src: "masonry/3.jpg" },
  { id: 4, src: "masonry/4.jpg" },
];
const branchOptions = [
  { value: "science", label: "Science" },
  { value: "math", label: "Mathematics" },
  { value: "history", label: "History" },
];

const faqOptions = [
  { value: "grading", label: "Grading System" },
  { value: "curriculum", label: "Curriculum Details" },
  { value: "admission", label: "Admission Process" },
];

// Validation Schema
 
interface FAQ {
  question: string;
  answer: string;
}
const FaqData = () => {
 
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState<string | undefined>();
  const [faqs] = useState<FAQ[]>([
    { question: 'How do I reset my password?', answer: 'You can reset your password by clicking on the "Forgot Password" link on the login page.' },
    { question: 'Where can I find my order history?', answer: 'Your order history is available under the "Orders" section in your profile.' },
    { question: 'How can I contact support?', answer: 'You can contact support via the "Contact Us" page or by calling our helpline.' },
  ]);

  // Toggle accordion item
  const toggle = (id: string) => {
    setOpen(open === id ? undefined : id);
  };

  // Filter FAQs based on the search query
  const filteredFAQs = faqs.filter(faq =>
    faq.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Card className="px-2">
      <SearchInput />
      
      <div>
      <Formik
        initialValues={{
          
          branch: "",
          faqTitle: "",
        }} 
        onSubmit={(values) => alert(JSON.stringify(values, null, 2))}
      >
        {({ values, handleChange }) => (
          <Form className="mt-3">
            <Row>
              {/* Branch Dropdown */}
              <Col md="6" className="mb-3">
                <FormGroup>
                  <Label>
                    Branch :
                  </Label>
                  <Field as="select" name="branch" className="form-control">
                    <option value="">Select Branch...</option>
                    {branchOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field> 
                </FormGroup>
              </Col>

              {/* FAQ Title Dropdown */}
              <Col md="6" className="mb-3">
                <FormGroup>
                  <Label>
                    Frequently Asked Question Title{" "}  :
                  </Label>
                  <Field as="select" name="faqTitle" className="form-control">
                    <option value="">Select FAQ...</option>
                    {faqOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field> 
                </FormGroup>
              </Col>

              
            </Row> 
          </Form>
        )}
      </Formik>
         {/* Top Section */}
         {/* <div className="faq-top bg-secondary rounded-2 text-white py-5 mt-3">
        <div className="container text-center">
          <h1 className="mb-3 text-white">How Can We Assist You?</h1>
          <p className="mb-4 text-white">Search for an answer from our frequently asked questions below.</p>
          <div className="d-flex justify-content-center">
            <Input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="me-2 w-50"
            />
            <Button color="primary">
              <FaSearch className="fs-5"/>
            </Button>
          </div>
        </div>
      </div> */}

      {/* FAQ Section */}
      <div className=" py-2"> 
        {filteredFAQs.length > 0 ? (
          <Accordion open={open} toggle={toggle} className="bg-primary">
            {filteredFAQs.map((faq, index) => (
              <AccordionItem key={index}>
                <AccordionHeader targetId={`${index}`} className="accordion-header bg-secondary p-0">
                  <div className="d-flex w-100 justify-content-between">
                    <div className='text-light'>
                      
                  <FaQuestionCircle className="me-2" />
                  {faq.question}
                    </div>
                  <FaChevronDown className="float-end" />
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId={`${index}`} className="accordion-bodyp-0">
                  {faq.answer}
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <p className="text-muted text-center">No FAQs match your search query.</p>
        )}
      </div>
    </div>
    </Card>
  );
};

export default FaqData;
