import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Country from "../../../Component/CommonInput/Country";
import { Col, FormGroup, Label, Row } from "reactstrap";
import CustomSelect from "../../../Component/CommonInput/CustomSelect";

// Define the form values interface
export interface FormValues {
  branchCountry: string;
  branchCountryFee: string;
}

// Initial form values
export const AdditionalCountryinitialValues: FormValues = {
  branchCountry: "",
  branchCountryFee: "",
};

// Validation schema
export const AdditionalCountryvalidationSchema = Yup.object({
  branchCountry: Yup.string().required("Branch Country is required"),
  branchCountryFee: Yup.string().required("Branch Country Fee is required"),
});
function AdditionalBranchCountry() {
    const branchCountries = ["USA", "Canada", "UK", "Australia"]; 
const branchCountryFees = [
    { value: "Listing 1", label: "Standard" },
    { value: "Listing 2", label: "Premium" },
    { value: "Listing 3", label: "VIP" },
  ];
  return (
    <Form className="form-wizard mb-5">
        <Row className="mb-5">
            <Col sm='4'>
          {/* Branch Country Dropdown */}
       
            <Label className="block">Additional Branch Country:</Label>
            <FormGroup floating>
            <Country
            name="branchcountry"
            placeholder="Select"
            isMulti={false}
            
          />
          <ErrorMessage
            name="branchcountry"
            component="span"
            className="invalid-feedback"
          />
          </FormGroup>
            </Col>
            <Col sm='4'>
          {/* Branch Country Fee Dropdown */}
          <div>
            <Label className="block">Branch Country Fee:</Label>
            {/* <Field as="select" name="branchCountryFee" className="border p-2 w-full">
              <option value="">Select Fee</option>
              {branchCountryFees.map((fee) => (
                <option key={fee} value={fee}>
                  {fee}
                </option>
              ))} */}
                <FormGroup floating>
          <Field
            className="custom-select"
            name="CategorySelect"
            options={branchCountryFees}
            component={CustomSelect}
            placeholder="Select"
            isMulti={true}
           
          />

        </FormGroup>
            {/* </Field> */}
            <ErrorMessage name="branchCountryFee" component="div" className="text-red-500" />
          </div>
            </Col>
        </Row>


      
        </Form>
  )
}

export default AdditionalBranchCountry