import React, { useEffect, useState } from "react";
import { Btn, H4, H5, H6, LI, UL } from "../../../AbstractElements";
import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { Field, Formik } from "formik";
import {
    AddNewIdeaStoryAlbum,
    AttributionRequired,
    Keywords,
    StartNewUpload,
    UndoChanges,
} from "../../../utils/Constant";
import CommonSwitchSpan from "../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import CardHeaderCommon from "../../BonusUi/CardHeaderCommon/CardHeaderCommon";
import Dropzone from "react-dropzone-uploader";
import Popup from "../../MasterPopup/Popup";
import {
    manageAlbumFormInitialValue,
    ManageAlbumFormValidationProp,
    manageAlbumFormValidationSchema,
} from "../../../Data/Tools/ManageContents/FormControlsValidation";
import AddManageMyAlbumsForm from "../../Tools/ManageContents/ManageMyAlbums/AddManageMyAlbumsForm";
import ProductBody from "../../Application/Ecommerce/AddProduct/ProductBody/ProductBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { X } from "react-feather";
import TagsInput from "../../CommonInput/TagsInput";
import CustomSelect from "../../CommonInput/CustomSelect";
import {
    createPostInitialValue,
    createPostSchema,
} from "../../../Data/Post/CreatePostValidationSchema";
import AddDetails from "./AddDetails";
import { setNavId } from "../../../ReduxToolkit/Reducers/AddProductSlice";
import { useAppDispatch } from "../../../ReduxToolkit/Hooks";  
import { useDescriptionvalidateDisregardsMutation, useValidateDisregardsMutation } from "../../../ReduxToolkit/Reducers/DisRegards/disregardsApiSlice";
import { toast } from "react-toastify";
import ServerErrorMessages from "../../CommonMessage/ServerErrorMessages";
import { useUploadPhotoMutation } from "../../../ReduxToolkit/Reducers/Upload/SocialMediaApiSlice";
import { SOCIAL_MEDIA_UPLOAD } from "../../../Api";
import AddAlbumModal from "./AddAlbumModal";
type ProductStockContentProps = {
    form?: string;
};
const ProductStockContent: React.FC<ProductStockContentProps> = ({ form }) => {
    const [openModal, setOpenModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false); 
    const [openPSModal, setOpenPSModal] = useState(false);
    const [albumType, setAlbumType] = useState("1");
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<any>();
    const openModalToggle = () => setOpenModal(!openModal);
    const openModalDetailToggle = () => setIsOpen(!isOpen);
    const [filesToUpload, setFilesToUpload] = useState<any[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState([]); // Track total uploads
    const [dailyUploadedFiles, setDailyUploadedFiles] = useState(0); // Track daily uploads 
    const [isLimitReached, setIsLimitReached] = useState(false);
    const [nextStep, setNextstep] = useState(false);
    const [responseData,setResponseData] = useState([])
    // Set your bulk upload limit
    const [uploadPhoto] = useUploadPhotoMutation();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    
    const dailyLimit = userInfo?.limits.social_media_daily_limit_for_upload_files || 0; // Daily upload limit from localStorage
    const bulkLimit = userInfo?.limits.bulk_limit_for_upload_files || 0;
    const fieSize = userInfo?.limits.social_media_single_file_size_allowed || 0;
    const heightSize = userInfo?.limits.social_media_min_image_height || 0;
    const widthSize = userInfo?.limits.social_media_min_image_width || 0;
     const [resposneAPi,setResponseApi] =useState() 
     const openGenralModalToggle = () => setOpenModal(!openModal);
  

// Reset daily uploads at midnight
useEffect(() => {
    const resetDailyUploads = () => {
      const now = new Date();
      const midnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        0,
        0,
        0
      );
      const timeUntilMidnight = midnight.getTime() - now.getTime();

      setTimeout(() => {
        localStorage.removeItem("uploadedFileCount"); // Clear the local storage count at midnight
        setDailyUploadedFiles(0); // Reset the local state
        setIsLimitReached(false); // Reset the limit reached flag
      }, timeUntilMidnight);
    };

    // Load the upload count from localStorage if it exists
    const storedCount = localStorage.getItem("dailyUploadedFiles");
    const count = storedCount ? parseInt(storedCount, 10) : 0;
    setDailyUploadedFiles(count);

    // Check if the limit is already reached
    if (count >= dailyLimit) {
      setIsLimitReached(true);
    }

    resetDailyUploads();
  }, [dailyLimit]);

  
  useEffect(() => {
    const storedFileCount = localStorage.getItem("uploadedFileCount");
    
    if (storedFileCount && parseInt(storedFileCount) >= 15) {
        console.log(storedFileCount);
        
      setIsLimitReached(true); // If 15 or more files are uploaded, set limit reached
    }
  }, []);
  
  // File upload handler
  const handleChangeStatus = async ({ meta, file }: any, status: string) => {
    if (status === "done") {
        const currentUploads = uploadedFiles.length;
        if (file.size < fieSize) { 
            console.error(
              `${meta.name} exceeds the maximum file size limit of 1MB.`
            );
            alert(`${meta.name} exceeds the maximum file size limit of 1MB.`);
            return;
          }
         // Validate file dimensions
    // const image = new Image();
    // image.src = URL.createObjectURL(file);
    // console.log(image.src);
    
    // image.onload = () => {
    //   const { width, height } = image;
    //   const maxWidth = 1920; // Maximum width in pixels
    //   const maxHeight = 1080; // Maximum height in pixels
    //   if (width > maxWidth || height > maxHeight) {
    //     console.error(
    //       `${meta.name} dimensions exceed the maximum allowed size of ${maxWidth}x${maxHeight}.`
    //     );
    //     alert(
    //       `${meta.name} dimensions exceed the maximum allowed size of ${maxWidth}x${maxHeight}.`
    //     );
    //     return;
    //   }

    //   // If all validations pass, proceed with upload
    //   console.log(`${meta.name} passed all validations.`); 
    // };
      if (currentUploads >= 15) {
        console.error("Daily upload limit reached!");
        setIsLimitReached(true);
        return; // Prevent further uploads
      }

      try {
        
        // Simulate API upload
        await handleUpload({ file, albumId: "123", attribution: "yes" });


         // Add the new file to the uploadedFiles array
      setUploadedFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, file]; // Add the new file 
        return updatedFiles; // Update the state with the new file array
      });
        
      if (currentUploads + 1 >= 15) {
        setIsLimitReached(true); // Set the limit reached flag
      }
      } catch (error) {
        console.error(`${meta.name} failed to upload`, error);
      }
    } else if (status === "removed") {
      // Remove the file from the list
      setFilesToUpload((prevFiles) =>
        prevFiles.filter((f) => f.meta.id !== meta.id)
      );
    }
  }; 
    
      const handleUpload = async ({ file , albumId, attribution }) => { 
          try {
            const response = await uploadPhoto({ file, albumId, attribution })  
            localStorage.setItem("uploadedFileCount", String(uploadedFiles.length));
            // Check if response has files and extract the file(s)
    const newFiles = response.data.data?.files;

    // If it's a single file object, wrap it in an array
    const filesToAdd = Array.isArray(newFiles) ? newFiles : newFiles ? [newFiles] : [];

    // Add the new files to the state
    setResponseData((prevFiles) => [...prevFiles, ...filesToAdd]); 
            setNextstep(response.data.success)
            if(response?.error?.data){ 
                
                setResponseApi(response.error.data.message)
            }
          } catch (error) {
            console.error('Upload failed:', error);
        } 
        console.log(responseData);
      };
   
   const handleSubmit = async (values: ManageAlbumFormValidationProp) => {
    console.log( values ); 
    
   };
   
    const SocialContentValidation = () => {};
 
    const dispatch = useAppDispatch()

    const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];
    return (
        <div className="px-4 py-4">
            <Row>
<Col>
<Col sm="4" className="mb-3">
                      <Label check>Select an Album <span className="text-danger">*</span></Label>
                      <div className="">
                    <FormGroup>
                        <Input type="select">
                            <option>{`Select`}</option>
                            <option>
                                Free for Commercial and Non-profit use
                            </option>

                            <option>Free for Non-Profit use only</option>
                            {/* <option>View Only. No right to download or use. </option>
                            <option>Display only on shopping pages. Not for social media usages</option> */}
                        </Input>
                        <span className="text-muted small">
                        <Btn className="ps-0  d-flex" onClick={openGenralModalToggle}>
                        <i className="bi bi-plus-circle mx-1 text-warning"></i>
           Add an Album 
          </Btn>
        </span>
                    </FormGroup>
                </div>
                      </Col> 
</Col>
<Col>
          
</Col>
            </Row>
            <Popup
                title={`Add Product`}
                openModalToggle={openModalToggle}
                isOpen={openPSModal}
                size={`xl`}
                backdrop={true}
                isClose={false}
                CommonFooter={false}
                >
                    
                <div className="p-3"> 
                    <ProductBody />
                </div>
            </Popup>
            <AddAlbumModal isOpen={openModal} title="Add an Album" openModalToggle={openGenralModalToggle}/>
            {/*   */}
            <Row className="py-2">
                <Col xxl="12">
                    <Formik
                        initialValues={{ email: "" }}
                        onSubmit={handleSubmit}
                        validationSchema={SocialContentValidation}>
                        {({ errors }) => (
                            <Form>
                                <FormGroup floating className={form}>
                                    <Input type="select">
                                        <option>{`Select`}</option>
                                        <option>
                                            Free for Commercial and Non-profit
                                            use
                                        </option>
                                        <option>
                                            Free for Non-Profit use only
                                        </option>
                                         <option>
                                    Premium Content, paid usage 
                                </option>
                                        {/* <option>View Only. No right to download or use. </option>
                                    <option>Display only on shopping pages. Not for social media usages</option> */}
                                    </Input>
                                    <Label>{`License and Right to Use Contents`}</Label>
                                </FormGroup>

                                <div
                                    className={`d-flex align-items-center ${form}`}>
                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                        <CommonSwitchSpan
                                            color={`primary`}
                                            defaultChecked
                                        />
                                    </div>
                                    <Label className="m-l-10" check>
                                        {AttributionRequired}
                                    </Label>
                                </div>

                                <Card className="mb-0">
                                    <CardBody className="pb-0">
            
  {isLimitReached ?  (
         <div style={{ padding: '20px', textAlign: 'center', color: 'red', border: '1px solid red', borderRadius: '10px' }}>
         <h3>Upload Limit Reached!</h3>
         <p>You have reached the daily limit of {dailyLimit} uploads or the bulk limit of {bulkLimit} uploads.</p>
        
       </div>
      ) : resposneAPi ?  
      <div style={{ padding: '20px', textAlign: 'center', color: 'red', border: '1px solid red', borderRadius: '10px' }}>
      {resposneAPi}
      </div>
       :  (  <Dropzone
          onChangeStatus={handleChangeStatus} 
          maxFiles={bulkLimit}  
          accept="image/*" 
          canCancel={false}
          multiple={true}
          inputContent="Drag and drop files or click to select"
          submitButtonText="Upload Files" 
          onDropRejected={() => console.log("File rejected")}  
        />)}
        
                                    </CardBody>
                                </Card>
                                <div className={form}>
                                    <Formik
                                        initialValues={createPostInitialValue}
                                        onSubmit={handleSubmit}
                                        validationSchema={createPostSchema}>
                                        {({
                                            errors,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <Form>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label check>
                                                                {`Category *`}:
                                                            </Label>
                                                            <FormGroup floating>
                                                                <Field
                                                                    className={`custom-select  `}
                                                                    name="CategorySelect"
                                                                    options={
                                                                        cityOptions
                                                                    }
                                                                    component={
                                                                        CustomSelect
                                                                    }
                                                                    placeholder="Category"
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <div className=" col-form-Label">
                                                                <Label check>
                                                                    {Keywords}:
                                                                </Label>
                                                                <TagsInput
                                                                    name="keywords"
                                                                    placeholder="Add keywords"
                                                                    className="w-100"
                                                                    setFieldValue={
                                                                        setFieldValue
                                                                    }
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                
                
                <div className="d-flex py-3 gap-2">
                                    {nextStep ? <Btn color="primary" type="button"  onClick={openModalDetailToggle}>
                                        {"Next Step (Add Details)"}
                                    </Btn>: '' }
                                    {/* <Btn color='secondary' className='mx-2'>{UndoChanges}</Btn> */}
                                    <Popup 
                                    title={'Add Details (step 2)'}
                                    openModalToggle={() => setIsOpen(!isOpen)}
                                    isOpen={isOpen}
                                    size={`xl`}
                                    backdrop={true}
                                    
                                     >
                                    <AddDetails heading={false} popup={true} responseData={responseData}/> 
                                    </Popup>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </div>
    );
};

export default ProductStockContent;
