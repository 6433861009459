import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { links } from "../../../Data/Tools/BrandSupportTools/ButtonActions";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { closeModal, openModal, setActiveItem } from "../../../ReduxToolkit/Reducers/Navigation/Navigation";
import { MenuList } from "../../../Data/LayoutData/SidebarData";

// Replace this with your actual links data

const SubMenu = () => {
  const { mobileView } = useAppSelector((state) => state.layout);

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current location

  const { websiteSettingsData } : any = useAppSelector((state) => state.common);
  const { userInfo }: any = useAppSelector((state) => state.auth);
  const { authUser }: any = (userInfo && userInfo) || {};

  const isActive = (path: any) => {
    return location.pathname.includes(`${process.env.PUBLIC_URL}/${path}`);
  };

  const menuNameMakeAsKey = (value: string) => {
    if (value !== undefined) {
      let updatedValue = value.toLocaleLowerCase();
      updatedValue = updatedValue.replace(/^.*\/([^\/]+)$/, "$1");
      updatedValue = updatedValue.replace(/\//g, "");
      return updatedValue;
    }
  };

  // make Menu Name as key.
  const menuAsKeyName = links.map((item: any) => {
    return menuNameMakeAsKey(item.path);
  });

  // get the filter disable.
  const disableMenu = menuAsKeyName.filter((item: any) => {
    return (
      (item !== undefined &&
        websiteSettingsData.frontend_tools[item] === "off") ||
      websiteSettingsData.frontend_tools[item] === "developer"
    );
  });

  // get the developer menu.
  const developerMenu = menuAsKeyName.filter((item: any) => {
    return websiteSettingsData.frontend_tools[item] === "off";
  });

  // if user is developer then not access off flag menu and if user is other then not access off and developer flag menu.
  let filterMenu;
  filterMenu =
    authUser !== undefined && authUser.roles.includes("Developer")
      ? links.filter((item: any) => {
          return !developerMenu.includes(menuNameMakeAsKey(item.path));
        })
      : links.filter((item: any) => {
          return !disableMenu.includes(menuNameMakeAsKey(item.path));
        });
const dispatch = useAppDispatch();
    const menuPopupOpen =()=>{
      const data = MenuList[0]?.Items?.find((item) => item.title === "Tools"); 
      dispatch(setActiveItem(data))
      dispatch(openModal())
    }
    const menuPopupClose =()=>{
      dispatch(closeModal())
    }
  return (
    <Container fluid>
      <Card className="mb-0">
        <CardBody className="pb-0 px-0 pt-0">
          <Row>
             
              <Col md="12">
              {isOpen ? (
                      <IoClose size='2em' onClick={() =>menuPopupClose()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  ) : (
                      <HiOutlineMenuAlt2 size='2em' onClick={() => menuPopupOpen()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  )}
              </Col> 
             
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default SubMenu;
