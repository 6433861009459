import { Card, Col, Container, Row } from 'reactstrap'
import NavClass from './NavClass/NavClass'
import { useAppDispatch } from '../../../ReduxToolkit/Hooks';
import TabClass from './TabClass/TabClass';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import { Apps, BrandTools, TasksHeading } from '../../../utils/Constant';
import { setActiveTab } from '../../../ReduxToolkit/Reducers/TasksSlice'; 
import SubMenu from '../SubMenu/SubMenu';
import SubMenuPopup from '../../../Layout/Sidebar/ManageStorePopup';

const TasksContainer = () => {
  const dispatch = useAppDispatch()
  const activeToggle = (tab: string) => {
    dispatch(setActiveTab(tab));
  };
  return (
    <>
     <div className="d-flex container-fluid align-items-center">
                <div className="px-2"> 
      <SubMenuPopup  currentPage="Tasks"/>
                </div> 
      <Breadcrumbs mainTitle={`${TasksHeading}(${BrandTools})`}  mainParent="All Tools" parent={BrandTools} />
            </div>
      <Container fluid>
        <div className="email-wrap bookmark-wrap">
          <Row>
          
            <NavClass activeToggle={activeToggle} />
            <TabClass />
          </Row>
        </div>
      </Container>
    </>
  )
}

export default TasksContainer