import React from 'react';
import { StudyMaterialAction } from '../../../Data/Tools/BrandSupportTools/ButtonActions';
import { Link, useLocation } from 'react-router-dom';
import { Btn } from '../../../AbstractElements';

const StudyMaterialSubMenu = ({skip}) => {
    const location = useLocation();
    const currentPath = location.pathname;

    // Function to determine if the current path matches or starts with the link's path
    const isActive = (path) => {
        // Construct the full path based on PUBLIC_URL and the provided path
        const fullPath = `${process.env.PUBLIC_URL}/${path}`;
        if (currentPath.startsWith(`${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/questionnaire-list/add-new`)) {
            return fullPath === `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/questionnaires-educational-games`;
        }
        // Return true only when the currentPath matches the full path exactly
        return currentPath === fullPath;
    };

    return (
        <div className='d-flex flex-wrap gap-2'>
            {StudyMaterialAction.map((material, index) => { 
                const activeClass = isActive(material.path) ? 'bg-gray text-light' : 'bg-dark text-light';
                if(index == skip){
                    return null
                }
                
                return (
                    <> 
                       
                    <Link 
                        to={`${process.env.PUBLIC_URL}/${material.path}`} 
                        key={index}
                        className={`border rounded p-2   ps-2 pe-2 ${activeClass}`}
                    >
                        <span>
                            {material.title}
                        </span>
                    </Link>
                    </>
                );
            })}
        </div>
    );
}

export default StudyMaterialSubMenu;
