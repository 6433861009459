import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import {
    FormGroup,
    Label,
    Input,
    Button,
    Row,
    Col,
    CardHeader,
    Card,
    Collapse,
} from "reactstrap";
import {
    FequentlyinitialValues,
    FequentlyValidationSchema,
    manageBranchFormInitialValue,
    manageBranchFormValidationSchema,
    scheduleFormInitialValue,
    scheduleFormValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import City from "../../../../Component/CommonInput/City";
import Country from "../../../../Component/CommonInput/Country";
import Branches from "../../../../Component/CommonInput/Branches";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { H3, LI, P, UL } from "../../../../AbstractElements";
import BranchSetupPrice from "../../../../Component/Application/Ecommerce/AddProduct/ProductBody/ProductTabContent/BranchSetupPrice";
import { useDispatch } from "react-redux";
import CustomSelect from "../../../../Component/CommonInput/CustomSelect";
import { ChevronDown, ChevronUp } from "react-feather";
import CommonModal from "../../../../Component/Ui-Kits/Modal/Common/CommonModal";
import GroupMain from "../../../../Component/Communication/GroupMain";
import TagsInput from "../../../../Component/CommonInput/TagsInput";
import Groups from "../../../../Component/CommonInput/Groups";
import SharingSection from "../../../../Component/Tools/BrandSupportTools/SurveyAndQuizzes/SharingSection";
function FequentlyModalForm({
    values,
    submitErrors,
    errors,
    selectField,
    setFieldValue,
}) {
    const options = [
        { value: "5", label: "30 Days, 5 USD" },
        { value: "12", label: "90 Days (4 USD/ 30 Days) if you pay 12 USD" },
        { value: "18", label: "180 Days (3 USD/ 30 Days) if you pay 18 USD" },
        { value: "24", label: "Annually (2 USD/ 30 Days) if you pay 24 USD" },
    ];
    const branchesOptions = [
        {
            label: "Branch 1",
            value: "branch1",
            country: "USA",
            state: "California",
            city: "Los Angeles",
        },
        {
            label: "Branch 2",
            value: "branch2",
            country: "USA",
            state: "Arizona",
            city: "Phoenix",
        },
        {
            label: "Branch 3",
            value: "branch3",
            country: "USA",
            state: "New York",
            city: "New York City",
        }
    ];
    const [openIndex, setOpenIndex] = useState<number | null>(null); // Track which card is open
    const [priceSection,setPriceSection]=useState([])
  const toggleCollapse = (index: number) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle collapse
  };
    const dispatch = useDispatch();
    const [selectedToolType, setSelectedToolType] = useState(selectField);
    const [forms, setForms] = useState([]);

    const handleAddBranch = () => { 
        
        setForms([...forms, scheduleFormInitialValue]); // Add a new form
    };
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]); 
  
    // Handle dropdown selection change
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
      setSelectedOptions(selectedValues);
  
      if (selectedValues.length > forms.length) {
        setForms([...forms, scheduleFormInitialValue]); // Add new form
      } else {
        setForms(forms.slice(0, selectedValues.length)); // Remove extra forms
      }
    };
    const handleRemoveBranch = (index: number) => {
        setForms(forms.filter((_, i) => i !== index)); // Remove the form at the specified index
    };
    const listings = [
      { value: "Listing 1", label: "Listing 1" },
      { value: "Listing 2", label: "Listing 2" },
      { value: "Listing 3", label: "Listing 3" },
    ];
    const [confirmedBranches, setConfirmedBranches] = useState();
    const [confirmedListing, setConfirmedListing] = useState([]);
    const [reloadGroupsData, setReloadGroupsData] = useState(0);
    const [openFromModal, setOpenFromModal] = useState(false);
    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
    const reloadGroups = () => {
        setReloadGroupsData((prev) => prev + 1);
        console.log(reloadGroupsData);
      }
    const handleConfirm = () => {

        if (Array.isArray(values.branches)) {
            // Match selected values with full branch details
            const selectedBranches = values.branches.map(branchValue =>
                listings.find(option => option.value === branchValue) // Find the branch
            ).filter(Boolean); // Remove undefined values

            setConfirmedBranches(selectedBranches);
        }
    };
    const handleConfirmsecond = () => {

        if (Array.isArray(values.listing)) {
          const selectedBranches = values.listing
            .map((listingValue) => {
              const match = listings.find(
                (option) =>
                  String(option.value).trim() ===
                  String(listingValue).trim()
              );
              return match;
            })
            .filter(Boolean); // Remove undefined values

          setConfirmedListing(selectedBranches);
        }
      };

    // const selectedListings =  [1,2];
    return (
        <>
            
            <Form className="form-wizard py-3 mx-2">
                <Row>
                    <Col sm="4">
                        {/* Activities and Events */}
                        <FormGroup>
                            <Label for="activitiesAndEvents">
                                Tool Type <span className="txt-danger">*</span>
                            </Label>
                            <Field
                                as={Input}f
                                type="select"
                                name="activitiesAndEvents"
                                id="activitiesAndEvents"
                                value={
                                    values.activitiesAndEvents || selectField
                                } // Ensure initial selection
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    setSelectedToolType(selectedValue);
                                    setFieldValue(
                                        "activitiesAndEvents",
                                        selectedValue
                                    ); // Update Formik field
                                }}>
                                <option value="events">Activities: events </option>
                                <option value="work">Activities: work shifts </option>
                                <option value="class">Activities: class rooms </option>
                                <option value="Conference">
                                    Frequently Asked Questions
                                </option>
                                <option value="lesson">Lessons</option>
                                <option value="Seminar">
                                    Questionnaires (educational games, quizzes,
                                    tests, and surveys)
                                </option>
                                <option value="photocontest">
                                    Photo Contests
                                </option>
                                <option value="article">
                                    Posts: Articles and Blogs
                                </option>
                                <option value="wish">
                                    Posts: Customer’s Wish of a Product or a
                                    Service
                                </option>
                                <option value="job">
                                    Posts: Job Opening: You want to hire
                                </option>
                                <option value="registration">
                                    Posts: Student Registration Period
                                </option>
                                <option value="product">
                                    Products and Services
                                </option>
                            </Field>
                        </FormGroup>
                    </Col>
                    {!(
                        selectedToolType === "article" ||
                        selectedToolType === "wish"
                    ) && (
                        <>
                            <Col md="4" className="mb-3">
                                <Label check>
                                    Branch Country{" "}
                                    <span className="txt-danger">*</span>
                                </Label>
                                <Country
                                    name="branchcountry"
                                    placeholder="Select"
                                    isMulti={false}
                                />
                                <ErrorMessage
                                    name="branchcountry"
                                    component="span"
                                    className="invalid-feedback"
                                />
                            </Col>

                            <Col sm="4" className="mb-3">
                                <Label check>
                                    City <span className="txt-danger">*</span>
                                </Label>
                                <City
                                    name="city"
                                    countryId={values.country}
                                    stateId={values.state}
                                    isMulti={true}
                                    placeholder="Select"
                                />
                                <ErrorMessage
                                    name="city"
                                    component="span"
                                    className="invalid-feedback"
                                />
                            </Col>
                            <P>Please add at least one branch first to be able to use this form</P>
                            <Col sm="4" className="mb-3">
                                <Label check>
                                    Branches{" "}
                                    <span className="txt-danger">*</span>
                                </Label>
                                <Branches
                                    name="branches"
                                    placeholder="Select"
                                    isMulti={true}
                                    option={branchesOptions}
                                />
                                <ErrorMessage
                                    name="branches"
                                    component="span"
                                    className="invalid-feedback"
                                />
                                <Button color="primary" className="mt-2" onClick={handleConfirm}>
                Confirm
            </Button>
            {confirmedBranches && confirmedBranches.length > 0 && (
                <div className="mt-2">
                   <p> <strong>Selected Branches:</strong></p>
                    {confirmedBranches.map((branch, index) => (
                        <p key={index}>
                            <strong>{branch.label}:</strong> {branch.country}, {branch.state}, {branch.city}
                        </p>
                    ))}
                </div>
            )}
                            </Col>
                            <Col sm="4" className="mb-3">
                                <Label check>
                                    Select listings to Publish Online{" "}
                                    <span className="txt-danger">*</span>
                                </Label> 
                                <Field
            className="custom-select"
            name="listing"
            options={listings}
            component={CustomSelect}
            placeholder="Select"
            isMulti={true}
          />
                                <ErrorMessage
                                    name="listing"
                                    component="span"
                                    className="invalid-feedback"
                                />
                                <Button color="primary" className="mt-2" onClick={handleConfirmsecond}>
                Confirm
            </Button>
            {confirmedListing && confirmedListing.length > 0 && (
                <div className="mt-2">
                   <p> <strong>Selected Listings:</strong></p>
                    {confirmedListing.map((listing, index) => (
                        <p key={index}>
                            <strong>{listing.label}:</strong> 
                        </p>
                    ))}
                </div>
            )}
                            </Col>
                        </>
                    )}
  
 
                </Row>
                <h4 className="my-2 fw-medium">1Webbb Balance and Service Fees:</h4>

                <div className="  p-2  mx-2 mb-0 my-3">
                    <UL className="list-content ">
                      
                        <LI>
                            <FontAwesomeIcon
                                icon={fas.faCircle}
                                className="pe-2"
                                size="xs"
                            />
                            {`Wallet Balance: 53.00 USD`}
                        </LI>
                        <LI>
                            <FontAwesomeIcon
                                icon={fas.faCircle}
                                className="pe-2"
                                size="xs"
                            />
                            {`Left Monthly Balance of FREE Submission: 1/5`}
                        </LI>
                        <LI>
                            <FontAwesomeIcon
                                icon={fas.faCircle}
                                className="pe-2"
                                size="xs"
                            />
                            {`Price (30 Days) for additional submission: 3 USD (for 3 additional submissions)`}
                        </LI>
                        <LI>
                            <FontAwesomeIcon
                                icon={fas.faCircle}
                                className="pe-2"
                                size="xs"
                            />
                            {`Left Balance of PAID Submission: 2/3`}
                        </LI>
                        <Formik
                            initialValues={manageBranchFormInitialValue}
                            // onSubmit={handleSubmit}
                            validationSchema={manageBranchFormValidationSchema}>
                            {({ errors, values, setFieldValue }) => (
                                <Form className="form-wizard ">
                                    <LI className="d-flex ">
                                        <FontAwesomeIcon
                                            icon={fas.faCircle}
                                            className="pe-2 mt-1"
                                            size="xs"
                                        />
                                        <Row className="w-100">
                                            <Col md="4" className="mb-3">
                                                <Label check>1Webbb Service Price:</Label>
                                                {/* <Label  check>Price  {!(selectedToolType === "article" || selectedToolType === "wish") &&  ('per 30 days - per branch') } :</Label> */}
                                                <Field
                                                    as="select"
                                                    name="amount"
                                                    className="form-control">
                                                    <option value="">
                                                        Select...
                                                    </option>
                                                    {options.map((option) => (
                                                        <option
                                                            key={option.value}
                                                            value={
                                                                option.value
                                                            }>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </Col>
                                        </Row>
                                    </LI>
                                </Form>
                            )}
                        </Formik>
                    </UL>
                </div>
                {confirmedListing && confirmedListing.length > 0 &&selectedToolType == "product" && <h4 className="my-2 fw-medium">Price Setup (of selected products and services):</h4>}
                {/* <Formik
  initialValues={{ CategorySelect: [] }}
  onSubmit={(values,e)=>{
    // e.preventDefault()
    console.log(values);
    
    // setPriceSection([priceSection,..values])
  }}
>
  {({ values, setFieldValue }) => {
    // console.log(values);
    // setPriceSection([priceSection,values])
    return (
      <Form >
        <Row>
<Col sm='4'>
        <Label className="mb-2 form-label"> = Base on Selected Item On the Top</Label>
        <FormGroup floating>
          <Field
            className="custom-select"
            name="CategorySelect"
            options={listings}
            component={CustomSelect}
            placeholder="Select"
            isMulti={true}
          />

        </FormGroup>
</Col>
        </Row>
        
         

        <Button type="submit">Confirm</Button>
      </Form>
    );
}}
</Formik> */}
{confirmedListing && confirmedListing.length > 0 &&selectedToolType == "product" && (
 confirmedListing.map((item: string, index: number) => (
    <> 
                                    <h4>{item.label}</h4>
                                    <Formik initialValues={scheduleFormInitialValue}  onSubmit={(values, { setSubmitting }) => {
                                                            
                                handleSubmit(values);
                                setSubmitting(false); // Stop submitting state
                                alert("Form submitted successfully!"); // Display success message
                            }}
                            validationSchema={
                                scheduleFormValidationSchema
                            }>
                            {({
                                errors,
                                setFieldValue,
                                handleSubmit,
                                values,
                                validateField,
                            }) => {
                                console.log("Current errors:", errors); // Log errors if any
                                console.log(
                                    "Current form values:",
                                    values
                                ); // Log form values
                                return (
                                    <Form
                                        className="form-wizard"
                                        onSubmit={handleSubmit}>
                                        {" "} 

                                        <BranchSetupPrice
                                            setFieldValue={
                                                setFieldValue
                                            }
                                            submitErrors={submitErrors}
                                            errors={errors}
                                            validateField={
                                                validateField
                                            }
                                            values={values}
                                        />
                                    </Form>
                                );
                            }}
                        </Formik>
        </>
                    )))}
                    <SharingSection CoManagement={true}  errors={errors} setFieldValue={setFieldValue} values={values} selectedToolType={selectedToolType}/>
                {/* <Row>
                    {![
                        "product",
                        "article",
                        "wish",
                        "class",
                        "work",
                        "job",
                        "registration",
                    ].includes(selectedToolType) && (
                        <Col sm="auto">
                            <FormGroup>
                                <div>
                                    <Label check>
                                        Who can open or use the link of this content?
                                    </Label>
                                </div>
                                <div className="d-flex flex-wrap gap-2 align-items-center">
                                    <div className="m-checkbox-inline px-2">
                                        <Label for={`edo-ani-a`} check>
                                            <Field
                                                className="radio_animated"
                                                id={`edo-ani-a`}
                                                type="radio"
                                                name="display_on_media_center"
                                                value={1}
                                                checked={
                                                    values?.display_on_media_center ==
                                                    "1"
                                                }
                                            />
                                            {` Anyone`}
                                        </Label>
                                    </div>
                                    <div className="m-checkbox-inline px-2 mt-1">
                                        <Label for={`edo-ani-b`} check>
                                            <Field
                                                className="radio_animated"
                                                id={`edo-ani-b`}
                                                type="radio"
                                                name="display_on_media_center"
                                                value={2}
                                                checked={
                                                    values?.display_on_media_center ==
                                                    "2"
                                                }
                                            />
                                            {`Only Followers and Invited Users`}
                                        </Label>
                                    </div>
                                    <div className="m-checkbox-inline px-2 mt-1">
                                        <Label for={`edo-ani-c`} check>
                                            <Field
                                                className="radio_animated"
                                                id={`edo-ani-c`}
                                                type="radio"
                                                name="display_on_media_center"
                                                value={3}
                                                checked={
                                                    values.display_on_media_center ==
                                                    "3"
                                                }
                                            />
                                            {`Only Invited Users`}
                                        </Label>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
                <h4 className="mb-2">Invitee List:</h4>
                    <Row>
                        <Col sm="12">
                            <FormGroup>
                                <FormGroup>
                                    <Groups
                                        title="Invite Groups"
                                        className={""}
                                        isRequired={false}
                                        name="groups"
                                        isMulti={true}
                                        reloadGroupsData={reloadGroupsData}
                                    />
                                </FormGroup>
                                <span>Create a New Group</span>
                                <span
                                    className="mt-1 btn btn-primary px-3 mx-2"
                                    onClick={openFromModalToggle}
                                >
                                    <i className="icofont icofont-users"></i>
                                </span>
                                <CommonModal
                                    isOpen={openFromModal}
                                    toggle={openFromModalToggle}
                                    sizeTitle="Add a Group"
                                    size="lg"
                                >
                                    <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                                        <GroupMain
                                            reloadGroups={reloadGroups}
                                            openFromModalToggle={
                                                openFromModalToggle
                                            }
                                        />
                                    </div>
                                </CommonModal>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <div className=" col-form-Label">
                                    <FormGroup className=" ">
                                        <Label check className="mb-1">
                                            Invite Usernames:
                                        </Label>
                                        <TagsInput
                                            name="individual_users"
                                            username={true}
                                            placeholder="Enter Invite Usernames"
                                            className={`form-control pt-0`}
                                            setFieldValue={setFieldValue}
                                        />
                                    </FormGroup>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col sm="6">
                            <FormGroup>
                                <Label for="fileInput">
                                Import Invitee List (optional) 
                                </Label>
                                <Input
                                    id="fileInput"
                                    name="file"
                                    type="file"
                                    className={`form-control ${
                                        errors.files && submitErrors
                                            ? "is-invalid"
                                            : "is-valid"
                                    }`}
                                    onChange={(event) => {
                                        if (event.target.files) {
                                            setFieldValue(
                                                "file",
                                                event.target.files[0]
                                            );
                                        }
                                    }}
                                    // invalid={!!(errors.file  )}
                                />
                            </FormGroup>
                        </Col>
                    </Row> */}
                

                <div className="text-right">
                    <Button type="submit" color="primary">
                        Submit
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default FequentlyModalForm;
