import { useState } from 'react'
import { BrandTools } from '../../../../utils/Constant'
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import StudyMaterialSubMenu from '../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import { questionFormInitialValue, questionFormValidationSchema } from '../../../../Data/Tools/BrandSupportTools/FormControlsValidation'
import { Field, Formik } from 'formik'
import TitleInput from '../../../../Component/CommonInput/TitleInput'
import DescriptionInput from '../../../../Component/CommonInput/DescriptionInput'

const AddQuizMaterial = () => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (values: any) => {
    console.log(values);
    setSubmitError(false);
  };

  return (
    <div className='page-body'>
      <Breadcrumbs mainParent="All Tools" mainTitle={`Add a Quiz Material (${BrandTools})`} parent={BrandTools} />
      <SubMenu />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className='my-2 ms-1'>
                <StudyMaterialSubMenu />
              </div>
              <Formik
                initialValues={questionFormInitialValue}
                onSubmit={handleSubmit}
                validationSchema={questionFormValidationSchema}
              >
                {({ errors, values, setFieldValue }) => ( // <-- Access setFieldValue here
                  <CardBody className='p-2'>
                    <Row>
                      <Col sm="4">
                        <TitleInput
                          title='Unique Quiz Material Title'
                          values={values.quizmaterialtitle}
                          name="quizmaterialtitle"
                          placeholder="Enter a Quiz Material Name"
                        />
                      </Col> 
                      <Col sm="4">
                        <DescriptionInput
                          name='quizmaterialdescription'
                          placeholder="Enter a Quiz Material Text"
                          values={values.quizmaterialdescription}
                          lable='Quiz Material Text'
                        />
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="audioFile">Audio Material</Label>
                          <input
                            id="audioFile"
                            name="audioFile"
                            type="file"
                            accept="audio/*"
                            className="form-control"
                            onChange={(event) => {
                              if (event.currentTarget.files) {
                                setFieldValue("audioFile", event.currentTarget.files[0]);
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="videoURL">Video URL</Label>
                          <Field
                            id="videoURL"
                            name="videoURL"
                            type="url"
                            placeholder="Enter video URL"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AddQuizMaterial;
