
import Popup from "./Cookies/Popup";
import Content from "./Announcements/Content";

function WebsiteSettings() {
  
  return (
    <>
      <Popup /> 
      <Content />
    </>
  );
}

export default WebsiteSettings;
