import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Use if using React Router
import CommonModal from "../../Component/Ui-Kits/Modal/Common/CommonModal";
import { MenuList } from "../../Data/LayoutData/SidebarData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Collapse, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, NavLink, Row } from "reactstrap";
import { workAvailabilityFormInitialValue, workAvailabilityFormSchema } from "../../Data/App/AccountSettings/MyResumesAddNew";
import { ErrorMessage, Field, Formik } from "formik";
import WorkAvailabilityForm from "../../Pages/App/AccountSettings/WorkAvailabilityForm";
import { resumesData } from "../../Pages/App/AccountSettings/MyResumes";
import Popup from "../../Component/MasterPopup/Popup";
import { Btn, H5 } from "../../AbstractElements";
import { FaTurnDown } from "react-icons/fa6";
import Notes from "../../Pages/Tools/Notes/Notes";
import { branchPublicContactFormInitialValue, branchPublicContactFormValidationSchema, FequentlyinitialValues, FequentlyValidationSchema } from "../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import CommonSwitchSpan from "../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import OnlineAssistantForm from "../../Component/App/AccountSettings/OnlineAssistant/OnlineAssistantForm";
import FequentlyModalForm from "../../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyModalForm";
import ImportBulk from "../../Pages/Tools/BrandSupportTools/ImportBulk/ImportBulk";
import BulkProductImages, { ProductvalidationSchema } from "../../Pages/Tools/ManageOnlineStore/ManageBranches/ManageProductServices/BulkProductImages";
import Country from "../../Component/CommonInput/Country";
import State from "../../Component/CommonInput/State";
import City from "../../Component/CommonInput/City";
import Branches from "../../Component/CommonInput/Branches";
import AddAlbumModal from "../../Component/App/UploadData/AddAlbumModal";
import AddVisualIdeaModal from "../../Component/App/UploadData/AddVisualIdeaModal";
import AddQuestionGroupModal from "../../Component/Tools/BrandSupportTools/Questions/AddQuestionGroupModal";
import AddQuizMaterial from "../../Component/Tools/BrandSupportTools/Questions/AddQuizMaterial";
import AddCourseandTeacherInformation from "../../Pages/Tools/BrandSupportTools/CourseandTeacherInformation/AddCourseandTeacherInformation";
import AddSurveyAndQuizzesForm from "../../Component/Tools/BrandSupportTools/SurveyAndQuizzes/AddSurveyAndQuizzesForm";
import { surveyAndQuizzesFormInitialValue, TabsurveyAndQuizzesFormValidationSchema } from "../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { faAngleDown, faChevronDown, faChevronUp, fas } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { closeModal } from "../../ReduxToolkit/Reducers/Navigation/Navigation";

interface MenuItem {
    title: string;
    path?: string; // URL for direct navigation
    children?: MenuItem[];
}
 
const NavbarPopup: React.FC = () => {
      const dispatch = useAppDispatch();
    const activeItem = useAppSelector((state) => state.navigation.activeItem);
    const modalOpen = useAppSelector((state) => state.navigation.modalOpen);
    const [isModalOpen, setIsModalOpen] = useState(false || modalOpen);
   
    const [selectedParent, setSelectedParent] = useState<MenuItem | null>(null);
    const [selectedChild, setSelectedChild] = useState<MenuItem | null>(null);
    const [selectedSubChild, setSelectedSubChild] = useState<MenuItem | null>(null); 
      const [openModal, setOpenModal] = useState(false);
      const [modalType, setModalType] = useState(false);
       const [submitErrors, setSubmitErrors] = useState(false);
    const [activeParent, setActiveParent] = useState(null); 
    const [Cards,setCards]=useState(null)
    const navigate = useNavigate();
    const location = useLocation();
    const [selectParentTab,setselectParentTab]= useState(null);
    // Modal related state
    const [showBusinessBranch, setShowBusinessBranch] = useState(true);
        const [showBusinessLocation, setShowBusinessLocation] = useState(true);
        const [showBranchPhotoLocation, setShowBranchPhotoLocation] = useState(true); 
        useEffect(() => {
            setIsModalOpen(modalOpen);
            setSelectedParent(activeItem)
          }, [modalOpen]);
    useEffect(() => {
        // Auto-select the submenu item if the current page matches its path
        MenuList.flatMap((menu) =>
            menu.Items.flatMap((item) =>
                item.children?.forEach((child) => {
                    if (child.path === location.pathname) { 
                        
                        setSelectedChild(child);
                    }
                })
            )
        );
        const foundParent = MenuList.flatMap((menu) => menu.Items).find(
            (item) =>
                item.children?.some((child) => location.pathname === child.path)
        );

        if (foundParent) {
            setActiveParent(foundParent);
        }
    }, [location.pathname]);
    useEffect(() => {
        if (selectedParent?.children?.length > 0) {
          setSelectedSubChild(selectedParent.children[0]); // Set first child as default
        }
      }, [selectedParent]);
    useEffect(() => {
        // Find the parent item based on the current URL
        let foundParent = null;
        MenuList.forEach((menu) => {
          menu.Items.forEach((item) => {
            if (
              item.children &&
              (item.children.some((child) => location.pathname === child.path) ||
                item.children.some(
                  (child) =>
                    child.Items &&
                    child.Items.some((subChild) => location.pathname === subChild.path)
                ))
            ) {
              foundParent = item;
            }
          });
        });
    
        if (foundParent) {
          setSelectedParent(foundParent);
        }
      }, [location.pathname]);
    const handleParentClick = (parent: MenuItem) => { 

        setSelectedParent(parent); // Show only this parent's submenu
        setselectParentTab(parent)
    };
    const handleChildClick = (child: MenuItem) => { 

        if (child.Items && child.Items.length > 0) {
            setSelectedSubChild(child); // Show third-level menu
            setSelectedChild(child);
        } else {
            setSelectedChild(child);
            setIsModalOpen(false);
            dispatch(closeModal());
            navigate(child.path!);
        }
    };
// Modal handler
const openModalToggle= () => {
    setOpenModal(!openModal) 
}
// submit handler
const handleSubmit =()=>{ 
    
}
const orderMapping = {
    'My 1Webbb Center': 1,
    'Account Settings': 2,
    'Communication': 3,
    'Dashboard': 4,
    'Knowledge': 5,
    'Posts': 6,
    'Tools': 7
};
const toggleCollapse = (index) => {
    setCards(prev => prev === index ? null : index);
};
    return (
        <>
        <div className=" menu_wraper w-100">
            {/* Navbar Section */}
            <nav className="nav w-100 bg_light  p-2">
                {MenuList.map((menu) =>
                    menu.Items.map((item) => {
                        const isActive =
                        location.pathname === item.path ||
                                (item.children && item.children.some((child) => location.pathname === child.path)) ||
                                (item.children && item.children.some((child) => child.Items && child.Items.some((subChild) => location.pathname === subChild.path)))
                        const isParentActive = activeParent?.id === item.id; 
                        if(item.title == 'Dashboard' || item.title == 'Account Settings' ) return null
                        return (
                            <button
                                key={item.id}
                                className={`btn me-0 me-xxl-2 px-1 px-xxl-2 nav-item text-white ${
                                    isActive
                                        ? "btn-primary text-white"
                                        : " "
                                }`}
                                onClick={() => {
                                    if (
                                        item.children &&
                                        item.children.length > 0
                                    ) {
                                        // If children exist, open modal
                                        setIsModalOpen(true);
                                        handleParentClick(item);
                                    } else if (item.path) {
                                        // If no children, redirect to the item's path
                                        navigate(item.path);
                                    }
                                }}
                            >
                                <NavLink to={item.path} className={`px-2 ${isActive ? " text-white" : " "}`}>
                                    {item.icon && (
                                        <FontAwesomeIcon icon={item.icon} />
                                    )}{" "}
                                   <span className="ms-2 fw-semibold">
                                   {item.title}
                                   {item.children && <span className="ms-1 ms-xxl-2"><FontAwesomeIcon icon={faAngleDown}/></span> }
                                    </span> 
                                </NavLink>
                                
                            </button>
                        );
                    })
                )}
            </nav>

            {/* Modal Popup */}
            <CommonModal
                isOpen={isModalOpen}
                toggle={() => {
                    setIsModalOpen(false);
                    dispatch(closeModal());
                    setSelectedParent(null);
                    setSelectedChild(null);
                }}
                largesize="modal_md_size"
                sizeTitle="Navigation Menu"
                AdShow={false}
                // size="md"
            >
                <div className="row">  
                    {/* Second Column: Show submenu only for the selected parent */}
 
                    <div className={`col-md-${selectedSubChild ?  12: 6} border-end `}>
                     
                                                                            
 <Formik
  initialValues={{ activitiesAndEvents: "" }}
  onSubmit={(values) => {
    console.log("Selected values:", values);
  }}
>
  {({ setFieldValue, values }) => (
    <Form>
        <Row>
            <Col sm="12">
       
        <Label for="activitiesAndEvents">
        Select a Purpose <span className="txt-danger">*</span>
        </Label>
        <Field
          as={Input}
          type="select"
          name="activitiesAndEvents"
          id="activitiesAndEvents"
          onChange={(e) => {
            const selectedValue = e.target.value; 
            // Find the selected item object
            let selectedItem = null;
            MenuList.forEach((menu) => {
              menu.Items.forEach((item) => {
                if (item.title === selectedValue) {
                  selectedItem = item;
                }
              });
            });

            setFieldValue("activitiesAndEvents", selectedValue); // Update Formik state
            setSelectedParent(selectedItem); // Set the entire item object
            if (selectedItem?.children?.length > 0) {
                setSelectedSubChild(selectedItem.children[0]); // ✅ Default to first child
              } else {
                setSelectedSubChild(null); // Reset if no children exist
              }
          }}
          value={selectedParent?.title || values.activitiesAndEvents}
        >
          <option value="">-- Select --</option>
          {MenuList.map((menu) =>
            menu.Items.map((item) => {
              if (!item.children) return null;
              const isActive =
                selectedParent?.id === item.id || // Highlight user-selected option
                (!selectedParent &&
                    (location.pathname === item.path ||
                    (item.children &&
                        item.children.some((child) => location.pathname === child.path)) ||
                    (item.children &&
                        item.children.some(
                        (child) =>
                            child.Items &&
                            child.Items.some((subChild) => location.pathname === subChild.path)
                        ))));
              return (
                <option
                  key={item.title}
                  value={item.title}
                  style={{ order: orderMapping[item.title] || 999 }}
                  className={`${ isActive ? "xxxx btn-primary text-white":''}`}
                >
                  {item.title}
                </option>
              );
            })
          )}
        </Field> 
      </Col>
      </Row>
      {/* Display children outside of <select> */}
      {selectedParent?.children && (
  <ul className="list-group flex-row mb-2 mt-2 mx-1">
    {selectedParent.children.map((child,index) => {
    const isChecked =
    selectedSubChild?.id === child.id || 
    (!selectedSubChild && index === 0);
      return selectedParent?.title === "Tools" ? (
        <li key={child.id} className="list-group-item  ">
            <div className="form-check form-check-inline radio radio-primary  px-2">
            <Input
                id={`checkbox-${child.id}`}
                type="radio"
                name="posttype"
                onChange={() => setSelectedSubChild(child)}  
                checked={isChecked}
                className="ms-0"
            />
            <Label
                className="mb-0"
                htmlFor={`checkbox-${child.id}`}
                >
                <h6>
                {child.icon && <FontAwesomeIcon icon={child.icon} />}{" "}
                {child.title}
                </h6>
            </Label>
        </div>
          {/* <div className="form-check w-100 d-flex">
            <Input
              id={`checkbox-${child.id}`}
              type="radio"
              name="posttype"
              onChange={() => setSelectedSubChild(isChecked ? null : child)}
              checked={isChecked}
              className="ms-0"
            />
            <Label className="mb-0" htmlFor={`checkbox-${child.id}`}>
              <h6>
                {child.icon && <FontAwesomeIcon icon={child.icon} />}{" "}
                {child.title}
              </h6>
            </Label>
          </div> */}
        </li>
      ) : null; // If title is "Tools", return null
    })}
  </ul>
)}
    </Form>
  )}
</Formik>
                        </div>
                    {selectedParent?.children && selectedParent.title !== "Tools" &&(
    <div className={`col-md-${selectedSubChild ?  12: 6} border-end mb-2 `}>
        
        <ul className={`  d-flex ${selectedParent.title === "My 1Webbb Center" ? 'flex-row flex-wrap' :'flex-column'}  gap-1 `}>
            
            {  
                selectedParent.children.map((child) =>{ 
                    const isActive =
                                location.pathname === child.path 
                                // ||
                                // (child.children && child.children.some((child) => location.pathname === child.path));
                                // console.log(location.pathname,item.path,isActive);
                                if(selectedParent.title === "Tools") return null
                                
                    return(
                    // <li key={child.id} className={` list-group-item text-start mb-1 `}>
                        <button
                        className={`btn text-start py-1 ${
                            selectedParent.title === "My 1Webbb Center"
                              ? selectedParent.children.some((c: any) => location.pathname === c.path)
                                ? location.pathname === child.path
                                  ? "sub-active btn-primary text-white"
                                  : "text-dark border btn_primary border-dark"
                                : "text-dark border btn_primary border-dark"
                              : location.pathname === child.path
                              ? "sub-active btn-primary text-white"
                              : "text-dark btn_primary"
                          }`}
                            //  className={` btn text-start py-1   ${location.pathname === child.path ? 'sub-active btn-primary text-white' : 'text-dark    btn_primary  '}`}
                            onClick={() =>{
                                if (child.type == "popup") {
                                    openModalToggle()
                                    setModalType(child.modalType)
                                }else{
                                    handleChildClick(child)
                                    setIsModalOpen(false);
                                    dispatch(closeModal());
                                }
                            }  
                        }
                            
                        >
                            {child.icon && (
                                <FontAwesomeIcon icon={child.icon} />
                            )}{" "}
                            {child.title}
                        </button>
                    // </li>
                )})
             }
        </ul>
    </div>
)}

                    { selectedParent?.title == "Tools" && selectedSubChild?.Items &&
                        selectedSubChild.Items.length > 0 && (
                            <div className="col-md-12 cccc border-end  "> 
                               
                               
                                <ul className="list-group">
                                    {selectedSubChild.Items.map((subChild,ind) =>{ 
                                       const isParentActive =
                                       location.pathname === subChild.path ||
                                       (subChild.children &&
                                           subChild.children.some(
                                               (subitem) =>
                                                   subitem.path &&
                                                   location.pathname.startsWith(`${process.env.PUBLIC_URL}/${subitem.path}`)
                                           ));
                                        return(
                                        <li key={subChild.id}  className="list-group-item   " >
                                            <button
                                                className={`btn ps-0 py-0  gap-2 d-flex align-items-center text-start ${
                                                    isParentActive
                                                        ? " "
                                                        // ? "sub-active btn-primary text-white"
                                                        : " "
                                                        // : "text-dark  border btn_primary border-dark"
                                                } `}
                                                onClick={() => toggleCollapse(ind)}
                                                
                                            >
                                                {subChild.icon && (
                                                    <FontAwesomeIcon
                                                        icon={subChild.icon}
                                                    />
                                                )}{" "}
                                              <h5 className="fw-bold my-1">{subChild.subtitle}</h5>  
                                              <FontAwesomeIcon
                            icon={Cards === ind ? faChevronUp : faChevronDown}
                            className="ms-auto"
                        />
                                            </button>
                                             <Collapse isOpen={Cards === ind}>
                                                <ul className="mt-2 d-flex flex-row gap-1 flex-wrap"> 
                                                {subChild.children && subChild.children.map((subitem, index) => {
                                                    console.log(subitem);
                                                    
                                                    const isActive = (path: string) => {
                                                        return (
                                                            path && path !== "#" && 
                                                            (location.pathname === `${process.env.PUBLIC_URL}/${path}` ||
                                                            (location.pathname.startsWith(`${process.env.PUBLIC_URL}/${path}`) &&
                                                                !location.pathname.includes(`${process.env.PUBLIC_URL}/${path}/`)))
                                                        );
                                                    };
                                                    
                                                    const activeClass = isActive(subitem.path) ? "bg-gray text-white" : "text-dark  border  border-dark";
                                                     
                                                    
                                                    return subitem.path ? (
                                                        <li key={index}> {/* Ensure unique key */}
                                                            <Btn 
                                                                className={`border d-inline-block rounded p-2 ps-2 pe-2 ${activeClass}`} 
                                                                // to={subitem.path}
                                                                onClick={() => {
                                                                    navigate(subitem.path);
                                                                    setIsModalOpen(false); 
                                                                    dispatch(closeModal());
                                                                }}
                                                            >
                                                                {subitem.pageType == "table" ? <span className="me-2">
                                                                <FontAwesomeIcon icon={fas.faTableList}  />
                                                                </span>:subitem.pageType == "form" ?<span className="me-2">
                                                                <FontAwesomeIcon icon={fas.faCirclePlus}  />
                                                                </span> : ''}
                                                                {subitem.actionName}
                                                                {/* {fas.faTableList} */}
                                                            </Btn>
                                                        </li>
                                                    ) : (
                                                        <Btn
                                                            className="border rounded p-2 ps-2 pe-2 text-dark  border btn_primary border-dark "
                                                            key={index} // Ensure each element has a unique key
                                                            // Uncomment and modify as needed
                                                            onClick={() => {
                                                                openModalToggle();
                                                                setModalType(subitem.modalType);
                                                            }}
                                                        >
                                                             {subitem.pageType == "table" ?<span className="me-2">
                                                                <FontAwesomeIcon icon={fas.faTableList}  />
                                                                </span>:subitem.pageType == "form" ?<span className="me-2">
                                                                <FontAwesomeIcon icon={fas.faCirclePlus}  />
                                                                </span> : ''}
                                                            <span
                                                                style={{
                                                                    lineHeight: "21px",
                                                                    fontFamily: "Lexend",
                                                                }}
                                                            >
                                                                {subitem.actionName}
                                                            </span>
                                                        </Btn>
                                                    );
                                                })} 
                                            </ul>
                                            </Collapse>
                                        </li>
                                    )})}
                                </ul>
                            </div>
                        )}
                </div>
            </CommonModal>
        </div>
     {modalType == "WorkAvailability" &&  openModal &&  
     <Popup
                        title={`Work Availability`}
                        openModalToggle={() => setOpenModal(!openModal)}
                        isOpen={openModal}
                        size={`lg`}
                        backdrop={true}
                        isClose={false}
                        CommonFooter={false}
                      >
                        <div className="mx-4 my-2">
                          <Formik
                            initialValues={workAvailabilityFormInitialValue}
                            onSubmit={handleSubmit}
                            validationSchema={workAvailabilityFormSchema}
                          >
                            {({ errors, values, setFieldValue }) => (
                              <WorkAvailabilityForm
                                errors={errors}
                                values={values}
                                setFieldValue={setFieldValue}
                                setOpenModal={setOpenModal}
                                openModal={openModal}
                                setSubmitErrors={setSubmitErrors}
                                submitErrors={submitErrors}
                                resumeData={resumesData}
                              />
                            )}
                          </Formik>
                        </div>
</Popup>}
                                 {modalType == "Verfied Branch Tags" &&  openModal &&  
                                  <Modal isOpen={openModal} toggle={openModalToggle} size="xl">
                                      <ModalHeader toggle={openModalToggle}>
                                      Verfied Branch Tags (1Webbb Service)
                                      </ModalHeader>
                                      <ModalBody className="overflow-y-auto">
                                      <Notes notsectionlist={true} branchountry={true} city={true} branches={true} PriceDay={true} Price={false}  oneBranch={true} />
                      
                                          {/* You can display additional data here */}
                                          <Formik
                                              initialValues={branchPublicContactFormInitialValue}
                                              onSubmit={handleSubmit}
                                              validationSchema={
                                                  branchPublicContactFormValidationSchema
                                              }>
                                              {({ errors, values, setFieldValue }) => {
                                                  
                                                  
                                                  return (
                                                      <Form className="form-wizard mx-3">
                                                          
                                                          <Row>
                                                              <Col sm="4" className="mt-2">
                                                                  <Label check>
                                                                  Verified Business Branch License Tags:{" "} 
                                                                      <small className="d-block">We display ‘Verified License’ tag under your branch name, after we verify with the government officials the business license you provide us.</small>
                                                                  </Label>
                                                                  <div className="flex-shrink-0 icon-state switch-outline">
                                                                  <CommonSwitchSpan
                                                                  color={"primary"}
                                                                  name="showBusinessBranch"
                                                                  checked={showBusinessBranch}
                                                                  onChange={() => setShowBusinessBranch(!showBusinessBranch)}
                                                              />
                                                                  </div>
                                                              </Col>
                                                              {showBusinessBranch && (
                                                          <>
                                                              <Col sm="4" className="mb-3">
                                                                  <Label check>
                                                                      Price (30 Days per Branch):{" "}
                                                                      <b>10 USD</b>
                                                                  </Label>
                                                              </Col>
                                                              <Col sm="4" className="mb-3">
                                                                  <Label check>Total: </Label>
                                                                  <b>{values.branches.length * 10}</b>
                                                              </Col>
                                                          </>
                                                      )}
                                                          </Row>
                                                          <Row>
                                                              <Col sm="4" className="mt-2">
                                                                  <Label check>
                                                                  Verified Branch Location Tags:{" "} 
                                                                      <small className="d-block">We display ‘Verified Location’ tag under your visited branch name, after we visit your location in various random times to verify your location.</small>
                                                                  </Label>
                                                                  <div className="flex-shrink-0 icon-state switch-outline">
                                                                  <CommonSwitchSpan
                                                                  color={"primary"}
                                                                  name="showBusinessLocation"
                                                                  checked={showBusinessLocation}
                                                                  onChange={() => setShowBusinessLocation(!showBusinessLocation)}
                                                              />
                                                                  </div>
                                                              </Col>
                                                              {showBusinessLocation && (
                                                          <>
                                                              <Col sm="4" className="mb-3">
                                                                  <Label check>
                                                                      Price (30 Days per Branch):{" "}
                                                                      <b>5 USD</b>
                                                                  </Label>
                                                              </Col>
                                                              <Col sm="4" className="mb-3">
                                                                  <Label check>Total: </Label>
                                                                  <b>{values.branches.length * 5}</b>
                                                              </Col>
                                                          </>
                                                      )}
                                                             
                                                              <Col sm="4" className="mb-3 mt-2">
                                                                  <Label check>
                                                                      Subtotal:{" "} <b>{(showBusinessBranch ? values.branches.length * 10 : 0) +
                                                                            (showBusinessLocation ? values.branches.length * 5 : 0)}</b>
                                                                      
                                                                  </Label>
                                                                  
                                                              </Col>
                                                          </Row>
                                                      </Form>
                                                  );
                                              }}
                                          </Formik>
                                      </ModalBody>
                                  </Modal>}
                                 {modalType == "Branch Photographer" &&  openModal &&   <Modal isOpen={openModal} toggle={openModalToggle} size="xl" className=' modal-dialog  modal-dialog-scrollable modal-custom  modal-xl modal-dialog-centered'>
                                      <ModalHeader toggle={openModalToggle}>
                                      Branch Photographer (taking photos at your location - 1Webbb Service)
                                      </ModalHeader>
                                      <ModalBody>
                                      <Notes notsectionlist={true} branchountry={true} city={true} branches={true} PriceDay={true} Price={false}  oneBranch={true} />
                      
                       
                                          <Formik
                                              initialValues={branchPublicContactFormInitialValue}
                                              onSubmit={handleSubmit}
                                              validationSchema={
                                                  branchPublicContactFormValidationSchema
                                              }>
                                              {({ errors, values, setFieldValue }) => {
                                                  return (
                                                      <Form className="form-wizard mx-3">
                                                           
                                                              <Row className="mb-2">
                                                                  <H5>
                                                                  Branch Photographer (taking photos at your location):{" "} 
                                                                  </H5> 
                                                                  <span>
                                                                  A well trained Assistant will visit the business location to take professional photos of your products or services
                                                                  </span>
                      
                                                                  <Col sm="4" className="mt-3">
                                                                      <div className="flex-shrink-0 icon-state switch-outline">
                                                                          <CommonSwitchSpan
                                                                              color={"primary"}
                                                                              name="showCalculator" 
                                                                  checked={showBranchPhotoLocation}
                      
                                                                  onChange={() => setShowBranchPhotoLocation(!showBranchPhotoLocation)}
                      
                                                                          />
                                                                      </div>
                                                                  </Col>
                                                                  {showBranchPhotoLocation && ( 
                                                                      <>
                                                                       <Col sm="4" className="mb-3 mt-3">
                                                                      <Label check>
                                                                          Price (per Session per
                                                                          Branch):
                                                                      </Label>
                                                                      <Field name="photoSessionPrice" className="form-control">
                                                      {({ field, form }) => (
                                                          <select
                                                              {...field}
                                                              id="photoSessionPrice"
                                                              className={`form-select `}
                                                              onChange={(e) => {
                                                                  form.setFieldValue(
                                                                      "photoSessionPrice",
                                                                      parseInt(e.target.value, 10)
                                                                  );
                                                              }}>
                                                              <option value={10}>
                                                                  10 USD (4 hours)
                                                              </option>
                                                              <option value={20}>
                                                                  20 USD (8 hours)
                                                              </option>
                                                              <option value={30}>
                                                                  30 USD (15 hours)
                                                              </option>
                                                          </select>
                                                      )}
                                                  </Field>
                                                                  </Col>
                                                                  <Col sm="4" className="mb-3 mt-3">
                                                                      <Label check>Total: <b>
                                                                  {values.branches.length *
                                                                      values.photoSessionPrice}{" "}
                                                                  USD
                                                              </b></Label>
                                                                  </Col>
                                                                  
                                                                  </>
                                                              )}
                                                              <Col sm="4" className="mt-3">
                                                                  <Label>Auto Renew (monthly)</Label>
                                                                      <div className="flex-shrink-0 icon-state switch-outline">
                                                                          <CommonSwitchSpan
                                                                              color={"primary"}
                                                                              name="showCalculator"
                                                                              defaultChecked
                                                                          />
                                                                      </div>
                                                                  </Col>
                                                              </Row> 
                                                      </Form>
                                                  );
                                              }}
                                          </Formik>
                                      </ModalBody>
                                </Modal>}
                                {modalType == "Add an Account Assistant" &&  openModal &&
                                <CommonModal
                                      isOpen={openModal}
                                      toggle={openModalToggle}
                                      modalBodyClassName="  modal-custom"
                                      largesize="modal-custom"
                                      sizeTitle="Add an Account Assistant"
                                      AdShow={false} 
                                      size="md">
                                            <div className="mx-0 my-2">
                                            <Notes branchountry={true} city={true} branches={true} PriceDay={true} Price={false}  oneBranch={true} />
                                    
                                                <div className="mx-2">
                                                <OnlineAssistantForm openAssistantModalToggle={openModalToggle}/>
                                                    </div>
                                                </div>
                                </CommonModal>  }
                             {modalType == "productservicePublishOnline" &&  openModal &&   
            
                                    <CommonModal
                                        isOpen={openModal}
                                        toggle={openModalToggle}
                                        modalBodyClassName="  modal-custom"
                                        largesize="modal-custom"
                                        title='Publish Online'
                                        AdShow={false} 
                                        size="md"> 
                                            <Formik
                                                    initialValues={FequentlyinitialValues}
                                                    validationSchema={FequentlyValidationSchema}
                                                    onSubmit={(values) => {
                                                    console.log("Selected values:", values);
                                                    }}
                                                >
                                                    {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                        <FequentlyModalForm selectField='product' setFieldValue={setFieldValue} selectField="product" handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
                                                        )}
                                            </Formik>
                                    </CommonModal> }
                              {modalType == "importBulkProductService" &&  openModal &&   
                                <CommonModal
                                      isOpen={openModal}
                                      toggle={openModalToggle}
                                    //   modalBodyClassName="  modal-custom"
                                      largesize="modal-custom"
                                     title='Import Bulk Products and Services'
                                      AdShow={false} 
                                      
                                      size="md"> 
                                           <ImportBulk/>
                                </CommonModal>}
                            { modalType == "importBulkProductImages" &&  openModal &&   
                                <CommonModal
                                    isOpen={openModal}
                                    toggle={openModalToggle} 
                                    largesize="modal-custom"
                                    title='Import Bulk Product Images'
                                    AdShow={false}  
                                    size="md"> 
                                    <Formik
                                        initialValues={{ 
                                        bulkproductImages: [],
                                        }}
                                        validationSchema={ProductvalidationSchema}
                                        onSubmit={(values) => {
                                            // Handle form submission.
                                            console.log(values);
                                        }}
                                    >
                                        {({ setFieldValue, errors, touched }) => (
                                            <BulkProductImages name="bulkproductImages" setFieldValue={setFieldValue} errors={errors} touched={touched} />
                                        )}
                                    </Formik>
                            
                                </CommonModal>}
                            { modalType == "importBulkAddons" &&  openModal &&   
                                <CommonModal
                                    isOpen={openModal}
                                    toggle={openModalToggle} 
                                    largesize="modal-custom"
                                    title='Import Bulk Addons'
                                    AdShow={false}  
                                    size="md"> 
                                    <ImportBulk/>
                            
                                </CommonModal>}
                                { modalType == "1webbbDelivery" &&  openModal &&   
                                <CommonModal
                                    isOpen={openModal}
                                    toggle={openModalToggle}  
                                    title='1Webbb Deleviery'
                                    AdShow={false}  
                                    size="xl"> 
                                                    {/* You can display additional data here */}
                                                    <Formik
                                                        initialValues={branchPublicContactFormInitialValue}
                                                        onSubmit={handleSubmit}
                                                        validationSchema={
                                                            branchPublicContactFormValidationSchema
                                                        }>
                                                        {({ errors, values, setFieldValue }) => {
                                                            return (
                                                                <Form className="form-wizard">
                                                                    <Row>
                                                                        <Col sm="4" className="mb-3">
                                                                            <Label check>
                                                                                Country{" "}
                                                                                <span className="txt-danger">
                                                                                    *
                                                                                </span>
                                                                            </Label>
                                                                            <Country
                                                                                name="country"
                                                                                isMulti={false}
                                                                                placeholder="Select"
                                                                                className={`${
                                                                                    submitErrors &&
                                                                                    `${
                                                                                        errors.country
                                                                                            ? "is-invalid"
                                                                                            : "is-valid"
                                                                                    }`
                                                                                }`}
                                                                            />
                                                                            <ErrorMessage
                                                                                name="country"
                                                                                component="span"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </Col>
                                                                        <Col sm="4" className="mb-3">
                                                                            <Label check>State</Label>
                                                                            <State
                                                                                name="state"
                                                                                countryId={values.country} // Pass the selected country ID
                                                                                isMulti={true}
                                                                                setFieldValue={setFieldValue}
                                                                                placeholder="Select"
                                                                            />
                                                                        </Col>
                                                                        <Col sm="4" className="mb-3">
                                                                            <Label check>
                                                                                City{" "}
                                                                                <span className="txt-danger">
                                                                                    *
                                                                                </span>
                                                                            </Label>
                                                                            <City
                                                                                name="city"
                                                                                countryId={values.country}
                                                                                stateId={values.state} // Pass the selected state ID
                                                                                isMulti={true}
                                                                                placeholder="Select"
                                                                                className={`${
                                                                                    submitErrors &&
                                                                                    `${
                                                                                        errors.city
                                                                                            ? "is-invalid"
                                                                                            : "is-valid"
                                                                                    }`
                                                                                }`}
                                                                            />
                                                                            <ErrorMessage
                                                                                name="city"
                                                                                component="span"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="align-items-center">
                                                                        <Col sm="4" className="mb-3">
                                                                            <Label check>
                                                                                Branches{" "}
                                                                                <span className="txt-danger">
                                                                                    *
                                                                                </span>
                                                                            </Label>
                                                                            <Branches
                                                                                name="branches"
                                                                                placeholder="Select"
                                                                                isMulti={true}
                                                                                className={`${
                                                                                    submitErrors &&
                                                                                    `${
                                                                                        errors.branches
                                                                                            ? "is-invalid"
                                                                                            : "is-valid"
                                                                                    }`
                                                                                }`}
                                                                            />
                                                                            <ErrorMessage
                                                                                name="branches"
                                                                                component="span"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                
                                                                    <Row className="mb-2">
                                                                        <H5>
                                                                            1Webbb Deleviery{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                        </H5>
                                
                                                                        <span>
                                                                            Use 1Webbb Delivery Team to serve
                                                                            your business. You pay nothing for
                                                                            us. Our fees will be added to the
                                                                            bill as a separate fee.{" "}
                                                                        </span>
                                
                                                                        <Col sm="4" className="mt-3">
                                                                            <div className="flex-shrink-0 icon-state switch-outline">
                                                                                <CommonSwitchSpan
                                                                                    color={"primary"}
                                                                                    name="showCalculator"
                                                                                    defaultChecked
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            );
                                                        }}
                                                    </Formik>
                                </CommonModal>}
                           {  modalType == "addAlbum" &&  openModal && <AddAlbumModal isOpen={openModal} title="Add an Album" openModalToggle={openModalToggle}/>}
                           {  modalType == "addVisualIdea" &&  openModal && <AddVisualIdeaModal  isOpen={openModal} title="Add a Visual Idea" openModalToggle={openModalToggle}/>}
                                     
                           { modalType == "postPublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={
                                            FequentlyinitialValues
                                        }
                                        validationSchema={
                                            FequentlyValidationSchema
                                        }
                                        onSubmit={(values) => {
                                            console.log(
                                                "Selected values:",
                                                values
                                            );
                                        }}
                                    >
                                            {({
                                                handleChange,
                                                values,
                                                errors,
                                                setFieldValue,
                                            }) => (
                                                <FequentlyModalForm
                                                    selectField="article"
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    handleChange={handleChange}
                                                    values={values}
                                                    submitErrors={submitErrors}
                                                    errors={errors}
                                                />
                                            )}
                                    </Formik>
                                </CommonModal>
                            }            
                           { modalType == "activitesPublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={FequentlyinitialValues}
                                        validationSchema={FequentlyValidationSchema}
                                        onSubmit={(values) => {
                                        console.log("Selected values:", values);
                                        }} >
                                                            {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                <FequentlyModalForm handleChange={handleChange} setFieldValue={setFieldValue} values={values} submitErrors={submitErrors} errors={errors}/>
                                                )}
                                    </Formik> 
                            </CommonModal>
                            }            
                           { modalType == "frequentlyPublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={FequentlyinitialValues}
                                        validationSchema={FequentlyValidationSchema}
                                        onSubmit={(values) => {
                                        console.log("Selected values:", values);
                                        }} >
                                                            {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                               <FequentlyModalForm selectField='Conference' setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
                                                )}
                                    </Formik> 
                            </CommonModal>
                            }            
                           { modalType == "mediaContestPublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={FequentlyinitialValues}
                                        validationSchema={FequentlyValidationSchema}
                                        onSubmit={(values) => {
                                        console.log("Selected values:", values);
                                        }} >
                                                            {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                                              <FequentlyModalForm  selectField='photocontest'  setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
                                                               
                                                )}
                                    </Formik> 
                            </CommonModal>
                            }            
                           { modalType == "knowledgePublish" &&  openModal && 
                            <CommonModal
                                largesize="modal-custom"
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Publish Online'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <Formik
                                        initialValues={FequentlyinitialValues}
                                        validationSchema={FequentlyValidationSchema}
                                        onSubmit={(values) => {
                                        console.log("Selected values:", values);
                                        }} >
                                                            {({ handleChange, values,errors ,setFieldValue}) => ( 
                                                                                             <FequentlyModalForm selectField='Seminar' setFieldValue={setFieldValue} handleChange={handleChange} values={values} submitErrors={submitErrors} errors={errors}/>
                                                               
                                                )}
                                    </Formik> 
                            </CommonModal>
                            }            
                           { modalType == "addQuestionGroup" &&  openModal && 
                            <CommonModal 
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Add a Question Group'
                                AdShow={false} 
                                backdrop={true} 
                                size="xl"> 
                                    <AddQuestionGroupModal />
                            </CommonModal>
                            }            
                           { modalType == "addQuizzMaterial" &&  openModal && 
                            <CommonModal 
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Add a Question Group'
                                AdShow={false} 
                                backdrop={true} 
                                largesize="modal-custom"
                                size="xl"> 
                                    <AddQuizMaterial />
                            </CommonModal>
                            }            
                           { modalType == "addCourseInfo" &&  openModal && 
                            <CommonModal 
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Add Course and Teacher Information'
                                AdShow={false} 
                                backdrop={true} 
                                largesize="modal-custom"
                                size="xl"> 
                                     <AddCourseandTeacherInformation />
                            </CommonModal>
                            }            
                           { modalType == "addQuestionnaireSettings" &&  openModal && 
                            <CommonModal 
                                isOpen={openModal}
                                toggle={openModalToggle}  
                                title='Add Questionnaire Settings'
                                AdShow={false} 
                                backdrop={true} 
                                largesize="modal-custom"
                                size="xl"> 
                                     <Formik
                                              initialValues={
                                                  surveyAndQuizzesFormInitialValue
                                              } 
                                              onSubmit={handleSubmit}
                                              validationSchema={
                                                  TabsurveyAndQuizzesFormValidationSchema
                                              }
                                          >
                                              {({
                                                  errors,
                                                  values,
                                                  setFieldValue,
                                                  handleSubmit,
                                              }) => (
                                                  <AddSurveyAndQuizzesForm
                                                      submitErrors={
                                                          submitErrors
                                                      }
                                                      setSubmitError={
                                                          setSubmitErrors
                                                      }
                                                      errors={errors}
                                                      values={values}
                                                      setFieldValue={
                                                          setFieldValue
                                                      }
                                                      addquizsurveysTab={false}
                                                      testquizsetting={false}
                                                      handleSubmit={
                                                          handleSubmit
                                                      }
                                                      // setCategory={setCategory}
                                                  />
                                              )}
                                          </Formik>
                            </CommonModal>
                            }            

                      </>
    );
};

export default NavbarPopup;
