export const ManageContents = [
    {
        title: "Manage Albums",
        total_contest: 0,
        path: "tools/manage-visual-contents/manage-my-albums",
    },
    {
        title: "Manage Visual Ideas",
        total_contest: 0,
        path: "tools/manage-visual-contents/manage-my-visual-ideas",
    },
    {
        title: "Manage Uploaded Contents",
        total_contest: 0,
        path: "tools/manage-contents/my-uploaded-visual-contents",
    },
    {
        title: "Trashed Photos",
        total_contest: 0,
        path: "tools/manage-visual-contents/trash",
    },
];

// Attendance System
export const AttendanceManagement = [
    {
        title: "Manage Attendance",
        total_contest: 0,
        path: "tools/brand-support-tools/activities-and-attendance",
    },
    {
        title: "Add New Activity",
        total_contest: 0,
        path: "tools/brand-support-tools/activities-and-attendance/add-new-activity",
    },
];
// attendance system actions
export const MyAtttendanceSystemTableAction = [
     
    { actionName: "CSV", path: "" },
    { actionName: "Delete", path: "" },
    { actionName: "Excel", path: "" },
    { actionName: "Print", path: "" },
];
export const ManageContentTableAction = [
     
    { actionName: "Delete", path: "" },
    { actionName: "CSV", path: "" },
    { actionName: "Excel", path: "" },
    { actionName: "Print", path: "" },
];

export const ManageUploadedContentTableAction = [
     
    { actionName: "Edit", path: "" },
    { actionName: "Delete", path: "" },
    { actionName: "CSV", path: "" },
    { actionName: "Excel", path: "" },
    { actionName: "Print", path: "" },
];

export const MyAlbumTableAction = [
   
    { actionName: "Delete", path: "" },
    { actionName: "CSV", path: "" },
    { actionName: "Excel", path: "" },
    { actionName: "Print", path: "" },
];

export const AddonCategoriesTableAction = [
    {
        actionName: "New Addon",
        path: "tools/manage-online-store/addon-categories/add-new",
    },
    { actionName: "Delete", path: " " },
    { actionName: "CSV", path: " " },
    { actionName: "Excel", path: " " },
    { actionName: "Print", path: " " },
];

export const CouponTableAction = [
    {
        actionName: "Add a Coupon",
        path: "tools/manage-online-store/manage-my-coupons/add-new",
    },
    { actionName: "Delete", path: " " },
    { actionName: "CSV", path: "" },
    { actionName: "Excel", path: "" },
    { actionName: "Print", path: "" },
];

export const DeliveryFeeAction = [
    {
        actionName: "Delivery Workers",
        path: "tools/manage-online-store/delivery-system/delivery-workers",
    },
    {
        actionName: "Delivery Fees and Settings",
        path: "tools/manage-online-store/delivery-system/delivery-fees-Settings",
    },
];

export const ManageOnlineStore = [
    {
        title: "Manage Sales and Branches",
        total_contest: 0,
        path: "tools/manage-online-store/manage-branches/sales",
    },
    {
        title: "Manage Products & Services",
        total_contest: 0,
        path: "tools/manage-online-store/manage-products-and-services",
    },
    // {title:'Contents Products & Services', total_contest:0  ,path:'tools/manage-online-store/manage-contents-of-products-and-services'},
    {
        title: "Addon Categories",
        total_contest: 0,
        path: "tools/manage-online-store/addon-categories",
    },
    {
        title: "Coupon System",
        total_contest: 0,
        path: "tools/manage-online-store/manage-my-coupons",
    },
    {
        title: "Delivery System",
        total_contest: 0,
        path: "tools/manage-online-store/delivery-system",
    },
];

export const DeliveryFeeTableAction = [
    {
        actionName: "Add Delivery Fees and Settings",
        path: "tools/manage-online-store/delivery-system/delivery-fees-Settings/add-new",
    },
    { actionName: "CSV", path: "" },
    { actionName: "Delete", path: "" },
    { actionName: "Excel", path: "" },
    { actionName: "PDF", path: "" },
    { actionName: "Print", path: "" },
];

export const DeliveryWorkerTableAction = [
    {
        actionName: "Add a Delivery Worker",
        path: "tools/manage-online-store/delivery-system/delivery-workers/add-new",
    },
    { actionName: "1Webbb Delivery", path: "" },
    { actionName: "Delete", path: "" },
    { actionName: "CSV", path: "" },
    { actionName: "Excel", path: "" },
    { actionName: "PDF", path: "" },
    { actionName: "Print", path: "" },
];

export const BranchsAction = [
 
    { actionName: "Delete", path: "#" },
    {
        actionName: "CSV",
        path: "tools/manage-online-store/manage-branches/csv",
    },
    {
        actionName: "Excel",
        path: "tools/manage-online-store/manage-branches/excel",
    },
    {
        actionName: "Print",
        path: "tools/manage-online-store/manage-branches/print",
    },
];

export const ManageContentsProductServicesAction = [
    { actionName: "Upload Contents of Products and Services", path: "#" },
    { actionName: "Assign contents to a product or service", path: "#" },
    {
        actionName: "Add to Album",
        path: "tools/manage-visual-contents/manage-my-albums/add-new",
    },
    { actionName: "Delete", path: "#" },
    { actionName: "CSV", path: "" },
    { actionName: "Excel", path: "" },
    { actionName: "Print", path: "" },
];
export const ManageProductServicesAction = [
    {
        actionName: "Add a Product or Service",
        path: "tools/manage-online-store/manage-products-and-services/add-new",
    },
    // {actionName:"Import Transition", path:'' },
    { actionName: "Publish Online", path: "" },
    { actionName: "Delete", path: "#" },
    { actionName: "CSV", path: "" },
    { actionName: "Excel", path: "" },
    { actionName: "Print", path: "" },
];
