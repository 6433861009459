import { useState } from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";
import { Btn, H4, LI, UL } from "../../../../AbstractElements";
import PriceContainer from "./PriceContainer";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { prizesValue } from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import Country from "../../../CommonInput/Country";
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput";
import Sharing from "../SurveyAndQuizzes/Sharing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TitleInput from "../../../CommonInput/TitleInput";
import DescriptionInput from "../../../CommonInput/DescriptionInput";
import { options } from "../../../Application/Ecommerce/AddProduct/AddProduct";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import CustomSelect from "../../../CommonInput/CustomSelect";
import FaqModal from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/AddFaqForm";
import Notes from "../../../../Pages/Tools/Notes/Notes";
import FequentlyModalForm from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyModalForm";
import { FequentlyinitialValues, FequentlyValidationSchema, scheduleFormInitialValue,  scheduleFormValidationSchema, } from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import Popup from "../../../MasterPopup/Popup";
import AddFaqForm from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/AddFaqForm";
import SharingSection from "../SurveyAndQuizzes/SharingSection";
function PhotoContestForm(props: any) {
    const {
        errors,
        submitErrors,
        setSubmitError,
        values,
        setFieldValue,
        handleChange,
    } = props;

    const [priceFormCount, setPriceFormCount] = useState(["1"]);

    const addFormHandler = (arrayHelpers: any) => {
        const value = priceFormCount.length + 1;
        setPriceFormCount((oldArray) => [...oldArray, value.toString()]);
        arrayHelpers.push(prizesValue);
    };

    const removeFormHandler = (
        value: string,
        arrayHelpers: any,
        index: number
    ) => {
        const updatedFormCount = priceFormCount.filter(
            (item) => item !== value
        );
        setPriceFormCount(updatedFormCount);
        arrayHelpers.remove(index);
    };
    const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];
    const faqOptions = [
        { label: "How to use the platform?", value: "how-to-use" },
        { label: "Pricing information", value: "pricing" },
        { label: "Account settings", value: "account-settings" },
        { label: "Troubleshooting", value: "troubleshooting" },
        { label: "Contact support", value: "contact-support" },
    ];
    const [openPublishModal, setOpenPublishModal] = useState(false);
    const openPublishModalToggle = () => setOpenPublishModal(!openPublishModal);
    const [openFaqModal, setOpenFaqModal] = useState(false);
    const openFaqModalToggle = () => setOpenFaqModal(!openFaqModal);
    return (
        <Form className="form-wizard">
            <Row>
                <Col sm="4" className="mb-3">
                    <Label check>
                        A Previous Photo Contest as a Reference (optional){" "}
                    </Label>
                    <Field
                        as="select"
                        className="form-control"
                        name="reference-coupon"
                    >
                        <option value={""}>Select a Reference</option>
                    </Field>
                </Col>/
                <Col sm="4" className="mb-3">
                    <TitleInput
                        title="Photo Contest Unique Title"
                        values={values.photoContestTitle}
                        name="photoContestTitle"
                        placeholder="Photo Contest Unique Title"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <DescriptionInput
                        name="description"
                        placeholder="Coupon Description"
                        values={values.description}
                        lable="Description"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Cover Image <span className="txt-danger">*</span>
                    </Label>
                    <FormGroup>
                        <Field
                            name="image"
                            type="file"
                            className={`form-control ${
                                submitErrors &&
                                `${errors.image ? "is-invalid" : "is-valid"}`
                            }`}
                        />
                        <ErrorMessage
                            name="image"
                            component="span"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                </Col>

                <Col sm="4" className="mb-3">
                    <Label check>Keywords (optional)</Label>
                    <TagsInput
                        name="keywords"
                        placeholder="Add keywords"
                        setFieldValue={setFieldValue}
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Submission Start Date{" "}
                        <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        name="submissionStartDate"
                        type="date"
                        placeholder="Start Date"
                        className={`form-control ${
                            submitErrors &&
                            `${
                                errors.submissionStartDate
                                    ? "is-invalid"
                                    : "is-valid"
                            }`
                        }`}
                    />
                    <ErrorMessage
                        name="submissionStartDate"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Submission End Date{" "}
                        <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        name="submissionEndDate"
                        type="date"
                        placeholder="Start Date"
                        className={`form-control ${
                            submitErrors &&
                            `${
                                errors.submissionEndDate
                                    ? "is-invalid"
                                    : "is-valid"
                            }`
                        }`}
                    />
                    <ErrorMessage
                        name="submissionEndDate"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>

                <Col sm="4" className="mb-3">
                    <Label check>
                        Photo Contest End Date{" "}
                        <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        name="photoContestEndDate"
                        type="date"
                        placeholder="Start Date"
                        className={`form-control ${
                            submitErrors &&
                            `${
                                errors.photoContestEndDate
                                    ? "is-invalid"
                                    : "is-valid"
                            }`
                        }`}
                    />
                    <ErrorMessage
                        name="photoContestEndDate"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Minimum Number of Files{" "}
                        <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        name="minNumberOfFiles"
                        type="number"
                        min="1"
                        max="5"
                        placeholder="Enter Minimum Files"
                        className={`form-control ${
                            submitErrors &&
                            `${
                                errors.minNumberOfFiles
                                    ? "is-invalid"
                                    : "is-valid"
                            }`
                        }`}
                        defaultValue="1" // Default value set to 1
                    />
                    <ErrorMessage
                        name="minNumberOfFiles"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>

                <Col sm="4" className="mb-3">
                    <Label check>
                        Maximum Number of Files{" "}
                        <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        name="maxNumberOfFiles"
                        type="number"
                        max="10"
                        placeholder="Enter Maximum Files"
                        className={`form-control ${
                            submitErrors &&
                            `${
                                errors.maxNumberOfFiles
                                    ? "is-invalid"
                                    : "is-valid"
                            }`
                        }`}
                    />
                    <ErrorMessage
                        name="maxNumberOfFiles"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Media Type <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        as="select"
                        name="mediaType"
                        className={`form-control ${
                            submitErrors &&
                            `${errors.mediaType ? "is-invalid" : "is-valid"}`
                        }`}
                    >
                        <option value={""}>Select photos</option>
                        <option value={"Photos"}>Photos</option>
                    </Field>
                    <ErrorMessage
                        name="mediaType"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>
                        Participants’ Countries{" "}
                        <span className="txt-danger">*</span>
                    </Label>
                    <Country
                        name="country"
                        isMulti={true}
                        placeholder="Select"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <FormGroup>
                        <Label for="isPreviousCustomer">
                            A Participant Must a Previous Customer from your
                            1Webbb Store
                        </Label>
                        <div className="flex-shrink-0 icon-state switch-outline">
                            <CommonSwitchSpan
                                name="isPreviousCustomer"
                                color={"primary"}
                                defaultChecked
                            />
                        </div>
                        {errors.isPreviousCustomer && (
                            <div className="text-danger">
                                {errors.isPreviousCustomer}
                            </div>
                        )}
                    </FormGroup>
                </Col>
                {values.isPreviousCustomer && (
                    <Col sm="4" className="mb-3">
                        <FormGroup>
                            <Label for="minOrderAmount">
                                Minimum total order amounts from the past 90
                                days
                            </Label>
                            <Field
                                type="number"
                                name="minOrderAmount"
                                className={`form-control ${
                                    errors.minOrderAmount ? "is-invalid" : ""
                                }`}
                            />
                            {errors.minOrderAmount && (
                                <div className="invalid-feedback">
                                    {errors.minOrderAmount}
                                </div>
                            )}
                        </FormGroup>
                    </Col>
                )}
                <Col sm="4" className="mb-3">
                    <FormGroup>
                        <Label for="isPreviousCustomer">
                            Related Frequently Asked Questions (optional)
                        </Label>

                        <Field
                            className={`custom-select`}
                            name={`faq`}
                            options={faqOptions}
                            component={CustomSelect}
                            placeholder="Select Frequently Asked Questions"
                            isMulti={true}
                        />
                        <Btn
                            className="ps-0  d-flex"
                            onClick={openFaqModalToggle}
                        >
                            <i className="bi bi-plus-circle mx-1 text-warning"></i>
                            Add Frequently Asked Questions
                        </Btn>
                    </FormGroup>
                </Col>
                <Col sm="4" className="mb-3">
                                                                <FormGroup>
                                                                    <Label check>
                                                                        {`Policy or Service Terms (optional)`}
                                                                        :
                                                                    </Label>
                                                                    <FormGroup floating>
                                                                        <Field
                                                                            className={`custom-select  `}
                                                                            name="PolicyService"
                                                                            options={
                                                                                cityOptions
                                                                            }
                                                                            component={
                                                                                CustomSelect
                                                                            }
                                                                            placeholder="Select Tests and Surveys"
                                                                            isMulti={true}
                                                                        />
                                                                        
                                                                    </FormGroup>
                                                                </FormGroup>
                                                                </Col>
            </Row>
            <SharingSection
                CoManagement={false}
                InviteeList={true}
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
                viewOnly={true}
            />
            <FieldArray
                name="prizes"
                render={(arrayHelpers) => {
                    return (
                        <>
                            {priceFormCount.map((item, index) => (
                                <Container
                                    fluid
                                    className="p-5 mb-4"
                                    id={`prize-container-${item}`}
                                    key={index}
                                    style={{ border: "1px solid #ccc" }}
                                >
                                    <Row>
                                        <Col md={10} className="mb-3">
                                            <h4>Prize : {item}</h4>
                                        </Col>

                                        <Col md={2} className="mb-3">
                                            {priceFormCount.length.toString() !==
                                                "1" && (
                                                <span
                                                    className="btn btn-outline-danger"
                                                    onClick={() =>
                                                        removeFormHandler(
                                                            item,
                                                            arrayHelpers,
                                                            index
                                                        )
                                                    }
                                                >
                                                    <i className="icofont icofont-ui-delete"></i>
                                                </span>
                                            )}
                                            {priceFormCount.length ===
                                                index + 1 && (
                                                <span
                                                    onClick={() =>
                                                        addFormHandler(
                                                            arrayHelpers
                                                        )
                                                    }
                                                    className="btn btn-outline-dark ms-2"
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                    <PriceContainer
                                        errors={errors}
                                        submitErrors={submitErrors}
                                        index={index}
                                        values={values}
                                    />
                                </Container>
                            ))}
                        </>
                    );
                }}
            />

            <Popup
                title="Publish Online"
                openModalToggle={openPublishModalToggle}
                isOpen={openPublishModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                BodyClassName="pt-1"
                largesize="modal-custom"
            >
                <Formik
                    initialValues={FequentlyinitialValues}
                    validationSchema={FequentlyValidationSchema}
                    onSubmit={(values) => {
                        console.log("Selected values:", values);
                    }}
                >
                    {({ handleChange, values, errors, setFieldValue }) => (
                        <FequentlyModalForm
                            selectField="photocontest"
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            values={values}
                            submitErrors={submitErrors}
                            errors={errors}
                        />
                    )}
                </Formik>
            </Popup>
             <Popup
                title='Add Frequently Asked Questions'
                openModalToggle={openFaqModalToggle}
                isOpen={openFaqModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                 BodyClassName="pt-1"
                largesize="modal-custom"> 
                    <div className="mx-2 mt-2 mb-3 px-2">
                    <Formik
                                    initialValues={scheduleFormInitialValue}
                                    // onSubmit={handleSubmit}
                                    validationSchema={scheduleFormValidationSchema}>
                                    {({ errors ,values}) => (  
                                      <AddFaqForm errors={errors}  values={values}/>
                                             )}
                                </Formik>  
               </div>
            </Popup>
            {/* <FaqModal
                title={"Add Frequently Asked Questions"}
                openModalToggle={openFaqModalToggle}
                isOpen={openFaqModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                BodyClassName="pt-1"
                largesize="modal-custom"
            /> */}
        </Form>
    );
}

export default PhotoContestForm;
