import { Card, CardHeader, Col, Row } from "reactstrap";
import { referralactions } from "../../../Application/Users/MyReferralsContainer/MyReferralsContainer";
import { Link, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { useState } from "react";
import { MenuList } from "../../../../Data/LayoutData/SidebarData";
import { closeModal, openModal, setActiveItem } from "../../../../ReduxToolkit/Reducers/Navigation/Navigation";



const PageHeader = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to determine if the current path matches or starts with the link's path
  const isActive = (path) => {
    return currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`);
  };
  const { mobileView } = useAppSelector((state) => state.layout);

  const [isOpen, setIsOpen] = useState(false); 
    const dispatch = useAppDispatch();
    const menuPopupOpen =()=>{
      const data = MenuList[0]?.Items?.find((item) => item.title === "Dashboard");
      
      dispatch(setActiveItem(data))
      dispatch(openModal())
    }
    const menuPopupClose =()=>{
      dispatch(closeModal())
    }
  return (
    <Card className="earning-card mb-0">
      <CardHeader className="py-0 pb-1 ps-0">
        
<Row>
           
                  <>
                      
                  <Col md="12">
                  {isOpen ? (
                      <IoClose size='2em' onClick={() =>menuPopupClose()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  ) : (
                      <HiOutlineMenuAlt2 size='2em' onClick={() => menuPopupOpen()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  )} 
                  </Col>
              </>
         
          </Row>
        </CardHeader>
    </Card>
  );
};

export default PageHeader;

 