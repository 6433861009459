import React, { useState } from "react";
import { Card, Col, Row, CardHeader, Container } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { PostLinks } from "../../../Data/Post/PostSubMenu";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { closeModal, openModal, setActiveItem } from "../../../ReduxToolkit/Reducers/Navigation/Navigation";
import { MenuList } from "../../../Data/LayoutData/SidebarData";

const PostsubMenu = () => {
    const [activeTab, setActiveTab] = useState("1");
    const location = useLocation();

    // Function to determine if the path is active
    const isActive = (path) => {
        return location.pathname.startsWith(
            `${process.env.PUBLIC_URL}/${path}`
        );
    };

    const [isOpen, setIsOpen] = useState(false);
    const { mobileView } = useAppSelector((state) => state.layout);
 const dispatch = useAppDispatch();
    const menuPopupOpen =()=>{
      const data = MenuList[0]?.Items?.find((item) => item.title === "Posts");
      
      dispatch(setActiveItem(data))
      dispatch(openModal())
    }
    const menuPopupClose =()=>{
      dispatch(closeModal())
    }
    return (
        <div className="email-wrap bookmark-wrap pb-1">
            <Container fluid>
                <Row>
                <Col md="12">
                              {isOpen ? (
                                      <IoClose size='2em' onClick={() =>menuPopupClose()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                                  ) : (
                                      <HiOutlineMenuAlt2 size='2em' onClick={() => menuPopupOpen()} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                                  )}
                              </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PostsubMenu;
