import { Card, Col, Container, Row } from 'reactstrap'
import TodoSideBar from './TodoSideBar'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { Apps, BrandTools, ToDoHeading } from '../../../utils/Constant'
import TodoBody from './TodoBody' 
import SubMenu from '../SubMenu/SubMenu'
import SubMenuPopup from '../../../Layout/Sidebar/ManageStorePopup'

const TodoContainer = () => {
  return (
    <>
     <div className="d-flex container-fluid align-items-center">
                <div className="px-2"> 
      <SubMenuPopup  currentPage="To Dos"/>
                </div> 
      <Breadcrumbs mainTitle={`${ToDoHeading} (${BrandTools})`}  mainParent="All Tools" parent={BrandTools} />
            </div>
      <Container fluid className="email-wrap bookmark-wrap todo-wrap">
        <Row>
         
          <TodoSideBar />
          <TodoBody />
        </Row>
      </Container>
    </>
  )
}

export default TodoContainer