import React from 'react'
import ReadyTemplatesContainer from '../../../Component/Application/Templates/ReadyTemplatesContainer'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { BrandTools, Home } from '../../../utils/Constant'
import SubMenuPopup from '../../../Layout/Sidebar/ManageStorePopup'

const ReadyTemplates = () => {
  return (
    <div className='page-body'>
       <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                <SubMenuPopup  currentPage="Ready Templates List "/>
                </div> 
        <Breadcrumbs mainTitle={`Ready Templats (${BrandTools})`}  mainParent="All Tools" parent={BrandTools} />
            </div>
        <ReadyTemplatesContainer />
    </div>
  )
}

export default ReadyTemplates