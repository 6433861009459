import { useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";

import { X } from "react-feather";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import ManageOnlineSubMenu from "../ManageOnlineStore/ManageOnlineSubMenu/ManageOnlineSubMenu";
import BranchSubMenu from "../ManageOnlineStore/ManageBranches/BranchSubMenu/BranchSubMenu";

const columns = [
    {
        title: "Manage Store",
        items: [
            {
                heading: "Manage Sales and Branches",
                description:
                    "Manage sales, also add, edit and manage branches.",
                link: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/sales`,
            },
            {
                heading: "Manage Products and Services",
                description: "Add, edit and manage your products and services.",
                link: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-products-and-services`,
            },
            {
                heading: "Addon Categories",
                description:
                    "Add, edit and manage add-ons with your products and services.",
                link: `${process.env.PUBLIC_URL}/tools/manage-online-store/addon-categories`,
            },
            {
                heading: "Coupon System",
                description: "Offer discounts and manage coupons.",
                link: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-my-coupons`,
            },
            {
                heading: "Delivery Service",
                description:
                    "Add, edit, manage your delivery fees and delivery workers.",
                link: `${process.env.PUBLIC_URL}/tools/manage-online-store/delivery-system/delivery-workers`,
            },
        ],
    },
    {
        title: "Manage Contents",
        items: [
            {
                heading: "Manage Albums",
                description:
                    "Group your contents under a specific idea or album name.",
                link: `${process.env.PUBLIC_URL}/tools/manage-visual-contents/manage-my-albums`,
            },
            {
                heading: "Manage Visual Ideas",
                description:
                    "Group contents you like from any user, under specific visual ideas.",
                link: `${process.env.PUBLIC_URL}/tools/manage-visual-contents/manage-my-visual-ideas`,
            },
            {
                heading: "Manage Uploaded Contents",
                description: "Organize and manage your uploaded media files.",
                link: `${process.env.PUBLIC_URL}/tools/manage-contents/my-uploaded-visual-contents`,
            },
        ],
    },
    {
        title: "Brand Support Tools",
        items: [
            {
                heading: "Manage Posts",
                description:
                    "Add and manage articles, blogs, job openings, and customer preferences.",
                link: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-posts`,
            },
            {
                heading: "Activities and Attendance",
                description:
                    "Manage attendance, registration data, appointments, and events.",
                link: `${process.env.PUBLIC_URL}/tools/brand-support-tools/activities-and-attendance`,
            },
            {
                heading: "Frequently Asked Question System",
                description:
                    "Group and manage FAQs to help followers and customers easily find answers.",
                link: `${process.env.PUBLIC_URL}/tools/brand-support-tools/frequently-asked-question-system`,
            },
            {
                heading: "Manage External Links",
                description:
                    "Group favorite web pages to help customers find them easily.",
                link: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-my-external-links`,
            },
            {
                heading: "Photo Contest System",
                description:
                    "Create and manage photo contests to reward and interact with followers.",
                link: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-my-photo-contests`,
            },
            {
                heading: "Knowledge System",
                description:
                    "Add and manage educational games, exams, tests, and tutorials.",
                link: `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/questionnaires-quizzes-and-surveys`,
            },
            {
                heading: "Manage Projects",
                description: "Manage your work with your team and clients.",
                link: `${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list`,
            },
            {
                heading: "Support Ticket System",
                description:
                    "Manage customer service and satisfaction effectively.",
                link: `${process.env.PUBLIC_URL}/tools/brand-support-tools/support-ticket-system`,
            },
            {
                heading: "Ready Templates",
                description: "Save time and use high quality templates.",
                link: `${process.env.PUBLIC_URL}/tools/brand-support/ready-templates`,
            },
            {
                title: "Attendance System",
                items: [
                    {
                        heading: "Add New Activity",
                        link: `${process.env.PUBLIC_URL}/tools/brand-support-tools/activities-and-attendance/add-new-activity`,
                    },
                ],
            },
        ],
    },
];

const Tools = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const handleRowSelected = () => {};

    const openModalToggleColumnClick = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const handleColumnClick = () => {
        setIsPopupOpen(!isPopupOpen);
        // setSelectedRow(row); // Store the clicked row's data
    };

    return (
        <div className="page-body">
            <Breadcrumbs mainTitle={`All Tools`} />

            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-2">
                                <>
                                    <div className="bg-gray-50 p-10">
                                        <div className="max-w-7xl mx-auto row gap-2">
                                            {columns.map((column, index) => (
                                                <div
                                                    key={index}
                                                    className="col"
                                                >
                                                    <h2 className="text-xl font-semibold mb-4">
                                                        {column.title}
                                                    </h2>
                                                    <ul
                                                        className="space-y-4 cust-dropdown-menu overflow-visible top-0 position-relative  d-block"
                                                        style={{
                                                            maxHeight: "100%",
                                                        }}
                                                    >
                                                        {column.items.map(
                                                            (item, idx) => (
                                                                <li key={idx}>
                                                                    <Link
                                                                        to={
                                                                            item.link
                                                                        }
                                                                        className="text-blue-600 hover:underline"
                                                                    >
                                                                        <strong>
                                                                            {
                                                                                item.heading
                                                                            }
                                                                        </strong>
                                                                        <br />
                                                                        <span className="text-gray-600">
                                                                            {
                                                                                item.description
                                                                            }
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Tools;
