import {useState} from 'react'
import { ManageAlbums, ManageMyCollection } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { ActionButtons, MyCollectionColumnData,   supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { ManageContentTableAction, ManageContents } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import Popup from '../../../../Component/MasterPopup/Popup'
import ManageMyCollectionEdit from '../../../../Component/Tools/ManageContents/ManageMyCollections/ManageMyCollectionEdit'
import ManageMyCollectionView from '../../../../Component/Tools/ManageContents/ManageMyCollections/ManageMyCollectionView'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import ManageSubMenu from '../ManageSubMenu/ManageSubMenu'
import { Btn } from '../../../../AbstractElements'
import AddVisualIdeaModal from '../../../../Component/App/UploadData/AddVisualIdeaModal'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'

const ManageMyVisualidea = () => {
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState(supportTicketData);    
  const [isEditOpen , setIsEditOpen] = useState(false);
  const [isOpen , setIsOpen] = useState(false); 

  const handleRowSelected =() =>{}
  const openPopup = (type:boolean) =>{
    setIsOpen(!isOpen);
    setIsEditOpen(type);
  }

  const openModalToggle = () =>{
      setIsOpen(!isOpen);
  }
  const [openVisualModal, setOpenVisualModal] = useState(false);

  const openVisualModalToggle = () => {
    setOpenVisualModal(!openVisualModal); // Toggle the modal open/close state
  };
  const MyCollectionColumnData: TableColumn<SupportDataType>[] = [    
    {
      name: <TableHeadColumn title={`ID`}/>,
      selector: (row) => row["id"],
      sortable: true,
      center: true,
    },
    {
      name: <TableHeadColumn title={`By`}/>,
      selector: (row) => row["by"],
      sortable: true,
      center: true,
    },
    {
      name: <TableHeadColumn title={`Visual Idea Name`}/>,
      selector: (row) => row["position"],
      sortable: true,
      center: true,
    },
    {
      name: <TableHeadColumn title={`Description`}/>,
      selector: (row) => row["salary"],
      sortable: true,
      center: true,
    },
    {
      name:<TableHeadColumn title={`Keywords`}/> ,
      selector: (row) => row["office"],
      sortable: true,
      center: true,
    },
    {
      name: <TableHeadColumn title={`Type`}/>,
      selector: (row: SupportDataType) => row["email"],
      sortable: true,
      center: true,
    },
    {
      name: <TableHeadColumn title={`Photos`}/>,
      selector: (row: SupportDataType) => row["email"],
      sortable: true,
      center: true,
    },
    {
      name: <TableHeadColumn title={`Date`}/>,
      selector: (row: SupportDataType) => row["email"],
      sortable: true,
      center: true,
    },
    
    {
      name:<TableHeadColumn title={`Actions`}/>,
      cell: (row) => <ActionButtons openPopup={openPopup}/>,
      center: false,
      right:true,
    },
    
  ];
  
  return (
    <div className='page-body'>
       <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                <SubMenuPopup  currentPage="Visual Idea List "/>
                </div>
                <Breadcrumbs mainParent="All Tools" mainTitle={`${ManageMyCollection} (Manage Contents)`} parent={`Manage Content`} />

            </div>
     
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card> 
            <div className='mt-2'>
                {ManageContentTableAction.map((action, index)=>{
                  
                    // For the first action, render it as a button that opens the modal
                    return (
                      <Btn
                        key={index}
                        className='border rounded   p-2 m-1 text-white bg-dark ps-2 pe-2' 
                      >
                        {action.actionName}
                      </Btn>
                    ); 
            })}
                </div>
              <CardBody className='p-2'>
                <> 
               
                {/* </Col></Row> */}
                <div className="table-responsive ">
                  <DataTable className='custom-scrollbar' columns={MyCollectionColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                </div>
                
                <Popup title={ManageMyCollection} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`}>
                  {isEditOpen ? <ManageMyCollectionEdit/> : <ManageMyCollectionView /> }
                </Popup> 
                <AddVisualIdeaModal
                 
        isOpen={openVisualModal}
        title="Add a Visual Idea"
        openModalToggle={openVisualModalToggle}
      />
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ManageMyVisualidea