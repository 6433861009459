import { ErrorMessage, Field, Formik } from 'formik';
import React, { useState } from 'react' 
import { CardBody, Col, Collapse, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4 } from '../../../../AbstractElements';
import TitleInput from '../../../CommonInput/TitleInput';
import DescriptionInput from '../../../CommonInput/DescriptionInput';
import { QuestionnaireinitialValues, QuestionnairevalidationSchema } from '../../../../Data/Tools/BrandSupportTools/FormControlsValidation';
import { ChevronDown, ChevronUp } from 'react-feather';
import ImportBulk from '../../../../Pages/Tools/BrandSupportTools/ImportBulk/ImportBulk';
import Popup from '../../../MasterPopup/Popup';

function AddQuestionGroupModal() {
    const handleSubmit = ()=>{
        console.log('');
        
    }
     const [isOpen, setIsOpen] = useState(false); // State to toggle collapse
    
          const toggleCollapse = () => {
            setIsOpen((prev) => !prev); // Toggle state
          };
          const [openQestionModal, setOpenQestionModal] = useState(false);
          const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
  return (
    <>
    <div className='my-2'>        
                    <Btn onClick={openQestionModalToggle} className='p-0 child_btn border rounded'   ><span  >Import Bulk Question Group</span></Btn>
                    </div>
                    <Popup
                title='Import Bulk Question Group'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup>
    <>
<Formik
                initialValues={QuestionnaireinitialValues}
                onSubmit={handleSubmit}
                validationSchema={QuestionnairevalidationSchema}
              >
                {({ errors, values, setFieldValue }) => (
                    <Form>
                  <CardBody className='p-2'>
                  
                    <Row>
                    <H4 className='mb-2'>Add a Question Group </H4>
                      <Col sm="4">
                        <TitleInput
                          title='Unique Question Group Title'
                          values={values.quizmaterialtitle}
                          name="quizmaterialtitle"
                          placeholder="Enter a Quiz Material Name"
                        />
                      </Col> 
                      <Col sm="4">
                        <DescriptionInput
                          name='quizmaterialdescription'
                          placeholder="Enter a Description"
                          values={values.quizmaterialdescription}
                          lable='Description'
                        />
                      </Col> 
                    </Row>
                  </CardBody>
                  </Form>
                )}
              </Formik>
    </>
    </>
  )
}

export default AddQuestionGroupModal