import React, { useState, useEffect } from "react";
import { H4, UL, LI } from "../../../../AbstractElements";
import { useAppSelector, useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import { useNavigate } from "react-router-dom";
import {
  useGetAssistantAccountMutation,
  useSwitchAssitantAccountMutation,
} from "../../../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import {
  setCredentials,
  setToken,
  setChooseAccountFlag,
} from "../../../../ReduxToolkit/Reducers/Authentication/authSlice";
import Loading from "../../../../Component/Loading/Loading";
import { AfterLoginRedirectUrl } from "../../../../utils/Constant";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { deleteCookie } from "../../../../Data/Ui-Kits/HelperClasses/HelperClassesData";

function OtherAccounts({ toggleDropdown }: any) {

  

  const [assistantData, setAssistantData] = useState([]);

  const [getAssistantAccountApiCall, { isLoading: AssistantDataLoading }] =
    useGetAssistantAccountMutation();
  const [switchAssitantAccountApiCall, { isLoading: switchAssistantLoading }] =
    useSwitchAssitantAccountMutation();

  const { userInfo } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getAssistantAccountData = async () => {
    try {
      const response = await getAssistantAccountApiCall().unwrap();
      const { data, success }: any = response;
      
      if (success === true) {
        setAssistantData(data);
      } else {
        toast.error("Failed to fetch assistant accounts");
      }
    } catch (error: any) { 
      toast.error("Error fetching assistant accounts");
    }
  };

  useEffect(() => {
    getAssistantAccountData();
  }, []);

  const redirect = AfterLoginRedirectUrl;

  const clickOnChooseAccount = async (id: number) => {
    try {
      const response = await switchAssitantAccountApiCall({
        account_id: id,
      }).unwrap();

      const { success, message, data } = response;
      if (success === true) {
        dispatch(setToken({ token: data?.token }));
        dispatch(setCredentials({ ...data }));
        dispatch(setChooseAccountFlag(true));
        navigate(redirect);
        deleteCookie();
        toast.success(message);
        toggleDropdown();
      } else {
        toast.error(message);
      }
    } catch (error: any) { 
      toast.error("Error switching account");
    }
  };

  if (AssistantDataLoading) return <div className="position-relative"> 
  <Loading className='position-relative' />
 </div>; 

  return (
    <>
      {assistantData.length > 0 && (
        <LI key="1111">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span>{"Other Accounts"}</span>
            </div>
          </div>
          <UL className="submenu">
            {assistantData.map((item): any => {
              const { id, email, avatar, username }: any = item;
              return (
                <LI
                  className="selected"
                  key={id}
                  onClick={() => clickOnChooseAccount(id)}
                >
                  <Link
                    to="javascript:void(0)"
                    className="d-flex align-items-center"
                  >
                    <img src={avatar} />
                    <span>{username}</span>
                  </Link>
                </LI>
              );
            })}
          </UL>
        </LI>
      )}
    </>
  );
}

export default OtherAccounts;
