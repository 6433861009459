import { ErrorMessage, Field, Formik } from 'formik';
import React, { useState } from 'react'
import { specialParametersFormValidationSchema } from '../../../../Data/Tools/BrandSupportTools/FormControlsValidation';
import { Col, Collapse, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import TitleInput from '../../../../Component/CommonInput/TitleInput';
import SubCategory from '../../../../Component/CommonInput/SubCategory';
import TextCounter from '../../../../Component/CommonInput/TextCounter';
import Country from '../../../../Component/CommonInput/Country';
import { useAppSelector } from '../../../../ReduxToolkit/Hooks';
import { Btn, H4 } from '../../../../AbstractElements';
import { ChevronDown, ChevronUp } from 'react-feather';
import Popup from '../../../../Component/MasterPopup/Popup';
import ImportBulk from '../ImportBulk/ImportBulk';

function AddCourseandTeacherInformation() {
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const formValues = useAppSelector((state) => state.form?.specialParameters);
    const [openQestionModal, setOpenQestionModal] = useState(false);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
  return (
    <div>
          
                  
                           <Formik
                                            initialValues={formValues}
                                            onSubmit={(values, { setSubmitting }) => {
                                              // Handle form submission logic here
                                              console.log('Form values:', values);
                                              setSubmitting(false); // If you're doing async work, ensure to setSubmitting(false)
                                            }}
                                            validationSchema={specialParametersFormValidationSchema}
                                            enableReinitialize={true}
                                        >
                                            {({ errors, values, setFieldValue,handleSubmit }) => (
                  <Form className="form-wizard" onSubmit={(e) => { handleSubmit(e); console.log(values); }}> 
                   <div className='my-2'>        
                    <Btn onClick={openQestionModalToggle} className='p-0 child_btn border rounded'   ><span  >Import Bulk Course and Teacher Information</span></Btn>
                    </div>

                        <Row>
                        <H4 className='mb-2'> Course and Teacher Information</H4>
                <Col sm="4">
                <FormGroup>
                                                          
                <TitleInput title='Unique Title or Course and Teacher Information' values={values.title} name="title"  placeholder="Title"/>  
                                                  
                                                                                                      </FormGroup>
                </Col> 
                
                <Col sm="4" className="mb-3">
                    <Label check>Course</Label>
                    <Field
                        type="text"
                        name="course"
                        maxLen
                        gth="140"
                        placeholder="Course"
                        className="form-control"
                    />
                    <TextCounter
                        length={values?.course?.length}
                        totalLength="140"
                    />
                </Col>
               
                <Col sm="4" className="mb-3">
                    <Label check>Teacher</Label>
                    <Field
                        type="text"
                        name="course"
                        maxLen
                        gth="140"
                        placeholder="Course"
                        className="form-control"
                    />
                    <TextCounter
                        length={values?.course?.length}
                        totalLength="140"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Book</Label>
                    <Field
                        type="text"
                        name="book"
                        maxLength="140"
                        placeholder="Book"
                        className="form-control"
                    />
                    <TextCounter
                        length={values?.book?.length}
                        totalLength="140"
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Official Exam Name (optional)</Label>
                    <Field
                        type="text"
                        name="exam"
                        maxLength="140"
                        placeholder="Exam"
                        className="form-control"
                    />
                    <TextCounter
                        length={values?.exam?.length}
                        totalLength="140"
                    />
                </Col> 
               
            </Row> 
            <Popup
                title='Import Bulk Course and Teacher Information '
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <ImportBulk/>
            </Popup>
             <button type="submit">Submit</button>
                    </Form> 
                          )}
                </Formik> 
                     
               
              
    </div>
  )
}

export default AddCourseandTeacherInformation