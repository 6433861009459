import { useState } from "react";
import { BrandTools, ManageExternalTitle } from "../../../../utils/Constant";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
    ActionButtons,
    ExternalLinkTableAction,
    links,
    ManagePostsTableAction,
    supportTicketData,
} from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import { SupportDataType } from "../../../App/AccountSettings/MyResumes";
import { TableHeadColumn } from "../../../App/AccountSettings/MyRegistrationPlan";
import Popup from "../../../../Component/MasterPopup/Popup";
import ExternalLinkView from "../../../../Component/Tools/BrandSupportTools/ManageExternalLink/ExternalLinkView";
import ExternalLinkEdit from "../../../../Component/Tools/BrandSupportTools/ManageExternalLink/ExternalLinkEdit";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import { Btn } from "../../../../AbstractElements";
import FequentlyModalForm from "../FequentlyQuestion/FequentlyModalForm";
import {
    FequentlyinitialValues,
    FequentlyValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { Formik } from "formik";
import ImportBulk from "../ImportBulk/ImportBulk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const ManagePost = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const [selectedData, setSelectedData] = useState();
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const handleRowSelected = () => {};

    const openPopup = (type: boolean) => {
        setIsOpen(!isOpen);
        setIsEditOpen(type);
    };

    const openModalToggle = () => {
        setIsOpen(!isOpen);
    };
    const handleIdClick = () => setOpenModal(!openModal);

    const [openQestionModal, setOpenQestionModal] = useState(false);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
    const [openBulkModal, setOpenBulkModal] = useState(false);
    const openBulkModalToggle = () => setOpenBulkModal(!openBulkModal);

    const externalColumns: TableColumn<SupportDataType>[] = [
        {
            name: <TableHeadColumn title={`ID`} />,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`By`} />,
            selector: (row) => row["by"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Image’`} />,
            selector: (row) => row["image"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Title`} />,
            selector: (row) => row["name"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Number of Applications`} />,
            selector: (row) => row["applications"],
            cell: (row) => (
                <span
                    onClick={() => handleIdClick(row["applications"])}
                    style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                    }}
                >
                    {row["applications"]}
                </span>
            ),
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Applicant Username`} />,
            selector: (row) => row["username"],
            cell: (row) => (
                <Link
                    to="/about"
                    style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                    }}
                >
                    {row["applications"]}
                </Link>
            ),
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Application Date/ Time`} />,
            selector: (row) => row["date"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Current Status `} />,
            selector: (row) => row["category"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Category`} />,
            selector: (row) => row["category"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Post Type`} />,
            selector: (row) => row["posttype"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Description`} />,
            selector: (row) => row["description"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Keywords`} />,
            selector: (row) => row["name"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Date`} />,
            selector: (row) => row["date"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Display on My 1Webbb Center`} />,
            selector: (row) => row["name"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Actions`} />,
            cell: (row) => (
                <>
                    <Btn
                        size="sm"
                        color="primary"
                        className="mx-1 px-2"
                        onClick={() => openPopup("share")}
                    >
                        <FontAwesomeIcon icon={fas.faEye} size={`sm`} />
                    </Btn>
                    <Btn
                        size="sm"
                        color="primary"
                        className="mx-1 px-2"
                        onClick={() => openPopup("share")}
                    >
                        <FontAwesomeIcon icon={fas.faCheck} size={`sm`} />
                    </Btn>
                    <Btn
                        size="sm"
                        color="primary"
                        className="mx-1 px-2"
                        onClick={() => openPopup("share")}
                    >
                        <FontAwesomeIcon icon={fas.faThumbsDown} size={`sm`} />
                    </Btn>
                    <Btn
                        size="sm"
                        color="primary"
                        className="mx-1 px-2"
                        onClick={() => openPopup("share")}
                    >
                        <FontAwesomeIcon icon={fas.faList} size={`sm`} />
                    </Btn>
                    <Btn
                        size="sm"
                        color="primary"
                        className="mx-1 px-2"
                        onClick={() => openPopup("share")}
                    >
                        <FontAwesomeIcon icon={fas.faMessage} size={`sm`} />
                    </Btn>
                    <Btn
                        size="sm"
                        color="primary"
                        className="mx-1 px-2"
                        onClick={() => openPopup("share")}
                    >
                        <FontAwesomeIcon icon={fas.faTrash} size={`sm`} />
                    </Btn>
                </>
            ),
            center: false,
            right: true,
            grow: 3,
        },
    ];

    return (
        <div className="page-body">
             <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    <SubMenuPopup currentPage="Post List" />
                </div>
             <Breadcrumbs
                mainParent="All Tools"
                mainTitle={`${`Manage Posts`} (${BrandTools})`}
                parent={BrandTools}
            />
            </div> 
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <div className="mt-2">
                                {ManagePostsTableAction.map((action, index) => {
                                   
                                        return (
                                            <Btn
                                                className="p-0" 
                                            >
                                                <span
                                                    className={`border rounded p-2 m-1 bg-dark text-white  ps-2 pe-2  `}
                                                >
                                                    {action.actionName}
                                                </span>
                                            </Btn>
                                        ); 
                                     
                                })}
                            </div>
                            <CardBody className="p-2">
                                <>
                                    <div className="table-responsive pt-2">
                                        <DataTable
                                            responsive
                                            className="custom-scrollbar"
                                            columns={externalColumns}
                                            data={data}
                                            striped={true}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={
                                                handleRowSelected
                                            }
                                            clearSelectedRows={toggleDelete}
                                        />
                                    </div>

                                    <Popup
                                        title={`External Links`}
                                        openModalToggle={openModalToggle}
                                        isOpen={isOpen}
                                        size={`xl`}
                                    >
                                        {isEditOpen ? (
                                            <ExternalLinkEdit />
                                        ) : (
                                            <ExternalLinkView />
                                        )}
                                    </Popup>
                                    <Popup
                                        title="Post Information"
                                        openModalToggle={handleIdClick}
                                        isOpen={openModal}
                                        size={`lg`}
                                        backdrop={true}
                                        CommonFooter={true}
                                        isClose={false}
                                        CommonFooter={false}
                                        BodyClassName="pt-1"
                                        largesize="modal-custom"
                                    >
                                        <div>
                                            <p>
                                                <strong>Post Type:</strong> Job
                                                Posting
                                            </p>
                                            <p>
                                                <strong>Post Title:</strong>
                                                Frontend Developer Needed
                                            </p>
                                            <p>
                                                <strong>
                                                    Number of Applicants:
                                                </strong>
                                                25
                                            </p>
                                            <p>
                                                <strong>
                                                    Applicant Username:
                                                </strong>
                                                <Link to="/about">Chingu</Link>
                                            </p>
                                            <p>
                                                <strong>
                                                    Application Date/ Time:
                                                </strong>
                                                25/2/25/ 5:00 Am
                                            </p>
                                            <p>
                                                <strong>
                                                    Current Status :
                                                </strong>
                                                Active
                                            </p>
                                            <p>
                                                <strong>Action : </strong>
                                                Approve/Disapprove /Add to Short
                                                List/Send a message/Delete
                                            </p>
                                        </div>
                                    </Popup>
                                    <Popup
                                        title="Publish Online"
                                        openModalToggle={openQestionModalToggle}
                                        isOpen={openQestionModal}
                                        size={`lg`}
                                        backdrop={true}
                                        CommonFooter={true}
                                        isClose={false}
                                        CommonFooter={false}
                                        BodyClassName="pt-1"
                                        largesize="modal-custom"
                                    >
                                        <Formik
                                            initialValues={
                                                FequentlyinitialValues
                                            }
                                            validationSchema={
                                                FequentlyValidationSchema
                                            }
                                            onSubmit={(values) => {
                                                console.log(
                                                    "Selected values:",
                                                    values
                                                );
                                            }}
                                        >
                                            {({
                                                handleChange,
                                                values,
                                                errors,
                                                setFieldValue,
                                            }) => (
                                                <FequentlyModalForm
                                                    selectField="article"
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    handleChange={handleChange}
                                                    values={values}
                                                    submitErrors={submitErrors}
                                                    errors={errors}
                                                />
                                            )}
                                        </Formik>
                                    </Popup>
                                    <Popup
                                        title="Import Bulk Post"
                                        openModalToggle={openBulkModalToggle}
                                        isOpen={openBulkModal}
                                        size={`lg`}
                                        backdrop={true}
                                        CommonFooter={true}
                                        isClose={false}
                                        CommonFooter={false}
                                        BodyClassName="pt-1"
                                        largesize="modal-custom"
                                    >
                                        <ImportBulk />
                                    </Popup>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ManagePost;
