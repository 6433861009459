import { Card, Col, Container, Input, Label, Row } from "reactstrap";
import UserBanner from "../../../Component/App/User/UserBanner";
import { Btn, Image, Progressbar } from "../../../AbstractElements";
import { Home } from "../../../utils/Constant";
import { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { dynamicImage } from "../../../Service";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import Popup from "../../../Component/MasterPopup/Popup";
import { TableHeadColumn } from "./MyRegistrationPlan";
import { Formik } from "formik";
import AddResume from "../../../Component/App/AccountSettings/MyResumes/AddEditResume";
import WorkAvailabilityForm from "./WorkAvailabilityForm";
import {
  WorkAvailabiltyValidationProp,
  workAvailabilityFormInitialValue,
  workAvailabilityFormSchema,
} from "../../../Data/App/AccountSettings/MyResumesAddNew";
import { useGetResumeDataMutation } from "../../../ReduxToolkit/Reducers/Resume/resumeApiSlice";
import { useSaveWorkAvailabilityMutation } from "../../../ReduxToolkit/Reducers/Resume/resumeApiSlice";
import { toast } from "react-toastify";
import Loading from "../../../Component/Loading/Loading";

export const resumesData = [
  {
    id: 1,
    name: "James",
    image: "user/5.jpg",
    email: "start@gmail.com",
    skill: 100,
    skillColor: "info",
  },
  {
    id: 2,
    name: "Kelvin",
    image: "user/5.jpg",
    email: "raft@gmail.com",
    skill: 90,
    skillColor: "danger",
  },
];

export interface ImageDataProp {
  image: string;
  title: string;
}

const ImageData: React.FC<ImageDataProp> = ({ image, title }) => {
  return (
    <div className="d-flex">
      <Image
        className="rounded-circle img-30 me-3"
        src={dynamicImage(`${image}`)}
        alt="Generic placeholder image"
      />
      <div className="flex-grow-1 align-self-center">
        <div>{title}</div>
      </div>
    </div>
  );
};

export interface SupportDataType {
  id: number;
  image: string;
  name: string;
  posttype: string;
  description: string;
  category: string;
  email: string;
  skill: number;
  skillColor: string;
  date: string;
  applications: string;
}

export interface ActionButtonProps {
  action: number;
}

const ActionButtons: React.FC<ActionButtonProps> = ({ action }) => {
  return (
    <div className="d-flex justify-content-end">
      <Btn size="sm" color="primary" className="mx-2 px-2">
        <i className="fa fa-pencil" />
      </Btn>
      <Btn size="sm" color="success" className="mx-2 px-2">
        <i className="fa fa-link" />
      </Btn>
      <Btn size="sm" color="danger" className="mx-2 px-2">
        <i className="fa fa-trash" />
      </Btn>
    </div>
  );
};

export const resumesColumn: TableColumn<SupportDataType>[] = [
  {
    name: "Image",
    cell: (row) => <ImageData image={row.image} title={row.name} />,
    sortable: true,
    center: false,
  },
  {
    name: "Name",
    selector: (row) => row["name"],
    sortable: true,
    center: false,
  },
  {
    name: "Email",
    selector: (row) => row["email"],
    sortable: true,
    center: false,
  },
  {
    name: "Skill",
    cell: (row) => <SkillsData value={row.skill} skillColor={row.skillColor} />,
    sortable: true,
    center: true,
  },
  {
    name: <TableHeadColumn title={`Actions`} />,
    cell: (row) => <ActionButtons action={1} />,
    sortable: true,
    right: true,
  },
];

export interface SkillsDataProp {
  value: number;
  skillColor: string;
}

const SkillsData: React.FC<SkillsDataProp> = ({ value, skillColor }) => {
  return (
    <div className="progress-showcase" style={{ width: "86px" }}>
      <Progressbar value={value} color={skillColor} style={{ height: "8px" }} />
    </div>
  );
};

const MyResumes = () => {
  const [submitErrors, setSubmitErrors] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState(resumesData);
  const [openModal, setOpenModal] = useState(false);
  const [resumeData, setResumeData] = useState<any>();

  const [getResumeDataCallApi, { isLoading: fetchResumeLoading }] =
    useGetResumeDataMutation();

  const getResumeData = async () => {
    try {
      const response = await getResumeDataCallApi().unwrap();
      const { data, message, success } = response;
      if (success === true) {
        setResumeData(data);
      }
    } catch (error: any) {
      console.log(error.data.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    getResumeData();
  }, []);

  const handleDelete = () => {
    if (
      window.confirm(
        `Are you sure you want to delete:\r ${selectedRows.map(
          (r: SupportDataType) => r.name
        )}?`
      )
    ) {
      setToggleDelete(!toggleDelete);
      setData(
        data.filter((item) =>
          selectedRows.filter((elem: SupportDataType) => elem.id === item.id)
            .length > 0
            ? false
            : true
        )
      );
      setSelectedRows([]);
    }
  };

  const [saveWorkAvailabillityApiCall, { isLoading }] =
    useSaveWorkAvailabilityMutation();

  const handleSubmit = async (values: WorkAvailabiltyValidationProp) => {
    console.log(values);
    try {
      const response = await saveWorkAvailabillityApiCall(values).unwrap();
      setOpenModal(!openModal);
    } catch (error: any) {
      toast.error(error?.data?.message);
    }

    setSubmitErrors(false);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={`Manage Resume`} parent={Home} />
      <UserBanner />
      <Container fluid>
        <Card>
          <Row className="px-3 py-1 d-flex justify-content-center">
            <Col xl="12" className="py-2">
              <Btn
                className="me-1 btn-dark"
                onClick={() => setOpenModal(!openModal)}
              >
                Work Availability
              </Btn>
              <Popup
                title={`Work Availability`}
                openModalToggle={() => setOpenModal(!openModal)}
                isOpen={openModal}
                size={`lg`}
                backdrop={true}
                isClose={false}
                CommonFooter={false}
              >
                <div className="mx-4 my-2">
                  <Formik
                    initialValues={workAvailabilityFormInitialValue}
                    onSubmit={handleSubmit}
                    validationSchema={workAvailabilityFormSchema}
                  >
                    {({ errors, values, setFieldValue }) => (
                      <WorkAvailabilityForm
                        errors={errors}
                        values={values}
                        setFieldValue={setFieldValue}
                        setOpenModal={setOpenModal}
                        openModal={openModal}
                        setSubmitErrors={setSubmitErrors}
                        submitErrors={submitErrors}
                        resumeData={resumeData}
                      />
                    )}
                  </Formik>
                </div>
              </Popup>
            </Col>
          </Row>
          <Row>
            <Col xxl="12" xl="12" className="box-col-8">
              <AddResume
                resumeData={resumeData}
                getResumeData={getResumeData}
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
};

export default MyResumes;
