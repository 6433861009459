import { apiSlice } from "../../apiSlice";
import { ADMIN_WEBSITE_SETTINGS_LIST } from "../../../Api";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const websiteSettingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWebsiteSettingData: builder.mutation<SuccessDataProps, any>({
      query: (body) => ({
        url: ADMIN_WEBSITE_SETTINGS_LIST,
        method: "POST",
        body: body
      }),
    }), 
  }),
});

export const { 
    useGetWebsiteSettingDataMutation
} = websiteSettingsApiSlice;
