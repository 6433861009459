import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_api } from "../Api";
import { logout } from "./Reducers/Authentication/authSlice";
//test
const baseQuery = fetchBaseQuery({
  baseUrl: base_api,
  prepareHeaders: (headers, { getState }) => {
    const tokenData = JSON.parse(localStorage.getItem("token")!)
      ? JSON.parse(localStorage.getItem("token")!)
      : false;
    if (tokenData) {
      const { token }: any = tokenData;
      headers.set("Authorization", `Bearer ${token !== undefined && token}`);
      //headers.set("X-CSRF-TOKEN",document.querySelector('meta[name="csrf-token"]').content);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // Handle unauthorized response globally
    console.error("Unauthorized! Redirecting to login...");
    // Optionally, dispatch an action to clear auth state
    api.dispatch(logout());
    // Redirect to login
    window.location.href = "/login"; 
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Country",
    "State",
    "City",
    "Language",
    "Plans",
    "Type",
    "Authentication",
  ],
  endpoints: (builder) => ({}),
});



  // REFRESH TOKEN CODE */
  /* const refreshResult : any = await baseQuery('/auth/refresh', api, extraOptions);
  if (refreshResult.data) {
    // Update the token in state
    api.dispatch({ type: 'auth/updateToken', payload: refreshResult.data.token });
    // Retry the original request with the new token
    result = await baseQuery(args, api, extraOptions);
  } else {
    // If refresh fails, log out the user
    api.dispatch({ type: 'auth/logout' });
    window.location.href = '/login';
  } */ 