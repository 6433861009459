import { useEffect, useState } from "react";
import { useAppDispatch} from "../../../ReduxToolkit/Hooks";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { MarketplaceText, Parent1Web } from "../../../utils/Constant";
import { productsData } from "../../../Data/Application/Ecommerce/Product";
import { setProductItem } from "../../../ReduxToolkit/Reducers/ProductSlice";
import ProductFeatures from "../Ecommerce/Products/ProductFeatures/ProductFeatures";
import ProductGrid from "../Ecommerce/Products/ProductGrid/ProductGrid";
import ProductList from "./ProductList";
import DefaultFilter from "../Ecommerce/Products/ProductFeatures/DefaultFilter";
import DeliveryService from "../../App/Marketplace/DeliveryService";
import { Link, useLocation } from "react-router-dom";

export const productst= [
  { id:1, src:"faq/1.jpg", name:"", fillStar:true},
  { id:2, src:"faq/1.jpg", name:"", fillStar:true},
  { id:3, src:"faq/1.jpg", name:"", fillStar:true},
  { id:4, src:"faq/1.jpg", name:"", fillStar:true},
  { id:5, src:"faq/1.jpg", name:"", fillStar:true},
  { id:6, src:"faq/1.jpg", name:"", fillStar:true},
  { id:7, src:"faq/1.jpg", name:"", fillStar:true},
  { id:8, src:"faq/1.jpg", name:"", fillStar:true},
  { id:9, src:"faq/1.jpg", name:"", fillStar:true},
  { id:10, src:"faq/1.jpg", name:"", fillStar:true},    
];


const KnowledgeLessonContainer = ( ) => {
  const [sideBarOn, setSideBarOn] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setProductItem(productsData));
  }, []);
  const location = useLocation();
  return (
    <>
      <Breadcrumbs mainTitle={'knowledge'} parent={Parent1Web} />
      <Container fluid className={`product-wrapper ${sideBarOn ? "sidebaron" : ""}`} >       
        {/* <DeliveryService/> */}
        <div className="mb-2">
        <Link 
        to='/knowledge/lessons' 
        className={`border p-2 px-3 me-2 rounded text-dark d-inline-block ${location.pathname === '/knowledge/lessons' ? 'btn-primary text-white' : ''}`}
      >
        Lesson
      </Link>
      <Link 
        to='/knowledge/quizzes-and-tests' 
        className={`border p-2 rounded text-dark px-3 d-inline-block ${location.pathname === '/knowledge/quizzes-and-tests' ? 'btn-primary text-white' : ''}`}
      >
        Quizzes and Tests
      </Link>
        </div>
        <DefaultFilter remove={true}/>      
        <div className="product-grid">
          {/* <ProductFeatures /> */}
          <ProductGrid knowledgeModal={true} modalBodyClassName="modal-custom"/>
          {/* <ProductList/> */}
        </div>
      </Container>
    </>
  );
};

export default KnowledgeLessonContainer;
