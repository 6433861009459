import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
} from "reactstrap";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { Btn, H3, H4, H5, LI, UL } from "../../../AbstractElements";
import { Keywords,
    PostContent, } from "../../../utils/Constant"; 
import { useState } from "react";
import Country from "../../CommonInput/Country";
import TextCounter from "../../CommonInput/TextCounter";
import TagsInput from "../../CommonInput/TagsInput";
import {
    createPostInitialValue,
    createPostSchema,
} from "../../../Data/Post/CreatePostValidationSchema"; 
import * as Yup from "yup";
import CreatePostForm from "./CreatePostForm";
import CustomSelect from "../../CommonInput/CustomSelect";
import City from "../../CommonInput/City";
import ScheduleType from "../../CommonInput/ScheduleType"; 
import DescriptionInput from "../../CommonInput/DescriptionInput";
import TitleInput from "../../CommonInput/TitleInput";
import LongDescription from "../../CommonInput/LongDescription";
import { options } from "../../Application/Ecommerce/AddProduct/AddProduct";
import {
    FequentlyinitialValues,
    FequentlyValidationSchema,
    scheduleFormInitialValue,
    scheduleFormValidationSchema,
} from "../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import AddFaqForm from "../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/AddFaqForm";
import Popup from "../../MasterPopup/Popup";
import Notes from "../../../Pages/Tools/Notes/Notes";
import FequentlyModalForm from "../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyModalForm";
import { Link } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import ReactDatePicker from "react-datepicker";
import SharingSection from "../../Tools/BrandSupportTools/SurveyAndQuizzes/SharingSection";

export const postschema = Yup.object().shape({});
const CreatePostContainer = () => {
    const [postType, setPostType] = useState<number | null>(1);
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const handleSubmit = () => {};
    const [openToModal, setOpenToModal] = useState(false);
    const openToModalToggle = () => {
        setOpenToModal(!openToModal);
    };

    const [openFromModal, setOpenFromModal] = useState(false);
    const [images, setImages] = useState([] as any);
    const [imageMaxLength, setImageMaxLength] = useState(false);
    const imageURLS = useState([]);
    const [openPublishModal, setOpenPublishModal] = useState(false);
    const openPublishModalToggle = () => setOpenPublishModal(!openPublishModal);
    const [openFaqModal, setOpenFaqModal] = useState(false);
    const openFaqModalToggle = () => setOpenFaqModal(!openFaqModal);
    const onImageChange = (e: any) => {
        let totalfiles = e.target.files.length;
        if (totalfiles < 1) return;
        else if (totalfiles > 10) {
            setImages([]);
            setImageMaxLength(true);
            e.target.value = "";
            return;
        } else if (totalfiles > 0 && totalfiles <= 10) {
            setImages([...e.target.files]);
        }

        images.forEach((image: any) => {
            if (imageURLS.length < 10) {
                imageURLS.push(URL.createObjectURL(image));
            }
        });
    };
    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
    const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];
    const scheduleOptions = [
        { label: "Full Time", value: "full-time" },
        { label: "Part Time", value: "part-time" },
        { label: "Freelancing", value: "freelancing" },
    ];
    return (
        <Container fluid>
            <Row className="px-2">
                <Col sm="12">
                    <Card>
                        <CardBody className="add-post">
                            <Formik
                                initialValues={createPostInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={createPostSchema}>
                                {({
                                    errors,
                                    values,
                                    setFieldValue,
                                    handleChange,
                                }) => (
                                    <>
                                        <Form>
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label check>
                                                            {`Post Type`}{" "}
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </Label>
                                                        <div className="px-2">
                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_upload"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            1
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        1
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_upload">
                                                                    <H5>
                                                                        Article
                                                                        or Blog
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadc"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            2
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        2
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadc">
                                                                    <H5>
                                                                        Customer’s
                                                                        Wish of
                                                                        a
                                                                        Product
                                                                        or a
                                                                        Service
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadj"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            3
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        3
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadj">
                                                                    <H5>
                                                                        Job
                                                                        Opening:
                                                                        you want
                                                                        to hire
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadn"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            4
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        4
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadn">
                                                                    <H5>
                                                                        Student
                                                                        Registration
                                                                        Period
                                                                    </H5>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                    </Col>
                                                    </Row>
                                                    <Row> 
                                                    {postType === 1 && (
                                                        <>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <TitleInput
                                                                    title="Unique Title"
                                                                    values={
                                                                        values.Title
                                                                    }
                                                                    name="Title"
                                                                    placeholder="Title"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <Label check>
                                                                Image
                                                            </Label>
                                                            <FormGroup>
                                                                <Field
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.image
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    name="image"
                                                                    type="file"
                                                                    multiple
                                                                    accept="image/*"
                                                                    onChange={
                                                                        onImageChange
                                                                    }
                                                                />
                                                                {images.length >
                                                                10 ? (
                                                                    <span className="text-danger">
                                                                        Max
                                                                        limit 10
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <ErrorMessage
                                                                    name="image"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                                <ul>
                                                                    Total Image
                                                                    :{" "}
                                                                    {
                                                                        images.length
                                                                    }
                                                                </ul>
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                            </Col>
                                                            <Row>
                                                            <Col sm='12'> 
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <DescriptionInput
                                                                            name="description"
                                                                            placeholder="Description/Summary"
                                                                            values={
                                                                                values.description
                                                                            }
                                                                            lable="Description/Summary (optional)"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            </Col>
                                                            <Col
                                                                sm="12">
                                                                <FormGroup>
                                                                    <LongDescription
                                                                        lable={
                                                                            PostContent
                                                                        }
                                                                        name="aarticalDescription"
                                                                        value={
                                                                            values.aarticalDescription
                                                                        }
                                                                        id="editor_container"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                    {postType === 2 && (
                                                        <>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <TitleInput
                                                                    title="Unique Title"
                                                                    values={
                                                                        values.customer_Title
                                                                    }
                                                                    name="customer_Title"
                                                                    placeholder="Title"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <Label check>
                                                                Image
                                                            </Label>
                                                            <FormGroup>
                                                                <Field
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.image
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    name="image"
                                                                    type="file"
                                                                    multiple
                                                                    accept="image/*"
                                                                    onChange={
                                                                        onImageChange
                                                                    }
                                                                />
                                                                {images.length >
                                                                10 ? (
                                                                    <span className="text-danger">
                                                                        Max
                                                                        limit 10
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <ErrorMessage
                                                                    name="image"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                                <ul>
                                                                    Total Image
                                                                    :{" "}
                                                                    {
                                                                        images.length
                                                                    }
                                                                </ul>
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <FormGroup>
                                                                    <Label
                                                                        check>
                                                                        Product
                                                                        or
                                                                        Service
                                                                        Name{" "}
                                                                        <span className="txt-danger">
                                                                            *
                                                                        </span>
                                                                    </Label>
                                                                    <Field
                                                                        name={` excatNumber`}
                                                                        as="select"
                                                                        className={`form-control  `}>
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }>
                                                                            select
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                "equal_among_winners"
                                                                            }>
                                                                            value
                                                                            1
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                "separate_prizes_for_each_winner"
                                                                            }>
                                                                            value
                                                                            2
                                                                        </option>
                                                                    </Field>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='12'>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <DescriptionInput
                                                                            name="customer_description"
                                                                            placeholder="Description "
                                                                            values={
                                                                                values.customer_description
                                                                            }
                                                                            lable="Description"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    External
                                                                    Links{" "}
                                                                    <span className="txt-danger">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                <Field
                                                                    name="externallink"
                                                                    type="text"
                                                                    placeholder="Enter your external link"
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.externallink
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="externallink"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Vender’s Country`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="country"
                                                                    placeholder="Any Country’"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Desired Budget`}
                                                                    :
                                                                </Label>

                                                                <ErrorMessage
                                                                    name="desired_budget"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Max Budget`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    type="number"
                                                                    name="max_budget"
                                                                    placeholder="Enter max budget"
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.max_budget
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="max_budget"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                        </>
                                                    )}
                                                    {postType === 3 && (
                                        <>
                                                        <Col sm='4'>
                                                            <FormGroup>
                                                                <TitleInput
                                                                    title="Unique Title"
                                                                    values={
                                                                        values.job_Title
                                                                    }
                                                                    name="job_Title"
                                                                    placeholder="Title"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm='4'>
                                                            <Label check>
                                                                Image
                                                            </Label>
                                                            <FormGroup>
                                                                <Field
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.image
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    name="image"
                                                                    type="file"
                                                                    multiple
                                                                    accept="image/*"
                                                                    onChange={
                                                                        onImageChange
                                                                    }
                                                                />
                                                                {images.length >
                                                                10 ? (
                                                                    <span className="text-danger">
                                                                        Max
                                                                        limit 10
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <ErrorMessage
                                                                    name="image"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                                <ul>
                                                                    Total Image
                                                                    :{" "}
                                                                    {
                                                                        images.length
                                                                    }
                                                                </ul>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col
                                                                sm="4"
                                                                className="mb-3">
                                                                <Label check>
                                                                    A Previous
                                                                    Job Opening
                                                                    as a
                                                                    Reference
                                                                    (optional){" "}
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-control"
                                                                    name="reference-coupon">
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }>
                                                                        Select a
                                                                        Reference
                                                                    </option>
                                                                </Field>
                                                        </Col>
                                                        <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm='4'>
                                                            <Label check>
                                                                    {`Required Skills (keywords) (optional)`}
                                                                    :
                                                                </Label>
                                                            <FormGroup>
                                                                <div className=" col-form-Label"> 
                                                                    <TagsInput
                                                                        name="skillskeywords"
                                                                        placeholder="Add keywords"
                                                                        className="w-100"
                                                                        setFieldValue={
                                                                            setFieldValue
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Minimum Years of experience`}{" "}
                                                                    <span className=" ">
                                                                        *
                                                                    </span>
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    type="text"
                                                                    name="employexperience"
                                                                    placeholder={`Minimum Years of experience`}
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.employexperience
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="employexperience"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Hiring Duration`}{" "}
                                                                    <span className=" ">
                                                                        *
                                                                    </span>
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    name="employjobLocationType"
                                                                    className="form-control">
                                                                    <option value="">{`1 Day`}</option>
                                                                    <option value="remote">{`Up to 1 Week `}</option>
                                                                    <option value="specific-address">{`1 Week to 1 Month`}</option>
                                                                    <option value="specific-address">{`1 Month to 3 Months `}</option>
                                                                    <option value="specific-address">{`3 Months to 6 Months `}</option>
                                                                    <option value="specific-address">{`6 Months to 1 Year`}</option>
                                                                    <option value="specific-address">{`1 Year to 2 Years`}</option>
                                                                    <option value="specific-address">{`Long Term`}</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            {/* Schedule Type */}
                                                            <Col sm='4'>                                                                    
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Schedule Type`}
                                                                    :
                                                                </Label>
                                                                <ScheduleType
                                                                    name="employjobLocationType"
                                                                    placeholder="Select Schedule Type"
                                                                    Options={
                                                                        scheduleOptions
                                                                    }
                                                                    className={`${
                                                                        errors &&
                                                                        `${
                                                                            errors.employjobLocationType
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                                    <Col sm='4'>
                                                            {/* salary */}
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Salary`}:
                                                                </Label>
                                                                <Field
                                                                    type="text"
                                                                    name="employsalary"
                                                                    placeholder={`Salary`}
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.employperiodMonths
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="employsalary"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                                    <Col sm='4'>
                                                            {/* Job Location Type */}
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Job Location Type`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    name="employjobLocationType"
                                                                    className="form-control">
                                                                    <option value="">{`Select Job Location Type`}</option>
                                                                    <option value="remote">{`Remote`}</option>
                                                                    <option value="specific-address">{`Specific Address`}</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                                    <Col sm='4'>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Job Location`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="jobcountry"
                                                                    placeholder="Any Country’"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="jobcountry"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4'>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <DescriptionInput
                                                                            name="job_description"
                                                                            placeholder="Description "
                                                                            values={
                                                                                values.job_description
                                                                            }
                                                                            lable="Description"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            </Col>
                                </>
                                                    )}

                                                    {postType === 4 && (
                                                        <>
                                                        <Col sm='4' >
                                                            <FormGroup>
                                                                <TitleInput
                                                                    title="Unique Title"
                                                                    values={
                                                                        values.job_Title
                                                                    }
                                                                    name="job_Title"
                                                                    placeholder="Title"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4' >
                                                            <Label check>
                                                                Image
                                                            </Label>
                                                            <FormGroup>
                                                                <Field
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.image
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    name="image"
                                                                    type="file"
                                                                    multiple
                                                                    accept="image/*"
                                                                    onChange={
                                                                        onImageChange
                                                                    }
                                                                />
                                                                {images.length >
                                                                10 ? (
                                                                    <span className="text-danger">
                                                                        Max
                                                                        limit 10
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <ErrorMessage
                                                                    name="image"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                                <ul>
                                                                    Total Image
                                                                    :{" "}
                                                                    {
                                                                        images.length
                                                                    }
                                                                </ul>
                                                            </FormGroup>
                                                            </Col>
                                                            <Col
                                                                sm="4"
                                                                className="mb-3">
                                                                <Label check>
                                                                    A Previous
                                                                    Student
                                                                    Registration
                                                                    Period as a
                                                                    Reference
                                                                    (optional){" "}
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-control"
                                                                    name="reference-coupon">
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }>
                                                                        Select a
                                                                        Reference
                                                                    </option>
                                                                </Field>
                                                            </Col>
                                                            <Col sm='4' >
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4' >
                                                            <FormGroup>
                                                                <div className=" col-form-Label">
                                                                    {
                                                                        "Required Skills (keywords)"
                                                                    }
                                                                    :
                                                                    <TagsInput
                                                                        name="skillskeywords"
                                                                        placeholder="Add keywords"
                                                                        className="w-100"
                                                                        setFieldValue={
                                                                            setFieldValue
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                            </Col>
                                                            {/* Schedule Type */}
                                                            <Col sm='4' >
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Schedule Type`}
                                                                    :
                                                                </Label>
                                                                <ScheduleType
                                                                    name="employjobLocationType"
                                                                    placeholder="Select Schedule Type"
                                                                    Options={
                                                                        scheduleOptions
                                                                    }
                                                                    className={`${
                                                                        errors &&
                                                                        `${
                                                                            errors.employjobLocationType
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4' > 
                                                            {/* salary */}
                                                            <FormGroup>
                                                                <DescriptionInput
                                                                    name="employsalary"
                                                                    placeholder="Tuition Fees other expenses in details "
                                                                    values={
                                                                        values.employsalary
                                                                    }
                                                                    lable="Tuition Fees other expenses in details"
                                                                />

                                                                <ErrorMessage
                                                                    name="employsalary"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4' >
                                                            {/* Job Location Type */}
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Study Location Type`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    name="employjobLocationType"
                                                                    className="form-control">
                                                                    <option value="">{`Select Study Location Type`}</option>
                                                                    <option value="remote">{`Remote`}</option>
                                                                    <option value="specific-address">{`Specific Address`}</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4' >
                                                            <FormGroup>
                                                                <Label>
                                                                    Registration
                                                                    Deadline{" "}
                                                                    <span className="txt-danger">
                                                                        {" "}
                                                                        *{" "}
                                                                    </span>
                                                                </Label>
                                                                <InputGroup className="flatpicker-calender">
                                                                    <ReactDatePicker
                                                                        className="form-control flatpickr-input"
                                                                        selected={
                                                                            values.registrationDeadline
                                                                        }
                                                                        onChange={(
                                                                            date
                                                                        ) =>
                                                                            setFieldValue(
                                                                                "registrationDeadline",
                                                                                date
                                                                            )
                                                                        }
                                                                        minDate={
                                                                            new Date()
                                                                        }
                                                                        placeholderText={`dd/mm/yyyy`}
                                                                        dateFormat="dd/MM/yyyy"
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4' >
                                                            <FormGroup>
                                                                <Label>
                                                                    Date of
                                                                    terminating
                                                                    this post{" "}
                                                                    <span className="txt-danger">
                                                                        {" "}
                                                                        *{" "}
                                                                    </span>
                                                                </Label>
                                                                <InputGroup className="flatpicker-calender">
                                                                    <ReactDatePicker
                                                                        className="form-control flatpickr-input"
                                                                        selected={
                                                                            values.dateofterminating
                                                                        }
                                                                        onChange={(
                                                                            date
                                                                        ) =>
                                                                            setFieldValue(
                                                                                "dateofterminating",
                                                                                date
                                                                            )
                                                                        }
                                                                        minDate={
                                                                            new Date()
                                                                        }
                                                                        placeholderText={`dd/mm/yyyy`}
                                                                        dateFormat="dd/MM/yyyy"
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='4' >
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Study Location`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="jobcountry"
                                                                    placeholder="Study Location "
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="jobcountry"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm='12' >
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <DescriptionInput
                                                                            name="job_description"
                                                                            placeholder="Description "
                                                                            values={
                                                                                values.job_description
                                                                            }
                                                                            lable="Description"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            </Col>
                                                        </>
                                                    )}
 
                                                
                                                    {postType === 3 ? (
                                                        ""
                                                    ) : (
                                                        <Col sm='4' className="mb-3">
                                                        <FormGroup>
                                                            <div className=" col-form-Label">
                                                                {`${Keywords} (optional)`}:
                                                                <TagsInput
                                                                    name="keywords"
                                                                    placeholder="Add keywords"
                                                                    className="w-100"
                                                                    setFieldValue={
                                                                        setFieldValue
                                                                    }
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                        </Col>
                                                    )}
                                              <SharingSection CoManagement={false} InviteeList={true} errors={errors} setFieldValue={setFieldValue} values={values}  />

                                                <Col sm="4" className="mb-3">
                                                <FormGroup>
                                                    <Label check>
                                                        {`Tests and Surveys (optional)`}
                                                        :
                                                    </Label>
                                                    <FormGroup floating>
                                                        <Field
                                                            className={`custom-select  `}
                                                            name="CategorySelect"
                                                            options={
                                                                cityOptions
                                                            }
                                                            component={
                                                                CustomSelect
                                                            }
                                                            placeholder="Select Tests and Surveys"
                                                            isMulti={true}
                                                        />
                                                        <Link
                                                            target="_blank"
                                                            to="/tools/brand-support-tools/knowledge/questionnaire-list/add-new"
                                                            className="ps-0 text-dark d-flex">
                                                            <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                                            Add a Questionnaire
                                                        </Link>
                                                    </FormGroup>
                                                </FormGroup>
                                                </Col>
                                                <Col sm="4" className="mb-3">
                                                    <Label check>
                                                        Frequently Asked
                                                        Questions (optional)
                                                    </Label>
                                                    <div className="">
                                                        <FormGroup>
                                                            <Input type="select">
                                                                <option>{`Select`}</option>
                                                                <option>
                                                                    Free for
                                                                    Commercial
                                                                    and
                                                                    Non-profit
                                                                    use
                                                                </option>

                                                                <option>
                                                                    Free for
                                                                    Non-Profit
                                                                    use only
                                                                </option> 
                                                            </Input>
                                                            <span className="text-muted small">
                                                                <Btn
                                                                    className="ps-0  d-flex"
                                                                    onClick={
                                                                        openFaqModalToggle
                                                                    }>
                                                                    <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                                                    Add
                                                                    Frequently
                                                                    Asked
                                                                    Questions
                                                                </Btn>
                                                            </span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col sm="4" className="mb-3">
                                                    <FormGroup>
                                                        <Label check>
                                                            {`Policy or Service Terms (optional)`}
                                                            :
                                                        </Label>
                                                        <FormGroup floating>
                                                            <Field
                                                                className={`custom-select  `}
                                                                name="PolicyService"
                                                                options={
                                                                    cityOptions
                                                                }
                                                                component={
                                                                    CustomSelect
                                                                }
                                                                placeholder="Select Tests and Surveys"
                                                                isMulti={true}
                                                            />
                                                            
                                                        </FormGroup>
                                                    </FormGroup>
                                                </Col>
                                               
                                                {postType === 4 ? ( 
                                                    <>
                                                     <Row>
                                                     <H4 className="mb-2">Require Documents</H4>
                                                 </Row>
                                                    <FieldArray name="documents">
        {({ push, remove }) => (
          <>
            {values.documents.map((doc, index) => (
              <div key={index} className="mb-4 border p-3 rounded">
                {/* Document Name (Fixed Labels) */}
                <Row>
                    <Col sm='4'>
                <Label className="fw-semibold mb-2">{doc.name}</Label>
                {/* Radio Buttons */}
                <div className="d-flex">
                  {["Unneeded", "Obligatory", "Optional"].map((option) => (
                    <div
                      key={option}
                      className="form-check form-check-inline radio radio-primary mx-3 px-2"
                    >
                      <Input
                        type="radio"
                        name={`documents[${index}].status`}
                        value={option}
                        checked={doc.status === option}
                        onChange={handleChange}
                        id={`radio_${option}_${index}`}
                      />
                      <Label className="mb-0" for={`radio_${option}_${index}`}>
                        {option}
                      </Label>
                    </div>
                  ))}
                </div>
                    </Col>
                 {doc.status != 'Unneeded' ? <Col sm='8'>
                  <DescriptionInput name={`documents[${index}].description`} placeholder="Enter description (optional)" lable="Description (optional)"/>
                 
                </Col>:''}
                </Row>
                {doc.status != 'Unneeded' ?<>
{["Statement of Purpose", "Essay"].includes(doc.name) && (
                  <>
                    <Label className="fw-semibold mt-2">Maximum Number of Characters</Label>
                    <Input
                      type="number"
                      name={`documents[${index}].maxCharacters`}
                      value={doc.maxCharacters}
                      onChange={handleChange}
                      placeholder="Enter max character limit"
                      min="1"
                      className="form-control"
                    />
                  </> 
                )}</>:''}
                {/* Remove Button */}
                
                {index >= 8 &&  (
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => remove(index)}
                    className="mt-2"
                  >
                    Remove Document
                  </Button>
                )}
                 
              </div>
            ))}

            {/* Add Another Document Button */}
            <div className="">
            <Button
              type="button"
              color="primary"
              onClick={() =>
                push({ name: `Document ${values.documents.length + 1}`, status: "Unneeded", description: "" })
              }
              className="mt-2 mb-3 d-inline-block"
            >
              + Add Another Document
            </Button>
            </div> 
          </>
        )}
      </FieldArray>
      </>):""}
                                           
                                           </Row>
                                        </Form>
                                        <div className="btn-showcase">
                                            <Btn
                                                color="primary"
                                                type="submit"
                                                onClick={() =>
                                                    setSubmitError(true)
                                                }>{`Save Offline`}</Btn>
                                            <Btn
                                                color="primary"
                                       
                                                onClick={
                                                    openPublishModalToggle
                                                }>{`Save and Publish Online`}</Btn>
                                            {/* <Btn color="light" type="reset">
                                        {`Cancel`}
                                        </Btn> */}
                                        </div>
                                    </>
                                )}
                            </Formik>
                            <Popup
                                            title="Publish Online"
                                            openModalToggle={
                                                openPublishModalToggle
                                            }
                                            isOpen={openPublishModal}
                                            size={`lg`}
                                            backdrop={true}
                                            CommonFooter={true}
                                            isClose={false}
                                            CommonFooter={false}
                                            BodyClassName="pt-1"
                                            largesize="modal-custom">
                                            <Formik
                                                initialValues={
                                                    FequentlyinitialValues
                                                }
                                                validationSchema={
                                                    FequentlyValidationSchema
                                                }
                                                onSubmit={(values) => {
                                                    console.log(
                                                        "Selected values:",
                                                        values
                                                    );
                                                }}>
                                                {({
                                                    handleChange,
                                                    values,
                                                    errors,
                                                    setFieldValue,
                                                }) => (
                                                    <FequentlyModalForm
                                                        selectField="article"
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        values={values}
                                                        submitErrors={
                                                            submitErrors
                                                        }
                                                        errors={errors}
                                                    />
                                                )}
                                            </Formik>
                                        </Popup>
                                        <Popup
                                            title="Add Frequently Asked Questions"
                                            openModalToggle={openFaqModalToggle}
                                            isOpen={openFaqModal}
                                            size={`lg`}
                                            backdrop={true}
                                            CommonFooter={true}
                                            isClose={false}
                                            CommonFooter={false}
                                            BodyClassName="pt-1"
                                            largesize="modal-custom">
                                            <div className="mx-2 mt-2 mb-3 px-2">
                                                <Formik
                                                    initialValues={
                                                        scheduleFormInitialValue
                                                    }
                                                    onSubmit={handleSubmit}
                                                    validationSchema={
                                                        scheduleFormValidationSchema
                                                    }>
                                                    {({ errors, values }) => (
                                                        <AddFaqForm
                                                            values={values}
                                                        />
                                                    )}
                                                </Formik>
                                            </div>
                                        </Popup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CreatePostContainer;
