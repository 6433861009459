import { useEffect, useState } from "react";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import { Btn, H2, H3, H4, H5, H6 } from "../../../../AbstractElements";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import TestOrQuizSettingsForm from "./TestOrQuizSettingsForm";
import { ErrorMessage, Field, Form } from "formik";
import SpecialParametersMain from "../SpecialParameters/SpecialParametersMain";
import QuizMaterialMain from "../QuizMaterial/QuizMaterialMain";
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput";
import GameSettingsForm from "./GameSettingsForm";
import { setActiveTab } from "../../../../ReduxToolkit/Reducers/tabSlice";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { Typeahead } from "react-bootstrap-typeahead";
import GroupMain from "../../../Communication/GroupMain";
import { postTypeData } from "../../../Miscellaneous/Blog/AddPost/AddPost";
import ScheduleType from "../../../CommonInput/ScheduleType";
import Sharing from "./Sharing";
import QuizScene from "../../../QuizzAnimation/NextScreenQuizzAnimation";
import AnimationDropdown from "../../../QuizzAnimation/NextScreenQuizzAnimation";
import AudioDropdownPlayer from "../../../QuizzAnimation/NextAudioDropdownPlayer";
import NextScreenQuizzAnimation from "../../../QuizzAnimation/NextScreenQuizzAnimation";
import {
    CorrectAnsweraudioEffects,
    NextScreenaudioEffects,
    WinneraudioEffects,
    WrongAnsweraudioEffects,
} from "../../../../Data/Quizz/Audio/AudioData";
import {
    CorrectAnsweranimationNames,
    NextScreenanimationNames,
    WinneranimationNames,
    WrongAnsweranimationNames,
} from "../../../../Data/Quizz/Animation/Animation";
import QuizTest from "../../../QuizzAnimation/QuizzTest";
import SubCategory from "../../../CommonInput/SubCategory";
import AnimationMultiSelect from "../../../QuizzAnimation/AnimationMultiSelect";
import AudioMultiSelect from "../../../QuizzAnimation/AudioMultiSelect";
import TitleInput from "../../../CommonInput/TitleInput";
import DescriptionInput from "../../../CommonInput/DescriptionInput";

const AddSurveyAndQuizzesForm = (props: any) => {
    const {
        errors,
        submitErrors,
        setSubmitError,
        values,
        setFieldValue,
        addquizsurveysTab,
        testquizsetting,
        setFormType,
        handleSubmit
        // setCategory 
    } = props;

    // Quiz Material popup
    const [openModal, setOpenModal] = useState(false);
    const openModalToggle = () => setOpenModal(!openModal);
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state) => state.tabs.activeTab);
    
    // Special Parameter popup
    const [openSPModal, setOpenSPModal] = useState(false);
    const [selectedNegativeMarking, setSelectedNegativeMarking] = useState(
        values.negativeMakingOptional === "zero" ? true : false
    );
    const openSPModalToggle = () => setOpenSPModal(!openSPModal);

    const formTypeHandler = (flag: string) => {
        setFieldValue("formType", flag);
        // setFormType(flag)
    };
    console.log(values );
    if(values.subcategory != ''){
        console.log(values.subcategory);
        
        // setCategory(values.subcategory)
    }
    // const handleSubmit = (e: React.FormEvent) => {
    //     e.preventDefault(); // Prevent default form submission

    //     // Check if there are any validation errors
    //     const hasErrors = Object.keys(errors).length > 0;

    //     if (!hasErrors) {
    //         dispatch(setActiveTab(activeTab + 1));
    //         // Dispatch form data to the Redux store
    //         // dispatch(setFormData(values));
    //         // Optionally, handle any additional logic here
    //     } else {
    //         console.log("Form has validation errors:", errors);
    //     }
    // };
    const [openFromModal, setOpenFromModal] = useState(false);
    
    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
    const scheduleOptions = [
        { label: "Life Style", value: "Life Style" },
        { label: "Travel", value: "Travel" },
    ];
    const handleNegativeMarkingChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        setSelectedNegativeMarking(!selectedNegativeMarking);
        // setSelectedNegativeMarking(value); // Update the state with the selected value
        setFieldValue("negativeMakingOptional", value); // Update Formik field value
        setFieldValue("pointGrade", value === "negative-grade" ? -5 : 0); // Set point grade based on selection
         
        
    };
    const handleOptionalNegativeMarkingChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        // setSelectedNegativeMarking(!selectedNegativeMarking);
        // setSelectedNegativeMarking(value); // Update the state with the selected value
        setFieldValue("optionnegativeMaking", value); // Update Formik field value
        setFieldValue("pointGrade", value === "negative-grade" ? -5 : 0); // Set point grade based on selection
         
        
    };
console.log(values);

    return (
         
            <Form className="form-wizard" onSubmit={(e) => {
      e.preventDefault(); // Prevents default page reload
      handleSubmit();
    }}> 
            <Row>
            <Col sm="4" className="mb-3">
                <Label check>
                   Previous Questionnaire Settings as a Reference (optional){" "}
                </Label>
                <Field
                    as="select"
                    className="form-control"
                    name="reference-coupon">
                    <option value={""}>Select a Reference</option>
                </Field>
            </Col>
            <Col sm="4" className="mb-3">
                    <TitleInput
                        title="Unique Setting Title"
                        values={values.title}
                        name="title"
                        placeholder="Title"
                    />
                </Col>
                
                </Row>
            <Row>
                <Col sm="4" className="mb-3">
                    <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>
                        General Info
                    </h4>
                </Col>
            </Row>
            <Row>
               
                <Col sm="12" className="mb-3">
                    <Label check>
                    Questionnaire Type 
                        <span className="txt-danger">*</span>
                    </Label>
                </Col>  
                <Col sm="12">
                <Row>
                    <Col sm="3" className="mb-3 ms-4">
                        <FormGroup   className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
    values?.formType == "3" ? 'transform-scale' : ''
  }`} check>
                            <div >
                            <Label
                                className={`mb-0 ${
                                    submitErrors &&
                                    ` ${
                                        errors.formType
                                            ? "text-danger"
                                            : "text-success"
                                    }`
                                }`}
                                for="formType3"
                                check>
                                 <Input
                                id="formType3"
                                name="formType"
                                type="checkbox"
                                value="3"
                                onChange={() => formTypeHandler("3")}
                                className="form-check-input"
                            /> <H6 className="ms-2">Educational Game</H6>
                            </Label>
            </div>
                        </FormGroup>
       
                    </Col>
                    <Col sm="3" className="mb-3 ms-4">
                        <FormGroup check>
                            
                            <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2  ${values?.formType == '2' ? 'transform-scale':""} `}>
                            <Label
                                className={`mb-0 ${
                                    submitErrors &&
                                    ` ${
                                        errors.formType
                                            ? "text-danger"
                                            : "text-success"
                                    }`
                                }`}
                                for="formType2"
                                check>
                                 <Input
                                 id="formType2"
                                name="formType"
                                type="checkbox"
                                value="2"
                                onChange={() => formTypeHandler("2")}
                                className="form-check-input"
                            />  <H6 className="ms-2">Quiz or Test</H6>
                            </Label>
            </div>
                        </FormGroup>
                    </Col>
                    <Col sm="3" className="mb-3 ms-4">
                        <FormGroup check>
                             
                            <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2  ${values?.formType == '1' ? 'transform-scale':""} `}>
                            <Label
                                className={`mb-0 ${
                                    submitErrors &&
                                    ` ${
                                        errors.formType
                                            ? "text-danger"
                                            : "text-success"
                                    }`
                                }`}
                                for="formType1"
                                check>
                                 <Input
                                 id="formType1"
                                name="formType"
                                type="checkbox"
                                value="1"
                                onChange={() => formTypeHandler("1")}
                                className="form-check-input"
                            />   <H6 className="ms-2">Survey</H6>
                            </Label>
            </div>
                        </FormGroup>
                    </Col>
                </Row>
                </Col>
                 
                
                {values?.formType !== "3" && (
                    <Col sm="4" className="mb-3">
                        <Label check>Expiry Date + Hour (optional)</Label>
                        <Field
                            type="datetime-local"
                            name="dateHoursExpiry"
                            placeholder="Expiry Date + Hour (optional)"
                            className="form-control"
                        />
                    </Col>
                )}

                {/* <Col sm="4" className="mb-3">
                    <Label check>Keywords (optional)</Label>
                    <TagsInput
                        name="keywords"
                        placeholder="Enter keywords"
                        className="form-control"
                        setFieldValue={setFieldValue}
                    />
                </Col> */}

                
                <Col sm="4" className="mb-3">
                    <Label check>
                        Timezone <span className="txt-danger">*</span>
                    </Label>
                    <Field
                        as="select"
                        name="timezone"
                        className={`me-2 mb-2 form-control ${
                            submitErrors &&
                            ` ${errors.timezone ? "is-invalid" : "is-valid"}`
                        }`}>
                        <option value={""}>Select Timezone</option>
                        <option value={"1"}>Timezone</option>
                    </Field>
                    <ErrorMessage
                        name="timezone"
                        component="span"
                        className="invalid-feedback"
                    />
                </Col>
                <Row>
                    <H4>Obligatory questions </H4>
                                            <Col sm="4" className="mb-3 mt-3">
                                                <Label check>
                                                    Negative Marking for Wrong
                                                    Answer (optional)
                                                </Label>
                                                <Row>
                                                    <Col
                                                        sm="auto"
                                                        className="mb-3 ms-4">
                                                         
                                                            <FormGroup
            className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                values.negativeMakingOptional === "Zero" ? "transform-scale" : ""
            }`}
            check
        >
            <Label
                className={`mb-0 ${
                    submitErrors &&
                    `${
                        errors.formType ? "text-danger" : "text-success"
                    }`
                }`}
                for="zero"
                check
            >
                <Input
                    id="zero"
                                                                name="negativeMakingOptional"
                    value="Zero"
                    type="checkbox" // Changed to 'radio'
                    onChange={handleNegativeMarkingChange}
                    className="form-check-input"
                />{" "}
                <H6 className="ms-2">
                Zero ( 0 )
                </H6>
            </Label>
        </FormGroup>
                                                       
                                                    </Col>

                                                    <Col
                                                        sm="auto"
                                                        className="mb-3 ms-4">
                                                        
                                                        <FormGroup
            className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                values.negativeMakingOptional === "negative-grade" ? "transform-scale" : ""
            }`}
            check
        >
            <Label
                className={`mb-0 ${
                    submitErrors &&
                    `${
                        errors.formType ? "text-danger" : "text-success"
                    }`
                }`}
                for="negative-grade"
                check
            >
                <Input
                   id="negative-grade"
                   name="negativeMakingOptional"                                   
                    value="negative-grade"
                    type="checkbox" // Changed to 'radio'
                    onChange={handleNegativeMarkingChange}
                    className="form-check-input"
                />{" "}
                <H6 className="ms-2">
                Negative Grade
                </H6>
            </Label>
        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {values.negativeMakingOptional ===
                                                "negative-grade" && (
                                                <Col sm="4" className="mb-3">
                                                    <FormGroup check>
                                                        <Label check>
                                                            Negative Marking
                                                            Value{" "}
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="number"
                                                            name="obligatorypointGrade"
                                                            placeholder="-0.5"
                                                            step="0.5" // Allow changes in increments of 0.5
                                                            min="-100" // Set a minimum value to allow negative numbers
                                                            value={
                                                                values.obligatorypointGrade <
                                                                0
                                                                    ? values.obligatorypointGrade
                                                                    : -Math.abs(
                                                                          values.obligatorypointGrade
                                                                      )
                                                            } // Ensure it's negative
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                const value =
                                                                    parseFloat(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                // Ensure the value is a valid number and adjust to negative if necessary
                                                                if (
                                                                    !isNaN(
                                                                        value
                                                                    )
                                                                ) {
                                                                    setFieldValue(
                                                                        "obligatorypointGrade",
                                                                        value <
                                                                            0
                                                                            ? value
                                                                            : -Math.abs(
                                                                                  value
                                                                              )
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage
                                                            name="obligatorypointGrade"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row>
                <Row>
                    <H4>Optional questions </H4>
                                            <Col sm="4" className="mb-3 mt-3">
                                                <Label check>
                                                    Negative Marking for Wrong
                                                    Answer (optional)
                                                </Label>
                                                <Row>
                                                    <Col
                                                        sm="auto"
                                                        className="mb-3 ms-4">
                                                         
                                                            <FormGroup
            className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                values.optionnegativeMaking === "Zero" ? "transform-scale" : ""
            }`}
            check
        >
            <Label
                className={`mb-0 ${
                    submitErrors &&
                    `${
                        errors.formType ? "text-danger" : "text-success"
                    }`
                }`}
                for="zero1"
                check
            >
                <Input
                    id="zero1"
                                                                name="optionnegativeMaking"
                    value="Zero"
                    type="checkbox" // Changed to 'radio'
                    onChange={handleOptionalNegativeMarkingChange}
                    className="form-check-input"
                />{" "}
                <H6 className="ms-2">
                Zero ( 0 )
                </H6>
            </Label>
        </FormGroup>
                                                       
                                                    </Col>

                                                    <Col
                                                        sm="auto"
                                                        className="mb-3 ms-4">
                                                        
                                                        <FormGroup
            className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                values.optionnegativeMaking  == "optionalNagetive" ? "transform-scale" : ""
            }`}
            check
        >
            <Label
                className={`mb-0 ${
                    submitErrors &&
                    `${
                        errors.formType ? "text-danger" : "text-success"
                    }`
                }`}
                for="negative-grade2"
                check
            >
                <Input
                   id="negative-grade2"
                   name="optionnegativeMaking"                                   
                    value="optionalNagetive"
                    type="checkbox" // Changed to 'radio'
                    onChange={handleOptionalNegativeMarkingChange}
                    className="form-check-input"
                />{" "}
                <H6 className="ms-2">
                Negative Grade
                </H6>
            </Label>
        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {values.optionnegativeMaking ===
                                                "optionalNagetive" && (
                                                <Col sm="4" className="mb-3">
                                                    <FormGroup check>
                                                        <Label check>
                                                            Negative Marking
                                                            Value{" "}
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                        </Label>
                                                        <Field
                                                            className="form-control"
                                                            type="number"
                                                            name="optionpointGrade"
                                                            placeholder="-0.5"
                                                            step="0.5" // Allow changes in increments of 0.5
                                                            min="-100" // Set a minimum value to allow negative numbers
                                                            value={
                                                                values.optionpointGrade <
                                                                0
                                                                    ? values.optionpointGrade
                                                                    : -Math.abs(
                                                                          values.optionpointGrade
                                                                      )
                                                            } // Ensure it's negative
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                const value =
                                                                    parseFloat(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                // Ensure the value is a valid number and adjust to negative if necessary
                                                                if (
                                                                    !isNaN(
                                                                        value
                                                                    )
                                                                ) {
                                                                    setFieldValue(
                                                                        "optionpointGrade",
                                                                        value <
                                                                            0
                                                                            ? value
                                                                            : -Math.abs(
                                                                                  value
                                                                              )
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage
                                                            name="optionpointGrade"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row>
                {values?.formType === "2" && (
                    <Col sm="12" className="mb-3">
                        <Label check>
                            Prerequisites and Requirements (optional)
                        </Label>
                        <Field
                            as="textarea"
                            name="prerequisitesAndRequirements"
                            className="form-control"
                            placeholder="Prerequisites and Requirements"
                            maxLength="300"
                        />
                        <TextCounter
                            length={
                                values?.prerequisitesAndRequirements?.length
                            }
                            totalLength="300"
                        />
                    </Col>
                )}
            </Row>
            <Row>
                <Col sm="4" className="mb-3">
                    <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>
                        Screen Style
                    </h4>
                </Col>
            </Row>
            <Row>
                {values?.formType == "2" && (
                    <Col sm="4" className="mb-3">
                        <Label check>Jump to Other Questions</Label>
                        <br />
                        <div className="d-flex">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                <CommonSwitchSpan
                                    color={"primary"}
                                    name="otherQuestions"
                                    className={`form-control`}
                                    defaultChecked
                                />
                            </div>
                            <Label className="m-l-10" check></Label>
                        </div>
                    </Col>
                )}
                {values?.formType == "2" && (
                    <Col sm="4" className="mb-3">
                        <Label check>Enable Bookmarking Questions </Label>
                        <br />
                        <div className="d-flex">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                <CommonSwitchSpan
                                    color={"primary"}
                                    name="enableBookmark"
                                    className={`form-control`}
                                    defaultChecked
                                />
                            </div>
                            <Label className="m-l-10" check></Label>
                        </div>
                    </Col>
                )}
                {values?.formType === "2" && (
                    <Col sm="4" className="mb-3">
                        <Label check>Pause Timer</Label>
                        <br />
                        <div className="d-flex">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                <CommonSwitchSpan
                                    color={"primary"}
                                    name="pauseTimer"
                                    className={`form-control`}
                                    defaultChecked
                                />
                            </div>
                            <Label className="m-l-10" check></Label>
                        </div>
                    </Col>
                )}
                <>
                    <Col sm="4" className="mb-3">
                        <Label check>Shuffle and Mix Questions</Label>
                        <br />
                        <div className="d-flex">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                <CommonSwitchSpan
                                    color={"primary"}
                                    name="shuffleAndMixQuestions"
                                    className="form-control"
                                    defaultChecked
                                />
                            </div>
                            <Label className="m-l-10" check></Label>
                        </div>
                    </Col> 
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Show Calculator
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="showCalculator"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Show Translator
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="showTranslator"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col> 
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Show Dictionary
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="showDictionary"
                                                            defaultChecked
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    Search the Web
                                                </Label>
                                                <br />
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                                                        <CommonSwitchSpan
                                                            color={"primary"}
                                                            name="searchTheWeb"
                                                            defaultChecked
                                                            
                                                        />
                                                    </div>
                                                    <Label
                                                        className="m-l-10"
                                                        check></Label>
                                                </div>
                                            </Col>
                                        
                    {values?.formType != "3" && (
                        <Col sm="4" className="mb-3">
                            <Label check>
                                Show "Previous" button{" "}
                                <span className="txt-danger">*</span>
                            </Label>
                            <br />
                            <div className="d-flex">
                                <div className="flex-shrink-0  text-end icon-state switch-outline">
                                    <CommonSwitchSpan
                                        color={"primary"}
                                        name="showAnswer"
                                        className={`${
                                            submitErrors &&
                                            `${
                                                errors.showAnswer
                                                    ? "danger"
                                                    : "primary"
                                            }`
                                        }`}
                                        defaultChecked
                                    />
                                </div>
                                <Label className="m-l-10" check></Label>
                            </div>
                        </Col>
                    )}
                   {values?.formType != "3" && <div>
                        <Label check>
                        Displaying Questions{" "}
                            <span className="txt-danger">*</span>
                        </Label>
                        <div className="mb-3 d-flex">
                            <Col sm="4" className="m-checkbox-inline px-2">
                                <Label for={`edo-ani-a`} check>
                                    <Input
                                        className="radio_animated"
                                        id={`edo-ani-a`}
                                        type="radio"
                                        name="rdo-ani"
                                        checked
                                    />
                                    {`1 Question = 1 Screen`}
                                </Label>
                            </Col>
                            <Col sm="4" className="m-checkbox-inline px-2 mt-1">
                                <Label for={`edo-ani-b`} check>
                                    <Input
                                        className="radio_animated"
                                        id={`edo-ani-b`}
                                        type="radio"
                                        name="rdo-ani"
                                    />
                                    {`Multiple Questions = 1 Screen `}
                                </Label>
                            </Col>
                        </div>
                    </div> }
                </>
            </Row>

            {values?.formType == "2" && (
                <TestOrQuizSettingsForm
                    submitErrors={submitErrors}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    addquizsurveysTab={addquizsurveysTab}
                    values={values}
                    testquizsetting={testquizsetting}
                />
            )}
            {values?.formType == "3" && (
                <GameSettingsForm
                    submitErrors={submitErrors}
                    addquizsurveysTab={addquizsurveysTab}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    values={values}
                />
            )}
            {values?.formType == "3" && (
                <Row>
                    <Col sm="4" className="my-3">
                        <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>
                            Animation and Audio
                        </h4>
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">Next Screen</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <NextScreenQuizzAnimation
                            animation={NextScreenanimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioDropdownPlayer
                            audioEffects={NextScreenaudioEffects}
                        />
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">While Playing and Answering:</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <AnimationMultiSelect
                            animation={NextScreenanimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioMultiSelect
                            audioEffects={NextScreenaudioEffects}
                        />
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">Gold Winner</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <NextScreenQuizzAnimation
                            animation={CorrectAnsweranimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioDropdownPlayer
                            audioEffects={CorrectAnsweraudioEffects}
                        />
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">Silver Winner</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <NextScreenQuizzAnimation
                            animation={WrongAnsweranimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioDropdownPlayer
                            audioEffects={WrongAnsweraudioEffects}
                        />
                    </Col>
                </Row>
            )}
            {values?.formType == "3" && (
                <Row className="justify-content-between">
                    <div>
                        <H4 className="my-2">Bronze Winner</H4>
                    </div>
                    <Col sm="5">
                        {" "}
                        <NextScreenQuizzAnimation
                            animation={WinneranimationNames}
                        />
                    </Col>{" "}
                    <Col sm="5">
                        {" "}
                        <AudioDropdownPlayer
                            audioEffects={WinneraudioEffects}
                        />
                    </Col>
                </Row>
            )}

            {/* <Sharing /> this is used in future Dont delete*/}

            <div className="">
                <Col sm="auto" className="mb-3">
                    <Btn
                        color="secondary"
                        type="submit"
                        onClick={() => {
                            setSubmitError(true);
                        }}>
                        Save as a Draft
                    </Btn>
                </Col>
            </div>
        </Form>
    );
};

export default AddSurveyAndQuizzesForm;
