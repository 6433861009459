import { Link, useLocation } from "react-router-dom"; 
import { DeliveryFeeAction, ManageOnlineStore } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import { Col, Row } from "reactstrap";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { useState } from "react";
import { closeModal, openModal, setActiveItem } from "../../../../ReduxToolkit/Reducers/Navigation/Navigation";
import { MenuList } from "../../../../Data/LayoutData/SidebarData";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const ManageOnlineSubMenu = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const { mobileView } = useAppSelector((state) => state.layout);
  // const isActive = (path) => {
  //   // Check if the current path starts with the path of the link
  //   return currentPath.includes(path) || currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`);
  // };
  const isActive = (path) => {
    const isMainActive =  currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`); 
    return isMainActive  ; // If either is active, return true
  };
  const [isOpen, setIsOpen] = useState(false); 
  const dispatch = useAppDispatch();
  const menuPopupOpen =()=>{
    const data = MenuList[0]?.Items?.find((item) => item.title === "Tools");
    
    dispatch(setActiveItem(data))
    dispatch(openModal())
  }
  const menuPopupClose =()=>{
    dispatch(closeModal())
  }
  
  return (
    <div>
          <Row>
          {/* {mobileView ? ( */}
                  <>
                      
          
               {/* <Col md="12">
                 {isOpen ? (
                      <>
                          {ManageOnlineStore.map((item, index) => { 
                              const activeClass = isActive(item.path) ? 'sub-active' : '';

                              return (
                                <Link
                                  key={index}
                                  to={`${process.env.PUBLIC_URL}/${item.path}`}
                                  className={`border d-inline-block p-2 m-1 rounded ${activeClass}`}
                                >
                                  <span>
                                    {item.title} {item.total_contest >= 0 ? `(${item.total_contest})` : ''}
                                  </span>
                                </Link>
                              );
                          })}
                      </>
                  ) : ("")}
              </Col>*/}
              </>
          {/* ) : ( */}
                  {/* <Col md="12">
                      {ManageOnlineStore.map((item, index) =>{
                          
                          const activeClass = isActive(item.path) ? 'sub-active' : ''; 
                          return (
                      
                            <Link
                            key={index}
                            to={`${process.env.PUBLIC_URL}/${item.path}`}
                            className={`border d-inline-block p-2 m-1 rounded ${activeClass}`}
                          >
                            <span>
                              {item.title} {item.total_contest >= 0 ? `(${item.total_contest})` : ''}
                            </span>
                          </Link>
                          )
                      }
                      )}
                  </Col> */}
              {/* )}  */}
          </Row>
    </div>
  );
};

export default ManageOnlineSubMenu;


 
 
