import React from 'react'
import OrderHistoryContainer from '../../../Component/Application/Ecommerce/OrderHistory/OrderHistory'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'
import Purchase from '../../../Component/Application/Ecommerce/Purchase/Purchase'

const MyPurchases = () => {
  return (
    <div className="page-body">
    <Breadcrumbs mainTitle={`My Purchase `} parent={`User Dashboard`}  /> 
    <Purchase />
    </div>
  )
}

export default MyPurchases