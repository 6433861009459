import { Card, Col, Container, Row } from 'reactstrap'
import ProjectListTabContent from './ProjectListTabContent'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { BrandTools, Project, ProjectListHeading } from '../../../../utils/Constant' 
import SubMenu from '../../SubMenu/SubMenu'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'


const ProjectListContainer = () => {
  return (
    <>
     <div className="d-flex container-fluid align-items-center">
                <div className="px-2"> 
      <SubMenuPopup  currentPage="Manage Projects List "/> 
                </div> 
      <Breadcrumbs mainTitle={`${ProjectListHeading} (${BrandTools})`}  mainParent="All Tools" parent={BrandTools} />
            </div>
      <Container fluid>
        <Row className="project-cards">
           
          <Col sm="12">
            <ProjectListTabContent />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProjectListContainer