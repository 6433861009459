import React, { useEffect, useRef, useState } from "react";
import CommonModal from "../Ui-Kits/Modal/Common/CommonModal";
import { Btn, H3, SVG } from "../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { X } from "react-feather";

export interface PopupModal {
  children?: React.ReactNode;
  title: string;
  isOpen: boolean;
  size: string;
  backdrop?: boolean;
  isClose?: boolean;
  openModalToggle: () => void;
  CommonFooter?: boolean;
  largesize?:string;
  BodyClassName?:string;
}

const handleNext = () => {};

const handlePrevious = () => {};

const Popup: React.FC<PopupModal> = ({
  title,
  isOpen,
  size,
  backdrop,
  isClose,
  CommonFooter,
  openModalToggle,
  children,
  largesize,
  BodyClassName
}) => { 

    const modalBodyRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (modalBodyRef.current) {
          if (event.key === "ArrowDown") {
            modalBodyRef.current.scrollBy({ top: 30, behavior: "smooth" });
          } else if (event.key === "ArrowUp") {
            modalBodyRef.current.scrollBy({ top: -30, behavior: "smooth" });
          }
        }
      };
    
      if (isOpen) {
        setTimeout(() => {
          console.log(modalBodyRef.current);
          if (modalBodyRef.current) {
            
            modalBodyRef.current.focus(); // Ensure focus after modal is fully rendered
          }
        }, 100); // Small delay to ensure the modal is mounted
    
        document.addEventListener("keydown", handleKeyDown);
      } else {
        document.removeEventListener("keydown", handleKeyDown);
      }
    
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [isOpen]);
  return (
    <CommonModal
      isOpen={isOpen}
      toggle={openModalToggle}
      modalBodyClassName={` px-3  ${BodyClassName}`} 
      size={size} 
      backdrop={backdrop}
      largesize={largesize}
      title={title}
    >
      <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
        
        <div   className="overflow-y-auto modal_body" ref={modalBodyRef} tabIndex={0}> 
        <div style={{ height: "auto", overflow: "auto" }}>{children}</div>
      </div>
        </div>
      {CommonFooter && (
        <div className="modal-footer fixed-footer">
          <div className="next_previous position-relative pt-0">
            <Btn onClick={handlePrevious} className="border">
              <div className="d-flex align-items-center gap-sm-2 gap-1">
                Previous <SVG iconId=" back-arrow" />
              </div>
            </Btn>
            <Btn onClick={handleNext} className="border">
              <div className="d-flex align-items-center gap-sm-2 gap-1">
                Next <SVG iconId="front-arrow" />
              </div>
            </Btn>
          </div>
        </div>
      )}
    </CommonModal>
  );
};

export default Popup;
