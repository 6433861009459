import {useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link, useLocation } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { BrandTools, ManageQuizSurveySystem } from '../../../../utils/Constant'
import { ActionButtons, QuizzSurveyTableAction, StudyMaterialAction, links, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
 
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import StudyMaterialSubMenu from '../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'
 


const PoliciesandServiceTerms = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const [isEditOpen , setIsEditOpen] = useState(false);
    const [isOpen , setIsOpen] = useState(false);
     const [isModal , setIsModal] = useState(false);
     const [selectedRow, setSelectedRow] = useState<SupportDataType | null>(null);
   
  const [openPublishModal, setOpenPublishModal] = useState(false); 
    const handleRowSelected =() =>{}

    const openPopup = (type:boolean) =>{
      setIsOpen(!isOpen);
      setIsEditOpen(type);
    }
 
 

  
  
    const handleColumnClick = (failedValue) => {
      setIsModal(!isModal); 
      setSelectedRow(failedValue); // Store the clicked row's data
    };
    const QuizSurveyColumnData: TableColumn<SupportDataType>[] = [
      {
        name: <TableHeadColumn title={`ID`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: false,
      },
      {
        name: <TableHeadColumn title={`By`}/>,
        selector: (row) => row["by"],
        sortable: true,
        center: false,
      }, 
      {
        name: <TableHeadColumn title={`Unique Title`}/>,
        selector: (row) => row["salary"],
        sortable: true,
        center: true,
      },
     
      {
        name: <TableHeadColumn title={`Date Created`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Last Modified`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
      },    
      
      {
        name:<TableHeadColumn title={`Actions`}/>,
        cell: (row) => <ActionButtons openPopup={openPopup}/>,
        center: false,
        right:true,
      },
    ];
    
 
     
    return (
      <div className='page-body'>
         <div className="d-flex container-fluid align-items-center">
                <div className="px-2"> 
      
        <SubMenuPopup  currentPage="Policy or Service Terms List "/>
                </div> 
        <Breadcrumbs mainParent="All Tools" mainTitle={`Policies-and-Service-Terms (${BrandTools})`} parent={BrandTools} />
            </div>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
              <div className='my-2 ms-1'>
                    <Row>
                      <Col md="12">  
                    <StudyMaterialSubMenu />
                    </Col>
                    </Row>
                  </div>
                  
                <CardBody className='p-2'>
                  <> 
                  <div className='p-2 border'>
                    <div className=''>
                    {QuizzSurveyTableAction.map((action, index)=>{
                      return(
                      <>
                        <Link to={`${process.env.PUBLIC_URL}/${action.path}`}><span key={index} className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                      </>)
                    })}
                    </div>
                    
                    <div className="table-responsive pt-2">
                      <DataTable className='custom-scrollbar' columns={QuizSurveyColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                    </div>

                  

                  </div>
                  
 
                  
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default PoliciesandServiceTerms