import { ErrorMessage, Field } from 'formik'
import React from 'react'
import { Label } from 'reactstrap'
import TextCounter from './TextCounter'
import { useAppSelector } from '../../ReduxToolkit/Hooks'

function TitleInput(props:any) {

  const {submitErrors,errors,values,title,name,placeholder,description} =props
  const { websiteSettingsData }: any = useAppSelector((state) => state.common); 
  const maxTitleLength = websiteSettingsData?.title_length
  return (
    <> 
    <Label check>
   {title} <span className="txt-danger">*</span>
  </Label>
  {description && <small className='d-block'>To combine and display very related products or services in one listing, use the same parent name for all of them.”</small>}
  <Field
    type="text"
    name={name}
    placeholder={placeholder}
    maxLength={maxTitleLength}
    className={`form-control ${
      submitErrors && `${errors.name ? "is-invalid" : "is-valid"}`
    }`}
  />
  <TextCounter length={values?.length} totalLength={maxTitleLength} />
  <ErrorMessage
            name={name}
            component="span"
            className="invalid-feedback"
    />
  </>
  )
}

export default TitleInput