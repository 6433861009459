import { useState } from "react";
import { AdditionalServices, BrandTools } from "../../../../utils/Constant";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import StudyMaterialSubMenu from "../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import TitleInput from "../../../../Component/CommonInput/TitleInput";
import DescriptionInput from "../../../../Component/CommonInput/DescriptionInput";
import TagsInput from "../../../CommonInput/TagsInput";
import { Btn, H4, H5 } from "../../../../AbstractElements";
import {
  questionFormInitialValue,
  questionFormValidationSchema,
  specialParametersFormValidationSchema,
  surveyAndQuizzesFormInitialValue,
  TabsurveyAndQuizzesFormValidationSchema,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import AddSurveyAndQuizzesForm from "../SurveyAndQuizzes/AddSurveyAndQuizzesForm";
import Popup from "../../../MasterPopup/Popup";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
import Country from "../../../CommonInput/Country";
import SubCategory from "../../../CommonInput/SubCategory";
import FaqModal from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/AddFaqForm";
import CustomSelect from "../../../CommonInput/CustomSelect";
import AddCourseandTeacherInformation from "../../../../Pages/Tools/BrandSupportTools/CourseandTeacherInformation/AddCourseandTeacherInformation";
import {
  FequentlyinitialValues,
  FequentlyValidationSchema,
  scheduleFormInitialValue,
  scheduleFormValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import AddFaqForm from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/AddFaqForm";
import QuestionSelectForm from "../Questions/QuestionSelectForm";
import ImportBulk from "../../../../Pages/Tools/BrandSupportTools/ImportBulk/ImportBulk";
import AddQuestionsForm from "../Questions/AddQuestionsForm";
import CardHeaderCollapse from "../../../../CommonElements/CardHeaderCommon/CardHeaderCollapse";
import DataTable from "react-data-table-component"; 
import FequentlyModalForm from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyModalForm";
import QuizzGame from "../../../QuizzAnimation/QuizGame"; 
import { appliedColumns, applieddata } from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import SharingSection from "../SurveyAndQuizzes/SharingSection";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";
interface QuestionnaireFormValues {
  questionnaireKey: string[];
  quizmaterialtitle: string;
  questionnaireType: string;
  academicYear?: number | "";
  quizmaterialdescription: string;
  keywords?: string[];
  file?: File | null;
  breakinActivity: Boolean;
  amount: string;
  duration: number | "";
  durationPerQuestion: number | "";
  questionnaireSettings: string;
  generalParameters: string;
  relatedlessons: string[];
  comanage: string;
  coManagers: Array<any>;
  PolicyService: string;
}

export const QuestionnaireinitialValues: QuestionnaireFormValues = {
  questionnaireKey: [],
  quizmaterialtitle: "",
  questionnaireType: "",
  amount: "",
  academicYear: "",
  quizmaterialdescription: "",
  keywords: [],
  file: null,
  breakinActivity: false,
  duration: "",
  durationPerQuestion: "",
  questionnaireSettings: "",
  generalParameters: "",
  relatedlessons: [],
  comanage: "1",
  coManagers: [ {username: "",
    permission: "1",
    dispaymedia: "0",}],
    PolicyService: "",
};

export const QuestionnairevalidationSchema = Yup.object({
  questionnaireKey: Yup.array()
    .min(1, "At least one Questionnaire Title is required")
    .required("This field is required"),
  quizmaterialtitle: Yup.string().required("Title is required"),
  questionnaireType: Yup.string().required("Type is required"),
  amount: Yup.string().required("Questionnaire Type is required"),
  questionnaireSettings: Yup.string().required(
    "Questionnaire Settings is required"
  ),
  relatedlessons: Yup.array().min(1, "Please select at least one lesson"),
  duration: Yup.number().when("amount", {
    is: (val) => val === "4" || val === "5", // For Quiz or Survey
    then: (schema) =>
      schema
        .required("Total Duration is required")
        .min(1, "Must be at least 1 minute"),
  }),
  durationPerQuestion: Yup.number().when("amount", {
    is: "3", // For Educational Game
    then: (schema) =>
      schema
        .required("Duration per question is required")
        .min(1, "Must be at least 1 minute"),
  }),
  quizmaterialdescription: Yup.string().required("Description is required"),
  // keywords: Yup.array().of(Yup.string()),
  file: Yup.mixed()
    .required("File is required")
    .test("fileSize", "File size is too large", (value) => {
      return value ? (value as File).size <= 5242880 : true; // 5MB limit
    })
    .test("fileType", "Unsupported file format", (value) => {
      return value
        ? ["application/pdf", "image/jpeg", "image/png"].includes(
            (value as File).type
          )
        : true;
    }),
});

const AddQuestionnaire = () => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 50 }, (_, i) => currentYear - i);
  const [selectedYear, setSelectedYear] = useState<number | "">("");
  const [openQestionModal, setOpenQestionModal] = useState(false);
  const [openQestionPageModal, setOpenQestionPageModal] = useState(false);
  const [openGenralModal, setOpenGenralModal] = useState(false);
  const [openFaqModal, setOpenFaqModal] = useState(false);
  const [openQuizzModal, setOpenQuizzModal] = useState(false);
  const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
  const openQestionPageModalToggle = () =>
    setOpenQestionPageModal(!openQestionPageModal);
  const openGenralModalToggle = () => setOpenGenralModal(!openGenralModal);
  const openFaqModalToggle = () => setOpenFaqModal(!openFaqModal);
  const openQuizzModalToggle = () => {
    setOpenQuizzModal(!openQuizzModal);
    setQuizUi(true);
  };
  const [activeCheckbox, setActiveCheckbox] = useState<"first" | "second">(
    "first"
  );
  const [activeQuestionCheckbox, setQuestionActiveCheckbox] = useState<
    "first" | "second"
  >("first");
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const openPublishModalToggle = () => setOpenPublishModal(!openPublishModal);
  const [quizUi, setQuizUi] = useState(false);

  const handleSubmit = (values: QuestionnaireFormValues) => {
    console.log(values);
    setSubmitError(false);
  };
  const handleFormSubmit = (values: QuestionnaireFormValues) => {
    console.log(values);
    setSubmitError(false);
  };
  const cityOptions = [
    {
        label: " Free for Commercial and  Non-profit use",
        value: "Free for Commercial and  Non-profit use",
    },
    {
        label: "Free for Non-Profit use only",
        value: "Free for Non-Profit use only",
    },
];
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [isAdditionalServiceOpen, setIsAdditionalServiceOpen] = useState(true);
  const handleRowSelected = () => {
    setSelectedRows([]);
    setToggleDelete(false);
  };
  const formValues = useAppSelector((state) => state.form?.specialParameters);
  const handleCheckboxChange = (checkbox: "first" | "second") => {
    // Toggle between checkboxes
    setActiveCheckbox(checkbox);
  };
  const handleQuestionCheckboxChange = (checkbox: "first" | "second") => {
    // Toggle between checkboxes
    setQuestionActiveCheckbox(checkbox);
  };
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const relatedlessons = [
    { value: "branch1", label: "Branch 1, Surat, Gujarat" },
    { value: "branch2", label: "Branch 2, Mumbai, Maharashtra" },
    { value: "branch3", label: "Branch 3, Bangalore, Karnataka" },
  ];
  const knowledgeoptions = [
    { value: "game", label: "Eductional Game" },
    { value: "quiz", label: "Quiz or Test" },
    { value: "survey", label: "Survey" },
  ];
  const question = [
    { value: "branch1", label: "Branch 1, Surat, Gujarat" },
    { value: "branch2", label: "Branch 2, Mumbai, Maharashtra" },
    { value: "branch3", label: "Branch 3, Bangalore, Karnataka" },
  ];
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {};

  const [openBulkQestionModal, setOpenBulkQestionModal] = useState(false);
  const openBulkQestionModalToggle = () =>
    setOpenBulkQestionModal(!openBulkQestionModal);
  const jobLocation = [
    { value: "remote", label: "Remote" },
    { value: "onsite", label: "Onsite" },
    { value: "hybrid", label: "Hybrid" },
    { value: "usa", label: "USA" },
    { value: "uk", label: "UK" },
    { value: "canada", label: "Canada" },
  ];
  return (
      <div className="page-body">
         <div className="d-flex container-fluid align-items-center">
                <div className="px-2"> 
                <SubMenuPopup  currentPage="Add a Questionnaire"/>
                </div> 
          <Breadcrumbs
              mainParent="All Tools"
              mainTitle={`Add a Question Group (${BrandTools})`}
              parent={BrandTools}
          />
            </div>
          <Container fluid>
              <Row>
                  <Col sm="12">
                      <Card>
                          <div className="my-2 ms-1">
                              <StudyMaterialSubMenu />
                          </div>
                          <div className="my-2 mx-2">
                              <Btn
                                  onClick={openBulkQestionModalToggle}
                                  className="p-0 child_btn border rounded"
                              >
                                  <span>Import Bulk Questionnaires</span>
                              </Btn>
                          </div>
                          <CardBody>
                              <div className=" ">
                                  <H4 className="mb-2">Questionnaires</H4>
                                  <div>
                                      <div
                                          className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                              activeCheckbox === "first"
                                                  ? "transform-scale"
                                                  : ""
                                          }`}
                                      >
                                          <label>
                                              <input
                                                  type="checkbox"
                                                  checked={
                                                      activeCheckbox === "first"
                                                  }
                                                  onChange={() =>
                                                      handleCheckboxChange(
                                                          "first"
                                                      )
                                                  }
                                              />
                                              <H5 className="ms-2">
                                                  Select a Questionnaire Header
                                              </H5>
                                          </label>
                                      </div>
                                      <div
                                          className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                              activeCheckbox === "second"
                                                  ? "transform-scale"
                                                  : ""
                                          }`}
                                      >
                                          <label>
                                              <input
                                                  type="checkbox"
                                                  checked={
                                                      activeCheckbox ===
                                                      "second"
                                                  }
                                                  onChange={() =>
                                                      handleCheckboxChange(
                                                          "second"
                                                      )
                                                  }
                                              />
                                              <H5 className="ms-2">
                                                  Add a Questionnaire Header
                                              </H5>
                                          </label>
                                      </div>
                                  </div>
                                  <Formik
                                      initialValues={QuestionnaireinitialValues}
                                      onSubmit={(values) =>
                                          console.log("Form Submitted:", values)
                                      }
                                      validationSchema={
                                          QuestionnairevalidationSchema
                                      }
                                  >
                                      {({
                                          errors,
                                          values,
                                          setFieldValue,
                                          handleChange,
                                          handleSubmit,
                                      }) => (
                                          <>
                                              <Row className="my-2">
                                                  <Form
                                                      onSubmit={(e) => {
                                                          e.preventDefault(); // Prevents default page reload
                                                          handleFormSubmit(
                                                              values
                                                          );
                                                      }}
                                                  >
                                                      {activeCheckbox ===
                                                      "first" ? (
                                                          <Col sm="4">
                                                              <FormGroup>
                                                                  <Label for="uniqueIdNumber">
                                                                      Select a
                                                                      Questionnaire
                                                                      Header
                                                                      <span className="txt-danger">
                                                                          *
                                                                      </span>
                                                                  </Label>

                                                                  <Field
                                                                      name="questionnaireKey"
                                                                      component={
                                                                          CustomSelect
                                                                      }
                                                                      options={
                                                                          jobLocation
                                                                      }
                                                                      className={`custom-select ${
                                                                          errors.questionnaireKey
                                                                              ? "is-invalid"
                                                                              : "is-valid"
                                                                      }`}
                                                                      placeholder="Select a Questionnaire Header"
                                                                      isMulti={
                                                                          false
                                                                      }
                                                                  />
                                                                  <ErrorMessage
                                                                      name="questionnaireKey"
                                                                      component="span"
                                                                      className="invalid-feedback"
                                                                  />
                                                              </FormGroup>
                                                          </Col>
                                                      ) : activeCheckbox ===
                                                        "second" ? (
                                                          <Row>
                                                              <Col sm="4">
                                                                  <TitleInput
                                                                      title="Questionnaire Unique Title"
                                                                      values={
                                                                          values.quizmaterialtitle
                                                                      }
                                                                      name="quizmaterialtitle"
                                                                      placeholder="Enter a Quiz Material Name"
                                                                  />
                                                              </Col>
                                                              <Col sm="4">
                                                                  <Label check>
                                                                      Cover
                                                                      Image
                                                                  </Label>
                                                                  <FormGroup>
                                                                      <Field
                                                                          className={`form-control ${
                                                                              submitErrors &&
                                                                              `${
                                                                                  errors.image
                                                                                      ? "is-invalid"
                                                                                      : "is-valid"
                                                                              }`
                                                                          }`}
                                                                          type="file"
                                                                          name="cover_image"
                                                                          Single
                                                                          accept="image/*"
                                                                          onChange={(
                                                                              e
                                                                          ) => {
                                                                              const file =
                                                                                  e
                                                                                      .target
                                                                                      .files
                                                                                      ? e
                                                                                            .target
                                                                                            .files[0]
                                                                                      : null;
                                                                              setImages(
                                                                                  file
                                                                              ); // Update the state with selected files

                                                                              console.log(
                                                                                  "Uploaded files:",
                                                                                  file
                                                                              ); // Log the upload image files
                                                                          }}
                                                                      />

                                                                      <ErrorMessage
                                                                          name="image"
                                                                          component="span"
                                                                          className="invalid-feedback"
                                                                      />
                                                                  </FormGroup>
                                                              </Col>

                                                              <Col
                                                                  sm="4"
                                                                  className="mb-3"
                                                              >
                                                                  <SubCategory
                                                                      name="subcategory"
                                                                      className={`${
                                                                          submitErrors &&
                                                                          `${
                                                                              errors.subcategory
                                                                                  ? "is-invalid"
                                                                                  : "is-valid"
                                                                          }`
                                                                      }`}
                                                                  />
                                                              </Col>
                                                              <Col md="4">
                                                                  <FormGroup>
                                                                      <Label>
                                                                          {
                                                                              "Targeted Countries"
                                                                          }{" "}
                                                                          (optional)
                                                                      </Label>
                                                                      <Country
                                                                          placeholder="Any Country"
                                                                          name="country"
                                                                          isMulti={
                                                                              true
                                                                          }
                                                                          className={`${
                                                                              submitErrors &&
                                                                              `${
                                                                                  errors.country
                                                                                      ? "is-invalid"
                                                                                      : "is-valid"
                                                                              }`
                                                                          }`}
                                                                      />
                                                                      <ErrorMessage
                                                                          name="country"
                                                                          component="span"
                                                                          className="invalid-feedback"
                                                                      />
                                                                  </FormGroup>
                                                              </Col>
                                                              <Col
                                                                  sm="4"
                                                                  className="mb-3"
                                                              >
                                                                  <Label check>
                                                                      Academic
                                                                      Year
                                                                      (optional)
                                                                  </Label>
                                                                  <select
                                                                      id="academic-year"
                                                                      value={
                                                                          values.academicYear
                                                                      }
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          setFieldValue(
                                                                              "academicYear",
                                                                              Number(
                                                                                  e
                                                                                      .target
                                                                                      .value
                                                                              )
                                                                          )
                                                                      }
                                                                      className="form-control"
                                                                  >
                                                                      <option value="">
                                                                          Select
                                                                          Year
                                                                      </option>
                                                                      {years.map(
                                                                          (
                                                                              year
                                                                          ) => (
                                                                              <option
                                                                                  key={
                                                                                      year
                                                                                  }
                                                                                  value={
                                                                                      year
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      year
                                                                                  }
                                                                              </option>
                                                                          )
                                                                      )}
                                                                  </select>
                                                              </Col>
                                                              <Col
                                                                  md="4"
                                                                  className="mb-3"
                                                              >
                                                                  <Label check>
                                                                      Questionnaire
                                                                      Type
                                                                      <span className="text-danger">
                                                                          *
                                                                      </span>{" "}
                                                                      :
                                                                  </Label>
                                                                  <Field
                                                                      as="select"
                                                                      name="questionnaireType"
                                                                      onChange={
                                                                          handleChange
                                                                      }
                                                                      className={`form-control ${
                                                                          errors.questionnaireType
                                                                              ? "is-invalid"
                                                                              : ""
                                                                      }`}
                                                                  >
                                                                      <option value="">
                                                                          Select...
                                                                      </option>
                                                                      {knowledgeoptions.map(
                                                                          (
                                                                              option
                                                                          ) => (
                                                                              <option
                                                                                  key={
                                                                                      option.value
                                                                                  }
                                                                                  value={
                                                                                      option.value
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      option.label
                                                                                  }
                                                                              </option>
                                                                          )
                                                                      )}
                                                                  </Field>
                                                                  <ErrorMessage
                                                                      name="questionnaireType"
                                                                      component="div"
                                                                      className="invalid-feedback"
                                                                  />
                                                              </Col>
                                                              <Col
                                                                  sm="4"
                                                                  className="mb-3"
                                                              >
                                                                  <Label check>
                                                                      Questionnaire
                                                                      Settings{" "}
                                                                      <span className="text-danger">
                                                                          *
                                                                      </span>
                                                                  </Label>

                                                                  <FormGroup>
                                                                      <Field
                                                                          as="select"
                                                                          name="questionnaireSettings"
                                                                          className={`form-control ${
                                                                              errors.questionnaireSettings
                                                                                  ? "is-invalid"
                                                                                  : ""
                                                                          }`}
                                                                          onChange={
                                                                              handleChange
                                                                          }
                                                                      >
                                                                          <option value="">
                                                                              Select
                                                                          </option>
                                                                          <option value="free-commercial">
                                                                              Free
                                                                              for
                                                                              Commercial
                                                                              and
                                                                              Non-profit
                                                                              use
                                                                          </option>
                                                                          <option value="free-nonprofit">
                                                                              Free
                                                                              for
                                                                              Non-Profit
                                                                              use
                                                                              only
                                                                          </option>
                                                                      </Field>
                                                                      <ErrorMessage
                                                                          name="questionnaireSettings"
                                                                          component="div"
                                                                          className="invalid-feedback"
                                                                      />
                                                                      <span className="text-muted small">
                                                                          <Btn
                                                                              className="ps-0 pt-0  d-flex"
                                                                              onClick={
                                                                                  openQestionModalToggle
                                                                              }
                                                                          >
                                                                              <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                                                              Add
                                                                              Questionnaire
                                                                              Settings
                                                                          </Btn>
                                                                      </span>
                                                                  </FormGroup>
                                                              </Col>

                                                              {values.amount ===
                                                                  "3" && (
                                                                  <Col
                                                                      md="4"
                                                                      className="mb-3"
                                                                  >
                                                                      <Label>
                                                                          Duration
                                                                          per
                                                                          Question
                                                                          in
                                                                          Minutes
                                                                          <span className="text-danger">
                                                                              *
                                                                          </span>{" "}
                                                                          :
                                                                      </Label>
                                                                      <Field
                                                                          type="number"
                                                                          name="durationPerQuestion"
                                                                          className="form-control"
                                                                          min="1"
                                                                          step="1"
                                                                          placeholder="Enter duration per question"
                                                                      />
                                                                      <ErrorMessage
                                                                          name="durationPerQuestion"
                                                                          component="div"
                                                                          className="text-danger"
                                                                      />
                                                                  </Col>
                                                              )}

                                                              {[
                                                                  "4",
                                                                  "5",
                                                              ].includes(
                                                                  values.amount
                                                              ) && (
                                                                  <Col
                                                                      md="4"
                                                                      className="mb-3"
                                                                  >
                                                                      <Label>
                                                                          Total
                                                                          Duration
                                                                          in
                                                                          Minutes
                                                                          (optional):
                                                                      </Label>
                                                                      <Field
                                                                          type="number"
                                                                          name="duration"
                                                                          className="form-control"
                                                                          min="1"
                                                                          step="1"
                                                                          placeholder="Enter duration in minutes"
                                                                      />
                                                                  </Col>
                                                              )}
                                                              <Col sm="4">
                                                                  <DescriptionInput
                                                                      name="quizmaterialdescription"
                                                                      placeholder="Enter a Description"
                                                                      values={
                                                                          values.quizmaterialdescription
                                                                      }
                                                                      lable="Description"
                                                                      className={
                                                                          errors.quizmaterialdescription
                                                                              ? "is-invalid"
                                                                              : ""
                                                                      }
                                                                  />
                                                              </Col>
                                                              <Col
                                                                  sm="4"
                                                                  className="mb-3"
                                                              >
                                                                  <Label check>
                                                                      Keywords
                                                                      (optional){" "}
                                                                  </Label>
                                                                  <TagsInput
                                                                      name="keywords"
                                                                      placeholder="Enter keywords"
                                                                      className="form-control"
                                                                      setFieldValue={
                                                                          setFieldValue
                                                                      }
                                                                  />
                                                              </Col>

                                                              <Col
                                                                  sm="4"
                                                                  className="mb-3"
                                                              >
                                                                  <Label check>
                                                                      Frequently
                                                                      Asked
                                                                      Questions
                                                                      (optional)
                                                                  </Label>
                                                                  <div className="">
                                                                      <FormGroup>
                                                                          <Input type="select">
                                                                              <option>{`Select`}</option>
                                                                              <option>
                                                                                  Free
                                                                                  for
                                                                                  Commercial
                                                                                  and
                                                                                  Non-profit
                                                                                  use
                                                                              </option>

                                                                              <option>
                                                                                  Free
                                                                                  for
                                                                                  Non-Profit
                                                                                  use
                                                                                  only
                                                                              </option>
                                                                              {/* <option>View Only. No right to download or use. </option>
                            <option>Display only on shopping pages. Not for social media usages</option> */}
                                                                          </Input>
                                                                          <span className="text-muted small">
                                                                              <Btn
                                                                                  className="ps-0  d-flex"
                                                                                  onClick={
                                                                                      openFaqModalToggle
                                                                                  }
                                                                              >
                                                                                  <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                                                                  Add
                                                                                  Frequently
                                                                                  Asked
                                                                                  Questions
                                                                              </Btn>
                                                                          </span>
                                                                      </FormGroup>
                                                                  </div>
                                                              </Col>
                                                              <Col sm="4" className="mb-3">
                                                <FormGroup>
                                                    <Label check>
                                                        {`Policy or Service Terms (optional)`}
                                                        :
                                                    </Label>
                                                    <FormGroup floating>
                                                        <Field
                                                            className={`custom-select  `}
                                                            name="PolicyService"
                                                            options={
                                                                cityOptions
                                                            }
                                                            component={
                                                                CustomSelect
                                                            }
                                                            placeholder="Select Tests and Surveys"
                                                            isMulti={true}
                                                        />
                                                        
                                                    </FormGroup>
                                                </FormGroup>
                                                </Col>
                                                              <Col
                                                                  sm="4"
                                                                  className="mb-3"
                                                              >
                                                                  <Label check>
                                                                      Course and
                                                                      Teacher
                                                                      Information
                                                                      (optional)
                                                                  </Label>
                                                                  <FormGroup>
                                                                      <Field
                                                                          as="select"
                                                                          name="generalParameters"
                                                                          className={`form-control ${
                                                                              errors.generalParameters
                                                                                  ? "is-invalid"
                                                                                  : ""
                                                                          }`}
                                                                          onChange={
                                                                              handleChange
                                                                          }
                                                                      >
                                                                          <option value="">
                                                                              Select
                                                                          </option>
                                                                          <option value="free-commercial">
                                                                              Free
                                                                              for
                                                                              Commercial
                                                                              and
                                                                              Non-profit
                                                                              use
                                                                          </option>
                                                                          <option value="free-nonprofit">
                                                                              Free
                                                                              for
                                                                              Non-Profit
                                                                              use
                                                                              only
                                                                          </option>
                                                                      </Field>
                                                                      <ErrorMessage
                                                                          name="generalParameters"
                                                                          component="div"
                                                                          className="invalid-feedback"
                                                                      />
                                                                      <span className="text-muted small">
                                                                          <Btn
                                                                              className="ps-0  d-flex"
                                                                              onClick={
                                                                                  openGenralModalToggle
                                                                              }
                                                                          >
                                                                              <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                                                              Add
                                                                              Course
                                                                              and
                                                                              Teacher
                                                                              Information
                                                                          </Btn>
                                                                      </span>
                                                                  </FormGroup>
                                                              </Col>
                                                              <Col
                                                                  sm="4"
                                                                  className="mb-3"
                                                              >
                                                                  <Label check>
                                                                      Related
                                                                      Lessons
                                                                  </Label>
                                                                  <FormGroup
                                                                      floating
                                                                  >
                                                                      <Field
                                                                          className={`custom-select`}
                                                                          name={`relatedlessons`}
                                                                          options={
                                                                              relatedlessons
                                                                          }
                                                                          component={
                                                                              CustomSelect
                                                                          }
                                                                          placeholder="Select Branches"
                                                                          isMulti={
                                                                              true
                                                                          }
                                                                      />
                                                                      <ErrorMessage
                                                                          name="relatedlessons"
                                                                          component="div"
                                                                          className="invalid-feedback"
                                                                      />
                                                                  </FormGroup>
                                                              </Col>
                                                              <Col
                                                                  sm="4"
                                                                  className="mb-3"
                                                              >
                                                                  <Label check>
                                                                      Reusable
                                                                      Bank of
                                                                      Knowledge
                                                                  </Label>
                                                                  <small className="d-block">
                                                                      Allow
                                                                      others
                                                                      users,
                                                                      such as
                                                                      teachers,
                                                                      trainers,
                                                                      influencers,
                                                                      colleges,
                                                                      schools,
                                                                      businesses
                                                                      etc… to
                                                                      search,
                                                                      find and
                                                                      use your
                                                                      lessons
                                                                      and
                                                                      quizzes to
                                                                      train
                                                                      their
                                                                      followers,
                                                                      students,
                                                                      trainees.
                                                                      Your
                                                                      username
                                                                      will be
                                                                      always
                                                                      visible to
                                                                      other
                                                                      users
                                                                  </small>
                                                                  <Row className="mb-3 mx-2">
                                                                      {/* Allow Option */}
                                                                      <Col sm="auto">
                                                                          <FormGroup
                                                                              className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                                                                  values.breakinActivity ===
                                                                                  false
                                                                                      ? "transform-scale"
                                                                                      : ""
                                                                              }`}
                                                                              check
                                                                          >
                                                                              <Label
                                                                                  className={`mb-0 ${
                                                                                      values.breakinActivity ===
                                                                                      false
                                                                                          ? "text-success"
                                                                                          : ""
                                                                                  }`}
                                                                                  for="yes"
                                                                                  check
                                                                              >
                                                                                  <Input
                                                                                      id="yes"
                                                                                      name="breakinActivity"
                                                                                      value={
                                                                                          false
                                                                                      } // Value as a boolean
                                                                                      type="checkbox" // Radio buttons for mutually exclusive options
                                                                                      onChange={() =>
                                                                                          setFieldValue(
                                                                                              "breakinActivity",
                                                                                              false
                                                                                          )
                                                                                      }
                                                                                      checked={
                                                                                          values.breakinActivity ===
                                                                                          false
                                                                                      }
                                                                                      className="form-check-input"
                                                                                  />
                                                                                  <span className="ms-2">
                                                                                      Allow
                                                                                  </span>
                                                                              </Label>
                                                                          </FormGroup>
                                                                      </Col>

                                                                      {/* Do not Option */}
                                                                      <Col
                                                                          sm="auto"
                                                                          className="mx-sm-2 mx-lg-4"
                                                                      >
                                                                          <FormGroup
                                                                              className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                                                                  values.breakinActivity ===
                                                                                  true
                                                                                      ? "transform-scale"
                                                                                      : ""
                                                                              }`}
                                                                              check
                                                                          >
                                                                              <Label
                                                                                  className={`mb-0 ${
                                                                                      values.breakinActivity ===
                                                                                      true
                                                                                          ? "text-success"
                                                                                          : ""
                                                                                  }`}
                                                                                  for="no"
                                                                                  check
                                                                              >
                                                                                  <Input
                                                                                      id="no"
                                                                                      name="breakinActivity"
                                                                                      value={
                                                                                          true
                                                                                      } // Value as a boolean
                                                                                      type="checkbox"
                                                                                      onChange={() =>
                                                                                          setFieldValue(
                                                                                              "breakinActivity",
                                                                                              true
                                                                                          )
                                                                                      }
                                                                                      checked={
                                                                                          values.breakinActivity ===
                                                                                          true
                                                                                      }
                                                                                      className="form-check-input"
                                                                                  />
                                                                                  <span className="ms-2">
                                                                                      Do
                                                                                      not
                                                                                  </span>
                                                                              </Label>
                                                                          </FormGroup>
                                                                      </Col>
                                                                  </Row>

                                                                  {/* Submit Button */}
                                                                  <Button
                                                                      type="submit"
                                                                      color="primary"
                                                                  >
                                                                      Save
                                                                      Questionnaire
                                                                      Header
                                                                  </Button>
                                                              </Col>
                                                          </Row>
                                                      ) : (
                                                          ""
                                                      )}
                                                      <Popup
                                                          title="Quizz Game"
                                                          openModalToggle={
                                                              openQuizzModalToggle
                                                          }
                                                          isOpen={
                                                              openQuizzModal
                                                          }
                                                          size={`lg`}
                                                          backdrop={true}
                                                          CommonFooter={true}
                                                          isClose={false}
                                                          CommonFooter={false}
                                                          BodyClassName="pt-1"
                                                          largesize="modal-custom"
                                                      >
                                                          <div className="mx-2 mt-2 mb-3 px-2">
                                                              {quizUi &&
                                                              (values.questionnaireType ===
                                                                  "game" ||
                                                                  values.questionnaireType ===
                                                                      "quiz") ? (
                                                                  <QuizzGame />
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </Popup>
                                                      {/* {quizUi && (values.questionnaireType === 'game' || values.questionnaireType === 'quiz') ? <QuizzGame /> : ''} */}
                                                  </Form>
                                              </Row>
                                              <Row></Row>

                                              <div className="mt-3">
                                                  <H4 className="mb-3">
                                                      Question List
                                                  </H4>
                                                  <div className="mt-2">
                                                      <div>
                                                          No. of Applied
                                                          Questions: 0
                                                      </div>
                                                      <div>
                                                          Total Grades of
                                                          Selected Questions: 0
                                                      </div>
                                                      <div>
                                                          Total Duration of
                                                          Selected Questions: 0
                                                          minutes
                                                      </div>
                                                  </div>
                                                  <Nav className="mt-3" tabs>
                                                      <NavItem>
                                                          <NavLink
                                                              className={
                                                                  activeTab ===
                                                                  "1"
                                                                      ? "active bg-light-gray"
                                                                      : ""
                                                              }
                                                              onClick={() =>
                                                                  toggle("1")
                                                              }
                                                              style={{
                                                                  cursor: "pointer",
                                                              }} // Ensures it's clickable
                                                          >
                                                              Applied Questions
                                                          </NavLink>
                                                      </NavItem>
                                                      <NavItem>
                                                          <NavLink
                                                              className={
                                                                  activeTab ===
                                                                  "2"
                                                                      ? "active bg-light-gray"
                                                                      : ""
                                                              }
                                                              onClick={() =>
                                                                  toggle("2")
                                                              }
                                                              style={{
                                                                  cursor: "pointer",
                                                              }}
                                                          >
                                                              Import from Other
                                                              Questionnaires
                                                          </NavLink>
                                                      </NavItem>
                                                      <NavItem>
                                                          <NavLink
                                                              className={
                                                                  activeTab ===
                                                                  "3"
                                                                      ? "active bg-light-gray"
                                                                      : ""
                                                              }
                                                              onClick={() =>
                                                                  toggle("3")
                                                              }
                                                              style={{
                                                                  cursor: "pointer",
                                                              }}
                                                          >
                                                              Add New Questions
                                                          </NavLink>
                                                      </NavItem>
                                                  </Nav>
                                                  <TabContent
                                                      activeTab={activeTab}
                                                  >
                                                      <TabPane tabId="1">
                                                          <div className="p-2 py-3 table-responsive">
                                                              <DataTable
                                                                  className="custom-scrollbar"
                                                                  columns={
                                                                      appliedColumns
                                                                  }
                                                                  data={
                                                                      applieddata
                                                                  }
                                                                  striped={true}
                                                                  pagination
                                                                  selectableRows
                                                                  onSelectedRowsChange={
                                                                      handleRowSelected
                                                                  }
                                                                  clearSelectedRows={
                                                                      toggleDelete
                                                                  }
                                                              />
                                                          </div>
                                                      </TabPane>
                                                      <TabPane tabId="2">
                                                          <Formik
                                                              initialValues={{
                                                                  activeQuestionCheckbox:
                                                                      "first",
                                                              }}
                                                              validationSchema={Yup.object(
                                                                  {
                                                                      activeQuestionCheckbox:
                                                                          Yup.string().required(
                                                                              "Please select an option"
                                                                          ),
                                                                  }
                                                              )}
                                                              onSubmit={(
                                                                  values
                                                              ) => {
                                                                  console.log(
                                                                      "Selected Option:",
                                                                      values.activeQuestionCheckbox
                                                                  );
                                                              }}
                                                          >
                                                              {({
                                                                  values,
                                                                  setFieldValue,
                                                              }) => (
                                                                  <Form className="my-2">
                                                                      <QuestionSelectForm />
                                                                  </Form>
                                                              )}
                                                          </Formik>
                                                      </TabPane>
                                                      <TabPane tabId="3">
                                                          <>
                                                              <div className="my-2">
                                                                  <Btn
                                                                      onClick={
                                                                          openQestionPageModalToggle
                                                                      }
                                                                      className="p-0 child_btn border rounded"
                                                                  >
                                                                      <span>
                                                                          Import
                                                                          Bulk
                                                                          Questions
                                                                      </span>
                                                                  </Btn>
                                                              </div>

                                                              <Popup
                                                                  title="Import Bulk Questions"
                                                                  openModalToggle={
                                                                      openQestionPageModalToggle
                                                                  }
                                                                  isOpen={
                                                                      openQestionPageModal
                                                                  }
                                                                  size="lg"
                                                                  backdrop={
                                                                      true
                                                                  }
                                                                  CommonFooter={
                                                                      true
                                                                  }
                                                                  isClose={
                                                                      false
                                                                  }
                                                                  CommonFooter={
                                                                      false
                                                                  }
                                                                  BodyClassName="pt-1"
                                                                  largesize="modal-custom"
                                                              >
                                                                  <ImportBulk
                                                                      titleField={
                                                                          true
                                                                      }
                                                                  />
                                                              </Popup>
                                                              <Formik
                                                                  initialValues={
                                                                      questionFormInitialValue
                                                                  }
                                                                  onSubmit={
                                                                      handleSubmit
                                                                  }
                                                                  validationSchema={
                                                                      questionFormValidationSchema
                                                                  }
                                                              >
                                                                  {({
                                                                      errors,
                                                                      values,
                                                                      setFieldValue,
                                                                  }) => (
                                                                      <AddQuestionsForm
                                                                          submitErrors={
                                                                              submitErrors
                                                                          }
                                                                          setSubmitError={
                                                                              setSubmitError
                                                                          }
                                                                          errors={
                                                                              errors
                                                                          }
                                                                          addQuestions={
                                                                              true
                                                                          }
                                                                          values={
                                                                              values
                                                                          }
                                                                          testquizsetting={
                                                                              true
                                                                          }
                                                                          setFieldValue={
                                                                              setFieldValue
                                                                          }
                                                                      />
                                                                  )}
                                                              </Formik>
                                                          </>
                                                      </TabPane>
                                                  </TabContent>
                                              </div> 
                                              <SharingSection
                CoManagement={false}
                InviteeList={true}
                errors={errors}
                setFieldValue={setFieldValue}
                values={values}
                viewOnly={true}
            />
                                              <Popup
                                                  title="Publish Online"
                                                  openModalToggle={
                                                      openPublishModalToggle
                                                  }
                                                  isOpen={openPublishModal}
                                                  size={`lg`}
                                                  backdrop={true}
                                                  CommonFooter={true}
                                                  isClose={false}
                                                  CommonFooter={false}
                                                  BodyClassName="pt-1"
                                                  largesize="modal-custom"
                                              >
                                                  <Formik
                                                      initialValues={
                                                          FequentlyinitialValues
                                                      }
                                                      validationSchema={
                                                          FequentlyValidationSchema
                                                      }
                                                      onSubmit={(values) => {
                                                          console.log(
                                                              "Selected values:",
                                                              values
                                                          );
                                                      }}
                                                  >
                                                      {({
                                                          handleChange,
                                                          values,
                                                          errors,
                                                          setFieldValue,
                                                      }) => (
                                                          <FequentlyModalForm
                                                              selectField="Seminar"
                                                              setFieldValue={
                                                                  setFieldValue
                                                              }
                                                              handleChange={
                                                                  handleChange
                                                              }
                                                              values={values}
                                                              submitErrors={
                                                                  submitErrors
                                                              }
                                                              errors={errors}
                                                          />
                                                      )}
                                                  </Formik>
                                              </Popup>

                                               
                                              <Row>
                                                  <Col
                                                      sm="auto"
                                                      className="mb-3 "
                                                  >
                                                      <Btn
                                                          color="primary"
                                                          type="submit"
                                                          onClick={() =>
                                                              setSubmitError(
                                                                  true
                                                              )
                                                          }
                                                      >
                                                          Save as a Draft
                                                      </Btn>
                                                  </Col>
                                                  <Col
                                                      sm="auto"
                                                      className="mb-3 "
                                                  >
                                                      <Btn
                                                          color="primary"
                                                          type="submit"
                                                          onClick={
                                                              openPublishModalToggle
                                                          }
                                                      >
                                                          Save and Publish
                                                          Online
                                                      </Btn>
                                                  </Col>
                                                  <Col
                                                      sm="auto"
                                                      className="mb-3 "
                                                  >
                                                      <Btn
                                                          color="primary"
                                                          type="submit"
                                                          onClick={
                                                              openQuizzModalToggle
                                                          }
                                                      >
                                                          Preview
                                                      </Btn>
                                                  </Col>
                                              </Row>
                                          </>
                                      )}
                                  </Formik>
                              </div>
                              <Popup
                                  title="Import Bulk Questionnaires"
                                  openModalToggle={openBulkQestionModalToggle}
                                  isOpen={openBulkQestionModal}
                                  size={`lg`}
                                  backdrop={true}
                                  CommonFooter={true}
                                  isClose={false}
                                  CommonFooter={false}
                                  BodyClassName="pt-1"
                                  largesize="modal-custom"
                              >
                                  <ImportBulk />
                              </Popup>
                              <Popup
                                  title={"Add Questionnaire Settings"}
                                  openModalToggle={openQestionModalToggle}
                                  isOpen={openQestionModal}
                                  size={`lg`}
                                  backdrop={true}
                                  CommonFooter={true}
                                  isClose={false}
                                  CommonFooter={false}
                                  BodyClassName="pt-1"
                                  largesize="modal-custom"
                              >
                                  <div className="mx-2 mt-2 mb-3 px-2">
                                      <>
                                          <Formik
                                              initialValues={
                                                  surveyAndQuizzesFormInitialValue
                                              }
                                              onSubmit={handleSubmit}
                                              validationSchema={
                                                  TabsurveyAndQuizzesFormValidationSchema
                                              }
                                          >
                                              {({
                                                  errors,
                                                  values,
                                                  setFieldValue,
                                                  handleSubmit,
                                              }) => (
                                                  <AddSurveyAndQuizzesForm
                                                      submitErrors={
                                                          submitErrors
                                                      }
                                                      setSubmitError={
                                                          setSubmitError
                                                      }
                                                      errors={errors}
                                                      values={values}
                                                      setFieldValue={
                                                          setFieldValue
                                                      }
                                                      addquizsurveysTab={false}
                                                      testquizsetting={false}
                                                      handleSubmit={
                                                          handleSubmit
                                                      }
                                                      // setCategory={setCategory}
                                                  />
                                              )}
                                          </Formik>
                                      </>
                                  </div>
                              </Popup>
                              <Popup
                                  title={"Add Course and Teacher Information"}
                                  openModalToggle={openGenralModalToggle}
                                  isOpen={openGenralModal}
                                  size={`lg`}
                                  backdrop={true}
                                  CommonFooter={true}
                                  isClose={false}
                                  CommonFooter={false}
                                  BodyClassName="pt-1"
                                  largesize="modal-custom"
                              >
                                  <div className="mx-2 mt-2 mb-3 px-2">
                                      <AddCourseandTeacherInformation />
                                  </div>
                              </Popup>
                              <Popup
                                  title="Add Frequently Asked Questions"
                                  openModalToggle={openFaqModalToggle}
                                  isOpen={openFaqModal}
                                  size={`lg`}
                                  backdrop={true}
                                  CommonFooter={true}
                                  isClose={false}
                                  CommonFooter={false}
                                  BodyClassName="pt-1"
                                  largesize="modal-custom"
                              >
                                  <div className="mx-2 mt-2 mb-3 px-2">
                                      <Formik
                                          initialValues={
                                              scheduleFormInitialValue
                                          }
                                          onSubmit={handleSubmit}
                                          validationSchema={
                                              scheduleFormValidationSchema
                                          }
                                      >
                                          {({ errors, values }) => (
                                              <AddFaqForm values={values} />
                                          )}
                                      </Formik>
                                  </div>
                              </Popup>
                          </CardBody>
                      </Card>
                  </Col>
              </Row>
          </Container>
      </div>
  );
};

export default AddQuestionnaire;
