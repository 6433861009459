import { apiSlice } from "../../apiSlice";
import {
  GET_CURRENT_REGISTRATION_PLAN,
  SET_ACTIVE_REGISTRATION_PLAN,
  ADD_ADDITIONAL_SERVICE,
} from "../../../Api";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const registrationPlanApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentRegistrationPlan: builder.mutation<SuccessDataProps, void>({
      query: () => ({
        url: GET_CURRENT_REGISTRATION_PLAN,
        method: "GET",
      }),
    }),
    setActiveRegistrationPlan: builder.mutation<SuccessDataProps, any>({
      query: (data) => ({
        url: `${SET_ACTIVE_REGISTRATION_PLAN}`,
        method: "POST",
        body: data,
      }),
    }),
    addAdditionalService: builder.mutation<SuccessDataProps, any>({
      query: (data) => ({
        url: `${ADD_ADDITIONAL_SERVICE}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetCurrentRegistrationPlanMutation,
  useSetActiveRegistrationPlanMutation,
  useAddAdditionalServiceMutation,
} = registrationPlanApiSlice;
