import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import TabOne from "./TabOne";
import TabTwo from "./TabTwo";
import TabThree from "./TabThree";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../ReduxToolkit/Hooks";
import { Href } from "../../../../../../utils/Constant";
import { setTabId } from "../../../../../../ReduxToolkit/Reducers/AddProductSlice";
import { productFiveNavData } from "../../../../../../Data/Application/Ecommerce/AddProduct";
import { CardHeader, Col, Form, Input, Label,   Button } from "reactstrap";
import { ChooseYourCurrency, InitialCost, ProductStocks, SellingPrice } from "../../../../../../utils/Constant";
import TypesOfProduct from "./TypesOfProduct"; 
import BranchSetupPrice from "./BranchSetupPrice";
import { FequentlyinitialValues, FequentlyValidationSchema, scheduleFormInitialValue, scheduleFormValidationSchema, SchedulesFormValidationProp } from "../../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { Formik } from "formik";
import { useState } from "react";
import CategoryTable from "./CategoryTable";
import { Btn, H3 } from "../../../../../../AbstractElements";
import { setFormValue } from "../../../../../../ReduxToolkit/Reducers/AddProductServiceSlice";
import Popup from "../../../../../MasterPopup/Popup";
import FequentlyModalForm from "../../../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyModalForm";
const ProductFive = () => {
  const {tabId} = useAppSelector((state)=>state.addProduct)
  const dispatch = useDispatch()
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const [openPublishModal, setOpenPublishModal] = useState(false);
    const openPublishModalToggle = () => setOpenPublishModal(!openPublishModal);
  return (
    <div className="sidebar-body advance-options">  
     <Popup
                title='Publish Online'
                openModalToggle={openPublishModalToggle}
                isOpen={openPublishModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                  <Formik
                        initialValues={FequentlyinitialValues}
                        validationSchema={FequentlyValidationSchema}
                        onSubmit={(values) => {
                          console.log("Selected values:", values);
                        }}
                      >
                        {({ handleChange, values,errors,setFieldValue }) => ( 
               <FequentlyModalForm selectField='product' setFieldValue={setFieldValue} handleChange={handleChange}  values={values} submitErrors={submitErrors} errors={errors}/>
              )}
    </Formik>
 
            </Popup>
   
    </div>
  );
};

export default ProductFive;
