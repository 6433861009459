import { useEffect, useState } from "react";
import { useGetAccountHistoryShowByNoMutation } from "../../../ReduxToolkit/Reducers/AccountHistory/accountHistoryApliSlice";
import Loading from "../../../Component/Loading/Loading";
import { Row, Col, Container, Card, CardBody } from "reactstrap";
import { H3, P } from "../../../AbstractElements";

function AccountHistoryView(props: any) {
  const [historyData, setHistoryData] = useState<any>([]);

  const { historyNo } = props;

  const [getAccountHistoryShowByNoApi, { isLoading }] =
    useGetAccountHistoryShowByNoMutation();

  const getHistoryDataByNo = async (history_number: any) => {
    try {
      const response = await getAccountHistoryShowByNoApi({
        no: history_number,
      }).unwrap();
      const { data }: any = response;
      setHistoryData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHistoryDataByNo(historyNo);
  }, [historyNo]);
  
  if (isLoading) return <Loading />;

  return (
    <Container fluid>
      <Row>
        <Col className="box-col-12">
          <Row>
            <Col xl="6" className="xl-100 mt-4">
              <H3 className="f-w-600 mb-3 text-capitalize " >{historyData.action}</H3>
              <Card className={`border`}>
                <div className="job-search">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <H3 className="f-w-600">{`Event Info`}</H3>
                      </div>
                    </div>
                    <Row>
                      <Col className="border-end">
                        <P>
                          <span className="f-w-600">Hisotry No : </span>{" "}
                          {historyData.historyNo}
                        </P>
                      </Col>
                      <Col>
                        <P>
                          <span className="f-w-600">User Name : </span>{" "}
                          {historyData.userName}
                        </P>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="border-end">
                        <P>
                          <span className="f-w-600">Module Name : </span>{" "}
                          {historyData.moduleName}
                        </P>
                      </Col>
                      <Col>
                        <P>
                          <span className="f-w-600">Action : </span>{" "}
                          {historyData.action}
                        </P>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="border-end">
                        <P>
                          <span className="f-w-600">Created At : </span>{" "}
                          {historyData.created_at}
                        </P>
                      </Col>
                      <Col></Col>
                    </Row>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="6" className="xl-100">
              <Card className={`border`}>
                <div className="job-search">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <H3 className="f-w-600">{`Event Detail`}</H3>
                      </div>
                    </div>
                    <p
                      style={{
                        whiteSpace: "pre-wrap",
                        padding: "20px",
                        borderRadius: "5px",
                        backgroundColor: "#80808036",
                      }}
                    >
                      {historyData.details &&
                        JSON.stringify(historyData.details, null, 2)}
                    </p>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default AccountHistoryView;
