// // import React, { useState } from "react";
// // import { Formik, Field, Form, FieldArray } from "formik";
// // import Select from "react-select";
// // import DataTable from "react-data-table-component";
// // import * as Yup from "yup";
// // import { Row, Col, Button } from "reactstrap";
// // import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
// // import { Link } from "react-router-dom";
// // import { Btn } from "../../../../AbstractElements";
// // import Popup from "../../../MasterPopup/Popup";
// // import { FequentlyinitialValues, FequentlyValidationSchema } from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
// // import FequentlyModalForm from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyModalForm";

// // const existingQuestionsOptions = [
// //   { label: "Math Question 1", value: "q1", category: "Math", questions: 10, duration: 60 },
// //   { label: "History Question 2", value: "q2", category: "History", questions: 12, duration: 45 },
// // ];

// // const knowledgeBankOptions = [
// //   { label: "Science Question 1", value: "kb1", category: "Science", questions: 8,  username: "User1" },
// //   { label: "Geography Question 2", value: "kb2", category: "Geography", questions: 15,  username: "User2" },
// // ];

// // const QuestionTable = ({ selectedQuestions, handleSwitchToggle }) => {
// //   const totalGrade = selectedQuestions.reduce((sum, q) => sum + (q.grade || 0), 0);
// //   const totalDuration = selectedQuestions.reduce((sum, q) => sum + q.duration, 0);

// //   const columns = [
// //     { name: "Question", selector: (row) => row.label },
// //     { name: "Grade", selector: (row) => row.grade || 0 },
// //     { name: "Duration", selector: (row) => row.duration },
// //     { name: "Obligatory", selector: (row) => (
// //         <CommonSwitchSpan
// //           color={"primary"}
// //           name="obligatory_switch"
// //           className={`form-control`}
// //           checked={row.obligatory_switch}
// //           onChange={() => handleSwitchToggle(row)}
// //         />
// //       )
// //     }
// //   ];

// //   return (
// //     <>
// //       {selectedQuestions.length > 0 && (
// //         <>
// //         <DataTable
// //           columns={columns}
// //           data={selectedQuestions}
// //           pagination
// //           selectableRows
// //         />
// //         </>
// //       )}
// //       <div>
// //         <div>Total Grades of Selected Questions: {totalGrade}</div>
// //         <div>Total Duration of Selected Questions: {totalDuration} minutes</div>
// //       </div>
// //     </>
// //   );
// // };

// // const QuestionSelectForm = () => {
// //   const [selectedQuestions, setSelectedQuestions] = useState([]);
// //   const [obligatory_switch, setobligatory_switch] = useState(true);
// //   const [submitErrors, setSubmitError] = useState<boolean>(false);
// //  const jobLocation = [
// //         { value: 'remote', label: 'Remote' },
// //         { value: 'onsite', label: 'Onsite' },
// //         { value: 'hybrid', label: 'Hybrid' },
// //         { value: 'usa', label: 'USA' },
// //         { value: 'uk', label: 'UK' },
// //         { value: 'canada', label: 'Canada' },
// //       ];
// //   const handleSwitchToggle = (row) => {
// //     const updatedQuestions = selectedQuestions.map((q) =>
// //       q.label === row.label ? { ...q, obligatory_switch: !q.obligatory_switch } : q
// //     );
// //     setSelectedQuestions(updatedQuestions);
// //   };

// //   const initialValues = {
// //     questions: [{ existingQuestions: null, knowledgeBank: null, obligatory_switch: false }],
// //   };
// //   const [openPublishModal, setOpenPublishModal] = useState(false);
// //   const openPublishModalToggle = () => setOpenPublishModal(!openPublishModal);
// //   const validationSchema = Yup.object({
// //     questions: Yup.array().of(
// //       Yup.object({
// //         existingQuestions: Yup.object().nullable(),
// //         knowledgeBank: Yup.object().nullable(),
// //         obligatory_switch: Yup.boolean(),
// //       })
// //     ),
// //   });

// //   const handleDropdownChange = (field, selectedOption, index, setFieldValue, values) => {
// //     const updatedQuestions = [...values.questions];
// //     updatedQuestions[index][field] = selectedOption;
// //     setFieldValue("questions", updatedQuestions);
// //     setSelectedQuestions([...selectedQuestions, { ...selectedOption, obligatory_switch: false }]);
// //   };

// //   return (
// //     <Formik
// //       initialValues={initialValues}
// //       validationSchema={validationSchema}
// //       onSubmit={(values) => console.log(values)}
// //     >
// //       {({ setFieldValue, values }) => (
// //         <Form>
// //           <FieldArray name="questions">
// //             {({ push }) => (
// //               <>
// //                 {values.questions.map((_, index) => (
// //                   <div key={index}>
// //                   <Row >
// //                     <Col sm="4" className="mb-3">
// //                       <label>YOUR existing questions</label>
// //                       <Field
// //                         name={`questions[${index}].existingQuestions`}
// //                         render={({ field }) => (
// //                           <Select
// //                             {...field}
// //                             options={existingQuestionsOptions}
// //                             placeholder="Select from existing questions"
// //                             getOptionLabel={(option) =>
// //                               `${option.label} - ${option.category} - ${option.questions} Questions - ${option.duration} mins`
// //                             }
// //                             onChange={(selectedOption) => {
// //                               handleDropdownChange("existingQuestions", selectedOption, index, setFieldValue, values);
// //                             }}
// //                           />
// //                         )}
// //                       />
// //                          <span className="text-muted small mt-1">

// //         </span>
// //                     </Col>
// //                     <Col sm="4" className="mb-3">
// //                       <label>KNOWLEDGE BANK, public questions from other users</label>
// //                       <Field
// //                         name={`questions[${index}].knowledgeBank`}
// //                         render={({ field }) => (
// //                           <Select
// //                             {...field}
// //                             options={knowledgeBankOptions}
// //                             placeholder="Select from knowledge bank"
// //                             getOptionLabel={(option) =>
// //                               `${option.label} - ${option.category} - ${option.questions} Questions - ${option.duration} mins - ${option.username}`
// //                             }
// //                             onChange={(selectedOption) => {
// //                               handleDropdownChange("knowledgeBank", selectedOption, index, setFieldValue, values);
// //                             }}
// //                           />
// //                         )}
// //                       />
// //                     </Col>
// //                   </Row>
// //                   </div>
// //                 ))}
// //                 <Button type="button" onClick={() => push({ existingQuestions: null, knowledgeBank: null })}>
// //                    Other Questions
// //                 </Button>
// //               </>
// //             )}
// //           </FieldArray>
// //           <Col sm="12" className="mb-3">
// //             <QuestionTable selectedQuestions={selectedQuestions} handleSwitchToggle={handleSwitchToggle} />
// //           </Col>

// //         </Form>
// //       )}
// //     </Formik>
// //   );
// // };

// // export default QuestionSelectForm;
// import React, { useState } from "react";
// import { Formik, Form, Field } from "formik";
// import Select from "react-select";
// import { Row, Col, Button } from "reactstrap";
// import DataTable from "react-data-table-component";
// import * as Yup from "yup";
// import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";

// const existingQuestionsOptions = [
//   {
//     label: "Math Question 1",
//     value: "q1",
//     category: "Math",
//     questions: 10,
//     duration: 60,
//   },
//   {
//     label: "History Question 2",
//     value: "q2",
//     category: "History",
//     questions: 12,
//     duration: 45,
//   },
// ];

// const knowledgeBankOptions = [
//   {
//     label: "Science Question 1",
//     value: "kb1",
//     category: "Science",
//     questions: 8,
//     username: "User1",
//   },
//   {
//     label: "Geography Question 2",
//     value: "kb2",
//     category: "Geography",
//     questions: 15,
//     username: "User2",
//   },
// ];

// const QuestionSelectForm = () => {
//   const [selectedExistingQuestions, setSelectedExistingQuestions] = useState(
//     []
//   );
//   const [selectedKnowledgeBank, setSelectedKnowledgeBank] = useState([]);

//   const [selectedExistingQuestion, setSelectedExistingQuestion] =
//     useState(null);
//   const [selectedKnowledgeQuestion, setSelectedKnowledgeQuestion] =
//     useState(null);

//   const validationSchema = Yup.object({
//     existingQuestions: Yup.object().nullable(),
//     knowledgeBank: Yup.object().nullable(),
//   });

//   const handleAddExistingQuestion = () => {
//     if (selectedExistingQuestion) {
//       setSelectedExistingQuestions([
//         ...selectedExistingQuestions,
//         { ...selectedExistingQuestion },
//       ]);
//       setSelectedExistingQuestion(null);
//     }
//   };

//   const handleAddKnowledgeQuestion = () => {
//     if (selectedKnowledgeQuestion) {
//       setSelectedKnowledgeBank([
//         ...selectedKnowledgeBank,
//         { ...selectedKnowledgeQuestion },
//       ]);
//       setSelectedKnowledgeQuestion(null);
//     }
//   };

//   const handleRemoveExistingQuestion = (index) => {
//     setSelectedExistingQuestions(
//       selectedExistingQuestions.filter((_, i) => i !== index)
//     );
//   };

//   const handleRemoveKnowledgeQuestion = (index) => {
//     setSelectedKnowledgeBank(
//       selectedKnowledgeBank.filter((_, i) => i !== index)
//     );
//   };

//   return (
//     <Formik
//       initialValues={{ existingQuestions: null, knowledgeBank: null,allowedToDeliver:false }}
//       validationSchema={validationSchema}
//       onSubmit={(values) => console.log(values)}
//     >
//       {({ setFieldValue }) => (
//         <Form>
//           <Row className="align-items-end">
//             {/* Existing Questionnaires Section */}
//             <Col sm="4" className="mb-3">
//               <label>YOUR existing questionnaires</label>

//               <Select
//                 className="react-select-container"
//                 classNamePrefix="react-select"
//                 options={existingQuestionsOptions}
//                 placeholder="Select from existing questionnaires"
//                 getOptionLabel={(option) =>
//                   `${option.label} - ${option.category} - ${option.questions} Questions - ${option.duration} mins`
//                 }
//                 value={selectedExistingQuestion}
//                 onChange={(selectedOption) => {
//                   setSelectedExistingQuestion(selectedOption);
//                   setFieldValue("existingQuestions", selectedOption);
//                 }}
//                 // isMulti={true}
//               />
//             </Col>
//             <Col sm="4" className="d-flex align-items-end mb-3">
//               <Button color="primary" onClick={handleAddExistingQuestion}>
//                 Confirm
//               </Button>
//             </Col>
//           </Row>

//           {/* Table for Existing Questions */}
//           <h5>Existing Questions Table</h5>
//           {selectedExistingQuestions.length > 0 && (
//             <DataTable
//               columns={[
//                 { name: "Question", selector: (row) => row.label,grow:7 },
//                 { name: "Category", selector: (row) => row.category },
//                 { name: "Grade", selector: (row) => row.questions },
//                 { name: "Duration", selector: (row) => row.duration },
//                 {
//                   name: "Obligatory",
//                   cell: (row, index) => (
//                     <div className="flex-shrink-0  text-end icon-state switch-outline">
//                 <CommonSwitchSpan name='allowedToDeliver' color={"primary"} defaultChecked />
//               </div>
//                   ),
//                 },
//               ]}
//               data={selectedExistingQuestions}
//               pagination
//               striped={true}
//               selectableRows
//             />
//           )}

//           <Row className="mt-4 align-items-end">
//             {/* Knowledge Bank Section */}
//             <Col sm="4" className="mb-3">
//               <label>
//                 KNOWLEDGE BANK, public questionnaires from other users
//               </label>
//               <Select
//                 className="react-select-container"
//                 classNamePrefix="react-select"
//                 options={knowledgeBankOptions}
//                 placeholder="Select from knowledge bank"
//                 isMulti={true}
//                 getOptionLabel={(option) =>
//                   `${option.label} - ${option.category} - ${option.questions} Questions - ${option.username}`
//                 }
//                 value={selectedKnowledgeQuestion}
//                 onChange={(selectedOption) => {
//                   setSelectedKnowledgeQuestion(selectedOption);
//                   setFieldValue("knowledgeBank", selectedOption);
//                 }}
//               />
//             </Col>
//             <Col sm="4" className="d-flex align-items-end mb-3">
//               <Button color="primary" onClick={handleAddKnowledgeQuestion}>
//               Confirm
//               </Button>
//             </Col>
//           </Row>

//           {/* Table for Knowledge Bank Questions */}
//           <h5>Knowledge Bank Table</h5>
//           {selectedKnowledgeBank.length > 0 && (
//             <DataTable
//               columns={[
//                 { name: "Question", selector: (row) => row.label },
//                 { name: "Category", selector: (row) => row.category },
//                 { name: "Questions", selector: (row) => row.questions },
//                 { name: "Username", selector: (row) => row.username },
//                 {
//                   name: "Obligatory",
//                   cell: (row, index) => (
//                     <div className="flex-shrink-0  text-end icon-state switch-outline">
//                 <CommonSwitchSpan name='allowedToDeliver' color={"primary"} defaultChecked />
//               </div>
//                   ),
//                 },
//               ]}
//               data={selectedKnowledgeBank}
//               pagination
//               striped={true}
//               selectableRows
//             />
//           )}
//         </Form>
//       )}
//     </Formik>
//   );
// };

// export default QuestionSelectForm;
import React, { useState } from "react";
import { Formik, Form } from "formik";
import Select from "react-select";
import { Row, Col, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import * as Yup from "yup";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";

const existingQuestionsOptions = [
  { label: "Math Question 1", value: "q1", category: "Math", questions: 10, duration: 60 },
  { label: "History Question 2", value: "q2", category: "History", questions: 12, duration: 45 },
];

const knowledgeBankOptions = [
  { label: "Science Question 1", value: "kb1", category: "Science", questions: 8, username: "User1" },
  { label: "Geography Question 2", value: "kb2", category: "Geography", questions: 15, username: "User2" },
];

const QuestionSelectForm = () => {
  const [existingTables, setExistingTables] = useState([]);
  const [knowledgeTables, setKnowledgeTables] = useState([]);
  const [selectedExistingQuestion, setSelectedExistingQuestion] = useState(null);
  const [selectedKnowledgeQuestion, setSelectedKnowledgeQuestion] = useState(null);

  const handleAddExistingQuestion = (values, setFieldValue) => {
    if (selectedExistingQuestion) {
      const newTable = { ...selectedExistingQuestion, obligatory: false };
      setExistingTables([...existingTables, newTable]);
      setFieldValue(`obligatory.${newTable.value}`, false); // Initialize in Formik state
      setSelectedExistingQuestion(null);
    }
  };

  const handleAddKnowledgeQuestion = (values, setFieldValue) => {
    if (selectedKnowledgeQuestion) {
      const newTable = { ...selectedKnowledgeQuestion, obligatory: false };
      setKnowledgeTables([...knowledgeTables, newTable]);
      setFieldValue(`obligatory.${newTable.value}`, false); // Initialize in Formik state
      setSelectedKnowledgeQuestion(null);
    }
  };

  const handleRemoveTable = (id, type, setFieldValue) => {
    if (type === "existing") {
      setExistingTables(existingTables.filter((item) => item.value !== id));
    } else {
      setKnowledgeTables(knowledgeTables.filter((item) => item.value !== id));
    }
    setFieldValue(`obligatory.${id}`, undefined); // Remove from Formik state
  };

  return (
      <Formik
          initialValues={{ obligatory: {} }}
          validationSchema={Yup.object({})}
          onSubmit={(values) => console.log(values)}
      >
          {({ setFieldValue, values }) => (
              <Form>
                  {/* Existing Questionnaires Selection */}
                  <Row className="align-items-end">
                      <Col sm="4" className="mb-3">
                          <label>YOUR existing questionnaires</label>
                          <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={existingQuestionsOptions}
                              placeholder="Select from existing questionnaires"
                              getOptionLabel={(option) =>
                                  `${option.label} - ${option.category} - ${option.questions} Questions - ${option.duration} mins`
                              }
                              value={selectedExistingQuestion}
                              onChange={(selectedOption) =>
                                  setSelectedExistingQuestion(selectedOption)
                              }
                          />
                      </Col>
                      <Col sm="4" className="d-flex align-items-end mb-3">
                          <Button
                              color="primary"
                              onClick={() =>
                                  handleAddExistingQuestion(
                                      values,
                                      setFieldValue
                                  )
                              }
                          >
                              Confirm
                          </Button>
                      </Col>
                  </Row>

                  {/* Generate a new table for each selected existing question */}
                  {existingTables.map((item) => (
                      <div key={item.value} className="mb-4">
                          <div className="d-flex gap-2 align-items-center">
                              <h5>{item.label}</h5>
                              <Button color="primary">Import Selected</Button>
                          </div>
                          <DataTable
                              columns={[
                                  {
                                      name: "Question",
                                      selector: (row) => row.label,
                                  },
                                  {
                                      name: "Category",
                                      selector: (row) => row.category,
                                  },
                                  {
                                      name: "Grade",
                                      selector: (row) => row.questions,
                                  },
                                  {
                                      name: "Duration",
                                      selector: (row) => row.duration,
                                  },
                                  {
                                      name: "Obligatory",
                                      cell: () => (
                                          <div className="flex-shrink-0  text-end icon-state switch-outline">
                                              <CommonSwitchSpan
                                                  name={`obligatory.${item.value}`}
                                                  color="primary"
                                                  checked={
                                                      values.obligatory[
                                                          item.value
                                                      ] || false
                                                  }
                                                  onChange={() =>
                                                      setFieldValue(
                                                          `obligatory.${item.value}`,
                                                          !values.obligatory[
                                                              item.value
                                                          ]
                                                      )
                                                  }
                                              />
                                          </div>
                                      ),
                                  },
                              ]}
                              data={[item]}
                              pagination
                              striped
                              selectableRows
                          />
                          <Button
                              color="danger"
                              onClick={() =>
                                  handleRemoveTable(
                                      item.value,
                                      "existing",
                                      setFieldValue
                                  )
                              }
                          >
                              Remove Table
                          </Button>
                      </div>
                  ))}

                  {/* Knowledge Bank Selection */}
                  <Row className="mt-4 align-items-end">
                      <Col sm="4" className="mb-3">
                          <label>
                              KNOWLEDGE BANK, public questionnaires from other
                              users
                          </label>
                          <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={knowledgeBankOptions}
                              placeholder="Select from knowledge bank"
                              getOptionLabel={(option) =>
                                  `${option.label} - ${option.category} - ${option.questions} Questions - ${option.username}`
                              }
                              value={selectedKnowledgeQuestion}
                              onChange={(selectedOption) =>
                                  setSelectedKnowledgeQuestion(selectedOption)
                              }
                          />
                      </Col>
                      <Col sm="4" className="d-flex align-items-end mb-3">
                          <Button
                              color="primary"
                              onClick={() =>
                                  handleAddKnowledgeQuestion(
                                      values,
                                      setFieldValue
                                  )
                              }
                          >
                              Confirm
                          </Button>
                      </Col>
                  </Row>

                  {/* Generate a new table for each selected knowledge bank question */}
                  {knowledgeTables.map((item) => (
                      <div key={item.value} className="mb-4">
                          <div className="d-flex gap-2 align-items-center">
                              <h5>{item.label}</h5>
                              <Button color="primary">Import Selected</Button>
                          </div>
                          <DataTable
                              columns={[
                                  {
                                      name: "Question",
                                      selector: (row) => row.label,
                                  },
                                  {
                                      name: "Category",
                                      selector: (row) => row.category,
                                  },
                                  {
                                      name: "Questions",
                                      selector: (row) => row.questions,
                                  },
                                  {
                                      name: "Username",
                                      selector: (row) => row.username,
                                  },
                                  {
                                      name: "Obligatory",
                                      cell: () => (
                                          <div className="flex-shrink-0  text-end icon-state switch-outline">
                                              <CommonSwitchSpan
                                                  name={`obligatory.${item.value}`}
                                                  color="primary"
                                                  checked={
                                                      values.obligatory[
                                                          item.value
                                                      ] || false
                                                  }
                                                  onChange={() =>
                                                      setFieldValue(
                                                          `obligatory.${item.value}`,
                                                          !values.obligatory[
                                                              item.value
                                                          ]
                                                      )
                                                  }
                                              />
                                          </div>
                                      ),
                                  },
                              ]}
                              data={[item]}
                              pagination
                              striped
                              selectableRows
                          />
                          <Button
                              color="danger"
                              onClick={() =>
                                  handleRemoveTable(
                                      item.value,
                                      "knowledge",
                                      setFieldValue
                                  )
                              }
                          >
                              Remove Table
                          </Button>
                      </div>
                  ))}
              </Form>
          )}
      </Formik>
  );
};

export default QuestionSelectForm;
