import { ErrorMessage, Field } from 'formik'
import React from 'react'
import { Label } from 'reactstrap'
import TextCounter from './TextCounter'
import { useAppSelector } from '../../ReduxToolkit/Hooks'

function DescriptionInput(props:any) {
    const {submitErrors,errors,values,lable,name,placeholder,optional, className} =props
    const { websiteSettingsData }: any = useAppSelector((state) => state.common); 
    const maxTitleLength = websiteSettingsData?.desc_length
    
  return (
    <>
    
    <Label check>{lable} {optional ? '' : <span className="txt-danger">*</span>}</Label>
          <Field
            as="textarea"
            name={name}
            className= {`form-control ${className || ""}`}
            maxLength={maxTitleLength}
            placeholder={placeholder}
          />
          <TextCounter length={values?.length} totalLength={maxTitleLength} />
          <ErrorMessage name={name} component="span" className="invalid-feedback" />
  </>
  )
}

export default DescriptionInput