import {useState} from 'react'
import { BrandTools, ManageExternalTitle, QuizMaterial } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { ActionButtons, QuizMaterialTableAction, StudyMaterialAction, links, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import Popup from '../../../../Component/MasterPopup/Popup'
import QuizMaterialEdit from '../../../../Component/Tools/BrandSupportTools/QuizMaterial/QuizMaterialEdit'
import StudyMaterialSubMenu from '../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import { SupportDataType } from '../../../../Pages/App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../../Pages/App/AccountSettings/MyRegistrationPlan'
import QuizMaterialView from '../../../../Pages/Tools/BrandSupportTools/QuizMaterial/QuizMaterialView'
import AddQuestionGroupModal from '../Questions/AddQuestionGroupModal'
import { Btn } from '../../../../AbstractElements'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'

const QuestionGroup = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const [isEditOpen , setIsEditOpen] = useState(false);
    const [isOpen , setIsOpen] = useState(false); 
    const [isPopupOpen, setPopupOpen] = useState(false); // Renamed state for modal visibility
  const [selectedQuiz, setSelectedQuiz] = useState<string | null>(null); // Renamed for clarity
  const [openQestionModal, setOpenQestionModal] = useState(false);
  const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
  const openQuizPopup = (quizName: string) => {
    setSelectedQuiz(quizName);
    setPopupOpen(!isPopupOpen);
  };

  const closeQuizPopup = () => {
    setPopupOpen(false);
    setSelectedQuiz(null);
  };
    const handleRowSelected =() =>{}

    const openPopup = (type:boolean) =>{
      setIsOpen(!isOpen);
      setIsEditOpen(type);
    }

    const openModalToggle = () =>{
      setIsOpen(!isOpen);
    }
    const openPopupToggle = () =>{
      setPopupOpen(!isPopupOpen);
    }

    const QuizMaterialColumnData: TableColumn<SupportDataType>[] = [
      {
        name: <TableHeadColumn title={`Id`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: false,
      },
      {
        name: <TableHeadColumn title={`By`}/>,
        selector: (row) => row["by"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Quiz Material Name `}/>,
        
        selector: (row: any) => (
          <span
            onClick={() => openQuizPopup(row.name)}
            style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
          >
            {row.name}
          </span>
        ),
      },
      {
        name: <TableHeadColumn title={`Unique Key`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Text`}/>,
        // selector: (row) => row["description"],
        selector: (row: any) => (
          <span
            onClick={() => openQuizPopup(row.description)}
            style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
          >
            {row.description}
          </span>
        ),
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Audio`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: true,
      },
      
      {
        name: <TableHeadColumn title={`Video URL`}/>,
        selector: (row: SupportDataType) => row["email"],
        sortable: true,
        center: true,
      },
      {
        name: <TableHeadColumn title={`Date/ Time`}/>,
        selector: (row: SupportDataType) => row["date"],
        sortable: true,
        center: true,
      },
      {
        name:<TableHeadColumn title={`Actions`}/>,
        cell: (row) => <ActionButtons openPopup={openPopup}/>,
        center: false,
        right:true,
      },
    ];
    
    return (
      <div className='page-body'>
         <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    
        <SubMenuPopup  currentPage="Question Group List "/>
                </div> 
        <Breadcrumbs mainParent="All Tools" mainTitle={`Question Groups (${BrandTools})`} parent={BrandTools} />
            </div>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card> 
              <div className='my-2 ms-1'>
                 
                    <StudyMaterialSubMenu  />
                    
                  </div>
                <CardBody className='p-2'>
                  <> 
                  <div className='border p-2'>
                  <div>
                  {QuizMaterialTableAction.map((action, index)=>{
                    
                    return(
                    <>
                      <Link to={`${process.env.PUBLIC_URL}${action.path}`}><span key={index} className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                    </>)
                  })}
                  </div> 
                    <div className="table-responsive pt-2">
                      <DataTable className='custom-scrollbar' columns={QuizMaterialColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                    </div>

                    <Popup title={`Quiz Material`} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`}>
                      {isEditOpen ? <QuizMaterialEdit/> : <QuizMaterialView /> }
                    </Popup>
                    <Popup title={`Quiz Material`} openModalToggle={openPopupToggle} isOpen={isPopupOpen} size={`xl`}>
                      {isPopupOpen  && <QuizMaterialEdit/> }
                    </Popup>
                    <Popup
                title='Add a Question Group'
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
               <AddQuestionGroupModal />
            </Popup>
                  </div>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default QuestionGroup