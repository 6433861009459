import { apiSlice } from "../../apiSlice";
import { ONLINE_ASSISTANT_DATA, ONLINE_ASSISTANT_SAVE } from "../../../Api";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const onlineAssistantApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOnlineAssistantData: builder.mutation<SuccessDataProps, void>({
      query: () => ({
        url: ONLINE_ASSISTANT_DATA,
        method: "GET",
      }),
    }),
    saveOnlineAssistantData: builder.mutation<SuccessDataProps, any>({
      query: (data) => ({
        url: ONLINE_ASSISTANT_SAVE,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetOnlineAssistantDataMutation,
  useSaveOnlineAssistantDataMutation,
} = onlineAssistantApiSlice;
