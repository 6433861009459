import Masonry from 'react-masonry-css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { dynamicImage } from '../../../../Service';
import { Link } from 'react-router-dom';
import { H4, Image, LI, P, UL } from '../../../../AbstractElements';
import SearchInput from '../../../Application/SearchResult/SearchInput/SearchInput';
import { items } from '../../CouponsData/CouponsData';
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';
import ContestModal from './ContestModal';
import GalleryFilter from '../Photos/GalleryFilter';

export const randomData = [
    {id:1,src: "masonry/1.jpg",},
    {id:2,src: "masonry/2.jpg"},
    {id:3,src: "masonry/3.jpg"},
    {id:4,src: "masonry/4.jpg"}];

const PhotoContestsData = () => {
    const breakpointColumnsObj = {
        default: 4,
        1199: 3,
        700: 2,
        500: 1,
      };

    const images_a = ["masonry/1.jpg","masonry/1.jpg","masonry/1.jpg","masonry/1.jpg","masonry/1.jpg"];
    const images_b = ["masonry/2.jpg","masonry/2.jpg","masonry/2.jpg","masonry/2.jpg","masonry/2.jpg"];

    const [dataId, setDataId] = useState<undefined | number>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const handlePopupClick = (id:number) =>{
        setDataId(id);
        setOpenModal(true);
    }
    const renderCarousel = (index: number) => (
        <Carousel
          className="owl-carousel owl-theme"
          showStatus={false}
          showIndicators={false}
          showArrows={true}
          swipeable={false}
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          stopOnHover={true}
          emulateTouch={false}
          onClickItem={() => handlePopupClick(index)}
        >
          {(index % 2 === 0 ? images_a : images_b).map((image, idx) => (
            <div className="item" key={idx}>
              <img src={dynamicImage(image)} alt="" />
              <div className="product-hover">
                <UL className="simple-list flex-row">
                  <LI>
                    <Link to={``}>
                      <i className="icon-eye"></i>
                    </Link>
                  </LI>
                </UL>
              </div>
            </div>
          ))}
        </Carousel>
      );
  return (
    <Card>
        <SearchInput />
        <GalleryFilter/>
        <CardBody className="photoswipe-pb-responsive me-3">
    {/* <GalleryFilter/> */}
        <div className={`product-wrapper-grid `}>
            <Row>
                {items.map((item, index) =>{
                return (
                    <div id="gridId" className={`col-xl-3 col-sm-6 `} key={index}>
                        <Card >
                        <div className="product-box border" >
                            <div className="product-img" >
                            {renderCarousel(index)}
                            </div>
                            <div className="product-details">
                            <div className='d-flex border-0 border-bottom mb-0' >
                                <Image className="img-50 rounded-circle m-r-15" src={dynamicImage(`user/user.png`)} alt="user" /> 
                                <div className={`social-status social-online`} />
                                <div className='flex-grow-1'>
                                <span className="d-block" >{`Bucky Barnes`}</span>
                                </div>
                            </div>
                            <div className="blog-box blog-grid text-center product-box border-0 border-bottom mb-1">
                                <div className="blog-details-main simple-list flex-row">
                                <UL className="blog-social justify-content-center flex-row mt-0 mb-0">
                                    <LI>9 April 2024</LI>
                                    <LI>by: Admin</LI>
                                    <LI>0 Hits</LI>
                                </UL>
                                </div>
                            </div>
                            <Link to={`${process.env.PUBLIC_URL}/ecommerce/productpage`}>
                                <H4>{`Name`}</H4>
                            </Link>
                            <P>{`Gray Denim Regular Men's Denim Jacket`}</P>
                            <div className="product-price">
                                {`$`}{`50`} <del>{`$`}{`45.00`}</del>
                            </div>
                            </div>
                        </div>
                        </Card>
                    </div>
                );
                })}
              
            {openModal && <ContestModal modalBodyClassName="modal-custom " value={openModal} setOpenModal={setOpenModal} dataId={dataId}  contestitems={items} />}
            </Row>
        </div>
    </CardBody>
    </Card>
  )
}

export default PhotoContestsData