import {useState} from 'react'
import { DeliveryWorker, ManageAlbums } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import {  DeliveryFeesColumnData,  supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import {  DeliveryFeeAction, DeliveryFeeTableAction, DeliveryWorkerTableAction, ManageOnlineStore } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import ManageOnlineSubMenu from '../ManageOnlineSubMenu/ManageOnlineSubMenu'
import DeliverySubMenu from '../DeliverySubMenu/DeliverySubMenu'
import { ErrorMessage, Formik } from 'formik'
import City from '../../../../Component/CommonInput/City'
import Branches from '../../../../Component/CommonInput/Branches'
import { H5 } from '../../../../AbstractElements'
import State from '../../../../Component/CommonInput/State'
import Country from '../../../../Component/CommonInput/Country'
import { branchPublicContactFormInitialValue, BranchPublicContactFormValidationProp, branchPublicContactFormValidationSchema } from '../../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import CommonSwitchSpan from '../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'


const DeliverySystem = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const [showModal, setShowModal] = useState(false);
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const handleSubmit = (values: BranchPublicContactFormValidationProp) => {
      setSubmitError(false);
  };
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
    const handleRowSelected =() =>{}
    return (
    <div className='page-body'>
        <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    <SubMenuPopup currentPage="Delivery Worker List" />
                </div>
                 
      <Breadcrumbs mainParent="All Tools" mainTitle={`${DeliveryWorker} (Manage Store)`} parent={`Manage Store`} />
            </div>
      <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card> 
            <div>
                <DeliverySubMenu/>
                </div>
                <div className=''>
                    {DeliveryWorkerTableAction.map((action, index)=>{
                    return(
                    <>
                    {action.actionName == "1Webbb Delivery" ? <button
                            className="border rounded p-2 ps-2 pe-2 bg-dark text-light text-white"
                            onClick={() => handleModalOpen()}
                            key={index} // Ensure each element has a unique key
                        >
                            <span
                                style={{
                                    lineHeight: "21px",
                                    fontFamily: "Lexend",
                                }}>
                               {action.actionName}
                            </span>
                        </button>:<Link to={`${process.env.PUBLIC_URL}/${action.path}`}><span key={index} className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                    }
                    </>)
                    })}
                    </div>
                
                   
              <CardBody className='p-2'>
                <> 
                <div className=''>
                    <div className="table-responsive">
                    <DataTable className='custom-scrollbar' columns={DeliveryFeesColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                    </div>
                </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={showModal} toggle={handleModalClose} size="xl">
                <ModalHeader toggle={handleModalClose}>
                1Webbb Deleviery
                </ModalHeader>
                <ModalBody>
                    {/* You can display additional data here */}
                    <Formik
                        initialValues={branchPublicContactFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={
                            branchPublicContactFormValidationSchema
                        }>
                        {({ errors, values, setFieldValue }) => {
                            return (
                                <Form className="form-wizard">
                                    <Row>
                                        <Col sm="4" className="mb-3">
                                            <Label check>
                                                Country{" "}
                                                <span className="txt-danger">
                                                    *
                                                </span>
                                            </Label>
                                            <Country
                                                name="country"
                                                isMulti={false}
                                                placeholder="Select"
                                                className={`${
                                                    submitErrors &&
                                                    `${
                                                        errors.country
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                    }`
                                                }`}
                                            />
                                            <ErrorMessage
                                                name="country"
                                                component="span"
                                                className="invalid-feedback"
                                            />
                                        </Col>
                                        <Col sm="4" className="mb-3">
                                            <Label check>State</Label>
                                            <State
                                                name="state"
                                                countryId={values.country} // Pass the selected country ID
                                                isMulti={true}
                                                setFieldValue={setFieldValue}
                                                placeholder="Select"
                                            />
                                        </Col>
                                        <Col sm="4" className="mb-3">
                                            <Label check>
                                                City{" "}
                                                <span className="txt-danger">
                                                    *
                                                </span>
                                            </Label>
                                            <City
                                                name="city"
                                                countryId={values.country}
                                                stateId={values.state} // Pass the selected state ID
                                                isMulti={true}
                                                placeholder="Select"
                                                className={`${
                                                    submitErrors &&
                                                    `${
                                                        errors.city
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                    }`
                                                }`}
                                            />
                                            <ErrorMessage
                                                name="city"
                                                component="span"
                                                className="invalid-feedback"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col sm="4" className="mb-3">
                                            <Label check>
                                                Branches{" "}
                                                <span className="txt-danger">
                                                    *
                                                </span>
                                            </Label>
                                            <Branches
                                                name="branches"
                                                placeholder="Select"
                                                isMulti={true}
                                                className={`${
                                                    submitErrors &&
                                                    `${
                                                        errors.branches
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                    }`
                                                }`}
                                            />
                                            <ErrorMessage
                                                name="branches"
                                                component="span"
                                                className="invalid-feedback"
                                            />
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <H5>
                                            1Webbb Deleviery{" "}
                                            <span className="txt-danger">
                                                *
                                            </span>
                                        </H5>

                                        <span>
                                            Use 1Webbb Delivery Team to serve
                                            your business. You pay nothing for
                                            us. Our fees will be added to the
                                            bill as a separate fee.{" "}
                                        </span>

                                        <Col sm="4" className="mt-3">
                                            <div className="flex-shrink-0 icon-state switch-outline">
                                                <CommonSwitchSpan
                                                    color={"primary"}
                                                    name="showCalculator"
                                                    defaultChecked
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
      </Container>
    </div>
  )
}

export default DeliverySystem