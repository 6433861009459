import { CardHeader, Col, Container, Row } from "reactstrap";
import NewOrders from "./NewOrders";
import ShippedOrders from "./ShippedOrders";
import CancelledOrders from "./CancelledOrders";
import DataTableOrderHistory from "./DataTableOrderHistory";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Ecommerce, OrderHistory } from "../../../../utils/Constant";
import { referralactions } from "../../Users/MyReferralsContainer/MyReferralsContainer";
import { Link } from "react-router-dom";
import PageHeader from "../../../Dashboard/Default/PageHeader/PageHeader";
import ManageOnlineSubMenu from "../../../../Pages/Tools/ManageOnlineStore/ManageOnlineSubMenu/ManageOnlineSubMenu";
import BranchSubMenu from "../../../../Pages/Tools/ManageOnlineStore/ManageBranches/BranchSubMenu/BranchSubMenu";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const OrderHistoryContainer = () => {
    return (
        <>
            <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    <SubMenuPopup currentPage="Sales" />
                </div>
                <Breadcrumbs
                    mainTitle={OrderHistory}
                    parent={`Manage Store `}
                    mainParent={`All Tools`}
                />
            </div>
            <Container fluid>
                {/* <CardHeader> */}
                <CardHeader className="pb-0 pt-0 px-0">
                    {/* </CardHeader> */}
                    <div className="mt-2 px-2">
                        <BranchSubMenu />
                    </div>
                </CardHeader>
                <Row>
                    <Col sm="12">
                        <NewOrders />
                        <ShippedOrders />
                        <CancelledOrders />
                        <DataTableOrderHistory />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default OrderHistoryContainer;
