import { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { H2 } from "../../AbstractElements";
import { SelectAction } from "../../utils/Constant";
import { Formik } from "formik";
import {
  RenewSignupValidationProp,
  renewSignupFormSchema,
  signupFormInitialValue,
} from "../../Data/RenewSignup/RenewSignupFormValidation";
import { useAppSelector, useAppDispatch } from "../../ReduxToolkit/Hooks";
import { useGetCountryListMutation } from "../../ReduxToolkit/Reducers/Country/countryApiSlice";
import { useGetRegistrationPlanListQuery } from "../../ReduxToolkit/Reducers/Registration/Plan/planApliSlice";
import { useGetCurrentRegistrationPlanMutation } from "../../ReduxToolkit/Reducers/RegistrationPlan/registrationPlanApiSlice";
import CurrentPlanSummary from "../../Pages/App/AccountSettings/RegistrationPlan/CurrentPlanSummary";
import { toast } from "react-toastify";
import { setCounteries } from "../../ReduxToolkit/Reducers/commonInputSlice";
import DynamicPlanData from "../Pricing/DynamicPlanData";
import StaticPlanData from "../Pricing/StaticPlanData";
import Loading from "../Loading/Loading";
import { useSetActiveRegistrationPlanMutation } from "../../ReduxToolkit/Reducers/RegistrationPlan/registrationPlanApiSlice";
import RenewFormContainer from "./RenewFormContainer";

const RenewSignupContainer = (props: any) => {
  const [toggleDelete, setToggleDelete] = useState(false);
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [currentRegistraionPlan, setCurrentRegistrationPlan] = useState<any>(
    []
  );
  const [countryId, setCountryId] = useState<any>("");
  const [plans, setPlans] = useState<any>();
  const [planId, setPlanId] = useState<any>();
  const [selectedAction, setSelectedAction] = useState(1);

  const dispatch = useAppDispatch();

  // get country list
  const { countries } = useAppSelector((state) => state.common);

  const [getCurrentRegistrationPlanApi, { isLoading }] =
    useGetCurrentRegistrationPlanMutation();

  //Get CountryListDropdown
  const [getCountryListApi, { isLoading: isCountryLoading }] =
    useGetCountryListMutation();

  //Update current registration plant to selected registration plan.
  const [setActiveRegistrationPlanApi, { isLoading: isSetActiveLoading }] =
    useSetActiveRegistrationPlanMutation();

  // Get Current Registration Plan Funcation.
  const getCurrentRegistrationPlan = async () => {
    try {
      const response = await getCurrentRegistrationPlanApi().unwrap();
      const { data, success, message } = response;
      if (success === true) {
        setCurrentRegistrationPlan(data);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error.data.message || "Something went weong.");
    }
  };

  useEffect(() => {
    getCurrentRegistrationPlan();
  }, []);

  //Change the currentplan blank when tab is changed
  useEffect(() => {
    if (selectedAction === 1) {
      if (countries.length === 0) {
        getCountryList();
      }
      getCurrentRegistrationPlan();
    } else if (selectedAction === 2) {
      setPlans(undefined);
      setCountryId("");
      setPlanId("");
    }
  }, [selectedAction]);

  // Get Registration Plan List
  const { data: plansList, isLoading: isLoadingPlan } =
    useGetRegistrationPlanListQuery(
      {
        country: countryId,
        registrationType: 0,
      },
      {
        skip: countryId !== 0 && countryId !== undefined ? false : true,
      }
    );

  // Current Registration Plan Country Id set.
  useEffect(() => {
    if (currentRegistraionPlan?.plan_summary?.account_country !== undefined) {
      const selectedCountry: any = countries.filter((item: any) => {
        return (
          item.label == currentRegistraionPlan.plan_summary.account_country
        );
      });
      setCountryId(selectedCountry[0]?.value);
      setPlanId(currentRegistraionPlan?.plan_summary?.plan_id);
    }
  }, [currentRegistraionPlan]);

  // If Country List is not find then load tha country data.
  const getCountryList = async () => {
    try {
      const response = await getCountryListApi().unwrap();
      const { data, success, message } = response;
      if (success === true) {
        // Map the fetched countries to your desired structure
        let filter_country = data.map((item: any) => {
          return { label: item.country_name, value: item.id };
        });

        // Add the clearable option
        filter_country = [...filter_country];

        // Dispatch the countries to the Redux store
        dispatch(setCounteries(filter_country));
      }
    } catch (error: any) {
      console.log(error?.data?.message || "Something went wrong.");
    }
  };

  // Filter Dyanmic Data for current Plan Id.
  let filterDynamicPlan =
    plans != undefined &&
    plans?.dynamic.filter((item: any) => {
      return item.plan_id === planId;
    });

  // Set Current Plan Data base on countryId.
  useEffect(() => {
    if (countryId !== 0 && countryId !== undefined) {
      setPlans(plansList?.data);
    }
  }, [plansList, countryId]);

  //Load country data.
  useEffect(() => {
    if (countries.length === 0) {
      getCountryList();
    }
  }, [countries]);

  // Showing My Current Plan Details using common component
  const showingPriceList =
    filterDynamicPlan &&
    filterDynamicPlan?.map((item: any, index: number) => {
      return (
        <div className="d-flex overflow-auto" key={index}>
          <Col className="ps-0">
            <StaticPlanData staticData={{ data: plans }} />
          </Col>
          <Col>
            <DynamicPlanData
              dData={{ data: { dynamic: filterDynamicPlan } }}
              isButton={false}
              isModifyButton={false}
              planPayPeriod={
                currentRegistraionPlan?.plan_summary?.plan_pay_period
              }
              countryId={countryId}
            />
          </Col>
        </div>
      );
    });

  const handleSignup = async (values: RenewSignupValidationProp) => {
    try {
      const response = await setActiveRegistrationPlanApi(values).unwrap();
      const { data, success, message } = response;
      if (success === true) {
        toast.success(message);
        if (selectedAction === 2) {
          setSelectedAction(1);
        }
        getCurrentRegistrationPlan();
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message);
    }
    setSubmitError(false);
  };

  const handleRowSelected = () => {
    setToggleDelete(false);
  };

  const registrationPlanOptions =
    plans != undefined &&
    plans?.dynamic.map((d_item: any, index: any) => (
      <option key={index} value={d_item.plan_id}>
        {d_item.plan_name}
      </option>
    ));

  if (isLoading || isLoadingPlan || isCountryLoading || isSetActiveLoading)
    return <Loading />;

  return (
    <Row className="d-flex justify-content-center px-3">
      <Col md="12" lg="12" sm="12">
        <CurrentPlanSummary
          planSummary={currentRegistraionPlan?.plan_summary}
        />
      </Col>
      <Col md="12" lg="12" sm="12">
        <div className="theme-form px-3">
          <H2 className="pb-4">{SelectAction}</H2>
          <Formik
            initialValues={signupFormInitialValue}
            onSubmit={handleSignup}
            validationSchema={renewSignupFormSchema}
          >
            {({ errors, values, setFieldValue }) => (
              <RenewFormContainer
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                toggleDelete={toggleDelete}
                handleRowSelected={handleRowSelected}
                submitErrors={submitErrors}
                setSubmitError={setSubmitError}
                currentRegistraionPlan={currentRegistraionPlan}
                filterDynamicPlan={filterDynamicPlan}
                countryId={countryId}
                showingPriceList={showingPriceList}
                registrationPlanOptions={registrationPlanOptions}
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                setCountryId={setCountryId}
                setPlanId={setPlanId}
                setPlans={setPlans}
              />
            )}
          </Formik>
        </div>
      </Col>
    </Row>
  );
};

export default RenewSignupContainer;
