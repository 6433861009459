import { Col, Row, TabContent, TabPane } from 'reactstrap' 
import ProductTwo from './ProductTwo'
import ProductThree from './ProductThree'
import ProductFour from './ProductFour'
import ProductFive from './ProductFive'
import { useAppSelector } from '../../../../../../ReduxToolkit/Hooks' 
import ProductCommonbtn from '../CommonButton/ProductCommonbtn'
import { Btn } from '../../../../../../AbstractElements'
import { Formik } from 'formik'
import { FequentlyinitialValues, FequentlyValidationSchema } from '../../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import FequentlyModalForm from '../../../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyModalForm'
import Popup from '../../../../../MasterPopup/Popup'
import { useState } from 'react'

const ProductTabContent = () => {
  const {navId} = useAppSelector((state) => state.addProductService)
  console.log(navId);
  const [openPublishModal, setOpenPublishModal] = useState(false);
      const openPublishModalToggle = () => setOpenPublishModal(!openPublishModal);
  return (
    <Col xxl="9" xl="8" className="box-col-8 mt-1 position-relative">
      
      <TabContent activeTab={navId}>
         
        <TabPane tabId={1} >
          <ProductTwo />
        </TabPane>
        <TabPane tabId={2} >
          <ProductThree />
        </TabPane>
        <TabPane tabId={3} >
          <ProductFive />
        </TabPane>
        <TabPane tabId={4} >
          {/* <ProductFour /> */}
        </TabPane>
      </TabContent>
      <Popup
                title='Publish Online'
                openModalToggle={openPublishModalToggle}
                isOpen={openPublishModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                    BodyClassName="pt-1"
                largesize="modal-custom"
                >
                  <Formik
                        initialValues={FequentlyinitialValues}
                        validationSchema={FequentlyValidationSchema}
                        onSubmit={(values) => {
                          console.log("Selected values:", values);
                        }}
                      >
                        {({ handleChange, values,errors,setFieldValue }) => ( 
               <FequentlyModalForm selectField='product' setFieldValue={setFieldValue} handleChange={handleChange}  values={values} submitErrors={submitErrors} errors={errors}/>
              )}
    </Formik>
 
            </Popup>
      <Row className="mt-2   bottom-0 w-100">
       <Col sm='6'>
       {navId === 3 ? <Row>
        <Col sm="auto" className="mb-3 ">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
          >
            Save as a Draft
          </Btn>
        </Col>
        <Col sm="auto" className="mb-3 ">
          <Btn
            color="primary"
            type="submit"
            onClick={openPublishModalToggle}
          >
            Save and Publish Online 
          </Btn>
          {/* <Btn className='p-0' onClick={openQestionModalToggle}><span className={`border rounded p-2 m-1 bg-dark text-white  ps-2 pe-2  `}>Publish Online</span></Btn> */}

        </Col>
        </Row>: ''}
        </Col> 
        <Col sm="6" className="mb-3 ">
      <ProductCommonbtn step={true} bottombutton={true}/>
        </Col>
      </Row>
    </Col>
  )
}

export default ProductTabContent