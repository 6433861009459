import React, { useState, useRef, useMemo } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { NoDataAvailable } from "../../utils/Constant";
import {
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";

interface CustomGridProps {
  id: string;
  column: any;
  data: any;
  title: any;
  isDeleteButton: boolean;
  deleteHandler?: any;
  handleRowSelected?: any;
  toggleDelete?: any;
  selectedData?: any;
}

function CustomGrid(props: CustomGridProps) {
  const {
    id,
    column,
    data,
    title,
    isDeleteButton,
    deleteHandler,
    handleRowSelected,
    toggleDelete,
    selectedData,
  } = props;

  const [visibleColumns, setVisibleColumns] = useState(
    column.map((col: any) => col.id)
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterText, setFilterText] = useState("");

  // Create a reference for the table
  const tableRef: any = useRef();

  const fileName = id !== undefined ? id : "datatable";

  // Export to Excel function
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data || []); // Convert JSON data to Excel sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  // Handle column visibility toggle
  const toggleColumn = (id: any) => {
    setVisibleColumns((prev: any) =>
      prev.includes(id) ? prev.filter((col: any) => col !== id) : [...prev, id]
    );
  };

  const filteredColumns = column.filter((col: any) =>
    visibleColumns.includes(col.id)
  );

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div
        id="basic-12_filter"
        className="dataTables_filter d-flex align-items-center"
      >
        <Label className="me-2">{"Search"}:</Label>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilterText(e.target.value)
          }
          type="search"
          value={filterText}
        />
      </div>
    );
  }, [filterText]);

  // Handles global search across all columns
  const filteredItems =
    data &&
    data.filter((row: any) =>
      column.some((col: any) =>
        row[col.id]?.toString().toLowerCase().includes(filterText.toLowerCase())
      )
    );

  return (
    <>
      <div className="row">
        {isDeleteButton === true && (
          <div className="col-12 col-md-1 d-grid mb-2 mb-md-0">
            <Link
              className={selectedData.length === 0 ? "disabled" : ""}
              to={`#`}
              onClick={deleteHandler}
            >
              <span className="btn btn-primary ">Delete</span>
            </Link>
          </div>
        )}
        <div className="col-12 col-md-1 d-grid mb-2 mb-md-0">
          <CSVLink
            data={data || []}
            filename={`${fileName}.csv`}
            className="btn btn-primary"
          >
            CSV
          </CSVLink>
        </div>
        <div className="col-12 col-md-1 d-grid mb-2 mb-md-0">
          <button className="btn btn-primary" onClick={exportToExcel}>
            Excel
          </button>
        </div>
        <div className="col-12 col-md-2 d-grid mb-2 mb-md-0">
          <div className="">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen(!dropdownOpen)}
            >
              <DropdownToggle
                caret
                color="btn btn-primary"
                className="pl-2 pr-2 me-2"
                style={{ width: "100%" }}
              >
                Column Visibility
              </DropdownToggle>
              <DropdownMenu>
                {column.map((col: any) => (
                  <DropdownItem
                    className="d-flex"
                    key={col.id}
                    toggle={false}
                    style={{ opacity: "1" }}
                    onClick={() => toggleColumn(col.id)}
                  >
                    <Input
                      type="checkbox"
                      checked={visibleColumns.includes(col.id)}
                    />
                    <p style={{ marginLeft: "5px" }}>{col.name}</p>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="col-12 col-md-3 d-grid">{subHeaderComponentMemo}</div>
      </div>
      <div className="table-responsive pt-2" ref={tableRef} id={id}>
        <DataTable
          className="custom-scrollbar"
          columns={filteredColumns}
          data={filteredItems || []}
          striped={true}
          noDataComponent={NoDataAvailable}
          pagination
          selectableRows
          persistTableHead
          paginationPerPage={20}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleDelete}
        />
      </div>
    </>
  );
}

export default CustomGrid;
