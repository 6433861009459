import { useEffect, useState, useMemo } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  AccountSettings,
  AccountHistoryTitle,
  NoDataAvailable,
} from "../../../utils/Constant";
import { CardBody, Card, Col, Container, Row, Input, Label  } from "reactstrap";
import { accountHistoryData } from "../../../Data/Tools/BrandSupportTools/ButtonActions";
import DataTable, { TableColumn } from "react-data-table-component";
import { TableHeadColumn } from "./MyRegistrationPlan";
import UserBanner from "../../../Component/App/User/UserBanner";
import {
  useGetAccountHistoryDataMutation,
  useDeleteAccountHistoryRowsMutation,
} from "../../../ReduxToolkit/Reducers/AccountHistory/accountHistoryApliSlice";
import { toast } from "react-toastify";
import Loading from "../../../Component/Loading/Loading";
import { ManageAccountHistoryTableAction } from "../../../Data/Tools/BrandSupportTools/ButtonActions";
import { Link } from "react-router-dom";
import Popup from "../../../Component/MasterPopup/Popup";
import AccountHistoryView from "./AccountHistoryView";
import CustomGrid from "../../../Component/CommonDataTable/CustomGrid";

function AccountHistory() {
  const [isOpen, setIsOpen] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [historyNo, setHistoryNo] = useState(); 

  const [getAccountSettingDataApi, { isLoading }] =
    useGetAccountHistoryDataMutation();
  const [deleteAccountHistoryRowsApi, { isLoading: isDeleteLoading }] =
    useDeleteAccountHistoryRowsMutation();

  const handleRowSelected = ({ selectedRows }: any) => {
    const historyNos =
      selectedRows && selectedRows.map((item: any) => item.historyNo);
    setSelectedData(historyNos);
  };

  const deleteHandler = async () => {
    if (selectedData.length > 0) { 
      try {
        const response = await deleteAccountHistoryRowsApi({
          ids: selectedData,
        }).unwrap();
        const { success, message } = response;
        if (success === true) {
          toast.success(message);
          getAccountSettingData();
        } else {
          toast.error(message);
        }
      } catch (error: any) {
        toast.error(error.data.message || "Something went wrong.");
      }
      setSelectedData([]);
    }
  };

  const openPopup = (history_number: any) => {
    setIsOpen(!isOpen);
    setHistoryNo(history_number);
  };

  const openModalToggle = () => {
    setIsOpen(!isOpen);
  };

  const externalColumns: TableColumn<any>[] = [
    { 
      name: <TableHeadColumn title={`ID`} />,
      selector: (row) => (
        <a className={`primary`} onClick={() => openPopup(row["historyNo"])}>
          {row["historyNo"]}
        </a>
      ), 
      sortable: true,
      center: false,
      id: "historyNo",
    },
    {
      name: <TableHeadColumn title={`Username`} />,
      selector: (row) => row.userName,
      sortable: true,
      center: false,
      id: "userName",
    },
    {
      name: <TableHeadColumn title={`Action`} />,
      selector: (row) => row.action,
      sortable: true,
      center: false,
      id: "action",
    },
    {
      name: <TableHeadColumn title={`Module Name`} />,
      selector: (row) => row.moduleName,
      sortable: true,
      center: false,
      id: "moduleName",
    },
    {
      name: <TableHeadColumn title={`Datetime`} />,
      selector: (row) => row.created_at,
      sortable: true,
      center: false,
      id: "created_at",
    },
  ];

  const getAccountSettingData = async () => {
    try {
      const response = await getAccountSettingDataApi().unwrap();
      const { data } = response;
      data && setHistoryData(data);
    } catch (error: any) {
      toast.error(error.data.message);
    }
  };

  useEffect(() => {
    getAccountSettingData();
  }, []); 


  if (isDeleteLoading) return <Loading />;

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={AccountHistoryTitle} parent={AccountSettings} />
      <UserBanner />
      {isLoading ? (
        <Loading />
      ) : (
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card> 
                <CardBody className="p-2">
                  <> 
                    <CustomGrid 
                      id="acccount-history"
                      column={externalColumns}
                      data={historyData}
                      title={"Account History"}
                      isDeleteButton={true}
                      deleteHandler={deleteHandler}
                      handleRowSelected={handleRowSelected}
                      toggleDelete={toggleDelete}
                      selectedData={selectedData}
                    />
                    <Popup
                      title={`Account History`}
                      openModalToggle={openModalToggle}
                      isOpen={isOpen}
                      size={`xl`}
                    >
                      <AccountHistoryView historyNo={historyNo} />
                    </Popup>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default AccountHistory;
