import {useState} from 'react'
import { BrandTools, ManageAlbums, ManageUploadedContents } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { ActionButtons, MyAbumsColumnData, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { ManageContents, MyAlbumTableAction } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import Popup from '../../../../Component/MasterPopup/Popup'
import ManageMyAlbumsEdit from '../../../../Component/Tools/ManageContents/ManageMyAlbums/ManageMyAlbumsEdit'
import ManageMyAlbumsView from '../../../../Component/Tools/ManageContents/ManageMyAlbums/ManageMyAlbumsView'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import ManageSubMenu from '../ManageSubMenu/ManageSubMenu'
import AddAlbumModal from '../../../../Component/App/UploadData/AddAlbumModal'
import { Btn } from '../../../../AbstractElements'
import ManageStorePopup from '../../../../Layout/Sidebar/ManageStorePopup'
import SubMenuPopup from '../../../../Layout/Sidebar/ManageStorePopup'

const ManageMyAlbums = () => {
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState(supportTicketData);    

  const [isEditOpen , setIsEditOpen] = useState(false);
  const [isOpen , setIsOpen] = useState(false); 
  const [openalbumModal, setOpenalbumModal] = useState(false);

  const openalbumModalToggle = () => {
    setOpenalbumModal(!openalbumModal); // Toggle the modal open/close state
  };
  const handleRowSelected =() =>{}
  const openPopup = (type:boolean) =>{
    setIsOpen(!isOpen);
    setIsEditOpen(type);
  }

  const openModalToggle = () =>{
      setIsOpen(!isOpen);
  }
  const MyAbumsColumnData: TableColumn<SupportDataType>[] = [    
    {
      name: <TableHeadColumn title={`ID`}/>,
      selector: (row) => row["id"],
      sortable: true,
      center: true,
    },
    {
      name: <TableHeadColumn title={`By`}/>,
      selector: (row) => row["by"],
      sortable: true,
      center: true,
    },
    {
      name:<TableHeadColumn title={`Album Name`}/> ,
      selector: (row) => row["position"],
      sortable: true,
      center: true,
    },
    
    {
      name:<TableHeadColumn title={`Album Description`}/> ,
      selector: (row) => row["salary"],
      sortable: true,
      center: true,
    },
    {
      name: <TableHeadColumn title={`Album Keywords`}/>,
      selector: (row) => row["office"],
      sortable: true,
      center: true,
    },
    {
      name:<TableHeadColumn title={`Photos`}/> ,
      selector: (row) => row["office"],
      sortable: true,
      center: true,
    },
    {
      name: <TableHeadColumn title={`Date`}/>,
      selector: (row: SupportDataType) => row["email"],
      sortable: true,
      center: true,
    },
    
    {
      name:<TableHeadColumn title={`Actions`}/>,
      cell: (row) => <ActionButtons openPopup={openPopup}/>,
      center: false,
      right:true,
    },
  ]; 

  return (
    <div className='page-body'>
       <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    <SubMenuPopup currentPage="Album List" />
                </div>
                 
      <Breadcrumbs mainParent="All Tools" mainTitle={`${ManageAlbums} (Manage Contents)`} parent={`Manage Content`} />
            </div>
     
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>  
            <div className='mt-2'> 
                {MyAlbumTableAction.map((action, index)=>{ 
                    return (
                      <Btn
                        key={index}
                        className='border rounded   p-2 m-1 text-white bg-dark ps-2 pe-2' 
                      >
                        {action.actionName}
                      </Btn>
                    ); 
                })}
                </div>
              <CardBody className='p-2'>
                <>   
                <div className="table-responsive pt-2">
                  <DataTable className='custom-scrollbar' columns={MyAbumsColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                </div>
                
                <Popup title={ManageAlbums} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`}>
                  {isEditOpen ? <ManageMyAlbumsEdit/> : <ManageMyAlbumsView /> }
                </Popup>
                <AddAlbumModal
        isOpen={openalbumModal}
        title="Add an Album"
        openModalToggle={openalbumModalToggle}
      />
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ManageMyAlbums