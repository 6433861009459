 

// import { Card, Row } from "reactstrap";
// import { dynamicImage } from "../../../Service";
// import { Link } from "react-router-dom";
// import { H4, LI, P, UL } from "../../../AbstractElements";
// import { useState, useCallback, useEffect } from "react";
// import { Carousel } from "react-responsive-carousel";
// import MediaModal from "./MediaModal";
// import AdSense from "../../WebsiteSettings/AdSense/Index";
// import { useAppSelector } from "../../../ReduxToolkit/Hooks";

// const images_a = [
//   "masonry/1.jpg",
//   "masonry/1.jpg",
//   "masonry/1.jpg",
//   "masonry/1.jpg",
//   "masonry/1.jpg",
// ];
// const images_b = [
//   "masonry/2.jpg",
//   "masonry/2.jpg",
//   "masonry/2.jpg",
//   "masonry/2.jpg",
//   "masonry/2.jpg",
// ];

// export const items = [
//   { id: 1, title: "Media 1" },
//   { id: 2, title: "Media 2" },
//   { id: 3, title: "Media 3" },
//   { id: 4, title: "Media 4" },
//   { id: 1, title: "Media 5" },
//   { id: 2, title: "Media 6" },
//   { id: 3, title: "Media 7" },
//   { id: 4, title: "Media 8" },
//   { id: 1, title: "Media 9" },
//   { id: 2, title: "Media 10" },
//   { id: 3, title: "Media 11" },
//   { id: 4, title: "Media 12" },
//   { id: 1, title: "Media 13" },
//   { id: 2, title: "Media 14" },
//   { id: 3, title: "Media 15" },
//   { id: 4, title: "Media 16" },
//   { id: 1, title: "Media 17" },
//   { id: 2, title: "Media 18" },
//   { id: 3, title: "Media 19" },
//   { id: 4, title: "Media 20" },
//   { id: 1, title: "Media 21" },
//   { id: 2, title: "Media 22" },
//   { id: 3, title: "Media 23" },
//   { id: 4, title: "Media 24" },
//   { id: 1, title: "Media 25" },
//   { id: 2, title: "Media 26" },
//   { id: 3, title: "Media 27" },
//   { id: 3, title: "Media 28" },
//   { id: 4, title: "Media 29" },
//   { id: 1, title: "Media 30" },
//   { id: 2, title: "Media 31" },
//   { id: 3, title: "Media 32" },
//   { id: 4, title: "Media 33" },
//   { id: 1, title: "Media 34" },
//   { id: 2, title: "Media 35" },
//   { id: 3, title: "Media 36" },
// ];
 
// const MediaData = (props: any) => {
//   const [dataId, setDataId] = useState<number>(0);
//   const [openModal, setOpenModal] = useState<boolean>(false);
//   const { mobileView } = useAppSelector((state) => state.layout); 
//   const [layout,setLayout]= useState()
//   const handlePopupClick = useCallback((id: number) => {
//     setDataId(id);
//     setOpenModal(true);
//   }, []);

//   const { websiteSettingsData }: any = useAppSelector((state) => state.common);

//   const renderCarousel = (index: number) => (
//     <Carousel
//       className="owl-carousel owl-theme"
//       showStatus={false}
//       showIndicators={false}
//       showArrows={true}
//       swipeable={false}
//       autoPlay={true}
//       infiniteLoop={true}
//       showThumbs={false}
//       stopOnHover={true}
//       emulateTouch={false}
//       onClickItem={() => handlePopupClick(index)}
//     >
//       {(index % 2 === 0 ? images_a : images_b).map((image, idx) => (
//         <div className="item" key={idx}>
//           <img src={dynamicImage(image)} alt="" />
//           <div className="product-hover">
//             <UL className="simple-list flex-row">
//               <LI>
//                 <Link to={``}>
//                   <i className="icon-eye"></i>
//                 </Link>
//               </LI>
//             </UL>
//           </div>
//         </div>
//       ))}
//     </Carousel>
//   );

//   const splitItems = [
    
//     items.filter((_, index) => index % 4 === 0),  // First array (0, 4, 8, 12, ...)
//     items.filter((_, index) => index % 4 === 1),  // Second array (1, 5, 9, 13, ...)
//     items.filter((_, index) => index % 4 === 2),  // Third array (2, 6, 10, 14, ...)
//     items.filter((_, index) => index % 4 === 3),  // Fourth array (3, 7, 11, 15, ...)
//   ];
//   const mergedItemsss =  mobileView?  items :splitItems.flat();

// console.log(mergedItemsss);
//   console.log(splitItems);
  
//   useEffect(()=>{
//     setLayout(mobileView ? items : mergedItemsss )
//   },[mobileView])
  

//   return (
//     <div className="product-wrapper-grid">
//     <div className="column-3" style={{columnCount:`${mobileView ? 1:4}` }}>
//       {layout && layout.map((item, index) => {
//         return (
//           <div key={item.id} className="">
//             {websiteSettingsData?.google_ads_index === "on" &&
//             (index !== 3 && index !== 15 && index !== 27) ? (
//               <Card className="pb-4 h-100">
//                 <div className="product-box h-100 border">
//                   <div className="product-img">
//                     {renderCarousel(index)}
//                   </div>
//                   <div className="product-details">
//                     <Link to={`${process.env.PUBLIC_URL}/ecommerce/productpage`}>
//                       <H4>{item.title}</H4>
//                     </Link>
//                     <P>Description of {item.title}</P>
//                   </div>
//                 </div>
//               </Card>
//             ) : (
//               <AdSense listingAds={true} />
//             )}
//           </div>
//         );
//       })}
//       {openModal && (
//         <MediaModal
//           value={openModal}
//           setOpenModal={setOpenModal}
//           dataId={dataId}
//           items={items}
//           modalBodyClassName="modal-custom"
//         />
//       )}
//     </div>
//   </div>
// );
// };

// export default MediaData;
import { Card, Row } from "reactstrap";
import { dynamicImage } from "../../../Service";
import { Link } from "react-router-dom";
import { H4, P, UL, LI } from "../../../AbstractElements";
import { useState, useCallback, useEffect, useMemo } from "react";
import { Carousel } from "react-responsive-carousel";
import MediaModal from "./MediaModal";
import AdSense from "../../WebsiteSettings/AdSense/Index";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";

export const images_a = [
  "masonry/1.jpg",
  "masonry/1.jpg",
  "masonry/1.jpg",
  "masonry/1.jpg",
  "masonry/1.jpg",
];
export const images_b = [
  "masonry/2.jpg",
  "masonry/2.jpg",
  "masonry/2.jpg",
  "masonry/2.jpg",
  "masonry/2.jpg",
];

export const items = [
  { id: 1, title: "Media 1" },
  { id: 2, title: "Media 2" },
  { id: 3, title: "Media 3" },
  { id: 4, title: "Media 4" },
  { id: 1, title: "Media 5" },
  { id: 2, title: "Media 6" },
  { id: 3, title: "Media 7" },
  { id: 4, title: "Media 8" },
  { id: 1, title: "Media 9" },
  { id: 2, title: "Media 10" },
  { id: 3, title: "Media 11" },
  { id: 4, title: "Media 12" },
  { id: 1, title: "Media 13" },
  { id: 2, title: "Media 14" },
  { id: 3, title: "Media 15" },
  { id: 4, title: "Media 16" },
  { id: 1, title: "Media 17" },
  { id: 2, title: "Media 18" },
  { id: 3, title: "Media 19" },
  { id: 4, title: "Media 20" },
  { id: 1, title: "Media 21" },
  { id: 2, title: "Media 22" },
  { id: 3, title: "Media 23" },
  { id: 4, title: "Media 24" },
  { id: 1, title: "Media 25" },
  { id: 2, title: "Media 26" },
  { id: 3, title: "Media 27" },
  { id: 3, title: "Media 28" },
  { id: 4, title: "Media 29" },
  { id: 1, title: "Media 30" },
  { id: 2, title: "Media 31" },
  { id: 3, title: "Media 32" },
  { id: 4, title: "Media 33" },
  { id: 1, title: "Media 34" },
  { id: 2, title: "Media 35" },
  { id: 3, title: "Media 36" },
];

const MediaData = (props: any) => {
  const [dataId, setDataId] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);
  const [shouldResize, setShouldResize] = useState(false);

  // Split the items for desktop layout
  const splitItems = [
    items.filter((_, index) => index % 4 === 0),
    items.filter((_, index) => index % 4 === 1),
    items.filter((_, index) => index % 4 === 2),
    items.filter((_, index) => index % 4 === 3),
  ];

  // Merge items for desktop view (to display 4 columns)
  const mergedItems = useMemo(() => {
    return splitItems.flat();
  }, [splitItems]);

  // Check and update layout on resize or page load
  const updateLayout = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setShouldResize(true); // Mobile view
    } else {
      setShouldResize(false); // Desktop view
    }
  }, []);

  useEffect(() => {
    updateLayout(); // Check layout on mount
    window.addEventListener("resize", updateLayout); // Add resize listener

    return () => {
      window.removeEventListener("resize", updateLayout); // Cleanup listener on unmount
    };
  }, [updateLayout]);

  const handlePopupClick = useCallback((id: number) => {
    setDataId(id);
    setOpenModal(true);
  }, []);

  const renderCarousel = (index: number) => (
    <Carousel
      className="owl-carousel owl-theme"
      showStatus={false}
      showIndicators={false}
      showArrows={true}
      swipeable={false}
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      stopOnHover={true}
      emulateTouch={false}
      onClickItem={() => handlePopupClick(index)}
    >
      {(index % 2 === 0 ? images_a : images_b).map((image, idx) => (
        <div className="item" key={idx+10}>
          <img src={dynamicImage(image)} alt="" />
          <div className="product-hover">
            <UL className="simple-list flex-row">
              <LI>
                <Link to={``}>
                  <i className="icon-eye"></i>
                </Link>
              </LI>
            </UL>
          </div>
        </div>
      ))}
    </Carousel>
  );

  return (
    <div className="product-wrapper-grid">
      <div className={shouldResize ? "d-flex flex-column" : "column-3"}>
        {shouldResize ? (
          // Mobile layout: one item per row
          items.map((item, index) => (
            <div key={index+500}>
              {websiteSettingsData?.google_ads_index === "on" &&
              index !== 3 &&
              index !== 15 &&
              index !== 27 ? (
                <Card className="pb-4 h-100">
                  <div className="product-box h-100 border">
                    <div className="product-img">{renderCarousel(index)}</div>
                    <div className="product-details">
                      <Link to={`${process.env.PUBLIC_URL}/ecommerce/productpage`}>
                        <H4>{item.title}</H4>
                      </Link>
                      <P>Description of {item.title}</P>
                    </div>
                  </div>
                </Card>
              ) : (
                <AdSense listingAds={true} />
              )}
            </div>
          ))
        ) : ( 
          
          // Desktop layout: grid of 4 items per row
          mergedItems.map((item, index) => (
            <div key={index+'a'} className="">
              {websiteSettingsData?.google_ads_index === "on" &&
              index !== 3 &&
              index !== 15 &&
              index !== 27 ? (
                <Card className="pb-4 h-100">
                  <div className="product-box h-100 border">
                    <div className="product-img">{renderCarousel(index)}</div>
                    <div className="product-details">
                      <Link to={`${process.env.PUBLIC_URL}/ecommerce/productpage`}>
                        <H4>{item.title}</H4>
                      </Link>
                      <P>Description of {item.title}</P>
                    </div>
                  </div>
                </Card>
              ) : (
                <AdSense listingAds={true} />
              )}
            </div>
          ))
        )}
      </div>

      {openModal && (
        <MediaModal
          value={openModal}
          setOpenModal={setOpenModal}
          dataId={dataId}
          items={items}
          modalBodyClassName="modal-custom"
        />
      )}
    </div>
  );
};

export default MediaData;
