import React from 'react';
import { StudyMaterialAction } from '../../../Data/Tools/BrandSupportTools/ButtonActions';
import { Link, useLocation } from 'react-router-dom';
import { AddonCategoriesTableAction, ManageProductServicesAction } from '../../../../../Data/Tools/ManageContents/ManageContentButtons';
import { Btn } from '../../../../../AbstractElements';

const ProductServiceSubMenu = ({openpublishModalToggle, openProductModalToggle}) => {
    const location = useLocation();
    const currentPath = location.pathname;

    // Function to determine if the current path matches or starts with the link's path
    const isActive = (path) => {
        return path && currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`);
    };
 

    return (
        <>
        
        <div className='d-flex flex-wrap'>
            {ManageProductServicesAction.map((material, index) => { 
                const activeClass = material.path && isActive(material.path) ? 'bg-gray text-white' : 'bg-dark text-light';
                    //  if(index == 1){
                    //     return <Btn className='p-0' onClick={openProductModalToggle}><span className={`border rounded p-2 m-1 bg-dark text-white  ps-2 pe-2  `}>{material.actionName}</span></Btn>
                    //  }
                     if(index == 1){
                        return <Btn className='p-0' onClick={openpublishModalToggle}><span className={`border rounded p-2 m-1 bg-dark text-white  ps-2 pe-2  `}>{material.actionName}</span></Btn>
                     }
                return (
                    <Link 
                        to={`${process.env.PUBLIC_URL}/${material.path}`} 
                        key={index}
                        className={`border rounded p-2 m-1 ps-2 pe-2 ${activeClass}`}
                    >
                        <span>
                            {material.actionName}
                        </span>
                    </Link>
                );
            })}
        </div>

        </>
    );
}

export default ProductServiceSubMenu;
