// import React, { useEffect, useState } from "react";
// import { FeatherIcons, H5 } from "../../../AbstractElements";
// import {
//   Accordion,
//   AccordionBody,
//   AccordionHeader,
//   AccordionItem,
//   Button,
//   Form,
//   FormGroup,
//   Input,
//   Label,
// } from "reactstrap";
// import { Field, Formik } from "formik";

// const options = [
//   { label: "All", value: "All" },
//   {
//     label: "Media",
//     subOptions: [
//       { label: "Albums", value: "Albums" },
//       { label: "Articles and Blogs", value: "Articles and Blogs" },
//       { label: "Visual Ideas", value: "Visual Ideas" },
//       { label: "External Links", value: "External Links" },
//       { label: "Photos", value: "Photos" },
//       { label: "Photo Contests", value: "Photo Contests" },
//       { label: "Reviews", value: "Reviews" },
//       { label: "Users", value: "Users" },
//     ],
//   },
//   {
//     label: "Posts",
//     subOptions: [
//       { label: "Activities and Events", value: "Activities and Events" },
//       { label: "Customers’ Wishes", value: "Events" },
//       { label: "Articles and Blogs", value: "ArticlesBlogs" },
//       { label: "Job Openings", value: "JobOpenings" },
//       { label: "Surveys", value: "Surveys" },
//     ],
//   },
//   {
//     label: "Marketplace",
//     subOptions: [
//       { label: "Branches", value: "Branches" },
//       { label: "Coupons", value: "Coupons" },
//       { label: "Products", value: "Products" },
//       { label: "Services", value: "Services" },
//     ],
//   },
//   {
//     label: "Knowledge",
//     subOptions: [
//       { label: "Games", value: "Games" },
//       { label: "Lessons", value: "Lessons" },
//       { label: "Quizzes", value: "Quizzes" },
//     ],
//   },
// ];

// const SearchOptions = ({
//   selectedOption,
//   setSelectedOption,
//   showSavedSearch
// }: {
//   selectedOption: string;
//   setSelectedOption: (value: string) => void;
//   showSavedSearch: boolean;
// }) => {
//   const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSelectedOption(e.target.value);
//     setOpen("")
//   };

//   useEffect(() => {
//     setOpen("1");
//   }, []);
//   const [open, setOpen] = useState<string | number>("1");
//   const toggle = (id: string) => (open === id ? setOpen("") : setOpen(id));
// console.log(showSavedSearch);

//   return (
//     <div className="search-options my-3">
//      { showSavedSearch  && ( 
//       <Accordion open={open} toggle={toggle} className="dark-accordion">
//         <AccordionItem>
//           <AccordionHeader targetId="1">
//             <div className="d-flex gap-3">
//               <span>Search Type</span>
//               <span className="ml-2">{`(${selectedOption})`}</span>
//             </div>
//             <FeatherIcons
//               iconName={open === "1" ? "ChevronUp" : "ChevronDown"}
//               className="svg-color"
//             />
//           </AccordionHeader>
//           <AccordionBody accordionId="1" className="">
//             <div className="d-flex flex-column">
//             <Formik
//                 initialValues={{ searchOption: "" }} // Initial selected value
//                 onSubmit={(values) => {
//                   console.log("Selected Option:", values.searchOption);
//                 }}
//               >
//               {({ values }) => (
//                 <Form>
//                   {options.map((option, index) => (
//                     <React.Fragment key={index}>
//                       <FormGroup>
//                         <div className={`search-options-radio my-3 ${option.label === "All" ? "m-0" : ""}`}>
//                           <Label check>
//                             <strong>{option.label}</strong>
//                           </Label>
//                           {option.value && (
//                             <Field
//                               as={Input}
//                               type="radio"
//                               name="searchOption"
//                               value={option.value}
//                               checked={values.searchOption === option.value}
//                             />
//                           )}
//                         </div>
//                       </FormGroup>

//                       <div className="search-options-radio">
//                         {option.subOptions &&
//                           option.subOptions.map((subOption, subIndex) => (
//                             <div key={subIndex} className="form-check form-check-inline radio radio-primary">
//                               <Field
//                                 as={Input}
//                                 type="radio"
//                                 name="searchOption"
//                                 value={subOption.value}
//                                 checked={values.searchOption === subOption.value}
//                                 className="form-check-input"
//                                 id={`radio-${subOption.value}`}
//                               />
//                               <Label htmlFor={`radio-${subOption.value}`}>{subOption.label}</Label>
//                             </div>
//                           ))}
//                       </div>
//                     </React.Fragment>
//                   ))}

//                   <Button type="submit" color="primary" className="mt-3">
//                     Submit
//                   </Button>
//                 </Form>
//               )}
//             </Formik>
//             </div>
//           </AccordionBody>
//         </AccordionItem>
//       </Accordion>)}
//     </div >
//   );
// };

// export default SearchOptions;
import React, { useEffect, useState } from "react";
import { FeatherIcons } from "../../../AbstractElements";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { Field, Formik } from "formik";

const options = [
  { label: "All", value: "All" },
  {
    label: "Media",
    subOptions: [
      { label: "Albums", value: "Albums" },
      { label: "Articles and Blogs", value: "Articles and Blogs" },
      { label: "Visual Ideas", value: "Visual Ideas" },
      { label: "External Links", value: "External Links" },
      { label: "Photos", value: "Photos" },
      { label: "Photo Contests", value: "Photo Contests" },
      { label: "Reviews", value: "Reviews" },
      { label: "Users", value: "Users" },
    ],
  },
  {
    label: "Posts",
    subOptions: [
      { label: "Activities and Events", value: "Activities and Events" },
      { label: "Customers’ Wishes", value: "Events" },
      { label: "Articles and Blogs", value: "ArticlesBlogs" },
      { label: "Job Openings", value: "JobOpenings" },
      { label: "Surveys", value: "Surveys" },
    ],
  },
];

const SearchOptions = ({
  selectedOption,
  setSelectedOption,
  showSavedSearch
}: {
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  showSavedSearch: boolean;
}) => {
  const [open, setOpen] = useState<string | number>("1");

  useEffect(() => {
    setOpen("1");
  }, []);

  const toggle = (id: string) => (open === id ? setOpen("") : setOpen(id));

  return (
    <div className="search-options my-3">
      {showSavedSearch && (
        <Accordion open={open} toggle={toggle} className="dark-accordion">
          <AccordionItem>
            <AccordionHeader targetId="1">
              <div className="d-flex gap-3">
                <span>Search Type</span>
                <span className="ml-2">{`(${selectedOption})`}</span>
              </div>
              <FeatherIcons
                iconName={open === "1" ? "ChevronUp" : "ChevronDown"}
                className="svg-color"
              />
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <Formik
                initialValues={{ searchOption: selectedOption }} // Set initial value to selectedOption
                onSubmit={(values) => {
                  console.log("Selected Option:", values.searchOption);
                  setSelectedOption(values.searchOption); // Ensure it updates state on submit
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    {options.map((option, index) =>{ 
                      console.log(option);
                      
                      return(
                      <React.Fragment key={index}>
                        <FormGroup>
                          <div className={`search-options-radio my-3 ${option.label === "All" ? "m-0" : ""}`}>
                            <Label check>
                              <strong>{option.label}</strong>
                            </Label>
                            {option.value && (
                              <Field
                                as={Input}
                                type="radio"
                                name="searchOption"
                                value={option.value}
                                checked={values.searchOption === option.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  console.log(e.target.value);
                                  
                                  setFieldValue("searchOption", e.target.value);
                                  setSelectedOption(e.target.value); // Update external state
                                }}
                              />
                            )}
                          </div>
                        </FormGroup>

                        <div className="search-options-radio">
                          {option.subOptions &&
                            option.subOptions.map((subOption, subIndex) => (
                              <div key={subIndex} className="form-check form-check-inline radio radio-primary">
                                <Field
                                  as={Input}
                                  type="radio"
                                  name="searchOption"
                                  value={subOption.value}
                                  checked={values.searchOption === subOption.value}
                                  className="form-check-input"
                                  id={`radio-${subOption.value}`}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    console.log(e.target.value);
                                    setFieldValue("searchOption", e.target.value);
                                    setSelectedOption(e.target.value); // Update external state
                                  }}
                                />
                                <Label htmlFor={`radio-${subOption.value}`}>{subOption.label}</Label>
                              </div>
                            ))}
                        </div>
                      </React.Fragment>
                    )})}

                    <Button type="submit" color="primary" className="mt-3">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
};

export default SearchOptions;
