import { CardHeader, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
    ManageBranches,
    DeliveryWorkersTitle,
} from "../../../../utils/Constant";
import DeliveryWorkersMain from "../../../../Component/Tools/ManageOnlineStore/DeliveryWorkers/DeliveryWorkersMain";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import DeliverySubMenu from "../DeliverySubMenu/DeliverySubMenu";
import SubMenuPopup from "../../../../Layout/Sidebar/ManageStorePopup";

const AddDeliveryWorkers = () => {
    return (
        <div className="page-body">
            <div className="d-flex container-fluid align-items-center">
                <div className="px-2">
                    <SubMenuPopup currentPage="Add a Delivery Worker" />
                </div>
                <Breadcrumbs
                mainTitle={`${DeliveryWorkersTitle} (Manage Store)`}
                parent={ManageBranches}
                mainParent="All Tools"
            />
            </div>
            
            <Container fluid>
                <CardHeader className="pb-0 pt-0  ">
                    <ManageOnlineSubMenu />
                </CardHeader>
            </Container>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <div>
                            <DeliverySubMenu />
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="  p-3 pt-1" fluid> 
                <DeliveryWorkersMain />
            </Container>
        </div>
    );
};

export default AddDeliveryWorkers;
