// import React, { useState, useEffect, useRef } from 'react';
// import { animate, motion } from 'framer-motion';
// import { Col, Form, Label, Row } from 'reactstrap';
// import { H4 } from '../../AbstractElements';
// import { useAppSelector } from '../../ReduxToolkit/Hooks';
// import { Formik } from 'formik';
// import { quizMaterialFormInitialValue, QuizMaterialFormValidationProp, quizMaterialLessonFormValidationSchema } from '../../Data/Tools/BrandSupportTools/FormControlsValidation';
// let questions = [
//     { id: 1, text: 'What is the capital of France?', options: ['Paris', 'Berlin', 'Madrid', 'Rome'], correct: 0 },
//     { id: 2, text: 'What is 2 + 2?', options: ['3', '4', '5', '6'], correct: 1 },
//     { id: 3, text: 'Who wrote "Hamlet"?', options: ['Shakespeare', 'Dickens', 'Hemingway', 'Austen'], correct: 0 },
//     { id: 4, text: 'What is the largest planet in our solar system?', options: ['Earth', 'Jupiter', 'Mars', 'Venus'], correct: 1 },
//     { id: 5, text: 'What is the chemical symbol for gold?', options: ['Au', 'Ag', 'Pb', 'Fe'], correct: 0 },
//     { id: 6, text: 'In which year did the Titanic sink?', options: ['1912', '1905', '1920', '1898'], correct: 0 },
//     { id: 7, text: 'What is the hardest natural substance on Earth?', options: ['Gold', 'Iron', 'Diamond', 'Platinum'], correct: 2 },
//     { id: 8, text: 'Who painted the Mona Lisa?', options: ['Da Vinci', 'Van Gogh', 'Picasso', 'Monet'], correct: 0 },
//     { id: 9, text: 'What is the capital of Japan?', options: ['Tokyo', 'Seoul', 'Beijing', 'Bangkok'], correct: 0 },
//     { id: 10, text: 'What is the smallest prime number?', options: ['0', '1', '2', '3'], correct: 2 },
//     { id: 11, text: 'Who discovered penicillin?', options: ['Fleming', 'Edison', 'Curie', 'Pasteur'], correct: 0 },
//     { id: 12, text: 'What is the largest ocean on Earth?', options: ['Atlantic', 'Indian', 'Pacific', 'Arctic'], correct: 2 },
//     { id: 13, text: 'What element does "O" represent on the periodic table?', options: ['Oxygen', 'Gold', 'Osmium', 'Oganesson'], correct: 0 },
//     { id: 14, text: 'In which continent is Egypt located?', options: ['Asia', 'Africa', 'Europe', 'South America'], correct: 1 },
//     { id: 15, text: 'What is the main ingredient in guacamole?', options: ['Tomato', 'Pepper', 'Avocado', 'Onion'], correct: 2 },
//     { id: 16, text: 'Who was the first President of the United States?', options: ['Lincoln', 'Washington', 'Jefferson', 'Adams'], correct: 1 },
//     { id: 17, text: 'What is the chemical symbol for water?', options: ['HO', 'H2O', 'O2', 'CO2'], correct: 1 },
//     { id: 18, text: 'What is the largest land animal?', options: ['Elephant', 'Giraffe', 'Rhino', 'Hippopotamus'], correct: 0 },
//     { id: 19, text: 'Which planet is known as the Red Planet?', options: ['Venus', 'Mars', 'Jupiter', 'Saturn'], correct: 1 },
//     { id: 20, text: 'What is the capital of Canada?', options: ['Toronto', 'Ottawa', 'Vancouver', 'Montreal'], correct: 1 },
//     { id: 21, text: 'Which gas do plants primarily use for photosynthesis?', options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'], correct: 2 },
//     { id: 22, text: 'Who is known as the "Father of Computers"?', options: ['Turing', 'Babbage', 'Jobs', 'Gates'], correct: 1 },
//     { id: 23, text: 'What is the smallest country in the world by land area?', options: ['Vatican City', 'Monaco', 'San Marino', 'Liechtenstein'], correct: 0 },
//     { id: 24, text: 'What is the currency of Japan?', options: ['Yuan', 'Yen', 'Won', 'Ringgit'], correct: 1 },
//     { id: 25, text: 'Which planet is closest to the Sun?', options: ['Venus', 'Earth', 'Mercury', 'Mars'], correct: 2 },
//     { id: 26, text: 'What is the freezing point of water in Celsius?', options: ['0°C', '32°C', '100°C', '-10°C'], correct: 0 },
//     { id: 27, text: 'What is the largest species of shark?', options: ['Great White', 'Hammerhead', 'Whale Shark', 'Tiger Shark'], correct: 2 },
//     { id: 28, text: 'Who was the 16th President of the United States?', options: ['Lincoln', 'Grant', 'Roosevelt', 'Jackson'], correct: 0 },
//     { id: 29, text: 'What is the most abundant gas in Earth’s atmosphere?', options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'], correct: 1 },
//     { id: 30, text: 'What is the capital of Australia?', options: ['Sydney', 'Melbourne', 'Canberra', 'Brisbane'], correct: 2 }
// ];
// const QuizTest = () => {
//   const [gameStarted, setGameStarted] = useState(false);
//   const [showVideo, setShowVideo] = useState(true);
//   const [questionIndex, setQuestionIndex] = useState(0);
//   const [answerStatus, setAnswerStatus] = useState<'correct' | 'incorrect' | null>(null);
//   const [animate, setAnimate] = useState(false);
//   const [currentQuestion, setCurrentQuestion] = useState(1);
//   const [timeLeftMini, setTimeLeftMini] = useState(120);
//   const [timeLeft, setTimeLeft] = useState(7200);  
//   const currentQuestionData = questions[currentQuestion - 1];
//   const videoRef = useRef<HTMLVideoElement | null>(null); // Reference to video element
//   useEffect(() => {
//     const timer = setInterval(() => {
//         setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
//     }, 1000);
//     return () => clearInterval(timer);
// }, []);
// useEffect(() => {
//     const timer = setInterval(() => {
//         setTimeLeftMini((prev) => (prev > 0 ? prev - 1 : 0));
//     }, 1000);
//     return () => clearInterval(timer);
// }, [currentQuestion]);
// useEffect(() => {
//     setTimeLeftMini(120); // Reset to 2 minutes
// }, [currentQuestion]);
 

//   // Start the video when the user clicks Start Game
//   const startGame = () => {
//     if (videoRef.current) {
//       videoRef.current.play(); // Play the video
//     }
//   };

//   // Listen to the video end event to show the quiz
//   useEffect(() => {
//     if (videoRef.current) {
//       videoRef.current.onended = () => {
//         setShowVideo(false); // Hide video when it ends
//         setGameStarted(true); // Start the game when the video ends
//       };
//     }
//   }, []);

//   const handleAnswer = (index: number) => {
//     if (index === questions[questionIndex].correct) {
//       setAnswerStatus('correct');
//     } else {
//       setAnswerStatus('incorrect');
//     }
//     setTimeout(() => setAnswerStatus(null), 2000);
//   };

//   const nextQuestion = () => {
//     setQuestionIndex((prevIndex) => prevIndex + 1);
//   };
 
// const totalQuestions = questions.length;

// const handleNext = () => {
//     if (currentQuestion < totalQuestions) {
//         setAnimate(true);
//         setTimeout(() => {
//             setCurrentQuestion(currentQuestion + 1);
//             setSelectedAnswer(null);
//             setCorrectAnswer(null);
//             // setAnswerLocked(false);  
//             setAnimate(false);
//         }, 300);
//     }
// };

// const handlePrevious = () => {
//     if (currentQuestion > 1) {
//         setAnimate(true);
//         setTimeout(() => {
//             setCurrentQuestion(currentQuestion - 1);
//             setSelectedAnswer(null);
//             setCorrectAnswer(null);
//             // setAnswerLocked(false);  
//             setAnimate(false);
//         }, 300);
//     }
// };
// const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
// const [correctAnswer, setCorrectAnswer] = useState<number | null>(null);
// const quizName = "General Knowledge Quiz"; // Hardcoded quiz name

// // Timer effect

// const handleAnswerClick = (index: number) => {
//     // if (!answerLocked) {
//         const correct = questions[currentQuestion - 1].correct;
//         setSelectedAnswer(index);
//         setCorrectAnswer(correct);
//         // setAnswerLocked(true);  
//     // }
// };
// const color2 = "#ff6600"; // First color
//     const color1 = "#5cbe1c"; // Second color
//     const { mobileView } = useAppSelector((state) => state.layout);

// const getButtonClass = (index: number) => {
//     if (selectedAnswer === index) {
//         return index === correctAnswer ? "btn-dark" : "btn-dark";
//     }
//     return " ";
// };

//   const formatTimehours = (seconds: number) => {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.floor((seconds % 3600) / 60);
//     const secs = seconds % 60;
//     return `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${secs < 10 ? '0' + secs : secs}`;
// }; 
// const formatTime = (seconds: number) => {
// const minutes = Math.floor(seconds / 60);
// const secs = seconds % 60;
// return `${minutes}:${secs < 10 ? "0" + secs : secs}`;
// };
// const handleSubmit = (values: QuizMaterialFormValidationProp) => {
//     console.log(values);
//     // setSubmitError(false);
// };
//   return (
//     <div className=" ">
//       {showVideo ? (
//         // Show the video and Start button
//         <motion.div
//           className="start-screen"
//           initial={{ opacity: 0, scale: 0.5 }}
//           animate={{ opacity: 1, scale: 1 }}
//           transition={{ duration: 0.8 }}
//         >
//             <Row>
//                 <Col>         
//           <video
//             ref={videoRef}
//             width="100%"
//             style={{ maxWidth: '100%' }}
//             controls={false}
//             muted
//           >
//             <source src="https://cdn.pixabay.com/video/2017/01/15/7374-199627560_large.mp4" type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//                 </Col>
//             </Row>
//           <motion.button
//             whileHover={{ scale: 1.2 }}
//             whileTap={{ scale: 0.9 }}
//             onClick={startGame}
//           >
//             Start Game
//           </motion.button>
//         </motion.div>
//       ) : gameStarted ? (
//         // After the video, start the quiz
//         <motion.div
//                         key={currentQuestion}
//                         initial={{ opacity: 0, x: 100 }}
//                         animate={{ opacity: 1, x: 0, backgroundPosition: `100% 0%` }}
//                         exit={{ opacity: 0, x: -100 }}
//                         transition={{ duration: 0.5 }}
//                         className="question-content"
//                         style={{
//                             padding: "20px",
//                             borderRadius: "8px",
//                             backgroundImage: `linear-gradient(135deg, ${color1} 50%, ${color2} 50%)`,
//                             backgroundSize: "200% 100%",
//                             backgroundPosition: animate ? "100% 0%" : "0% 0%",
//                             transition: "background-position 0.5s ease-in-out"
//                         }}
//                     >
//                      <div className="text-end mt-0 text-white">
//                                 <strong>Time Left for this question: {formatTime(timeLeftMini)}</strong>
//                             </div>
//                         <div className="d-flex flex-column  justify-content-center" style={{ marginLeft: mobileView ? "50px" : "125px" }}    >
//                             <H4 className="text-white">{currentQuestion}/{totalQuestions}: {" "}{currentQuestionData.text}</H4>
//                             <div className="mt-3 d-flex  gap-2">
//                                 {currentQuestionData.options.map((option, index) => (
//                                     <button
//                                         key={index}
//                                         onClick={() => handleAnswerClick(index)}
//                                         className={`btn ${getButtonClass(index)} w-100 border py-2 px-3 text-white`}
//                                     >
//                                         {option}
//                                     </button>
//                                 ))}
//                             </div>
//                         </div>
//                     </motion.div>
//       ) : null}
//       <footer className="sticky-footer p-2">
//                         <div className="d-block d-md-flex justify-content-between">
//                         <div className="pt-2 ms-0 d-flex align-items-center">
                            
//                             <Formik
//                                 initialValues={quizMaterialFormInitialValue}
//                                 onSubmit={handleSubmit}
//                                 validationSchema={quizMaterialLessonFormValidationSchema}
//                             >
//                                 <Form style={{ Width:"400px"}} className="d-flex align-items-center gap-2">
//                                 <Label className="text-black text-left" check>Question Number:{' '}</Label>
//                                                 <input
//                                                                     type="text"
//                                                                     // value={item.quantity}
//                                                                     // onChange={(e) =>
//                                                                     //     // handleQuantityChange( )
//                                                                     // }
//                                                                     min="1" 
//                                                                     className="form-control"
//                                                                     style={{ width: "50px" }}  // Disable if not in edit mode
//                                                                 />
//                                 </Form>
//                             </Formik>
//                             <div className="next_previous pt-0">
//                             <button className="btn ms-3" >
//                                 Jump
//                             </button>
//                             </div>
//                         </div>
//                         <div className="next_previous">
//                             <button className="btn" onClick={handlePrevious} disabled={currentQuestion === 1}>
//                                 Previous
//                             </button>
//                             <button className="btn ms-2" onClick={handleNext} disabled={currentQuestion === totalQuestions}>
//                                 Next
//                             </button>
//                         </div>
//                         </div>
//                     </footer>
//     </div>
//   );
// };

// export default QuizTest;
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Col, Progress, Row } from 'reactstrap';
import QuizmaterialBoxValue from '../Application/Marketplace/QuizmaterialBoxValue';
import { Image } from '../../AbstractElements';
import { dynamicImage } from '../../Service';

// Questions array with 30 questions for demonstration
export const questions = [
    { id: 1, text: 'What is the capital of France?', options: ['Paris', 'Berlin', 'Madrid', 'Rome'], correct: 0 },
    { id: 2, text: 'What is 2 + 2?', options: ['3', '4', '5', '6'], correct: 1 },
    { id: 3, text: 'Who wrote "Hamlet"?', options: ['Shakespeare', 'Dickens', 'Hemingway', 'Austen'], correct: 0 },
    { id: 4, text: 'What is the largest planet in our solar system?', options: ['Earth', 'Jupiter', 'Mars', 'Venus'], correct: 1 },
    { id: 5, text: 'What is the chemical symbol for gold?', options: ['Au', 'Ag', 'Pb', 'Fe'], correct: 0 },
    { id: 6, text: 'In which year did the Titanic sink?', options: ['1912', '1905', '1920', '1898'], correct: 0 },
    { id: 7, text: 'What is the hardest natural substance on Earth?', options: ['Gold', 'Iron', 'Diamond', 'Platinum'], correct: 2 },
    { id: 8, text: 'Who painted the Mona Lisa?', options: ['Da Vinci', 'Van Gogh', 'Picasso', 'Monet'], correct: 0 },
    { id: 9, text: 'What is the capital of Japan?', options: ['Tokyo', 'Seoul', 'Beijing', 'Bangkok'], correct: 0 },
    { id: 10, text: 'What is the smallest prime number?', options: ['0', '1', '2', '3'], correct: 2 },
    { id: 11, text: 'What is the capital of Italy?', options: ['Rome', 'Venice', 'Naples', 'Florence'], correct: 0 },
    { id: 12, text: 'What is 5 + 3?', options: ['5', '8', '9', '7'], correct: 1 },
    { id: 13, text: 'Who discovered gravity?', options: ['Newton', 'Einstein', 'Galileo', 'Curie'], correct: 0 },
    { id: 14, text: 'What is the boiling point of water?', options: ['100°C', '0°C', '50°C', '200°C'], correct: 0 },
    { id: 15, text: 'What planet is known as the Red Planet?', options: ['Mars', 'Venus', 'Earth', 'Jupiter'], correct: 0 },
    { id: 16, text: 'What is H2O?', options: ['Hydrogen', 'Oxygen', 'Water', 'Helium'], correct: 2 },
    { id: 17, text: 'Who painted Starry Night?', options: ['Da Vinci', 'Monet', 'Van Gogh', 'Picasso'], correct: 2 },
    { id: 18, text: 'What is the capital of India?', options: ['Delhi', 'Mumbai', 'Bangalore', 'Chennai'], correct: 0 },
    { id: 19, text: 'What is the speed of light?', options: ['299,792 km/s', '150,000 km/s', '100,000 km/s', '500,000 km/s'], correct: 0 },
    { id: 20, text: 'What is 9 * 9?', options: ['81', '72', '64', '99'], correct: 0 },
    { id: 21, text: 'What is the capital of Australia?', options: ['Canberra', 'Sydney', 'Melbourne', 'Perth'], correct: 0 },
    { id: 22, text: 'What is 6 / 2?', options: ['2', '3', '4', '1'], correct: 1 },
    { id: 23, text: 'Who invented the telephone?', options: ['Bell', 'Edison', 'Tesla', 'Curie'], correct: 0 },
    { id: 24, text: 'What is the atomic number of Oxygen?', options: ['6', '8', '10', '12'], correct: 1 },
    { id: 25, text: 'What is 7 + 7?', options: ['12', '13', '14', '15'], correct: 2 },
    { id: 26, text: 'What is the capital of Canada?', options: ['Toronto', 'Vancouver', 'Ottawa', 'Montreal'], correct: 2 },
    { id: 27, text: 'What is the square root of 16?', options: ['2', '4', '8', '16'], correct: 1 },
    { id: 28, text: 'Who discovered penicillin?', options: ['Fleming', 'Curie', 'Newton', 'Einstein'], correct: 0 },
    { id: 29, text: 'What is 12 / 4?', options: ['2', '3', '4', '5'], correct: 1 },
    { id: 30, text: 'What is the capital of Brazil?', options: ['Brasilia', 'Rio', 'Sao Paulo', 'Salvador'], correct: 0 }
];

const QuizTest = ({value,totalTime,questionsPerPage,nextQuestion,pageIndex,setPageIndex,quizFinished,setQuizFinished,timeLeftBulk,setTimeLeftBulk}) => {
  // const questionsPerPage = 10;
  // const totalTime  = 1200; // 120 seconds per question
  // const [pageIndex, setPageIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  // const [timeLeft, setTimeLeft] = useState(totalTime );
  const [gameStarted, setGameStarted] = useState(value);
  // const [quizFinished, setQuizFinished] = useState(false);
  // Start the game and reset state
  const startGame = () => {
      setGameStarted(true);
      setQuizFinished(false);
      setTimeLeftBulk(totalTime);  // 2 minutes for each question
      setPageIndex(0);
      setSelectedAnswers({});
  };

  // Handle timer for each question
  useEffect(() => {
    if (gameStarted && timeLeftBulk > 0 && !quizFinished) {
        const timer = setInterval(() => setTimeLeftBulk((prev) => prev - 1), 1000);
        return () => clearInterval(timer);
    }
    if (timeLeftBulk === 0) {
        nextQuestion();
        setTimeLeftBulk(totalTime ); // reset timer for next question
    }
}, [timeLeftBulk, gameStarted, quizFinished]);

  // Handle answer selection
  const handleAnswerClick = (questionId, optionIndex) => {
      setSelectedAnswers(prev => ({ ...prev, [questionId]: optionIndex }));
  };

  // Move to next question
  // const nextQuestion = () => {
  //     if ((pageIndex + 1) * questionsPerPage < questions.length) {
  //         setPageIndex(pageIndex + 1);
  //         setTimeLeft(totalTime ); // reset timer for new question
  //     }else {
  //       // Finish the quiz when the last question is answered
  //       setQuizFinished(true);
  //   }
  // };

  // Navigate to the previous question
  // const previousQuestion = () => {
  //     if (pageIndex > 0) {
  //         setPageIndex(pageIndex - 1);
  //         setTimeLeft(totalTime ); // reset timer for previous question
  //     }
  // };
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${String(seconds).padStart(2, '0')}`;
};

    // Get the current set of questions based on pageIndex
    const startIndex = pageIndex * questionsPerPage;
    const currentQuestions = questions.slice(startIndex, startIndex + questionsPerPage);
// Calculate progress bar value
const progress = ((pageIndex + 1) * questionsPerPage) / questions.length * 100;
    return (
        <div className="text-black " style={{ maxWidth: "100%", margin: "auto" }}>
            {
            // !gameStarted ? (
            //     <button onClick={startGame} className="btn btn-primary">Start Game</button>
            // )  
            // :
             quizFinished ? (
              <div>
                <h2>Quiz Results</h2>
                {questions.map((question,num) => {
                  const selectedAnswer = selectedAnswers[1];
                  const correctAnswer = question.correct;
      
                  return (
                    <div key={question.id} className="question my-3">
                      <h5>{num + 1}{")"}.{' '}{question.text}</h5>
                      <div className='d-flex gap-2 mt-2'>
                        {question.options.map((option, index) => {
                          const isSelected = selectedAnswer === index;
                          const isCorrect = correctAnswer === index;
                          let buttonClass = 'btn ';
                          if (isSelected && !isCorrect) buttonClass += 'bg-danger'; // wrong answer selected
                          if (isCorrect) buttonClass += 'bg-success'; // correct answer
      
                          return (
                            <button
                              key={index}
                              className={buttonClass}
                              disabled
                            >
                              {option}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                <button onClick={startGame} className="btn btn-primary">Restart</button>
              </div>
            ): (
                <> 
                   <div className='d-flex justify-content-between mt-3'>
                   
                        <p>Page {pageIndex + 1} of {Math.ceil(questions.length / questionsPerPage)}</p>
                   </div>
                         {/* Progress bar */}
          <Progress value={progress} color="info" />
                        {currentQuestions.map((question) => (
                            <div key={question.id} className="question mt-3">
                               <motion.div
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={{ duration: 0.5 }}
                        className="questions-content"
                        style={{ padding: "20px", borderRadius: "8px", border: "1px solid #ddd", backgroundColor: "#f9f9f9" }}
                    >  
                    <div className="d-flex align-items-center justify-content-end">
                                        <Image className="img-20 rounded-circle m-r-15" src={dynamicImage(`user/user.png`)} alt="user" />                                                                                                     
                                        <span className="d-block mt-1 fs-6 fw-normal" >{`Bucky Barnes`}</span>

                                        </div> 
                                <p>{question.text}</p>
                                <div className="">
                                     
                                    <Row className='mt-3'>
                                    {question.options.map((option, index) => (
                                        <Col xs="3" key={index} className="my-2">
                                            <motion.button
                                                className={`btn ${selectedAnswers[question.id] === index ? 'btn-info' : 'btn-light'} w-100`}
                                                whileTap={{ scale: 0.95 }}
                                                onClick={() => handleAnswerClick(question.id, index)}
                                            >
                                                {option}
                                            </motion.button>
                                        </Col>
                                    ))}
                                </Row>
                            </div> 
                                </motion.div>
                            </div>
                        ))}
                  
                </>
            )}
        </div>
    );
};

export default QuizTest;
