import { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, H6, LI, UL } from "../../../../AbstractElements";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import Country from "../../../CommonInput/Country";
import State from "../../../CommonInput/State";
import City from "../../../CommonInput/City";
import TextCounter from "../../../CommonInput/TextCounter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TitleInput from "../../../CommonInput/TitleInput";
import DescriptionInput from "../../../CommonInput/DescriptionInput";
import ImportBulk from "../../../../Pages/Tools/BrandSupportTools/ImportBulk/ImportBulk";
import Popup from "../../../MasterPopup/Popup";
import Notes from "../../../../Pages/Tools/Notes/Notes";
import {
    manageBranchFormInitialValue,
    manageBranchFormValidationSchema,
    scheduleFormInitialValue,
    scheduleFormValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import AdditionalBranchCountry, {
    AdditionalCountryinitialValues,
    AdditionalCountryvalidationSchema,
} from "../../../../Pages/Tools/AdditionalBranchCountry/AdditionalBranchCountry";
import Branches from "../../../CommonInput/Branches";
import GroupMain from "../../../Communication/GroupMain";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import Groups from "../../../CommonInput/Groups";
import TagsInput from "../../../CommonInput/TagsInput";
import SharingSection from "../../BrandSupportTools/SurveyAndQuizzes/SharingSection";
import AddFaqForm from "../../../../Pages/Tools/BrandSupportTools/FequentlyQuestion/AddFaqForm";
import CustomSelect from "../../../CommonInput/CustomSelect";
interface AddManageBranchesFormProps {
    errors: any; // Define a more specific type based on your use case
    submitErrors: boolean;
    setSubmitError: (value: boolean) => void;
    values: ManageBranchFormValidationProp;
    setFieldValue: (field: string, value: any) => void;
}
const AddManageBranchesForm = (props: any) => {
    const { errors, submitErrors, setSubmitError, values, setFieldValue } =
        props;

    const [hideButton, setHideButton] = useState(false);
    const [openQestionModal, setOpenQestionModal] = useState(false);
    const openQestionModalToggle = () => setOpenQestionModal(!openQestionModal);
    // when click on radio button button is hide/show
    const handleHoursDayRadio = (value: boolean) => {
        setHideButton(value);
    };

    // when click on the image file input.
    const uploadFileHandler = (event: any) => {
        const formData = new FormData();
        formData.append("image", event.target.files[0]);
    };
    const notificationSounds = {
        "Alert-1": "/assets/audio/audio-1.mp3",
        "Alert-2": "/assets/audio/audio-2.mp3",
        "Alert-3": "/assets/audio/audio-3.mp3",
        "Alert-4": "/assets/audio/audio-4.mp3",
        "Alert-5": "/assets/audio/audio-5.mp3",
        "Chime-1": "/assets/audio/chime-1.mp3",
        "Chime-2": "/assets/audio/chime-2.mp3",
        "Chime-3": "/assets/audio/chime-3.mp3",
        "Chime-4": "/assets/audio/chime-4.mp3",
        "Chime-5": "/assets/audio/chime-5.mp3",
    } as const; // This tells TypeScript to treat this object as immutable

    // Create a type from the keys of the notificationSounds object
    type NotificationToneKeys = keyof typeof notificationSounds;
    const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];
    const handleToneChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
        setFieldValue: (field: string, value: any) => void
    ) => {
        const selectedTone = event.target.value as NotificationToneKeys; // Type assertion
        setFieldValue("notificationtone", selectedTone); // Update Formik field value
        const audio = new Audio(notificationSounds[selectedTone]); // Create a new audio instance

        // Play the selected tone with error handling
        audio.play().catch((error) => {
            console.error("Error playing audio:", error);
            // Optionally, you can show a notification to the user here
        });
    };
    const handleSubmit = () => {};
    const [openAdditonalModal, setopenAdditonalModal] = useState();
    const [openFromModal, setOpenFromModal] = useState(false);
        const [reloadGroupsData, setReloadGroupsData] = useState(0);
    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
    const [openFaqModal, setOpenFaqModal] = useState(false);
    const openFaqModalToggle = () => setOpenFaqModal(!openFaqModal);
    const reloadGroups = () => {
      setReloadGroupsData((prev) => prev + 1);
      console.log(reloadGroupsData);
    }
    const openAdditonalModalToggle = () =>
        setopenAdditonalModal(!openAdditonalModal);
    const options = [
        { value: "5", label: "30 Days, 5 USD" },
        { value: "12", label: "90 Days (4 USD/ 30 Days) if you pay 12 USD" },
        { value: "18", label: "180 Days (3 USD/ 30 Days) if you pay 18 USD" },
        { value: "24", label: "Annually (2 USD/ 30 Days) if you pay 24 USD" },
    ];
    const branchesOptions = [
        {
            label: "Branch 1",
            value: "branch1",
            country: "USA",
            state: "California",
            city: "Los Angeles",
        },
        {
            label: "Branch 2",
            value: "branch2",
            country: "USA",
            state: "Arizona",
            city: "Phoenix",
        },
        {
            label: "Branch 3",
            value: "branch3",
            country: "USA",
            state: "New York",
            city: "New York City",
        },
    ];
    return (
        <>
            <Form className="form-wizard ">
                <Row className="mb-3">
                    <Col md="12">
                        <div className="border rounded border-danger p-2 px-3  mx-2 my-3">
                            <UL className="list-content ">
                                <Row>
                                    <Col sm="4">
                                        <Formik
                                            initialValues={
                                                manageBranchFormInitialValue
                                            }
                                            // onSubmit={handleSubmit}
                                            validationSchema={
                                                manageBranchFormValidationSchema
                                            }>
                                            {({
                                                errors,
                                                values,
                                                setFieldValue,
                                            }) => (
                                                <Form className="form-wizard ">
                                                    <LI className="d-flex ">
                                                        
                                                        <Row className="w-100">
                                                            <Col
                                                                md="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    Branch
                                                                    Country{" "}
                                                                    <span className="txt-danger">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                <Country
                                                                    name="branchcountry"
                                                                    placeholder="Select"
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    // className={`${
                                                                    //   // submitErrors &&
                                                                    //   // `${errors.branchcountry ? "is-invalid" : "is-valid"}`
                                                                    // }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="branchcountry"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                                <Btn
                                                                    className="ps-0  d-flex"
                                                                    onClick={
                                                                        openAdditonalModalToggle
                                                                    }>
                                                                    <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                                                    Add an
                                                                    Additional
                                                                    Branch
                                                                    Country{" "}
                                                                </Btn>
                                                            </Col>
                                                        </Row>
                                                    </LI>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Col>
                                    <Col sm="6">
                                        <LI>
                                            <FontAwesomeIcon
                                                icon={fas.faCircle}
                                                className="pe-2"
                                                size="xs"
                                            />
                                            {`Wallet Balance: 53.00 USD`}
                                        </LI>
                                        <LI>
                                            <FontAwesomeIcon
                                                icon={fas.faCircle}
                                                className="pe-2"
                                                size="xs"
                                            />
                                            {`Left Monthly Balance of FREE Submission: 1/5`}
                                        </LI>
                                        <LI>
                                            <FontAwesomeIcon
                                                icon={fas.faCircle}
                                                className="pe-2"
                                                size="xs"
                                            />
                                            {`Price (30 Days) for additional submission: 3 USD (for 3 additional submissions)`}
                                        </LI>
                                        <LI>
                                            <FontAwesomeIcon
                                                icon={fas.faCircle}
                                                className="pe-2"
                                                size="xs"
                                            />
                                            {`Left Balance of PAID Submission: 2/3`}
                                        </LI>
                                        <Formik
                                            initialValues={
                                                manageBranchFormInitialValue
                                            }
                                            // onSubmit={handleSubmit}
                                            validationSchema={
                                                manageBranchFormValidationSchema
                                            }>
                                            {({
                                                errors,
                                                values,
                                                setFieldValue,
                                            }) => (
                                                <Form className="form-wizard ">
                                                    <LI className="d-flex ">
                                                        <FontAwesomeIcon
                                                            icon={fas.faCircle}
                                                            className="pe-2 mt-1"
                                                            size="xs"
                                                        />
                                                        <Row className="w-100">
                                                            <Col
                                                                md="6"
                                                                className="mb-3">
                                                                <Label check>
                                                                    1Webbb
                                                                    Service
                                                                    Price:
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    name="amount"
                                                                    className="form-control">
                                                                    <option value="">
                                                                        Select...
                                                                    </option>
                                                                    {options.map(
                                                                        (
                                                                            option
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    option.value
                                                                                }
                                                                                value={
                                                                                    option.value
                                                                                }>
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </Field>
                                                            </Col>
                                                        </Row>
                                                    </LI>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Col>
                                </Row>
                            </UL>
                            <Popup
                                title="Additional Branch Country"
                                openModalToggle={openAdditonalModalToggle}
                                isOpen={openAdditonalModal}
                                size={`lg`}
                                backdrop={true}
                                CommonFooter={true}
                                isClose={false}
                                CommonFooter={false}
                                BodyClassName="pt-1"
                                largesize="modal-custom">
                                <Formik
                                    initialValues={
                                        AdditionalCountryinitialValues
                                    }
                                    validationSchema={
                                        AdditionalCountryvalidationSchema
                                    }
                                    // onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <AdditionalBranchCountry />
                                    )}
                                </Formik>
                            </Popup>
                        </div>
                    </Col>
                </Row>
                <div className="mx-4"> 
                <Row className="mb-3">
                    <div>
                        <Btn
                            onClick={openQestionModalToggle}
                            className="p-0 child_btn border rounded">
                            <span> Import Bulk Branches</span>
                        </Btn>
                    </div>
                </Row> 
                <Row>
                    <Col md="4" className="mb-3">
                        {/* <Label check>
            Branch Name <span className="txt-danger">*</span>
          </Label>
          <Field
            name="branchname"
            type="text"
            placeholder="Branch Name"
            maxLength="140"
            className={`form-control ${
              submitErrors && `${errors.branchname ? "is-invalid" : "is-valid"}`
            }`}
          />
          <TextCounter length={values?.branchname?.length} totalLength="140" />
          <ErrorMessage
            name="branchname"
            component="span"
            className="invalid-feedback"
          /> */}
                        <TitleInput
                            title="Branch Unique Name"
                            values={values.branchname}
                            name="branchname"
                            placeholder="Branch Unique Name"
                        />
                    </Col>
                    <Col md="4" className="mb-3"> 
                        <DescriptionInput
                            name="description"
                            placeholder="Description"
                            values={values.description}
                            lable="Description"
                        />
                    </Col>
                    <Col md="4" className="mb-3">
                        <FormGroup>
                            <Label check>Cover Image</Label>
                            <Field
                                name="uploadimage"
                                className="form-control"
                                type="file"
                                onChange={uploadFileHandler}
                            />
                            <span className="help-text text-muted">
                                Image dimension 160x117
                            </span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="mb-3">
                        <Label check>
                            Full Address <span className="txt-danger">*</span>
                        </Label>
                        <Field
                            name="fulladdress"
                            type="text"
                            placeholder="Full Address of Company"
                            maxLength="140"
                            className={`form-control ${
                                submitErrors &&
                                `${
                                    errors.fulladdress
                                        ? "is-invalid"
                                        : "is-valid"
                                }`
                            }`}
                        />
                        <TextCounter
                            length={values?.fulladdress?.length}
                            totalLength="140"
                        />
                        <ErrorMessage
                            name="fulladdress"
                            component="span"
                            className="invalid-feedback"
                        />
                    </Col>

                    <Col md="4" className="mb-3">
                        <Label check>State</Label>
                        <State
                            name="branchstate"
                            isMulti={false}
                            placeholder="Select"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="mb-3">
                        <Label check>
                            City <span className="txt-danger">*</span>
                        </Label>
                        <City
                            name="branchcity"
                            isMulti={false}
                            placeholder="Select"
                            className={`${
                                submitErrors &&
                                `${
                                    errors.branchcountry
                                        ? "is-invalid"
                                        : "is-valid"
                                }`
                            }`}
                        />
                        <ErrorMessage
                            name="branchcity"
                            component="span"
                            className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4" className="mb-3">
                        <Label check>Pincode</Label>
                        <Field
                            name="pincode"
                            type="text"
                            placeholder="Pincode / Postcode / Zip Code of Store"
                            className="form-control"
                        />
                    </Col>
                    <Col md="4" className="mb-3">
                        <Label check>Landmark</Label>
                        <Field
                            name="landmark"
                            type="text"
                            placeholder="Any Near Landmark"
                            className="form-control"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="mb-3">
                        <Label check>
                            Latitude <span className="txt-danger">*</span>
                        </Label>
                        <Field
                            name="latitude"
                            type="text"
                            placeholder="Latitude of the Store"
                            className={`form-control ${
                                submitErrors &&
                                `${errors.latitude ? "is-invalid" : "is-valid"}`
                            }`}
                        />
                        <ErrorMessage
                            name="latitude"
                            component="span"
                            className="invalid-feedback"
                        />
                    </Col>
                    <Col md="4" className="mb-3">
                        <Label check>
                            Longitude <span className="txt-danger">*</span>
                        </Label>
                        <Field
                            name="longitude"
                            type="text"
                            placeholder="Longitude of the Store"
                            className={`form-control ${
                                submitErrors &&
                                `${
                                    errors.longitude ? "is-invalid" : "is-valid"
                                }`
                            }`}
                        />
                        <ErrorMessage
                            name="longitude"
                            component="span"
                            className="invalid-feedback"
                        />
                        <span className="text-muted small">
                            You can use services like:{" "}
                            <a
                                href="https://www.latlong.net/"
                                rel="noreferrer"
                                target="_blank">
                                https://www.latlong.net/
                            </a>
                            <br />
                            If you enter an invalid Latitude/Longitude the map
                            system might crash with a white screen.
                        </span>
                    </Col>
                    <Col md="4" className="mb-3">
                        <div className="">
                            <Label className="col-form-label m-r-10" check>
                                Auto Accept Orders?
                            </Label>
                            <br />
                            <div className="d-flex">
                                <div className="flex-shrink-0  text-end icon-state switch-outline">
                                    <CommonSwitchSpan
                                        name="autoAcceptOrder"
                                        color={"primary"}
                                        defaultChecked
                                    />
                                </div>
                                <Label className="m-l-10" check></Label>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4" className="mb-3">
                                        <Label check>
                                            Frequently Asked
                                            Questions (optional)
                                        </Label>
                                        <div className="">
                                                        <FormGroup>
                                                            <Input type="select">
                                                                <option>{`Select`}</option>
                                                                <option>
                                                                    Free for
                                                                    Commercial
                                                                    and
                                                                    Non-profit
                                                                    use
                                                                </option>

                                                                <option>
                                                                    Free for
                                                                    Non-Profit
                                                                    use only
                                                                </option> 
                                                            </Input>
                                                            <span className="text-muted small">
                                                                <Btn
                                                                    className="ps-0  d-flex"
                                                                    onClick={
                                                                        openFaqModalToggle
                                                                    }>
                                                                    <i className="bi bi-plus-circle mx-1 text-warning"></i>
                                                                    Add
                                                                    Frequently
                                                                    Asked
                                                                    Questions
                                                                </Btn>
                                                            </span>
                                                        </FormGroup>
                                        </div>
                    </Col>
                                                <Col sm="4" className="mb-3">
                                                    <FormGroup>
                                                        <Label check>
                                                            {`Policy or Service Terms (optional)`}
                                                            :
                                                        </Label>
                                                        <FormGroup floating>
                                                            <Field
                                                                className={`custom-select  `}
                                                                name="PolicyService"
                                                                options={
                                                                    cityOptions
                                                                }
                                                                component={
                                                                    CustomSelect
                                                                }
                                                                placeholder="Select Tests and Surveys"
                                                                isMulti={true}
                                                            />
                                                            
                                                        </FormGroup>
                                                    </FormGroup>
                                                </Col>
                </Row>
                <Row>
                    <Col md="4" className="mb-3">
                        <Label check>
                            Notification Tone{" "}
                            <span className="txt-danger">*</span>
                        </Label>
                        <Field
                            as="select"
                            name="notificationtone"
                            className={`form-control ${
                                submitErrors &&
                                `${
                                    errors.notificationtone
                                        ? "is-invalid"
                                        : "is-valid"
                                }`
                            }`}
                            onChange={(event) =>
                                handleToneChange(event, setFieldValue)
                            }>
                            <option value={""}>Please select</option>
                            <option value={"Alert-1"}>Alert-1</option>
                            <option value={"Alert-2"}>Alert-2</option>
                            <option value={"Alert-3"}>Alert-3</option>
                            <option value={"Alert-4"}>Alert-4</option>
                            <option value={"Alert-5"}>Alert-5</option>
                            <option value={"Chime-1"}>Chime-1</option>
                            <option value={"Chime-2"}>Chime-2</option>
                            <option value={"Chime-3"}>Chime-3</option>
                            <option value={"Chime-4"}>Chime-4</option>
                            <option value={"Chime-5"}>Chime-5</option>
                        </Field>
                        <ErrorMessage
                            name="notificationtone"
                            component="span"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <strong>Shipment out of the City</strong>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="mb-3">
                        <Label check>Ship to states in your country</Label>
                        <Country
                            name="shipmentYourCountry"
                            isMulti={true}
                            placeholder="Select"
                        />
                    </Col>
                    <Col md="4" className="mb-3">
                        <Label check>Ship to other countries</Label>
                        <Country
                            name="shipOtherCountry"
                            isMulti={true}
                            placeholder="Select"
                        />
                    </Col>
                    <Col md="4" className="mb-3">
                        <Label check>
                            24 hours/7 Days (Online Store){" "}
                            <span className="txt-danger">*</span>
                        </Label>
                        <Row>
                            <Col sm="auto" className=" mb-2 ms-md-4">
                                <FormGroup
                                    className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                                        hideButton === true
                                            ? "transform-scale"
                                            : ""
                                    }`}
                                    check>
                                    <Label
                                        className={`mb-0 ${
                                            submitErrors &&
                                            `${
                                                errors.formType
                                                    ? "text-danger"
                                                    : "text-success"
                                            }`
                                        }`}
                                        for="hoursDay1"
                                        check>
                                        <Input
                                            id="hoursDay1"
                                            name="hoursday"
                                            value="zero"
                                            type="checkbox" // Changed to 'radio'
                                            onChange={() =>
                                                handleHoursDayRadio(true)
                                            }
                                            className="form-check-input"
                                        />{" "}
                                        <H6 className="ms-2">Yes</H6>
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="auto" className=" mb-2 ms-md-4">
                                <FormGroup
                                    className={`form-check form-check-inline popup_rediobtn  radio-primary mt-2 px-2 ${
                                        hideButton === false
                                            ? "transform-scale"
                                            : ""
                                    }`}
                                    check>
                                    <Label
                                        className={`mb-0 ${
                                            submitErrors &&
                                            `${
                                                errors.formType
                                                    ? "text-danger"
                                                    : "text-success"
                                            }`
                                        }`}
                                        for="hoursDay2"
                                        check>
                                        <Input
                                            id="hoursDay2"
                                            name="hoursday"
                                            value="zero"
                                            type="checkbox" // Changed to 'radio'
                                            onChange={() =>
                                                handleHoursDayRadio(false)
                                            }
                                            className="form-check-input"
                                        />{" "}
                                        <H6 className="ms-2">No</H6>
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                 
                <SharingSection CoManagement={false} viewOnly={true}  errors={errors} setFieldValue={setFieldValue} values={values} ImportInviteeList={true} />
                <Row className="mt-3">
                    <Col sm="auto" className="mb-3">
                        <Btn
                            color="primary"
                            type="submit"
                            onClick={() => setSubmitError(true)}>
                            Save
                        </Btn>
                    </Col>
                    {!hideButton && (
                        <Col sm="auto" className="mb-3">
                            <span className="btn btn-primary">
                                Save and Add a Schedule
                            </span>
                        </Col>
                    )}
                </Row>
                </div>
            </Form>
            <Popup
                title="Import Bulk Branches"
                openModalToggle={openQestionModalToggle}
                isOpen={openQestionModal}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                BodyClassName="pt-1"
                largesize="modal-custom">
                <ImportBulk />
            </Popup>
            <Popup
                                            title="Add Frequently Asked Questions"
                                            openModalToggle={openFaqModalToggle}
                                            isOpen={openFaqModal}
                                            size={`lg`}
                                            backdrop={true}
                                            CommonFooter={true}
                                            isClose={false}
                                            CommonFooter={false}
                                            BodyClassName="pt-1"
                                            largesize="modal-custom">
                                            <div className="mx-2 mt-2 mb-3 px-2">
                                                <Formik
                                                    initialValues={
                                                        scheduleFormInitialValue
                                                    }
                                                    onSubmit={handleSubmit}
                                                    validationSchema={
                                                        scheduleFormValidationSchema
                                                    }>
                                                    {({ errors, values }) => (
                                                        <AddFaqForm
                                                            values={values}
                                                        />
                                                    )}
                                                </Formik>
                                            </div>
                                        </Popup>
        </>
    );
};

export default AddManageBranchesForm;
