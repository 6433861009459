import React, { useState } from 'react'
import { Btn, H5, LI, UL } from '../../../AbstractElements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { ErrorMessage, Field, Formik } from 'formik'
import { manageBranchFormInitialValue, manageBranchFormValidationSchema } from '../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import Country from '../../../Component/CommonInput/Country'
import TitleInput from '../../../Component/CommonInput/TitleInput'
import TagsInput from '../../../Component/CommonInput/TagsInput'
import ScheduleType from '../../../Component/CommonInput/ScheduleType'
import CustomSelect from '../../../Component/CommonInput/CustomSelect'
import DescriptionInput from '../../../Component/CommonInput/DescriptionInput'
import { Keywords, PostContent } from '../../../utils/Constant'
import LongDescription from '../../../Component/CommonInput/LongDescription'
import City from '../../../Component/CommonInput/City'
import Branches from '../../../Component/CommonInput/Branches'
import Popup from '../../../Component/MasterPopup/Popup'
import AdditionalBranchCountry, { AdditionalCountryinitialValues, AdditionalCountryvalidationSchema } from '../AdditionalBranchCountry/AdditionalBranchCountry'

function Notes({branchountry,PriceDay,Price ,oneBranch,post,city,branches,notsectionlist }) {
     const [confirmedBranches, setConfirmedBranches] = useState();
     const [openAdditonalModal, setopenAdditonalModal] = useState();
     const openAdditonalModalToggle = () => setopenAdditonalModal(!openAdditonalModal);
     const handleSubmit = () => {};
     const branchesOptions = [
        {
            label: "Branch 1",
            value: "branch1",
            country: "USA",
            state: "California",
            city: "Los Angeles",
        },
        {
            label: "Branch 2",
            value: "branch2",
            country: "USA",
            state: "Arizona",
            city: "Phoenix",
        },
        {
            label: "Branch 3",
            value: "branch3",
            country: "USA",
            state: "New York",
            city: "New York City",
        }
    ];
        const handleConfirm = (values) => {
            console.log("Selected Values:", values.branches);
    
            if (Array.isArray(values.branches)) {
                // Match selected values with full branch details
                const selectedBranches = values.branches.map(branchValue =>
                    branchesOptions.find(option => option.value === branchValue) // Find the branch
                ).filter(Boolean); // Remove undefined values
    
                setConfirmedBranches(selectedBranches);
            }
        };

 const options = [
        { value: '5', label: '30 Days, 5 USD' },
        { value: '12', label: '90 Days (4 USD/ 30 Days) if you pay 12 USD' },
        { value: '18', label: '180 Days (3 USD/ 30 Days) if you pay 18 USD' },
        { value: '24', label: 'Annually (2 USD/ 30 Days) if you pay 24 USD' }, 
      ];
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const [images, setImages] = useState([] as any);
    const [imageMaxLength, setImageMaxLength] = useState(false);
         const [postType, setPostType] = useState<number | null>(1);  
         const onImageChange = (e: any) => {
          let totalfiles = e.target.files.length;
          if (totalfiles < 1) return;
          else if (totalfiles > 10) {
              setImages([]);
              setImageMaxLength(true);
              e.target.value = "";
              return;
          } else if (totalfiles > 0 && totalfiles <= 10) {
              setImages([...e.target.files]);
          }
  
          images.forEach((image: any) => {
              if (imageURLS.length < 10) {
                  imageURLS.push(URL.createObjectURL(image));
              }
          });
      };
      const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];
    const scheduleOptions = [
        { label: "Full Time", value: "full-time" },
        { label: "Part Time", value: "part-time" },
        { label: "Freelancing", value: "freelancing" },
    ];
  return (
    <div className='border rounded border-danger p-2  mx-2 my-3'>
                                   
                                   <UL className='list-content '>
                                   <Formik
              initialValues={manageBranchFormInitialValue}
              // onSubmit={handleSubmit}
              validationSchema={manageBranchFormValidationSchema}
            >
              {({ errors, values,setFieldValue }) => (
    <Form className="form-wizard ">
       <LI className="d-flex "> 
    <Row className="w-100">
      {branchountry &&  <Col  md='4' className="mb-3">
          <Label check>
            Branch Country <span className="txt-danger">*</span>
          </Label>
          <Country
            name="branchcountry"
            placeholder="Select"
            isMulti={false}
            // className={`${
            //   // submitErrors &&
            //   // `${errors.branchcountry ? "is-invalid" : "is-valid"}`
            // }`}
          />
          <ErrorMessage
            name="branchcountry"
            component="span"
            className="invalid-feedback"
          />
          <Btn  className="ps-0  d-flex" onClick={openAdditonalModalToggle}>
                 <i className="bi bi-plus-circle mx-1 text-warning"></i>
            Add an Additional Branch Country  </Btn>
        </Col>}
     <Col sm='4'> </Col>
     <Col sm='12'>
     {oneBranch &&   <p className='mb-2'> {`Please add at least one branch first to be able to use this form`}</p>}
     </Col>

       {city && <Col sm="4" className="mb-3">
                                            <Label check>
                                                City{" "}
                                                <span className="txt-danger">
                                                    *
                                                </span>
                                            </Label>
                                            <City
                                                name="city"
                                                countryId={values.country}
                                                stateId={values.state} // Pass the selected state ID
                                                isMulti={true}
                                                placeholder="Select"
                                                className={`${
                                                    submitErrors &&
                                                    `${
                                                        errors.city
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                    }`
                                                }`}
                                            />
                                            <ErrorMessage
                                                name="city"
                                                component="span"
                                                className="invalid-feedback"
                                            />
                                        </Col>}
                                       
                      
       
                <Col sm="4" className="mb-3">
                                <Label check>
                                    Branches{" "}
                                    <span className="txt-danger">*</span>
                                </Label>
                                <Branches
                                    name="branches"
                                    placeholder="Select"
                                    isMulti={true}
                                    option={branchesOptions}
                                />
                                <ErrorMessage
                                    name="branches"
                                    component="span"
                                    className="invalid-feedback"
                                />
                                <Button color="primary" className="mt-2" onClick={()=>handleConfirm(values)}>
                Confirm
            </Button>
            {confirmedBranches && confirmedBranches.length > 0 && (
                <div className="mt-2">
                   <p> <strong>Selected Branches:</strong></p>
                    {confirmedBranches.map((branch, index) => (
                        <p key={index}>
                            <strong>{branch.label}:</strong> {branch.country}, {branch.state}, {branch.city}
                        </p>
                    ))}
                </div>
            )}
                            </Col>
                       
             {post &&   <Col sm="4">
                                                    <FormGroup>
                                                        <Label check>
                                                            {`Post Type`}{" "}
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </Label>
                                                        <div className="px-2">
                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_upload"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            1
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        1
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_upload">
                                                                    <H5>
                                                                        Article
                                                                        or Blog
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadc"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            2
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        2
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadc">
                                                                    <H5>
                                                                        Customer’s
                                                                        Wish of
                                                                        a
                                                                        Product
                                                                        or a
                                                                        Service
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadj"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            3
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        3
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadj">
                                                                    <H5>
                                                                        Job
                                                                        Opening:
                                                                        you want
                                                                        to hire
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadn"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            4
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        4
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadn">
                                                                    <H5>
                                                                        Notification
                                                                        Screen
                                                                        on My
                                                                        Media
                                                                        Center
                                                                    </H5>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                   

                                                    {postType === 1 && (
                                                        <>
                                                        <FormGroup>
                                                          
                                                          <TitleInput title='Title' values={values.Title} name="Title"  placeholder="Title"/>  
                                                  
                                                                                                      </FormGroup>
                                                  
                                                                                                      <Label check>Image</Label>
                                                                                                      <FormGroup>
                                                                                                          <Field
                                                                                                              className={`form-control ${
                                                                                                                  submitErrors &&
                                                                                                                  `${
                                                                                                                      errors.image
                                                                                                                          ? "is-invalid"
                                                                                                                          : "is-valid"
                                                                                                                  }`
                                                                                                              }`}
                                                                                                              name="image"
                                                                                                              type="file"
                                                                                                              multiple
                                                                                                              accept="image/*"
                                                                                                              onChange={
                                                                                                                  onImageChange
                                                                                                              }
                                                                                                          />
                                                                                                          {images.length > 10 ? (
                                                                                                              <span className="text-danger">
                                                                                                                  Max limit 10
                                                                                                              </span>
                                                                                                          ) : (
                                                                                                              ""
                                                                                                          )}
                                                                                                          <ErrorMessage
                                                                                                              name="image"
                                                                                                              component="span"
                                                                                                              className="invalid-feedback"
                                                                                                          />
                                                                                                          <ul>
                                                                                                              Total Image :{" "}
                                                                                                              {images.length}
                                                                                                          </ul>
                                                                                                      </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                    <DescriptionInput  name='description' placeholder="Description/Summary" values={values.description} lable='Description/Summary (optional)'/>
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <Col
                                                                sm="12"
                                                                md="12">
                                                                <FormGroup> 
                                                                    <LongDescription lable={PostContent} name="aarticalDescription" value={values.aarticalDescription} id="editor_container"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </>
                                                    )}
                                                    {postType === 2 && (
                                                        <>
                                                        <FormGroup>
                                                          
                                                          <TitleInput title='Title' values={values.customer_Title} name="customer_Title"  placeholder="Title"/>  
                                                  
                                                                                                      </FormGroup>
                                                  
                                                                                                      <Label check>Image</Label>
                                                                                                      <FormGroup>
                                                                                                          <Field
                                                                                                              className={`form-control ${
                                                                                                                  submitErrors &&
                                                                                                                  `${
                                                                                                                      errors.image
                                                                                                                          ? "is-invalid"
                                                                                                                          : "is-valid"
                                                                                                                  }`
                                                                                                              }`}
                                                                                                              name="image"
                                                                                                              type="file"
                                                                                                              multiple
                                                                                                              accept="image/*"
                                                                                                              onChange={
                                                                                                                  onImageChange
                                                                                                              }
                                                                                                          />
                                                                                                          {images.length > 10 ? (
                                                                                                              <span className="text-danger">
                                                                                                                  Max limit 10
                                                                                                              </span>
                                                                                                          ) : (
                                                                                                              ""
                                                                                                          )}
                                                                                                          <ErrorMessage
                                                                                                              name="image"
                                                                                                              component="span"
                                                                                                              className="invalid-feedback"
                                                                                                          />
                                                                                                          <ul>
                                                                                                              Total Image :{" "}
                                                                                                              {images.length}
                                                                                                          </ul>
                                                                                                      </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormGroup>
                                                                    <Label
                                                                        check>
                                                                        Product
                                                                        or
                                                                        Service
                                                                        Name{" "}
                                                                        <span className="txt-danger">
                                                                            *
                                                                        </span>
                                                                    </Label>
                                                                    <Field
                                                                        name={` excatNumber`}
                                                                        as="select"
                                                                        className={`form-control  `}>
                                                                        <option
                                                                            value={
                                                                                ""
                                                                            }>
                                                                            select
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                "equal_among_winners"
                                                                            }>
                                                                            value
                                                                            1
                                                                        </option>
                                                                        <option
                                                                            value={
                                                                                "separate_prizes_for_each_winner"
                                                                            }>
                                                                            value
                                                                            2
                                                                        </option>
                                                                    </Field>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup> 
                                                                    <DescriptionInput  name='customer_description' placeholder="Description " values={values.customer_description} lable='Description'/> 
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <FormGroup>
                                                                <Label check>
                                                                    External
                                                                    Links{" "}
                                                                    <span className="txt-danger">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                <Field
                                                                    name="externallink"
                                                                    type="text"
                                                                    placeholder="Enter your external link"
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.externallink
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="externallink"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Vender’s Country`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="country"
                                                                    placeholder="Any Country’"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Desired Budget`}
                                                                    :
                                                                </Label>
                                                                
                                                                <ErrorMessage
                                                                    name="desired_budget"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Max Budget`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    type="number"
                                                                    name="max_budget"
                                                                    placeholder="Enter max budget"
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.max_budget
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="max_budget"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                        </>
                                                    )}
                                                    {postType === 3 && (
                                                        <>
                                                        <FormGroup>
                                                          
                                                          <TitleInput title='Title' values={values.job_Title} name="job_Title"  placeholder="Title"/>  
                                                  
                                                                                                      </FormGroup>
                                                  
                                                                                                      <Label check>Image</Label>
                                                                                                      <FormGroup>
                                                                                                          <Field
                                                                                                              className={`form-control ${
                                                                                                                  submitErrors &&
                                                                                                                  `${
                                                                                                                      errors.image
                                                                                                                          ? "is-invalid"
                                                                                                                          : "is-valid"
                                                                                                                  }`
                                                                                                              }`}
                                                                                                              name="image"
                                                                                                              type="file"
                                                                                                              multiple
                                                                                                              accept="image/*"
                                                                                                              onChange={
                                                                                                                  onImageChange
                                                                                                              }
                                                                                                          />
                                                                                                          {images.length > 10 ? (
                                                                                                              <span className="text-danger">
                                                                                                                  Max limit 10
                                                                                                              </span>
                                                                                                          ) : (
                                                                                                              ""
                                                                                                          )}
                                                                                                          <ErrorMessage
                                                                                                              name="image"
                                                                                                              component="span"
                                                                                                              className="invalid-feedback"
                                                                                                          />
                                                                                                          <ul>
                                                                                                              Total Image :{" "}
                                                                                                              {images.length}
                                                                                                          </ul>
                                                                                                      </FormGroup>
                                                            <Col
                                                                sm="4"
                                                                className="mb-3">
                                                                <Label check>
                                                                    A Previous
                                                                    Job Opening
                                                                    as a
                                                                    Reference
                                                                    (optional){" "}
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-control"
                                                                    name="reference-coupon">
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }>
                                                                        Select a
                                                                        Reference
                                                                    </option>
                                                                </Field>
                                                            </Col>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <div className=" col-form-Label">
                                                                    {
                                                                        "Required Skills (keywords)"
                                                                    }
                                                                    :
                                                                    <TagsInput
                                                                        name="skillskeywords"
                                                                        placeholder="Add keywords"
                                                                        className="w-100"
                                                                        setFieldValue={
                                                                            setFieldValue
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Minimum Years of experience`}{" "}
                                                                    <span className=" ">
                                                                        *
                                                                    </span>
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    type="text"
                                                                    name="employexperience"
                                                                    placeholder={`Minimum Years of experience`}
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.employexperience
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="employexperience"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Hiring Duration`}{" "}
                                                                    <span className=" ">
                                                                        *
                                                                    </span>
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    name="employjobLocationType"
                                                                    className="form-control">
                                                                    <option value="">{`1 Day`}</option>
                                                                    <option value="remote">{`Up to 1 Week `}</option>
                                                                    <option value="specific-address">{`1 Week to 1 Month`}</option>
                                                                    <option value="specific-address">{`1 Month to 3 Months `}</option>
                                                                    <option value="specific-address">{`3 Months to 6 Months `}</option>
                                                                    <option value="specific-address">{`6 Months to 1 Year`}</option>
                                                                    <option value="specific-address">{`1 Year to 2 Years`}</option>
                                                                    <option value="specific-address">{`Long Term`}</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            {/* Schedule Type */}

                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Schedule Type`}
                                                                    :
                                                                </Label>
                                                                <ScheduleType
                                                                    name="employjobLocationType"
                                                                    placeholder="Select Schedule Type"
                                                                    Options={
                                                                        scheduleOptions
                                                                    }
                                                                    className={`${
                                                                        errors &&
                                                                        `${
                                                                            errors.employjobLocationType
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            {/* salary */}
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Salary`}:
                                                                </Label>
                                                                <Field
                                                                    type="text"
                                                                    name="employsalary"
                                                                    placeholder={`Salary`}
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.employperiodMonths
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                                <ErrorMessage
                                                                    name="employsalary"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                            {/* Job Location Type */}
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Job Location Type`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    name="employjobLocationType"
                                                                    className="form-control">
                                                                    <option value="">{`Select Job Location Type`}</option>
                                                                    <option value="remote">{`Remote`}</option>
                                                                    <option value="specific-address">{`Specific Address`}</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="employjobLocationType"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Job Location`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="jobcountry"
                                                                    placeholder="Any Country’"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="jobcountry"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                  
                                                                    <DescriptionInput  name='job_description' placeholder="Description " values={values.job_description} lable='Description'/>

                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    {postType === 4 && (
                                                        <>
                                                        <FormGroup>
                                                          
                                                          <TitleInput title='Title' values={values.notification_Title} name="notification_Title"  placeholder="Title"/>  
                                                  
                                                                                                      </FormGroup>
                                                  
                                                                                                      <Label check>Image</Label>
                                                                                                      <FormGroup>
                                                                                                          <Field
                                                                                                              className={`form-control ${
                                                                                                                  submitErrors &&
                                                                                                                  `${
                                                                                                                      errors.image
                                                                                                                          ? "is-invalid"
                                                                                                                          : "is-valid"
                                                                                                                  }`
                                                                                                              }`}
                                                                                                              name="image"
                                                                                                              type="file"
                                                                                                              multiple
                                                                                                              accept="image/*"
                                                                                                              onChange={
                                                                                                                  onImageChange
                                                                                                              }
                                                                                                          />
                                                                                                          {images.length > 10 ? (
                                                                                                              <span className="text-danger">
                                                                                                                  Max limit 10
                                                                                                              </span>
                                                                                                          ) : (
                                                                                                              ""
                                                                                                          )}
                                                                                                          <ErrorMessage
                                                                                                              name="image"
                                                                                                              component="span"
                                                                                                              className="invalid-feedback"
                                                                                                          />
                                                                                                          <ul>
                                                                                                              Total Image :{" "}
                                                                                                              {images.length}
                                                                                                          </ul>
                                                                                                      </FormGroup>
                                                            <Col
                                                                sm="4"
                                                                className="mb-3">
                                                                <Label check>
                                                                    {" "}
                                                                    A Previous
                                                                    Notification
                                                                    Screen as a
                                                                    Reference
                                                                    (optional){" "}
                                                                </Label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-control"
                                                                    name="reference-coupon">
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }>
                                                                        Select a
                                                                        Reference
                                                                    </option>
                                                                </Field>
                                                            </Col>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Target Countries`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="targetcountry"
                                                                    placeholder="Select"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup> 
                                                                       <DescriptionInput  name='notification_description' placeholder="Description/Summary" values={values.notification_description} lable='Description'/>

                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Country`}:
                                                                </Label>
                                                                <Country
                                                                    name="country"
                                                                    placeholder="Any Country’"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        true
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                        </>
                                                    )}

                                                    {postType === 3 ? (
                                                        ""
                                                    ) : (
                                                        <FormGroup>
                                                            <div className=" col-form-Label">
                                                                {Keywords}:
                                                                <TagsInput
                                                                    name="keywords"
                                                                    placeholder="Add keywords"
                                                                    className="w-100"
                                                                    setFieldValue={
                                                                        setFieldValue
                                                                    }
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    )}
                                                    {/* <Sharing/> this is used in future Dont delete */}

                                                </Col>}
                                                <Col sm='4'>
                                                <LI> {`Wallet Balance: 53.00 USD`}</LI>
                                     {notsectionlist ? "" : ( <> <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Left Monthly Balance of FREE Submission: 1/5`}</LI>
                                       <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Price (30 Days) for additional submission: 3 USD (for 3 additional submissions)`}</LI>
                                       <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Left Balance of PAID Submission: 2/3`}</LI>
                                       <Formik
              initialValues={manageBranchFormInitialValue}
              // onSubmit={handleSubmit}
              validationSchema={manageBranchFormValidationSchema}
            >
              {({ errors, values,setFieldValue }) => (
    <Form className="form-wizard ">
       <LI className="d-flex ">
       <FontAwesomeIcon icon={fas.faCircle} className='pe-2 mt-1' size="xs"/>
    <Row className="w-100"> 
                                       <Col md='12' className="mb-3">
                <Label  check>1Webbb Service Price:</Label>
                    <Field as="select" name="amount" className="form-control">
                      <option value="">Select...</option>
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                </Col> 
                </Row>
        </LI>
        </Form>)}
                </Formik></>)}
                                                </Col>
        </Row>
        </LI>
        </Form>)}
                </Formik>
                                       
                           
                                        </UL>
                 
                                        <Popup
                                            title="Publish Online"
                                            openModalToggle={
                                                openAdditonalModalToggle
                                            }
                                            isOpen={openAdditonalModal}
                                            size={`lg`}
                                            backdrop={true}
                                            CommonFooter={true}
                                            isClose={false}
                                            CommonFooter={false}
                                            BodyClassName="pt-1"
                                            largesize="modal-custom">
                                           <Formik
      initialValues={AdditionalCountryinitialValues}
      validationSchema={AdditionalCountryvalidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <AdditionalBranchCountry />
                                                  
                                                )}
                                            </Formik>
                                        </Popup>
                               </div>
  )
}

export default Notes