// import React from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import { BranchsAction } from '../../../../../Data/Tools/ManageContents/ManageContentButtons';
// interface BranchSubMenuProps {
//     sliced: string;
// }
// const BranchSubMenu : React.FC<BranchSubMenuProps> = ({ sliced }) => {
//     const location = useLocation();
//     const currentPath = location.pathname;

//     // Function to determine if the current path is the exact path or if it starts with the link's path
//     const isActive = (path) => {
//         return currentPath === `${process.env.PUBLIC_URL}/${path}` ||
//                currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`) && !currentPath.includes(`${process.env.PUBLIC_URL}/${path}/`);
//     };
//     console.log('Last three items:', BranchsAction.slice(0, -4));
//     return (
//         <div className='d-flex flex-wrap gap-2'>
//             {BranchsAction.slice(0,sliced).map((material, index) => {
//                 const activeClass = isActive(material.path) ? 'bg-gray text-white' : 'bg-dark text-light';
//                 return (
//                     <Link
//                         to={`${process.env.PUBLIC_URL}/${material.path}`}
//                         key={index}
//                         className={`border rounded p-2 ps-2 pe-2 ${activeClass}`}
//                     >
//                         <span>
//                             {material.actionName}
//                         </span>
//                     </Link>
//                 );
//             })}
//         </div>
//     );
// }

// export default BranchSubMenu;
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BranchsAction } from "../../../../../Data/Tools/ManageContents/ManageContentButtons";
import {
    Button,
    Col,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import AttendanceHistory from "../../../../../Component/Application/Calender/DragCalendar/AttendanceHistory";
import {
    branchPublicContactFormInitialValue,
    BranchPublicContactFormValidationProp,
    branchPublicContactFormValidationSchema,
} from "../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { ErrorMessage, Field, Formik } from "formik";
import City from "../../../../../Component/CommonInput/City";
import Country from "../../../../../Component/CommonInput/Country";
import State from "../../../../../Component/CommonInput/State";
import Branches from "../../../../../Component/CommonInput/Branches";
import { Btn, H4, H5 } from "../../../../../AbstractElements";
import CommonSwitchSpan from "../../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import CommonModal from "../../../../../Component/Ui-Kits/Modal/Common/CommonModal";
import OnlineAssistantForm from "../../../../../Component/App/AccountSettings/OnlineAssistant/OnlineAssistantForm";
import Notes from "../../../Notes/Notes";
import ManageStorePopup from "../../../../../Layout/Sidebar/ManageStorePopup";

interface BranchSubMenuProps {
    sliced: number; // Changed to number to align with `slice` type
}

const BranchSubMenu: React.FC<BranchSubMenuProps> = ({ sliced }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    // State to manage modal visibility
    const [showModal, setShowModal] = useState(false);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const [showBusinessBranch, setShowBusinessBranch] = useState(true);
    const [showBusinessLocation, setShowBusinessLocation] = useState(true);
    const [showBranchPhotoLocation, setShowBranchPhotoLocation] = useState(true);
    const [isAssistantModalOpen, setIsAssistantModalOpen] = useState(false);
    const openAssistantModalToggle = () => {
        console.log("Hello");
        setIsAssistantModalOpen(!isAssistantModalOpen);
      };
    const handleSubmit = (values: BranchPublicContactFormValidationProp) => {
        setSubmitError(false);
    };
    const handleModalOpen = () => setShowModal(true);
    const handlePhotographerModalOpen = () => setShowPhotoModal(true);
    const handleModalClose = () => setShowModal(false);
    const handlePhotographerModalClose = () => setShowPhotoModal(false);

    // Function to determine if the current path is the exact path or if it starts with the link's path
    const isActive = (path: string) => {
        return (
            currentPath === `${process.env.PUBLIC_URL}/${path}` ||
            (currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`) &&
                !currentPath.includes(`${process.env.PUBLIC_URL}/${path}/`))
        );
    };

    // Check if the current page is "Manage Branch" page
    const isManageBranchPage = currentPath.includes(
        "tools/manage-online-store/manage-branches"
    );

    return (
        <div className="d-flex flex-wrap gap-2"> 
            {/* <Link
                         to={`${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/add-new`}
                         className={  "bg-dark text-white  py-2 ms-2 px-2 d-inline-block rounded mt-2"  }
                        
                       >
                         Add a New Branch
                       </Link> */}
            {BranchsAction.slice(0, sliced).map((material, index) => {
                const activeClass = isActive(material.path)
                    ? "bg-gray text-white"
                    : "bg-dark text-light";

                return   material.actionName === "Verfied Branch Tags" || material.actionName === "Branch Photographer" || material.actionName === "Add an Account Assistant" ? (
                    // isManageBranchPage && (
                      <>
                        <Btn
                            className="border rounded p-2 ps-2 pe-2 bg-dark text-light text-white"
                            onClick={
                                material.actionName === "Verfied Branch Tags"
                                  ? handleModalOpen
                                  : material.actionName === "Add an Account Assistant" ? openAssistantModalToggle :  handlePhotographerModalOpen
                              }
                            key={index} // Ensure each element has a unique key
                        >
                            <span
                                style={{
                                    lineHeight: "21px",
                                    fontFamily: "Lexend",
                                }}>
                                    { material.actionName}
                               
                            </span>
                        </Btn>
                        {/* <button
                            className="border rounded p-2 ps-2 pe-2 bg-dark text-light text-white"
                            onClick={() => handlePhotographerModalOpen()}
                            key={index} // Ensure each element has a unique key
                        >
                            <span
                                style={{
                                    lineHeight: "21px",
                                    fontFamily: "Lexend",
                                }}>
                                Branch Photographer
                            </span>
                        </button> */}
                        </>
                    // )
                ) : (
                    <Link
                        to={`${process.env.PUBLIC_URL}/${material.path}`}
                        key={index}
                        className={`border rounded p-2 ps-2 pe-2 ${activeClass}`}>
                        <span>{material.actionName}</span>
                    </Link>
                );
            })}

            {/* Show "Verify Branch" button only on Manage Branch page */}

            <Modal isOpen={showModal} toggle={handleModalClose} size="xl">
                <ModalHeader toggle={handleModalClose}>
                Verfied Branch Tags (1Webbb Service)
                </ModalHeader>
                <ModalBody className="overflow-y-auto">
                <Notes notsectionlist={true} branchountry={true} city={true} branches={true} PriceDay={true} Price={false}  oneBranch={true} />

                    {/* You can display additional data here */}
                    <Formik
                        initialValues={branchPublicContactFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={
                            branchPublicContactFormValidationSchema
                        }>
                        {({ errors, values, setFieldValue }) => {
                            
                            
                            return (
                                <Form className="form-wizard mx-3">
                                    
                                    <Row>
                                        <Col sm="4" className="mt-2">
                                            <Label check>
                                            Verified Business Branch License Tags:{" "} 
                                                <small className="d-block">We display ‘Verified License’ tag under your branch name, after we verify with the government officials the business license you provide us.</small>
                                            </Label>
                                            <div className="flex-shrink-0 icon-state switch-outline">
                                            <CommonSwitchSpan
                                            color={"primary"}
                                            name="showBusinessBranch"
                                            checked={showBusinessBranch}
                                            onChange={() => setShowBusinessBranch(!showBusinessBranch)}
                                        />
                                            </div>
                                        </Col>
                                        {showBusinessBranch && (
                                    <>
                                        <Col sm="4" className="mb-3">
                                            <Label check>
                                                Price (30 Days per Branch):{" "}
                                                <b>10 USD</b>
                                            </Label>
                                        </Col>
                                        <Col sm="4" className="mb-3">
                                            <Label check>Total: </Label>
                                            <b>{values.branches.length * 10}</b>
                                        </Col>
                                    </>
                                )}
                                    </Row>
                                    <Row>
                                        <Col sm="4" className="mt-2">
                                            <Label check>
                                            Verified Branch Location Tags:{" "} 
                                                <small className="d-block">We display ‘Verified Location’ tag under your visited branch name, after we visit your location in various random times to verify your location.</small>
                                            </Label>
                                            <div className="flex-shrink-0 icon-state switch-outline">
                                            <CommonSwitchSpan
                                            color={"primary"}
                                            name="showBusinessLocation"
                                            checked={showBusinessLocation}
                                            onChange={() => setShowBusinessLocation(!showBusinessLocation)}
                                        />
                                            </div>
                                        </Col>
                                        {showBusinessLocation && (
                                    <>
                                        <Col sm="4" className="mb-3">
                                            <Label check>
                                                Price (30 Days per Branch):{" "}
                                                <b>5 USD</b>
                                            </Label>
                                        </Col>
                                        <Col sm="4" className="mb-3">
                                            <Label check>Total: </Label>
                                            <b>{values.branches.length * 5}</b>
                                        </Col>
                                    </>
                                )}
                                       
                                        <Col sm="4" className="mb-3 mt-2">
                                            <Label check>
                                                Subtotal:{" "} <b>{(showBusinessBranch ? values.branches.length * 10 : 0) +
 (showBusinessLocation ? values.branches.length * 5 : 0)}</b>
                                                
                                            </Label>
                                            
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
            <Modal isOpen={showPhotoModal} toggle={handlePhotographerModalClose} size="xl" className=' modal-dialog  modal-dialog-scrollable modal-custom  modal-xl modal-dialog-centered'>
                <ModalHeader toggle={handlePhotographerModalClose}>
                Branch Photographer (taking photos at your location - 1Webbb Service)
                </ModalHeader>
                <ModalBody>
                <Notes notsectionlist={true} branchountry={true} city={true} branches={true} PriceDay={true} Price={false}  oneBranch={true} />


                    {/* You can display additional data here */}
                    <Formik
                        initialValues={branchPublicContactFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={
                            branchPublicContactFormValidationSchema
                        }>
                        {({ errors, values, setFieldValue }) => {
                            return (
                                <Form className="form-wizard mx-3">
                                     
                                        <Row className="mb-2">
                                            <H5>
                                            Branch Photographer (taking photos at your location):{" "} 
                                            </H5> 
                                            <span>
                                            A well trained Assistant will visit the business location to take professional photos of your products or services
                                            </span>

                                            <Col sm="4" className="mt-3">
                                                <div className="flex-shrink-0 icon-state switch-outline">
                                                    <CommonSwitchSpan
                                                        color={"primary"}
                                                        name="showCalculator" 
                                            checked={showBranchPhotoLocation}

                                            onChange={() => setShowBranchPhotoLocation(!showBranchPhotoLocation)}

                                                    />
                                                </div>
                                            </Col>
                                            {showBranchPhotoLocation && ( 
                                                <>
                                                 <Col sm="4" className="mb-3 mt-3">
                                                <Label check>
                                                    Price (per Session per
                                                    Branch):
                                                </Label>
                                                <Field name="photoSessionPrice" className="form-control">
                                {({ field, form }) => (
                                    <select
                                        {...field}
                                        id="photoSessionPrice"
                                        className={`form-select `}
                                        onChange={(e) => {
                                            form.setFieldValue(
                                                "photoSessionPrice",
                                                parseInt(e.target.value, 10)
                                            );
                                        }}>
                                        <option value={10}>
                                            10 USD (4 hours)
                                        </option>
                                        <option value={20}>
                                            20 USD (8 hours)
                                        </option>
                                        <option value={30}>
                                            30 USD (15 hours)
                                        </option>
                                    </select>
                                )}
                            </Field>
                                            </Col>
                                            <Col sm="4" className="mb-3 mt-3">
                                                <Label check>Total: <b>
                                            {values.branches.length *
                                                values.photoSessionPrice}{" "}
                                            USD
                                        </b></Label>
                                            </Col>
                                            
                                            </>
                                        )}
                                        <Col sm="4" className="mt-3">
                                            <Label>Auto Renew (monthly)</Label>
                                                <div className="flex-shrink-0 icon-state switch-outline">
                                                    <CommonSwitchSpan
                                                        color={"primary"}
                                                        name="showCalculator"
                                                        defaultChecked
                                                    />
                                                </div>
                                            </Col>
                                        </Row> 
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
            <CommonModal
                isOpen={isAssistantModalOpen}
                toggle={openAssistantModalToggle}
                modalBodyClassName="  modal-custom"
                largesize="modal-custom"
                sizeTitle="Add an Account Assistant"
                AdShow={false} 
                size="md">
        <div className="mx-0 my-2">
        <Notes branchountry={true} city={true} branches={true} PriceDay={true} Price={false}  oneBranch={true} />

            <div className="mx-2">
            <OnlineAssistantForm openAssistantModalToggle={openAssistantModalToggle}/>
                </div>
            </div>
            </CommonModal>
        
        </div>
    );
};

export default BranchSubMenu;
