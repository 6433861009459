import React, { useState } from 'react'
import Popup from '../../MasterPopup/Popup'
import { AddNewIdeaStoryAlbum } from '../../../utils/Constant'
import { Col, Input, Label } from 'reactstrap'
import { H5 } from '../../../AbstractElements'
import { manageAlbumFormInitialValue, manageAlbumFormValidationSchema, visualIdeainitialValues, visualIdeavalidationSchema } from '../../../Data/Tools/ManageContents/FormControlsValidation'
import { Formik } from 'formik'
import AddManageMyAlbumsForm from '../../Tools/ManageContents/ManageMyAlbums/AddManageMyAlbumsForm'
import ServerErrorMessages from '../../CommonMessage/ServerErrorMessages'
import AddVisualideaForm from '../../Tools/ManageContents/ManageMyCollections/AddVisualideaForm'

function AddVisualIdeaModal({isOpen,openModalToggle,title}) {
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const handleSubmit =  (values) => {
        console.log( values ); 
        
       };
  return (
    <>
    <Popup
                title={title}
                openModalToggle={openModalToggle}
                isOpen={isOpen}
                size={`lg`}
                backdrop={true}
                CommonFooter={true}
                isClose={false}
                CommonFooter={false}
                > 
                
                    <div className="px-4 py-2">
                    <Formik
                    initialValues={visualIdeainitialValues}
                    onSubmit={handleSubmit}
                    validationSchema={visualIdeavalidationSchema}>
                    {({ errors, values, setFieldValue }) => (
                        <AddVisualideaForm
                            submitErrors={submitErrors}
                            setSubmitError={setSubmitError}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Formik>

                    </div>
                 
            </Popup>
    </>
  )
}

export default AddVisualIdeaModal