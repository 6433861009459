import { apiSlice } from "../../apiSlice";
import { GET_COUNTRY } from "../../../Api";
interface Country {
  data: any;
  message: string;
  success: boolean;
}

export const countryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCountryData: builder.query<Country, void>({
      query: () => ({
        url: GET_COUNTRY,
      }),
      keepUnusedDataFor: 5,
    }),
    getCountryList: builder.mutation<Country, void>({
      query: () => ({
        url: GET_COUNTRY,
      })
    }),
  }),
});

export const { useGetCountryDataQuery, useGetCountryListMutation } = countryApiSlice;
