import { Row, Col, Label, FormGroup } from "reactstrap";
import { ErrorMessage, Field } from "formik";
import TextCounter from "../../../CommonInput/TextCounter";
import TitleInput from "../../../CommonInput/TitleInput";
import DescriptionInput from "../../../CommonInput/DescriptionInput";
import CustomSelect from "../../../CommonInput/CustomSelect";

const PriceContainer = (props: any) => {
  
  const { errors, submitErrors, index, values } = props;
  const { prizes } = errors;
  const prizeError = prizes?.length && prizes[index];
  const currentValue = values?.prizes?.length && values?.prizes[index];
  console.log(values.prizes[index].prizeName);
  const titleValue = values.prizes[index].prizeName
  const descriptionValue = values.prizes[index].prizeDescription
  const Pricetype = [
    { value: "Money", label: "Money" },
    { value: "ProductorService ", label: "Product or Service " }, 
  ];
  return (
    <>
      <Row>
        <Col sm="4" className="mb-3">
          <Label check>
           Rank Number <span className="txt-danger">*</span>
          </Label>
          <Field
            name={`prizes.${index}.rank`}
            type="text"
            placeholder="Enter a Number of the Rank"
            className={`form-control ${
              submitErrors && `${prizeError?.rank ? "is-invalid" : "is-valid"}`
            }`}
          />
          <ErrorMessage
            name={`prizes.${index}.rank`}
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
        <TitleInput title='Prize Unique Name' values={titleValue} name={`prizes.${index}.prizeName`}  placeholder="Prize Unique Name"/>  

           
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Prize Image <span className="txt-danger">*</span>
          </Label>
          <FormGroup>
            <Field
              name={`prizes.${index}.prizeImage`}
              type="file" 
              className={`form-control ${
                submitErrors &&
                `${prizeError?.prizeImage ? "is-invalid" : "is-valid"}`
              }`}
            /> 
            <ErrorMessage
              name={`prizes.${index}.prizeImage`}
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="mb-3">
        <DescriptionInput  name={`prizes.${index}.prizeDescription`} placeholder="Price Description" values={descriptionValue} lable='Prize Description'/>
        </Col>
        <Col>
        <FormGroup>
        <Label>Prize Type :</Label>
        <Field
          name={`prizes.${index}.priceType`}
          component={CustomSelect}
          options={Pricetype}
          placeholder="Select Prize Type"
          isMulti={false} // Enables multi-select
        />
      </FormGroup>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Prize Value in USD $ <span className="txt-danger">*</span>
          </Label>
          <Field
            name={`prizes.${index}.prizeValueInUsd`}
            type="text" 
            placeholder="0.00"
            className={`form-control ${
              submitErrors &&
              `${prizeError?.prizeValueInUsd ? "is-invalid" : "is-valid"}`
            }`}
          />
          <ErrorMessage
            name={`prizes.${index}.prizeValueInUsd`}
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Number of Prizes <span className="txt-danger">*</span>
          </Label>
          <Field
            name={`prizes.${index}.numberOfPrize`}
            type="text" 
            placeholder="0.00"
            className={`form-control ${
              submitErrors &&
              `${prizeError?.numberOfPrize ? "is-invalid" : "is-valid"}`
            }`}
          />
          <ErrorMessage
            name={`prizes.${index}.numberOfPrize`}
            component="span"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      {/* <Row>
        <Col sm="4" className="mb-3">
          <Label check>
            The winners are selected based on{" "}
            <span className="txt-danger">*</span>
          </Label>
          <FormGroup check>
            <Field
              id="numberOfShares"
              name={`prizes.${index}.numberOfShares`}
              type="checkbox"
              className="form-check-input"
            />
            <Label
              className={`mb-0 ${
                submitErrors &&
                ` ${
                  prizeError?.numberOfShares ? "text-danger" : "text-success"
                }`
              }`}
              for="numberOfShares"
              check
            >
              Number of shares
            </Label>
          </FormGroup>
          <FormGroup check>
            <Field
              id="numberOfLikes"
              name={`prizes.${index}.numberOfLikes`}
              type="checkbox"
              className="form-check-input"
            />
            <Label
              className={`mb-0 ${
                submitErrors &&
                ` ${prizeError?.numberOfLikes ? "text-danger" : "text-success"}`
              }`}
              for="numberOfLikes"
              check
            >
              Number of likes
            </Label>
          </FormGroup>
          <FormGroup check>
            <Field
              id="numberOfVotes"
              name={`prizes.${index}.numberOfVotes`}
              type="checkbox"
              className="form-check-input"
            />
            <Label
              className={`mb-0 ${
                submitErrors &&
                ` ${prizeError?.numberOfVotes ? "text-danger" : "text-success"}`
              }`}
              for="numberOfVotes"
              check
            >
              Number of votes
            </Label>
          </FormGroup>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            If multiple users reach the exact numbers{" "}
            <span className="txt-danger">*</span>
          </Label>
          <Field
            name={`prizes.${index}.excatNumber`}
            as="select"
            className={`form-control ${
              submitErrors &&
              `${prizeError?.excatNumber ? "is-invalid" : "is-valid"}`
            }`}
          >
            <option value={""}>select</option>
            <option value={"equal_among_winners"}>
              The same prize is divided among winners
            </option>
            <option value={"separate_prizes_for_each_winner"}>
              A separate equal prize for each winner (undivided)
            </option>
          </Field>
          <ErrorMessage
            name={`prizes.${index}.excatNumber`}
            component="span"
            className="invalid-feedback"
          />
        </Col>
      </Row> */}
      <Row>
      <Col sm="10" className="mb-3">
  <Label check>
    The winners are selected based on <span className="txt-danger">*</span>
  </Label>

  {/* Number of Shares */}
<FormGroup className="row">
  <div className="col-3">
    <Field
      id={`numberOfShares-${index}`}
      name={`prizes.${index}.numberOfShares`}
      type="checkbox"
      className="form-check-input"
    />
    <Label for={`numberOfShares-${index}`} check className="ms-2">
      Number of shares
    </Label>
  </div>

  {values.prizes[index].numberOfShares && (
    <div className="col-6 flex-fill">
      <FormGroup>
        <Label>
          Minimum eligibility to win a prize (minimum shares per content)*
        </Label>
        <Field
          name={`prizes.${index}.minSharesPerContent`}
          type="number"  
          className={`form-control ${submitErrors && `${errors.prizes?.[index]?.minSharesPerContent  ? "is-invalid" : "is-valid"}`}`}
          placeholder="Enter minimum shares"
          min="50"
          defaultValue="50"
          // validate={(value) => (value >= 50 ? undefined : "Enter 50 or more")}
        />
        <ErrorMessage
          name={`prizes.${index}.minSharesPerContent`}
          component="span"
          className="invalid-feedback"
        />
      </FormGroup>
    </div>
  )}
</FormGroup>

{/* Number of Likes */}
<FormGroup className="row">
  <div className="col-3">
    <Field
      id={`numberOfLikes-${index}`}
      name={`prizes.${index}.numberOfLikes`}
      type="checkbox"
      className="form-check-input"
    />
    <Label for={`numberOfLikes-${index}`} check className="ms-2">
      Number of likes
    </Label>
  </div>

  {values.prizes[index].numberOfLikes && (
    <div className="col-6 flex-fill">
      <FormGroup>
        <Label>
          Minimum eligibility to win a prize (minimum likes per content)*
        </Label>
        <Field
          name={`prizes.${index}.minLikesPerContent`}
          type="number"
          
          className={`form-control ${submitErrors && `${ errors.prizes?.[index]?.minLikesPerContent  ? "is-invalid" : "is-valid"}`}`}
          placeholder="Enter minimum likes"
          min="50"
          defaultValue="50"
          // validate={(value) => (value >= 50 ? undefined : "Enter 50 or more")}
        />
        <ErrorMessage
          name={`prizes.${index}.minLikesPerContent`}
          component="span"
          className="invalid-feedback"
        />
      </FormGroup>
    </div>
  )}
</FormGroup>

{/* Number of Votes */}
<FormGroup className="row">
  <div className="col-3">
    <Field
      id={`numberOfVotes-${index}`}
      name={`prizes.${index}.numberOfVotes`}
      type="checkbox"
      className="form-check-input"
    />
    <Label for={`numberOfVotes-${index}`} check className="ms-2">
      Number of votes
    </Label>
  </div>

  {values.prizes[index].numberOfVotes && (
    <div className="col-6 flex-fill">
      <FormGroup>
        <Label>
          Minimum eligibility to win a prize (minimum votes per content)*
        </Label>
        <Field
          name={`prizes.${index}.minVotesPerContent`}
          type="number"
          className={`form-control ${submitErrors && `${ errors.prizes?.[index]?.minVotesPerContent  ? "is-invalid" : "is-valid"}`}`}
          placeholder="Enter minimum votes"
          min="50"
          defaultValue="50"
          // validate={(value) => (value >= 50 ? undefined : "Enter 50 or more")}
        />
        <ErrorMessage
          name={`prizes.${index}.minVotesPerContent`}
          component="span"
          className="invalid-feedback"
        />
      </FormGroup>
    </div>
  )}
</FormGroup>

</Col>

      </Row>
    </>
  );
};

export default PriceContainer;
